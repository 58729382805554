import { ReactElement, useState } from 'react';
import { useToast } from '@hooks';
import useNoodleApi from '@hooks/useNoodleApi';
import TextInput from '@components/FormFields/TextInput';
import Buttons from '@components/Buttons';
import Spacer from '@components/Spacer';
import { findOrCreateTypedCustomTermsDynamicVariable } from '@tsClient/creators';
import { CustomTermsDynamicVariableType } from '@typings/api-models';

type Props = {
  creatorId: string;
  type: CustomTermsDynamicVariableType;
  onSubmit: () => void;
};

export const validVariableTextRegex = /^[a-zA-Z0-9_:\s]+$/;

const convertStringToCamelCase = (input: string): string => input.replace(/[-_\s]+(.)?/g, (_, char) => char ? char.toUpperCase() : '');

const AddDynamicVariableForm = ({ creatorId, onSubmit, type }: Props): ReactElement => {
  const [variableName, setVariableName] = useState<string>('');
  const [variableNameError, setVariableNameError] = useState<string | null>(null);

  const { getData: findOrCreateDynamicVariableFn, fetchingState } = useNoodleApi(findOrCreateTypedCustomTermsDynamicVariable, {
    toastErrorMessage: error => [
      useToast.ToastTypeVariants.ERROR,
      error?.errors?.[0] || 'There was an error creating your custom input. Please try again.',
    ],
    toastOnError: true,
    toastSuccessMessage: () => [useToast.ToastTypeVariants.SUCCESS, 'Custom input created successfully!'],
  });

  const handleAddInput = async (): Promise<void> => {
    if (!validVariableTextRegex.test(variableName)) {
      setVariableNameError('Variable should only include alphanumerics, spaces, or underscores.');
      return;
    }
    if (variableNameError) {
      return;
    }
    await findOrCreateDynamicVariableFn({
      creatorId,
      type,
      variableName: convertStringToCamelCase(variableName),
    });
    onSubmit();
  };

  return (
    <>
      <TextInput
        error={variableNameError}
        hasFixedHeight={false}
        id={'variableName'}
        value={variableName}
        onChange={setVariableName}
        placeholder={`Enter custom input name`}
        onError={setVariableNameError}
        required
      />
      <Spacer size={8}/>
      <Buttons isSecondary isFullWidth onClick={handleAddInput} isFetching={fetchingState.isFetching}>
        Add custom input
      </Buttons>
    </>
  );
};

export default AddDynamicVariableForm;
