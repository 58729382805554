const SvgAmex: React.FC = () => (
  <svg width="32" height="20" viewBox="0 0 32 20" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ verticalAlign: 'middle' }}>
    <g clipPath="url(#clip0_1_2)">
      <path
        d="M28 0.5H4C2.067 0.5 0.5 2.067 0.5 4V16C0.5 17.933 2.067 19.5 4 19.5H28C29.933 19.5 31.5 17.933 31.5 16V4C31.5 2.067 29.933 0.5 28 0.5Z"
        fill="#286CB4"
        stroke="black"
        strokeOpacity="0.15"
      />
      <path
        d="M7.47601 8.55236L8.11848 10.1126H6.83354L7.47601 8.55236ZM20.8678 8.61911H18.3897V9.31164H20.826V10.3463H18.3897V11.1139H20.8678V11.7313L22.5949 9.86233L20.8678 7.90989V8.61911ZM9.15311 6.66667H12.4823L13.2249 8.27701L13.9091 6.65832H22.5449L23.446 7.65123L24.3721 6.65832H28.3354L25.4068 9.87067L28.3104 13.058H24.2804L23.3792 12.0651L22.4447 13.058H8.36045L7.95161 12.0651H7.00876L6.59992 13.058H3.34585L6.0826 6.65832H8.93617L9.15311 6.66667ZM16.3705 7.56779H14.5015L13.2499 10.5131L11.8982 7.56779H10.0459V11.5728L8.32708 7.56779H6.66667L4.68085 12.1569H5.97414L6.38298 11.164H8.5607L8.96955 12.1569H11.2391V8.87776L12.6992 12.1569H13.6921L15.1439 8.88611V12.1569H16.3621L16.3705 7.56779ZM24.1469 9.87067L26.2578 7.56779H24.7393L23.4043 9.00292L22.111 7.56779H17.2048V12.1652H22.0442L23.3876 10.7134L24.6809 12.1652H26.2411L24.1469 9.87067Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_1_2">
        <rect width="32" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgAmex;
