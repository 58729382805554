import React, { useEffect, useRef, useState, useCallback, FC, ReactElement } from 'react';

import { getCorrectFileUrl } from '@helpers/urlsHelper';
import AudioPlayerTemplate from '@components/AudioPlayer/AudioPlayerTemplate';

type AudioProps = {
  audio: {
    url: string;
  };
  isSmall: boolean;
};

const AudioPlayer: FC<AudioProps> = ({ audio, isSmall }): ReactElement => {
  const audioRef = useRef<HTMLDivElement>(null);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const wavesurfer = useRef<any>(null);

  const [audioPlaying, setAudioPlaying] = useState(false);
  const [remaining, setRemaining] = useState<string>('');
  const [isLoaded, setIsLoaded] = useState(false);

  const formatTime = (inSeconds:number): string => {
    const mins = Math.floor(inSeconds / 60);
    const seconds = Math.ceil(inSeconds % 60);
    return `${mins}:${seconds >= 10 ? seconds : `0${seconds}`}`;
  };

  const updateRemainingTime = useCallback(() => {
    if (wavesurfer.current) {
      try {
        const inSeconds = Math.ceil(wavesurfer.current?.getDuration());
        const currentPosition = Math.ceil(wavesurfer.current?.getCurrentTime());
        setRemaining(formatTime(inSeconds - currentPosition));
      } catch (e) {
        // ignore wavesurfer error
      }
    }
  }, []);

  let intervalId:ReturnType<typeof setInterval> | null = null;

  const handlePlayPause = ():void => {
    if (audioPlaying) {
      if (intervalId) {
        clearInterval(intervalId);
      }
      setAudioPlaying(false);
    } else {
      intervalId = setInterval(() => {
        updateRemainingTime();
      }, 1000);
      setAudioPlaying(true);
    }
    wavesurfer?.current?.playPause();
  };

  const handleFinishPlaying = useCallback(() => {
    if (intervalId) {
      clearInterval(intervalId);
      setAudioPlaying(false);
    }
  }, [intervalId]);

  const createWaveSurferOptions = useCallback(
    (el) => ({
      barGap: 2,
      barMinHeight: 1,
      barWidth: 2,
      container: el,
      cursorColor: '#a8a8a800',
      height: isSmall ? 48 : 60,
      normalize: true,
      progressColor: '#616161',
      waveColor: '#ccc',
    }),
    [isSmall],
  );

  useEffect(() => {
    const handleWaveSurfer = async (): Promise<void> => {
      if (audio?.url && audioRef.current) {
        const audioUrl = getCorrectFileUrl(audio.url);

        setAudioPlaying(false);
        if (audioRef.current) {
          const { default: WaveSurfer } = await import('wavesurfer.js');

          const options = createWaveSurferOptions(audioRef.current);
          wavesurfer.current = WaveSurfer.create(options);
          wavesurfer.current.load(audioUrl);

          wavesurfer.current.on('ready', () => {
            setIsLoaded(true);
            updateRemainingTime();
          });
          wavesurfer.current.on('finish', () => {
            handleFinishPlaying();
          });
        }
      }
    };

    handleWaveSurfer();

    return () => audio && wavesurfer?.current?.destroy();
  }, [audio, createWaveSurferOptions, handleFinishPlaying, updateRemainingTime]);

  return (
    <AudioPlayerTemplate
      isLoaded={isLoaded}
      isSmall={isSmall}
      audioRef={audioRef}
      remaining={remaining}
      audioPlaying={audioPlaying}
      handlePlayPause={handlePlayPause}
    />
  );
};

export default AudioPlayer;
