import * as Stripe from '@stripe/stripe-js';

const getPaymentMethodId = (paymentMethod: string | Pick<Stripe.PaymentMethod, 'id'> | null | undefined): string | null => {
  if (!paymentMethod) {
    return null;
  }

  if (typeof paymentMethod === 'string') {
    return paymentMethod;
  }

  if (typeof paymentMethod === 'object'
    && paymentMethod !== null
    && 'id' in paymentMethod
  ) {
    return paymentMethod.id;
  }

  return null;
};

export default getPaymentMethodId;