import Modal from '@components/Modal';
import { FC, useState } from 'react';
import commonStyles from '@modals/ModalsCommon.module.scss';
import Spacer from '@components/Spacer';
import Button from '@components/DesignLibrary/Button';
import * as ApiModels from '@typings/api-models';
import useNoodleApi from '@hooks/useNoodleApi';
import * as tsClient from '@tsClient';

type Props = {
  invoice: Pick<ApiModels.Invoice, 'id'>;
  onClose: () => void;
  refetchData: () => Promise<void>;
};

const CancelPaymentPlanModal: FC<Props> = ({ invoice, onClose, refetchData }) => {
  const [isCanceling, setIsCanceling] = useState(false);

  const { getData: cancelPaymentPlanFn } = useNoodleApi(tsClient.invoices.cancelPaymentPlan, {
    toastOnError: true,
  });

  const cancel = async (): Promise<void> => {
    setIsCanceling(true);
    const { data } = await cancelPaymentPlanFn({ id: invoice.id });
    if (data) {
      await refetchData();
      onClose();
    } else {
      setIsCanceling(false);
    }
  };

  return (
    <Modal
      hideBottomInset

      title=' '
      onClose={onClose}

    >
      <div className={commonStyles.container} style={{ gap: 0 }}>
        <h2 className='heading-sm'>You are about to cancel your payment plan.</h2>
        <Spacer size={24} />
        <Button variant='destructive' size='md' onClick={cancel} loading={isCanceling}>
          Cancel payment plan
        </Button>
        <Spacer size={8} />
        <Button variant='secondary' size='md' onClick={onClose}>
          Go back
        </Button>
      </div>
    </Modal>
  );
};

export default CancelPaymentPlanModal;
