import { STRIPE_KEY } from '@configuration/client';

const isTestEnvironment = !STRIPE_KEY || /pk_test.*/.test(STRIPE_KEY);

const makeTestEmail = (email: string | null | undefined): string | undefined => {
  if (!email) {
    return undefined;
  }

  if (isTestEnvironment) {
    return email.replace('@', '+test_email@');
  }

  return email;
};

export default makeTestEmail;