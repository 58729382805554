import Buttons from '@components/Buttons';
import PlusCircle from '@components/Icons/PlusCircle';
import ProgressIndicator from '@components/ProgressIndicator';
import { m } from 'framer-motion';
import { useState } from 'react';
import s from './DocumentAddButton.module.scss';

type Props = {
  isSpinning: boolean;
  onClick: () => void;
};

const MotionButton = m(Buttons);

const DocumentAddButton: React.FC<Props> = ({ isSpinning, onClick }) => {
  const [isHovered, setIsHovered] = useState(false);
  const text = isSpinning ? 'Uploading...' : 'Add document';
  return (
    <MotionButton
      onHoverStart={() => setIsHovered(true)}
      onHoverEnd={() => setIsHovered(false)}
      layout='position'
      isWrapper
      onClick={onClick}
      disabled={isSpinning}
      className={s.addDocument}
      whileHover={{ borderColor: 'var(--color-gray-25)', paddingRight: 8 }}
    >
      <m.div layout className={s.progress}>
        {isSpinning ? <ProgressIndicator size={16} /> : <PlusCircle weight="fill" size={16} color={isHovered ? "var(--color-noodle)" : "var(--color-gray-50)"} />}
      </m.div>
      {(isHovered || isSpinning) && (
        <m.p initial={{ opacity: 0 }} animate={{ opacity: 1 }} layout className="caption">
          {text}
        </m.p>
      )}
    </MotionButton>
  );
};

export default DocumentAddButton;
