import { Expression, ExpressionVariable } from "@/typings/api-models";

export type ThisExpression = Pick<Expression, 'expression'> & {
    variables: Pick<ExpressionVariable, 'variableName' | 'referenceId' | 'selector'>[];
  };

const isSimpleExpression = (conditionExpression: ThisExpression): boolean => {
  // Show dropdown editor when there is no expression
  if (!conditionExpression?.expression) {
    return true;
  }
  // If there is only one variable check if complex operaters are used
  if (conditionExpression?.variables?.length === 1) {
    const complexOperators = /(\|\|)|(&&)/;
    return !complexOperators.test(conditionExpression.expression);
  }
  // In any other case, show text editor
  return false;
};

export default isSimpleExpression;