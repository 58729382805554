import React, { useState, useMemo, useCallback, useEffect } from 'react';
import {
  Switch,
  Select,
  Input,
  TextArea,
} from '@/components/DesignLibrary/Atoms';
import { Option } from '@/components/DesignLibrary/Atoms/Select';
import { Questionnaire } from '@/typings/Questionnaire';
import Modal from '@/components/Modal';
import Buttons from '@/components/Buttons';
import TrashSimple from '@/components/Icons/TrashSimple';
import Button from '@/components/DesignLibrary/Button';
import { IBM_Plex_Mono as ibmPlexMono } from 'next/font/google';
import { useFormEditorContext } from '@/components/Questionnaire/questionnaireEditor/FormEditor/FormEditorContext';

import s from './Conditions.module.scss';

const mono = ibmPlexMono({
  subsets: ['latin'],
  weight: '400',
});

type SingleCondition = {
  field: string;
  operator: Questionnaire.Comparison;
  value?: string;
  logicalOperator?: '&&' | '||';
};

type ConditionsProps = {
  conditionExpression?: {
    expression: string;
    variables: {
      variableName: string;
      referenceId: string;
      selector: string;
    }[];
  };
  setConditionExpression?: (
    expression: {
      expression: string;
      variables: {
        variableName: string;
        referenceId: string;
        selector: string;
      }[];
    },
  ) => void;
};

const ConditionsLabel: Record<Questionnaire.Comparison, string> = {
  [Questionnaire.Comparison.Equal]: 'Equal',
  [Questionnaire.Comparison.NotEqual]: 'Not equal',
  [Questionnaire.Comparison.Contain]: 'Contain',
  [Questionnaire.Comparison.NotContain]: 'Not contain',
  [Questionnaire.Comparison.GreaterThan]: 'Greater than',
  [Questionnaire.Comparison.LessThan]: 'Less than',
  [Questionnaire.Comparison.Filled]: 'Filled',
  [Questionnaire.Comparison.NotFilled]: 'Not filled',
};

const ConditionOptions: Record<string, Option> = Object.entries(ConditionsLabel).reduce(
  (options, [key, label]) => ({
    ...options,
    [key]: { key, label },
  }),
  {},
);

const operatorToComparison: Record<string, Questionnaire.Comparison> = {
  '!': Questionnaire.Comparison.NotFilled,
  '!!': Questionnaire.Comparison.Filled,
  '!==': Questionnaire.Comparison.NotEqual,
  '<': Questionnaire.Comparison.LessThan,
  '===': Questionnaire.Comparison.Equal,
  '>': Questionnaire.Comparison.GreaterThan,
  contains: Questionnaire.Comparison.Contain,
  not_contains: Questionnaire.Comparison.NotContain,
};

const comparisonToOperator: Record<Questionnaire.Comparison, string> = {
  [Questionnaire.Comparison.Equal]: '===',
  [Questionnaire.Comparison.NotEqual]: '!==',
  [Questionnaire.Comparison.LessThan]: '<',
  [Questionnaire.Comparison.GreaterThan]: '>',
  [Questionnaire.Comparison.Filled]: '!!',
  [Questionnaire.Comparison.NotFilled]: '!',
  [Questionnaire.Comparison.Contain]: 'contains',
  [Questionnaire.Comparison.NotContain]: 'not_contains',
};

const parseConditions = (expression: string): SingleCondition[] => {
  const tokens = expression.split(/(\s+&&\s+|\s+\|\|\s+)/).filter(t => t.trim() !== '');
  const conditions: SingleCondition[] = [];
  let logicalOperator: '&&' | '||' | undefined;

  tokens.forEach(token => {
    const trimmedToken = token.trim();

    if (trimmedToken === '&&' || trimmedToken === '||') {
      logicalOperator = trimmedToken as '&&' | '||';
    } else {
      const regex = /^(!!|!)?(\w+)\s*(===|!==|>|<|contains|not_contains)?\s*(["'].*["'])?$/;
      const match = regex.exec(trimmedToken);

      if (match) {
        const [_, operatorPrefix, field, operatorSymbol, value] = match;
        let operator: Questionnaire.Comparison;

        if (operatorPrefix) {
          operator = operatorToComparison[operatorPrefix];
        } else {
          operator = operatorToComparison[operatorSymbol] || Questionnaire.Comparison.Equal;
        }

        conditions.push({
          field,
          logicalOperator,
          operator,
          value: value ? value.replace(/['"]/g, '').trim() : undefined,
        });

        logicalOperator = undefined;
      }
    }
  });

  // The first condition is IF, so it should not have a logical operator
  if (conditions.length > 0) {
    conditions[0].logicalOperator = undefined;
  }

  return conditions;
};

const Conditions: React.FC<ConditionsProps> = ({
  conditionExpression,
  setConditionExpression,
}) => {
  const { formData } = useFormEditorContext();
  const [conditions, setConditions] = useState<SingleCondition[]>([]);
  const [showConditions, setShowConditions] = useState(
    Boolean(conditionExpression?.expression),
  );
  const [showConditionEditor, setShowConditionEditor] = useState(false);
  const [showCodeEditor, setShowCodeEditor] = useState(false);
  const [codeExpression, setCodeExpression] = useState(
    conditionExpression?.expression || '',
  );
  const [editingConditionIndex, setEditingConditionIndex] = useState<number | null>(
    null,
  );
  const [newCondition, setNewCondition] = useState<SingleCondition>({
    field: '',
    logicalOperator: undefined,
    operator: Questionnaire.Comparison.Equal,
    value: '',
  });

  useEffect(() => {
    if (conditionExpression?.expression) {
      setConditions(parseConditions(conditionExpression.expression));
    } else {
      setConditions([]);
    }
  }, [conditionExpression]);

  const fieldsInfo = useMemo(() => {
    const info: Record<
      string,
      Pick<Questionnaire.FieldCommon, 'key' | 'label' | 'type' | 'options'>
    > = {};

    formData.sections.forEach((section) => {
      section.fields.forEach((field) => {
        info[field.key] = {
          key: field.key,
          label: field.label || field.key,
          options: field.options,
          type: field.type,
        };
      });
    });

    return info;
  }, [formData.sections]);

  const fieldOptions = useMemo(
    () =>
      Object.values(fieldsInfo).map((field) => ({
        key: field.key,
        label: field.label,
      })),
    [fieldsInfo],
  );

  const saveCombinedExpression = (conds: SingleCondition[]): void => {
    if (!conds.length) {
      setConditionExpression?.({ expression: '', variables: [] });
      return;
    }

    const expressionParts = conds.map((cond, index) => {
      const operator = comparisonToOperator[cond.operator];
      let conditionStr = '';
      if (['!!', '!'].includes(operator)) {
        conditionStr = `${operator}${cond.field}`;
      } else {
        conditionStr = `${cond.field} ${operator} "${cond.value}"`;
      }

      if (index > 0 && cond.logicalOperator) {
        conditionStr = `${cond.logicalOperator} ${conditionStr}`;
      }

      return conditionStr;
    });

    const combinedExpression = expressionParts.join(' ');
    const variables = conds.map(cond => ({
      referenceId: cond.field,
      selector: cond.field,
      variableName: cond.field,
    }));

    setConditionExpression?.({ expression: combinedExpression, variables });
  };

  const saveCondition = (): void => {
    const updatedConditions = [...conditions];
    if (editingConditionIndex !== null && editingConditionIndex < conditions.length) {
      updatedConditions[editingConditionIndex] = newCondition;
    } else {
      updatedConditions.push(newCondition);
    }
    setConditions(updatedConditions);
    setShowConditionEditor(false);
    setNewCondition({
      field: '',
      logicalOperator: undefined,
      operator: Questionnaire.Comparison.Equal,
      value: '',
    });
    setEditingConditionIndex(null);
    saveCombinedExpression(updatedConditions);
  };

  const saveCodeExpression = (): void => {
    const parsedConditions = parseConditions(codeExpression);
    const variables = parsedConditions.map((cond) => ({
      referenceId: cond.field,
      selector: cond.field,
      variableName: cond.field,
    }));

    setConditionExpression?.({ expression: codeExpression, variables });
    setConditions(parsedConditions);
    setShowConditionEditor(false);
    setShowCodeEditor(false);
  };

  const deleteCondition = (index: number): void => {
    if (window.confirm('Are you sure you want to delete this condition?')) {
      const updatedConditions = conditions.filter((_, idx) => idx !== index);
      setConditions(updatedConditions);
      saveCombinedExpression(updatedConditions);
    }
  };

  const editCondition = (index: number): void => {
    setEditingConditionIndex(index);
    setNewCondition(conditions[index]);
    setShowConditionEditor(true);
    setShowCodeEditor(false);
  };

  const addNewCondition = (): void => {
    setEditingConditionIndex(conditions.length);
    setNewCondition({
      field: '',
      logicalOperator: conditions.length > 0 ? '&&' : undefined,
      operator: Questionnaire.Comparison.Equal,
      value: '',
    });
    setShowConditionEditor(true);
    setShowCodeEditor(false);
  };

  const handleEditWithCode = (): void => {
    setShowConditionEditor(true);
    setShowCodeEditor(true);
    setCodeExpression(conditionExpression?.expression || '');
  };

  const getAvailableConditions = useCallback(
    (fieldKey: string) => {
      const fieldType = fieldsInfo[fieldKey]?.type;

      switch (fieldType) {
      case 'short-text':
      case 'long-text':
      case 'name':
      case 'address-international':
      case 'address-us':
      case 'ssn':
      case 'phone-number':
        return [
          ConditionOptions[Questionnaire.Comparison.Equal],
          ConditionOptions[Questionnaire.Comparison.NotEqual],
          ConditionOptions[Questionnaire.Comparison.Contain],
          ConditionOptions[Questionnaire.Comparison.NotContain],
          ConditionOptions[Questionnaire.Comparison.Filled],
          ConditionOptions[Questionnaire.Comparison.NotFilled],
        ];
      case 'number':
      case 'numeric':
      case 'currency':
      case 'percent':
      case 'date':
        return [
          ConditionOptions[Questionnaire.Comparison.Equal],
          ConditionOptions[Questionnaire.Comparison.NotEqual],
          ConditionOptions[Questionnaire.Comparison.GreaterThan],
          ConditionOptions[Questionnaire.Comparison.LessThan],
          ConditionOptions[Questionnaire.Comparison.Filled],
          ConditionOptions[Questionnaire.Comparison.NotFilled],
        ];
      case 'multi-select':
        return [
          ConditionOptions[Questionnaire.Comparison.Equal],
          ConditionOptions[Questionnaire.Comparison.NotEqual],
          ConditionOptions[Questionnaire.Comparison.Contain],
          ConditionOptions[Questionnaire.Comparison.NotContain],
          ConditionOptions[Questionnaire.Comparison.Filled],
          ConditionOptions[Questionnaire.Comparison.NotFilled],
        ];
      case 'single-select':
      case 'selection':
        return [
          ConditionOptions[Questionnaire.Comparison.Equal],
          ConditionOptions[Questionnaire.Comparison.NotEqual],
          ConditionOptions[Questionnaire.Comparison.Filled],
          ConditionOptions[Questionnaire.Comparison.NotFilled],
        ];
      default:
        return [];
      }
    },
    [fieldsInfo],
  );

  return (
    <>
      {showConditionEditor && (
        <Modal
          title="Condition Editor"

          onClose={() => setShowConditionEditor(false)}

        >
          <div className={s.conditionEditor} {...showCodeEditor && { style: { minHeight: 'unset'} }}>
            {showCodeEditor
              ? (
                <>
                  <TextArea
                    label=""
                    value={codeExpression}
                    rows={10}
                    onChange={setCodeExpression}
                    style={mono.style}
                  />
                  <Button variant="primary" size="md" onClick={saveCodeExpression}>
                  Save Condition
                  </Button>
                </>
              )
              : (
                <>
                  {editingConditionIndex !== null && editingConditionIndex > 0 && (
                    <Select
                      label="Logical Operator"
                      options={[
                        { key: '&&', label: 'And' },
                        { key: '||', label: 'Or' },
                      ]}
                      value={newCondition.logicalOperator || '&&'}
                      onChange={(value) =>
                        setNewCondition({ ...newCondition, logicalOperator: value as '&&' | '||' })
                      }
                    />
                  )}
                  <Select
                    label="Field"
                    options={fieldOptions}
                    value={newCondition.field}
                    onChange={(value) =>
                      setNewCondition({ ...newCondition, field: value })
                    }
                  />
                  {newCondition.field && (
                    <>
                      <Select
                        label="Condition"
                        options={getAvailableConditions(newCondition.field)}
                        value={newCondition.operator}
                        onChange={(value) =>
                          setNewCondition({
                            ...newCondition,
                            operator: value as Questionnaire.Comparison,
                          })
                        }
                      />
                      {![
                        Questionnaire.Comparison.Filled,
                        Questionnaire.Comparison.NotFilled,
                      ].includes(newCondition.operator) && (
                        <>
                          {['multi-select', 'single-select', 'selection'].includes(
                            fieldsInfo[newCondition.field]?.type,
                          )
                            ? (
                              <Select
                                label="Value"
                                options={
                                  fieldsInfo[newCondition.field]?.options?.map(
                                    ({ key, label }) => ({
                                      key,
                                      label,
                                    }),
                                  ) ?? []
                                }
                                value={newCondition.value}
                                onChange={(value) =>
                                  setNewCondition({ ...newCondition, value })
                                }
                              />
                            )
                            : (
                              <Input
                                label="Value"
                                value={newCondition.value}
                                onChange={(value) =>
                                  setNewCondition({ ...newCondition, value })
                                }
                              />
                            )}
                        </>
                      )}
                      <Button variant="primary" size="md" onClick={saveCondition}>
                      Save Condition
                      </Button>
                    </>
                  )}
                </>
              )}
          </div>
        </Modal>
      )}
      <Switch
        label="Show conditionally"
        name="show-conditions"
        isChecked={showConditions}
        onChange={(checked) => {
          setShowConditions(checked);
          if (!checked) {
            setConditions([]);
            setConditionExpression?.({ expression: '', variables: [] });
          }
        }}
      />
      {showConditions && (
        <>
          {conditions.length > 0 && (
            <table className={s.conditions}>
              <tbody>
                {conditions.map((condition, idx) => (
                  <tr key={idx}>
                    <td className={idx === 0 ? s.if : s.andOr}>
                      <Buttons isWrapper onClick={() => editCondition(idx)}>
                        {(idx === 0 && 'If') || (condition.logicalOperator === '&&' ? 'And' : 'Or')}
                      </Buttons>
                    </td>
                    <td title={fieldsInfo[condition.field]?.label || condition.field}>
                      {fieldsInfo[condition.field]?.label || condition.field}
                    </td>
                    <td className={s.condition} title={ConditionsLabel[condition.operator]}>
                      {ConditionsLabel[condition.operator]}
                    </td>
                    {![
                      Questionnaire.Comparison.Filled,
                      Questionnaire.Comparison.NotFilled,
                    ].includes(condition.operator)
                      ? (
                        <td title={condition.value}>{condition.value || 'NULL'}</td>
                      )
                      : <td />}
                    <td className={s.delete} title="Delete">
                      <Buttons isWrapper onClick={() => deleteCondition(idx)}>
                        <TrashSimple weight="fill" color="var(--color-error)" size={16} />
                      </Buttons>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
          <div className={s.buttons}>
            <Button variant="primary" size="sm" onClick={addNewCondition}>
              Add Condition
            </Button>
            <Button variant="secondary" size="sm" onClick={handleEditWithCode}>
              Edit with code
            </Button>
          </div>
        </>
      )}
    </>
  );
};

export default Conditions;
