import { nanoid } from 'nanoid';

const CIRCUIT_BREAKER = 100;
const guaranteeUniqueSlug = (slug: string, takenSlugs: string[]): string => {
  let maybeUniqueSlug = slug;
  let nextN = 2;
  while (takenSlugs.includes(maybeUniqueSlug) && nextN < CIRCUIT_BREAKER) {
    maybeUniqueSlug = `${slug}-${nextN}`;
    nextN += 1;
  }

  if (takenSlugs.includes(maybeUniqueSlug)) {
    maybeUniqueSlug = nanoid();
  }

  if (takenSlugs.includes(maybeUniqueSlug)) {
    throw new Error(`Failed to generate unique slug: ${slug}`);
  }

  return maybeUniqueSlug;
};

export default guaranteeUniqueSlug;