import * as React from 'react';
import { m, AnimatePresence } from 'framer-motion';

import XCircle from 'components/Icons/XCircle';
import Microphone from 'components/Icons/Microphone';
import Buttons from 'components/Buttons';
import { mixpanelTrack } from '@providers/Mixpanel';
import OutsideClick from '@components/OutsideClick';
import ChatDropDown from '@components/Chat/ChatDropDown';
import MediaRecorder from './MediaRecorder';

import s from './InputComposer.module.scss';

type Props = {
  onSubmit: (url: string) => void;
  disabled: boolean;
  onFileUploadClicked: () => void;
};

const AddSound = ({ onSubmit, disabled, onFileUploadClicked }: Props): React.ReactElement => {
  const [popoverOpen, setPopoverOpen] = React.useState(false);

  const handleSubmit = (url: string): void => {
    mixpanelTrack('InputComposer - audio recorded');
    onSubmit(url);
  };

  return (
    <>
      <Buttons
        disabled={disabled}
        ariaLabel={'Record audio'}
        iconBefore={
          <m.div
            style={{
              alignItems: 'center',
              display: 'flex',
              height: '16px',
              justifyContent: 'center',
              width: '16px',
              willChange: 'transform',
            }}
            whileTap={{ scale: disabled ? 1 : 0.9 }}
          >
            {popoverOpen
              ? (
                <XCircle weight="fill" size={16} color={'var(--color-gray-50)'} />
              )
              : (
                <Microphone weight="fill" size={16} color={((disabled || popoverOpen) && 'var(--color-gray-50)') || 'var(--color-primary)'} />
              )}
          </m.div>
        }
        onClick={() => {
          setPopoverOpen(!popoverOpen);
        }}
      >
        Audio
      </Buttons>
      <AnimatePresence>
        {popoverOpen && (
          <OutsideClick onOutsideClick={() => setPopoverOpen(false)} className={s.outsideClick}>
            <ChatDropDown>
              <MediaRecorder onSubmit={handleSubmit} setPopoverOpen={setPopoverOpen} onFileUploadClicked={onFileUploadClicked} />
            </ChatDropDown>
          </OutsideClick>
        )}
      </AnimatePresence>
    </>
  );
};

export default AddSound;
