import * as React from 'react';
import { Reorder, useDragControls, m } from 'framer-motion';
import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import FormField from '@/components/Questionnaire/form/FormField';
import DotsSixVertical from '@/components/Icons/DotsSixVertical';
import Workflow from '@/components/Icons/Workflow';
import { Questionnaire } from '@/typings/Questionnaire';

import Tooltip from '@/components/Tooltip';

import s from './FormPreview.module.scss';

type Props = {
  item: Questionnaire.Field;
  subFields?: Questionnaire.Field[];
  isSelected: boolean;
  assignees: string[];
  currentSelectedField?: string;
  onClick: (args: { field: Questionnaire.Field; isInList: boolean }) => void;
  conditions?: Questionnaire.Condition[];
  conditionExpression?: Questionnaire.Expression;
  onReorderSubFields?: (parentFieldId: string, subFields: Questionnaire.Field[]) => void;
};

const generateConditionDescription = (conditionExpression?: Questionnaire.Expression, conditions?: Questionnaire.Condition[]): string => {
  if (conditionExpression) {
    return conditionExpression.expression;
  }
  return conditions
    ?.map((condition, idx) => {
      const operatorText = (condition.operator && idx !== 0) ? ` ${Questionnaire.Operator[condition.operator]}` : '';
      return `${operatorText} ${condition.dependentFieldKey} ${condition.condition} ${condition.value}`;
    })
    .join(' ') || '';
};

const FormItem: React.FC<Props> = ({
  assignees,
  conditionExpression,
  conditions,
  currentSelectedField,
  isSelected,
  item,
  subFields,
  onClick,
  onReorderSubFields,
}) => {
  const { control } = useForm();
  const dragControls = useDragControls();

  const hasConditions = ((conditions && conditions.length > 0)
    || conditionExpression?.expression || (conditionExpression?.variables && conditionExpression.variables.length > 0));

  const handleClickItem = React.useCallback((event: React.MouseEvent) => {
    event.stopPropagation();
    onClick({ field: item, isInList: false });
  }, [item, onClick]);

  const handleClickSubItem = React.useCallback((field: Questionnaire.Field) => {
    onClick({ field, isInList: true });
  }, [onClick]);

  const handleReorderSubFields = (fields: Questionnaire.Field[]): void => {
    onReorderSubFields?.(item.id, fields);
  };

  return (
    <Reorder.Item
      as="div"
      value={item}
      id={item.id}
      className={classNames(s.field, isSelected && s.selectedField)}
      dragListener={false}
      dragControls={dragControls}
      onClick={handleClickItem}
      whileDrag={{ boxShadow: 'var(--shadow-lg)' }}
    >
      {(assignees.length > 0 || hasConditions) && (
        <div className={s.topLine}>
          {hasConditions && (
            <Tooltip text={`Visible if: ${generateConditionDescription(conditionExpression, conditions)}`}>
              <Workflow size={12} weight="fill" color="var(--color-noodle)" />
            </Tooltip>
          )}
          {assignees && <div className={s.assigneeGroup}>
            {assignees.map(assignee => (
              <div key={assignee} className={s.assignee}>
                {assignee}
              </div>
            ))}
          </div>}
        </div>
      )}
      <m.div layout='position'>
        <FormField field={item} control={control} rules={{}} isPreview />
      </m.div>

      {subFields && subFields.length > 0 && (
        <Reorder.Group as="div" axis="y" values={subFields} className={s.listItemsContainer} onReorder={handleReorderSubFields}>
          {subFields
            .sort((a, b) => a.rank > b.rank ? 1 : -1)
            .map((field) => (
              <FormItem
                key={field.id}
                item={field}
                assignees={[]}
                isSelected={field.id === currentSelectedField}
                conditions={field.conditions}
                conditionExpression={field.conditionExpression || undefined}
                onClick={() => handleClickSubItem(field)}
              />
            ))}
        </Reorder.Group>
      )}
      {isSelected && <DotsSixVertical className={s.reorderHandle} onPointerDown={e => dragControls.start(e)} />}
    </Reorder.Item>
  );
};

export default FormItem;
