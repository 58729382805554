import { useMemo, useEffect, FC, memo, useState, ReactNode, useContext } from 'react';
import { Table, TBody, THead, TH, TD, TableRow } from '@/components/DesignLibrary/Table';
import { capitalize, last } from 'lodash';
import useNoodleApi from '@hooks/useNoodleApi';
import * as tsClient from '@tsClient';
import { getDateTimeFormat } from '@/helpers/helper';
import Link from 'next/link';
import { BlackText, GrayText, GreenText, RedText } from '@/components/DesignLibrary/Widget/UserWorkflowActivity/Events/ColoredText';
import { ACTIVITY_ACTIONS, ACTIVITY_TYPES, ACTIVITY_TYPES_AND_ACTIONS_ROLLUP, ActivityNoteAddedData } from '@/typings/api-models';
import Filter from '@/components/Filter';
import Header from '@/components/DesignLibrary/Header';
import ProgressIndicator from '@/components/ProgressIndicator';
import Spacer from '@/components/Spacer';
import Note from '@components/Icons/Note';
import Users from '@components/Icons/Users';
import Calendar from '@components/Icons/Calendar';
import * as format from '@format';
import AddUserWorkflowNoteModal from '@modals/AddUserWorkflowNoteModal';
import Bluebird from 'bluebird';
import { IDENTIFIERS, getUrl } from '@/helpers/urlsHelper';
import TeamsContext from '@/providers/Teams/TeamsContext';
import { useOpenFormRequestPane } from '@/panes/formRequestHooks';
import Buttons from '@/components/Buttons';
import s from './UserWorkflowActivityThread.module.scss';

const columns = ['type', 'action', 'taken by', 'item', 'date'];

const FILTERS: { value: ACTIVITY_TYPES | 'all'; title: string }[] = [
  {
    title: 'All',
    value: 'all',
  },
  {
    title: 'Message',
    value: ACTIVITY_TYPES.MESSAGE,
  },
  {
    title: 'Terms',
    value: ACTIVITY_TYPES.TERMS,
  },
  {
    title: 'Document request',
    value: ACTIVITY_TYPES.DOCUMENT_REQUEST,
  },
  {
    title: 'Status change',
    value: ACTIVITY_TYPES.STATUS_CHANGE,
  },
  {
    title: 'Payment',
    value: ACTIVITY_TYPES.PAYMENT,
  },
];

const getHumanReadableType = (type: string): string =>
  type
    .split('-')
    .map(word => capitalize(word))
    .join(' ');
const getHumanReadableTypeFromRollUp = (type: string): string =>
  type
    .split('-')
    .slice(0, -1)
    .map(word => capitalize(word))
    .join(' ');
const getHumanReadableAction = (action: string | null, details: Record<string, string>, type: ACTIVITY_TYPES): string => {
  if (!action) {
    return 'N/A';
  }

  if (type === ACTIVITY_TYPES.PAYMENT && details.amount) {
    if (action === 'succeeded') {
      return `$${details.amount} paid`;
    }
    return `$${details.amount} ${action}`;
  }

  return action
    .split('-')
    .map(word => capitalize(word))
    .join(' ');
};
const getHumanReadableActionFromRollUp = (rollup: string): string => capitalize(last(rollup.split('-')));

const getHumanReadableItem = (action: ACTIVITY_ACTIONS, type: ACTIVITY_TYPES | null, details: Record<string, string>): ReactNode => {
  if (type === ACTIVITY_TYPES.STATUS_CHANGE) {
    return <GrayText>N/A</GrayText>;
  }
  if (type === ACTIVITY_TYPES.MESSAGE) {
    return <GrayText>N/A</GrayText>;
  }
  if (type === ACTIVITY_TYPES.DOCUMENT_REQUEST && action === 'uploaded-document') {
    return <>{details.fileName}</>;
  }
  if (type === ACTIVITY_TYPES.DOCUMENT_REQUEST && action !== 'uploaded-document') {
    return <>{details.title}</>;
  }
  if (type === ACTIVITY_TYPES.TERMS) {
    return <>{details.documentName}</>;
  }
  if (type === ACTIVITY_TYPES.FORM_REQUEST) {
    return <>{details.name}</>;
  }
  if (type === ACTIVITY_TYPES.PAYMENT) {
    return <>{details.title}</>;
  }

  return <></>;
};

const getHumanReadableItemFromRollUp = (rollup: ACTIVITY_TYPES_AND_ACTIONS_ROLLUP, details: Record<string, string>): ReactNode => {
  if (
    rollup === ACTIVITY_TYPES_AND_ACTIONS_ROLLUP.USER_WORKFLOW_INITIATED
    || rollup === ACTIVITY_TYPES_AND_ACTIONS_ROLLUP.USER_WORKFLOW_STATUS_CHANGED
  ) {
    return <GrayText>N/A</GrayText>;
  }
  if (rollup === ACTIVITY_TYPES_AND_ACTIONS_ROLLUP.COMMENT_LEFT) {
    return <GrayText>N/A</GrayText>;
  }
  if (rollup === ACTIVITY_TYPES_AND_ACTIONS_ROLLUP.DOCUMENT_UPLOADED) {
    return <>{details.fileName}</>;
  }
  if (
    rollup === ACTIVITY_TYPES_AND_ACTIONS_ROLLUP.DOCUMENT_REQUEST_COMPLETED
    || rollup === ACTIVITY_TYPES_AND_ACTIONS_ROLLUP.DOCUMENT_REQUEST_SUBMITTED_FOR_REVIEW
  ) {
    return <>{details.title}</>;
  }
  if (rollup === ACTIVITY_TYPES_AND_ACTIONS_ROLLUP.TERMS_AGREED) {
    return <>{details.documentName}</>;
  }
  if (
    rollup === ACTIVITY_TYPES_AND_ACTIONS_ROLLUP.PAYMENT_INITIATED
    || rollup === ACTIVITY_TYPES_AND_ACTIONS_ROLLUP.PAYMENT_SUCCEEDED
    || rollup === ACTIVITY_TYPES_AND_ACTIONS_ROLLUP.PAYMENT_FAILED
  ) {
    return <>{details.title}</>;
  }
  if (rollup === ACTIVITY_TYPES_AND_ACTIONS_ROLLUP.FORM_REQUEST_COMPLETED) {
    return <>{details.name}</>;
  }

  return <GrayText>N/A</GrayText>;
};

const actionCell: React.FC<{
  action: ACTIVITY_ACTIONS;
  type: ACTIVITY_TYPES | null;
  details: Record<string, string>;
  rollup: ACTIVITY_TYPES_AND_ACTIONS_ROLLUP;
}> = ({ action, type, details, rollup }) => {
  const actionText = type ? getHumanReadableAction(action, details, type) : getHumanReadableActionFromRollUp(rollup);

  if (type) {
    if (type === ACTIVITY_TYPES.MESSAGE) {
      return <GrayText>{actionText}</GrayText>;
    }
    if (type === ACTIVITY_TYPES.PAYMENT && action === 'succeeded') {
      return <GreenText>{actionText}</GreenText>;
    }
    if (type === ACTIVITY_TYPES.PAYMENT && action === 'failed') {
      return <RedText>{actionText}</RedText>;
    }
  } else {
    if (rollup === ACTIVITY_TYPES_AND_ACTIONS_ROLLUP.COMMENT_LEFT) {
      return <GrayText>{actionText}</GrayText>;
    }
    if (rollup === ACTIVITY_TYPES_AND_ACTIONS_ROLLUP.PAYMENT_SUCCEEDED) {
      return <GreenText>{actionText}</GreenText>;
    }
    if (rollup === ACTIVITY_TYPES_AND_ACTIONS_ROLLUP.PAYMENT_FAILED) {
      return <RedText>{actionText}</RedText>;
    }
  }

  return <BlackText>{actionText}</BlackText>;
};

type Item = {
  action: {
    action: ACTIVITY_ACTIONS;
    activityType: ACTIVITY_TYPES_AND_ACTIONS_ROLLUP;
    type: ACTIVITY_TYPES | null;
  };
  details: unknown;
  id: string;
  time: string;
  user: {
    name: string | null;
    id: string | null;
  };
};

const ItemRow: FC<{ item: Item }> = ({ item }) => {
  const { currentTeamOwner } = useContext(TeamsContext);
  const openFormRequestPane = useOpenFormRequestPane();
  return (
    <TableRow key={item.id} state={item.action.type === ACTIVITY_TYPES.CASE_NOTE ? 'note' : undefined}>
      {columns.map(c => {
        switch (c) {
        case 'type':
          return (
            <TD key={c} compact>
              <div className={s.typeColumn}>
                {item.action.type === ACTIVITY_TYPES.CASE_NOTE && <Note weight="fill" size={16} color="var(--color-gray-75)" />}
                {item.action.type ? getHumanReadableType(item.action.type) : getHumanReadableTypeFromRollUp(item.action.activityType)}
              </div>
            </TD>
          );
        case 'action':
          return (
            <TD key={c}>
              <p className="caption">
                {actionCell({
                  action: item.action.action,
                  details: item.details as Record<string, string>,
                  rollup: item.action.activityType,
                  type: item.action.type,
                })}
              </p>
            </TD>
          );
        case 'taken by':
          return (
            <TD key={c}>
              <p className="caption">
                {currentTeamOwner?.person.id === item.user.id
                  ? (
                    <>{item.user.name}</>
                  )
                  : (
                    <Link href={getUrl(IDENTIFIERS.DASHBOARD_MEMBER, { id: item.user.id || '' })} className="caption" target="_blank">
                      {item.user.name}
                    </Link>
                  )}
              </p>
            </TD>
          );
        case 'item':
          return (
            <TD key={c}>
              {item.action.type !== ACTIVITY_TYPES.CASE_NOTE && (
                <>
                  {item.action.type === ACTIVITY_TYPES.FORM_REQUEST
                    ? <Buttons
                      isWrapper
                      style={{ color: 'var(--color-primary)' }}
                      onClick={() => openFormRequestPane({ formRequestId: (item.details as Record<string, string>).formRequestId })}
                    >
                      <p className="caption">
                        {getHumanReadableItem(item.action.action, item.action.type, item.details as Record<string, string>)
                            || getHumanReadableItemFromRollUp(item.action.activityType, item.details as Record<string, string>)}
                      </p>
                    </Buttons>
                    : <p className="caption">
                      {getHumanReadableItem(item.action.action, item.action.type, item.details as Record<string, string>)
                          || getHumanReadableItemFromRollUp(item.action.activityType, item.details as Record<string, string>)}
                    </p>}
                </>
              )}
              {item.action.type === ACTIVITY_TYPES.CASE_NOTE && (
                <div style={{ width: '100%' }}>
                  <p className="caption">{(item.details as ActivityNoteAddedData).text}</p>
                  <div>
                    {(item.details as ActivityNoteAddedData).metadata?.contactType && (
                      <div className={s.metadata}>
                        <Users size={16} />
                        <p className="caption">Contacted via {(item.details as ActivityNoteAddedData).metadata?.contactType}</p>
                      </div>
                    )}
                    {(item.details as ActivityNoteAddedData).metadata?.time && (item.details as ActivityNoteAddedData).metadata?.date && (
                      <div className={s.metadata}>
                        <Calendar size={16} />
                        <p className="caption">
                          {format.datetime.abbreviated((item.details as ActivityNoteAddedData).metadata?.date as string)},{' '}
                          {(item.details as ActivityNoteAddedData).metadata?.time}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </TD>
          );
        case 'date':
          return <TD key={c} label={getDateTimeFormat(item.time)} compact />;
        default:
          return <TD key={c} label="—" compact />;
        }
      })}
    </TableRow>
  );
};

const MemoizedItemRow = memo(ItemRow);

const UserWorkflowActivityThread: FC<{ userWorkflowId: string }> = ({ userWorkflowId }) => {
  const [search, setSearch] = useState<{
    searchBy: string;
    startDate: string | null;
    endDate: string | null;
  }>({
    endDate: null,
    searchBy: '',
    startDate: null,
  });
  const [isNoteModalOpen, setIsNoteModalOpen] = useState(false);
  const [selectedFilterIndex, setSelectedFilterIndex] = useState(0);
  const { data, fetchingState, getData: getUserWorkflowActivityById } = useNoodleApi(tsClient.workflows.getUserWorkflowActivityById);

  const { data: userWorkflowData, getData: getUserWorkflowFn } = useNoodleApi(tsClient.workflows.getUserWorkflowById, { toastOnError: true });

  const { data: collaborators, getData: getCollaboratorsFn } = useNoodleApi(tsClient.workflows.getUserWorkflowCollaborators, { toastOnError: true });

  useEffect(() => {
    if (userWorkflowId) {
      getUserWorkflowFn({
        userWorkflowId,
      });
      getCollaboratorsFn({ userWorkflowId });
    }
  }, [userWorkflowId, getUserWorkflowFn, getCollaboratorsFn]);

  useEffect(() => {
    if (userWorkflowId) {
      getUserWorkflowActivityById({
        endDate: search.endDate || undefined,
        filter: FILTERS[selectedFilterIndex].value,
        page: 0,
        perPage: 1000,
        search: search.searchBy,
        startDate: search.startDate || undefined,
        userWorkflowId,
      });
    }
  }, [userWorkflowId, getUserWorkflowActivityById, selectedFilterIndex, search]);

  useEffect(() => {
    if (userWorkflowId) {
      getUserWorkflowFn({ userWorkflowId });
    }
  }, [userWorkflowId, getUserWorkflowFn]);

  const widgetUserWorkflowActivityItems = useMemo(
    () =>
      data
        ? data.items.map(({ action, activityType, details, createdAt, personName, personId, id, type }) => ({
          action: {
            action,
            activityType,
            type,
          },
          details,
          id,
          time: createdAt,
          user: {
            id: personId,
            name: personName,
          },
        }))
        : [],
    [data],
  );

  if (!userWorkflowData) {
    return (
      <>
        <Spacer size={40} />
        <ProgressIndicator isCentered />
      </>
    );
  }

  return (
    <>
      <Header
        title="Workflow Activity"
        description={`${userWorkflowData.person.name} · ${userWorkflowData.workflow.name}`}
        actions={[
          {
            icon: Note,
            label: 'Add case note',
            onClick: () => setIsNoteModalOpen(true),
          },
        ]}
      />
      <Filter
        values={search}
        setValues={setSearch}
        filters={FILTERS}
        selectedFilterIndex={selectedFilterIndex}
        setSelectedFilterIndex={setSelectedFilterIndex}
        resetData={() => {}}
        displayDatePickers={false}
        displaySearch={false}
      />
      <Table>
        <THead>
          <TableRow>
            {columns.map(c => (
              <TH key={c} label={capitalize(c)} />
            ))}
          </TableRow>
        </THead>
        {fetchingState.isFetched && (
          <TBody>
            {widgetUserWorkflowActivityItems.map(item => (
              <MemoizedItemRow key={item.id} item={item} />
            ))}
          </TBody>
        )}
      </Table>
      {fetchingState.isFetching && (
        <>
          <Spacer size={40} />
          <ProgressIndicator isCentered />
        </>
      )}
      {isNoteModalOpen && (
        <AddUserWorkflowNoteModal
          collaborators={collaborators || []}
          userWorkflowId={userWorkflowId}
          onClose={() => setIsNoteModalOpen(false)}
          onAddComment={async () => {
            // Need to delay, since activity is added on event listener
            await Bluebird.delay(250);
            await getUserWorkflowActivityById({
              endDate: search.endDate || undefined,
              filter: FILTERS[selectedFilterIndex].value,
              page: 0,
              perPage: 1000,
              search: search.searchBy,
              startDate: search.startDate || undefined,
              userWorkflowId,
            });
          }}
        />
      )}
    </>
  );
};

export default UserWorkflowActivityThread;
