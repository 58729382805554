import React, { FC, useEffect, useState } from 'react';

import useLocalCurrency from '@hooks/useLocalCurrency';
import requestWithCatch from '@helpers/requestWithCatch';
import * as ApiModels from '@typings/api-models';
import { convertCurrency } from '@tsClient';
import * as format from '@format';
import ProgressIndicator from '@components/ProgressIndicator';
import s from './LocalCurrencyConversion.module.scss';

type Props = {
  price: number;
  localCurrency?: string | null;
  currency: string;
  interval?: ApiModels.Interval | null;
  roundUp?: boolean;
  noStyles?: boolean;
  progressConfig?: {
    size?: number;
    isInline?: boolean;
  };
};

const convertCurrencyWrapped = requestWithCatch(convertCurrency);

const LocalCurrencyConversion: FC<Props> = ({ price, localCurrency: specifiedCurrency, currency, interval, roundUp, noStyles, progressConfig }) => {
  const ipCurrency = useLocalCurrency();
  const [foreignAmount, setForeignAmount] = useState<number | null>(null);
  const [foreignCurrency, setForeignCurrency] = useState<string | null>(null);
  const [isFetching, setIsFetching] = useState<boolean>(false);

  const localCurrency = specifiedCurrency || ipCurrency;
  const intervalString = format.price.interval(interval);

  useEffect(() => {
    const getAmount = async (): Promise<void> => {
      if (currency && localCurrency && localCurrency !== currency) {
        setIsFetching(true);
        const { data: amount } = await convertCurrencyWrapped({ amount: price, from: currency, to: localCurrency });
        if (amount !== null) {
          setForeignAmount(amount);
          setForeignCurrency(localCurrency);
        } else {
          setForeignAmount(null);
          setForeignCurrency(null);
        }
        setIsFetching(false);
      }
    };
    getAmount();
  }, [localCurrency, currency, price]);

  const formattedForeignPrice = (foreignCurrency && foreignAmount)
    ? format.price.withCurrency(roundUp ? Math.ceil(foreignAmount) : foreignAmount, foreignCurrency)
    : null;

  if (isFetching) {
    return <ProgressIndicator size={progressConfig?.size} isInline={progressConfig?.isInline} />;
  }

  if (noStyles) {
    return <>{
      currency?.toLowerCase() === localCurrency?.toLowerCase()
        ? format.price.withCurrency(roundUp ? Math.ceil(price) : price, localCurrency)
        : `${format.price.withCurrency(roundUp ? Math.ceil(price) : price, currency)}${formattedForeignPrice ? ` (approx. ${formattedForeignPrice})` : ''}`
    }</>;
  }

  return (
    <p className={s['local-currency']}>
      {!isFetching && formattedForeignPrice && `Approx. ${formattedForeignPrice}`}
      {formattedForeignPrice && intervalString}
    </p>
  );
};

export default LocalCurrencyConversion;
