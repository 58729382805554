import React, { useEffect, useRef, useCallback } from 'react';

import Portal from 'components/Portal';
import OutsideClick from 'components/OutsideClick';
import VirtualScroll from 'components/VirtualScroll';

import DropDownItem from './DropDownItem';

import s from './DropDown.module.scss';

const DropDown = ({
  id,
  CMP = DropDownItem,
  value = undefined,
  menuItems,
  itemHeight,
  searchText = '',
  isTopFixed = false,
  isCentered = false,
  isWidthFixed = false,
  isBottomFixed = false,
  repositionOnscroll = false,
  menuWrapperEl,
  handleClose,
  withCloseOnSelect,
  hasActiveByDefault = false,
}) => {
  const menuScroll = useRef();
  const menuDropdown = useRef();

  const getMinHeight = useCallback(() => {
    const fullSizeItems = menuItems.filter(
      ({ isDivider }) => !isDivider,
    ).length;
    const separators = menuItems.length - fullSizeItems;
    const itemsMinHeight = 16 + fullSizeItems * itemHeight + separators * 9;
    if (itemsMinHeight > 320) {
      return 320;
    }
    return itemsMinHeight;
  }, [itemHeight, menuItems]);

  const getMenuPosition = useCallback(() => {
    if (!menuDropdown?.current) {
      return;
    }
    const ww = window.innerWidth;
    const wh = window.innerHeight;
    const button = menuWrapperEl.getBoundingClientRect();
    const {
      height, top, width, x, y,
    } = button;
    const rect = menuDropdown.current.getBoundingClientRect();
    const { width: menuWidth, height: menuHeight } = rect;
    const minHeight = getMinHeight();
    const style = {
      left: '',
      top: '',
    };
    if (isCentered) {
      style.left = `${x - (menuWidth / 2 - width / 2)}px`;
    } else if (ww - 20 - x > menuWidth) {
      style.left = `${x + 1}px`;
    } else {
      style.left = `${x - (menuWidth - width)}px`;
    }
    if (isBottomFixed) {
      style.top = `${y + height}px`;
      const reservedHeight = wh - (height + top);
      if (minHeight > reservedHeight) {
        const newMenuHeight = reservedHeight > 150 ? reservedHeight : 200;
        menuScroll.current.style.height = `${newMenuHeight}px`;
        menuScroll.current.style.minHeight = `${newMenuHeight}px`;
      }
    } else if (isTopFixed) {
      style.top = `${y - menuHeight}px`;
      const reservedHeight = wh - (height + top);
      if (minHeight > reservedHeight) {
        const newMenuHeight = reservedHeight > 150 ? reservedHeight : 200;
        menuScroll.current.style.height = `${newMenuHeight}px`;
        menuScroll.current.style.minHeight = `${newMenuHeight}px`;
      }
    } else {
      menuScroll.current.style.minHeight = `${minHeight}px`;
      if (wh - (height + top) > menuHeight) {
        style.top = `${y + height}px`;
      } else {
        style.top = `${y - menuHeight}px`;
      }
    }

    if (isWidthFixed) {
      menuScroll.current.style.width = `${width}px`;
      menuScroll.current.style.maxWidth = `${width}px`;
    }
    menuDropdown.current.style.left = style.left;
    menuDropdown.current.style.top = style.top;
    // setTimeout(() => {
    menuDropdown.current.style.opacity = '1';
    menuDropdown.current.style.minHeight = `${minHeight}px`;
    // }, 50);
  }, [getMinHeight, isBottomFixed, isCentered, isTopFixed, isWidthFixed, menuWrapperEl]);

  const scrollHandler = useCallback(() => {
    if (!repositionOnscroll) {
      handleClose();
    } else if (menuDropdown && menuWrapperEl) {
      getMenuPosition(0);
    }
  }, [repositionOnscroll, menuWrapperEl, menuDropdown, getMenuPosition, handleClose]);

  useEffect(() => {
    document.addEventListener('scroll', scrollHandler);
    getMenuPosition(50);
    return () => {
      document.removeEventListener('scroll', scrollHandler);
    };
  }, [scrollHandler, getMenuPosition]);

  useEffect(() => {
    getMenuPosition();
  }, [menuItems, getMenuPosition]);

  const isItemSelected = (itemValue, newValue) => {
    if (typeof newValue === 'string') {
      return itemValue === newValue;
    }
    if (Array.isArray(newValue)) {
      return newValue.includes(itemValue);
    }
    return false;
  };

  const handleClick = (clickFn) => (e) => {
    if (withCloseOnSelect) {
      handleClose(e);
    }
    clickFn?.(e);
  };

  return (
    <Portal>
      <div
        ref={menuDropdown}
        className={s['drop-down__wrapper']}
        style={{ opacity: 0, zIndex: 99999 }}
      >
        <OutsideClick isWithoutTransition={true} onOutsideClick={handleClose}>
          <div ref={menuScroll} className={s['drop-down__container']}>
            {!!menuItems.length && (
              <VirtualScroll
                id={id}
                itemHeight={itemHeight}
                items={menuItems.map((i, index) => ({ ...i, index }))}
                value={value}
                hasActiveByDefault={hasActiveByDefault}
                handleClick={handleClick}
                handleClose={handleClose}
                isItemSelected={isItemSelected}
                CMP={CMP || DropDownItem}
              />
            )}
            {!menuItems.length && (
              <small className={s['drop-down__nothing-to-display']}>
                {searchText
                  ? 'No items match your criteria'
                  : 'Nothing to display'}
              </small>
            )}
          </div>
        </OutsideClick>
      </div>
    </Portal>
  );
};

export default DropDown;
