import { useRouter } from 'next/router';
import Link from '@components/CustomLink';
import BackButton from '@components/BackButton';
import { getUrl, IDENTIFIERS } from '@helpers/urlsHelper';
import s from '@styles/errorPage.module.scss';

const DONT_RETURN_TO_PATHS = [IDENTIFIERS.LOGOUT, IDENTIFIERS.CREATOR_SIGN_IN, IDENTIFIERS.LOGIN, IDENTIFIERS.PAGE_401].map(identifier =>
  getUrl(identifier),
);

const DocumentError: React.FC<{ message: string }> = ({ message }) => {
  const router = useRouter();
  const returnTo = router.asPath;
  const returnToQuery = returnTo && !DONT_RETURN_TO_PATHS.includes(returnTo) ? { returnTo } : {};

  return (
    <main className={s.errorpage__wrapper}>
      <BackButton />
      <title>{message}</title>
      <h1 className="heading-md">{message}</h1>
      {(message === 'Not Found' && <p className="body-sm">Sorry, we couldn&apos;t find that document.</p>)
        || (message === 'Forbidden' && <p className="body-sm">Sorry, it looks like you don&apos;t have access to this document.</p>)
        || null}
      {message === 'Forbidden' && <p className="body-sm">
        <Link to={getUrl(IDENTIFIERS.LOGOUT, returnToQuery)} prefetch={false}>
          Change Account
        </Link>
      </p>}
    </main>
  );
};

export default DocumentError;
