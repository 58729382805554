import React, { FC, useContext, useEffect, useState } from 'react';
import { nanoid } from 'nanoid';
import InputField from '@components/FormFields/InputField/InputField';
import PlusCircle from '@components/Icons/PlusCircle';
import CButton from '@components/Buttons';
import useNoodleApi from '@hooks/useNoodleApi';
import * as tsClient from '@tsClient';
import teamsContext from '@providers/Teams/TeamsContext';
import Spacer from '@components/Spacer';
import DocumentFileCard from '@components/Document/DocumentFileCard';
import { DocumentRequest, DocumentRequestFile } from '@typings/document-api-models';
import OrderableItem from '@components/OrderableItem';
import { AnimatePresence, LayoutGroup } from 'framer-motion';
import AttachmentSettings from '@components/DesignLibrary/AttachmentSettings/AttachmentSettings';
import Button from '@components/DesignLibrary/Button';
import Header from '@components/DesignLibrary/Header';
import DocumentForm, { Document } from '@components/DocumentForm';
import AssignSelector from '@components/AssignSelector';
import { getCreatorTeam } from '@tsClient/creators';
import UserCirclePlus from '@components/Icons/UserCirclePlus';
import { ListAndDetailsSubLayoutContext } from '@layouts/ListAndDetailsSubLayout';
import CheckBox from '@components/DesignLibrary/Atoms/CheckBox';
import { useRouter } from 'next/router';
import PageContext from '@components/DesignLibrary/PageContext';
import { getUrl, IDENTIFIERS } from '@helpers/urlsHelper';
import TrashSimple from '@components/Icons/TrashSimple';
import ProgressIndicator from '@components/ProgressIndicator';
import { Files, Pen } from '@phosphor-icons/react';
import s from './DocumentRequestForm.module.scss';

type DocumentRequestType = Pick<
  DocumentRequest,
  'id' | 'title' | 'isFollowupEnabled' | 'isForCreator'
> & {
  files: Pick<DocumentRequestFile, 'id' | 'fileName' | 'fileDescription' | 'isRequired' | 'order' | 'isForYourEyesOnly'>[];
  reviewerIds?: string[];
};

type Props = {
  onSubmit?: (updatedDocument: DocumentRequestType | null) => Promise<void>;
  documentRequest?: DocumentRequestType | null;
};

const sortByOrder = (a: { order: number }, b: { order: number }): number => a.order - b.order;

const PAGE = 1;
const PAGE_SIZE = 1000;

const DocumentRequestForm: FC<Props> = ({ onSubmit, documentRequest }) => {
  const [documents, setDocuments] = useState<Document[]>(
    documentRequest
      ? documentRequest.files
        .map((f, index) => ({
          description: f.fileDescription || '',
          id: f.id,
          isCollapsed: true,
          isForYourEyesOnly: f.isForYourEyesOnly,
          isRequired: f.isRequired,
          order: f.order ?? index + 1,
          title: f.fileName,
        }))
        .sort(sortByOrder)
      : [
        {
          description: '',
          id: nanoid(),
          isCollapsed: false,
          isForYourEyesOnly: false,
          isRequired: true,
          order: 1,
          title: '',
        },
      ],
  );
  const { reloadListView } = useContext(ListAndDetailsSubLayoutContext);
  const router = useRouter();

  const [isArchiving, setIsArchiving] = useState(false);
  const [isFollowupEnabled, setFollowupEnabled] = useState(documentRequest?.isFollowupEnabled || false);
  const [isForCreator, setIsForCreator] = useState(documentRequest?.isForCreator || false);
  const [isReviewerSelectOpen, setIsReviewerSelectOpen] = useState(false);
  const [currentSelectedReviewers, setCurrentlySelectedReviewers] = useState<{ key: string; label: string }[]>([]);
  const [title, setTitle] = useState(documentRequest?.title || '');

  const [isSaving, setIsSaving] = useState(false);
  const { creatorId } = useContext(teamsContext);

  const { getData: archiveDocumentRequestFn } = useNoodleApi(tsClient.documentRequests.archiveDocumentRequest);
  const { getData: createDocumentRequestFn } = useNoodleApi(tsClient.documentRequests.createDocumentRequest);
  const { getData: updateDocumentRequestFn } = useNoodleApi(tsClient.documentRequests.updateDocumentRequestTemplate);
  const {
    data: workflows,
    getData: getWorkflowsFn,
    fetchingState: { isFetching: isFetchingWorkflows },
  } = useNoodleApi(tsClient.workflows.getWorkflowsFromBuildingBlock);
  const { data: creatorTeam, getData: getCreatorTeamFn } = useNoodleApi(getCreatorTeam);

  useEffect(() => {
    if (documentRequest) {
      getWorkflowsFn({ page: 1, perPage: 1000, referenceId: documentRequest.id });
    }
  }, [getWorkflowsFn, documentRequest]);

  useEffect(() => {
    const fetchCreatorTeam = async (): Promise<void> => {
      if (creatorId) {
        await getCreatorTeamFn({
          creatorId,
          page: PAGE,
          perPage: PAGE_SIZE,
        });
      }
    };
    fetchCreatorTeam();
  }, [creatorId, getCreatorTeamFn]);

  useEffect(() => {
    if (documentRequest && documentRequest.reviewerIds && creatorTeam) {
      const creatorTeamReviewers = creatorTeam.items
        .filter((member) => documentRequest.reviewerIds?.includes(member.id))
        .map((member) => ({
          key: member.id,
          label: member.name || '',
        }));
      setCurrentlySelectedReviewers(creatorTeamReviewers);
    }
  }, [creatorTeam, documentRequest]);

  const addEmptyDocument = (): void => {
    setDocuments(prev => [
      ...prev,
      {
        description: '',
        id: nanoid(),
        isCollapsed: false,
        isForYourEyesOnly: false,
        isRequired: true,
        order: prev.length + 1,
        title: '',
      },
    ]);
  };

  const removeDocument = (index: number): void => {
    setDocuments(prev => prev.filter((_, i) => i !== index));
  };
  const handleSave = async (): Promise<void> => {
    if (creatorId) {
      setIsSaving(true);
      let newDocumentRequest: DocumentRequestType | null = null;
      if (documentRequest) {
        const response = await updateDocumentRequestFn({
          id: documentRequest.id,
          updates: {
            files: documents.map(d => ({
              fileDescription: d.description,
              fileName: d.title,
              id: d.id,
              isForYourEyesOnly: d.isForYourEyesOnly,
              isRequired: d.isRequired,
              order: d.order,
            })),
            isFollowupEnabled,
            isForCreator,
            reviewerIds: currentSelectedReviewers.map((reviewer) => reviewer.key),
            title,
          },
        });
        newDocumentRequest = response.data || null;
      } else {
        const response = await createDocumentRequestFn({
          creatorId,
          files: documents,
          isFollowupEnabled,
          isForCreator,
          title,
        });
        newDocumentRequest = response.data || null;
      }
      if (onSubmit) {
        await onSubmit(newDocumentRequest);
      }
      reloadListView();
      setIsSaving(false);
    }
  };

  const handleChangeReviewers = (newPeople: { key: string; label: string }[]): void => {
    setCurrentlySelectedReviewers(newPeople);
  };

  const handleChange = (documentId: string) =>
    (key: string, value: string | boolean): void => {
      setDocuments(prev =>
        prev.map(document => {
          if (documentId === document.id) {
            return {
              ...document,
              [key]: value,
            };
          }
          return document;
        }),
      );
    };

  const handleRankChange = (documentId: string, newRank: number): void => {
    setDocuments(prev => {
      const movedDocument = prev.find(d => d.id === documentId);
      if (movedDocument) {
        const newOrder: typeof prev = [];
        let nextRank = 1;
        for (let i = 0; i < prev.length; i += 1) {
          const thisDocument = prev[i];
          if (movedDocument.id !== thisDocument.id) {
            if (newRank === nextRank) {
              newOrder.push({
                ...movedDocument,
                order: nextRank,
              });
              nextRank += 1;
            }

            newOrder.push({
              ...thisDocument,
              order: nextRank,
            });
            nextRank += 1;
          }
        }

        if (newRank === nextRank) {
          newOrder.push({
            ...movedDocument,
            order: nextRank,
          });
          nextRank += 1;
        }

        return newOrder;
      }
      return prev;
    });
  };

  const areDocumentsComplete = (): boolean => {
    for (let i = 0; i < documents.length; i += 1) {
      if (!documents[i].title) {
        return false;
      }
    }
    return true;
  };

  const formatReviewers = (assignedReviewers: { key: string; label: string }[]): string => {
    if (assignedReviewers.length === 0) {
      return 'Assign reviewers from your team';
    }
    let returnString = '';
    assignedReviewers.forEach((person, index) => {
      returnString = returnString.concat(`${person.label}${index === assignedReviewers.length - 1 ? '' : ', '}`);
    });
    return returnString;
  };

  const archiveDocumentRequest = async (): Promise<void> => {
    if (documentRequest) {
      setIsArchiving(true);
      await archiveDocumentRequestFn({ id: documentRequest.id });
      await reloadListView();
      await router.push(getUrl(IDENTIFIERS.DASHBOARD_DOCUMENT_REQUEST_TEMPLATES));
      setIsArchiving(false);
    }
  };

  return (
    <>
      {documentRequest
        ? (
          <>
            <Header
              title={title}
              noDivider
              secondaryActions={[
                {
                  icon: TrashSimple,
                  isDestructive: true,
                  isFetching: isArchiving,
                  label: 'Archive',
                  onClick: archiveDocumentRequest,
                },
              ]}
            />
            <div className={s.context}>
              <PageContext
                collapsible
                label={isFetchingWorkflows ? 'workflows' : `Used in ${workflows?.items.length} workflow${workflows?.items && workflows?.items.length === 1 ? '' : 's'}`}
                items={workflows?.items?.map?.((workflow) => ({
                  href: `${router.asPath.split('?')[0]}?paneWorkflowSlug=${workflow.slug}${router.asPath.split('?')[1] ? `&${router.asPath.split('?')[1]}` : ''}`,
                  id: workflow.id,
                  value: workflow.name,
                })) || []}
                isFetching={isFetchingWorkflows}
              />
            </div>
          </>
        )
        : <Spacer size={8} />}
      {isArchiving && (
        <>
          <Spacer size={16} />
          <ProgressIndicator isCentered />
        </>
      )}
      <div className={s.padding}>
        <InputField id="title" name="title" values={{ title }} onChange={setTitle} label="Title of document request" hasFixedHeight />
      </div>
      <AttachmentSettings
        isFollowupEnabled={isFollowupEnabled}
        setIsFollowupEnabled={setFollowupEnabled}
      />
      <CheckBox
        className={s.box}
        id='isForCreator'
        label={'For creator to upload'}
        isChecked={isForCreator}
        onChange={setIsForCreator}
      />
      <Header title="Reviewers" hierarchy="h3" Icon={Pen} />
      <div className={s.reviewers}>
        <div className={s.reviewers_content}>
          <p className='body-sm'>{formatReviewers(currentSelectedReviewers)}</p >
          <Button variant="secondary" size="sm" onClick={() => setIsReviewerSelectOpen(!isReviewerSelectOpen)} className={s.reviewers_button}>
            <UserCirclePlus size={16} weight="fill" />
            {currentSelectedReviewers.length > 0 ? 'Update reviewers' : 'Add reviewers'}
          </Button>
        </div>
        {isReviewerSelectOpen && creatorTeam && (
          <div className={s.selector}>
            <AssignSelector
              onChange={handleChangeReviewers}
              items={creatorTeam.items.map(reviewer => ({
                isChecked: currentSelectedReviewers.some(item => item.key === reviewer.id),
                key: reviewer.id,
                label: reviewer.name || '',
              }))}
            />
          </div>
        )}
      </div>
      <Header title="Documents" hierarchy="h3" Icon={Files} />
      <div className={s.padding}>
        <LayoutGroup>
          <AnimatePresence>
            {documents.map((document, index) => (
              <OrderableItem
                id={document.id}
                key={document.id}
                rank={document.order}
                min={1}
                max={documents.length}
                onChangeRank={handleRankChange}
                onRemove={documents.length > 1 ? () => removeDocument(index) : undefined}
              >
                {document.isCollapsed
                  ? (
                    <CButton isFullWidth isWrapper onClick={() => handleChange(document.id)('isCollapsed', false)}>
                      <DocumentFileCard
                        fileDescription={document.description}
                        fileName={document.title}
                        isEdit
                        isRequired={document.isRequired}
                      />
                    </CButton>
                  )
                  : (
                    <DocumentForm document={document} handleChange={handleChange(document.id)} />
                  )}
                <Spacer size={24} />
              </OrderableItem>
            ))}
          </AnimatePresence>
        </LayoutGroup>
        <Spacer size={8} />
        <Button variant="secondary" size="sm" onClick={addEmptyDocument} className={s.reviewers_button}>
          <PlusCircle weight="fill" size={16} />
          Add document
        </Button>
        <Spacer size={16} />
        <Button variant="primary" size="md" onClick={handleSave} loading={isSaving} disabled={!creatorId || !title || !areDocumentsComplete()}>
          Save
        </Button>
      </div>
    </>
  );
};

export default DocumentRequestForm;
