import Buttons from '@components/Buttons/Buttons';
import { FC } from 'react';
import classNames from 'classnames';
import PencilSimple from '@components/Icons/PencilSimple';
import VideoCamera from '@components/Icons/VideoCamera';
import s from './EditButton.module.scss';

type Props = {
  onClick?: () => void;
  label?: string;
  isPlateContent?: boolean;
  isAvailabiltyButton?: boolean;
  type?: 'video' | 'tags';
  isMock?: boolean;
};

const EditButton: FC<Props> = ({ onClick, label = 'Edit', isPlateContent = false, isAvailabiltyButton = false, isMock, type }) => {
  if (type === 'tags') {
    return (
      <Buttons
        className={s.addTags}
        onClick={e => {
          e.stopPropagation();
          if (onClick) {
            onClick();
          }
        }}
      >
        <div>
          <PencilSimple weight="fill" />
          <span>{label}</span>
        </div>
      </Buttons>
    );
  }

  if (type === 'video') {
    return (
      <Buttons
        className={s[`editButton__full-width`]}
        onClick={e => {
          e.stopPropagation();
          if (onClick) {
            onClick();
          }
        }}
      >
        <VideoCamera weight="fill" />
        <span>{label}</span>
      </Buttons>
    );
  }

  if (isMock) {
    return <div className={s.mockButton}>{label}</div>;
  }

  return (
    <>
      <Buttons
        className={classNames(
          s.editButton,
          { [s['editButton__place-content']]: isPlateContent },
          { [s['editButton__availability-button']]: isAvailabiltyButton },
        )}
        isWrapper
        title="Edit"
        onClick={e => {
          e.stopPropagation();
          if (onClick) {
            onClick();
          }
        }}
      >
        <span>{label}</span>
      </Buttons>
    </>
  );
};

export default EditButton;
