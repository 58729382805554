import documentApiClient, { ApiDefinition } from '@documentsApi';
import buildPath from '@tsClient/utilities/buildPath';

type ThisApi = ApiDefinition['/documents/{documentId}']['PUT'];
type Input = ThisApi['query'] & ThisApi['params'] & {body:ThisApi['body']};
type Output = ThisApi['response'];

const updateDocumentById = async ({ documentId, body }: Input): Promise<Output> => {
  const path = buildPath<ThisApi>('/documents/{documentId}', { documentId });

  const response = await documentApiClient.put(path, body);
  return response.data;
};

export default updateDocumentById;