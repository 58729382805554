import React, { MouseEvent, ReactElement, useContext, useEffect, useState } from 'react';
import { useFeatureFlag } from '@providers/FeatureFlags';
import Buttons from '@components/Buttons';
import useNoodleApi from '@hooks/useNoodleApi';
import { updateWorkflow } from '@tsClient';
import Spacer from '@components/Spacer';
import FieldLabel from '@components/FormFields/FieldLabel';
import TextInput from '@components/FormFields/TextInput';
import * as ApiModels from '@typings/api-models';
import { AssignToPersonType, CONTEXT_TYPE } from '@typings/api-models';
import TriggerCard from '@components/TriggerCard';
import ActionCard from '@components/ActionCard';
import PlusCircle from '@components/Icons/PlusCircle';
import Gear from '@components/Icons/Gear';
import HandCoins from '@components/Icons/HandCoins';
import BracketsCurly from '@components/Icons/BracketsCurly';
import Users from '@components/Icons/Users';
import updateWorkflowStep from '@tsClient/workflows/updateWorkflowStep';
import createWorkflowStep from '@tsClient/workflows/createWorkflowStep';
import deleteWorkflowStep from '@tsClient/workflows/deleteWorkflowStep';
import updateWorkflowOwners from '@tsClient/workflows/updateWorkflowOwners';
import removeNullish from '@helpers/removeNullish';
import { SendMessageActionData } from '@components/EditSendMessageActionForm/EditSendMessageActionForm';
import ProgressIndicator from '@components/ProgressIndicator';
import CheckBox from '@components/DesignLibrary/Atoms/CheckBox';
import SelectInvoiceTemplate from '@components/SelectInvoiceTemplate';
import { createWorkflowInvoice, deleteWorkflowContext, deleteWorkflowInvoice, updateWorkflowInvoice } from '@tsClient/workflows';
import WorkflowInvoices from '@components/WorkflowInvoices';
import updateWorkflowContext from '@tsClient/workflows/updateWorkflowContext';
import AddContextForm from '@components/AddContextForm';
import CustomSelect from '@components/FormFields/CustomSelect';
import PriceInput from '@components/FormFields/PriceInput';
import Button from '@components/DesignLibrary/Button';
import CollapsibleArea from '@components/CollapsibleArea';
import EditWorkflowStepModal from '@modals/EditWorkflowStepModal';
import dashboardContext from '@layouts/DashboardLayout/DashboardContext';
import { isAttorney } from '@helpers/businessCategories';
import AddWorkflowThreadModal from '@modals/AddWorkflowThreadModal';
import EditWorkflowThreadTitleModal from '@modals/EditWorkflowThreadTitleModal';
import classNames from 'classnames';
import AssignCollaboratorsToAttachmentForm from '@components/AssignCollaboratorsToAttachmentForm';
import { DeleteButton } from '@/components/DesignLibrary/Atoms';
import { getCreatorTeam } from '@tsClient/creators';
import s from './EditWorkflowForm.module.scss';
import getAvailableTriggers from './getAvailableTriggers';
import ConditionsWidget from '../DesignLibrary/ConditionsWidget';

type ThisWorkflow =
  & Pick<ApiModels.Workflow,
    | 'id'
    | 'name'
    | 'hasGeneratedPdfs'
    | 'noodleFee'
    | 'estimatedTotalFees'
    | 'sendEmailToCreatorOnCompletion'
    | 'sendEmailToCreatorOnFirstPayment'
    | 'canInitiateAssociatedWorkflows'
    | 'type'
    | 'enableUscisUpdates'
  > & {
    /** @deprecated - use threads + stepMap instead */
    steps: Array<
      & Pick<ApiModels.WorkflowStep, 'id' | 'type'>
      & Parameters<typeof TriggerCard>[0]['trigger']
      & Parameters<typeof ActionCard>[0]['action']
      & Parameters<typeof getAvailableTriggers>[0]['step']
      & Parameters<typeof EditWorkflowStepModal>[0]['currentStep']
    >;
    owners: Array<Pick<ApiModels.WorkflowOwner, 'personId'>>;
    threads: Array<{
      rank: number;
      stepIds: string[];
    }>;
    contexts: Array<Pick<ApiModels.WorkflowContext, 'id' | 'type' | 'label' | 'isTitleField' | 'isOptional' | 'options' | 'slug'> & {
      expression?: ApiModels.Expression | null;
      variables: Array<Pick<ApiModels.WorkflowContextVariable, 'variableName'>>;
    }>;
    workflowInvoices: Array<Pick<ApiModels.WorkflowInvoice, 'id' | 'title'>
      & { expression?: ApiModels.Expression | null }>;
  }
  & Parameters<typeof getAvailableTriggers>[0]['workflow']
  & Parameters<typeof EditWorkflowStepModal>[0]['workflow'];

type Props = {
  workflow: ThisWorkflow;
  initialMessage?: string | null;
  creatorTeamMembers: Pick<ApiModels.TeamMember, 'id' | 'name'>[];
  onSave: () => void;
  refetchWorkflow: () => Promise<void>;
  canEdit?: boolean;
};

const workflowTypeOptions = [
  {
    title: 'Basic',
    value: 'basic',
  },
  {
    title: 'Attorney',
    value: 'attorney-case',
  },
];

const EditWorkflowForm = ({ workflow: initialWorkflow, canEdit = true, creatorTeamMembers, refetchWorkflow, onSave }: Props): ReactElement => {
  const canEditNoodleApplicationFees = useFeatureFlag('can-edit-noodle-application-fees');
  const isUscisEnabled = useFeatureFlag('uscis');
  const [workflow, setWorkflow] = useState<ThisWorkflow>(initialWorkflow);
  const [isSaving, setIsSaving] = useState(false);
  const [addingStepAfterId, setAddingStepAfterId] = useState<string | null>(null);
  const [isAddInvoiceSectionOpen, setIsAddInvoiceSectionOpen] = useState(false);
  const [isAddContextSectionOpen, setAddContextSectionOpen] = useState(false);
  const [isAddThreadModalOpen, setAddThreadModalOpen] = useState(false);
  const [isEditThreadTitleModalOpen, setEditThreadTitleModalOpen] = useState(false);
  const [isEditWorkflowStepModalOpen, setEditWorkflowStepModalOpen] = useState(false);
  const [currentInvoiceTemplateId, setCurrentInvoiceTemplateId] = useState<string | null>(null);
  const [deletingContextId, setDeletingContextId] = useState('');
  const [deletingStep, setDeletingStep] = useState<ThisWorkflow['steps'][number]['id'] | null>(null);
  const [currentStep, setCurrentStep] = useState<ThisWorkflow['steps'][number] | null>(null);
  const [ownerPersonIds, setOwnerPersonIds] = useState<string[]>(initialWorkflow.owners.map((o) => o.personId));
  const { getData: createStepFn } = useNoodleApi(createWorkflowStep);
  const { getData: deleteStepFn } = useNoodleApi(deleteWorkflowStep);
  const { fetchingState: creatingInvoice, getData: createWorkflowInvoiceFn } = useNoodleApi(createWorkflowInvoice);
  const { getData: deleteWorkflowInvoiceFn } = useNoodleApi(deleteWorkflowInvoice);
  const { getData: updateWorkflowInvoiceFn } = useNoodleApi(updateWorkflowInvoice);
  const { getData: updateWorkflowStepFn } = useNoodleApi(updateWorkflowStep, { toastOnError: true });
  const { getData: updateWorkflowFn } = useNoodleApi(updateWorkflow, { toastOnError: true });
  const { getData: updateWorkflowContextFn } = useNoodleApi(updateWorkflowContext, { toastOnError: true });
  const { getData: deleteWorkflowContextFn } = useNoodleApi(deleteWorkflowContext, { toastOnError: true });
  const {
    fetchingState: { isFetching: isAssigningOwners },
    getData: updateWorkflowOwnersFn,
  } = useNoodleApi(updateWorkflowOwners, { toastOnError: true });
  const { fetchingState: { isFetching: isFetchingTeamMembers }, getData: getTeamMembersFn, data: teamMembers } = useNoodleApi(getCreatorTeam);

  const { creator } = useContext(dashboardContext);

  useEffect(() => {
    setWorkflow(initialWorkflow);
  }, [initialWorkflow]);

  useEffect(() => {
    if (creator) {
      getTeamMembersFn({ creatorId: creator.id, page: 1, perPage: 1000 });
    }
  }, [getTeamMembersFn, creator]);

  const handleSave = async (): Promise<void> => {
    setIsSaving(true);
    const updates: Parameters<typeof updateWorkflowFn>[0]['updates'] = {
      canInitiateAssociatedWorkflows: workflow.canInitiateAssociatedWorkflows,
      enableUscisUpdates: workflow.enableUscisUpdates,
      hasGeneratedPdfs: workflow.hasGeneratedPdfs,
      name: workflow.name,
      sendEmailToCreatorOnCompletion: workflow.sendEmailToCreatorOnCompletion,
      sendEmailToCreatorOnFirstPayment: workflow.sendEmailToCreatorOnFirstPayment,
      type: workflow.type,
    };
    if (canEditNoodleApplicationFees) {
      updates.estimatedTotalFees = workflow.estimatedTotalFees;
      updates.noodleFee = workflow.noodleFee;
    }
    await updateWorkflowFn({
      updates,
      workflowId: workflow.id,
    });
    onSave();
    setIsSaving(false);
  };

  const handleAddContext = async (): Promise<void> => {
    await refetchWorkflow();
    setAddContextSectionOpen(false);
  };

  const deleteContext = async (contextId: string): Promise<void> => {
    setDeletingContextId(contextId);
    await deleteWorkflowContextFn({
      contextId,
      workflowId: workflow.id,
    });
    await refetchWorkflow();
    setDeletingContextId('');
  };

  const handleAddInvoice = async (): Promise<void> => {
    if (currentInvoiceTemplateId) {
      await createWorkflowInvoiceFn({
        invoiceTemplateId: currentInvoiceTemplateId,
        workflowId: workflow.id,
      });
      setIsAddInvoiceSectionOpen(false);
      refetchWorkflow();
    }
  };

  const handleDeleteInvoice = async (workflowInvoiceId: string): Promise<void> => {
    await deleteWorkflowInvoiceFn({
      id: workflowInvoiceId,
      workflowId: workflow.id,
    });
    refetchWorkflow();
  };

  const handleChangeName = (newValue: string): void => {
    setWorkflow(prev => ({ ...prev, name: newValue }));
  };

  const handleToggleHasGeneratedPdfs = (newValue: boolean): void => {
    setWorkflow(prev => ({ ...prev, hasGeneratedPdfs: newValue }));
  };

  const handleToggleSendEmailToCreatorOnCompletion = (newValue: boolean): void => {
    setWorkflow(prev => ({ ...prev, sendEmailToCreatorOnCompletion: newValue }));
  };

  const handleToggleEnableUscisUpdates = (newValue: boolean): void => {
    setWorkflow(prev => ({ ...prev, enableUscisUpdates: newValue }));
  };

  const handleToggleSendEmailToCreatorOnFirstPayment = (newValue: boolean): void => {
    setWorkflow(prev => ({ ...prev, sendEmailToCreatorOnFirstPayment: newValue }));
  };

  const handleToggleCanInitiateAssociatedWorkflows = (newValue: boolean): void => {
    setWorkflow(prev => ({ ...prev, canInitiateAssociatedWorkflows: newValue }));
  };

  const handleToggleIsTitleField = async (wfContextId: string, newValue: boolean): Promise<void> => {
    await updateWorkflowContextFn({
      contextId: wfContextId,
      updates: { isTitleField: newValue },
      workflowId: workflow.id,
    });
    refetchWorkflow();
  };

  const handleToggleIsOptional = async (wfContextId: string, newValue: boolean): Promise<void> => {
    await updateWorkflowContextFn({
      contextId: wfContextId,
      updates: { isOptional: newValue },
      workflowId: workflow.id,
    });
    refetchWorkflow();
  };

  const handleChangeType = (newValue: string): void => {
    if (newValue === 'attorney-case' || newValue === 'basic') {
      setWorkflow(prev => ({ ...prev, type: newValue }));
    }
  };

  const handleChangeWorkflowNumberField = (field: 'noodleFee' | 'estimatedTotalFees', newValue: number): void => {
    setWorkflow(prev => ({ ...prev, [field]: newValue }));
  };

  const updateStep: Parameters<typeof TriggerCard>[0]['updateStep'] = async ({ stepId, updates }) => {
    setWorkflow({
      ...workflow,
      steps: workflow.steps.map(step => (step.id === stepId ? { ...step, ...updates } : step)),
    });
    await updateWorkflowStepFn({
      id: workflow.id,
      stepId,
      updates,
    });
  };

  const handleAddSteps = (stepId: string) => async (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (addingStepAfterId) {
      return;
    }
    setAddingStepAfterId(stepId);
    const lastStep = workflow.steps.find(step => step.id === stepId);

    let trigger;
    if (lastStep && lastStep.type === ApiModels.StepType.TRIGGER) {
      trigger = lastStep;
    } else {
      const triggerToCreate = workflow.steps.length === 0
        ? ApiModels.WORKFLOW_TRIGGERS.PRODUCT_PURCHASED
        : ApiModels.WORKFLOW_TRIGGERS.ALL_DEPENDENCIES;
      const { data: newTrigger } = await createStepFn({
        id: workflow.id,
        stepData: {
          data: {},
          dependentOn: [{ id: stepId }],
          eventName: triggerToCreate,
          title: null,
          type: ApiModels.StepType.TRIGGER,
        },
      });
      trigger = newTrigger;
    }
    if (trigger) {
      await createStepFn({
        id: workflow.id,
        stepData: {
          data: {},
          dependentOn: [trigger],
          eventName: ApiModels.WORKFLOW_ACTIONS.SEND_MESSAGE,
          title: null,
          type: ApiModels.StepType.ACTION,
        },
      });
    }
    await refetchWorkflow();
    setAddingStepAfterId(null);
  };

  const handleDeleteStep = (stepId: string) => async (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const confirmation = window.confirm("Are you sure you want to delete this step?");

    if (!confirmation) {
      return;
    }

    setDeletingStep(stepId);
    let isFirstStep = false;
    let title;
    let nextTriggerStepId;

    workflow.threads.forEach((thread) => {
      if(stepId === thread.stepIds[1] && thread.stepIds.length > 2) {
        isFirstStep = true;
        title = workflow.steps.find(step => step.id === thread.stepIds[0])?.title;
        // eslint-disable-next-line prefer-destructuring
        nextTriggerStepId = thread.stepIds[2];
      }
    });

    if(isFirstStep && nextTriggerStepId && title) {
      await updateWorkflowStepFn({
        id: workflow.id,
        stepId: nextTriggerStepId,
        updates: {
          title,
        },
      });
    }

    await deleteStepFn({
      id: workflow.id,
      stepId,
    });

    await refetchWorkflow();
  };

  const getContextTypeLabel = (type: CONTEXT_TYPE): string => {
    if (type === CONTEXT_TYPE.PERSON) {
      return 'Person';
    }
    if (type === CONTEXT_TYPE.ADDITIONAL_INFO) {
      return 'Text';
    }
    if (type === CONTEXT_TYPE.SELECTION) {
      return 'Selection';
    }
    return 'Context';
  };

  const handleOwnerSelect = async (personIds: string[]): Promise<void> => {
    setOwnerPersonIds(personIds);
    await updateWorkflowOwnersFn({
      personIds,
      workflowId: workflow.id,
    });
  };

  const handleUpdateInvoiceConditions = async (expressionId: string, workflowInvoiceId: string): Promise<void> => {
    await updateWorkflowInvoiceFn({
      data: { expressionId },
      id: workflowInvoiceId,
      workflowId: workflow.id,
    });
    refetchWorkflow();
  };

  const handleUpdateContextConditions = async (expressionId: string, contextId: string): Promise<void> => {
    await updateWorkflowContextFn({
      contextId,
      updates: { expressionId },
      workflowId: workflow.id,
    });
    refetchWorkflow();
  };

  return (
    <>
      <div className={s.editWorkflowForm}>
        <CollapsibleArea title="Settings" Icon={{ Component: Gear }} className={s.details}>
          <div className={s.editWorkflowFormHeader}>
            <FieldLabel id="description">
              Workflow name
              <Spacer size={4} />
              <TextInput disabled={!canEdit} placeholder={'Add workflow name'} id="name" value={workflow.name} onChange={handleChangeName} hasFixedHeight={false} />
            </FieldLabel>
            <CheckBox
              isChecked={workflow.hasGeneratedPdfs}
              label={'Needs to generate PDFs'}
              onChange={handleToggleHasGeneratedPdfs}
              className={s.checkBoxRow}
              disabled={!canEdit}
            />
            <CheckBox
              isChecked={workflow.sendEmailToCreatorOnCompletion}
              label={'Send email to team upon completion'}
              onChange={handleToggleSendEmailToCreatorOnCompletion}
              className={s.checkBoxRow}
              disabled={!canEdit}
            />
            <CheckBox
              isChecked={workflow.sendEmailToCreatorOnFirstPayment}
              label={'Send email to team on first payment'}
              onChange={handleToggleSendEmailToCreatorOnFirstPayment}
              className={s.checkBoxRow}
              disabled={!canEdit}
            />
            <CheckBox
              isChecked={workflow.canInitiateAssociatedWorkflows}
              label={'Can initiate associated cases'}
              onChange={handleToggleCanInitiateAssociatedWorkflows}
              className={s.checkBoxRow}
              disabled={!canEdit}
            />
            {isAttorney(creator?.businessCategory) && isUscisEnabled && (
              <CheckBox
                isChecked={workflow.enableUscisUpdates}
                label={'Enable USCIS Updates'}
                onChange={handleToggleEnableUscisUpdates}
                className={s.checkBoxRow}
                disabled={!canEdit}
              />
            )}
            <Spacer size={16} />
            <CustomSelect
              label="Select Workflow Type"
              value={workflow.type}
              onChange={value => handleChangeType(value)}
              id="workflowType"
              options={workflowTypeOptions}
              disabled={!canEdit}
            />
            {canEditNoodleApplicationFees && (
              <>
                <Spacer size={16} />
                <PriceInput
                  currency={ApiModels.Currency.Usd}
                  value={workflow.noodleFee ?? 0}
                  name={'noodleFee'}
                  onChange={newValue => handleChangeWorkflowNumberField('noodleFee', newValue)}
                  label="Noodle Fee"
                  disabled={!canEdit}
                />
              </>
            )}
            {canEditNoodleApplicationFees && (
              <>
                <Spacer size={16} />
                <PriceInput
                  currency={ApiModels.Currency.Usd}
                  value={workflow?.estimatedTotalFees ?? 0}
                  name={'estimatedTotalFees'}
                  onChange={newValue => handleChangeWorkflowNumberField('estimatedTotalFees', newValue)}
                  label="Estimated Total Fees"
                  disabled={!canEdit}
                />
              </>
            )}
            {canEdit && <>
              <Spacer size={16} />
              <Button size="sm" variant="primary" loading={isSaving} onClick={handleSave}>
                Save
              </Button>
            </>}
          </div>
        </CollapsibleArea>
        <CollapsibleArea title="Contexts" Icon={{ Component: BracketsCurly }} className={s.details}>
          <div className={s.contexts}>
            {workflow.contexts.length > 0 && (
              <div>
                <Spacer size={8} />
                <table>
                  <thead>
                    <tr>
                      <th>Label</th>
                      <th>Type</th>
                      <th>Custom terms variable</th>
                      <th>Options</th>
                      <th>Is Title Field</th>
                      <th>Is Optional</th>
                      <th>Conditions</th>
                      {canEdit && <th>&nbsp;</th>}
                    </tr>
                  </thead>
                  <tbody>
                    {workflow.contexts.map(context => (
                      <tr key={context.id}>
                        <td>{context.label}</td>
                        <td>{getContextTypeLabel(context.type)}</td>
                        <td>{context.variables.length > 0 ? context.variables.map(v => v.variableName).join(', ') : '-'}</td>
                        <td>
                          <div className={s.cellIsColumn}>
                            {context.type === CONTEXT_TYPE.SELECTION
                              ? (
                                <>
                                  {context.options.map(option => (
                                    <span key={option.slug} className='caption'>{option.label}</span>
                                  ))}
                                </>
                              )
                              : ( <>N/A</> )}
                          </div>
                        </td>
                        <td>
                          <CheckBox
                            id={context.id}
                            isChecked={context.isTitleField}
                            label={''}
                            onChange={value => {
                              handleToggleIsTitleField(context.id, value);
                            }}
                            size="sm"
                            disabled={!canEdit}
                          />
                        </td>
                        <td>
                          <CheckBox
                            id={context.id}
                            isChecked={context.isOptional}
                            label={''}
                            onChange={value => {
                              handleToggleIsOptional(context.id, value);
                            }}
                            size="sm"
                            disabled={!canEdit}
                          />
                        </td>
                        <td>
                          <div className={s.contextConditions}>
                            <ConditionsWidget
                              conditionalExpression={context.expression || null}
                              options={workflow.contexts.filter((c) => c.id !== context.id).map((otherContext) => ({
                                key: otherContext.slug || otherContext.id,
                                label: otherContext.label,
                              }))}
                              type={'workflow-context'}
                              onOpenModal={() => {}}
                              handleSubmit={(expressionId) => handleUpdateContextConditions(expressionId , context.id)}
                            />
                          </div>
                        </td>
                        {canEdit && <td>
                          <DeleteButton
                            loading={deletingContextId === context.id}
                            onClick={() => deleteContext(context.id)}
                          />
                        </td>}
                      </tr>
                    ))}
                  </tbody>
                </table>
                <Spacer size={12} />
              </div>
            )}
            {canEdit && (
              <Button size="sm" variant="primary" onClick={() => setAddContextSectionOpen(prev => !prev)}>
                {isAddContextSectionOpen ? 'Close' : 'Add Context'}
              </Button>
            )}
            {isAddContextSectionOpen && (
              <>
                <Spacer size={12} />
                <AddContextForm onSubmit={handleAddContext} workflowId={workflow.id} />
              </>
            )}
          </div>
        </CollapsibleArea>
        <CollapsibleArea title="Invoices" Icon={{ Component: HandCoins }} className={s.details}>
          <div className={s.invoices}>
            {workflow.workflowInvoices.length === 0 && !isAddInvoiceSectionOpen && (
              <>
                <span className={'caption'}>No invoices yet</span>
                <Spacer size={8} />
              </>
            )}
            {workflow.workflowInvoices.length > 0 && (
              <div className={s.invoices__list}>
                <WorkflowInvoices
                  handleUpdateConditions={handleUpdateInvoiceConditions}
                  invoices={workflow.workflowInvoices.map(invoice => ({
                    expression: invoice.expression,
                    id: invoice.id,
                    referenceId: invoice.id,
                    title: invoice.title || '',
                  }))}
                  removeInvoice={handleDeleteInvoice}
                  workflowContexts={workflow.contexts}
                />
              </div>
            )}
            <Spacer size={8} />
            {canEdit && (
              <Button size="sm" variant="primary" onClick={() => setIsAddInvoiceSectionOpen(!isAddInvoiceSectionOpen)}>
                {isAddInvoiceSectionOpen ? 'Close' : 'Add Invoice'}
              </Button>
            )}
            <Spacer size={8} />
            {isAddInvoiceSectionOpen && (
              <>
                <Spacer size={12} />
                <SelectInvoiceTemplate
                  onSelect={template => {
                    setCurrentInvoiceTemplateId(template.id);
                  }}
                />
                <Spacer size={8} />
                <Button
                  variant='secondary'
                  fullWidth
                  size='md'
                  onClick={handleAddInvoice}
                  loading={creatingInvoice.isFetching}
                  disabled={!currentInvoiceTemplateId}
                >
                  Add Invoice
                </Button>
                <Spacer size={8} />
              </>
            )}
          </div>
        </CollapsibleArea>
        <CollapsibleArea title="Owners" Icon={{ Component: Users }} className={s.details}>
          {isFetchingTeamMembers
            ? <><ProgressIndicator isCentered /><Spacer /></>
            : (
              <AssignCollaboratorsToAttachmentForm
                collaborators={teamMembers?.items?.map((m) => ({
                  color: m.primaryColour?.hex || null,
                  email: m.email || null,
                  id: m.id,
                  logo: m.image?.url || null,
                  name: m.name || null,
                })) ?? []}
                isSubmitting={isAssigningOwners}
                onSubmit={handleOwnerSelect}
                initialCollaboratorIds={ownerPersonIds}
              />
            )}
        </CollapsibleArea>
        {workflow.threads.map((thread) => (
          <div key={thread.stepIds[0]} className={s.thread}>
            <Buttons isWrapper
              className={s.threadLabel}
              onClick={() => {
                setCurrentStep(workflow.steps.find(step => step.id === thread.stepIds[0]) || null);
                setEditThreadTitleModalOpen(true);
              }}
              disabled={!canEdit}
            >
              {workflow.steps.find(step => step.id === thread.stepIds[0])?.title || 'Untitled Thread'}
            </Buttons>
            {thread.stepIds.map(stepId => workflow.steps.find(step => step.id === stepId)).filter(removeNullish).map((step) => (
              <div key={step.id} className={classNames(s.step, deletingStep === step.id && s.stepDeleting)}>
                {step.type === ApiModels.StepType.TRIGGER
                  ? (
                    <div className={s.t}>
                      <TriggerCard
                        canEdit={canEdit}
                        availableTriggers={getAvailableTriggers({ step, workflow })}
                        trigger={step}
                        updateStep={updateStep}
                        deleteStep={handleDeleteStep}
                        workflowId={workflow.id}
                      />
                    </div >
                  )
                  : (
                    <ActionCard
                      action={step}
                      canEdit={canEdit}
                      onEditClick={() => {
                        setCurrentStep(step);
                        setEditWorkflowStepModalOpen(true);
                      }}
                      onUpdate={refetchWorkflow}
                      actionData={step.data as SendMessageActionData}
                      availableAssignees={[
                        {
                          key: 'customer',
                          label: 'Contact',
                          type: 'customer' as AssignToPersonType,
                        },
                        ...workflow.contexts
                          .filter(c => c.type === CONTEXT_TYPE.PERSON)
                          .map(context => ({
                            key: context.id,
                            label: context.label,
                            type: 'context' as AssignToPersonType,
                          })),
                        ...creatorTeamMembers.map(teamMember => ({
                          key: teamMember.id,
                          label: teamMember.name || '',
                          type: 'team-member' as AssignToPersonType,
                        })),
                      ]}
                      workflowContexts={workflow.contexts.map(context => ({
                        id: context.id,
                        label: context.label,
                        slug: context.slug,
                      }))}
                      workflowId={workflow.id}
                    />
                  )}
                {step.type === ApiModels.StepType.TRIGGER && <div className={s.connector} />}
                {step.type === ApiModels.StepType.ACTION && (
                  <>
                    <Spacer size={16} />
                    {canEdit && <>
                      <Buttons isWrapper isFullWidth onClick={handleAddSteps(step.id)} disabled={addingStepAfterId === step.id}>
                        <div className={s.sendMessage__button}>
                          {addingStepAfterId === step.id ? <ProgressIndicator size={20} /> : <PlusCircle fill="var(--color-gray-100)" size={20} weight="fill" />}
                          <strong className="body-sm-bold">Add Step</strong>
                        </div>
                      </Buttons>
                      <Spacer size={16} />
                    </>}
                  </>
                )}
              </div>
            ))}
          </div>
        ))}
        {canEdit && <>
          <Spacer size="16px" />
          <Buttons isWrapper onClick={() => setAddThreadModalOpen(true)}>
            <div className={s.sendMessage__button}>
              {<PlusCircle fill="var(--color-primary)" size={20} weight="fill" />}
              <strong className="body-sm-bold">Add Thread</strong>
            </div>
          </Buttons>
          <Spacer size={16} />
        </>}
      </div>
      {isEditWorkflowStepModalOpen && currentStep && (
        <EditWorkflowStepModal
          workflow={workflow}
          currentStep={currentStep}
          onSave={async () => {
            await refetchWorkflow();
            setEditWorkflowStepModalOpen(false);
            setCurrentStep(null);
          }}
          onClose={() => {
            setEditWorkflowStepModalOpen(false);
            setCurrentStep(null);
          }}
        />
      )}
      {isAddThreadModalOpen && (
        <AddWorkflowThreadModal
          workflow={{
            id: workflow.id,
            stepsLength: workflow.steps.length,
          }}
          onSubmit={async () => {
            await refetchWorkflow();
          }}
          onClose={() => setAddThreadModalOpen(false)}
        />
      )}
      {isEditThreadTitleModalOpen && currentStep && (
        <EditWorkflowThreadTitleModal
          currentStep={currentStep}
          workflowId={workflow.id}
          onSubmit={async () => {
            await refetchWorkflow();
          }}
          onClose={() => {
            setEditThreadTitleModalOpen(false);
            setCurrentStep(null);
          }}
        />
      )}
    </>
  );
};

export default EditWorkflowForm;
