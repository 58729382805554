import * as ApiModels from '@typings/api-models';
import * as DocumentApiModels from '@typings/document-api-models';
import removeNullish from '@helpers/removeNullish';
import * as format from '@format';
import s from './DocumentsListModal.module.scss';

type Props = {
  compiledDocument: Pick<ApiModels.UserWorkflowDocument,
  | 'referenceType'
  | 'templateReferenceType'
  > & {
    document: Pick<DocumentApiModels.Document, 'fileName' | 'fileSize' > | null;
  }
};

const getDescription = ({ compiledDocument }: {
  compiledDocument: Props['compiledDocument'];
}): string | null => {
  const isEmpty = !compiledDocument.document;

  if (isEmpty) {
    return (
      (compiledDocument.templateReferenceType === 'anvil' && 'Noodle Generated PDF')
      || (
        (compiledDocument.templateReferenceType === 'document-request-file' || compiledDocument.templateReferenceType === 'document-request-user-file')
        && 'Document Request File'
      )
      || (compiledDocument.templateReferenceType === 'manually-added' && 'Manual upload')
      || (compiledDocument.templateReferenceType === 'ai-bot' && 'AI Generated')
      || (compiledDocument.templateReferenceType === 'custom-terms-template' && 'Agreed Terms PDF')
      || null
    );
  }

  return (
    (compiledDocument.referenceType === 'anvil' && 'Noodle Generated PDF')
    || ((compiledDocument.referenceType === 'document-request-file' || compiledDocument.referenceType === 'document-request-file-document') && 'Uploaded via Document Request')
    || (compiledDocument.referenceType === 'direct-document' && 'Direct Document')
    || (compiledDocument.referenceType === 'manually-added' && 'Manually added')
    || (compiledDocument.referenceType === 'custom-terms' && 'Generated from Terms')
    || ((compiledDocument.referenceType === 'ai-bot' || compiledDocument.templateReferenceType === 'ai-bot') && 'AI Generated')
    || null
  );
};

const DocumentSummary: React.FC<Props> = ({ compiledDocument }) => {
  const parts = [
    compiledDocument.document?.fileName ?? "File name not found",
    getDescription({ compiledDocument }),
    compiledDocument.document
      ? format.fileSize({ as: 'kb', size: compiledDocument.document.fileSize })
      : null,
  ].filter(removeNullish);

  return (
    <small className={s.info}>
      {parts.join(' · ')}
      {' '}
    </small>
  );
};

export default DocumentSummary;
