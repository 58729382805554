import Button from '@components/DesignLibrary/Button';
import Header from '@components/DesignLibrary/Header';
import PageContext from '@components/DesignLibrary/PageContext';
import DocumentUploadButton from '@components/Document/DocumentUploadButton';
import DownloadArchive from '@components/Document/DownloadArchive';
import DotsSixVertical from '@components/Icons/DotsSixVertical';
import NoodleAI from '@components/Icons/NoodleAI';
import TrashSimple from '@components/Icons/TrashSimple';
import { reorderItems } from '@components/OrderableItem';
import ProgressIndicator from '@components/ProgressIndicator';
import Spacer from '@components/Spacer';
import Tooltip from '@components/Tooltip';
import DocumentRow from '@components/UserWorkflowInfo/DocumentsAction/DocumentRow';
import removeNullish from '@helpers/removeNullish';
import { IDENTIFIERS, getUrl } from '@helpers/urlsHelper';
import userWorkflowToContext from '@helpers/userWorkflowToContext';
import { usePrevious, useToast } from '@hooks';
import useNoodleApi from '@hooks/useNoodleApi';
import useLocalStorageStateSerializers from '@lib/UserWorkflowDetailCustomerView/useLocalStorageStateSerializers';
import { USER_WORKFLOW_DOCUMENT_ID_KEY, useOpenUserWorkflowDocumentPane } from '@panes/hooks';
import { DocumentAccessContext, PromptForDocumentAccessToken } from '@providers/DocumentAccess';
import { useLocalStorageState } from '@providers/LocalStorageState';
import * as tsClient from '@tsClient';
import { getUserWorkflowById } from '@tsClient/workflows';
import * as ApiModels from '@typings/api-models';
import { UserWorkflowCollaborator } from '@typings/api-models';
import { Reorder } from 'framer-motion';
import { useRouter } from 'next/router';
import { useQueryState } from 'nuqs';
import { FC, MouseEvent, useContext, useEffect, useRef, useState } from 'react';
import s from './UserWorkflowDocuments.module.scss';

type ThisCompiledDocument = Pick<ApiModels.UserWorkflowDocument, 'id' | 'rank' | 'templateReferenceId'> &
  Parameters<typeof DocumentRow>[0]['compiledDocument'] & {
    isNew?: true;
    isEmptyRowUpload?: true;
  };

type Props = {
  creatorId: string;
  userWorkflowId: string;
  isDownloadEnabled: boolean;
};

const UserWorkflowDocuments: FC<Props> = ({ userWorkflowId, creatorId, isDownloadEnabled }) => {
  const { accessToken } = useContext(DocumentAccessContext);
  const [compiledDocuments, setCompiledDocuments] = useState<ThisCompiledDocument[]>([]);
  const [isSaving, setIsSaving] = useState(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const containerRef = useRef<HTMLUListElement>(null);
  const [collaborators, setCollaborators] = useLocalStorageState<UserWorkflowCollaborator[]>({
    defaultValue: [],
    key: 'collaborators',
    prefix: 'workflows',
    ...useLocalStorageStateSerializers.collaborators,
  });

  const [context, setContext] = useState<{ id: string; label: string, value: string }[]>([]);

  const [userWorkflowDocumentId] = useQueryState(USER_WORKFLOW_DOCUMENT_ID_KEY);
  const previousUserWorkflowDocumentId = usePrevious(userWorkflowDocumentId);

  const router = useRouter();

  const openUserWorkflowDocumentPane = useOpenUserWorkflowDocumentPane();

  const {
    getData: generateUserWorkflowPdfs,
    fetchingState: { isFetching: isGeneratingPdfs },
  } = useNoodleApi(tsClient.workflows.generateUserWorkflowPdfs, {
    toastOnError: true,
  });
  const { data: loadedCompiledDocumentsResponse, getData: getUserWorkflowDocuments } = useNoodleApi(
    tsClient.workflows.getUserWorkflowCompiledDocuments,
    { toastOnError: true },
  );
  const { data: workflowData, getData: getWorkflow } = useNoodleApi(getUserWorkflowById, {
    toastOnError: true,
  });

  const { getData: updateUserWorkflowDocuments } = useNoodleApi(tsClient.workflows.updateUserWorkflowCompiledDocuments, {
    toastOnError: true,
    toastSuccessMessage: () => [useToast.ToastTypeVariants.SUCCESS, 'Documents saved.'],
  });
  const { data: loadedCollaborators, getData: getCollaboratorsFn } = useNoodleApi(tsClient.workflows.getUserWorkflowCollaborators, {
    toastOnError: true,
  });

  useEffect(() => {
    getUserWorkflowDocuments({ perPage: 1000, userWorkflowId });
  }, [userWorkflowId, getUserWorkflowDocuments]);

  useEffect(() => {
    if (workflowData) {
      (async () => {
        try {
          const userWorkflowContext = await userWorkflowToContext(workflowData);
          const combinedContext = [
            ...collaborators
              .map(c => {
                if (c.roleLabel && c.name && c.source === 'context-person') {
                  return {
                    id: c.id,
                    label: c.roleLabel,
                    value: c.name || '',
                  };
                }
                return null;
              })
              .filter(removeNullish),
            ...userWorkflowContext,
          ].filter(removeNullish);
          setContext(combinedContext);
        } catch (error) {
          console.error("Failed to load user workflow context", error);
        }
      })();
    }
  }, [workflowData, collaborators]);

  useEffect(() => {
    if (userWorkflowId) {
      getWorkflow({ userWorkflowId });
      getCollaboratorsFn({ userWorkflowId });
    }
  }, [userWorkflowId, getWorkflow, getCollaboratorsFn]);

  useEffect(() => {
    // Refetch documents when pane closes
    if (previousUserWorkflowDocumentId && !userWorkflowDocumentId) {
      getUserWorkflowDocuments({ perPage: 1000, userWorkflowId });
    }
  }, [userWorkflowId, userWorkflowDocumentId, previousUserWorkflowDocumentId, getUserWorkflowDocuments]);

  useEffect(() => {
    setCompiledDocuments(loadedCompiledDocumentsResponse?.items || []);
  }, [loadedCompiledDocumentsResponse]);

  useEffect(() => {
    if (loadedCollaborators) {
      setCollaborators(loadedCollaborators);
    }
  }, [loadedCollaborators, setCollaborators]);

  useEffect(() => {
    const confirmMessage = 'You have unsaved changes. Are you sure you want to leave this page?';
    const handleRouteChange = (url: string): void => {
      if (hasUnsavedChanges) {
        if (window.confirm(confirmMessage)) {
          router.events.emit('routeChangeComplete', url);
        } else {
          // throw error to prevent router change
          router.events.emit('routeChangeError');
          // eslint-disable-next-line no-throw-literal
          throw 'Route change aborted.';
        }
      }
    };
    const handleBeforeUnload = (e: BeforeUnloadEvent): void => {
      if (hasUnsavedChanges) {
        e.preventDefault();
      }
    };

    router.events.on('routeChangeStart', handleRouteChange);
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [hasUnsavedChanges]);

  const handleRemoveItem = (documentId: string): void => {
    window.confirm('Are you sure you want to remove this document?');
    setCompiledDocuments(items => items.filter(item => item.id !== documentId));
    setHasUnsavedChanges(true);
  };

  const handleTitleChange = (documentId: string, newTitle: string): void => {
    setCompiledDocuments(items =>
      items.map(item => {
        if (item.id === documentId) {
          return {
            ...item,
            title: newTitle,
          };
        }
        return item;
      }),
    );
    setHasUnsavedChanges(true);
  };

  const handleSave = async (event: MouseEvent<HTMLButtonElement>): Promise<void> => {
    if (event && event.preventDefault) {
      event.preventDefault();
      event.stopPropagation();
    }

    setIsSaving(true);
    const updates = compiledDocuments
      .map((doc, index) => {
        if (doc.isEmptyRowUpload && doc.document) {
          return {
            documentId: doc.document.id,
            id: doc.id,
            rank: index + 1,
            title: doc.title,
          };
        }
        if (!doc.isNew || !doc.document) {
          return {
            id: doc.id,
            rank: index + 1,
            title: doc.title,
          };
        }
        return {
          documentId: doc.document.id,
          rank: index + 1,
          title: doc.title,
        };
      })
      .filter(removeNullish);

    const response = await updateUserWorkflowDocuments({
      updates,
      userWorkflowId,
    });

    if (response.data) {
      setHasUnsavedChanges(false);
    }

    setIsSaving(false);
  };

  const handleDocumentAdded = (
    document: Pick<NonNullable<ThisCompiledDocument['document']>, 'id' | 'mimeType' | 'fileName' | 'fileSize'> | null,
    rank: number,
  ): void => {
    setHasUnsavedChanges(true);
    if (document) {
      setCompiledDocuments(items =>
        reorderItems({
          items: [
            ...items,
            {
              document,
              id: document.id,
              isNew: true,
              rank,
              referenceId: document.id,
              referencePublishedAt: null,
              referenceType: 'manually-added',
              referenceVersion: null,
              templateReferenceId: null,
              templateReferenceType: null,
              title: document.fileName,
            },
          ],
          movedItemId: document.id,
          newRank: rank,
        }),
      );
    }
  };

  const handleEmptyRowManualUpload = (
    compiledDocument: ThisCompiledDocument,
    document: Pick<NonNullable<ThisCompiledDocument['document']>, 'id' | 'mimeType' | 'fileName' | 'fileSize'> | null,
  ): void => {
    setHasUnsavedChanges(true);
    if (document) {
      setCompiledDocuments(items => {
        const updatedItems = items.map(item =>
          item.id === compiledDocument.id
            ? ({
              ...item,
              document,
              isEmptyRowUpload: true,
              referenceId: document.id,
              referenceType: 'manually-added',
            } as ThisCompiledDocument)
            : item,
        );
        return updatedItems;
      });
    }
  };

  const showDownloadLink = isDownloadEnabled && accessToken;
  const showPromptForAccessToken = isDownloadEnabled && !accessToken;

  const showGeneratePdfsButton = Boolean(workflowData?.workflow?.hasGeneratedPdfs);

  const handleGeneratePdfs = async (): Promise<void> => {
    if (showGeneratePdfsButton) {
      await generateUserWorkflowPdfs({ userWorkflowId });
      await getUserWorkflowDocuments({ perPage: 1000, userWorkflowId });
    }
  };

  if (!workflowData || !compiledDocuments) {
    return (
      <>
        <Spacer size={80} />
        <ProgressIndicator isPage isCentered />
      </>
    );
  }

  return (
    <div className={s['compiled-documents-content']}>
      {workflowData && (
        <>
          <Header
            title={workflowData.person.name}
            description={workflowData.product?.title}
            actions={[
              {
                href: getUrl(IDENTIFIERS.DASHBOARD_MEMBER, { id: workflowData.person.id }),
                label: 'Profile',
              },
              showGeneratePdfsButton
                ? {
                  isFetching: isGeneratingPdfs,
                  label: 'Generate PDFs',
                  onClick: handleGeneratePdfs,
                }
                : null,
            ].filter(removeNullish)}
          />
          <PageContext
            items={context}
          />
        </>
      )}
      {showPromptForAccessToken && <PromptForDocumentAccessToken size="sm" className={s.passcode} />}
      {showDownloadLink && (
        <div className={s['download-link-wrapper']}>
          <DownloadArchive
            accessToken={accessToken}
            className={s['download-link']}
            documents={compiledDocuments.map(d => d.document).filter(removeNullish)}
            userWorkflowId={userWorkflowId}
          />
          <Spacer size={16} />
        </div>
      )}
      <Reorder.Group
        ref={containerRef}
        values={compiledDocuments}
        onReorder={r => {
          setCompiledDocuments(r);
          setHasUnsavedChanges(true);
        }}
        axis="y"
        className={s.table}
      >
        {compiledDocuments.map((compiledDocument, index) => (
          <Reorder.Item
            whileDrag={{ cursor: 'grabbing' }}
            onDragStart={() => setIsDragging(true)}
            onDragEnd={() => setIsDragging(false)}
            value={compiledDocument}
            key={compiledDocument.id}
            dragConstraints={containerRef}
          >
            <div>
              <DocumentRow
                creatorId={creatorId}
                compiledDocument={compiledDocument}
                onChangeTitle={handleTitleChange}
                onUploadComplete={d => handleEmptyRowManualUpload(compiledDocument, d)}
                isSaving={isSaving}
              />
              {compiledDocument.templateReferenceType === 'ai-bot' && (
                <Tooltip text="Generate with AI" className={s['ai-tooltip']}>
                  <button
                    className={s['circle-button']}
                    onClick={() => openUserWorkflowDocumentPane({ userWorkflowDocumentId: compiledDocument.id })}
                  >
                    <NoodleAI size={16} />
                  </button>
                </Tooltip>
              )}
              <button className={s['circle-button']} onClick={() => handleRemoveItem(compiledDocument.id)}>
                <TrashSimple size={16} weight="fill" color="var(--color-error)" />
              </button>
              <div className={s['drag-handle']}>
                <DotsSixVertical size={24} color="var(--color-gray-50)" />
              </div>
            </div>
            <DocumentUploadButton
              creatorId={creatorId}
              id={`document-list-${index + 2}`}
              onUploadComplete={d => handleDocumentAdded(d, index + 2)}
              isDragging={isDragging}
            />
          </Reorder.Item>
        ))}
      </Reorder.Group>
      <Spacer size={48} />
      <Button
        size='lg'
        variant='primary'
        onClick={handleSave}
        loading={isSaving}
        className={s.sticky}
      >
        {hasUnsavedChanges ? 'Save Changes' : 'Save'}
      </Button>
    </div>
  );
};

export default UserWorkflowDocuments;
