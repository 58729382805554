import { FC, useEffect, useRef, useState } from 'react';
import FieldLabel from '@components/FormFields/FieldLabel';
import FieldError from '@components/FieldError';
import Shimmer from '@components/Shimmer';
import { FieldState } from '@providers/Confido/ConfidoTypes';
import classes from './confidoHostedField.module.scss';

type HostedFieldProps = {
  id: string;
  label: string;
  fieldState?: FieldState;
};

const ConfidoHostedField: FC<HostedFieldProps> = ({
  id,
  label,
  fieldState,
}) => {
  const [internalLoading, setInternalLoading] = useState(true);
  const timeout = useRef<NodeJS.Timeout>();
  const loading = fieldState?.loading ?? true;

  useEffect(() => {
    if (!loading) {
      timeout.current = setTimeout(() => setInternalLoading(false), 250);
    } else if (timeout.current) {
      clearTimeout(timeout.current);
    }
    return () => timeout.current && clearTimeout(timeout.current);
  }, [loading]);

  const error = fieldState?.error?.message;
  return (
    <div className={classes.confidoIframe}>
      {internalLoading
        ? (
          <Shimmer />
        )
        : (
          <FieldLabel id={`${id}-${label}`}>{label}</FieldLabel>
        )}
      <div id={id} className={internalLoading ? classes.hidden : undefined}/>
      {error && (<FieldError className={classes.error}>{error}</FieldError>)}
    </div>
  );
};

export default ConfidoHostedField;
