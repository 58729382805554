import * as Types from '@typings/graphql-models';
import * as ApiModels from '@typings/api-models';

type ThisProduct = {
  productTypes: Array<Pick<Types.ProductType, 'noodleProductType'>>;
};

const getProductsNoodleProductType = (product: ThisProduct | null | undefined): ApiModels.NoodleProductTypes | null => {
  if (!product || !product.productTypes || product.productTypes.length === 0) {
    return null;
  }
  return product.productTypes[0]?.noodleProductType || null;
};

export default getProductsNoodleProductType;
