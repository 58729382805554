import React, { useMemo, useState } from 'react';
import classNames from 'classnames';
import * as ApiModels from '@typings/api-models';
import PaymentMethodLogo from '@components/Icons/PaymentMethodLogo';
import ProgressIndicator from '@components/ProgressIndicator';
import PaymentMethodEditModal from '@modals/PaymentMethodEditModal';
import Buttons from '@components/Buttons/Buttons';
import s from './CardDetails.module.scss';

type Props = {
  card: {
    brand: Parameters<typeof PaymentMethodLogo>[0]['brand'] | null;
    exp_year?: number;
    exp_month?: number;
    last4: string;
  } | null;
  creator: Parameters<typeof PaymentMethodEditModal>[0]['creator'];
  isPrimary: boolean;
  isLoading?: boolean;
  onSaveCard?: Parameters<typeof PaymentMethodEditModal>[0]['onSaveCard'];
  personId?: string;
  subscription?: {
    id: string;
    subscriptionStatus?: ApiModels.SubscriptionStatus | null;
  };
  retryPayment?: (id: string) => Promise<void>;
};

const CardDetails: React.FC<Props> = ({
  card,
  creator,
  isLoading,
  isPrimary,
  onSaveCard,
  personId,
  retryPayment,
  subscription,
}) => {
  const { exp_month: expMonth, exp_year: expYear } = card || {};
  const expiredDate = `${expMonth}/${expYear?.toString().slice(-2)}`;
  let stateOfCard = null;

  const [isRetrying, setIsRetrying] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  const isCardExpired = useMemo(() => {
    if (expMonth === undefined || expYear === undefined) {
      return true;
    }
    const expiration = new Date(expYear, expMonth);

    const today = new Date();

    return today > expiration;
  }, [expMonth, expYear]);

  if (isCardExpired) {
    stateOfCard = 'Expired';
  } else if (isPrimary) {
    stateOfCard = 'Primary';
  }

  const handleClick = (): void => {
    setIsVisible(true);
  };

  const handleRetry = async (e: React.MouseEvent): Promise<void> => {
    e.stopPropagation();
    if (subscription && retryPayment) {
      setIsRetrying(true);
      await retryPayment(subscription.id);
      setIsRetrying(false);
    }
  };

  return (
    <>
      {onSaveCard && isVisible && personId && (
        <PaymentMethodEditModal
          creator={creator}
          onClose={() => setIsVisible(false)}
          onSaveCard={onSaveCard}
          personId={personId}
        />
      )}
      <button className={s.container} onClick={handleClick}>
        <PaymentMethodLogo brand={card?.brand || 'credit-card'} />
        <div className={s.content}>
          <p>{`•••• •••• •••• ${card?.last4}`}</p>
          {expMonth && expYear && (
            <small className={classNames(s['card-details'], { [s['card-details--expired']]: isCardExpired })}>
              {expiredDate} {stateOfCard && ' · '}
              {stateOfCard && (
                <span className={classNames(s['card-details--isPrimary'], { [s['card-details--isExpired']]: isCardExpired })}>
                  {isCardExpired ? 'Expired' : 'Primary'}
                </span>
              )}
            </small>
          )}
          {isLoading && <ProgressIndicator isAbsolute />}
        </div>
        {onSaveCard && <p className={s.editButton}>Edit</p>}
        {subscription?.subscriptionStatus === ApiModels.SubscriptionStatus.PastDue && retryPayment && (
          <Buttons isFetching={isRetrying} className={s.retry} onClick={handleRetry}>
            Retry
          </Buttons>
        )}
      </button>
    </>
  );
};

export default CardDetails;
