import Spacer from '@components/Spacer';
import { PaymentMethod } from '@components/Payment/PaymentMethods';

type Props = {
  creatorSlug: string | null;
  defaultPaymentMethod:
    & Parameters<typeof PaymentMethod>[0]['paymentMethod']
    & { card: { funding: string } | null }
    | null;
  isDebitCardPaymentEnabled: boolean;
  isCardPaymentEnabled: boolean;
  unverifiedPaymentMethods: Array<
    & { id: string }
    & Parameters<typeof PaymentMethod>[0]['paymentMethod']
  >
  useDifferentPaymentMethod: boolean;
  userHasCustomerStripeId: boolean;
  paymentType: 'Card' | 'ACH';
  addSpacing?: boolean;
};

const DefaultPaymentMethod: React.FC<Props> = ({
  creatorSlug,
  defaultPaymentMethod,
  isCardPaymentEnabled,
  isDebitCardPaymentEnabled,
  unverifiedPaymentMethods,
  useDifferentPaymentMethod,
  userHasCustomerStripeId,
  paymentType,
  addSpacing = true,
}) => {
  if (!userHasCustomerStripeId || !creatorSlug || useDifferentPaymentMethod || !defaultPaymentMethod) {
    return null;
  }

  if (paymentType === 'Card') {
    if (isDebitCardPaymentEnabled && !isCardPaymentEnabled
      ? defaultPaymentMethod?.card?.funding === 'debit'
      : defaultPaymentMethod?.card) {
      return (
        <>
          <PaymentMethod
            creator={{ slug: creatorSlug }}
            paymentMethod={defaultPaymentMethod}
            isLoading={false}
            isPrimary
          />
          {addSpacing && <Spacer size={12} />}
        </>
      );
    }
    return null;
  }

  if (paymentType === 'ACH') {
    if (unverifiedPaymentMethods.length > 0) {
      return (
        <>
          {unverifiedPaymentMethods.map((paymentMethod) => (
            <PaymentMethod
              creator={{ slug: creatorSlug }}
              key={paymentMethod.id}
              paymentMethod={paymentMethod}
              isLoading={false}
              isPrimary={false}
            />
          ))}
          {addSpacing && <Spacer size={12} />}
        </>
      );
    }
    if (defaultPaymentMethod?.us_bank_account) {
      return (
        <>
          <PaymentMethod
            creator={{ slug: creatorSlug }}
            paymentMethod={defaultPaymentMethod}
            isLoading={false}
            isPrimary
          />
          {addSpacing && <Spacer size={12} />}
        </>
      );
    }
  }
  return null;
};

export default DefaultPaymentMethod;
