import Badge, { Props as BadgeCellProps } from './Badge';
import Empty, { Props as EmptyCellProps } from './Empty';
import Link, { Props as LinkCellProps } from './Link';
import Assignees, { Props as AssigneesProps } from './Assignees';
import OpenDetails, { Props as OpenDetailsProps } from './OpenDetails';
import Progress, { Props as ProgressCellProps } from './Progress';
import Text, { Props as TextCellProps } from './Text';
import OpenOnClick, { Props as OpenOnClickProps } from './OpenOnClick';
import AttachmentAssignees, { Props as AttachmentAssigneeProps } from './AttachmentAssignees';

export type TableCell =
  | BadgeCellProps
  | AssigneesProps
  | EmptyCellProps
  | LinkCellProps
  | OpenDetailsProps
  | OpenOnClickProps
  | ProgressCellProps
  | TextCellProps
  | AttachmentAssigneeProps;

type Props = {
  cell: TableCell;
  onCloseTable: () => void;
};

const CellWrapper: React.FC<Props> = ({ cell, onCloseTable }) => {
  if (cell.type === 'badge') {
    return (<Badge {...cell} />);
  }

  if (cell.type === 'empty') {
    return (<Empty {...cell} />);
  }

  if (cell.type === 'link') {
    return (<Link {...cell} />);
  }

  if (cell.type === 'open-details') {
    return (<OpenDetails {...cell} onClick={onCloseTable} />);
  }

  if (cell.type === 'open-on-click') {
    return (<OpenOnClick {...cell} />);
  }

  if (cell.type === 'progress') {
    return (<Progress {...cell} />);
  }

  if (cell.type === 'assignees') {
    return (<Assignees {...cell} />);
  }

  if (cell.type === 'attachment-assignees') {
    return (<AttachmentAssignees {...cell} />);
  }

  if (cell.type === 'text') {
    return (<Text {...cell} />);
  }

  return <Empty type='empty' />;
};

export default CellWrapper;
