import clsx from 'classnames';
import { mixpanelTrack } from '@providers/Mixpanel';
import FilePdf from '@components/Icons/FilePdf';
import FileZip from '@components/Icons/FileZip';
import { getNoodleApiUrl, getNoodleDocumentsUrl } from '@helpers/urlsHelper';
import s from './DownloadArchive.module.scss';

type Props = {
  accessToken?: string;
  className?: string;
  userWorkflowId: string;
  documents: { id: string }[];
};
const DownloadArchive: React.FC<Props> = ({ accessToken, className, documents, userWorkflowId }) => {
  if (documents.length === 0) {
    return null;
  }
  const pdfDownloadLink = getNoodleApiUrl('/workflows/user-workflows/[userWorkflowId]/compiled-documents.pdf', {
    accessToken,
    userWorkflowId,
  });

  const zipDownloadLink = getNoodleDocumentsUrl('/documents/archive.zip', {
    accessToken,
    archiveName: `userWorkflow-${userWorkflowId}-documents`,
    documentId: documents.map((d) => d.id),
  });

  const handleClick = (): void => {
    mixpanelTrack('Workflow - Download archive', { userWorkflowId });
  };

  return (
    <div className={s.wrapper}>
      <a
        className={clsx(s['download-link'], className)}
        download
        href={pdfDownloadLink}
        data-cy="downloadPdf"
        onClick={handleClick}
      >
        <FilePdf /> Download all as a PDF
      </a>
      <a
        className={clsx(s['download-link'], className)}
        download
        href={zipDownloadLink}
        data-cy="downloadZip"
        onClick={handleClick}
      >
        <FileZip /> Download all as a ZIP
      </a>
    </div>
  );
};

export default DownloadArchive;
