import classNames from 'classnames';
import { FC, useEffect, useState } from 'react';
import Plus from '@components/Icons/Plus';
import SelectionBlock from '../SelectionBlock';
import s from './SelectionBlockList.module.scss';
import { Block } from '../../types';

type SelectionBlockListProps = {
  blocks: Block[];
  updateBlock: (index: number, from: string, to: string) => void;
  removeBlock: (index: number) => void;
  addBlock: () => void;
};

const SelectionBlockList: FC<SelectionBlockListProps> = ({
  blocks,
  updateBlock,
  removeBlock,
  addBlock,
}) => {
  const [isPossibleToAdd, setIsPossibleToAdd] = useState(true);

  useEffect(() => {
    if (blocks.length === 0) {
      setIsPossibleToAdd(true);
      return;
    }

    const lastTime = blocks[blocks.length - 1].to === '11:45pm'
      || blocks[blocks.length - 1].to === '11:30pm';

    setIsPossibleToAdd(!lastTime);

    const lastBlock = blocks.find(
      (block) => block.to === '11:45pm' || block.to === '11:30pm',
    );
    if (lastBlock) {
      const index = blocks.indexOf(lastBlock);
      if (index !== blocks.length - 1) {
        removeBlock(index + 1);
      }
    }
  }, [blocks]);

  return (
    <div className={s.selectionblocklist}>
      <div
        className={classNames(
          s.selectionblocklist__container,
          'selectionblocklist__container',
        )}
      >
        {blocks.map((block, index) => (
          <SelectionBlock
            key={`${block.from}:${block.to}`}
            from={block.from}
            setFrom={(time: string) => updateBlock(index, time, block.to)}
            to={block.to}
            setTo={(time: string) => updateBlock(index, block.from, time)}
            removeBlock={() => removeBlock(index)}
            after={blocks[index - 1]?.to}
          />
        ))}
      </div>
      {isPossibleToAdd && (
        <button
          onClick={() => addBlock()}
          className={classNames(
            s['selectionblocklist__add-button'],
            'selectionblocklist__add-button',
          )}
        >
          <div className={s['selectionblocklist__add-icon']}>
            <Plus />
          </div>

          <span
            className={classNames(
              s['selectionblocklist__add-text'],
              'selectionblocklist__add-text',
            )}
          >
            Add time
          </span>
        </button>
      )}
    </div>
  );
};

export default SelectionBlockList;
