import React, { useEffect, useState } from 'react';
import * as configuration from '@configuration/client';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import getMORStripeAccount from '@helpers/getMORStripeAccount';
import { StripeAccountType } from '@typings/api-models';

type Props = {
  creator: Exclude<Parameters<typeof getMORStripeAccount>[0], undefined>;
};

const StripeElementsProvider: React.FC<Props> = ({ creator, children }) => {
  const [stripe, setStripe] = useState<Stripe | null>(null);

  useEffect(() => {
    const loadStripeInstance = async (): Promise<void> => {
      if (!creator || !creator.stripe_account_id || !configuration.STRIPE_KEY) {
        return;
      }
      const newStripe = creator.stripeAccountType !== StripeAccountType.Connect
        ? await loadStripe(configuration.STRIPE_KEY)
        : await loadStripe(configuration.STRIPE_KEY, { stripeAccount: creator.stripe_account_id });
      setStripe(newStripe);
    };
    loadStripeInstance();
  }, [creator]);

  if (!stripe) {
    return null;
  }

  return (
    <Elements stripe={stripe}>
      {children}
    </Elements>
  );
};

export default StripeElementsProvider;
