import Link from 'next/link';
import { TD } from '@/components/DesignLibrary/Table';

export type Props = {
  type: 'link';
  label: string;
  href: string;
  // newTab?: boolean; // @todo
};

const LinkCell: React.FC<Props> = ({ label, href }) => (
  <TD compact>
    <Link href={href} className="caption-bold" style={{ color: 'var(--color-gray-100)' }}>
      {label}
    </Link>
  </TD>
);

export default LinkCell;