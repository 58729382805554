import Buttons from '@components/Buttons/Buttons';
import CircularProgress from '@components/CircularProgress';
import CustomLink from '@components/CustomLink';
import ChatCircle from '@components/Icons/ChatCircle';
import NoodleIcon from '@components/Icons/Noodle';
import NoodleAI from '@components/Icons/NoodleAI';
import ProgressIndicator from '@components/ProgressIndicator';
import X from '@components/Icons/X';
import Link from '@components/Icons/Link';
import UserImage from '@components/UserImage';
import { useIsTextTruncated } from '@hooks';
import { ChatsCircle as ChatIcon, CheckCircle as CheckboxOn, Circle as CheckboxOff, Chat as CommentIcon, CheckFat as TaskIcon } from '@phosphor-icons/react';
import { m } from 'framer-motion';
import { FC, ReactElement, useRef, useState } from 'react';

import SecondaryActions from '../SecondaryActions';
import Badge from '../Badge';
import s from './ListCell.module.scss';

interface ListCellProps {
  topLine?: string | null;
  label: string;
  bottomLine?: string | null;
  aiMessage?: string | null;
  avatar?: {
    url?: string;
    name: string;
    color?: string;
  };
  needsAttention?: boolean;
  isRouting?: boolean;
  isSelected?: boolean;
  showCheckbox?: boolean;
  isMuted?: boolean;
  isAi?: boolean;
  time?: string;
  progress?: number;
  messageCount?: number;
  href?: string;
  onClick?: () => void;
  badge?: Omit<Parameters<typeof Badge>[0], 'children'>;
  tag?: 'li' | 'div';
  secondaryActions?: Parameters<typeof SecondaryActions>[0];
  inboxType?: 'task' | 'comment' | 'chat' | 'noodle-broadcast';
  linkedCells?: {
    type: 'workflows';
    items: Array<ListCellProps & { id: string; }>;
  };
}

const ListCell: FC<ListCellProps> = ({
  topLine,
  label,
  bottomLine,
  aiMessage,
  avatar,
  href,
  onClick,
  needsAttention,
  isRouting,
  isSelected,
  showCheckbox,
  isMuted,
  isAi,
  time,
  progress,
  messageCount,
  badge,
  tag,
  secondaryActions,
  inboxType,
  linkedCells,
}) => {

  const [showLinkedCells, setShowLinkedCells] = useState(false);

  const linkedCellsRef = useRef<HTMLButtonElement | null>(null);

  const topLineRef = useRef(null);
  const isTopLineTruncated = useIsTextTruncated(topLineRef);

  const labelRef = useRef(null);
  const isLabelTruncated = useIsTextTruncated(labelRef);

  const bottomLineRef = useRef(null);
  const isBottomLineTruncated = useIsTextTruncated(bottomLineRef);

  const getInboxTypeIcon = (): ReactElement | null => {
    switch (inboxType) {
    case 'task':
      return <TaskIcon color="var(--color-warning)" size={12} className={s.inboxIcon} />;
    case 'comment':
      return <CommentIcon color="#8F00FF" size={12} className={s.inboxIcon} />;
    case 'chat':
      return <ChatIcon color="#C0A309" size={12} className={s.inboxIcon} />;
    case 'noodle-broadcast':
      return <NoodleIcon fill="var(--color-noodle)" width={12} height={12} className={s.inboxIcon} />;
    default:
      return null;
    }
  };

  const renderData = (): ReactElement => (
    <div
      className={s.container}
      {...((isSelected || isMuted) && {
        style: {
          ...(isSelected && { backgroundColor: 'var(--color-gray-15)' }),
          ...(isMuted && { opacity: 0.5 }),
        },
      })}
    >
      {isAi && (
        <div className={s.ai}>
          <NoodleAI size={12} />
        </div>
      )}
      {avatar && <UserImage {...avatar} size={32} />}

      <div className={s.content} {...secondaryActions && {
        style: {
          paddingRight: '24px',
        },
      }}>
        {(topLine || badge || inboxType) && (
          <small
            ref={topLineRef}
            title={isTopLineTruncated ? (topLine ?? undefined) : undefined}
            {...inboxType === 'noodle-broadcast' && {
              style: {
                color: 'var(--color-noodle)',
                fontWeight: 'bold',
              },
            }}
          >
            {getInboxTypeIcon()} {badge && <Badge {...badge} />} {topLine}
          </small>
        )}
        <p ref={labelRef} title={isLabelTruncated ? label : undefined}>
          {label}
        </p>
        {(bottomLine || linkedCells || aiMessage) && (
          <small ref={bottomLineRef} title={(isBottomLineTruncated && bottomLine) ? bottomLine : undefined}
            {...aiMessage && {
              style: {
                alignItems: 'center',
                display: 'flex',
                gap: 'var(--spacer-xs)',
              },
            }}
            {...linkedCellsRef?.current && {
              style: {
                paddingLeft: linkedCellsRef.current.clientWidth + 8,
              },
            }}>
            {aiMessage && <NoodleAI size={12} />}{aiMessage || bottomLine}
          </small>
        )}
      </div>
      {(messageCount && messageCount > 0 && (
        <div className={s.discussion}>
          <ChatCircle size={12} /> <span>{Intl.NumberFormat('en', { notation: 'compact' }).format(messageCount)}</span>
        </div>
      )) || <></>}
      {isRouting && <ProgressIndicator />}
      {(needsAttention || progress !== undefined) && !isRouting && (
        <div className={s.attention}>
          {needsAttention && <m.div initial={{ scale: 0 }} animate={{ scale: 1 }} />}
          {progress !== undefined && <CircularProgress progress={progress} size={20} />}
        </div>
      )}
      {showCheckbox && (
        <>
          {isSelected ? <CheckboxOn weight='fill' size={24} color='var(--color-primary)' /> : <CheckboxOff size={24} color='var(--color-gray-100)' />}
        </>
      )}
      {time && <div className={s.time}>{time}</div>}
    </div>
  );

  const renderLinkedCells = (): ReactElement[] | undefined => (
    linkedCells?.items.map((cellProps) => (
      <ListCell key={cellProps.id} {...cellProps} />
    ))
  );

  const Tag = tag || 'li';

  return (
    <Tag className={s.item}>
      {href && <CustomLink to={href} onClick={onClick}>{renderData()}</CustomLink>}
      {!href && onClick && (
        <Buttons isWrapper isFullWidth onClick={onClick}>
          {renderData()}
        </Buttons>
      )}
      {!href && !onClick && renderData()}
      {secondaryActions && progress === undefined && !messageCount && !isRouting && (
        <div className={s.actionsWrapper}>
          <SecondaryActions {...secondaryActions} position="bottom-right" />
        </div>
      )}
      {linkedCells && linkedCells.items.length > 0 && (
        <div className={s.linkedCellCta}>
          <Buttons
            ref={linkedCellsRef}
            {...showLinkedCells && {
              style: {
                backgroundColor: 'var(--color-gray-25)',
              },
            }}
            isWrapper onClick={() => {
              setShowLinkedCells(!showLinkedCells);
            }}>
            {showLinkedCells ? <X color="var(--color-gray-100)" /> : <Link color="var(--color-gray-100)" />}
            {linkedCells.items.length === 1 ? <>{linkedCells.items[0].bottomLine}</> : <>{linkedCells.items.length} linked {linkedCells.type}</>}
          </Buttons>
        </div>
      )}
      {showLinkedCells && linkedCells && linkedCells.items.length > 0 && (
        <m.ul layout initial={{ height: 0, opacity: 0 }} animate={{ height: 'auto', opacity: 1 }} transition={{ duration: 0.3 }} className={s.linkedCells}>
          {renderLinkedCells()}
        </m.ul>
      )}
    </Tag>
  );
};

export default ListCell;
