import { FC } from 'react';
import type { Icon } from '@phosphor-icons/react';
import s from './ListSection.module.scss';

const ListSection: FC<{ label: string; icon: Icon }> = ({ label, icon }) => {
  const IconComponent = icon;
  return (
    <li className={s.section}>
      <IconComponent size={16} weight="fill" />
      {label}
    </li>
  );
};

export default ListSection;
