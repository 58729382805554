import * as ApiModels from '@typings/api-models';
import * as DocumentApiModels from '@typings/document-api-models';
import removeNullish from '@helpers/removeNullish';
import * as format from '@format';
import s from './DocumentsListModal.module.scss';

type Props = {
  compiledDocument: Pick<ApiModels.UserWorkflowDocument,
  | 'referenceVersion'
  | 'referencePublishedAt'
  > & {
    document: Pick<DocumentApiModels.Document, 'fileSize'> | null;
  }
};

const DocumentVersion: React.FC<Props> = ({ compiledDocument }) => {
  const parts = [
    compiledDocument.referenceVersion ? `v${compiledDocument.referenceVersion}` : null,
    compiledDocument.referencePublishedAt ? `published ${format.datetime.timeSince({ date: compiledDocument.referencePublishedAt })} ago` : null,
  ].filter(removeNullish);

  if (parts.length === 0) {
    return null;
  }

  return (
    <small className={s.info}>
      {parts.join(' · ')}
      {' '}
    </small>

  );
};

export default DocumentVersion;
