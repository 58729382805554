import { ReactElement, useState } from 'react';
import { useToast } from '@hooks';
import Modal from '@components/Modal';
import Button from '@components/DesignLibrary/Button';
import { Questionnaire } from '@/typings/Questionnaire';
import ConditionsForm from '@/components/ConditionsForm';
import Spacer from '@/components/Spacer';
import useNoodleApi from '@/hooks/useNoodleApi';
import { createExpression } from '@/tsClient/expressions';
import s from './AddConditionsModal.module.scss';

type Expression = Pick<Questionnaire.Expression, 'expression'> & {
  variables: Pick<Questionnaire.ExpressionVariable, 'variableName' | 'referenceId' | 'selector'>[];
};

type Props = {
  stepId?: string | null;
  numConditions: number;
  conditionExpression: Expression | null;
  options: { key: string; label: string }[];
  type: 'workflow-context';
  onClose: () => void;
  onSubmit: (expressionId: string) => void;
};

const AddConditionsModal = ({
  conditionExpression,
  numConditions,
  options,
  type,
  onClose,
  onSubmit,
}: Props): ReactElement => {
  const [isSaving, setIsSaving] = useState(false);
  const [currentConditionExpression, setConditionExpression] = useState<Expression>(conditionExpression || {
    expression: '',
    variables: [],
  });

  const { getData: createExpressionFn } = useNoodleApi(createExpression, {
    toastOnError: true,
    toastSuccessMessage: () => [useToast.ToastTypeVariants.SUCCESS, 'Conditions created successfully!'],
  });

  const handleSaveConditions = async (): Promise<void> => {
    setIsSaving(true);
    const data = await createExpressionFn({
      expression: currentConditionExpression,
    });
    const expressionId = data.data?.expressionId;
    if (expressionId) {
      onSubmit(expressionId);
    }
    onClose();
    setIsSaving(false);
  };

  return (
    <Modal
      hideBottomInset

      title={numConditions > 0 ? 'Edit conditions' : 'Add conditions'}
      onClose={onClose}

    >
      <div className={s.addConditions}>
        <ConditionsForm
          conditionExpression={currentConditionExpression}
          options={options}
          setConditionExpression={setConditionExpression}
          type={type}
        />
        <Spacer size={12}/>
        <Button variant='primary' size='md' fullWidth onClick={handleSaveConditions} loading={isSaving}>
          Submit
        </Button>
      </div>
    </Modal>
  );
};

export default AddConditionsModal;
