import React, {
  ReactElement, FC, ChangeEvent,
} from 'react';
import classNames from 'classnames';

import s from './DayPicker.module.scss';

  type SelectProps = {
    options: Array<{
        key?: number;
        title: string;
        value: string;
        disabled?: boolean;
    }>;
    disabled?: boolean;
    tabIndex?: number;
    defaultValue?: string;
    name: string;
    id: string;
    onChange: (value: string) => void;
    isFullWidth?: boolean;
  };

const DayPicker: FC<SelectProps> = ({
  options,
  disabled,
  defaultValue,
  tabIndex,
  name,
  id,
  isFullWidth,
  onChange,
}): ReactElement => {
  const onSelect = (event: ChangeEvent<HTMLSelectElement>): void => {
    if (event && event.preventDefault) {
      event.preventDefault();
      event.stopPropagation();
    }

    onChange(event.target.value);
  };

  return (
    <div className={classNames(s.wrapper, { [s['wrapper--full-width']]: isFullWidth })}>
      <select
        id={id}
        defaultValue={defaultValue}
        disabled={disabled}
        tabIndex={tabIndex}
        name={name}
        onChange={onSelect}
        className={`${s.select} action`}
      >
        <option value="" disabled selected={true}>
            Reuse availability
        </option>
        {options.map((option) => (
          <option key={option.key || option.value} value={option.value}>
            {option.title}
          </option>
        ))}
      </select>
    </div>
  );
};

export default DayPicker;
