import { useEffect, useRef, useState } from 'react';
import CheckBox from '@components/DesignLibrary/Atoms/CheckBox';
import { AnimatePresence, m } from 'framer-motion';

import s from '../Filters.module.scss';

type Props<K> = {
  onChange: (selected: Array<K>) => unknown;
  label: string;
  items: Array<{
    label: string;
    isChecked: boolean;
    key: K;
  }>;
};

const FilterMany = <K extends string>({ onChange, label, items }: Props<K>): React.ReactElement => {
  const [open, setOpen] = useState(false);
  const onChangeRef = useRef(onChange);
  onChangeRef.current = onChange;

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent): void => {
      if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
        setOpen(false);
      }
    };

    if (open) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [open]);

  const checked = items.filter(item => item.isChecked);
  const checkedKeys = checked.map(item => item.key);

  const handleChange = (key: string, newValue: boolean): void => {
    const newChecked = items
      .filter(item => item.key === key ? newValue : item.isChecked)
      .map(item => item.key);

    onChangeRef.current(newChecked);
  };

  const animationHidden = {
    opacity: 0,
    y: 8,
  };

  return (
    <div className={s.container} ref={containerRef}>
      <button type="button" onClick={() => setOpen(!open)} style={open ? { backgroundColor: 'var(--color-gray-25)' } : undefined}>
        <span>
          {label}
          {checked.length > 0 && `: ${checked.map(item => item.label).join(', ')}`}
        </span>
      </button>
      <AnimatePresence>
        {open && (
          <m.fieldset
            className={s.inputs}
            initial={animationHidden}
            animate={{
              opacity: 1,
              y: 0,
            }}
            exit={animationHidden}
            transition={{
              duration: 0.2,
            }}
          >
            {items.map(i => (
              <CheckBox
                key={i.key}
                size="sm"
                style="round"
                isChecked={checkedKeys.includes(i.key)}
                onChange={(value => handleChange(i.key, value))}
                label={i.label}
              />
            ))}
          </m.fieldset>)}
      </AnimatePresence>
    </div>
  );
};

export default FilterMany;
