import noodleApiClient, { ApiDefinition } from '@noodleApi';

type THIS_API = ApiDefinition['/payments/processing-fees']['GET'];
type Input= THIS_API['query'];
type Output = THIS_API['response'];

const getProcessingFees = async ({ amount, isConfido }: Input): Promise<Output> => {
  const response = await noodleApiClient.get(`/payments/processing-fees`, { params: { amount, isConfido }});
  return response.data;
};

export default getProcessingFees;