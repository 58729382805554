import { useRef } from 'react';
import CheckBox from '@components/DesignLibrary/Atoms/CheckBox';
import Button from '@components/DesignLibrary/Button';
import s from './AssignSelector.module.scss';

type Props<K> = {
  onChange: (selected: Array<{ key: K, label: string }>) => unknown;
  handleSave?: (selectedKeys: string[]) => Promise<void>;
  items: Array<{
    label: string;
    isChecked: boolean;
    key: K;
  }>;
};

const AssignSelector = <K extends string>({ handleSave, onChange, items }: Props<K>): React.ReactElement => {
  const onChangeRef = useRef(onChange);
  onChangeRef.current = onChange;

  const checked = items.filter(item => item.isChecked);
  const checkedKeys = checked.map(item => item.key);

  const handleChange = (key: string, newValue: boolean): void => {
    const newChecked = items
      .filter(item => item.key === key ? newValue : item.isChecked)
      .map(item => ({ key: item.key, label: item.label }));

    onChangeRef.current(newChecked);
  };

  return (
    <div className={s.container}>
      <fieldset className={s.inputs}>
        {items.map(i => (
          <CheckBox
            key={i.key}
            size="sm"
            style="round"
            isChecked={checkedKeys.includes(i.key)}
            onChange={(value => handleChange(i.key, value))}
            label={i.label}
          />
        ))}
      </fieldset>
      {handleSave && (
        <Button
          size='xs'
          variant='primary'
          onClick={() => handleSave(checked.map((c) => c.key))}
        >
          Assign
        </Button>
      )}
    </div>
  );
};

export default AssignSelector;