import React, { FC } from 'react';
import Spacer from '@components/Spacer';
import { useIsMobile } from '@hooks';
import * as ApiModels from '@typings/api-models';
import * as format from '@format';
import { DEFAULT_CURRENCY } from '@typings/common';
import Payment from '@components/InvoicePaymentsWidget/Payment';
import PaymentsList from './PaymentsList';
import s from './InvoicePaymentsWidget.module.scss';

type Props = {
  payments: Parameters<typeof PaymentsList>[0]['payments'];
  paymentPlan: Pick<ApiModels.PaymentPlan, 'id' | 'amount' | 'nextPaymentDate' | 'paymentMethodId' | 'frequency'> | null;
  currency: string;
};

const InvoicePaymentsWidget: FC<Props> = ({ currency, payments, paymentPlan }) => {
  const isMobile = useIsMobile();
  if (isMobile) {
    return <PaymentsList payments={payments} />;
  }

  return (
    <>
      <p className="body-md-bold">Payments</p>
      <Spacer size={16} />
      {paymentPlan?.nextPaymentDate && (
        <>
          <p className={s.nextPayment}>Next payment</p>
          <Spacer size={8} />
          <div className={s.row}>
            <p className='caption-bold'>{format.price.withCurrency(paymentPlan.amount,
              currency || (payments.filter(p => p.type === 'payment')?.[0] as Parameters<typeof Payment>[0]['payment'])?.creatorCurrency || DEFAULT_CURRENCY,
            )}</p>
            <p className={s.rowTitle}>{format.datetime.withYear(paymentPlan.nextPaymentDate)}</p>
          </div>
          <Spacer size={24} isLine />
        </>
      )}
      <PaymentsList payments={payments} />
    </>
  );
};

export default InvoicePaymentsWidget;
