import { FC, useCallback, useEffect, useState } from 'react';
import DOMPurify from 'isomorphic-dompurify';
import { JWT_COOKIE_NAME, NOODLE_API_HOST } from '@configuration/client';
import { getCookiesAsJson } from '@providers/Auth';
import useNoodleApi from '@hooks/useNoodleApi';
import * as tsClient from '@tsClient';
import Header from '@components/DesignLibrary/Header';
import userWorkflowToContext from '@helpers/userWorkflowToContext';
import PageContext from '@components/DesignLibrary/PageContext';
import removeNullish from '@helpers/removeNullish';
import SlateEditor from '@components/SlateEditor';
import createRichTextAst from '@helpers/createRichTextAst';
import Spacer from '@components/Spacer';
import { m } from 'framer-motion';
import { getNoodleFrontendUrl, IDENTIFIERS } from '@helpers/urlsHelper';
import NoodleAI from '@components/Icons/NoodleAI';
import ProgressIndicator from '@components/ProgressIndicator';
import serializeToPlainText from '@helpers/slate/serializeToPlainText';
import { Descendant } from 'slate';
import s from './AIDocumentGenerationForm.module.scss';

type Props = {
  userWorkflowDocumentId: string;
  creatorSlug: string;
  onClose: () => void;
};

const AIDocumentGenerationForm: FC<Props> = ({ creatorSlug, userWorkflowDocumentId, onClose }) => {
  const [error, setError] = useState(false);
  const [text, setText] = useState('');
  const [slate, setSlate] = useState<{ children: Descendant[] }[]>([createRichTextAst('')]);
  const [isGenerated, setIsGenerated] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const { getData: generateDocumentPDF } = useNoodleApi(tsClient.workflows.generateUserWorkflowDocumentPDF);
  const { data: document, getData: getDocument} = useNoodleApi(tsClient.workflows.getUserWorkflowDocument);
  const {
    data: userWorkflow,
    getData: getUserWorkflowFn,
  } = useNoodleApi(tsClient.workflows.getUserWorkflowById, { toastOnError: true });

  const generateText = useCallback(async (): Promise<void> => {
    setIsGenerated(false);
    const response = await fetch(`${NOODLE_API_HOST}/workflows/user-workflows/documents/${userWorkflowDocumentId}/ai-generated-content-stream`, {
      headers: {
        Authorization: getCookiesAsJson()[JWT_COOKIE_NAME] || window.httpToken || '',
      },
    });
    const reader = response.body?.getReader();
    if (reader) {
      let chunk;
      do {
        // eslint-disable-next-line no-await-in-loop
        chunk = await reader.read();
        const textChunk = new TextDecoder().decode(chunk.value);
        if (textChunk) {
          if (textChunk.includes('"message":"Internal Server Error"')) {
            setError(true);
          } else {
            setText(prev => prev + textChunk);
          }
        }
      } while (!chunk?.done);
    }
    setIsGenerated(true);
  }, [userWorkflowDocumentId]);

  const saveDocument = async (): Promise<void> => {
    setIsSaving(true);
    const { data: newDocument } = await generateDocumentPDF({
      slate: slate[0],
      userWorkflowDocumentId,
    });
    if (newDocument) {
      window.open(getNoodleFrontendUrl(IDENTIFIERS.DOCUMENT_BY_ID, {
        creatorSlug,
        documentId: newDocument.id,
      }), '_blank');
      onClose();
    }
  };

  const regenerateDocument = async (): Promise<void> => {
    setText('');
    setIsGenerated(false);
    setIsEditing(false);
    setError(false);
    await generateText();
  };

  useEffect(() => {
    if (text && !document?.generatedContent) {
      setSlate([createRichTextAst(text)]);
    }
  }, [text, document]);

  useEffect(() => {
    if (document && !document.generatedContent) {
      generateText();
    }
  }, [generateText, document]);

  useEffect(() => {
    getDocument({ userWorkflowDocumentId });
  }, [getDocument, userWorkflowDocumentId]);

  useEffect(() => {
    if (document) {
      getUserWorkflowFn({ userWorkflowId: document.userWorkflowId });
    }
  }, [getUserWorkflowFn, document]);

  useEffect(() => {
    if (document?.generatedContent) {
      setText(serializeToPlainText([document.generatedContent]));
      setSlate([document.generatedContent]);
      setIsGenerated(true);
    }
  }, [document]);

  const isLoading = document && !text && !error;

  return (
    <div className={s.container}>
      {!document && <ProgressIndicator isCentered isPage />}
      {document && (
        <>
          <Header
            title={document.title}
            actions={[
              isGenerated && !isEditing
                ? {
                  label: 'Edit',
                  onClick: () => {
                    if (!document?.generatedContent) {
                      setSlate([createRichTextAst(text)]);
                    }
                    setIsEditing(true);
                  },
                }
                : null,
              isGenerated
                ? {
                  isFetching: isSaving,
                  label: 'Save Document',
                  onClick: saveDocument,
                }
                : null,
              isGenerated && document?.generatedContent
                ? {
                  label: 'Regenerate Document',
                  onClick: regenerateDocument,
                }
                : null,
            ].filter(removeNullish)}
          />
          <PageContext
            isFetching={!userWorkflow}
            items={userWorkflowToContext(userWorkflow)}
          />
        </>
      )}
      {isLoading && (
        <>
          <m.div animate={{ scale: 0.8 }} transition={{ duration: 1, repeat: Infinity, repeatType: 'mirror' }}>
            <NoodleAI size={64} />
          </m.div>
          <p className="caption">Your AI Assistant is writing the copy for this document. Hold tight.</p>
        </>
      )}

      {error && <p style={{ color: 'var(--color-error)' }}>Failed to generate document. Please contact support.</p>}
      {text && !isEditing && <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(text.replace(/\n/g, '<br>')) }} />}
      {isEditing && (
        <>
          <Spacer size={24} />
          <SlateEditor
            placeholder='Edit your document...'
            id='editor'
            name='editor'
            isMediaButton={false}
            slateValue={slate}
            onChange={setSlate}
          />
        </>
      )}
    </div>
  );
};

export default AIDocumentGenerationForm;
