import React, {
  Suspense, useState, useEffect, FC, Fragment,
} from 'react';
import { Media } from '@typings/graphql-models';
import AddVideoCMP from './AddVideo';

type Props = {
  disabled: boolean;
  onUploadVideo: () => void;
  isLoomEnabled?: boolean;
  isVideoEnabled?: boolean;
  onAttachLoomMedia?: (media : Media) => void;
  jobCorrelationId?: string;
};

const AddVideo:FC<Props> = (props) => {
  const [isBrowser, setIsBrowser] = useState(false);

  useEffect(() => {
    setIsBrowser(true);
  }, []);

  return (
    <Fragment>
      {isBrowser && (
        <Suspense fallback={<div></div>}>
          <AddVideoCMP {...props} />
        </Suspense>
      )}
    </Fragment>
  );
};

export default AddVideo;
