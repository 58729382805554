import { useCallback, useRef } from 'react';
import debounce from 'lodash/debounce';
import MagnifyingGlass from '@components/Icons/MagnifyingGlass';
import s from './Search.module.scss';

type Props = {
  ariaLabel?: string;
  placeholder?: string;
  onChange: (search: string) => void;
  wait?: number;
};

const Search: React.FC<Props> = ({ ariaLabel, placeholder = 'Search', wait = 500, onChange }) => {
  const onChangeRef = useRef(onChange);
  onChangeRef.current = onChange;

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = useCallback( // eslint-disable-line react-hooks/exhaustive-deps
    debounce(
      event => onChangeRef.current(event.target.value || ''),
      wait,
    ),
    [wait],
  );

  return (
    <div className={s.wrapper}>
      <MagnifyingGlass size={16} color="var(--color-gray-100)" />
      <input
        type="search"
        placeholder={placeholder}
        aria-label={ariaLabel || placeholder}
        onChange={handleChange}
      />
    </div>
  );
};

export default Search;
