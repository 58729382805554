import { useContext, useState, useRef, useCallback, useEffect, useMemo } from 'react';
import { nanoid } from 'nanoid';
import { IDENTIFIERS, getUrl } from '@helpers/urlsHelper';
import useNoodleApi from '@hooks/useNoodleApi';
import TeamsContext from '@providers/Teams/TeamsContext';
import * as tsClient from '@tsClient';
import * as format from '@format';
import { useFeatureFlag } from '@/providers/FeatureFlags';
import { useQueryState } from 'nuqs';
import { MESSAGE_ATTACHMENT_REFERENCE_TYPES, WORKFLOW_ATTACHMENT_STATUS } from '@typings/api-models';
import { useOpenCustomTermsPane } from '@/panes/customTermsHooks';
import { useOpenFormRequestPane } from '@/panes/formRequestHooks';
import { useOpenInvoicePane } from '@/panes/invoiceHooks';
import { useOpenDocumentRequestUserPane } from '@/panes/documentRequestUserHooks';
import { useRouter } from 'next/router';
import {
  type HookResponse,
  type ListViewRegularItem,
  type TableCell,
  type TableRow,
  type TableColumn,
  addHeaderKeyToCell,
} from './common';
import { formatUserWorkflowContext } from './useActivity';

type ThisWorkflowAttachment = Awaited<ReturnType<typeof tsClient.creators.getCreatorWorkflowAttachments>>['items'][number];

type AttachmentTypesFilter =
| MESSAGE_ATTACHMENT_REFERENCE_TYPES.INVOICE
| MESSAGE_ATTACHMENT_REFERENCE_TYPES.CUSTOM_TERMS
| MESSAGE_ATTACHMENT_REFERENCE_TYPES.CREDIT_REPORT
| MESSAGE_ATTACHMENT_REFERENCE_TYPES.DOCUMENT_REQUEST_USER
| MESSAGE_ATTACHMENT_REFERENCE_TYPES.FORM_REQUEST
| MESSAGE_ATTACHMENT_REFERENCE_TYPES.BOOKING;

interface StatusProperty {
  label: string;
  color: string;
}

const StatusToBadge: Record<WORKFLOW_ATTACHMENT_STATUS, StatusProperty> = {
  complete: { color: 'var(--color-workflow-completed)', label: 'Complete' },
  'in-progress': { color: 'var(--color-workflow-data-collection)', label: 'In Progress' },
};

const getAttachmentTitle = (attachment: ThisWorkflowAttachment): string => {
  if (attachment.referenceType === MESSAGE_ATTACHMENT_REFERENCE_TYPES.CREDIT_REPORT) {
    return 'Credit Report';
  }

  if (attachment.referenceType === MESSAGE_ATTACHMENT_REFERENCE_TYPES.INVOICE) {
    return attachment.data.invoice.title || attachment.data.invoice.fallbackTitle || 'Invoice';
  }

  if (attachment.referenceType === MESSAGE_ATTACHMENT_REFERENCE_TYPES.CUSTOM_TERMS) {
    return attachment.data.template.title || 'Terms';
  }

  if (attachment.referenceType === MESSAGE_ATTACHMENT_REFERENCE_TYPES.FORM_REQUEST) {
    return attachment.data.template.name;
  }

  if (attachment.referenceType === MESSAGE_ATTACHMENT_REFERENCE_TYPES.DOCUMENT_REQUEST_USER) {
    return attachment.data.documentRequest.title;
  }

  if (attachment.referenceType === MESSAGE_ATTACHMENT_REFERENCE_TYPES.BOOKING) {
    return attachment.data.booking.price.priceTitle || 'Booking';
  }

  return 'N/A';
};

const getAttachmentTypeText = (type: MESSAGE_ATTACHMENT_REFERENCE_TYPES): string => {
  if (type === MESSAGE_ATTACHMENT_REFERENCE_TYPES.CREDIT_REPORT) {
    return 'Credit Report';
  }

  if (type === MESSAGE_ATTACHMENT_REFERENCE_TYPES.PAYMENT_LINK) {
    return 'Payment Link';
  }

  if (type === MESSAGE_ATTACHMENT_REFERENCE_TYPES.INVOICE_REQUEST || type === MESSAGE_ATTACHMENT_REFERENCE_TYPES.INVOICE) {
    return 'Invoice';
  }

  if (type === MESSAGE_ATTACHMENT_REFERENCE_TYPES.CUSTOM_TERMS || type === MESSAGE_ATTACHMENT_REFERENCE_TYPES.CUSTOM_TERMS_TEMPLATE) {
    return 'Custom Terms';
  }

  if (type === MESSAGE_ATTACHMENT_REFERENCE_TYPES.FORM_REQUEST) {
    return 'Form Request';
  }

  if (type === MESSAGE_ATTACHMENT_REFERENCE_TYPES.DOCUMENT_REQUEST_USER) {
    return 'Document Request';
  }

  if (type === MESSAGE_ATTACHMENT_REFERENCE_TYPES.BOOKING_PRICE || type === MESSAGE_ATTACHMENT_REFERENCE_TYPES.BOOKING) {
    return 'Booking';
  }

  return 'N/A';
};

const statusToBadge = (status: WORKFLOW_ATTACHMENT_STATUS): ListViewRegularItem['badge'] | undefined => {
  if (status in StatusToBadge) {
    return StatusToBadge[status];
  }
  return undefined;
};

const TABLE_COLUMNS: TableColumn[] = [
  {
    key: 'task',
    label: 'Task',
  },
  {
    key: 'contact',
    label: 'Contact',
  },
  {
    key: 'workflow',
    label: 'Workflow',
  },
  {
    key: 'type',
    label: 'Type',
  },
  {
    key: 'status',
    label: 'Status',
  },
  {
    key: 'assignees',
    label: 'Assignees',
  },
  {
    key: 'context',
    label: 'Context',
  },
  {
    columnId: 'createdAt',
    key: 'created-at',
    label: 'Created at',
  },
  {
    columnId: 'lastActivityAt',
    key: 'recent-activity',
    label: 'Recent activity',
  },
];

const curriedAddHeaderKeyToCell = addHeaderKeyToCell(TABLE_COLUMNS);

const getDetailPagePath: HookResponse['getDetailPagePath'] = ({ id }) => getUrl(IDENTIFIERS.DASHBOARD_USER_WORKFLOW_DETAIL, { userWorkflowId: id });

const useAttachments = (): HookResponse => {
  const router = useRouter();
  const useTableView = useFeatureFlag('table-view');
  const { creatorId } = useContext(TeamsContext);
  const [attachments, setAttachments] = useState<ThisWorkflowAttachment[]>([]);
  const [itemsCount, setItemsCount] = useState<number | undefined>(undefined);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [currentPage, setCurrentPage] = useState(1);
  const [isFetching, setIsFetching] = useState(false);
  const [selectedStatuses, setSelectedStatuses] = useState<WORKFLOW_ATTACHMENT_STATUS[]>([]);
  const [numPages, setNumPages] = useState(0);
  const [currentSortDirection, setCurrentSortDirection] = useState<'asc' | 'desc'>('desc');
  const [currentSortField, setCurrentSortField] = useState<string>('lastActivityAt');
  const mostRecentRequestRef = useRef<string | null>(null);
  const { getData: getWorkflowAttachmentsFn } = useNoodleApi(tsClient.creators.getCreatorWorkflowAttachments);
  const [customTermsFilterValue, setCustomTermsFilterValue] = useQueryState('custom-terms');
  const [documentRequestUserFilterValue, setDocumentRequestUserFilterValue] = useQueryState('document-request-user');
  const [formRequestFilterValue, setFormRequestFilterValue] = useQueryState('form-request');
  const [invoiceFilterValue, setInvoiceFilterValue] = useQueryState('invoice');
  const [bookingFilterValue, setBookingFilterValue] = useQueryState('booking');
  const [creditReportFilterValue, setCreditReportFilterValue] = useQueryState('credit-report');
  const [viewTable] = useQueryState('table');
  const openCustomTermsPane = useOpenCustomTermsPane();
  const openFormRequestPane = useOpenFormRequestPane();
  const openInvoicePane = useOpenInvoicePane();
  const openDocumentRequestUserPane = useOpenDocumentRequestUserPane();

  const handleOpenAttachmentClick = (referenceType: MESSAGE_ATTACHMENT_REFERENCE_TYPES, referenceId: string, userWorkflowId: string | null): void => {
    if (referenceType === MESSAGE_ATTACHMENT_REFERENCE_TYPES.CUSTOM_TERMS) {
      openCustomTermsPane({ customTermsId: referenceId });
    }
    if (referenceType === MESSAGE_ATTACHMENT_REFERENCE_TYPES.INVOICE) {
      openInvoicePane({ invoiceId: referenceId });
    }
    if (referenceType === MESSAGE_ATTACHMENT_REFERENCE_TYPES.DOCUMENT_REQUEST_USER) {
      openDocumentRequestUserPane({ documentRequestUserId: referenceId });
    }
    if (referenceType === MESSAGE_ATTACHMENT_REFERENCE_TYPES.FORM_REQUEST) {
      openFormRequestPane({ formRequestId: referenceId });
    }
    // These types don't have panes so route to userWorkflow intead
    if (referenceType === MESSAGE_ATTACHMENT_REFERENCE_TYPES.BOOKING && userWorkflowId) {
      router.push(getUrl(IDENTIFIERS.DASHBOARD_USER_WORKFLOW_DETAIL, { userWorkflowId }));
    }
    if (referenceType === MESSAGE_ATTACHMENT_REFERENCE_TYPES.CREDIT_REPORT && userWorkflowId) {
      router.push(getUrl(IDENTIFIERS.DASHBOARD_USER_WORKFLOW_DETAIL, { userWorkflowId }));
    }
  };

  const attachmentToTableRow = ({
    attachment,
  }: {
    attachment: Awaited<ReturnType<typeof tsClient.creators.getCreatorWorkflowAttachments>>['items'][number];
  }): TableRow => {
    const statusBadge = statusToBadge(attachment.status);
    const cells: TableCell[] = [
      {
        cta: 'Open task',
        label: getAttachmentTitle(attachment),
        onClick: () => handleOpenAttachmentClick(attachment.referenceType, attachment.referenceId, attachment.userWorkflow?.id || null),
        type: 'open-on-click',
      },
      attachment.userWorkflow?.person
        ? {
          href: getUrl(IDENTIFIERS.DASHBOARD_MEMBER, { id: attachment.userWorkflow.person.id }),
          label: attachment.userWorkflow.person.name || 'Contact',
          type: 'link',
        }
        : {
          type: 'empty',
        },
      attachment.userWorkflow
        ? {
          href: getUrl(IDENTIFIERS.DASHBOARD_USER_WORKFLOW_DETAIL, { userWorkflowId: attachment.userWorkflow.id }),
          label: attachment.userWorkflow.title,
          type: 'link',
        }
        : {
          type: 'empty',
        },
      {
        content: getAttachmentTypeText(attachment.referenceType),
        type: 'text',
      },
      statusBadge
        ? {
          type: 'badge',
          ...statusBadge,
        }
        : {
          type: 'empty',
        },
      attachment.assignees.length > 0
        ? {
          assignees: attachment.assignees,
          type: 'attachment-assignees',
        }
        : {
          type: 'empty',
        },
      attachment.userWorkflow
        ? {
          content: formatUserWorkflowContext(attachment.userWorkflow.context),
          type: 'text',
        }
        : {
          type: 'empty',
        },
      {
        content: format.datetime.withoutTimezone({datetime:attachment.createdAt}),
        type: 'text',
      },
      {
        content: format.datetime.withoutTimezone({datetime:attachment.lastActivityAt}),
        type: 'text',
      },
    ];

    return {
      cells: cells.map(curriedAddHeaderKeyToCell),
      id: attachment.id,
    };
  };

  const TABLE_COLUMNS_WITH_SORT = useMemo(() => TABLE_COLUMNS.map(column => ({
    ...column,
    sort: column.columnId
      ? {
        currentSortByField: currentSortField,
        currentSortDirection,
        onSort: (columnId: string, direction: 'asc' | 'desc') => {
          setCurrentSortField(columnId);
          setCurrentSortDirection(direction);
        },
      }
      : undefined,
  })), [currentSortField, currentSortDirection, setCurrentSortField, setCurrentSortDirection]);

  const selectedAttachmentTypeFilters = useMemo(() => {
    const allFilters: Record<AttachmentTypesFilter, string | null> = {
      'booking': bookingFilterValue,
      'credit-report': creditReportFilterValue,
      'custom-terms': customTermsFilterValue,
      'document-request-user': documentRequestUserFilterValue,
      'form-request': formRequestFilterValue,
      'invoice': invoiceFilterValue,
    };

    return Object.keys(allFilters).filter(key => allFilters[key as unknown as AttachmentTypesFilter] === 'true');
  }, [
    customTermsFilterValue,
    documentRequestUserFilterValue,
    formRequestFilterValue,
    invoiceFilterValue,
    bookingFilterValue,
    creditReportFilterValue,
  ]);

  const fetchAttachments = useCallback(
    async (
      {
        attachmentTypeFilters,
        page: newPage,
        search: newSearch,
        statuses,
        sortField,
        sortDirection,
      }:
      {
        attachmentTypeFilters?: string[];
        page: number;
        search: string;
        statuses: WORKFLOW_ATTACHMENT_STATUS[];
        sortField: string;
        sortDirection: 'asc' | 'desc';
      }): Promise<void> => {
      if (creatorId) {
        setCurrentPage(newPage);
        const thisRequestId = nanoid();
        mostRecentRequestRef.current = thisRequestId;
        setIsFetching(true);
        setAttachments([]);
        setCurrentPage(newPage);

        const response = await getWorkflowAttachmentsFn({
          attachmentTypeFilters,
          complete: statuses.includes(WORKFLOW_ATTACHMENT_STATUS.COMPLETE) || statuses.length === 0,
          creatorId,
          inProgress: statuses.includes(WORKFLOW_ATTACHMENT_STATUS.IN_PROGRESS) || statuses.length === 0,
          page: newPage,
          perPage: 20,
          search: newSearch,
          sortDirection,
          sortField,
        });

        if (thisRequestId === mostRecentRequestRef.current) {
          if (response.data) {
            setNumPages(response.data.numPages);
            setAttachments(response.data.items);
            setItemsCount(response.data.numItems);
          }
          setIsFetching(false);
        }
      }
    },
    [creatorId, getWorkflowAttachmentsFn],
  );

  const handleNewPage = (newPage: number): void => {
    fetchAttachments({
      attachmentTypeFilters: selectedAttachmentTypeFilters,
      page: newPage,
      search: searchTerm,
      sortDirection: currentSortDirection,
      sortField: currentSortField,
      statuses: selectedStatuses,
    });
  };

  useEffect(() => {
    fetchAttachments({
      attachmentTypeFilters: selectedAttachmentTypeFilters,
      page: 1,
      search: searchTerm,
      sortDirection: currentSortDirection,
      sortField: currentSortField,
      statuses: selectedStatuses,
    });
  }, [fetchAttachments, searchTerm, selectedStatuses, selectedAttachmentTypeFilters, currentSortDirection, currentSortField]);

  const pagination = {
    numPages,
    onChangePage: handleNewPage,
    page: currentPage,
  };

  const statusFilterItems = useMemo(() => {
    const statuses: Array<{
      isChecked: boolean;
      key: WORKFLOW_ATTACHMENT_STATUS;
      label: string;
    }> = [
      {
        isChecked: selectedStatuses.includes(WORKFLOW_ATTACHMENT_STATUS.IN_PROGRESS),
        key: WORKFLOW_ATTACHMENT_STATUS.IN_PROGRESS,
        label: 'In Progress',
      },
      {
        isChecked: selectedStatuses.includes(WORKFLOW_ATTACHMENT_STATUS.COMPLETE),
        key: WORKFLOW_ATTACHMENT_STATUS.COMPLETE,
        label: 'Complete',
      },
    ];
    return statuses;
  }, [
    selectedStatuses,
  ]);

  const attachmentTypeFilterItems = useMemo(() => {
    const attachmentTypes: Array<{
      isChecked: boolean;
      key: string;
      label: string;
    }> = [
      {
        isChecked: selectedAttachmentTypeFilters.includes('document-request-user'),
        key: 'document-request-user',
        label: `Document Requests`,
      },
      {
        isChecked: selectedAttachmentTypeFilters.includes('custom-terms'),
        key: 'custom-terms',
        label: `Custom Terms Requests`,
      },
      {
        isChecked: selectedAttachmentTypeFilters.includes('form-request'),
        key: 'form-request',
        label: `Form Requests`,
      },
      {
        isChecked: selectedAttachmentTypeFilters.includes('invoice'),
        key: 'invoice',
        label: `Invoice Requests`,
      },
      {
        isChecked: selectedAttachmentTypeFilters.includes('booking'),
        key: 'booking',
        label: `Booking Requests`,
      },
      {
        isChecked: selectedAttachmentTypeFilters.includes('credit-report'),
        key: 'credit-report',
        label: `Credit Reports`,
      },
    ];
    return attachmentTypes;
  }, [
    selectedAttachmentTypeFilters,
  ]);

  const filters: NonNullable<HookResponse['filters']> = {
    items: [
      {
        isMultiSelect: true,
        items: statusFilterItems,
        label: 'Status',
        onChange: (newStatuses) => setSelectedStatuses(newStatuses as WORKFLOW_ATTACHMENT_STATUS[]),
      },
      {
        isMultiSelect: true,
        items: attachmentTypeFilterItems,
        label: 'Task Type',
        onChange: (newFilters) => {
          if (newFilters.includes('custom-terms')) {
            setCustomTermsFilterValue('true');
          } else {
            setCustomTermsFilterValue('false');
          }

          if (newFilters.includes('document-request-user')) {
            setDocumentRequestUserFilterValue('true');
          } else {
            setDocumentRequestUserFilterValue('false');
          }

          if (newFilters.includes('form-request')) {
            setFormRequestFilterValue('true');
          } else {
            setFormRequestFilterValue('false');
          }

          if (newFilters.includes('invoice')) {
            setInvoiceFilterValue('true');
          } else {
            setInvoiceFilterValue('false');
          }

          if (newFilters.includes('booking')) {
            setBookingFilterValue('true');
          } else {
            setBookingFilterValue('false');
          }

          if (newFilters.includes('credit-report')) {
            setCreditReportFilterValue('true');
          } else {
            setCreditReportFilterValue('false');
          }
        },
      },
    ],
  };

  const tableRows = useMemo(() => attachments.map(attachment =>
    attachmentToTableRow({
      attachment,
    }),
  ), [attachments]);

  return {
    filters,
    getDetailPagePath,
    ifNoItemsText: 'No activity',
    isFetchingList: isFetching,
    items: [],
    itemsCount,
    onSearch: setSearchTerm,
    openTableView: viewTable === 'true',
    pagination,
    tableView: useTableView
      ? {
        columns: TABLE_COLUMNS_WITH_SORT,
        rows: tableRows,
      }
      : undefined,
  };
};

export default useAttachments;