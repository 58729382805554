import { Checkbox } from '@/components/DesignLibrary/Atoms';

type Props = {
  isInList: boolean;
  isViewableInTableView: boolean;
  onChange: (newValue: boolean) => void;
};

const IsViewableInTableView: React.FC<Props> = ({ isViewableInTableView, onChange, isInList }) => {
  if (!isInList) {
    return null;
  }

  return (
    <Checkbox
      label="Visible in table view"
      isChecked={isViewableInTableView}
      onChange={onChange}
      size="sm"
    />
  );
};

export default IsViewableInTableView;
