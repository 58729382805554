import { FollowupHistoryType } from '@/typings/api-models';
import CustomLink from '@components/CustomLink';
import UserImage from '@components/UserImage';
import * as format from '@format';
import { IDENTIFIERS, getUrl } from '@helpers/urlsHelper';
import DOMPurify from 'isomorphic-dompurify';
import { ComponentProps, FC, useState } from 'react';
import s from './FollowupCard.module.scss';
import FollowupTextModal from './FollowupTextModal/FollowupTextModal';

const FollowupCard: FC<{
  title: string;
  text: string;
  type: FollowupHistoryType;
  member: Pick<ComponentProps<typeof UserImage>, 'name' | 'url' | 'color'> & { id: string };
  date: Date;
  numActiveWorkflows: number;
}> = ({ title, text, type, member, date, numActiveWorkflows }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const getTypeText = (t: FollowupHistoryType): string => {
    if (t === FollowupHistoryType.EMAIL) {
      return 'Email';
    }
    return 'SMS';
  };

  return (
    <div className={s.wrapper}>
      {isModalOpen && (
        <FollowupTextModal
          followupText={text}
          onClose={() => setIsModalOpen(false)}
        />
      )}
      <div>
        <h2 className="body-sm-bold">{title}</h2>
        <CustomLink
          to={getUrl(IDENTIFIERS.DASHBOARD_MEMBER, {
            id: member.id,
            tab: 'workflows',
          })}
        >
          {numActiveWorkflows === 0 ? `View workflows` : `View ${numActiveWorkflows} workflow${numActiveWorkflows > 1 ? 's' : ''}`}
        </CustomLink>
      </div>
      <div
        className={s.text}
        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(text || '') }}
        onClick={() => setIsModalOpen(true)}
      />
      <div className={s.bottom}>
        <UserImage {...member} />
        <p className="caption">
          AI followed up with
          <br />
          <CustomLink
            to={getUrl(IDENTIFIERS.DASHBOARD_MEMBER, {
              id: member.id,
            })}
          >
            {member.name}
          </CustomLink>{' '}
          {`${format.datetime.timeSince({ date })} via ${getTypeText(type)}`}
        </p>
      </div>
    </div>
  );
};

export default FollowupCard;
