const LoomIcon: React.FC = () => (
  <svg
    width="24"
    height="20"
    viewBox="0 0 155 155"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M154.342 68.5886H109.21L148.296 46.0222L139.713 31.1508L100.626 53.7171L123.188 14.634L108.316 6.04533L85.7547 45.1284V0H68.5867V45.1327L46.0167 6.04533L31.1499 14.6297L53.7156 53.7128L14.6293 31.1508L6.04517 46.0179L45.1315 68.5843H0V85.7531H45.1271L6.04517 108.319L14.6293 123.191L53.7112 100.629L31.1456 139.712L46.0167 148.296L68.5824 109.209V154.342H85.7506V109.213L108.312 148.296L123.183 139.712L100.617 100.625L139.704 123.191L148.288 108.319L109.206 85.7571H154.333V68.5886H154.342ZM77.1708 100.52C64.2256 100.52 53.7328 90.0282 53.7328 77.0823C53.7328 64.1367 64.2256 53.6437 77.1708 53.6437C90.1161 53.6437 100.608 64.1367 100.608 77.0823C100.608 90.0282 90.1161 100.52 77.1708 100.52Z"
      fill="#625DF5"
    />
  </svg>
);

export default LoomIcon;
