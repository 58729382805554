import React, { useState, useEffect, useMemo, useCallback } from 'react';

import File from '@/components/Icons/File';
import { Input, TextArea } from '@/components/DesignLibrary/Atoms';
import Button from '@/components/DesignLibrary/Button';
import type { Questionnaire } from '@/typings/Questionnaire';
import slugify from '@/helpers/slugify';
import TabText from '@/components/DesignLibrary/Atoms/TabText';

import Conditions from '../Conditions';
import s from './SectionEditor.module.scss';

type SectionEditorProps = {
  section: Questionnaire.Section;
  usedSlugs: string[];
  usedKeys: string[];
  onUpdateSection: (updatedSection: Questionnaire.Section) => void;
  onRemoveSection: (sectionId: string) => void;
};

type Expression = Pick<Questionnaire.Expression, 'expression'> & {
  variables: Pick<Questionnaire.ExpressionVariable, 'variableName' | 'referenceId' | 'selector'>[];
};

const SectionEditor: React.FC<SectionEditorProps> = ({
  section,
  usedSlugs,
  usedKeys,
  onUpdateSection,
  onRemoveSection,
}) => {
  const [label, setLabel] = useState<string>(section.label);
  const [selectedTab, setSelectedTab] = useState('settings');

  const [key, setKey] = useState<string>(section.key);
  const [description, setDescription] = useState<string>(section.description);
  const [conditionExpression, setConditionExpression] = useState<Expression>(
    section.conditionExpression || {
      expression: '',
      variables: [],
    },
  );

  const isValidKey = useMemo(() => !usedKeys.includes(key), [usedKeys, key]);

  useEffect(() => {
    setLabel(section.label);
    setKey(section.key);
    setDescription(section.description);
    setConditionExpression(
      section.conditionExpression || {
        expression: '',
        variables: [],
      },
    );
  }, [section]);

  const handleSave = useCallback((): void => {
    if (label && key && isValidKey) {
      onUpdateSection({
        ...section,
        conditionExpression: conditionExpression.expression ? conditionExpression : null,
        description,
        key,
        label,
        slug: slugify.generateUniqSlug({
          existingSlugs: usedSlugs,
          name: label,
        }),
      });
    }
  }, [section, key, isValidKey, label, usedSlugs, description, onUpdateSection, conditionExpression]);

  const tabs = useMemo(
    () => [
      { key: 'settings', label: 'Settings', selected: selectedTab === 'settings' },
      { key: 'conditions', label: 'Conditions', selected: selectedTab === 'conditions' },
    ],
    [selectedTab],
  );

  const keyError: string | undefined = useMemo(() => {
    if (!key) {
      return 'Required';
    }

    if (!isValidKey) {
      return 'Key already used';
    }

    return undefined;
  }, [key, isValidKey]);

  return (
    <div className={s.sectionEditor}>
      <div className={s.sectionHeader}>
        <File />
        <span className={s.label}>Section Details</span>
      </div>
      <div className={s.sectionContent}>
        <div className={s.sectionContentTabs}>
          {tabs.map(tab => (
            <TabText {...tab} key={tab.key} onClick={() => setSelectedTab(tab.key)} />
          ))}
        </div>
        {selectedTab === 'settings' && (
          <>
            <Input label="" description="Section title" value={label} onChange={value => setLabel(value)} />
            <Input label="" description="Section key" value={key} error={keyError} onChange={value => setKey(value)} />
            <TextArea label="" description="Section description" value={description} onChange={setDescription} />
          </>
        )}
        {selectedTab === 'conditions' && <Conditions setConditionExpression={setConditionExpression} conditionExpression={conditionExpression} />}
        {selectedTab === 'settings' && (
          <>
            <Button variant="primary" size="sm" onClick={handleSave}>
              Save
            </Button>
            <Button
              variant="destructive"
              size="sm"
              onClick={() => {
                if (window.confirm('Are you sure you want to delete this section?')) {
                  onRemoveSection(section.id);
                }
              }}
            >
              Delete section
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export default SectionEditor;
