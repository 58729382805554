import React, {
  SyntheticEvent,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { m } from 'framer-motion';
import SecondaryActions from '@components/DesignLibrary/SecondaryActions';
import PaperPlaneRight from '@components/Icons/PaperPlaneRight';
import ToggleLeft from '@components/Icons/ToggleLeft';
import ToggleRight from '@components/Icons/ToggleRight';
import Plus from '@components/Icons/Plus';
import NoodleAI from '@components/Icons/NoodleAI';
import * as ApiModels from '@typings/api-models';
import Button from '@components/DesignLibrary/Button';
import ProgressIndicator from '@components/ProgressIndicator';
import File from '@components/Icons/File';
import FileSelector from '@components/FormFields/FileSelector';
import BeforeUnload from '@providers/Jobs/JobsContainer/BeforeUnload';
import FileAttachment from '@components/InputComposer/FileAttachment';
import Image from '@components/Icons/Image';
import Info from '@components/Icons/Info';
import VideoCamera from '@components/Icons/VideoCamera';
import { JobStatus, useJobContext } from '@providers/Jobs';
import { useIsMobile } from '@hooks';
import classNames from 'classnames';
import MessageTip from '@/components/MessageTip';

import s from './InputComposer.module.scss';

type AttachmentType = 'File' | 'Photo' | 'Video';

type Props = {
  ai?: {
    defaultActive?: boolean;
    onToggle?: (active: boolean) => void;
  };
  attachmentTypes?: Array<AttachmentType>;
  onSubmit: (value: { text: string; medias: { id: string }[] }) => void;
  isSending: boolean;
  onChange?: (value: string) => void;
  onChangeIsEdit?: (isEdit: boolean) => void;
  referenceId: string;
  referenceType: string;
  isAsync: boolean;
  jobCorrelationId?: string;
  collaborators?: { personId: string; name: string | null; }[];
  showSendButton?: boolean;
  className?: string;
  placeholder?: string;
  isEmbedded?: boolean;
};

export type InputComposerRef = {
  setValue: (val: { text: string | null }) => void;
  setIsEdit: (val: boolean) => void;
  sendMessage: () => void;
};

const AllAttachments: Parameters<typeof SecondaryActions>[0]['options'] = [
  {
    icon: File,
    value: 'File',
  },
  {
    icon: Image,
    value: 'Photo',
  },
  {
    icon: VideoCamera,
    value: 'Video',
  },
];

// eslint-disable-next-line react/display-name
const InputComposer = forwardRef<InputComposerRef, Props>(({
  ai,
  attachmentTypes,
  onSubmit,
  isSending = false,
  onChange,
  onChangeIsEdit,
  isAsync,
  jobCorrelationId,
  referenceType,
  referenceId,
  collaborators,
  showSendButton = true,
  className,
  placeholder,
  isEmbedded = false,
}, ref) => {
  const [value, setValue] = useState('');
  const [isEdit, setIsEdit] = useState(false);
  const [aiActive, setAiActive] = useState(false);
  const [filesToUpload, setFiles] = useState<(File | string)[]>([]);
  const [medias, setMedias] = useState<(Pick<ApiModels.Media, 'id' | 'name' | 'type'> & { uploadPercentage?: number })[]>([]);
  const [collaboratorMatches, setCollaboratorMatches] = useState<{ personId: string; name: string; }[]>([]);
  const [cursorPosition, setCursorPosition] = useState(0);

  const textareaRef = useRef<HTMLDivElement>(null);
  const inputFileRef = useRef<HTMLLabelElement>(null);
  const hiddenInputRef = useRef<HTMLInputElement>(null);

  const isMobile = useIsMobile();

  const { jobs } = useJobContext();

  const fileLoadingInProcess = medias.length !== filesToUpload.length;

  useEffect(() => {
    if (textareaRef.current && textareaRef.current.scrollHeight !== 0) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [value, textareaRef, cursorPosition]);

  useEffect(() => {
    if (onChangeIsEdit) {
      onChangeIsEdit(isEdit);
    }
  }, [isEdit, onChangeIsEdit]);

  useEffect(() => {
    if (onChange) {
      onChange(value);
    }
  }, [onChange, value]);

  const collaboratorsWithSearchKey = useMemo(() => (collaborators || []).filter(c => c.name)
    .sort((a, b) => (b.name?.length || 0) - (a.name?.length || 0))
    .map((collaborator) => ({
      ...collaborator,
      exactSearchKey: `@${collaborator.name?.replace(/\(/g, '\\(')?.replace(/\)/g, '\\)')}`,
      searchKey: `@${collaborator.name?.toLowerCase?.()}`,
    })), [collaborators]);

  const assignPerson = (match: { personId: string; name: string; }): void => {
    const latestAtIndex = value.substring(0, cursorPosition).lastIndexOf('@');
    if (latestAtIndex !== -1 && textareaRef.current) {
      const collaborator = collaboratorsWithSearchKey.find((c) => c.personId === match.personId);
      if (collaborator) {
        let atNumber = 0;
        let replaceTextStart = 0;
        for (let i = 0; i < latestAtIndex + 1; i += 1) {
          if (value.charAt(i) === '@') {
            atNumber += 1;
            replaceTextStart = i;
          }
        }
        const textToReplace = value.substring(latestAtIndex, cursorPosition);
        const newValue = `${value.substring(0, replaceTextStart)}${value.substring(replaceTextStart).replace(textToReplace, `@${collaborator.name}`)}`;
        setValue(newValue);
        let currentHTMLAtNumber = 0;
        let replaceHTMLStart = 0;
        for (let i = 0; i < textareaRef.current.innerHTML.length; i += 1) {
          if (textareaRef.current.innerHTML.charAt(i) === '@') {
            currentHTMLAtNumber += 1;
            if (currentHTMLAtNumber === atNumber) {
              replaceHTMLStart = i;
              break;
            }
          }
        }
        textareaRef.current.innerHTML = `${textareaRef.current.innerHTML.substring(0, replaceHTMLStart)}${textareaRef.current.innerHTML.substring(replaceHTMLStart).replace(textToReplace, `<span style="color: var(--color-noodle);">@${collaborator.name}</span>&nbsp;`)}`;
        hiddenInputRef.current?.focus?.();
        textareaRef.current.focus();
        const range = document.createRange?.();
        if (range) {
          range.selectNodeContents(textareaRef.current);
          range.collapse(false);
          const selection = window.getSelection();
          if (selection) {
            selection.removeAllRanges();
            selection.addRange(range);
          }
        }
      }
    }
    setCollaboratorMatches([]);
  };

  /*
  It should pop up when an @ is entered and is the first character or preceded by a blank space.
  It should stay popped up until the next character that is entered does not match any available option
   */
  useEffect(() => {
    if (textareaRef.current) {
      // Find closest @ symbol to cursor position
      const latestAtIndex = value.substring(0, cursorPosition).lastIndexOf('@');
      // If the @ is the first character, or if the @ is preceeded by a space, find collaborators that match
      if (latestAtIndex !== -1 && (latestAtIndex === 0 || value.charAt(latestAtIndex - 1) === ' ')) {
        const searchString = value.substring(latestAtIndex, cursorPosition).toLowerCase();
        const matches = collaboratorsWithSearchKey.filter((c) => c.name && c.searchKey.includes(searchString));
        // If full name is entered, continue
        const perfectMatch = matches.find(match => searchString === match.searchKey && match.name);
        if (perfectMatch) {
          setCollaboratorMatches([]);
        } else {
          // Name should never be null here, but TS is complaining
          setCollaboratorMatches(matches.map(match => ({ ...match, name: match.name || '' })));
        }
      } else {
        setCollaboratorMatches([]);
      }
    }
  }, [value, collaboratorsWithSearchKey, textareaRef, cursorPosition]);

  useEffect(() => {
    setAiActive(ai?.defaultActive ?? false);
  }, [ai]);

  useEffect(() => {
    if (value === '\n') {
      setValue('');
    }
    if (value === '' && textareaRef.current && hiddenInputRef.current) {
      textareaRef.current.innerHTML = '';
    }
  }, [value]);

  const handleSubmit = (event?: Pick<SyntheticEvent, 'preventDefault'>): void => {
    event?.preventDefault();
    let text = value;
    collaboratorsWithSearchKey.forEach(collaborator => {
      text = text.replace(new RegExp(collaborator.exactSearchKey, 'g'), `{{@${collaborator.personId}}}`);
    });
    onSubmit({
      medias,
      text,
    });
    setValue('');
    setFiles([]);
    setMedias([]);
    setIsEdit(false);
    if (textareaRef.current) {
      textareaRef.current.innerHTML = '';
    }
  };

  useImperativeHandle(ref, () => ({
    sendMessage: handleSubmit,
    setIsEdit: (val: boolean): void => setIsEdit(val),
    setValue: ({ text }: { text: string | null }): void => {
      setValue(text || '');
      if (textareaRef.current) {
        textareaRef.current.innerHTML = text || '';
      }
    },
  }));

  const onUploadFile = ({ mediaId, name, type }: { mediaId: string; name: string; type: string }): void => {
    setMedias(prev => [...prev, { id: mediaId, name, type }]);
  };

  const onToolbarClick = (type: string, accept: string): void => {
    if (type === 'file' && inputFileRef.current) {
      (inputFileRef.current.firstChild as HTMLInputElement)?.setAttribute('accept', accept);
      inputFileRef.current.click();
    }
  };

  const handleAttachmentClick = (type: string): void => {
    if (type === 'File') {
      onToolbarClick('file', '*');
    } else if (type === 'Video') {
      onToolbarClick('file', 'video/*');
    } else if (type === 'Photo') {
      onToolbarClick('file', 'image/*');
    }
  };

  const handleFileUpload = async (files: string | File[]): Promise<void> => {
    const arr: (string | File)[] = [];
    if (typeof files === 'string') {
      arr.push(files);
    } else {
      arr.push(...files);
    }
    setFiles(prev => [...prev, ...arr]);
  };

  const handleRemoveFile = (file: string | File) => () => {
    setFiles(prevFiles => prevFiles.filter(f => f !== file));
  };

  const handleCancelEdit = (event: Pick<SyntheticEvent, 'preventDefault'>): void => {
    event?.preventDefault();
    setValue('');
    setIsEdit(false);
  };

  const hasContent = (isAsync ? filesToUpload.length > 0 : medias.length > 0)
    || value.trim().length !== 0;
  const isSendButtonEnabled = hasContent
    && (isAsync
      ? !jobs.find(j => j.correlationId === jobCorrelationId && j.status === JobStatus.FAILED)
      : !fileLoadingInProcess
    );

  return (
    <>
      <MessageTip dontShowDays={1000} buttonClassName={s.tipButton} containerClassName={s.tip} messageTipKey='tag-collaborator'>
        <Info color="var(--color-noodle)" size={12} weight='fill' /><p className='caption'>Use @ to tag a collaborator on the case</p>
      </MessageTip>
      <input className={s.hiddenInput} ref={hiddenInputRef} />
      {collaboratorMatches.length > 0 && (
        <div className={s.collaboratorMatches} style={{ bottom: `${(textareaRef.current?.scrollHeight || 0) + 4}px`, left: 4, position: isEmbedded ? 'initial' : 'absolute' }}>
          {collaboratorMatches.map((match) => (
            <button key={match.personId} className={s.match} onClick={() => assignPerson(match)}>
              {match.name}
            </button>
          ))}
        </div>
      )}
      <m.div layout="position" className={classNames(s.composer, className)}>
        {filesToUpload.length > 0 && <BeforeUnload />}
        <FileSelector id={jobCorrelationId} ref={inputFileRef} uploadFunction={handleFileUpload} />
        {ai && (
          <label className={s.composerAi}>
            <NoodleAI size={aiActive ? 20 : 12} />
            <span>AI Assistant is {aiActive ? 'on' : 'off'}</span>
            {aiActive
              ? (
                <ToggleRight size={24} weight="fill" color="var(--color-noodle)" />
              )
              : (
                <ToggleLeft size={24} weight="fill" color="var(--color-gray-25)" />
              )}
            <input
              type="checkbox"
              defaultChecked={ai.defaultActive}
              onChange={e => {
                setAiActive(e.target.checked);
                ai.onToggle?.(e.target.checked);
              }}
            />
          </label>
        )}
        {!aiActive && (
          <>
            <form
              style={{ flexDirection: isEdit ? 'column' : 'row'}}
              onSubmit={handleSubmit}
            >
              {attachmentTypes && !isEdit && (
                <div className={s.composerAttachments}>
                  <SecondaryActions
                    position="top-left"
                    ariaLabel="Attach"
                    hideTooltip
                    onSelect={handleAttachmentClick}
                    options={AllAttachments.filter(a => attachmentTypes?.includes?.(a.value as AttachmentType))}
                    customIcon={Plus}
                  />
                </div>
              )}
              <div
                contentEditable
                className={s.textarea}
                data-ph={placeholder || "Type your message"}
                ref={textareaRef}
                onInput={e => {
                  setValue((e.target as HTMLElement).innerText);
                }}
                {...((!attachmentTypes || isEdit) && {
                  style: {
                    paddingLeft: 16,
                  },
                })}
                onSelect={() => {
                  const range = window.getSelection?.()?.getRangeAt?.(0);
                  if (range && textareaRef.current) {
                    const preCaretRange = range.cloneRange();
                    preCaretRange.selectNodeContents(textareaRef.current);
                    preCaretRange.setEnd(range.endContainer, range.endOffset);
                    setCursorPosition(preCaretRange.toString().length);
                  }
                }}
                {...isMobile && {
                  style: {
                    fontSize: 16,
                  },
                }}
              />
              {showSendButton && (
                <>
                  {isEdit
                    ? (
                      <div className={s.edit}>
                        <Button
                          variant='secondary'
                          size='sm'
                          onClick={handleCancelEdit}
                        >
                          Cancel Edit
                        </Button>
                        <Button
                          variant='primary'
                          size='sm'
                          onClick={handleSubmit}
                          loading={isSending}
                          disabled={!isSendButtonEnabled}
                        >
                          Save
                        </Button>
                      </div>
                    )
                    : (
                      <button
                        className={s.sendButton}
                        disabled={!isSendButtonEnabled || isSending}
                        aria-label="Send message"
                        onClick={handleSubmit}
                      >
                        {isSending ? <ProgressIndicator /> : <PaperPlaneRight width={24} weight="fill" />}
                      </button>
                    )}
                </>
              )}
            </form>
            <div className={s.attachments}>
              {filesToUpload.map(file => (
                <FileAttachment
                  isAsync={isAsync}
                  key={typeof file === 'string' ? file : file.name}
                  file={file}
                  media={medias.find(media => media.name === (typeof file === 'string' ? file : file.name))}
                  onUploadFile={onUploadFile}
                  jobCorrelationId={jobCorrelationId}
                  referenceId={referenceId}
                  referenceType={referenceType}
                  removeFile={handleRemoveFile(file)}
                  removeMedia={(mediaId: string) => setMedias(medias.filter(f => f.id !== mediaId))}
                />
              ))}
            </div>
          </>
        )}
      </m.div>
    </>
  );
});

export default InputComposer;
