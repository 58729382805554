import React, { useContext, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import Head from 'next/head';

import { mixpanelTrack } from '@providers/Mixpanel';
import useNoodleApi from '@hooks/useNoodleApi';
import * as tsClient from '@tsClient';
import ProgressIndicator from '@components/ProgressIndicator';
import Spacer from '@components/Spacer';
import DocumentPreview from '@components/Document/DocumentPreview';
import { DocumentAccessContext, DocumentAccessGate } from '@providers/DocumentAccess';
import { IDENTIFIERS, getUrl } from '@helpers/urlsHelper';
import * as ApiModels from '@typings/api-models';
import removeNullish from '@helpers/removeNullish';
import TeamsContext from '@providers/Teams/TeamsContext';
import BannerStatus from '@components/DesignLibrary/BannerStatus';
import ConfirmationModal from '@modals/ConfirmationModal';
import DashboardContext from '@layouts/DashboardLayout/DashboardContext';
import HandCoins from '@components/Icons/HandCoins';
import { Prohibit } from '@phosphor-icons/react';

import { useUser } from '@hooks';
import InvoicePaymentsWidget from '@components/InvoicePaymentsWidget';
import MainPanelSubLayout from '@layouts/MainPanelSubLayout';
import InvoiceProgressTooltip from '@components/DesignLibrary/InvoiceProgressTooltip';
import userWorkflowToContext from '@helpers/userWorkflowToContext';
import UserProfileContext from '@layouts/UserProfileLayout/UserProfileContext';
import PayInvoiceOutsideNoodleModal from '@modals/PayInvoiceOutsideNoodleModal';
import ConfidoPaymentModal from '@modals/ConfidoPaymentModal';
import CurrencyCircleDollar from '@components/Icons/CurrencyCircleDollar';
import PaymentPlanModal from '@modals/PaymentPlanModal';
import { useFeatureFlag } from '@providers/FeatureFlags';
import ArrowClockwise from '@components/Icons/ArrowClockwise';
import CancelPaymentPlanModal from '@modals/CancelPaymentPlanModal';
import s from './InvoicePreview.module.scss';

type Props = {
  invoiceId: string;
  creatorSlug: string;
};

const InvoicePreview: React.FC<Props> = ({ invoiceId, creatorSlug }) => {
  const { data: invoice, getData: getInvoiceFn } = useNoodleApi(tsClient.invoices.getInvoice, { toastOnError: true });
  const { getData: voidInvoiceFn } = useNoodleApi(tsClient.invoices.voidInvoice);
  const { accessToken } = useContext(DocumentAccessContext);
  const { currentTeam, creatorId } = useContext(TeamsContext);
  const { isInDashboard } = useContext(DashboardContext);
  const { isInUserProfile } = useContext(UserProfileContext);
  const [isPayOutsideModalOpen, setPayOutsideModalOpen] = useState(false);
  const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [isConfidoPaymentModalOpen, setConfidoPaymentModalOpen] = useState(false);
  const [isPaymentPlanModalOpen, setPaymentPlanModalOpen] = useState(false);
  const [isCancelPaymentPlanModalOpen, setCancelPaymentPlanModalOpen] = useState(false);
  const [user] = useUser();

  const useConfidoCart = useFeatureFlag('confido-cart');

  const router = useRouter();

  const { data: userWorkflow,
    fetchingState: userWorkflowFetchingState,
    getData: getUserWorkflowFn } = useNoodleApi(tsClient.workflows.getUserWorkflowById);

  const { data: payments, getData: getPaymentsFn } = useNoodleApi(tsClient.invoices.getInvoicePayments);

  useEffect(() => {
    mixpanelTrack('InvoicePreview - viewed', { creatorSlug, invoiceId });
  }, [creatorSlug, invoiceId]);

  useEffect(() => {
    getInvoiceFn({ id: invoiceId });
    getPaymentsFn({ id: invoiceId, page: 1, perPage: 1000 });
  }, [getPaymentsFn, getInvoiceFn, invoiceId]);

  useEffect(() => {
    if (invoice?.userWorkflowId) {
      getUserWorkflowFn({ userWorkflowId: invoice.userWorkflowId });
    }
  }, [invoice, getUserWorkflowFn]);

  const payInvoice = (onBehalfOfClient: boolean): void => {
    if (creatorSlug && invoice) {
      if (invoice.confidoPaymentLink && !useConfidoCart) {
        setConfidoPaymentModalOpen(true);
      } else {
        router.push(
          getUrl(IDENTIFIERS.PAY_INVOICE, {
            creatorSlug,
            invoiceId,
            onBehalfOfPersonId: onBehalfOfClient ? invoice.personId : null,
          }),
        );
      }
    }
  };

  const voidInvoice = async (): Promise<void> => {
    await voidInvoiceFn({ id: invoiceId });
    await getInvoiceFn({ id: invoiceId });
    setConfirmationModalOpen(false);
  };

  const refetchData = async (): Promise<void> => {
    await Promise.all([
      getInvoiceFn({ id: invoiceId }),
      getPaymentsFn({ id: invoiceId, page: 1, perPage: 1000 }),
    ]);
  };

  if (!invoice) {
    return (
      <>
        <Spacer size={80} />
        <ProgressIndicator isPage isCentered />
      </>
    );
  }

  const canVoid = currentTeam && invoice.status === ApiModels.INVOICE_STATUS.IN_PROGRESS;
  const isSkipped = invoice.status === ApiModels.INVOICE_STATUS.SKIPPED;

  const hasAccess = user?.id === invoice.personId || accessToken;
  const showPayAction = invoice.canBePaid && invoice.hasPayPermission;
  const showPayOutsideAction = invoice.canBePaid && isInDashboard && creatorId === invoice.creatorId;
  const showPaymentPlanAction = (
    invoice.isPartialPaymentEnabled
    && invoice.canBePaid
  );

  const actions = [
    showPayAction && isInDashboard
      ? {
        icon: HandCoins,
        label: "Make payment on behalf of client",
        onClick: () => payInvoice(true),
      }
      : null,
    showPayAction && !isInDashboard
      ? {
        icon: HandCoins,
        label: "Pay Invoice",
        onClick: () => payInvoice(false),
      }
      : null,
    showPaymentPlanAction
      ? {
        icon: invoice.paymentPlan ? ArrowClockwise : CurrencyCircleDollar,
        isDestructive: false,
        isSecondary: true,
        label: invoice.paymentPlan ? 'Modify payment plan' : 'Set up payment plan',
        onClick: () => setPaymentPlanModalOpen(true),
      }
      : null,
    showPaymentPlanAction && invoice.paymentPlan
      ? {
        icon: Prohibit,
        isDestructive: true,
        isSecondary: true,
        label: 'Cancel payment plan',
        onClick: () => setCancelPaymentPlanModalOpen(true),
      }
      : null,
    showPayOutsideAction
      ? {
        isDestructive: false,
        label: 'Record a payment outside of noodle',
        onClick: () => setPayOutsideModalOpen(true),
      }
      : null,
    canVoid && isInDashboard && hasAccess
      ? {
        isDestructive: true,
        label: "Void Invoice",
        onClick: () => setConfirmationModalOpen(true),
      }
      : null,
    !isInDashboard && !isInUserProfile
      ? {
        href: getUrl(IDENTIFIERS.USER_PROFILE, {
          creatorSlug,
          tab: invoice.userWorkflowId ? 'activity' : 'chat',
          userWorkflowId: invoice.userWorkflowId || undefined,
        }),
        label: 'Back to profile',
      }
      : null,
  ].filter(removeNullish);

  const isVoided = invoice.status === ApiModels.INVOICE_STATUS.VOIDED;
  const isPaid = invoice.status === ApiModels.INVOICE_STATUS.PAID;

  return (
    <>
      <Head>
        <title>{invoice.document?.fileName || 'Invoice'}</title>
      </Head>
      <MainPanelSubLayout
        header={{
          actions,
          description: invoice.isPartialPaymentEnabled && <InvoiceProgressTooltip invoice={invoice} />,
          title: invoice.title || invoice.document?.fileName || 'Invoice',
        }}
        pageContext={{ isFetching: userWorkflowFetchingState.isFetching, items: userWorkflowToContext(userWorkflow) }}
        rightPanel={{
          component: (
            <div className={s.rightPanel}>
              <InvoicePaymentsWidget paymentPlan={invoice.paymentPlan} currency={invoice.currency} payments={payments?.items || []} />
            </div>
          ),
          title: 'Invoice Payments',
        }}
      >
        <div className={s.container}>
          <div className={s.content}>
            {(isSkipped || isVoided || isPaid) && (
              <div className={s.status}>
                {isSkipped && (
                  <>
                    This invoice request has been skipped. No action required. {' '}
                  </>
                )}
                {isVoided && <BannerStatus status="error" label="Invoice voided" />}
                {isPaid && <BannerStatus status="success" label="Invoice paid" />}
              </div>
            )}
            {!isSkipped && (
              <>
                {invoice.document && (
                  <DocumentAccessGate
                    documentCreatorId={invoice.document.creatorId}
                    documentPersonId={invoice.document.personId}
                    isCollaborator={invoice.document.isCollaborator}
                    isCenteredVertically
                  >
                    {invoice.status === ApiModels.INVOICE_STATUS.PAYMENT_FAILED && (
                      <div className={s.status}>
                        <BannerStatus status="error" label="Your payment failed. Please re-submit it here." />
                      </div>
                    )}

                    <div style={{ position: 'relative' }}>
                      <DocumentPreview
                        document={invoice.document}
                        accessToken={accessToken}
                        canDownload={!isVoided || !isInDashboard}
                        isFaded={isVoided}
                      />
                    </div>
                  </DocumentAccessGate>
                )}
              </>
            )}
            {isConfirmationModalOpen && (
              <ConfirmationModal
                onClose={() => setConfirmationModalOpen(false)}
                variant='destructive'
                text="Are you sure you want to void this invoice? It will no longer be able to be paid."
                onSubmit={voidInvoice}
                cta="Void Invoice"
              />
            )}
            {isPayOutsideModalOpen && (
              <PayInvoiceOutsideNoodleModal
                onClose={() => setPayOutsideModalOpen(false)}
                invoice={invoice}
                onSubmit={refetchData}
              />
            )}
            {isConfidoPaymentModalOpen && invoice.confidoPaymentLink && (
              <ConfidoPaymentModal
                confidoPaymentLink={invoice.confidoPaymentLink}
                onClose={() => setConfidoPaymentModalOpen(false)}
              />
            )}
            {isPaymentPlanModalOpen && (
              <PaymentPlanModal
                refetchData={refetchData}
                creatorSlug={creatorSlug}
                invoice={invoice}
                onClose={() => setPaymentPlanModalOpen(false)}
              />
            )}
            {isCancelPaymentPlanModalOpen && (
              <CancelPaymentPlanModal
                refetchData={refetchData}
                invoice={invoice}
                onClose={() => setCancelPaymentPlanModalOpen(false)}
              />
            )}
          </div>
        </div>
      </MainPanelSubLayout>
    </>
  );
};

export default InvoicePreview;
