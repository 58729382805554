import { Dispatch, SetStateAction, useCallback, useRef } from 'react';
import { AddButton, DeleteButton, Input } from '@/components/DesignLibrary/Atoms';
import Label from '@/components/DesignLibrary/Atoms/shared/Label';
import guaranteeUniqueSlug from '@/helpers/guaranteeUniqueSlug';
import slugify from '@/helpers/slugify';
import s from './AddContextForm.module.scss';

export type SelectionOption = {
  key: string; // need this because if key is used and changes onChange causing the input to unmount on every keypress
  slug: string;
  label: string;
};

type Props = {
  onChange: Dispatch<SetStateAction<SelectionOption[]>>;
  options: SelectionOption[];
};

const SelectionOptions: React.FC<Props> = ({ onChange, options }) => {
  const onChangeRef = useRef(onChange);
  onChangeRef.current = onChange;

  const handleRemove = (currSlug: string): void => {
    onChange(prevState => prevState.filter(o => o.slug !== currSlug));
  };

  const handleAddOption = useCallback(() => {
    onChangeRef.current(prevState => {
      const label = '';
      const slug = guaranteeUniqueSlug(label, prevState.map(state => state.slug));
      const key = guaranteeUniqueSlug('option', prevState.map(state => state.key));
      return [...prevState, { key, label, slug }];
    });
  }, []);

  const handleChange = (key: string, label: string): void => {
    onChangeRef.current(prevState => {
      const takenKeys = prevState.filter(o => o.key !== key).map(o => o.slug);
      return prevState.map(prevOption => (prevOption.key === key)
        ? {
          key,
          label,
          slug: guaranteeUniqueSlug(slugify({ name: label }), takenKeys),
        }
        : prevOption,
      );
    });
  };

  return (
    <div className={s.selectOptionsWrapper}>
      <Label
        id={'options'}
        label='Selection Options'
        required
        compact
      />
      <div className={s.selectOptionsWrapper}>
        {options.map((option) => (
          <div key={option.key} className={s.selectOptionsRow}>
            <Input
              value={option.label}
              onChange={(value) => handleChange(option.key, value)}
            />
            <DeleteButton
              onClick={() => handleRemove(option.slug)}
            />
          </div>

        ))}
        <AddButton onClick={handleAddOption} />
      </div>
    </div>
  );
};

export default SelectionOptions;