import React, {ChangeEvent, Fragment, KeyboardEvent, ReactElement, useEffect, useMemo, useRef, useState} from 'react';
import {Descendant} from 'slate';

import { CREDIT_REPORT_PRODUCT_SLUG } from '@/configuration/client';
import ImageIcon from 'components/Icons/Image';
import FileIcon from 'components/Icons/File';
import PaperPlaneRight from 'components/Icons/PaperPlaneRight';
import PlusCircle from 'components/Icons/PlusCircle';
import CreditReport from 'components/Icons/CreditReport';
import Buttons from 'components/Buttons';
import {m} from 'framer-motion';
import BeforeUnload from '@providers/Jobs/JobsContainer/BeforeUnload';
import { useFeatureFlag } from '@providers/FeatureFlags';
import {KEY_CODES} from 'helpers/appConsts';
import MessageTip from '@components/MessageTip';
import FieldLabel from '@components/FormFields/FieldLabel';
import createRichTextAst from '@helpers/createRichTextAst';
import getTextFromRichText from '@helpers/getTextFromRichText';
import FileSelector from '@components/FormFields/FileSelector';
import {Product} from '@typings/graphql-models';
import * as ApiModels from '@typings/api-models';
import {MESSAGE_ATTACHMENT_REFERENCE_TYPES} from '@typings/api-models';
import SlateEditor, {slateHasText} from '@components/SlateEditor';
import FileAttachment from '@components/InputComposer/FileAttachment';
import {mixpanelTrack} from '@providers/Mixpanel';
import {JobStatus, useJobContext} from '@providers/Jobs';
import {logError} from '@providers/ErrorTracking';
import LoomAttachments from '@components/InputComposer/LoomAttachments';

import ProgressIndicator from '@components/ProgressIndicator';
import AddSound from '@components/InputComposer/AddSound';
import AddVideo from '@components/InputComposer/AddVideo';
import AddHandbook from '@components/InputComposer/AddHandbook';
import HandbookAttachments from '@components/InputComposer/HandbookAttachments';
import AddPaymentLink from '@components/InputComposer/AddPaymentLink';
import AddDocumentRequest from '@components/InputComposer/AddDocumentRequest';
import AddFormRequest from '@components/InputComposer/AddFormRequest';
import AddInvoice from '@components/InputComposer/AddInvoice';
import {nanoid} from 'nanoid';
import Attachments from '@components/InputComposer/Attachments';
import AddCustomTerms from '@components/InputComposer/AddCustomTerms';
import AddBooking from '@components/InputComposer/AddBooking';
import s from './InputComposer.module.scss';

type Media = {
  id: string;
  name?: string | null;
  imageUrl?: string;
  mimetype: string;
  title: string;
  type?: string | null;
  url: string;
  uploadPercentage?: number;
};

type CommonProps = {
  onSend?: ({
    attachments,
    text,
    handbooks,
    medias,
  }: {
    attachments?: ApiModels.CreateMessageAttachment[];
    text?: { children: Descendant[] }[];
    medias?: { id: string }[];
    handbooks?: { id: string }[];
  }) => Promise<void>;
  placeholder: string;
  areAttachmentsEnabled?: boolean;
  inputOptions?: Pick<ApiModels.Conversation, 'conversationStatus' | 'sid' | 'endedAt' | 'id'> | null;
  handleReopenThread?: () => void;
  isFetching: boolean;
  showAddVideo?: boolean;
  showChatEnded?: boolean;
  isLoomEnabled?: boolean;
  showProTips?: boolean;
  rows?: number;
  label?: string;
  showSendButton?: boolean;
  onChangeText?: (text: { children: Descendant[] }[]) => void;
  onChangeMedia?: (media: Pick<Media, 'id' | 'name'>[]) => void;
  onChangeFiles?: (files: (File | string)[]) => void;
  onChangeHandbooks?: (handbooks: Pick<Product, 'id' | 'title'>[]) => void;
  initialText?: { children: Descendant[] }[] | null;
  initialAttachments?: { referenceId: string; referenceType: MESSAGE_ATTACHMENT_REFERENCE_TYPES; title: string; id: string }[];
  initialMedia?: { id: string; name: string | null }[];
  isRichText?: boolean;
  isDisabled?: boolean;
  initialHandbooks?: { id: string; title?: string | null; }[];
  showAddHandbook?: boolean;
  showAddPaymentLink?: boolean;
  showAddAnyFile?: boolean;
  showAddCustomTerms?: boolean;
  referenceId: string;
  referenceType: string;
  onFocus?: () => void;
  showAddDocumentRequest?: boolean;
  showAddCreditReport?: boolean;
  showAddFormRequest?: boolean;
  showAddBooking?: boolean;
  showAddInvoice?: boolean;
  onChangeAttachments?: (
    attachments: {
      referenceId: string;
      referenceType: MESSAGE_ATTACHMENT_REFERENCE_TYPES;
      title: string;
      id: string;
    }[],
  ) => void;
};

type Props = (CommonProps & { isAsync: true; jobCorrelationId: string }) | (CommonProps & { isAsync?: false; jobCorrelationId?: never });

const MotionButton = m(Buttons);

const InputComposer = (props: Props): ReactElement => {
  const {
    areAttachmentsEnabled = true,
    onSend,
    placeholder,
    inputOptions,
    handleReopenThread,
    isFetching,
    rows = 1,
    showAddVideo,
    isLoomEnabled = false,
    showProTips = true,
    label,
    showSendButton = true,
    onChangeText,
    onChangeMedia,
    onChangeFiles,
    onChangeHandbooks,
    onChangeAttachments,
    initialText,
    initialAttachments = [],
    initialHandbooks = [],
    initialMedia = [],
    isRichText = false,
    isDisabled = false,
    jobCorrelationId,
    isAsync,
    showAddCustomTerms = false,
    showAddHandbook = false,
    showAddPaymentLink = false,
    showAddAnyFile = false,
    showAddDocumentRequest = false,
    showAddCreditReport = false,
    showAddFormRequest = false,
    showAddInvoice = false,
    showAddBooking = false,
    referenceType,
    referenceId,
    onFocus,
  } = props;
  const canUseCreditReportAttachment = useFeatureFlag('credit-report-attachments-enabled');
  const [sendError, setSendError] = useState<Error | null>(null);
  const [isSending, setIsSending] = useState(false);
  const [attachments, setAttachments] = useState<
    {
      data?: unknown;
      referenceType: MESSAGE_ATTACHMENT_REFERENCE_TYPES;
      referenceId: string;
      title: string;
      id: string;
    }[]
  >(initialAttachments);
  const [filesToUpload, setFiles] = useState<(File | string)[]>(initialMedia?.map(media => new File([], media.name || media.id)) || []);
  const [medias, setMedias] = useState<Pick<Media, 'id' | 'name' | 'uploadPercentage' | 'type'>[]>(initialMedia);
  const [handbooks, setHandbooks] = useState<Pick<Product, 'id' | 'title' | 'image' | 'description'>[]>(initialHandbooks);
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const inputFileRef = useRef<HTMLLabelElement>(null);
  const [isShimmeringReopenThread, setIsShimmeringReopenThread] = useState(false);
  const [isIconsBarOpen, setIsIconsBarOpen] = useState(false);
  const { jobs } = useJobContext();

  const handleFileUpload = async (files: string | File[]): Promise<void> => {
    const arr: (string | File)[] = [];
    if (typeof files === 'string') {
      arr.push(files);
    } else {
      arr.push(...files);
    }
    setFiles(prev => [...prev, ...arr]);
  };
  const [value, setValue] = useState<{ children: Descendant[] }[]>(initialText || [createRichTextAst('')]);

  useEffect(() => {
    setSendError(null);
    if (onChangeText) {
      onChangeText(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    setSendError(null);
    if (onChangeMedia) {
      onChangeMedia(medias);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [medias]);

  useEffect(() => {
    setSendError(null);
    if (onChangeFiles) {
      onChangeFiles(filesToUpload);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filesToUpload]);

  useEffect(() => {
    setSendError(null);
    if (onChangeAttachments) {
      onChangeAttachments(attachments);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attachments]);

  useEffect(() => {
    if (onChangeHandbooks) {
      onChangeHandbooks(handbooks);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handbooks]);

  const onInputChange = (e: ChangeEvent<HTMLTextAreaElement>): void => {
    setValue([createRichTextAst(e.target.value)]);
  };

  const onSlateChange = (newValue: { children: Descendant[] }[]): void => {
    setValue(newValue);
  };

  const handleSend = async (newValue: { children: Descendant[] }[]): Promise<void> => {
    mixpanelTrack('InputComposer - send message');
    setSendError(null);
    setIsSending(true);
    try {
      await onSend?.({
        // @todo tsc says this is missing assignTo and that data isn't typed correctly.
        // This does work, fix tsc later.
        attachments: attachments as unknown as Parameters<typeof onSend>[0]['attachments'],
        handbooks: handbooks.map(h => ({ id: h.id })),
        medias: medias.map(media => ({ id: media.id })),
        text: newValue,
      });
      setValue([createRichTextAst('')]);
      setMedias([]);
      setFiles([]);
      setHandbooks([]);
      setAttachments([]);
    } catch (err) {
      logError(err, { tag: { flow: 'input-composer' } });
      setSendError(err as Error);
    }
    setIsSending(false);
  };

  const onUploadFile = ({ mediaId, name }: { mediaId: string; name: string }): void => {
    setMedias(prev => [...prev, { id: mediaId, name }]);
  };

  const hasText = useMemo(() => slateHasText(value), [value]);

  const conversationMessageTipKey = `conversations_sid_${inputOptions?.sid}`;
  const fileLoadingInProcess = medias.filter(media => media.type !== 'loomVideo').length !== filesToUpload.length;

  const loomUploadJobs = jobs.filter(j => j.correlationId === jobCorrelationId && j.status === JobStatus.IN_PROGRESS && j.metadata?.isLoom);

  const loomMedias = medias.filter(media => media.type === 'loomVideo');

  // disabled if there are no handbooks, medias, or text
  const hasContent = handbooks.length > 0
    || (isAsync ? filesToUpload.length > 0 || loomUploadJobs.length > 0 : medias.length > 0)
    || hasText
    || loomMedias.length > 0
    || attachments.length > 0;
  const disabled = isDisabled || false;
  const isSendButtonEnabled = !disabled
    && hasContent
    && (isAsync ? !jobs.find(j => j.correlationId === jobCorrelationId && j.status === JobStatus.FAILED) : !fileLoadingInProcess);

  const handleKeyDown = (e: KeyboardEvent<HTMLTextAreaElement>): void => {
    if (e.keyCode === KEY_CODES.ENTER && (e.ctrlKey || e.altKey || e.shiftKey) && isSendButtonEnabled) {
      e.preventDefault();
      e.stopPropagation();
      handleSend(value);
    }
    // Fixed enter for new line for iphone
    if (e.keyCode === KEY_CODES.ENTER && !(e.ctrlKey || e.altKey || e.shiftKey)) {
      e.preventDefault();
      // add \n to the current position of the cursor
      const cursorPosition = e.currentTarget.selectionStart;
      const textBeforeCursor = e.currentTarget.value.substring(0, cursorPosition);
      const textAfterCursor = e.currentTarget.value.substring(cursorPosition, e.currentTarget.value.length);
      setValue([createRichTextAst(`${textBeforeCursor}\n${textAfterCursor}`)]);
      // make sure the cursor remains in the same position
      setTimeout(() => {
        if (inputRef?.current) {
          inputRef.current.selectionStart = cursorPosition + 1;
          inputRef.current.selectionEnd = cursorPosition + 1;
        }
      }, 0);
    }
  };

  useEffect(() => {
    if (inputRef?.current) {
      inputRef.current.style.height = '48px';
      if (inputRef.current.scrollHeight > 48) {
        inputRef.current.style.height = `${Math.max(inputRef.current.scrollHeight + 2, 48)}px`;
      }
    }
  }, [value, inputRef]);

  const handleAttachHandbook = (handbook: Pick<Product, 'id' | 'title' | 'image' | 'description'>): void => {
    setHandbooks(oldHandbooks => [...oldHandbooks, { id: handbook.id, image: handbook.image, title: handbook.title }]);
  };

  const addDocumentRequestTemplateAttachment = ({ title, documentRequestTemplateId }: { title: string; documentRequestTemplateId: string }): void => {
    setAttachments(prev => [
      ...prev,
      {
        id: nanoid(),
        referenceId: documentRequestTemplateId,
        referenceType: MESSAGE_ATTACHMENT_REFERENCE_TYPES.DOCUMENT_REQUEST_TEMPLATE,
        title,
      },
    ]);
  };

  const addCreditReportAttachment = (): void => {
    const id = CREDIT_REPORT_PRODUCT_SLUG;
    const title = 'Purchase credit report';

    setAttachments(prev => [
      ...prev,
      {
        id: nanoid(),
        referenceId: id,
        referenceType: MESSAGE_ATTACHMENT_REFERENCE_TYPES.CREDIT_REPORT,
        title,
      },
    ]);
  };

  const addFormRequestAttachment = ({ title, formTemplateId }: { title: string; formTemplateId: string }): void => {
    setAttachments(prev => [
      ...prev,
      {
        id: nanoid(),
        referenceId: formTemplateId,
        referenceType: MESSAGE_ATTACHMENT_REFERENCE_TYPES.FORM_REQUEST_TEMPLATE,
        title,
      },
    ]);
  };

  const handleAddInvoice = ({ invoiceTemplateId, title }: { invoiceTemplateId: string; title: string }): void => {
    setAttachments(prev => [
      ...prev,
      {
        id: nanoid(),
        referenceId: invoiceTemplateId,
        referenceType: MESSAGE_ATTACHMENT_REFERENCE_TYPES.INVOICE_REQUEST,
        title,
      },
    ]);
    setIsIconsBarOpen(false);
  };

  const handleAddBooking = ({ priceId, title }: { priceId: string; title: string }): void => {
    setAttachments(prev => [
      ...prev,
      {
        id: nanoid(),
        referenceId: priceId,
        referenceType: MESSAGE_ATTACHMENT_REFERENCE_TYPES.BOOKING_PRICE,
        title,
      },
    ]);
  };

  const handleAddCustomTerms = ({ customTermsTemplateId, title }: { customTermsTemplateId: string; title: string; }): void => {
    setAttachments(prev => [
      ...prev,
      {
        id: nanoid(),
        referenceId: customTermsTemplateId,
        referenceType: MESSAGE_ATTACHMENT_REFERENCE_TYPES.CUSTOM_TERMS_TEMPLATE,
        title,
      },
    ]);
    setIsIconsBarOpen(false);
  };

  const handleAddPaymentLink = ({ priceId, title }: { priceId: string; title: string }): void => {
    setAttachments(prev => [
      ...prev,
      {
        id: nanoid(),
        referenceId: priceId,
        referenceType: MESSAGE_ATTACHMENT_REFERENCE_TYPES.PAYMENT_LINK,
        title,
      },
    ]);
    setIsIconsBarOpen(false);
  };

  const onToolbarClick = (type: string, accept: string): void => {
    if (type === 'file' && inputFileRef.current) {
      (inputFileRef.current.firstChild as HTMLInputElement)?.setAttribute('accept', accept);
      inputFileRef.current.click();
    }
  };

  const handleAttachLink = (link: string): void => {
    handleSend([createRichTextAst(link)]);
  };

  const handleAddFiles = (el: Media): void => {
    setMedias(prevMedias => [...prevMedias, el]);
  };

  const removeHandbook = (media: Pick<Product, 'id' | 'title'>): void => {
    setHandbooks(handbooks.filter(h => h.id !== media.id));
  };

  const removeAttachment = (attachmentId: string): void => {
    setAttachments(prev => prev.filter(a => a.id !== attachmentId));
  };

  const attachLoomMedia = (media: Pick<Media, 'id' | 'name' | 'type'>): void => {
    setMedias(prevMedias => [...prevMedias, media]);
  };

  const handleRemoveFile = (file: string | File) => () => {
    setFiles(prevFiles => prevFiles.filter(f => f !== file));
  };

  return (
    <Fragment>
      {filesToUpload.length > 0 && <BeforeUnload />}
      <FileSelector id={jobCorrelationId} ref={inputFileRef} uploadFunction={handleFileUpload} />
      {label && <FieldLabel id="input-composer-label">{label}</FieldLabel>}
      <m.div
        initial={(!isRichText && { opacity: 0, y: 20 }) || undefined}
        animate={(!isRichText && { opacity: 1, y: 0 }) || undefined}
        transition={{
          duration: 1,
          type: 'spring',
        }}
        className={s[`chat-composer__wrapper${(!isRichText && '__one-line') || ''}`]}
      >
        {inputOptions?.conversationStatus === ApiModels.ConversationStatus.Completed
          ? (
            <div
              style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                gap: 12,
                padding: '16px 0',
                width: '100%',
              }}
            >
              <p className="body-md" style={{ color: 'var(--color-gray-75)' }}>
              Thread Closed
              </p>
              {handleReopenThread && (
                <Buttons
                  isSecondary
                  onClick={() => {
                    handleReopenThread();
                    setIsShimmeringReopenThread(true);
                  }}
                  isShimmering={isShimmeringReopenThread}
                >
                Reopen Thread
                </Buttons>
              )}
            </div>
          )
          : (
            <>
              <div className={s[`chat-composer__${isRichText ? 'multi' : 'one'}-line`]}>
                {areAttachmentsEnabled && (
                  <MotionButton
                    whileTap={{
                      scale: 0.9,
                    }}
                    animate={{
                      filter: isIconsBarOpen ? 'saturate(0)' : 'saturate(1)',
                      rotate: isIconsBarOpen ? 45 : 0,
                    }}
                    className={s.iconsButton}
                    isWrapper
                    onClick={() => setIsIconsBarOpen(!isIconsBarOpen)}
                  >
                    <PlusCircle weight="fill" size={24} />
                  </MotionButton>
                )}
                {isIconsBarOpen && (
                  <m.div
                    className={s['chat-composer__icons']}
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{
                      duration: 0.5,
                      type: 'spring',
                    }}
                  >
                    <Buttons
                      ariaLabel="Attach image"
                      iconBefore={
                        <m.div
                          style={{
                            alignItems: 'center',
                            display: 'flex',
                            height: '16px',
                            justifyContent: 'center',
                            width: '16px',
                            willChange: 'transform',
                          }}
                          whileTap={{ scale: disabled ? 1 : 0.9 }}
                        >
                          <ImageIcon
                            weight="fill"
                            size={16}
                            style={{
                              color: !disabled ? 'var(--color-primary)' : 'var(--color-gray-75)',
                              fill: !disabled ? 'var(--color-primary)' : 'var(--color-gray-75)',
                              stroke: 'transparent',
                            }}
                          />
                        </m.div>
                      }
                      disabled={disabled}
                      onClick={() => onToolbarClick('file', 'image/*')}
                    >
                    Image
                    </Buttons>

                    <AddSound onSubmit={handleFileUpload} disabled={disabled} onFileUploadClicked={() => onToolbarClick('file', 'audio/*')} />
                    {(showAddVideo || isLoomEnabled) && (
                      <AddVideo
                        jobCorrelationId={jobCorrelationId}
                        disabled={disabled}
                        onUploadVideo={() => onToolbarClick('file', 'video/*')}
                        onAttachLoomMedia={attachLoomMedia}
                        isLoomEnabled={isLoomEnabled}
                        isVideoEnabled={showAddVideo}
                      />
                    )}
                    {(
                      showAddAnyFile
                      || showAddHandbook
                      || showAddDocumentRequest
                      || showAddFormRequest
                      || showAddCreditReport
                      || showAddPaymentLink
                      || showAddBooking
                    ) && (
                      <>
                        {showAddAnyFile && (
                          <Buttons
                            ariaLabel="Attach file"
                            iconBefore={
                              <m.div
                                style={{
                                  alignItems: 'center',
                                  display: 'flex',
                                  height: '16px',
                                  justifyContent: 'center',
                                  width: '16px',
                                  willChange: 'transform',
                                }}
                                whileTap={{ scale: disabled ? 1 : 0.9 }}
                              >
                                <FileIcon
                                  weight="fill"
                                  size={16}
                                  style={{
                                    color: !disabled ? 'var(--color-primary)' : 'var(--color-gray-75)',
                                    fill: !disabled ? 'var(--color-primary)' : 'var(--color-gray-75)',
                                    stroke: 'transparent',
                                  }}
                                />
                              </m.div>
                            }
                            disabled={disabled}
                            onClick={() => onToolbarClick('file', '*')}
                          >
                          File
                          </Buttons>
                        )}
                        {showAddHandbook && (
                          <Fragment>
                            <AddHandbook
                              disabled={disabled}
                              onSendMessage={handleAttachHandbook}
                              onAddLink={handleAttachLink}
                              onAddFiles={handleAddFiles}
                            />
                          </Fragment>
                        )}
                        {showAddDocumentRequest && <AddDocumentRequest disabled={disabled} onAttach={addDocumentRequestTemplateAttachment} />}
                        {showAddFormRequest && <AddFormRequest disabled={disabled} onAttach={addFormRequestAttachment} />}
                        {canUseCreditReportAttachment && showAddCreditReport && (
                          <Buttons
                            ariaLabel="Attach file"
                            iconBefore={
                              <m.div
                                style={{
                                  alignItems: 'center',
                                  display: 'flex',
                                  height: '16px',
                                  justifyContent: 'center',
                                  width: '16px',
                                  willChange: 'transform',
                                }}
                                whileTap={{ scale: disabled ? 1 : 0.9 }}
                              >
                                <CreditReport
                                  weight="fill"
                                  size={16}
                                  style={{
                                    color: !disabled ? 'var(--color-primary)' : 'var(--color-gray-75)',
                                    fill: !disabled ? 'var(--color-primary)' : 'var(--color-gray-75)',
                                    stroke: 'transparent',
                                  }}
                                />
                              </m.div>
                            }
                            disabled={disabled}
                            onClick={addCreditReportAttachment}
                          >
                            Purchase Credit Report
                          </Buttons>
                        )}
                        {showAddPaymentLink && <AddPaymentLink onAttach={handleAddPaymentLink} />}
                        {showAddInvoice && <AddInvoice onAttach={handleAddInvoice} />}
                        {showAddCustomTerms && <AddCustomTerms onAttach={handleAddCustomTerms} />}
                        {showAddBooking && <AddBooking onAttach={handleAddBooking} />}
                      </>
                    )}
                  </m.div>
                )}

                {isRichText
                  ? (
                    <SlateEditor
                      id="input"
                      name="input"
                      placeholder={placeholder}
                      slateValue={value}
                      onChange={onSlateChange}
                      isMediaButton={false}
                      containerClassName={s.slateEditor}
                      {...(onFocus ? { onFocus } : {})}
                    />
                  )
                  : (
                    <textarea
                      rows={rows}
                      ref={inputRef}
                      value={getTextFromRichText(value)}
                      onChange={onInputChange}
                      onKeyDown={handleKeyDown}
                      placeholder={placeholder}
                      className={s['chat-composer__input']}
                    />
                  )}
                {showSendButton && (
                  <Buttons
                    isWrapper
                    className={s.sendButton}
                    isShimmering={isFetching || isSending}
                    disabled={!isSendButtonEnabled}
                    ariaLabel="Send message"
                    onClick={() => {
                      handleSend(value);
                    }}
                  >
                    {isSending || isFetching ? <ProgressIndicator /> : <PaperPlaneRight width={24} weight="fill" />}
                  </Buttons>
                )}
              </div>

              {sendError && (
                <div style={{ width: '100%' }}>
                  <span className="caption" style={{ color: 'var(--color-error)' }}>
                  Failed to send message. Please try again.
                  </span>
                </div>
              )}
              {(filesToUpload.length > 0 || !!handbooks.length || loomMedias.length > 0 || loomUploadJobs.length > 0 || attachments.length > 0) && (
                <div className={s.attachments}>
                  {filesToUpload.length > 0 && (
                    <>
                      {filesToUpload.map(file => (
                        <FileAttachment
                          isAsync={isAsync}
                          key={typeof file === 'string' ? file : file.name}
                          file={file}
                          media={medias.find(media => media.name === (typeof file === 'string' ? file : file.name))}
                          onUploadFile={onUploadFile}
                          jobCorrelationId={jobCorrelationId}
                          referenceId={referenceId}
                          referenceType={referenceType}
                          removeFile={handleRemoveFile(file)}
                          removeMedia={(mediaId: string) => setMedias(medias.filter(f => f.id !== mediaId))}
                        />
                      ))}
                    </>
                  )}
                  {(loomMedias.length > 0 || loomUploadJobs.length > 0) && <LoomAttachments medias={loomMedias} uploadJobs={loomUploadJobs} />}
                  {!!handbooks.length && <HandbookAttachments handbooks={handbooks} removeHandbook={removeHandbook} />}
                  <Attachments attachments={attachments} removeAttachment={removeAttachment} />
                </div>
              )}
            </>
          )}
      </m.div>

      {/* TODO - move this outside of InputComposer component */}
      {showProTips && (
        <MessageTip containerClassName={s['conv-message-tip']} messageTipKey={conversationMessageTipKey}>
          <b className="body-md-bold">👋Pro tips to make the most of your chat:</b>
          <ol className={s['conv-message-tip__list']}>
            <li className={s['conv-message-tip__list-item']}>
              1. Keep your questions under
              <b>&nbsp;2 minutes for video&nbsp;</b>
              or
              <b>&nbsp;2 sentences for text.&nbsp;</b>
            </li>
            <li className={s['conv-message-tip__list-item']}>
              2. Focus on
              <b>&nbsp;one question&nbsp;</b>
              at a time.
            </li>
          </ol>
        </MessageTip>
      )}
    </Fragment>
  );
};

export default InputComposer;
