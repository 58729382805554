import { ReactElement, useState } from 'react';
import { useIsMobile } from '@hooks';
import Modal from '@components/Modal';
import * as tsClient from '@tsClient';
import useNoodleApi from '@hooks/useNoodleApi';
import TextInput from '@components/FormFields/TextInput';
import Buttons from '@components/Buttons';
import Spacer from '@components/Spacer';
import CheckBox from '@components/DesignLibrary/Atoms/CheckBox';
import { Meow_Script as meowScript } from 'next/font/google';
import SignatureInput from '@components/FormFields/SignatureInput';
import s from './AgreeToTermsModal.module.scss';

type Props = {
  customTermsId: string;
  onClose: () => void;
  onSubmit: () => void;
  usesHandwrittenSignature: boolean;
  customerName: string | null;
};

const handwriting = meowScript({
  subsets: ['latin'],
  weight: '400',
});

const AgreeToTermsModal = ({ customTermsId, onClose, onSubmit, usesHandwrittenSignature, customerName }: Props): ReactElement => {
  const isMobile = useIsMobile();
  const [signeeName, setSigneeName] = useState<string>(customerName || '');
  const [hasAgreed, setHasAgreed] = useState(false);
  const [isAgreeing, setIsAgreeing] = useState(false);
  const [customerSignature, setCustomerSignature] = useState('');

  const { getData: agreeToCustomTerms } = useNoodleApi(tsClient.customTerms.agreeToCustomTerms);

  const handleAgreeToSign = async (): Promise<void> => {
    setIsAgreeing(true);
    await agreeToCustomTerms({
      customerSignatorName: signeeName,
      customerSignature,
      id: customTermsId,
    });
    onSubmit();
  };

  return (
    <Modal
      hideBottomInset

      title={'Add signature'}
      onClose={onClose}

    >
      <div className={s.agreeToTerms}>
        <TextInput
          id="signeeName"
          value={signeeName}
          onChange={setSigneeName}
          label="Your Name"
          hasFixedHeight
        />
        {usesHandwrittenSignature
          ? (
            <SignatureInput
              value={customerSignature}
              label='Signature'
              onChange={newSignature => setCustomerSignature(newSignature)}
            />
          )
          : (
            <TextInput
              id="signatorySignature"
              value={signeeName}
              onChange={setSigneeName}
              label="Signature"
              hasFixedHeight
              disabled
              style={{
                fontFamily: handwriting.style.fontFamily,
                fontSize: '24px',
                fontWeight: 800,
              }}
            />
          )}
        <CheckBox
          id='hasAgreed'
          label={'I agree to electronically sign these terms'}
          isChecked={hasAgreed}
          onChange={setHasAgreed}
        />
        <Spacer size={8}/>
        <Buttons
          isSecondary
          isFullWidth
          onClick={handleAgreeToSign}
          isFetching={isAgreeing}
          disabled={!hasAgreed || signeeName === '' || (usesHandwrittenSignature && !customerSignature)}
        >
          Sign the terms
        </Buttons>
        {isMobile && <Spacer size='calc(env(safe-area-inset-bottom) + 24px)' />}
      </div>
    </Modal>
  );
};

export default AgreeToTermsModal;
