import { ReactElement, useState } from 'react';
import { useToast } from '@hooks';
import Modal from '@components/Modal';
import useNoodleApi from '@hooks/useNoodleApi';
import TextInput from '@components/FormFields/TextInput';
import Buttons from '@components/Buttons';
import Spacer from '@components/Spacer';
import { updateWorkflowStep } from '@tsClient/workflows';
import s from './EditWorkflowThreadTitleModal.module.scss';

type Props = {
  currentStep: {
    id: string;
    title: string | null;
  }
  workflowId: string;
  onClose: () => void;
  onSubmit: () => void;
};

const EditWorkflowThreadTitleModal = ({ currentStep, workflowId, onClose, onSubmit }: Props): ReactElement => {
  const [title, setTitle] = useState<string>(currentStep.title || '');
  const [isUpdatingThread, setIsUpdatingThread] = useState(false);
  const [isTouched, setIsTouched] = useState(false);
  const [titleError, setTitleError] = useState<string | null>(null);

  const { getData: updateWorkflowStepFn } = useNoodleApi(updateWorkflowStep, {
    toastOnError: true,
    toastSuccessMessage: () => [useToast.ToastTypeVariants.INFO, 'Thread title updated'],
  });

  const handleEditWorkflowThreadTitle = async (): Promise<void> => {
    if (titleError) {
      setIsTouched(true);
      return;
    }
    setIsUpdatingThread(true);
    await updateWorkflowStepFn({
      id: workflowId,
      stepId: currentStep.id,
      updates: {
        title,
      },
    });
    setIsUpdatingThread(false);
    onSubmit();
    onClose();
  };

  return (
    <Modal
      hideBottomInset

      title={'Edit thread title'}
      onClose={onClose}

    >
      <div className={s.updateThread}>
        <TextInput
          id={'title'}
          value={title}
          onChange={setTitle}
          label={'Thread title'}
          placeholder={`Enter thread title`}
          onError={setTitleError}
          isTouched={isTouched}
          required
        />
        <Spacer size={12}/>
        <Buttons isSecondary isFullWidth onClick={handleEditWorkflowThreadTitle} isFetching={isUpdatingThread}>
          Update
        </Buttons>
      </div>
    </Modal>
  );
};

export default EditWorkflowThreadTitleModal;
