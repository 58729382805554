import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';
import * as dateFns from 'date-fns';

import calculateTimeElapsed from '@helpers/calculateTimeElapsed';
import List from '@/components/Icons/List';
import User from '@/components/Icons/User';
import Modal from '@/components/Modal';
import PlusCircle from '@/components/Icons/PlusCircle';
import type { Questionnaire, QuestionnaireTemplateRawJSON } from '@/typings/Questionnaire';
import { TextArea } from '@/components/DesignLibrary/Atoms';
import Button from '@/components/DesignLibrary/Button';
import ExpandableList from '@/components/Questionnaire/ExpandableList';
import useBeforeUnload from '@/hooks/useBeforeUnload';
import { QuestionnaireAssigneeTemplate } from '@/typings/questionnaires-api-models';
import { downloadJsonAsFile } from '@/helpers/download';
import SectionEditor from '../SectionEditor';
import FormPreview from '../FormPreview';
import FieldEditor from '../FieldEditor';
import AssigneeEditor from '../AssigneeEditor';
import FormEditorProvider, { useFormEditorContext } from './FormEditorContext';
import s from './FormEditor.module.scss';

type Props = {
  formData: Questionnaire.Questionnaire;
  isSaving?: boolean;
  onSave: (data: Questionnaire.Questionnaire) => Promise<void>;
};

const FormEditor: React.FC<Omit<Props, 'formData'>> = ({ isSaving, onSave }) => {
  const { formData, setFormData } = useFormEditorContext();

  const [assignees, setAssignees] = useState<QuestionnaireAssigneeTemplate[]>([]);
  const [currentSection, setCurrentSection] = useState<Questionnaire.Section | null>(null);
  const [currentAssignee, setCurrentAssignee] = useState<QuestionnaireAssigneeTemplate | null>(null);
  const [currentField, setCurrentField] = useState<Questionnaire.Field | null>(null);
  const [currentFieldIsInList, setCurrentFieldIsInList] = useState(false);
  const [editor, setEditor] = useState<'section' | 'assignee' | 'field' | undefined>();
  const [isVersionNoteModalOpen, setIsVersionNoteModalOpen] = useState(false);
  const [versionNote, setVersionNote] = useState('');
  const [hasChanges, setHasChanges] = useState(false);
  const bottomRef = useRef<HTMLDivElement>(null);
  const previewRef = useRef<HTMLDivElement>(null);
  useBeforeUnload(hasChanges);

  useEffect(() => {
    setAssignees(formData.assigneeTemplates ?? []);

    if (formData.sections.length) {
      setCurrentSection(prev => prev ? formData.sections.find((section) => section.id === prev.id) ?? formData.sections[0] : formData.sections[0]);
    }
  }, [formData]);

  useEffect(() => {
    if (currentSection?.id) {
      previewRef.current?.scrollTo({
        top: 0,
      });
    }
  }, [currentSection?.id]);

  const handleSaveDraft = useCallback((): void => {
    onSave({
      ...formData,
      assigneeTemplates: assignees,
      status: 'draft',
    });
    setHasChanges(false);
  }, [formData, onSave, assignees]);

  const updateAssignee = useCallback((id: string, payload: { label: string, key: string }): void => {
    const newAssignees = assignees.map((assignee) =>
      assignee.id === id
        ? {
          ...assignee,
          ...payload,
        }
        : assignee,
    );
    setAssignees(newAssignees);
    if (currentAssignee?.id === id) {
      setCurrentAssignee({
        ...currentAssignee,
        ...payload,
      });
    }
    setHasChanges(true);
    setEditor(undefined);

    onSave({
      ...formData,
      assigneeTemplates: newAssignees,
      status: 'draft',
    });
    setHasChanges(false);
  }, [currentAssignee, assignees, formData, onSave]);

  const updateSection = useCallback((updatedSection: Questionnaire.Section, needToSave: boolean = true): void => {
    const newFormData = {
      ...formData,
      sections: formData.sections.map((section) =>
        section.id === updatedSection.id ? updatedSection : section,
      ),
    };

    setFormData(newFormData);
    if (currentSection?.id === updatedSection.id) {
      setCurrentSection(updatedSection);
    }
    setHasChanges(true);
    setEditor(undefined);

    if (needToSave) {
      onSave({
        ...newFormData,
        assigneeTemplates: assignees,
        status: 'draft',
      });
      setHasChanges(false);
    }
  }, [currentSection, formData, assignees, onSave, setFormData]);

  const updateField = useCallback((updatedField: Questionnaire.Field): void => {
    if (!currentSection) return;

    const updatedFields = currentSection.fields.map((field) =>
      field.id === updatedField.id ? updatedField : field,
    );

    const updatedSection = {
      ...currentSection,
      fields: updatedFields,
    };

    updateSection(updatedSection);
    setHasChanges(true);
    setCurrentField(null);
    setCurrentFieldIsInList(false);
  }, [currentSection, updateSection]);

  const addAssignee = useCallback((): void => {
    const newAssignee = {
      id: uuidv4(),
      key: `assignee${assignees.length + 1}`,
      label: `Assignee ${assignees.length + 1}`,
      questionnaireTemplateId: formData.id,
    };

    setAssignees((prev) => [...prev, newAssignee]);
    setCurrentAssignee(newAssignee);
    setHasChanges(true);
    setEditor('assignee');
  }, [formData.id, assignees]);

  const removeAssignee = useCallback((assignee: string): void => {
    setAssignees((prev) => prev.filter((a) => a.id !== assignee));
    if (currentAssignee?.id === assignee) {
      setCurrentAssignee(null);
      setEditor(undefined);
    }
    setHasChanges(true);
  }, [currentAssignee]);

  const addSection = useCallback((): void => {
    const newSection: Questionnaire.Section = {
      conditionExpression: null,
      description: '',
      fields: [],
      id: uuidv4(),
      isLoading: false,
      key: `section${formData.sections.length + 1}`,
      label: 'New section',
      questionnaireTemplateId: formData.id,
      rank: 0,
      slug: '',
    };
    setFormData((prev) => ({
      ...prev,
      sections: [...prev.sections, newSection],
    }));
    setCurrentSection(newSection);
  }, [setFormData, formData.sections]);

  const removeSection = useCallback((sectionId: string): void => {
    setFormData((prev) => ({
      ...prev,
      sections: prev.sections.filter((section) => section.id !== sectionId),
    }));
    if (currentSection?.id === sectionId) {
      setCurrentSection(null);
      setHasChanges(true);
      setCurrentField(null);
      setCurrentFieldIsInList(false);
    }
  }, [currentSection, setFormData]);

  const addField = useCallback((parentFieldId?: string): void => {
    if (!currentSection) return;

    const newFieldIsInList = Boolean(parentFieldId);
    const newField: Questionnaire.Field = {
      assignedTo: [],
      conditionExpression: null,
      description: '',
      id: uuidv4(),
      isEditableInTableView: false,
      isRequired: true,
      isViewableInTableView: true,
      key: `${currentSection.key
        .replace(/[^a-zA-Z0-9_]/g, '')
        .replace(/^[0-9]+/g, '')
      }field${currentSection.fields.length + 1}`,
      label: 'Label',
      listFieldTemplateId: parentFieldId ?? null,
      listItemTemplates: [],
      rank: currentSection.fields.length + 1,
      type: 'short-text',
      view: null,
    };

    const updatedSection = {
      ...currentSection,
      fields: [...currentSection.fields, newField],
    };
    updateSection(updatedSection, false);
    setCurrentField(newField);
    setCurrentFieldIsInList(newFieldIsInList);
    setEditor('field');
  }, [currentSection, updateSection]);

  const handleSectionClick = useCallback((section: Questionnaire.Section): void => {
    setCurrentSection(section);
    setCurrentField(null);
    setCurrentFieldIsInList(false);
    setEditor('section');
  }, []);

  const handleAssigneeClick = useCallback((assignee: QuestionnaireAssigneeTemplate): void => {
    setCurrentAssignee(assignee);
    setEditor('assignee');
  }, []);

  const handleFieldClick = useCallback((args: { field: Questionnaire.Field; isInList: boolean }): void => {
    setCurrentField(args.field);
    setCurrentFieldIsInList(args.isInList);
    setEditor('field');
  }, []);

  const removeField = useCallback((fieldId: string): void => {
    if (!currentSection) return;

    const updatedSection = {
      ...currentSection,
      fields: currentSection.fields.filter((field) => field.id !== fieldId),
    };

    updateSection(updatedSection);
    setHasChanges(true);
    setCurrentField(null);
    setCurrentFieldIsInList(false);
  }, [currentSection, updateSection]);

  const handleReorderSections = useCallback((newOrder: Questionnaire.Section[]): void => {
    setFormData((prev) => ({
      ...prev,
      sections: newOrder,
    }));
    setHasChanges(true);
  }, [setFormData]);

  const handleReorderFields = useCallback((newOrder: Questionnaire.Field[]): void => {
    if (!currentSection) return;

    const updatedSection = {
      ...currentSection,
      fields: newOrder.map((field, i) => ({
        ...field,
        rank: i + 1,
      })),
    };

    setHasChanges(true);
    updateSection(updatedSection);
  }, [currentSection, updateSection]);

  const handlePublish = useCallback((): void => {
    setIsVersionNoteModalOpen(true);
  }, []);

  const handleConfirmPublish = useCallback(async (): Promise<void> => {
    await onSave({
      ...formData,
      assigneeTemplates: assignees,
      status: 'current',
      versionNote,
    });
    setHasChanges(false);
    setIsVersionNoteModalOpen(false);
  }, [formData, onSave, versionNote, assignees]);

  const handleCloseModal = useCallback((): void => {
    setVersionNote('');
    setIsVersionNoteModalOpen(false);
  }, []);

  const usedSlugs = useMemo(
    () =>
      (formData.sections ?? [])
        .filter((section: Questionnaire.Section) => section.id !== currentSection?.id)
        .map((section: Questionnaire.Section) => section.slug)
        .filter(Boolean),
    [formData, currentSection],
  );

  const usedAssigneeLabels = useMemo(
    () => assignees.map(({ id, label }) => (id === currentAssignee?.id ? '' : label)),
    [assignees, currentAssignee],
  );

  const usedSectionKeys = useMemo(
    () =>
      (formData.sections ?? [])
        .filter((section: Questionnaire.Section) => section.id !== currentSection?.id)
        .map((section: Questionnaire.Section) => section.key)
        .filter(Boolean),
    [formData, currentSection],
  );

  const usedAssigneeKeys = useMemo(
    () => assignees.map(({ id, key }) => (id === currentAssignee?.id ? '' : key)),
    [assignees, currentAssignee],
  );

  const usedFieldKeys = useMemo(
    () =>
      (formData.sections ?? [])
        .reduce((p: Questionnaire.Field[], section: Questionnaire.Section) => [...p, ...section.fields], [])
        .map((field: Questionnaire.Field) => (field.id === currentField?.id ? '' : field.key))
        .filter(Boolean),
    [formData, currentField],
  );

  const handleAddField = (parentFieldId?: string): void => {
    addField(parentFieldId);

    if (!parentFieldId) {
      bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleExportJSON = useCallback((): void => {
    const jsonData: QuestionnaireTemplateRawJSON = {
      description: formData.description,
      label: formData.label,
      sections: formData.sections.map((section) => ({
        description: section.description,
        fields: section.fields.map((field) => ({
          ..._.pick(
            field,
            'key',
            'label',
            'description',
            'type',
            'hint',
            'placeholder',
            'rank',
            'options',
            'view',
          ),
          assignees: field.assignedTo.map((assigneeId) => assignees.find(({ id }) => id === assigneeId))
            .filter(Boolean)
            .map((assignee) => ({ key: assignee!.key, label: assignee!.label })),
          optional: !field.isRequired,
        })),
        key: section.key,
        label: section.label,
      })),
    };

    downloadJsonAsFile<QuestionnaireTemplateRawJSON>(
      jsonData,
      `${jsonData.label}-v${formData.version} (${dateFns.format(new Date(), 'MM-dd-yyyy')})`,
    );
  }, [formData, assignees]);

  return (
    <div className={s.formEditor}>
      <div className={s.header}>
        <div className={s.info}>
          <div className={s.title}>{formData.label}</div>
          <div className={s.description}>{formData.description}</div>
        </div>
        <div className={s.headerActions}>
          <Button disabled={Boolean(formData.sections.find((section) => section.isLoading))} loading={isSaving} variant='primary' size='sm' onClick={handlePublish}>Publish</Button>
          <Button
            variant='secondary'
            size='sm'
            loading={isSaving}
            onClick={handleSaveDraft}
          >Save draft</Button>
          <Button variant='secondary' size='sm' onClick={handleExportJSON}>Export as JSON</Button>
          <div className={s.versionInfo}>
            <div>Version: {formData.version} ({formData.versionNote})</div>
            <div>Updated {calculateTimeElapsed(formData.updatedAt!)} by {formData.updatedByName}</div>
          </div>
        </div>
      </div>
      <div className={s.body}>
        <Button variant='secondary' size='sm' onClick={() => handleAddField()} className={s.addFieldButton}>
          <PlusCircle weight="fill" color="var(--color-noodle)" />
          Add Field
        </Button>
        <div className={s.editorPane}>
          <ExpandableList<Questionnaire.Section>
            initOpen
            items={formData.sections}
            label="Sections"
            headerIcon={<List />}
            currentSelectedItem={currentSection?.id}
            onAddItem={addSection}
            onReorderItems={handleReorderSections}
            onSelectItem={handleSectionClick}
          />

          <div className={s.divider} />

          <ExpandableList<QuestionnaireAssigneeTemplate>
            items={assignees}
            label="Assignees"
            headerIcon={<User />}
            currentSelectedItem={currentAssignee?.id}
            onAddItem={addAssignee}
            onSelectItem={handleAssigneeClick}
          />

          <div className={s.divider} />

          {editor === 'assignee' && currentAssignee && (
            <AssigneeEditor
              usedLabels={usedAssigneeLabels}
              assignee={currentAssignee}
              usedKeys={usedAssigneeKeys}
              onUpdateAssignee={updateAssignee}
              onRemoveAssignee={removeAssignee}
            />
          )}

          {editor === 'section' && currentSection && (
            <SectionEditor
              section={currentSection}
              usedSlugs={usedSlugs}
              usedKeys={usedSectionKeys}
              onUpdateSection={updateSection}
              onRemoveSection={removeSection}
            />
          )}

          {editor === 'field' && currentSection && currentField && (
            <FieldEditor
              field={currentField}
              usedKeys={usedFieldKeys}
              onUpdateField={updateField}
              onRemoveField={removeField}
              assigneeTemplates={assignees}
              onAddField={handleAddField}
              isInList={currentFieldIsInList}
            />
          )}
        </div>
        <div className={s.previewPane} ref={previewRef}>
          {currentSection
            ? (
              <FormPreview
                ref={bottomRef}
                formData={formData}
                assignees={assignees}
                currentSection={currentSection}
                currentField={currentField?.id}
                onSelectField={handleFieldClick}
                onReorderFields={handleReorderFields}
              />
            )
            : null}
        </div>
      </div>
      {isVersionNoteModalOpen && (
        <Modal
          title="Publish Questionnaire"
          onClose={() => setIsVersionNoteModalOpen(false)}
        >
          <form className={s.versionNoteForm}>
            <TextArea
              label=""
              description="Version note"
              value={versionNote}
              onChange={setVersionNote}
            />
            <div className={s.modalButtons}>
              <Button loading={isSaving} size="sm" variant="primary" onClick={handleConfirmPublish}>Publish</Button>
              <Button size="sm" variant="secondary" onClick={handleCloseModal}>Cancel</Button>
            </div>
          </form>
        </Modal>
      )}
    </div>
  );
};

const FormEditorContainer: React.FC<Props> = ({ formData, isSaving, onSave }) => (
  <FormEditorProvider formData={formData}>
    <FormEditor isSaving={isSaving} onSave={onSave} />
  </FormEditorProvider>
);

export default FormEditorContainer;
