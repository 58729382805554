import React, { FC } from 'react';
import Modal from '@components/Modal';
import ServiceDetails from '@lib/Creators/Dashboard/ServiceDetails';

type Props = {
  onClose: () => void;
  creatorSlug: string;
  productSlug: string;
};

const ProductEditModal: FC<Props> = ({ creatorSlug, productSlug, onClose }) => (
  <Modal title={'Edit Product'} onClose={onClose} >
    <ServiceDetails productSlug={productSlug} creatorSlug={creatorSlug} />
  </Modal>
);

export default ProductEditModal;
