import { FC, useCallback, useContext, useEffect, useRef, useState } from 'react';
import Head from 'next/head';
import userWorkflowToContext from '@helpers/userWorkflowToContext';
import useNoodleApi from '@hooks/useNoodleApi';
import * as tsClient from '@tsClient';
import Spacer from '@components/Spacer';
import Check from '@components/Icons/Check';
import ProgressIndicator from '@components/ProgressIndicator';
import { getUserWorkflowById } from '@tsClient/workflows';
import MainPanelSubLayout from '@layouts/MainPanelSubLayout';
import { DocumentAccessContext, DocumentAccessGate } from '@providers/DocumentAccess';
import DocumentPreview from '@components/Document/DocumentPreview';
import { useQueryState } from 'nuqs';
import { DocumentRequestFileStatus } from '@typings/document-api-models';
import TeamsContext from '@providers/Teams/TeamsContext';
import RejectDocumentModal from '@modals/RejectDocumentModal';
import { Reorder } from 'framer-motion';
import File from '@components/DesignLibrary/File';
import Buttons from '@components/Buttons';
import DotsSixVertical from '@components/Icons/DotsSixVertical';
import Button from '@components/DesignLibrary/Button';
import DocumentUploader, { DocumentUploaderRef } from '@components/Document/DocumentUploader';
import Bluebird from 'bluebird';
import BannerStatus from '@components/DesignLibrary/BannerStatus';
import { usePrevious } from '@hooks';
import DocumentStatus from '@components/Document/DocumentStatus';
import s from './DocumentRequestUserFileDetail.module.scss';

type Props = {
  documentRequestUserFileId: string;
};

const QS_KEY = 'documentId';

const DocumentRequestUserFileDetail: FC<Props> = ({ documentRequestUserFileId }) => {
  const [documentId, setDocumentId] = useQueryState(QS_KEY);

  const [isRejectModalOpen, setRejectModalOpen] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [removingFileId, setRemovingFileId] = useState<string | null>(null);
  const [isRemovingAllFiles, setIsRemovingAllFiles] = useState(false);
  const [files, setFiles] = useState<Array<Parameters<typeof File>[0]['document']>>([]);
  const previousFiles = usePrevious(files);
  const { accessToken } = useContext(DocumentAccessContext);
  const { creatorId } = useContext(TeamsContext);

  const { data: workflowData, getData: getWorkflow } = useNoodleApi(getUserWorkflowById, {
    toastOnError: true,
  });
  const { data: documentRequestUserFile, getData: getDocumentRequestUserFile } = useNoodleApi(tsClient.documentRequests.getDocumentRequestUserFile);
  const { getData: removeDocument } = useNoodleApi(tsClient.documentRequests.removeDocumentFromDocumentRequestUserFile);
  const { getData: updateFile } = useNoodleApi(tsClient.documentRequests.updateDocumentRequestUserFile);
  const { getData: updateDocumentOrder } = useNoodleApi(tsClient.documentRequests.updateDocumentOrder);

  const reorderContainerRef = useRef<HTMLUListElement>(null);
  const documentUploaderRef = useRef<DocumentUploaderRef>(null);

  useEffect(() => {
    if (documentRequestUserFile?.documentRequestUser?.userWorkflowId) {
      getWorkflow({ userWorkflowId: documentRequestUserFile.documentRequestUser.userWorkflowId });
    }
  }, [documentRequestUserFile?.documentRequestUser?.userWorkflowId, getWorkflow]);

  useEffect(() => {
    if (documentRequestUserFile?.documents) {
      setFiles(documentRequestUserFile.documents);
    }
  }, [documentRequestUserFile?.documents]);

  const fetchDocument = useCallback(async (): Promise<void> => {
    setIsUploading(false);
    await getDocumentRequestUserFile({
      fileId: documentRequestUserFileId,
    });
  }, [documentRequestUserFileId, getDocumentRequestUserFile]);

  useEffect(() => {
    fetchDocument();
  }, [fetchDocument]);

  useEffect(() => {
    if (documentRequestUserFile?.documents?.[0]) {
      setDocumentId(documentRequestUserFile.documents[0].id);
    }
  }, [setDocumentId, documentRequestUserFile]);

  useEffect(() => {
    if (documentRequestUserFile && files.length > 0 && (previousFiles?.length || 0) > 0) {
      updateDocumentOrder({
        fileId: documentRequestUserFileId,
        id: documentRequestUserFile.documentRequestUser.id,
        updates: files.map((f, idx) => ({
          documentId: f.id,
          rank: idx + 1,
        })),
      });
    }
  }, [files, previousFiles, documentRequestUserFile, updateDocumentOrder, documentRequestUserFileId]);

  if (!documentRequestUserFile) {
    return (
      <>
        <Spacer size={80} />
        <ProgressIndicator isCentered isPage />
      </>
    );
  }

  const uploadFile = (): void => {
    documentUploaderRef.current?.uploadDocument();
  };

  const removeFile = async (documentIdToRemove: string): Promise<void> => {
    if (window.confirm('Are you sure you want to remove this file?')) {
      setRemovingFileId(documentIdToRemove);
      await removeDocument({
        documentId: documentIdToRemove,
        fileId: documentRequestUserFile.id,
        id: documentRequestUserFile.documentRequestUser.id,
      });
      await fetchDocument();
      setRemovingFileId(null);
    }
  };

  const removeAllFiles = async (): Promise<void> => {
    if (window.confirm('Are you sure you want to remove all files?')) {
      setIsRemovingAllFiles(true);
      // todo - single endpoint to remove all documents
      await Bluebird.map(documentRequestUserFile.documents, async document => {
        await removeDocument({
          documentId: document.id,
          fileId: documentRequestUserFile.id,
          id: documentRequestUserFile.documentRequestUser.id,
        });
      });
      await fetchDocument();
      setIsRemovingAllFiles(false);
    }
  };

  const acceptFiles = async (): Promise<void> => {
    await updateFile({
      fileId: documentRequestUserFileId,
      id: documentRequestUserFile.documentRequestUser.id,
      status: DocumentRequestFileStatus.COMPLETE,
    });
    await fetchDocument();
  };

  const document = documentRequestUserFile.documents.find(d => d.id === documentId);
  const canReject = creatorId === documentRequestUserFile.creatorId && documentRequestUserFile.status !== DocumentRequestFileStatus.ACTION_REQUIRED;
  return (
    <>
      <Head>
        <title>{documentRequestUserFile.fileName || documentRequestUserFile.documentRequestFile?.fileName}</title>
      </Head>

      <MainPanelSubLayout
        header={{
          actions: [
            ...((documentRequestUserFile.status === DocumentRequestFileStatus.ACTION_REQUIRED
              && creatorId === documentRequestUserFile.creatorId && [
              {
                icon: Check,
                label: 'Accept document',
                onClick: acceptFiles,
              },
            ])
              || []),
            ...((canReject && [
              {
                isDestructive: true,
                label: 'Reject document',
                onClick: () => setRejectModalOpen(true),
              },
            ])
              || []),
          ],
          title: documentRequestUserFile.fileName || documentRequestUserFile.documentRequestFile?.fileName || 'Requested Document',
        }}
        pageContext={{
          isFetching: !workflowData,
          items: workflowData ? userWorkflowToContext(workflowData) : [],
        }}
        rightPanel={{
          component: (
            <div className={s.rightPanel}>
              <DocumentUploader
                isMultiple
                ref={documentUploaderRef}
                documentRequestUserFileId={documentRequestUserFileId}
                onUploadProgressChange={() => {}}
                onUploadError={() => {}}
                onFinishUpload={async () => {
                  await fetchDocument();
                  setIsUploading(false);
                }}
                onUploadStart={() => setIsUploading(true)}
              />
              {documentRequestUserFile.rejectionMessage && documentRequestUserFile.status === DocumentRequestFileStatus.ACTION_REQUIRED && (
                <div className={s.rejectionContainer}>
                  <BannerStatus status="error" label={documentRequestUserFile.rejectionMessage} />
                </div>
              )}
              <Reorder.Group ref={reorderContainerRef} values={files} onReorder={setFiles} axis="y" className={s.table}>
                {files.map(rightPanelDocument => (
                  <Reorder.Item
                    whileDrag={{ backgroundColor: 'var(--color-gray-0)', cursor: 'grabbing' }}
                    value={rightPanelDocument}
                    key={rightPanelDocument.id}
                    dragConstraints={reorderContainerRef}
                    style={documentId === rightPanelDocument.id ? { backgroundColor: 'var(--color-gray-15)' } : {}}
                  >
                    <Buttons isWrapper onClick={() => setDocumentId(rightPanelDocument.id)} ariaLabel={rightPanelDocument.fileName}>
                      <div className={s.document}>
                        <div style={{ alignItems: "center", display: "flex", flexDirection: "row", width: '100%' }}>
                          <File document={rightPanelDocument} />
                          <DotsSixVertical size={24} color="var(--color-gray-50)" />
                        </div>
                      </div>
                    </Buttons>
                    <div className={s.documentFooter}>
                      <DocumentStatus document={rightPanelDocument} />
                      <Button
                        variant="destructive"
                        size="xs"
                        onClick={() => removeFile(rightPanelDocument.id)}
                        loading={removingFileId === rightPanelDocument.id}
                        className={s.removeButton}
                      >
                        Remove
                      </Button>
                    </div >
                  </Reorder.Item>
                ))}
              </Reorder.Group>
              <div className={s.buttons}>
                <Button loading={isUploading} fullWidth variant="primary" size="sm" onClick={uploadFile}>
                  Upload more files
                </Button>
                <Spacer size={8} />
                <Button fullWidth variant="destructive" size="sm" onClick={removeAllFiles} loading={isRemovingAllFiles}>
                  Remove all
                </Button>
              </div>
            </div>
          ),
          title: 'Files',
        }}
      >
        <div className={s.container}>
          {documentRequestUserFile.documents.length === 0 && (
            <div className={s.empty}>
              <p className="body-md">No documents have been uploaded yet.</p>
            </div>
          )}
          {document && (
            <DocumentAccessGate
              documentCreatorId={documentRequestUserFile.creatorId}
              documentPersonId={documentRequestUserFile.personId}
              isCollaborator={false}
              isCenteredVertically
            >
              <div style={{ width: '100%' }}>
                <DocumentPreview
                  document={document}
                  accessToken={accessToken}
                  canDownload={true}
                  canEvaluateWithAi={true}
                  documentRequestFileDescription={documentRequestUserFile.fileDescription}
                  documentRequestFileName={documentRequestUserFile.fileName}
                  showRenameButton={true}
                />
              </div>
            </DocumentAccessGate>
          )}
        </div>
      </MainPanelSubLayout>
      {isRejectModalOpen && (
        <RejectDocumentModal
          refetchDocument={fetchDocument}
          documentRequestUserId={documentRequestUserFile.documentRequestUser.id}
          fileId={documentRequestUserFile.id}
          onClose={() => setRejectModalOpen(false)}
        />
      )}
    </>
  );
};

export default DocumentRequestUserFileDetail;
