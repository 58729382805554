import React, { FC, useContext, useEffect, useState, useMemo, useCallback } from 'react';
import { FormProviders, FormRequestTemplate } from '@typings/api-models';
import InputField from '@components/FormFields/InputField/InputField';
import Spacer from '@components/Spacer';
import useNoodleApi from '@hooks/useNoodleApi';
import * as tsClient from '@tsClient';
import CustomSelect from '@components/FormFields/CustomSelect';
import AttachmentSettings from '@components/DesignLibrary/AttachmentSettings/AttachmentSettings';
import Button from '@components/DesignLibrary/Button';
import Header from '@components/DesignLibrary/Header';
import CheckBox from '@components/DesignLibrary/Atoms/CheckBox';
import { ListAndDetailsSubLayoutContext } from '@layouts/ListAndDetailsSubLayout';
import { useFeatureFlag } from '@providers/FeatureFlags';
import { useRouter } from 'next/router';
import PageContext from '@components/DesignLibrary/PageContext';
import TrashSimple from '@components/Icons/TrashSimple';
import { getUrl, IDENTIFIERS } from '@helpers/urlsHelper';
import * as ApiModels from '@typings/api-models';
import ProgressIndicator from '@components/ProgressIndicator';
import QuestionnairePreview from '@/components/Questionnaire/QuestionnairePreview';
import PencilSimple from '@components/Icons/PencilSimple';
import TextInput from '@/components/FormFields/TextInput';
import { useOpenFormRequestTemplatePane } from '@/panes/hooks';

import s from './CreateEditFormRequestTemplateForm.module.scss';

type Props = {
  formRequestTemplate: Pick<
    FormRequestTemplate,
    | 'canAutoComplete'
    | 'id'
    | 'inheritanceScheme'
    | 'isFollowupEnabled'
    | 'name'
    | 'description'
    | 'provider'
    | 'providerLocator'
  > | null;
  onSubmit: () => void;
  beforeClose?: () => Promise<void>;
  creatorId: string;
};

const ProviderOptions: { value: string; title: string }[] = [
  { title: 'Anvil', value: FormProviders.Anvil },
  { title: 'Typeform', value: FormProviders.Typeform },
];

const InheritanceSchemeOptions: { value: FormRequestTemplate['inheritanceScheme']; title: string }[] = [
  { title: 'None', value: 'none' },
  { title: 'Inherit from last submission by creator', value: 'creator' },
  { title: 'Inherit from last submission by the customer', value: 'customer' },
];

const CreateEditFormRequestTemplateForm: FC<Props> = ({ beforeClose, formRequestTemplate, onSubmit, creatorId }) => {
  const { reloadListView } = useContext(ListAndDetailsSubLayoutContext);
  const router = useRouter();
  const isEditing = !!formRequestTemplate;
  const [isArchiving, setIsArchiving] = React.useState(false);
  const [title, setTitle] = useState(formRequestTemplate?.name || '');
  const [description, setDescription] = useState(formRequestTemplate?.description || '');
  const [isSaving, setIsSaving] = useState(false);
  const [selectedTab, setSelectedTab] = useState('Settings');
  const [isFollowupEnabled, setFollowupEnabled] = useState(formRequestTemplate?.isFollowupEnabled || false);
  const [canAutoComplete, setCanAutoComplete] = useState<boolean>(formRequestTemplate?.canAutoComplete ?? false);
  const [inheritanceScheme, setInheritanceScheme] = useState<FormRequestTemplate['inheritanceScheme']>(
    formRequestTemplate?.inheritanceScheme ?? 'none',
  );
  const [provider, setProvider] = useState<FormProviders>(formRequestTemplate?.provider || FormProviders.Anvil);
  const [providerLocator, setProviderLocator] = useState(formRequestTemplate?.providerLocator || '');
  const canEditFormRequestProvider = useFeatureFlag('can-edit-form-request-provider');
  const canUseNoodleQuestionnaire = useFeatureFlag('can-use-noodle-questionnaire');
  const [useExistingQuestionnaire, setUseExistingQuestinnaire] = useState(!!formRequestTemplate?.providerLocator);
  const openFormRequestTemplatePane = useOpenFormRequestTemplatePane();

  const { getData: updateTemplate } = useNoodleApi(tsClient.formRequests.updateFormRequestTemplate, {
    toastOnError: true,
  });
  const { getData: postTemplate } = useNoodleApi(tsClient.formRequests.postFormRequestTemplate, {
    toastOnError: true,
  });
  const {
    data: workflows,
    getData: getWorkflowsFn,
    fetchingState: { isFetching: isFetchingWorkflows },
  } = useNoodleApi(tsClient.workflows.getWorkflowsFromBuildingBlock);
  const { getData: archiveFormRequestFn } = useNoodleApi(tsClient.formRequests.archiveFormRequestTemplate);

  const handleSave = async (): Promise<void> => {
    setIsSaving(true);

    if (isEditing) {
      await updateTemplate({
        id: formRequestTemplate!.id,
        updates: {
          canAutoComplete,
          description,
          inheritanceScheme,
          isFollowupEnabled,
          name: title,
          provider,
          providerLocator: provider !== FormProviders.Noodle || useExistingQuestionnaire ? providerLocator : '',
        },
      });
      reloadListView();
    } else {
      await postTemplate({
        canAutoComplete,
        creatorId,
        description,
        inheritanceScheme,
        isFollowupEnabled,
        name: title,
        provider,
        providerLocator: provider !== FormProviders.Noodle || useExistingQuestionnaire ? providerLocator : '',
      });
    }
    await beforeClose?.();
    setIsSaving(false);
    onSubmit();
  };

  useEffect(() => {
    if (formRequestTemplate) {
      getWorkflowsFn({ page: 1, perPage: 1000, referenceId: formRequestTemplate.id });
    }
  }, [getWorkflowsFn, formRequestTemplate]);

  const archiveFormRequestTemplate = async (): Promise<void> => {
    if (formRequestTemplate) {
      setIsArchiving(true);
      await archiveFormRequestFn({ id: formRequestTemplate.id });
      await reloadListView();
      onSubmit();
      await router.push(getUrl(IDENTIFIERS.DASHBOARD_FORM_REQUESTS));
      setIsArchiving(false);
    }
  };

  const providerOptions = useMemo(() => canUseNoodleQuestionnaire
    ? [
      ...ProviderOptions,
      { title: 'Noodle', value: FormProviders.Noodle },
    ]
    : ProviderOptions, [canUseNoodleQuestionnaire]);

  const hasQuestionnaireTemplate = formRequestTemplate?.provider === FormProviders.Noodle
    && formRequestTemplate.providerLocator;

  const handleEditQuestionnaireTemplate = (): void => {
    if (formRequestTemplate) {
      openFormRequestTemplatePane({ formRequestTemplateSlug: formRequestTemplate.providerLocator });
    }
  };

  const renderPreview = useCallback((): JSX.Element => {
    switch (provider) {
    case FormProviders.Noodle:
      return <QuestionnairePreview slug={providerLocator} />;
    case FormProviders.Anvil:
    default:
      return <iframe
        className={s.iframe}
        src={
          provider === FormProviders.Anvil
            ? `https://app.useanvil.com/form/noodle/${providerLocator}`
            : `https://form.typeform.com/to/${providerLocator}`
        }
      />;
    }
  }, [provider, providerLocator]);

  return (
    <>
      {isEditing
        ? (
          <>
            <Header
              title={title}
              secondaryActions={
                hasQuestionnaireTemplate
                  ? [{
                    icon: PencilSimple,
                    label: 'Edit',
                    onClick: handleEditQuestionnaireTemplate,
                  },
                  {
                    icon: TrashSimple,
                    isDestructive: true,
                    isFetching: isArchiving,
                    label: 'Archive',
                    onClick: archiveFormRequestTemplate,
                  },
                  ]
                  : [{
                    icon: TrashSimple,
                    isDestructive: true,
                    isFetching: isArchiving,
                    label: 'Archive',
                    onClick: archiveFormRequestTemplate,
                  }]}
              tabs={providerLocator || hasQuestionnaireTemplate
                ? [
                  { label: 'Settings', onClick: () => setSelectedTab('Settings'), selected: selectedTab === 'Settings' },
                  { label: 'Preview', onClick: () => setSelectedTab('Preview'), selected: selectedTab === 'Preview' },
                ]
                : undefined}
            />
            <div className={s.context}>
              <PageContext
                collapsible
                label={isFetchingWorkflows ? 'workflows' : `Used in ${workflows?.items.length} workflow${workflows?.items && workflows?.items.length === 1 ? '' : 's'}`}
                items={workflows?.items?.map?.((workflow: Pick<ApiModels.Workflow, 'id' | 'name' | 'slug'>) => ({
                  href: `${router.asPath.split('?')[0]}?paneWorkflowSlug=${workflow.slug}${router.asPath.split('?')[1] ? `&${router.asPath.split('?')[1]}` : ''}`,
                  id: workflow.id,
                  value: workflow.name,
                })) || []}
                isFetching={isFetchingWorkflows}
              />
            </div>
          </>
        )
        : (
          <Spacer size={8} />
        )}
      {isArchiving && (
        <>
          <Spacer size={16} />
          <ProgressIndicator isCentered />
        </>
      )}
      {selectedTab === 'Preview' && renderPreview()}
      {selectedTab === 'Settings' && (
        <>
          <div className={s.padding}>
            <InputField id="title" name="title" values={{ title }} onChange={setTitle} label="Title of form request" hasFixedHeight />
            <TextInput id="description" value={description} onChange={setDescription} label="Description of form request" rows={3} />
          </div>
          <AttachmentSettings
            isFollowupEnabled={isFollowupEnabled}
            setIsFollowupEnabled={setFollowupEnabled}
          />
          <CheckBox
            className={s.settingsRow}
            id="can-auto-complete"
            isChecked={canAutoComplete}
            onChange={setCanAutoComplete}
            label="Auto Submit Form"
          />
          <div className={s.padding}>
            {canEditFormRequestProvider && (
              <>
                <CustomSelect
                  options={InheritanceSchemeOptions}
                  value={inheritanceScheme}
                  id="inheritance-scheme"
                  onChange={value => setInheritanceScheme(value as FormRequestTemplate['inheritanceScheme'])}
                  label="Inheritance Scheme"
                />
                <Spacer size={16} />
                <CustomSelect
                  options={providerOptions}
                  value={provider}
                  id="stripeAccountId"
                  onChange={value => setProvider(value as FormProviders)}
                  label="Form Provider"
                  disabled={isEditing}
                />
                <Spacer size={16} />
                {provider === FormProviders.Noodle && <CheckBox
                  id="use-existing-noodle-questionnaire"
                  isChecked={useExistingQuestionnaire}
                  onChange={setUseExistingQuestinnaire}
                  label="Use existing Noodle Questionnaire"
                />}
                {(provider !== FormProviders.Noodle || useExistingQuestionnaire) && <InputField
                  id="providerLocator"
                  name="providerLocator"
                  values={{ providerLocator }}
                  onChange={setProviderLocator}
                  label={provider === FormProviders.Noodle ? "Questionnaire Template Slug" : "Provider Locator"}
                  hasFixedHeight
                />}
                <Spacer size={16} />
              </>
            )}
            <Button fullWidth variant="primary" size="md" onClick={handleSave} loading={isSaving} disabled={!title}>
              Save
            </Button>
          </div>
        </>
      )}
    </>
  );
};

export default CreateEditFormRequestTemplateForm;
