import Badge from '@/components/DesignLibrary/Badge';
import { TD } from '@/components/DesignLibrary/Table';

export type Props = {
  type: 'badge';
  color: string;
  label: string;
};

const BadgeCell: React.FC<Props> = ({ color, label }) => (
  <TD>
    <Badge color={color} label={label} />
  </TD>
);

export default BadgeCell;
