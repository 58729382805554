import { FC, useEffect, useState } from 'react';
import * as tsClient from '@tsClient';
import useNoodleApi from '@/hooks/useNoodleApi';
import { THead, Table, TH, TD, TableRow, TBody } from '@/components/DesignLibrary/Table';
import UserImage from '@/components/UserImage';
import CheckCircle from '@/components/Icons/CheckCircle';
import XCircle from '@/components/Icons/XCircle';
import SecondaryActions from '@/components/DesignLibrary/SecondaryActions';
import ProgressIndicator from '@/components/ProgressIndicator';
import * as ApiModels from '@typings/api-models';
import CheckBox from '@components/FormFields/CheckBox';

import s from '../../CreatorProductAvailability.module.scss';

const TeamMembers: FC<{
  teamMembers: Awaited<ReturnType<typeof tsClient.creators.getCreatorTeam>>;
  product: Pick<ApiModels.Product, 'id' | 'defaultSchedulingPersonId'>;
  creatorId: string;
  setSelectedMember: (id: string) => void;
}> = ({ teamMembers, product, setSelectedMember, creatorId }) => {
  const [availability, setAvailability] = useState<{ [key: string]: boolean }>({});
  const [calendars, setCalendars] = useState<{ [key: string]: boolean }>({});
  const [defaultSchedulingPersonId, setDefaultSchedulingPersonId] = useState<string | null>(product.defaultSchedulingPersonId);
  const { getData: getProductAvailability } = useNoodleApi(tsClient.getProductAvailability);
  const { getData: getCalendarAccounts } = useNoodleApi(tsClient.getCalendarAccounts);
  const { getData: updateProduct } = useNoodleApi(tsClient.products.updateProductById, { toastOnError: true });

  useEffect(() => {
    const fetchAvailability = async (memberId: string): Promise<void> => {
      const { data: availabilityData } = await getProductAvailability({ productId: product.id, teamMemberPersonId: memberId });
      const { data: calendarsData } = await getCalendarAccounts({ creatorId, personId: memberId });
      const hasAvailabilitySet = Boolean(availabilityData && Object.keys(availabilityData.availability).length > 0);
      const hasCalendarsSet = Boolean(calendarsData && calendarsData.items.length > 0);
      setAvailability(prev => ({ ...prev, [memberId]: hasAvailabilitySet }));
      setCalendars(prev => ({ ...prev, [memberId]: hasCalendarsSet }));
    };

    const fetchAllAvailabilities = async (): Promise<void> => {
      if (product.id) {
        await Promise.all(teamMembers.items.map(tm => (tm.id ? fetchAvailability(tm.id) : Promise.resolve())));
      }
    };

    fetchAllAvailabilities();
  }, [product, teamMembers.items, getProductAvailability, creatorId, getCalendarAccounts]);

  const data = teamMembers.items
    .map(tm => ({
      hasAvailabilityCheckmark: availability[tm.id],
      hasCalendarsCheckmark: calendars[tm.id],
      id: tm.id,
      label: tm.name || tm.email || tm.id,
      userImage: {
        color: tm.primaryColour?.hex,
        name: tm.name || undefined,
        url: tm.image?.url,
      },
    }))
    .sort((a, b) => (availability[b.id] ? 1 : 0) - (availability[a.id] ? 1 : 0));

  const handleDefaultAssignee = (personId: string): void => {
    const newPersonId = defaultSchedulingPersonId === personId ? null : personId;
    setDefaultSchedulingPersonId(newPersonId);
    updateProduct({
      body: {
        defaultSchedulingPersonId: newPersonId,
      },
      productId: product.id,
    });
  };

  return (
    <>
      <Table className={s.membersTable}>
        <THead>
          <TableRow>
            <TH label="Team member" />
            <TH label="Availability" />
            <TH label="Connected calendars" />
            <TH label="Is default assignee" />
            <TH label="Actions" />
          </TableRow>
        </THead>
        <TBody>
          {data.map(member => (
            <TableRow key={member.id}>
              <TD>
                <div className={s.member}>
                  <UserImage size={20} {...member.userImage} />
                  <p className="caption">{member.label}</p>
                </div>
              </TD>
              <TD>
                {member.hasAvailabilityCheckmark === undefined
                  ? (
                    <ProgressIndicator size={20} />
                  )
                  : (
                    <>
                      {(member.hasAvailabilityCheckmark && <CheckCircle weight="fill" color="var(--color-success)" size={20} />) || (
                        <XCircle weight="fill" color="var(--color-gray-50)" size={20} />
                      )}
                    </>
                  )}
              </TD>
              <TD>
                {member.hasCalendarsCheckmark === undefined
                  ? (
                    <ProgressIndicator size={20} />
                  )
                  : (
                    <>
                      {(member.hasCalendarsCheckmark && <CheckCircle weight="fill" color="var(--color-success)" size={20} />) || (
                        <XCircle weight="fill" color="var(--color-gray-50)" size={20} />
                      )}
                    </>
                  )}
              </TD>
              <TD>
                <CheckBox
                  disabled={!member.hasCalendarsCheckmark || !member.hasAvailabilityCheckmark}
                  id={`isDefaultAssignee-${member.id}`}
                  name={`isDefaultAssignee-${member.id}`}
                  values={{ [`isDefaultAssignee-${member.id}`]: defaultSchedulingPersonId === member.id }}
                  onChange={() => handleDefaultAssignee(member.id)}
                  hasFixedHeight={false}
                  className={s.defaultAssigneeCheckbox}
                />
              </TD>
              <TD>
                <SecondaryActions
                  ariaLabel="Actions"
                  hideTooltip
                  compact
                  position="static"
                  onSelect={() => setSelectedMember(member.id)}
                  options={[
                    {
                      value: 'Edit Availability',
                    },
                  ]}
                />
              </TD>
            </TableRow>
          ))}
        </TBody>
      </Table>
    </>
  );
};

export default TeamMembers;
