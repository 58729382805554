import { useEffect, useState } from 'react';
import { StatusMessages, useReactMediaRecorder } from 'react-media-recorder';
import { m } from 'framer-motion';
import Buttons from '@components/Buttons';
import WaveSurferAudioPlayer from '@components/WaveSurferAudioPlayer';
import Spacer from '@components/Spacer';
import { logError } from '@providers/ErrorTracking';
import { mixpanelTrack } from '@providers/Mixpanel';
import UploadAudioButton from './UploadAudioButton';

const containerStyle = {
  alignItems: 'center',
  display: 'flex',
  height: 72,
  justifyContent: 'center',
  width: '100%',
};

const STATUS_THAT_ARE_OK: StatusMessages[] = ['idle', 'acquiring_media', 'delayed_start', 'recording', 'stopping', 'stopped', 'paused'];

type MediaRecorderProps = {
  setPopoverOpen: (isOpen: boolean) => void;
  onSubmit: (url: string) => void;
  onFileUploadClicked: () => void;
};

const MediaRecordOrUpload: React.FC<MediaRecorderProps> = ({ setPopoverOpen, onSubmit, onFileUploadClicked }) => {
  const { error: mediaRecorderError, status, startRecording, stopRecording, mediaBlobUrl } = useReactMediaRecorder({ audio: true });
  const [canAskForPermission, setCanAskForPermission] = useState<boolean>(false);
  const [isPermissionDenied, setIsPermissionDenied] = useState<boolean>(true);

  useEffect(() => {
    if (!STATUS_THAT_ARE_OK.includes(status)) {
      const error = new Error('ReactMediaRecorder invalid status');
      logError(error, { status });
    }
  }, [status]);

  useEffect(() => {
    if (mediaRecorderError) {
      mixpanelTrack('Failed to record audio', {
        mediaRecorderError,
        status,
      });
    }
  }, [status, mediaRecorderError]);

  const record = (): void => {
    startRecording();
  };

  const checkPermissions: () => Promise<void> = async () => {
    if (navigator.permissions) {
      const permissionStatus = await navigator.permissions.query({ name: 'microphone' as PermissionName });
      if (permissionStatus.state === 'granted') {
        setCanAskForPermission(false);
        setIsPermissionDenied(false);
      } else if (permissionStatus.state === 'denied') {
        setIsPermissionDenied(true);
        setCanAskForPermission(false);
      } else if (permissionStatus.state === 'prompt') {
        setIsPermissionDenied(true);
        setCanAskForPermission(true);
      }
    }
  };

  useEffect(() => {
    checkPermissions();
  }, []);

  return (
    <div style={{ alignItems: 'center', display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'space-between', padding: 16 }}>
      <div style={containerStyle}>
        <m.div
          animate={status === 'recording' ? { scale: [1, 1.2] } : { scale: 1 }}
          transition={
            status === 'recording'
              ? {
                duration: 1,
                repeat: Infinity,
                repeatType: 'mirror',
              }
              : undefined
          }
          style={{
            alignItems: 'center',
            border: '2px solid #f2f2f2',
            borderRadius: '50%',
            display: 'flex',
            height: 72,
            justifyContent: 'center',
            position: 'absolute',
            width: 72,
          }}
        />
        <m.button
          onClick={status === 'recording' ? stopRecording : record}
          style={{
            background: isPermissionDenied ? 'var(--color-gray-50)' : 'red',
            border: 'none',
            borderRadius: '24px',
            height: 48,
            position: 'absolute',
            width: 48,
          }}
          disabled={isPermissionDenied}
          whileTap={(status === 'recording' && { scale: 0.6 }) || (!isPermissionDenied && { scale: 0.8 }) || { scale: 1 }}
          animate={
            status === 'recording'
              ? {
                borderRadius: '8px',
                scale: 0.7,
              }
              : { borderRadius: '24px' }
          }
          aria-label={status === 'recording' ? 'Stop Recording' : 'Start Recording'}
        />
      </div>
      {isPermissionDenied && (
        <>
          <Spacer size="12px" />
          <p className="caption" style={{ textAlign: 'center' }}>
            Access to the microphone blocked, please update your browser settings
          </p>
          {canAskForPermission && (
            <>
              <Spacer size="12px" />
              <Buttons
                isThird
                onClick={async () => {
                  await navigator.mediaDevices
                    .getUserMedia({ audio: true })
                    .then(() => {
                      setIsPermissionDenied(false);
                    })
                    .catch(() => {
                      setIsPermissionDenied(true);
                    });
                }}
                style={{ fontSize: 12, height: 40 }}
              >
                Allow access
              </Buttons>
            </>
          )}
        </>
      )}
      <Spacer size="16px" />
      {status === 'stopped' && mediaBlobUrl
        ? (
          <>
            <Spacer size="12px" />
            <div
              style={{
                display: 'grid',
                gridGap: '16px',
                marginTop: '-14px',
                width: '100%',
              }}
            >
              <WaveSurferAudioPlayer audio={{ url: mediaBlobUrl }} isSmall={true} />
              <Buttons
                isSecondary
                isFullWidth
                onClick={() => {
                  onSubmit(mediaBlobUrl);
                  setPopoverOpen(false);
                }}
              >
            Use recording
              </Buttons>
            </div>
          </>
        )
        : (
          <UploadAudioButton
            onClick={() => {
              onFileUploadClicked();
              setPopoverOpen(false);
            }}
          />
        )}
    </div>
  );
};

const MediaRecorder = ({ setPopoverOpen, onSubmit, onFileUploadClicked }: MediaRecorderProps): React.ReactElement => {
  if (window.MediaRecorder) {
    return <MediaRecordOrUpload setPopoverOpen={setPopoverOpen} onSubmit={onSubmit} onFileUploadClicked={onFileUploadClicked} />;
  }

  return (
    <div style={containerStyle}>
      <UploadAudioButton
        onClick={() => {
          onFileUploadClicked();
          setPopoverOpen(false);
        }}
      />
    </div>
  );
};

export default MediaRecorder;
