import { FC, useRef, useState } from 'react';
import Modal from '@components/Modal';
import InputComposer, { InputComposerRef } from '@components/DesignLibrary/InputComposer';
import commonStyles from '@modals/ModalsCommon.module.scss';
import Button from '@components/DesignLibrary/Button';
import useNoodleApi from '@hooks/useNoodleApi';
import * as tsClient from '@tsClient';
import * as ApiModels from '@typings/api-models';
import CollapsibleArea from '@components/CollapsibleArea';
import { Select } from '@components/DesignLibrary/Atoms';
import Spacer from '@components/Spacer';
import * as format from '@format';
import DatePicker from '@components/FormFields/DatePicker';
import TimeSelect from '@lib/CreatorProductAvailability/components/TimeSelect';
import Label from '@components/DesignLibrary/Atoms/shared/Label';
import createRichTextAst from '@helpers/createRichTextAst';
import s from './AddUserWorkflowNoteModal.module.scss';

type Props = {
  userWorkflowId: string;
  onClose: () => void;
  onAddComment: (message: Awaited<ReturnType<typeof tsClient.workflowConversations.createWorkflowConversationMessage>>) => Promise<void>;
  collaborators: Parameters<typeof InputComposer>[0]['collaborators'];
};

const AddUserWorkflowNoteModal: FC<Props> = ({ collaborators, onAddComment, onClose, userWorkflowId}) => {
  const [isSending, setIsSending] = useState(false);
  const [value, setValue] = useState('');
  const [contactType, setContactType] = useState('');
  const [date, setDate] = useState<string | null>(null);
  const [selectedTime, setSelectedTime] = useState('');
  const [error, setError] = useState(false);
  const inputComposerRef = useRef<InputComposerRef | null>(null);

  const { getData: sendMessage } = useNoodleApi(tsClient.workflowConversations.createWorkflowConversationMessage);

  const handleSend = async ({ text }: { text: string }): Promise<void> => {
    setError(false);
    setIsSending(true);
    const { data: message } = await sendMessage({
      attachments: [],
      medias: [],
      messageType: ApiModels.MessageType.WorkflowInternalNote,
      metadata: {
        contactType,
        date,
        time: selectedTime,
      },
      text: createRichTextAst(text),
      userWorkflowId,
    });
    if (message) {
      await onAddComment(message);
      onClose();
    } else {
      setError(true);
    }
  };

  return (
    <Modal hideBottomInset title='Add internal note' onClose={onClose} >
      <div className={commonStyles.container}>
        <div className={s.inputComposerContainer}>
          <p className={s.label}>Internal note</p>
          <Spacer size={8} />
          <InputComposer
            ref={inputComposerRef}
            onChange={setValue}
            isSending={isSending}
            onSubmit={handleSend}
            showSendButton={false}
            referenceId={userWorkflowId}
            referenceType='discussion-message'
            isAsync={false}
            collaborators={collaborators}
            className={s.inputComposer}
            placeholder='Add internal note'
            isEmbedded
          />
        </div>
        <CollapsibleArea className={s.collapsible} title='Optional details'>
          <Spacer size={16} />
          <Select
            compact
            label='Contact type'
            placeholder='Select contact type'
            options={[
              {
                key: 'Phone',
                label: 'Phone',
              },
              {
                key: 'Email',
                label: 'Email',
              },
              {
                key: 'Messages',
                label: 'Messages',
              },
              {
                key: 'In Person',
                label: 'In Person',
              },
              {
                key: 'Chat',
                label: 'Chat',
              },
            ]}
            onChange={setContactType}
            value={contactType}
          />
          <Spacer size={16} />
          <DatePicker
            max={format.datetime.dateString(new Date())}
            value={date}
            onChange={setDate}
            label='Date'
          />
          <Spacer size={16} />
          <Label required compact label='Time' />
          <Spacer size={8} />
          <TimeSelect selectedTime={selectedTime} setSelectedTime={setSelectedTime} type='to' otherTime='' className={s.timeSelect} />
        </CollapsibleArea>
        {error && <p className='body-sm' style={{ color: 'var(--color-error)' }}>Failed to add note. Please try again.</p>}
        <Button disabled={!value || !inputComposerRef.current} loading={isSending} onClick={() => inputComposerRef.current?.sendMessage()} variant='primary' size='md'>
          Save
        </Button>
      </div>
    </Modal>
  );
};

export default AddUserWorkflowNoteModal;
