import Button from '@/components/DesignLibrary/Button';
import AddConditionsModal from '@/modals/AddConditionsModal';
import { Expression } from '@/typings/api-models';
import pluralize from 'pluralize';
import { useState } from 'react';
import s from './ConditionsWidget.module.scss';

type Props = {
  conditionalExpression: Expression | null;
  options: { key: string; label: string }[];
  type: 'workflow-context';
  handleSubmit: (expressionId: string) => void;
  onOpenModal: () => void;
};

const ConditionsWidget: React.FC<Props> = ({
  conditionalExpression,
  options,
  type,
  onOpenModal,
  handleSubmit,
}) => {
  const numConditions = conditionalExpression ? 1 : 0;
  const [isModalOpen, setIsModalOpen] = useState(false);
  return (
    <>
      {isModalOpen && (
        <AddConditionsModal
          conditionExpression={conditionalExpression}
          onClose={() => setIsModalOpen(false)}
          onSubmit={handleSubmit}
          options={options}
          type={type}
          numConditions={numConditions}
        />
      )}
      <Button
        variant='secondary'
        size='xs'
        onClick={() => {
          setIsModalOpen(true);
          onOpenModal();
        }}
        className={s.conditionsButton}
      >
        {numConditions > 0
          ? (
            <>
              <div className={s.number}>{numConditions}</div>
              <span>{pluralize('Condition', numConditions, false)}</span>
            </>
          )
          : 'Add Conditions'}
      </Button>
    </>
  );
};

export default ConditionsWidget;
