import { useState, forwardRef } from 'react';
import clsx from 'classnames';

import SvgRadioChecked from 'components/Icons/RadioChecked';
import SvgRadioBlank from 'components/Icons/RadioBlank';
import FieldLabel from '@components/FormFields/FieldLabel';
import FieldError from 'components/FieldError';
import FieldHelpText from 'components/FieldHelpText';

import { checkValidity } from 'helpers/validation';

import s from './RadioInput.module.scss';

const RadioInput = (
  {
    id,
    name,
    label,
    values,
    options,
    onChange,
    disabled,
    helpText,
    rules = {},
    formErrors = {},
    ...restProps
  },
  ref,
) => {
  const [isTouched, setIsTouched] = useState(false);

  const onInputChange = (e) => {
    const { value } = e.target;
    const field = e.target.name;
    const { errorMessage } = checkValidity(value, rules[field]);
    onChange(value, { formErrors: { ...formErrors, [field]: errorMessage } });
  };

  const errorMessage = formErrors[name];
  const isValid = !errorMessage;

  return (
    <div
      className={clsx(
        s['radio-input__wrapper'],
        disabled && s['radio-input__wrapper--disabled'],
      )}
    >
      <div
        className={clsx(
          s['radio-input__fields'],
          ((!isValid && errorMessage && isTouched) || helpText) && s['radio-input__fields--help-text'],
        )}
      >
        <FieldLabel id={id} isValid={isValid || !isTouched}>
          {label}
        </FieldLabel>

        {options.map((option, index) => (
          <FieldLabel
            id={option.id}
            className={clsx(
              s['radio-input__field'],
              (values[name]?.toString() === option.value.toString()) && s['radio-input__field--checked'],
            )}
            key={option.id || index}
          >
            {values[name]?.toString() === option.value.toString()
              ? (
                <SvgRadioChecked />
              )
              : (
                <SvgRadioBlank />
              )}

            {option.label}

            <input
              ref={ref}
              name={name}
              type="radio"
              id={option.id}
              disabled={disabled}
              checked={values[name]?.toString() === option.value.toString()}
              value={option.value}
              onChange={onInputChange}
              onBlur={() => setIsTouched(true)}
              className={clsx(s['radio-input__input'])}
              {...restProps}
            />
          </FieldLabel>
        ))}
      </div>

      {!isValid && errorMessage && isTouched && (
        <FieldError>{errorMessage}</FieldError>
      )}
      {helpText && !(isTouched && errorMessage) && (
        <FieldHelpText>{helpText}</FieldHelpText>
      )}
    </div>
  );
};

export default forwardRef(RadioInput);
