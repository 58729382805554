import noodleApiClient, { ApiDefinition } from '@noodleApi';

type THIS_API = ApiDefinition['/creators/{creatorId}/connect-outlook-redirect-url']['GET'];
type Input = THIS_API['params'] & THIS_API['query'];
type Output = THIS_API['response'];

const connectOutlookAccount = async ({ creatorId, personId, returnUrl }: Input): Promise<Output> => {
  const data = await noodleApiClient.get(`/creators/${creatorId}/connect-outlook-redirect-url`, {
    params: {
      personId,
      returnUrl,
    },
  });
  return data.data;
};

export default connectOutlookAccount;
