import * as Types from '@typings/graphql-models';
import { NoodleProductTypes } from '@typings/api-models';

type Product = { productTypes?: Array<Pick<Types.ProductType, 'noodleProductType'>> | null };

const hasNoodleProductType = (product: Product | null, ...expected: Array<NoodleProductTypes>): boolean => {
  const productTypes = product?.productTypes || [];
  for (let i = 0; i < productTypes.length; i += 1) {
    for (let j = 0; j < expected.length; j += 1) {
      if (productTypes[i]?.noodleProductType === expected[j]) {
        return true;
      }
    }
  }
  return false;
};

export default hasNoodleProductType;
