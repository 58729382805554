import { Day, Availability, Block } from '../types';

/*
  * Function to calculate the difference in minutes between two times
  * @param time1 - First time in the format hh:mmam/pm
  * @param time2 - Second time in the format hh:mmam/pm
  * @returns - Difference in minutes between time1 and time2
  * @example
  * timeDiff('1:00am', '12:00am') // returns 60
*/
export const timeDiff = (time1: string, time2: string): number => {
  // Regular expression to match and extract hour, minute, and am/pm from input time string
  const match = /^(\d{1,2}):(\d{2})(am|pm)$/i;

  // Extract hour, minute, and am/pm from time1 and time2
  const [, hour1, minute1, ampm1] = match.exec(time1) || ['', '0', '0', 'am'];
  const [, hour2, minute2, ampm2] = match.exec(time2) || ['', '0', '0', 'am'];

  // Convert hour to 24-hour format and calculate difference in minutes
  const h1 = (hour1 === '12' ? 0 : parseInt(hour1, 10)) + (ampm1.toLowerCase() === 'pm' ? 12 : 0);
  const h2 = (hour2 === '12' ? 0 : parseInt(hour2, 10)) + (ampm2.toLowerCase() === 'pm' ? 12 : 0);

  // Calculate difference in minutes
  const diff = (h1 - h2) * 60 + (parseInt(minute1, 10) - parseInt(minute2, 10));
  return diff;
};

/*
  * Function to get the number of a day of the week from its name
  * @param day - Name of the day of the week
  * @returns - Number of the day of the week
  * @example
  * getIsoWeekday('monday') // returns 1
*/
const getIsoWeekday: (day: string) => number = (day) => {
  switch (day) {
  case 'monday':
    return 1;
  case 'tuesday':
    return 2;
  case 'wednesday':
    return 3;
  case 'thursday':
    return 4;
  case 'friday':
    return 5;
  case 'saturday':
    return 6;
  case 'sunday':
    return 7;
  default:
    return 1;
  }
};

/*
  * Function to format the availability data for the API
  * @param timezone - Timezone of the creator
  * @param days - Array of days of the week
  * @param productId - ID of the product
  * @returns - Formatted availability data
*/
export function formatBody(days: Day[], productId: string, teamMemberPersonId: string, timezone?: string): {
  teamMemberPersonId: string;
  timezone?: string;
  availability: Record<
    number,
    Array<{
      start: {
        hours: number;
        minutes: number;
      };
      end: {
        hours: number;
        minutes: number;
      };
    }>
  >;
  productId: string;
} {
  const availability: Record<
    number,
    Array<{
      start: {
        hours: number;
        minutes: number;
      };
      end: {
        hours: number;
        minutes: number;
      };
    }>
  > = {};

  // Loop through each day of the week
  days.forEach((day) => {
    // Get the number of the day of the week
    const isoWeekday = getIsoWeekday(day.name);

    // Loop through each block of time for the day
    availability[isoWeekday] = day.blocks.map((block) => {
    // eslint-disable-next-line prefer-const
      let [startHour, startMinute] = block.from
        .split(':')
        .map((val) => parseInt(val, 10));
      const startAmPm = block.from.slice(-2);
      if (startAmPm.toLowerCase() === 'pm' && startHour !== 12) {
        startHour += 12;
      }
      if (startAmPm.toLowerCase() === 'am' && startHour === 12) {
        startHour = 0;
      }
      // eslint-disable-next-line prefer-const
      let [endHour, endMinute] = block.to
        .split(':')
        .map((val) => parseInt(val, 10));
      const endAmPm = block.to.slice(-2);
      if (endAmPm.toLowerCase() === 'pm' && endHour !== 12) {
        endHour += 12;
      }
      if (endAmPm.toLowerCase() === 'am' && endHour === 12) {
        endHour = 0;
      }

      return {
        end: { hours: endHour, minutes: endMinute },
        start: { hours: startHour, minutes: startMinute },
      };
    });
  });
  return {
    availability,
    productId,
    teamMemberPersonId,
    timezone,
  };
}

// time options for the time picker
export const times = [
  '12:00am',
  '12:15am',
  '12:30am',
  '12:45am',
  '1:00am',
  '1:15am',
  '1:30am',
  '1:45am',
  '2:00am',
  '2:15am',
  '2:30am',
  '2:45am',
  '3:00am',
  '3:15am',
  '3:30am',
  '3:45am',
  '4:00am',
  '4:15am',
  '4:30am',
  '4:45am',
  '5:00am',
  '5:15am',
  '5:30am',
  '5:45am',
  '6:00am',
  '6:15am',
  '6:30am',
  '6:45am',
  '7:00am',
  '7:15am',
  '7:30am',
  '7:45am',
  '8:00am',
  '8:15am',
  '8:30am',
  '8:45am',
  '9:00am',
  '9:15am',
  '9:30am',
  '9:45am',
  '10:00am',
  '10:15am',
  '10:30am',
  '10:45am',
  '11:00am',
  '11:15am',
  '11:30am',
  '11:45am',
  '12:00pm',
  '12:15pm',
  '12:30pm',
  '12:45pm',
  '1:00pm',
  '1:15pm',
  '1:30pm',
  '1:45pm',
  '2:00pm',
  '2:15pm',
  '2:30pm',
  '2:45pm',
  '3:00pm',
  '3:15pm',
  '3:30pm',
  '3:45pm',
  '4:00pm',
  '4:15pm',
  '4:30pm',
  '4:45pm',
  '5:00pm',
  '5:15pm',
  '5:30pm',
  '5:45pm',
  '6:00pm',
  '6:15pm',
  '6:30pm',
  '6:45pm',
  '7:00pm',
  '7:15pm',
  '7:30pm',
  '7:45pm',
  '8:00pm',
  '8:15pm',
  '8:30pm',
  '8:45pm',
  '9:00pm',
  '9:15pm',
  '9:30pm',
  '9:45pm',
  '10:00pm',
  '10:15pm',
  '10:30pm',
  '10:45pm',
  '11:00pm',
  '11:15pm',
  '11:30pm',
  '11:45pm',
];

// days of the week
export const days = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
];

const formatTime: (time: {hours: number, minutes: number}) => string = (time) => {
  let { hours } = time;
  if (hours === 0) {
    hours = 12;
  } else if (hours > 12) {
    hours -= 12;
  }
  const minutes = time.minutes < 10 ? `0${time.minutes}` : time.minutes;
  const amPm = time.hours >= 12 ? 'pm' : 'am';
  return `${hours}:${minutes}${amPm}`;
};

const formatBlock:
  (block: {end: {hours: number, minutes: number}, start: {hours: number, minutes: number}}) => {from: string, to: string} = (block) => {
    const from = formatTime(block.start);
    const to = formatTime(block.end);
    return { from, to };
  };

export const formatAvailability: (availability: Availability) => Day[] = (availability) => {
  const daysOfWeek: Day[] = [
    { blocks: [] as Block[], name: 'monday' },
    { blocks: [] as Block[], name: 'tuesday' },
    { blocks: [] as Block[], name: 'wednesday' },
    { blocks: [] as Block[], name: 'thursday' },
    { blocks: [] as Block[], name: 'friday' },
    { blocks: [] as Block[], name: 'saturday' },
    { blocks: [] as Block[], name: 'sunday' },
  ];

  Object.keys(availability).forEach((day: string) => {
    const newDay = parseInt(day, 10);
    const blocksInWeek: Block[] = [];
    const availabilityDay = availability[newDay];
    availabilityDay.forEach((block) => {
      const formattedBlock = formatBlock(block);
      blocksInWeek.push(formattedBlock);
    });

    daysOfWeek[newDay - 1].blocks = blocksInWeek;
  });

  return daysOfWeek;
};
