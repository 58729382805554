import { useEffect } from 'react';

const typographyTags = new Set([
  'P',
  'H1',
  'H2',
  'H3',
  'H4',
  'H5',
  'H6',
  'SPAN',
  'B',
  'I',
  'U',
  'SMALL',
  'EM',
  'STRONG',
  'MARK',
  'DEL',
  'INS',
  'SUB',
  'SUP',
]);

const highlightText = (text: string, regex: RegExp, elements: HTMLElement[]): void => {
  const traverseNodes = (node: Node): void => {
    if (
      node.nodeType === Node.TEXT_NODE
      && node.nodeValue?.trim()
      && regex.test(node.nodeValue)
      && !node.parentElement?.closest('search-highlight')
      && node.parentElement
      && typographyTags.has(node.parentElement.tagName)
    ) {
      const span = document.createElement('span');
      span.innerHTML = node.nodeValue.replace(regex, `<search-highlight>$1</search-highlight>`);

      const { parentNode } = node;
      if (parentNode) {
        parentNode.replaceChild(span, node);
      }
    } else if (node.childNodes.length > 0) {
      Array.from(node.childNodes).forEach(childNode => traverseNodes(childNode));
    }
  };
  elements.forEach(element => {
    traverseNodes(element);
  });
};

const removeHighlights = (elements: HTMLElement[]): void => {
  elements.forEach(element => {
    const highlights = element.querySelectorAll('search-highlight');

    if (highlights.length > 0) {
      highlights.forEach(el => {
        const parent = el.parentNode;
        if (parent) {
          parent.replaceChild(document.createTextNode(el.textContent || ''), el);
        }
      });

      element.normalize();
    }
  });
};

const escapeSpecialChars = (string: string): string => string.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&');

const useHighlightSearchTerm = (searchTerm: string, listRef: React.RefObject<HTMLDivElement>, contentRef: React.RefObject<HTMLDivElement>): void => {
  useEffect(() => {
    const elements = [listRef.current, contentRef.current].filter(Boolean) as HTMLElement[];
    if (searchTerm.length < 3) {
      removeHighlights(elements);
      return () => {};
    }
    const escapedSearchTerm = escapeSpecialChars(searchTerm);
    const regex = new RegExp(`(${escapedSearchTerm})`, 'gi');

    const observer = new MutationObserver(() => {
      highlightText(searchTerm, regex, elements);
    });

    elements.forEach(element => {
      observer.observe(element, { childList: true, subtree: true });
    });

    return () => {
      observer.disconnect();
      removeHighlights(elements);
    };
  }, [searchTerm, listRef, contentRef]);
};

export default useHighlightSearchTerm;
