import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ChangeEvent, HostedFieldsState, OnChangeCb } from './ConfidoTypes';
import validateState from './validateState';

type Params =
  & {
    isDebitCardPaymentEnabled: boolean;
    isDebitCardPaymentRequiredForCustomer: boolean;
    isCreditPaymentEnabled: boolean;
    isCustomerPaymentMethod: boolean;
    isAchPaymentEnabled: boolean;
    formType: 'card' | 'ach';
  }
  & (
    | {
      paymentToken: string | null;
      paymentMethodToken?: never;
    }
    | {
      paymentToken?: never;
      paymentMethodToken: string | null;
    }
  );

const useConfido = (
  params: Params,
): { state: HostedFieldsState | undefined, reload: () => void } => {
  const [hostedFieldsStateInternal, setHostedFieldsStateInternal] = useState<HostedFieldsState>();
  const listenerRef = useRef<OnChangeCb>();

  const reload = useCallback(() => {
    const gl = window.gravityLegal;
    const listener = (event: ChangeEvent): void => {
      setHostedFieldsStateInternal(event.state);
    };

    listenerRef.current = listener;

    gl.addChangeListener(listener);

    const fieldStyle = {
      border: '1px solid #EBEBEB',
      'border-radius': '4px',
      color: '#010101',
      'font-family':
        '-apple-system, "system-ui", "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
      'font-size': '16px',
      'font-weight': 400,
      height: '44px',
      width: '100%',
    };

    gl.init({
      activeForm: params.formType,
      fields: {
        accountHolderName: {
          containerId: 'account-holder-name',
          style: fieldStyle,
        },
        accountNumber: {
          containerId: 'account-number',
          style: fieldStyle,
        },
        cardExpirationDate: {
          containerId: 'card-exp',
          style: fieldStyle,
        },
        cardNumber: {
          containerId: 'card-number',
          style: fieldStyle,
        },
        cardSecurityCode: {
          containerId: 'card-cvv',
          style: fieldStyle,
        },
        routingNumber: {
          containerId: 'routing-number',
          style: fieldStyle,
        },
      },
      paymentToken: params.paymentToken || undefined,
      savePaymentMethodToken: params.paymentMethodToken || undefined,
    });
  }, [params.formType, params.paymentToken, params.paymentMethodToken]);

  useEffect(() => () => {
    if (listenerRef.current) {
      window.gravityLegal.removeChangeListener(listenerRef.current);
    }
  }, []);

  const hostedFieldsState = useMemo(() => {
    if (!hostedFieldsStateInternal) {
      return undefined;
    }
    return validateState({
      isAchPaymentEnabled: params.isAchPaymentEnabled,
      isCreditPaymentEnabled: params.isCreditPaymentEnabled,
      isCustomerPaymentMethod: params.isCustomerPaymentMethod,
      isDebitCardPaymentEnabled: params.isDebitCardPaymentEnabled,
      isDebitCardPaymentRequiredForCustomer: params.isDebitCardPaymentRequiredForCustomer,
      state: hostedFieldsStateInternal,
    });
  }, [hostedFieldsStateInternal, params.isAchPaymentEnabled, params.isCreditPaymentEnabled, params.isDebitCardPaymentEnabled,
    params.isDebitCardPaymentRequiredForCustomer, params.isCustomerPaymentMethod]);

  return {
    reload,
    state: hostedFieldsState,
  };
};

export default useConfido;
