import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import TeamsContext from '@providers/Teams/TeamsContext';
import useNoodleApi from '@hooks/useNoodleApi';
import * as tsClient from '@tsClient';
import { SectionTemplate } from '@/typings/questionnaires-api-models';
import DynamicForm from '@/components/Questionnaire/form/DynamicForm';
import Bluebird from 'bluebird';

type Props = { slug: string };

const QuestionnairePreview: React.FC<Props> = ({ slug }) => {
  const { currentTeamOwner } = useContext(TeamsContext);
  const {
    data: questionnaireTemplate,
    getData: getQuestionnaireTemplate,
  } = useNoodleApi(tsClient.questionnaires.getQuestionnaireTemplateBySlug);

  const { getData: getFieldTemplates } = useNoodleApi(tsClient.questionnaires.getFieldTemplatesForSectionTemplate);

  const [sectionFields, setSectionFields] = useState<Record<string, unknown>>({});

  const creatorId = currentTeamOwner?.id;

  useEffect(() => {
    if (creatorId) {
      getQuestionnaireTemplate({ creatorId, questionnaireTemplateSlug: slug, statusOrVersion: 'draft' });
    }
  }, [getQuestionnaireTemplate, slug, creatorId]);

  const getFieldTemplatesForSectionTemplate = useCallback(async (section: Pick<SectionTemplate, 'id' | 'slug' | 'questionnaireTemplateId'>): Promise<void> => {
    if (questionnaireTemplate?.creatorId && section.id) {
      const { data: fields } = await getFieldTemplates({
        creatorId: questionnaireTemplate.creatorId,
        questionnaireTemplateId: section.questionnaireTemplateId,
        sectionTemplateId: section.id,
      });
      if (fields) {
        setSectionFields(prev => ({
          ...prev,
          [section.id!]: fields.map((field) => ({
            ...field,
            assignedTo: field.fieldAssigneeTemplates?.map((assignee) => assignee.questionnaireAssigneeTemplateId) ?? [],
            description: field.descriptionText ?? '',
            disabled: false,
            id: field.id!,
            validation: field.validation || undefined,
          })),
        }));
      }
    }
  }, [questionnaireTemplate?.creatorId, getFieldTemplates]);

  // @ts-expect-error @todo: fix validation typing issue later
  const formData: Questionnaire.Questionnaire | undefined = useMemo(() =>
    questionnaireTemplate
      ? ({
        ...questionnaireTemplate,
        assigneeTemplates: questionnaireTemplate.questionnaireAssigneeTemplates,
        description: questionnaireTemplate.descriptionText ?? '',
        id: questionnaireTemplate.id!,
        sections: questionnaireTemplate.sectionTemplates?.map((section: SectionTemplate) => ({
          ...section,
          description: section.descriptionText ?? '',
          // @todo: support html text later
          fields: sectionFields[section.id!] ?? [],
          id: section.id!,
          isLoading: !sectionFields[section.id!],
        })).sort(
          (a: SectionTemplate, b: SectionTemplate) => {
            const rankA = a.rank ?? Number.MAX_SAFE_INTEGER;
            const rankB = b.rank ?? Number.MAX_SAFE_INTEGER;
            return rankA - rankB;
          },
        ) ?? [],
        updatedByName: '',
      })
      : undefined,
  [sectionFields, questionnaireTemplate],
  );

  useEffect(() => {
    if (questionnaireTemplate?.sectionTemplates) {
      Bluebird.mapSeries(questionnaireTemplate.sectionTemplates.sort((a, b) => a.rank - b.rank), getFieldTemplatesForSectionTemplate);
    }
  }, [questionnaireTemplate, getFieldTemplatesForSectionTemplate]);

  if (!creatorId || !questionnaireTemplate) {
    return null;
  }

  return formData
    ? (
      <DynamicForm
        defaultValues={{}}
        isPreview
        formData={formData}
        onSubmit={async () => true}
      />
    )
    : null;
};

export default QuestionnairePreview;
