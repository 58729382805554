import React, { FC, useEffect, useState } from 'react';
import Modal from '@components/Modal';
import { mixpanelTrack } from '@providers/Mixpanel';
import {
  StripeCardCvcElementChangeEvent,
  StripeCardExpiryElementChangeEvent,
  StripeCardNumberElementChangeEvent,
  StripeError,
} from '@stripe/stripe-js';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { CARD_ELEMENT_OPTIONS } from '@helpers/checkout';
import Buttons from '@components/Buttons';
import Spacer from '@components/Spacer';
import * as Types from '@typings/graphql-models';
import * as tsClient from '@tsClient';
import useUserProfile from '@providers/Auth/useUserProfile';
import useNoodleApi from '@hooks/useNoodleApi';
import s from './PaymentMethodEditModal.module.scss';

type Props = {
  creator: Pick<Types.Creator, 'id' | 'stripe_account_id'>;
  onClose: () => void;
  onSaveCard?: () => Promise<void>;
  personId: string;
};

type Event = StripeCardNumberElementChangeEvent | StripeCardCvcElementChangeEvent | StripeCardExpiryElementChangeEvent;

const PaymentMethodEditModal: FC<Props> = ({ creator, onClose, onSaveCard, personId }) => {
  const [error, setError] = useState<StripeError | string | null>(null);
  const [isDisabled, setDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const elements = useElements();
  const stripe = useStripe();
  const profile = useUserProfile();
  const { getData: attachNewCardFn } = useNoodleApi(tsClient.attachNewCard);

  const handleCardEvent = async (event: Event): Promise<void> => {
    if (event.error) {
      setError(event.error.message);
      setDisabled(true);
    } else {
      setError('');
      setDisabled(event.empty);
    }
  };

  useEffect(() => {
    mixpanelTrack('Edit payment method');
  }, []);

  const handleSaveCard = async (): Promise<void> => {
    try {
      setIsLoading(true);
      if (!stripe || !elements) {
        throw new Error(`Stripe not initialized: stripe=${Boolean(stripe)} elements=${Boolean(elements)}`);
      }

      const card = elements?.getElement(CardNumberElement);

      if (!card) {
        throw new Error('Failed to get card from stripe');
      }
      const data = await stripe.createPaymentMethod({
        billing_details: {
          name: profile?.name || 'User',
        },
        card,
        type: 'card',
      });

      const { error: stripeError, paymentMethod } = data;

      if (stripeError) {
        mixpanelTrack('Error in attach card form', { errorReason: stripeError.message });
        setError(stripeError.message as string);
        return;
      }

      if (paymentMethod?.id) {
        await attachNewCardFn({
          creatorId: creator.id,
          isDefaultPaymentMethod: true,
          paymentMethodId: paymentMethod?.id,
          personId,
        });
        await onSaveCard?.();
        onClose();
      }

      setIsLoading(false);
    } catch (e) {
      setError((e as Error).message);
    }
  };

  return (
    <Modal

      title={'Edit payment method'}
      onClose={onClose}

    >
      <div className={s.container}>
        <p className='caption'>Card number</p>
        <Spacer size='8px' />
        <div className={s.input}>
          <CardNumberElement options={CARD_ELEMENT_OPTIONS} onChange={handleCardEvent} />
        </div>
        <Spacer />
        <div className={s.double}>
          <div style={{ width: '100%' }}>
            <p className='caption'>Expiration date</p>
            <Spacer size='8px' />
            <div className={s.input}>
              <CardExpiryElement options={CARD_ELEMENT_OPTIONS} onChange={handleCardEvent} />
            </div>
          </div>
          <div style={{ width: '100%' }}>
            <p className='caption'>CVC</p>
            <Spacer size='8px' />
            <div className={s.input}>
              <CardCvcElement options={CARD_ELEMENT_OPTIONS} onChange={handleCardEvent} />
            </div>
          </div>
        </div>
        {error && <p className={s.error}>{error}</p>}
        <Spacer size='24px' />
        <Buttons
          isSecondary
          isFullWidth
          disabled={isDisabled || Boolean(error)}
          onClick={handleSaveCard}
          isFetching={isLoading}
        >
          Save
        </Buttons>
      </div>
    </Modal>
  );
};

export default PaymentMethodEditModal;
