import TimezonePicker, { DEFAULT_TIMEZONE } from '@components/TimezonePicker';
import useNoodleApi from '@hooks/useNoodleApi';
import Page404 from '@lib/Page404';
import useUserProfile from '@providers/Auth/useUserProfile';
import { useCreatorUI } from '@providers/CreatorUI';
import { logError } from '@providers/ErrorTracking';
import * as tsClient from '@tsClient';
import { useToast } from '@hooks';
import BackButton from '@components/BackButton';
import Button from '@components/DesignLibrary/Button';
import { useContext, useEffect, useState } from 'react';
import ProductIcon from '@components/ProductIcon';
import getProductsNoodleProductType from '@helpers/getProductsNoodleProductType';
import { NoodleProductTypes } from '@typings/graphql-models';
import { mixpanelTrack } from '@providers/Mixpanel';
import hasNoodleProductType from '@helpers/hasNoodleProductType';
import useOnboardingContext from '@layouts/OnboardingLayout/useOnboardingContext';
import dashboardContext from '@layouts/DashboardLayout/DashboardContext';
import { getCreatorTeam } from '@/tsClient/creators';
import { useFeatureFlag } from '@/providers/FeatureFlags';
import Modal from '@/components/Modal';
import Spacer from '@/components/Spacer';
import ProgressIndicator from '@/components/ProgressIndicator';

import { Availability, Block, Day } from './types';
import DayOfWeek from './components/DayOfWeek';
import ConnectCalendars from './components/ConnectCalendars';
import MainWrapper from './components/MainWrapper';
import { formatAvailability, formatBody } from './utils';
import s from './CreatorProductAvailability.module.scss';
import TeamMembers from './components/TeamMembers';

type Props = {
  creatorSlug: string;
  productSlug: string;
  creatorId: string;
  isInline?: boolean;
  onSave?: () => void;
  className?: string;
};

const CreatorProductAvailability: React.FC<Props> = ({ creatorId, creatorSlug, productSlug, isInline, onSave, className }) => {
  const creatorUI = useCreatorUI();
  const profile = useUserProfile();
  const [timezone, setTimezone] = useState<string>(DEFAULT_TIMEZONE);
  const [isSaving, setIsSaving] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [hasManuallySaved, setHasManuallySaved] = useState(false);
  const [showAvailabilityEditor, setShowAvailabilityEditor] = useState(false);
  const [selectedTeamMemberPersonId, setSelectedTeamMemberPersonId] = useState<string>('');
  const teamMemberAvailabilityEnabled = useFeatureFlag('team-member-availability');

  const { isInDashboard } = useContext(dashboardContext);

  const { setNextAction } = useOnboardingContext();

  const [days, setDays] = useState<Day[]>([
    { blocks: [] as Block[], name: 'monday' },
    { blocks: [] as Block[], name: 'tuesday' },
    { blocks: [] as Block[], name: 'wednesday' },
    { blocks: [] as Block[], name: 'thursday' },
    { blocks: [] as Block[], name: 'friday' },
    { blocks: [] as Block[], name: 'saturday' },
    { blocks: [] as Block[], name: 'sunday' },
  ]);

  const { data: product, fetchingState: getProductBySlugState, getData: getProductBySlug } = useNoodleApi(tsClient.getProductBySlug);
  const { data: productAvailability, getData: getProductAvailability } = useNoodleApi(tsClient.getProductAvailability);
  const { getData: createProductAvailability } = useNoodleApi(tsClient.createProductAvailability, {
    toastErrorMessage: error => [
      useToast.ToastTypeVariants.ERROR,
      error?.errors?.[0] || 'There was an error saving your availability. Please try again.',
    ],
    toastOnError: true,
    toastSuccessMessage: () => [useToast.ToastTypeVariants.SUCCESS, 'Your availability has been saved!'],
  });
  const { getData: getTeamMembersFn, data: teamMembers } = useNoodleApi(getCreatorTeam);

  useEffect(() => {
    getProductBySlug({ creatorSlug, productSlug });
  }, [creatorSlug, productSlug, getProductBySlug]);

  useEffect(() => {
    if (product) {
      getProductAvailability({ productId: product.id, teamMemberPersonId: selectedTeamMemberPersonId });
    }
  }, [product, selectedTeamMemberPersonId, getProductAvailability]);

  useEffect(() => {
    getTeamMembersFn({ creatorId });
  }, [creatorId, getTeamMembersFn]);

  useEffect(() => {
    if (productAvailability && Object.keys(productAvailability.availability).length > 0) {
      const availability = formatAvailability(productAvailability.availability);
      setShowAvailabilityEditor(true);
      setDays(availability);
    }
    if (productAvailability && Object.keys(productAvailability.availability).length === 0) {
      const defaultAvailability: Availability = {
        1: [{ end: { hours: 17, minutes: 0 }, start: { hours: 9, minutes: 0 } }],
        2: [{ end: { hours: 17, minutes: 0 }, start: { hours: 9, minutes: 0 } }],
        3: [{ end: { hours: 17, minutes: 0 }, start: { hours: 9, minutes: 0 } }],
        4: [{ end: { hours: 17, minutes: 0 }, start: { hours: 9, minutes: 0 } }],
        5: [{ end: { hours: 17, minutes: 0 }, start: { hours: 9, minutes: 0 } }],
      };
      const formattedDefaultAvailability = formatAvailability(defaultAvailability);
      setShowAvailabilityEditor(false);
      setDays(formattedDefaultAvailability);
    }
    setIsDisabled(true);
  }, [productAvailability]);

  useEffect(() => {
    const creatorTimezone = product?.creator?.timezone;
    if (creatorTimezone) {
      setTimezone(creatorTimezone);
    }
  }, [product]);

  const setDay: (day: string, blocks: Block[]) => void = (day, blocks) => {
    const newDays = [...days];
    const index = newDays.findIndex(d => d.name === day);
    newDays[index] = { ...newDays[index], blocks };
    setDays(newDays);
    setIsDisabled(false);
  };

  const handleSave = async ({ newTimezone }: { newTimezone?: string }): Promise<void> => {
    if (!product) {
      logError(new Error('Trying to save product availability without a product'));
    } else {
      setIsSaving(true);
      let body;
      if (!timezone) {
        body = formatBody(days, product.id, selectedTeamMemberPersonId);
      } else {
        body = formatBody(days, product.id, selectedTeamMemberPersonId, newTimezone || timezone);
      }
      const response = await createProductAvailability(body);
      setIsSaving(false);
      if (response.error) {
        setIsDisabled(false);
      } else {
        mixpanelTrack('Updated Availability', {
          creator: product.creator?.slug,
          product: product.slug,
        });
        setIsDisabled(true);
        setHasManuallySaved(true);
      }
    }
    if (onSave) {
      onSave();
    }
  };

  // If we are in the onboarding context, setNextAction to call handleSave()
  useEffect(() => {
    if (setNextAction) {
      setNextAction(() => async () => {
        if (!hasManuallySaved) {
          await handleSave({});
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [days, timezone, hasManuallySaved]);

  const handleChangeTimezone: Parameters<typeof TimezonePicker>[0]['onChange'] = async newTimezone => {
    setTimezone(newTimezone);
    await handleSave({ newTimezone });
    setIsDisabled(false);
  };

  const waitForCreatorUI = !isInDashboard;

  if (getProductBySlugState.isFetching || (waitForCreatorUI && !creatorUI) || !profile) {
    return (
      <>
        <Spacer />
        <ProgressIndicator isCentered />
        <Spacer />
      </>
    );
  }

  if (!product) {
    return <Page404 />;
  }

  const noodleProductType = getProductsNoodleProductType(product);

  const hasAvailabilitySet = Boolean(productAvailability && Object.keys(productAvailability.availability).length > 0);

  return (
    <MainWrapper className={className}>
      <div className={s['creator-product-availability__header']}>
        <div style={{ padding: 16 }}>
          {isInline
            ? (
              <h2 className="body-md-bold">
                Set up your{' '}
                {(hasNoodleProductType(product, NoodleProductTypes.InPersonSession) && 'In Person Services')
                || (hasNoodleProductType(product, NoodleProductTypes.OnlineSession) && 'Online Sessions')}{' '}
                availability
              </h2>
            )
            : (
              <>
                <BackButton />
                <div>
                  <ProductIcon noodleProductType={noodleProductType} size={36} />
                  <div>
                    <p>{product.title}</p>
                    <h1>Your weekly availability</h1>
                  </div>
                </div>
              </>
            )}
        </div>
        {teamMembers && teamMemberAvailabilityEnabled && (
          <TeamMembers
            teamMembers={teamMembers}
            setSelectedMember={setSelectedTeamMemberPersonId}
            product={product}
            creatorId={creatorId}
          />
        )}
        {selectedTeamMemberPersonId && (
          <Modal
            title={teamMembers?.items.find(tm => tm.id === selectedTeamMemberPersonId)?.name || ''}

            onClose={() => setSelectedTeamMemberPersonId('')}
          >
            <Spacer />
            <ConnectCalendars creatorId={creatorId} teamMemberPersonId={selectedTeamMemberPersonId} />
            {showAvailabilityEditor && (
              <>
                <div className={s['creator-product-availability__days']}>
                  <div className={s['creator-product-availability__timezone']}>
                    <TimezonePicker id={'timezone'} value={timezone} onChange={handleChangeTimezone} />
                  </div>
                  <DayOfWeek name="Monday" blocks={days[0].blocks} setBlocks={(blocks: Block[]) => setDay('monday', blocks)} days={days} />
                  <DayOfWeek name="Tuesday" blocks={days[1].blocks} setBlocks={(blocks: Block[]) => setDay('tuesday', blocks)} days={days} />
                  <DayOfWeek name="Wednesday" blocks={days[2].blocks} setBlocks={(blocks: Block[]) => setDay('wednesday', blocks)} days={days} />
                  <DayOfWeek name="Thursday" blocks={days[3].blocks} setBlocks={(blocks: Block[]) => setDay('thursday', blocks)} days={days} />
                  <DayOfWeek name="Friday" blocks={days[4].blocks} setBlocks={(blocks: Block[]) => setDay('friday', blocks)} days={days} />
                  <DayOfWeek name="Saturday" blocks={days[5].blocks} setBlocks={(blocks: Block[]) => setDay('saturday', blocks)} days={days} />
                  <DayOfWeek name="Sunday" blocks={days[6].blocks} setBlocks={(blocks: Block[]) => setDay('sunday', blocks)} days={days} />
                </div>
                <div className={s['creator-product-availability__footer']}>
                  <Button
                    variant="primary"
                    size='md'
                    loading={isSaving}
                    fullWidth
                    disabled={isDisabled && hasAvailabilitySet}
                    onClick={() => handleSave({})}
                  >
                    Save
                  </Button>
                </div>
              </>
            )}
            {!showAvailabilityEditor && (
              <div className={s['creator-product-availability__footer']}>
                <Button
                  variant="primary"
                  size='md'
                  fullWidth
                  onClick={() => setShowAvailabilityEditor(true)}>
                  Add availability
                </Button>
              </div>
            )}
          </Modal>
        )}
      </div>
    </MainWrapper>
  );
};

export default CreatorProductAvailability;
