import { useContext, useEffect, useState, useMemo, useRef } from 'react';
import Button from '@components/DesignLibrary/Button';
import { IBM_Plex_Mono as ibmPlexMono } from 'next/font/google';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import ProgressIndicator from '@/components/ProgressIndicator';
import Spacer from '@/components/Spacer';
import CollapsibleArea from '@/components/CollapsibleArea';
import { useIsMobile } from '@/hooks';
import { SIZES } from '@/styles/media';
import { useUser } from '@/providers/Auth';
import { DocumentAccessContext } from '@/providers/DocumentAccess';
import preloadDocument from '@/helpers/preloadDocument';
import { logError } from '@providers/ErrorTracking';
import * as ApiModels from '@typings/api-models';
import { FixedSizeList as List } from 'react-window';
import DocumentPreview from '@/components/Document/DocumentPreview/Content';
import MimeTypes from '@/typings/MimeTypes';

import s from './CreditReportDetails.module.scss';

type Props = {
  creditReportResponse?: ApiModels.CreditReportResponse;
};

const mono = ibmPlexMono({
  subsets: ['latin'],
  weight: '400',
});

const CreditReportResponse: React.FC<Props> = ({ creditReportResponse }) => {
  const [user] = useUser();
  const { accessToken } = useContext(DocumentAccessContext);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const [fetchingPdf, setFetchingPdf] = useState(false);
  const contentRef = useRef<HTMLDivElement | null>(null);
  const [contentHeight, setContentHeight] = useState<number>(0); // Type the state as number

  const shouldSwitchToVertical = useIsMobile(SIZES.md);

  useEffect(() => {
    const loadPdf = async (): Promise<void> => {
      if (creditReportResponse?.pdfRequestId && (accessToken || user?.id)) {
        setFetchingPdf(true);
        preloadDocument({
          accessToken,
          id: creditReportResponse.pdfRequestId,
        })
          .then(blob => {
            const typeBlob = new Blob([blob], { type: 'application/pdf' });
            const blobPreviewUrl = window.URL.createObjectURL(typeBlob);

            setPreviewUrl(blobPreviewUrl);
          })
          .catch(error => {
            logError(error);
          })
          .finally(() => {
            setFetchingPdf(false);
          });
      } else {
        setPreviewUrl(null);
      }
    };

    loadPdf();
  }, [creditReportResponse?.pdfRequestId, accessToken, user?.id]);

  useEffect(() => {
    if (contentRef.current) {
      setContentHeight(contentRef.current.clientHeight);
    }

    const handleResize = (): void => {
      if (contentRef.current) {
        setContentHeight(contentRef.current.clientHeight);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const downloadPdf = (): void => {
    if (previewUrl) {
      const link = document.createElement('a');

      link.href = previewUrl;
      link.download = 'Credit Report.pdf';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const JSONComponent = shouldSwitchToVertical ? CollapsibleArea : 'div';

  const jsonData = creditReportResponse?.response ? JSON.stringify(creditReportResponse?.response, null, 2) : '';

  const jsonLines = useMemo(() => jsonData.split('\n'), [jsonData]);

  const Row = ({ index, style }: { index: number; style: React.CSSProperties }): JSX.Element => (
    <div style={style}>
      <SyntaxHighlighter
        language="json"
        customStyle={{
          backgroundColor: 'var(--color-gray-0)',
          fontSize: 14,
          lineHeight: 0,
          margin: 0,
          overflow: 'hidden',
          padding: '8px 16px',
        }}
        codeTagProps={{ style: mono.style }}
      >
        {jsonLines[index]}
      </SyntaxHighlighter>
    </div>
  );

  return (
    <div className={shouldSwitchToVertical ? s.contentWrapperMobile : s.contentWrapper}>
      <div className={s.content} ref={contentRef}>
        {creditReportResponse?.response
          ? (
            <JSONComponent
              {...shouldSwitchToVertical
                ? {
                  title: 'Credit Report JSON',
                }
                : {
                  style: {
                    display: 'contents',
                  },
                  title: '',
                }}
            >
              <List
                height={contentHeight}
                itemCount={jsonLines.length}
                itemSize={20}
                width="100%"
              >
                {Row}
              </List>
            </JSONComponent>
          )
          : (
            <>
              <Spacer />
              <ProgressIndicator isCentered />
              <Spacer />
            </>
          )}
      </div>
      <div className={s.content}>
        {!fetchingPdf && previewUrl && (
          <div className={s.padding}>
            <DocumentPreview url={previewUrl} mimeType={MimeTypes.PDF} />
          </div>
        )}
        {!fetchingPdf && !previewUrl && (
          <div className={s.padding}>
            <p className='body-sm'>No PDF available</p>
          </div>
        )}
        {fetchingPdf && (
          <>
            <Spacer />
            <ProgressIndicator isCentered />
          </>
        )}
        {!fetchingPdf && previewUrl && (<Button variant="secondary" size="sm" onClick={downloadPdf}>
          Download
        </Button>)}
      </div>
    </div>
  );
};

export default CreditReportResponse;
