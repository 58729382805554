const SvgMaster: React.FC = () => (
  <svg
    width="32"
    height="20"
    viewBox="0 0 32 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ verticalAlign: 'middle' }}
  >
    <path
      d="M18.6775 5.28314H13.3037V14.7175H18.6775V5.28314Z"
      fill="#FF5F00"
    />
    <path
      d="M13.6496 9.99999C13.6488 9.09139 13.8595 8.19451 14.2659 7.37725C14.6723 6.55999 15.2637 5.84376 15.9953 5.2828C15.0893 4.58707 14.0012 4.15441 12.8554 4.03427C11.7097 3.91412 10.5524 4.11134 9.51591 4.60338C8.47943 5.09542 7.60556 5.86244 6.99418 6.81675C6.38281 7.77106 6.05859 8.87417 6.05859 9.99999C6.05859 11.1258 6.38281 12.2289 6.99418 13.1832C7.60556 14.1375 8.47943 14.9046 9.51591 15.3966C10.5524 15.8886 11.7097 16.0859 12.8554 15.9657C14.0012 15.8456 15.0893 15.4129 15.9953 14.7172C15.2637 14.1562 14.6723 13.44 14.2659 12.6227C13.8595 11.8055 13.6488 10.9086 13.6496 9.99999V9.99999Z"
      fill="#EB001B"
    />
    <path
      d="M25.9276 9.99999C25.9276 11.1258 25.6035 12.2289 24.9921 13.1832C24.3808 14.1375 23.5069 14.9045 22.4705 15.3966C21.434 15.8886 20.2768 16.0859 19.131 15.9657C17.9852 15.8456 16.8972 15.4129 15.9912 14.7172C16.7222 14.1556 17.3131 13.4393 17.7194 12.6222C18.1257 11.805 18.3369 10.9084 18.3369 9.99999C18.3369 9.09153 18.1257 8.19494 17.7194 7.3778C17.3131 6.56067 16.7222 5.84433 15.9912 5.2828C16.8972 4.58707 17.9852 4.15441 19.131 4.03427C20.2768 3.91412 21.434 4.11135 22.4705 4.60339C23.5069 5.09544 24.3808 5.86245 24.9921 6.81677C25.6035 7.77108 25.9276 8.87418 25.9276 9.99999V9.99999Z"
      fill="#F79E1B"
    />
    <path
      d="M25.3499 13.7191V13.5259H25.4296V13.4866H25.2266V13.5259H25.3063V13.7191H25.3499ZM25.744 13.7191V13.4862H25.6818L25.6102 13.6464L25.5386 13.4862H25.4764V13.7191H25.5203V13.5434L25.5874 13.6949H25.633L25.7001 13.543V13.7191H25.744Z"
      fill="#F79E1B"
    />
    <rect
      x="0.5"
      y="0.5"
      width="31"
      height="19"
      rx="3.5"
      stroke="black"
      strokeOpacity="0.15"
    />
  </svg>
);

export default SvgMaster;
