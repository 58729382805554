const SvgVisa: React.FC = () => (
  <svg
    width="32"
    height="20"
    viewBox="0 0 32 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ verticalAlign: 'middle' }}
  >
    <path
      d="M26.222 13.7671L25.997 12.6421H23.483L23.083 13.7591L21.068 13.7631C22.0273 11.4563 22.9886 9.15028 23.952 6.84514C24.116 6.45414 24.407 6.25514 24.836 6.25714C25.164 6.26014 25.699 6.26014 26.442 6.25814L28 13.7641L26.222 13.7671ZM24.049 11.1011H25.669L25.064 8.28114L24.049 11.1011ZM11.06 6.25614L13.086 6.25814L9.954 13.7681L7.903 13.7661C7.38725 11.7825 6.87791 9.79712 6.375 7.81014C6.275 7.41414 6.077 7.13714 5.696 7.00614C5.13322 6.81756 4.56784 6.63688 4 6.46414L4 6.25914H7.237C7.797 6.25914 8.124 6.53014 8.229 7.08614C8.335 7.64314 8.601 9.06114 9.029 11.3401L11.06 6.25614ZM15.87 6.25814L14.268 13.7661L12.34 13.7641L13.94 6.25614L15.87 6.25814ZM19.78 6.11914C20.357 6.11914 21.084 6.29914 21.502 6.46414L21.164 8.02114C20.786 7.86914 20.164 7.66414 19.641 7.67114C18.881 7.68414 18.411 8.00314 18.411 8.30914C18.411 8.80714 19.227 9.05814 20.067 9.60214C21.026 10.2221 21.152 10.7791 21.14 11.3841C21.127 12.6401 20.067 13.8791 17.831 13.8791C16.811 13.8641 16.443 13.7781 15.611 13.4831L15.963 11.8581C16.81 12.2131 17.169 12.3261 17.893 12.3261C18.556 12.3261 19.125 12.0581 19.13 11.5911C19.134 11.2591 18.93 11.0941 18.186 10.6841C17.442 10.2731 16.398 9.70414 16.412 8.56214C16.429 7.10014 17.814 6.11914 19.781 6.11914H19.78Z"
      fill="#153BC3"
    />
    <rect
      x="0.5"
      y="0.5"
      width="31"
      height="19"
      rx="3.5"
      stroke="black"
      strokeOpacity="0.15"
    />
  </svg>
);

export default SvgVisa;
