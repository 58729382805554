import React, {
  useRef, useEffect, useState, useCallback,
} from 'react';
import ResizeObserver from 'resize-observer-polyfill';

import { isBrowser } from 'helpers/helper';
import { KEY_CODES } from 'helpers/appConsts';

import s from './VirtualScroll.module.scss';

const VirtualScroll = ({
  items,
  handleClick,
  handleClose,
  CMP,
  itemHeight = 40,
  ...restProps
}) => {
  const wrapperEl = useRef();
  const scrollEl = useRef();

  const [isMounted, setIsMounted] = useState(false);
  const [itemsToDisplay, setItemsToDisplay] = useState([]);
  const [activeIndex, setActiveIndex] = useState(-1);
  const [scrolledItems, setScrolledItems] = useState(0);

  const getItemsToDisplay = useCallback((allItems) => {
    if (isBrowser()) {
      const { clientHeight } = wrapperEl.current;
      const { scrollTop } = wrapperEl.current;
      if (!clientHeight) {
        setTimeout(() => {
          getItemsToDisplay(allItems);
        }, 50);
      } else {
        const fullSizeItems = allItems.filter(
          ({ isDivider }) => !isDivider,
        ).length;
        scrollEl.current.style.minHeight = `${
          fullSizeItems * itemHeight + (allItems.length - fullSizeItems) * 9
        }px`;
        const visibleItems = Math.ceil(clientHeight / itemHeight) + 4;
        setScrolledItems(Math.floor(scrollTop / itemHeight));
        scrollEl.current.style.paddingTop = `${scrolledItems * itemHeight}px`;
        return setItemsToDisplay(
          [...allItems].splice(scrolledItems, visibleItems),
        );
      }
    } else {
      setItemsToDisplay(allItems.splice(0, 20));
    }
    return allItems;
  }, [itemHeight, scrolledItems]);

  const handleKeyActions = useCallback((keyAction) => {
    if (!isMounted) {
      return;
    }

    const { keyCode } = keyAction;
    switch (keyCode) {
    case KEY_CODES.ENTER:
      if (items[activeIndex] && items[activeIndex].onClick) {
        handleClick?.(items[activeIndex].onClick)?.({});
      } else if (activeIndex > -1 && items[activeIndex].href) {
        handleClose();
        // navigate(items[activeIndex].href);
      }
      break;
    case KEY_CODES.ESC:
      handleClose?.();
      break;
    case KEY_CODES.ARROW_DOWN:
      setActiveIndex(
        activeIndex + 1 < items.length ? activeIndex + 1 : activeIndex,
      );
      break;
    case KEY_CODES.ARROW_UP:
      setActiveIndex(activeIndex > 0 ? activeIndex - 1 : activeIndex);
      break;
    default:
      break;
    }
  }, [activeIndex, handleClick, handleClose, isMounted, items]);

  useEffect(() => {
    let resizeObserver;
    // wrapperEl.current can change without triggering a rerender
    // So the wrapperEl.current when adding the observe isn't necessarily the same thing
    // we have in the Destructor. So holding on to the element here so that we can correctly
    // unobserve later.
    const element = wrapperEl?.current;
    if (ResizeObserver && element) {
      resizeObserver = new ResizeObserver((entries) => {
        // eslint-disable-next-line no-restricted-syntax
        for (const el of entries) {
          if (el.contentRect.height) getItemsToDisplay(items, wrapperEl);
        }
      });
      resizeObserver.observe(element);
    }

    return () => {
      if (resizeObserver && element) {
        resizeObserver.unobserve(element);
      }
    };
  }, [getItemsToDisplay, items]);

  useEffect(() => {
    let handleKeyDown;

    if (isBrowser()) {
      handleKeyDown = (e) => {
        if (
          e.keyCode === KEY_CODES.ARROW_DOWN
          || e.keyCode === KEY_CODES.ARROW_UP
        ) {
          e.preventDefault();
          e.stopPropagation();
        }
        handleKeyActions(e);
      };

      document.addEventListener('keydown', handleKeyDown);
    }
    setIsMounted(true);

    return () => {
      if (handleKeyDown) {
        document.removeEventListener('keydown', handleKeyDown);
      }
    };
  }, [handleKeyActions]);

  const scrollToActiveIndex = useCallback((currentActiveIndex) => {
    if (wrapperEl && wrapperEl.getBoundingClientRect) {
      const rect = wrapperEl.getBoundingClientRect();
      const { height: menuHeight } = rect;
      const elementScrollTop = wrapperEl.scrollTop;
      const activeElementTop = 8 + (currentActiveIndex + 2) * itemHeight;
      if (activeElementTop - itemHeight * 3 < elementScrollTop) {
        wrapperEl.scrollTop = activeElementTop - itemHeight * 3;
      } else if (activeElementTop > elementScrollTop + menuHeight) {
        wrapperEl.scrollTop = activeElementTop - menuHeight;
      }
    }
  }, [itemHeight]);

  const handleMouseEnter = (index) => {
    setActiveIndex(index);
  };

  useEffect(() => {
    getItemsToDisplay(items, wrapperEl);
  }, [items, wrapperEl, getItemsToDisplay]);

  const handleScroll = () => {
    getItemsToDisplay(items, wrapperEl);
  };

  useEffect(() => {
    scrollToActiveIndex(activeIndex);
  }, [activeIndex, scrollToActiveIndex]);

  return (
    <div
      onScroll={handleScroll}
      ref={wrapperEl}
      className={s['v-scroll__wrapper']}
    >
      <div ref={scrollEl}>
        {(itemsToDisplay || []).map((item, index) => {
          if (CMP) {
            return (
              <CMP
                key={item.key || item.id || item.title}
                handleClick={handleClick}
                handleClose={handleClose}
                activeIndex={activeIndex}
                index={scrolledItems + index}
                onMouseEnter={handleMouseEnter.bind(
                  null,
                  scrolledItems + index,
                )}
                {...restProps}
                item={item}
              />
            );
          }

          return (
            <div
              key={JSON.stringify(item)}
              onMouseEnter={handleMouseEnter.bind(null, scrolledItems + index)}
              className={s['v-scroll__item']}
            >
              {item.index}
              {item.Name}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default VirtualScroll;
