import { FC, useEffect, useState } from 'react';
import Modal from '@components/Modal';
import commonStyles from '@modals/ModalsCommon.module.scss';
import ProgressIndicator from '@components/ProgressIndicator';

type Props = {
  confidoPaymentLink: string;
  onClose: () => void;
};

const ConfidoPaymentModal: FC<Props> = ({ confidoPaymentLink, onClose }) => {
  const [isFetching, setIsFetching] = useState(true);

  useEffect(() => {
    const divElement = document.getElementById('confidoPayment') as HTMLDivElement;
    divElement.innerHTML = '';
    const iframeElement = document.createElement('iframe');
    iframeElement.src = confidoPaymentLink;
    iframeElement.width = '100%';
    iframeElement.height = '800px';
    divElement.appendChild(iframeElement);
    setIsFetching(false);
  }, [confidoPaymentLink, setIsFetching]);

  return (
    <Modal title='Make payment' onClose={onClose} >
      <div className={commonStyles.container}>
        {isFetching && <ProgressIndicator isCentered isPage />}
        <div id='confidoPayment'/>
      </div>
    </Modal>
  );
};

export default ConfidoPaymentModal;
