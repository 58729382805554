import { ReactElement, useEffect, useState } from 'react';
import Modal from '@components/Modal';
import useNoodleApi from '@hooks/useNoodleApi';
import * as tsClient from '@tsClient';
import AssignCollaboratorsToAttachmentForm from '@components/AssignCollaboratorsToAttachmentForm';
import { getCreatorTeam } from '@tsClient/creators';
import ProgressIndicator from '@components/ProgressIndicator';
import Spacer from '@components/Spacer';

type Props = {
  userWorkflowId: string;
  onClose: () => void;
  onSubmit: () => Promise<void>;
  ownerIds: string[];
  creatorId: string;
};

const UserWorkflowAssigneeModal = ({
  onClose,
  onSubmit,
  userWorkflowId,
  ownerIds,
  creatorId,
}: Props): ReactElement => {
  const [isFetching, setIsFetching] = useState(false);

  const { getData: getTeamMembersFn, data: teamMembers } = useNoodleApi(getCreatorTeam);

  const {
    getData: updateOwnersFn,
  } = useNoodleApi(tsClient.workflows.updateUserWorkflowOwners, {
    toastOnError: true,
  });

  useEffect(() => {
    getTeamMembersFn({ creatorId, page: 1, perPage: 1000 });
  }, [getTeamMembersFn, creatorId]);

  const handleCollaboratorAssignment = async (userIds: string[]): Promise<void> => {
    setIsFetching(true);
    await updateOwnersFn({
      ownerIds: userIds,
      userWorkflowId,
    });
    await onSubmit();
    setIsFetching(false);
  };

  return (
    <Modal
      hideBottomInset

      title={'Workflow assignees'}
      onClose={onClose}

    >
      {teamMembers
        ? (
          <AssignCollaboratorsToAttachmentForm
            collaborators={teamMembers?.items?.map((m) => ({
              color: m.primaryColour?.hex || null,
              email: m.email || null,
              id: m.id,
              logo: m.image?.url || null,
              name: m.name || null,
            }))}
            onSubmit={handleCollaboratorAssignment}
            isSubmitting={isFetching}
            initialCollaboratorIds={ownerIds}
          />
        )
        : (
          <>
            <Spacer size={24} />
            <ProgressIndicator isCentered />
            <Spacer size={24} />
          </>
        )}
    </Modal>
  );
};

export default UserWorkflowAssigneeModal;
