import { m } from 'framer-motion';

import s from './ChatDropDown.module.scss';

const ChatDropDown: React.FC = ({ children }) => (
  <m.div initial={{ opacity: 0, y: 10 }} animate={{ opacity: 1, y: 0 }}>
    <div className={s['chat-drop-down']}>{children}</div>
  </m.div>
);

export default ChatDropDown;
