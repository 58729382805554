export const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      '::placeholder': {
        color: '#AAAAAA',
      },
      color: 'black',
      fontFamily: 'Inter, sans-serif',
      fontSize: '16px',
      fontWeight: 'normal',
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a',
    },
  },
};

const roundToTwoDecimals = (num: number): number => +(`${Math.round(Number(`${num}e+2`))}e-2`);
export const calculateTotalPriceInCents = (prices: number[]): number => roundToTwoDecimals((prices.reduce((acc, cur) => acc + cur, 0))
  * 100);
