import * as React from 'react';
import { m, AnimatePresence } from 'framer-motion';

import Buttons from 'components/Buttons';
import { mixpanelTrack } from '@providers/Mixpanel';
import OutsideClick from '@components/OutsideClick';
import CurrencyDollarSimple from '@components/Icons/CurrencyDollarSimple';
import XCircle from '@components/Icons/XCircle';
import getRecentlyUsedPrices from '@tsClient/creators/getRecentlyUsedPrices';
import Spacer from '@components/Spacer';
import ChatDropDown from '@components/Chat/ChatDropDown';
import PriceSelector from '@components/PriceSelector';
import s from './InputComposer.module.scss';

type Props = {
  onAttach: (data: { priceId: string; title: string; }) => void;
};
type Price = Awaited<ReturnType<typeof getRecentlyUsedPrices>>[0];

const AddPaymentLink = ({ onAttach }: Props): React.ReactElement => {
  const [popoverOpen, setPopoverOpen] = React.useState(false);

  const handleInsert = (price: Price): void => {
    mixpanelTrack('InputComposer - payment link added to input');
    onAttach({ priceId: price.id, title: price.priceTitle || price.product?.title || 'Payment Link' });
  };

  return (
    <>
      <Buttons
        ariaLabel={'Insert Payment Link'}
        iconBefore={
          <m.div
            style={{
              alignItems: 'center',
              display: 'flex',
              height: '16px',
              justifyContent: 'center',
              width: '16px',
              willChange: 'transform',
            }}
            whileTap={{ scale: 0.9 }}
          >
            {popoverOpen
              ? (
                <XCircle weight="fill" color="var(--color-gray-50)" size={16} />
              )
              : (
                <CurrencyDollarSimple size={16} weight="fill" color="var(--color-primary)" />
              )}
          </m.div>
        }
        onClick={() => {
          setPopoverOpen(!popoverOpen);
        }}
      >
        Payment Link
      </Buttons>
      <AnimatePresence>
        {popoverOpen && (
          <OutsideClick onOutsideClick={() => setPopoverOpen(false)} className={s.outsideClick}>
            <ChatDropDown>
              <div style={{ padding: 16 }}>
                <p className="body-sm">
                  <b>Payment Links</b>
                </p>
                <Spacer size={12} />
                <PriceSelector onPriceSelect={handleInsert} />
              </div>
            </ChatDropDown>
          </OutsideClick>
        )}
      </AnimatePresence>
    </>
  );
};

export default AddPaymentLink;
