import React, { useState, useEffect, useMemo } from 'react';

import PlusCircle from '@/components/Icons/PlusCircle';
import IdentificationBadge from '@/components/Icons/IdentificationBadge';
import MinusCircle from '@/components/Icons/MinusCircle';
import type { Questionnaire, FieldType, SelectOptionStyle } from '@/typings/Questionnaire';
import { Select, Input, Checkbox, TextArea, Checkboxes } from '@/components/DesignLibrary/Atoms';
import Button from '@/components/DesignLibrary/Button';
import { QuestionnaireAssigneeTemplate } from '@/typings/questionnaires-api-models';
import { useFeatureFlag } from '@/providers/FeatureFlags';
import TabText from '@/components/DesignLibrary/Atoms/TabText';
import Buttons from '@/components/Buttons';

import Conditions from '../Conditions';
import s from './FieldEditor.module.scss';
import ViewSelector from './ViewSelector';
import IsEditableInTableView from './IsEditableInTableView';
import IsViewableInTableView from './IsViewableInTableView';

const fieldTypes = [
  {key: "short-text", label: "Short Text" },
  {key: "ssn", label: "Social Security Number" },
  {key: "long-text", label: "Long Text" },
  {key: "numeric", label: "Numeric" },
  {key: "number", label: "Number" },
  {key: "percent", label: "Percent" },
  {key: "currency", label: "Currency" },
  {key: "multi-select", label: "Checkboxes" },
  {key: "single-select", label: "Radio buttons" },
  {key: "selection", label: "Dropdown" },
  {key: "name", label: "Name" },
  {key: "date", label: "Date" },
  {key: "address-us", label: "US Address" },
  {key: "address-international", label: "International Address" },
  {key: "phone-number", label: "Phone Number" },
  {key: "explanation", label: "Explanation" },
  {key: "list", label: "List" },
];

type FieldEditorProps = {
  field: Questionnaire.Field;
  usedKeys: string[];
  onUpdateField: (updatedField: Questionnaire.Field) => void;
  onRemoveField: (fieldId: string) => void;
  assigneeTemplates: QuestionnaireAssigneeTemplate[];
  onAddField: (listFieldTemplateId: string) => void;
  isInList: boolean;
};

type FieldSelect = Questionnaire.FieldMultiSelect | Questionnaire.FieldSingleSelect | Questionnaire.FieldSelection;

type Expression = Pick<Questionnaire.Expression, 'expression'> & {
  variables: Pick<Questionnaire.ExpressionVariable, 'variableName' | 'referenceId' | 'selector'>[];
};

const fieldKeyRegex = /^[a-zA-Z_][a-zA-Z0-9_]*$/;

const FieldEditor: React.FC<FieldEditorProps> = ({
  field,
  usedKeys,
  onUpdateField,
  onRemoveField,
  onAddField,
  assigneeTemplates,
  isInList,
}) => {
  const [label, setLabel] = useState<string>(field.label);
  const [key, setKey] = useState<string>(field.key);
  const [description, setDescription] = useState<string>(field.description);
  const [type, setType] = useState<string>(field.type);
  const [placeholder, setPlaceholder] = useState<string | undefined>(field.placeholder);
  const [hint, setHint] = useState<string | undefined>(field.hint);
  const [isRequired, setIsRequired] = useState<boolean>(field.isRequired);
  const [assignedTo, setAssignedTo] = useState<string[]>(field.assignedTo);
  const [view, setView] = useState<string | null>(field.view ?? field.type === 'list' ? 'table' : null);
  const [isEditableInTableView, setIsEditableInTableView] = useState(field.isEditableInTableView);
  const [isViewableInTableView, setIsViewableInTableView] = useState(field.isViewableInTableView);
  const [options, setOptions] = useState<{ key: string; label: string, isDefault: boolean }[]>(Array.isArray(field.options) ? field.options : []);
  const [selectedTab, setSelectedTab] = useState('settings');
  const [optionStyle, setOptionStyle] = useState<SelectOptionStyle>(
    'style' in field ? field.style : 'checkbox',
  );
  const [conditionExpression, setConditionExpression] = useState<Expression>(field.conditionExpression || {
    expression: '',
    variables: [],
  });
  const useEditableInTableView = useFeatureFlag('questionnaire-editable-table-view');

  const isUsedKey = useMemo(() => usedKeys.includes(key), [usedKeys, key]);
  const isValidKey = useMemo(() => fieldKeyRegex.test(key), [key]);

  useEffect(() => {
    setLabel(field.label);
    setDescription(field.description);
    setType(field.type);
    setIsRequired(field.isRequired);
    setAssignedTo(field.assignedTo);
    setOptions(Array.isArray(field.options) ? field.options : []);
    setHint(field.hint ?? '');
    setPlaceholder(field.placeholder ?? '');
    setKey(field.key);
    setView(field.view);
    setIsEditableInTableView(field.isEditableInTableView);
    setIsViewableInTableView(field.isViewableInTableView);
    if ('style' in field) setOptionStyle(field.style);
    setConditionExpression(field.conditionExpression || {
      expression: '',
      variables: [],
    });
  }, [field]);

  const needPlaceholder = useMemo(() =>
    [
      "number",
      "selection",
      "short-text",
      "long-text",
      "numeric",
      "percent",
      "currency",
      "date",
    ].includes(type),
  [type]);

  const labelError: string | undefined = useMemo(() => {
    if (!label) {
      return 'Required';
    }

    return undefined;
  }, [label]);

  const keyError: string | undefined = useMemo(() => {
    if (!key) {
      return 'Required';
    }

    if (isUsedKey) {
      return 'Key already used';
    }

    if (!isValidKey) {
      return 'Key can contain only a-z, A-Z, 0-9 and _. Key can\'t begin with numbers';
    }

    return undefined;
  }, [key, isValidKey, isUsedKey]);

  // Prevent list being nested
  const availableFieldTypes = useMemo(() => field.listFieldTemplateId ? fieldTypes.filter(({ key: fieldKey }) => fieldKey !== 'list') : fieldTypes, [field.listFieldTemplateId]);

  const handleSave = (): void => {
    if (!keyError && !labelError) {
      // @ts-expect-error numeric vs number
      const updatedField: Questionnaire.Field = {
        ...field,
        assignedTo,
        conditionExpression,
        description,
        hint,
        isEditableInTableView,
        isRequired: (type as FieldType) === 'explanation' ? false : isRequired,
        isViewableInTableView,
        key,
        label,
        placeholder,
        type: type as FieldType,
        view,
      };
      if (['multi-select', 'single-select', 'selection'].includes(type)) {
        (updatedField as FieldSelect).options = options.filter(option => option.key && option.label);
        if (type !== 'selection') (updatedField as Questionnaire.FieldMultiSelect | Questionnaire.FieldSingleSelect).style = optionStyle;
      }
      onUpdateField(updatedField);
    }
  };

  const handleAddOption = (): void => {
    setOptions([...options, { isDefault: false, key: '', label: ''}]);
  };

  const handleOptionChange = (index: number, fieldKey: string, value: string | boolean): void => {
    const updatedOptions = options.map((option, i) => {
      if (i === index) {
        return { ...option, [fieldKey]: value };
      } if (fieldKey === 'isDefault' && value === true) {
        return { ...option, isDefault: false };
      }
      return option;
    });
    setOptions(updatedOptions);
  };

  const handleRemoveOption = (index: number): void => {
    if (window.confirm('Are you sure you want to remove this option?')) {
      setOptions(options.filter((_, i) => i !== index));
    }
  };

  const handleAssignedToChange = (ids: string[]): void => {
    setAssignedTo(ids);
  };

  const tabs = useMemo(() => [
    { key: 'settings', label: 'Settings', selected: selectedTab === 'settings' },
    { key: 'conditions', label: 'Conditions', selected: selectedTab === 'conditions' },
    ...!field.listFieldTemplateId ? [{ key: 'assignees', label: 'Assignees', selected: selectedTab === 'assignees' }] : [],
  ], [selectedTab, field.listFieldTemplateId]);

  return (
    <div className={s.fieldEditor}>
      <div className={s.fieldHeader}>
        <IdentificationBadge />
        <span className={s.label}>{field.label}</span>
      </div>

      <div className={s.fieldContent}>
        <div className={s.fieldContentTabs}>{tabs.map(tab => <TabText {...tab} key={tab.key} onClick={() => setSelectedTab(tab.key)} />)}</div>
        {selectedTab === 'conditions' && <Conditions
          conditionExpression={conditionExpression}
          setConditionExpression={setConditionExpression}
        />}
        {selectedTab === 'settings' && <>
          <Select
            label=""
            description="Field type"
            options={availableFieldTypes}
            value={type}
            onChange={(v) => setType(v)}
          />
          {(type as FieldType) !== 'explanation' && <Checkbox
            label="Required"
            isChecked={isRequired}
            onChange={setIsRequired}
            size="sm"
          />}
          <ViewSelector
            fieldType={field.type}
            onChange={setView}
            value={view}
          />
          {useEditableInTableView && <IsEditableInTableView
            isEditableInTableView={isEditableInTableView}
            onChange={setIsEditableInTableView}
            isInList={isInList}
          />}
          <IsViewableInTableView
            isViewableInTableView={isViewableInTableView}
            onChange={setIsViewableInTableView}
            isInList={isInList}
          />
          <div className={s.separator} />
          <Input
            label=""
            description="Label"
            value={label}
            error={labelError}
            onChange={setLabel}
          />
          <TextArea
            label=""
            description="Description"
            value={description}
            onChange={setDescription}
          />
          {needPlaceholder && <Input
            optional
            label=""
            description="Placeholder"
            value={placeholder}
            onChange={setPlaceholder}
          />}
          {/* <Input
            optional
            label=""
            description="Hint message"
            value={hint}
            onChange={(e) => setHint(e.target.value)}
          /> */}
          <Input
            label=""
            description="Field name"
            value={key}
            error={keyError}
            onChange={setKey}
          />
          {['multi-select', 'single-select', 'selection'].includes(type) && (
            <div className={s.options}>
              <span className='caption'>Options</span>
              {options.map((option, index) => (
                <div key={index} className={s.optionContainer}>
                  <div className={s.option}>
                    <Input
                      label=""
                      description="Key"
                      value={option.key}
                      onChange={(value) => handleOptionChange(index, 'key', value)}
                    />
                    <Input
                      label=""
                      description="Label"
                      value={option.label}
                      onChange={(value) => handleOptionChange(index, 'label', value)}
                    />
                  </div>
                  <Checkbox
                    label="Set as default"
                    isChecked={option.isDefault}
                    onChange={(value) => handleOptionChange(index, 'isDefault', value)}
                    size="sm"
                  />
                  <Buttons isWrapper className={s.removeOptionButton} onClick={() => handleRemoveOption(index)}>
                    <MinusCircle />
                  </Buttons>
                </div>
              ))}
              <button
                className={s.addOptionButton}
                onClick={handleAddOption}
              >
                <PlusCircle weight="fill" /> Add Option
              </button>
            </div>
          )}
          {field.type === 'list' && field.key && (
            <button
              className={s.addOptionButton}
              onClick={() => onAddField(field.id)}
            >
              <PlusCircle weight="fill" /> Add Field
            </button>
          )}
          {/* {['multi-select', 'single-select'].includes(type) && (
            <div>
              <h3>Option Style</h3>
              <select value={optionStyle} onChange={(e) => setOptionStyle(e.target.value as SelectOptionStyle)}>
                <option value="checkbox">Checkbox</option>
                <option value="radio-button">Radio Button</option>
                <option value="button">Button</option>
              </select>
            </div>
          )} */}
        </>}
        {selectedTab === 'assignees' && <Checkboxes
          label=""
          description="Assignee"
          options={assigneeTemplates.map((assignee) => ({ key: assignee.id, label: assignee.label }))}
          value={assignedTo}
          onChange={handleAssignedToChange}
          compact
        />}
        {selectedTab === 'settings' && <>
          <Button size="sm" variant="primary" onClick={handleSave}>Save</Button>
          <Button size="sm" variant="destructive" onClick={() => {
            if (window.confirm('Are you sure you want to delete this field?')) {
              onRemoveField(field.id);
            }
          }}>Delete</Button>
        </>}
      </div>
    </div>
  );
};

export default FieldEditor;
