import { FC, useEffect, useRef, useState, useCallback } from 'react';
import SignaturePad from 'signature_pad';
import { AnimatePresence, m } from 'framer-motion';
import Button from '@/components/DesignLibrary/Button';
import classNames from 'classnames';
import s from './SignatureInput.module.scss';

const SignatureInput: FC<{
  label: string;
  required?: boolean;
  onChange: (e: string) => void;
  error?: string;
  value?: string;
  disabled?: boolean;
}> = ({ label, required, onChange, error, value, disabled }) => {
  const [signatureSvg, setSignatureSvg] = useState(value || '');
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const signaturePadRef = useRef<SignaturePad | null>(null);

  const initializeSignaturePad = useCallback(() => {
    const canvas = canvasRef.current;
    if (canvas) {
      try {
        // pixel ratio adjustments
        const ratio = Math.max(window.devicePixelRatio || 1, 1);
        const canvasWidth = canvas.offsetWidth;
        const canvasHeight = canvas.offsetHeight;
        canvas.width = canvasWidth * ratio;
        canvas.height = canvasHeight * ratio;
        canvas.getContext('2d')?.scale(ratio, ratio);

        // init
        signaturePadRef.current = new SignaturePad(canvas, {
          backgroundColor: 'transparent',
          maxWidth: 1 * ratio,
          minWidth: 0.5 * ratio,
          penColor: 'black',
        });

        // show existing signature
        if (signatureSvg) {
          signaturePadRef.current.fromDataURL(signatureSvg);
        }

        // listen
        if (disabled) {
          signaturePadRef.current.off();
        } else {
          signaturePadRef.current.addEventListener('endStroke', () => {
            setSignatureSvg(signaturePadRef.current?.toDataURL() || '');
          });
        }

      } catch (initError) {
        console.error('Error initializing signature pad:', initError);
      }
    }
  }, [disabled, signatureSvg]);

  const handleResize = useCallback(() => {
    if (signaturePadRef.current && canvasRef.current) {
      signaturePadRef.current.clear();
      initializeSignaturePad();
      setSignatureSvg('');
    }
  }, [initializeSignaturePad]);

  useEffect(() => {
    onChange(signatureSvg);
  }, [signatureSvg]);

  useEffect(() => {
    initializeSignaturePad();
    window.addEventListener('resize', handleResize);

    return () => {
      if (signaturePadRef.current) {
        signaturePadRef.current.off();
        signaturePadRef.current = null;
      }
      window.removeEventListener('resize', handleResize);
    };
  }, [initializeSignaturePad, handleResize]);

  return (
    <div className={s.wrapper}>
      <label>
        {label}
        <input type="hidden" name="signature" value={signatureSvg} required={required} disabled={disabled} />
      </label>
      <div className={classNames(s.signature, disabled && s.disabled)}>
        <canvas ref={canvasRef} />
        <AnimatePresence>
          {signatureSvg && !disabled && (
            <m.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 0.1 }} className={s.button}>
              <Button
                variant="secondary"
                size="xs"
                onClick={() => {
                  signaturePadRef.current?.clear();
                  setSignatureSvg('');
                }}
              >
                Start Over
              </Button>
            </m.div>
          )}
        </AnimatePresence>
      </div>
      {error && <p className={s.error}>{error}</p>}
    </div>
  );
};

export default SignatureInput;
