import React from 'react';

import TextEllipsis from 'components/TextEllipsis';

import s from './DropDown.module.scss';

const DropDownItem = ({
  id,
  index,
  value,
  activeIndex,
  handleClose,
  handleClick,
  isItemSelected,
  item: {
    href,
    label,
    title,
    style,
    target,
    onClick,
    disabled,
    iconAfter,
    isDivider,
    iconBefore,
    className = '',
    value: itemValue,
  },
}) => {
  if (isDivider) {
    return <div className={s['drop-down__divider']} />;
  }

  if (href) {
    return (
      <a
        href={href}
        style={style}
        target={target}
        onClick={handleClose}
        className={[
          s['drop-down__item'],
          ...(className ? [className] : []),
          ...(activeIndex === index ? [s['drop-down__item--active']] : []),
          ...(isItemSelected(itemValue, value)
            ? [s['drop-down__item--selected']]
            : []),
        ].join(' ')}
      >
        {!!iconBefore && iconBefore}
        <span className="drop-down__item-text">
          <TextEllipsis>{title || label}</TextEllipsis>
        </span>
        {!!iconAfter && iconAfter}
      </a>
    );
  }

  return (
    <button
      key={index}
      style={style}
      disabled={disabled}
      data-focus-parent={id}
      onClick={handleClick(onClick)}
      className={[
        s['drop-down__item'],
        ...(className ? [className] : []),
        ...(activeIndex === index ? [s['drop-down__item--active']] : []),
        ...(disabled ? [s['drop-down__item--disabled']] : []),
        ...(isItemSelected(itemValue, value)
          ? [s['drop-down__item--selected']]
          : []),
      ].join(' ')}
    >
      {!!iconBefore && (
        <span className={s['drop-down__icon-before']}>
          {
            iconBefore
          }
        </span>
      )}
      <span className={s['drop-down__item-text']}>
        <TextEllipsis>{title || label}</TextEllipsis>
      </span>
      {!!iconAfter && (
        <span className={s['drop-down__icon-after']}>
          { (
            iconAfter
          )}
        </span>
      )}
    </button>
  );
};

export default DropDownItem;
