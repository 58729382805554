import { type Icon } from '@phosphor-icons/react';
import FileDashed from '@components/Icons/FileDashed';
import CreditReport from '@components/Icons/CreditReport';
import CurrencyCircleDollar from '@components/Icons/CurrencyCircleDollar';
import FileText from '@components/Icons/FileText';
import HandCoins from '@components/Icons/HandCoins';
import Signature from '@components/Icons/Signature';
import ListBullets from '@components/Icons/ListBullets';
import PDFIcon from '@components/Icons/PDFIcon';
import Calendar from '@components/Icons/Calendar';
import { MESSAGE_ATTACHMENT_REFERENCE_TYPES } from '@typings/api-models';

function getAttachmentIcon(type: MESSAGE_ATTACHMENT_REFERENCE_TYPES): Icon | null {
  if (type === MESSAGE_ATTACHMENT_REFERENCE_TYPES.DOCUMENT_REQUEST_TEMPLATE) {
    return FileDashed;
  }

  if (type === MESSAGE_ATTACHMENT_REFERENCE_TYPES.CREDIT_REPORT) {
    return CreditReport;
  }

  if (type === MESSAGE_ATTACHMENT_REFERENCE_TYPES.PAYMENT_LINK) {
    return CurrencyCircleDollar;
  }

  if (type === MESSAGE_ATTACHMENT_REFERENCE_TYPES.FORM_REQUEST_TEMPLATE) {
    return FileText;
  }

  if (type === MESSAGE_ATTACHMENT_REFERENCE_TYPES.INVOICE_REQUEST || type === MESSAGE_ATTACHMENT_REFERENCE_TYPES.INVOICE) {
    return HandCoins;
  }

  if (type === MESSAGE_ATTACHMENT_REFERENCE_TYPES.CUSTOM_TERMS || type === MESSAGE_ATTACHMENT_REFERENCE_TYPES.CUSTOM_TERMS_TEMPLATE) {
    return Signature;
  }

  if (type === MESSAGE_ATTACHMENT_REFERENCE_TYPES.FORM_REQUEST) {
    return ListBullets;
  }

  if (type === MESSAGE_ATTACHMENT_REFERENCE_TYPES.DOCUMENT_REQUEST_USER) {
    return PDFIcon;
  }

  if (type === MESSAGE_ATTACHMENT_REFERENCE_TYPES.BOOKING_PRICE) {
    return Calendar;
  }

  if (type === MESSAGE_ATTACHMENT_REFERENCE_TYPES.BOOKING) {
    return Calendar;
  }

  return null;
}

export default getAttachmentIcon;
