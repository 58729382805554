import { ReactElement, useEffect, useState } from 'react';
import Buttons from '@components/Buttons';
import Button from '@components/DesignLibrary/Button';
import Spacer from '@components/Spacer';
import PlusCircle from '@components/Icons/PlusCircle';
import { MESSAGE_ATTACHMENT_REFERENCE_TYPES } from '@typings/api-models';
import useNoodleApi from '@hooks/useNoodleApi';
import * as tsClient from '@tsClient';
import ListCell from '@components/DesignLibrary/ListCell';
import s from './AssignCollaboratorsToAttachmentForm.module.scss';

export type Collaborator = {
    id: string;
    logo?: string | null;
    color?: string | null;
    name?: string | null
    email?: string | null;
  };

type Props = {
  attachmentType?: MESSAGE_ATTACHMENT_REFERENCE_TYPES;
  attachmentReferenceId?: string;
  collaborators: Collaborator[],
  isSubmitting: boolean,
  onSubmit: (userIds: string[]) => void;
  onInviteClick?: () => void;
  initialCollaboratorIds?: string[];
  hasPadding?: boolean;
};

const AssignCollaboratorsToAttachmentForm = ({
  onSubmit,
  onInviteClick,
  isSubmitting,
  collaborators,
  attachmentReferenceId,
  attachmentType,
  initialCollaboratorIds,
  hasPadding = true,
}: Props): ReactElement => {
  const [assignedCollaboratorIds, setAssignedCollaboratorIds] = useState<string[]>(initialCollaboratorIds || []);

  const { getData: getDocumentRequestUserPermissionsFn } = useNoodleApi(tsClient.documentRequests.getDocumentRequestUserPermissions);
  const { getData: getFormRequestPermissionsFn } = useNoodleApi(tsClient.formRequests.getFormRequestPermissions);
  const { getData: getInvoicePermissions } = useNoodleApi(tsClient.invoices.getInvoicePermissions);
  const { getData: getCustomTermsPermissions } = useNoodleApi(tsClient.customTerms.getCustomTermsPermissions);
  const { getData: getBookingPermissions } = useNoodleApi(tsClient.scheduling.getBookingPermissions);
  const { getData: getCreditReportPermissionsFn } = useNoodleApi(tsClient.creditReportsB2C.getCreditReportPermissions);

  const flipCollaboratorAssignment = (collaboratorId: string): void =>
    assignedCollaboratorIds.includes(collaboratorId)
      ? setAssignedCollaboratorIds(prev => prev.filter((id) => id !== collaboratorId))
      : setAssignedCollaboratorIds(prev => [...prev, collaboratorId]);

  useEffect(() => {
    const getAssignedCollaborators = async (): Promise<void> => {
      if (!attachmentType || !attachmentReferenceId) {
        return;
      }
      if (attachmentType === MESSAGE_ATTACHMENT_REFERENCE_TYPES.DOCUMENT_REQUEST_USER) {
        const { data: documentRequestPermissions } = await getDocumentRequestUserPermissionsFn({ id: attachmentReferenceId });
        if (documentRequestPermissions) {
          const assignedUserIds = documentRequestPermissions.map((permission) => permission.personId);
          setAssignedCollaboratorIds(assignedUserIds);
        }
      } else if (attachmentType === MESSAGE_ATTACHMENT_REFERENCE_TYPES.CREDIT_REPORT) {
        const { data: creditReportPermissions } = await getCreditReportPermissionsFn({ creditReportId: attachmentReferenceId });
        if (creditReportPermissions) {
          const assignedUserIds = creditReportPermissions.map((permission) => permission.personId);
          setAssignedCollaboratorIds(assignedUserIds);
        }
      } else if (attachmentType === MESSAGE_ATTACHMENT_REFERENCE_TYPES.FORM_REQUEST) {
        const { data: formRequestPermissions } = await getFormRequestPermissionsFn({ formRequestId: attachmentReferenceId });
        if (formRequestPermissions) {
          const assignedUserIds = formRequestPermissions.map((permission) => permission.personId);
          setAssignedCollaboratorIds(assignedUserIds);
        }
      } else if (attachmentType === MESSAGE_ATTACHMENT_REFERENCE_TYPES.INVOICE) {
        const { data: invoicePermissions } = await getInvoicePermissions({ invoiceId: attachmentReferenceId });
        if (invoicePermissions) {
          const assignedUserIds = invoicePermissions.map((permission) => permission.personId);
          setAssignedCollaboratorIds(assignedUserIds);
        }
      } else if (attachmentType === MESSAGE_ATTACHMENT_REFERENCE_TYPES.CUSTOM_TERMS) {
        const { data: termsPermissions } = await getCustomTermsPermissions({ id: attachmentReferenceId });
        if (termsPermissions) {
          const assignedUserIds = termsPermissions.map((p) => p.personId);
          setAssignedCollaboratorIds(assignedUserIds);
        }
      } else if (attachmentType === MESSAGE_ATTACHMENT_REFERENCE_TYPES.BOOKING) {
        const { data: bookingPermissions } = await getBookingPermissions({ id: attachmentReferenceId });
        if (bookingPermissions) {
          const assignedUserIds = bookingPermissions.map((p) => p.personId);
          setAssignedCollaboratorIds(assignedUserIds);
        }
      }
    };
    getAssignedCollaborators();
  }, [
    getInvoicePermissions,
    getDocumentRequestUserPermissionsFn,
    getFormRequestPermissionsFn,
    getCreditReportPermissionsFn,
    attachmentType,
    attachmentReferenceId,
    getCustomTermsPermissions,
    getBookingPermissions,
  ]);

  return (
    <div className={s.assignCollaborator} style={{ padding: hasPadding ? 24 : 0 }}>
      {collaborators.length > 0 && (
        <div className={s['collaborators-container']}>
          {collaborators.map(collaborator => (
            <ListCell
              key={collaborator.id}
              avatar={{
                color: collaborator.color || '',
                name: collaborator.name || '',
                url: collaborator.logo || '',
              }}
              label={collaborator.name || 'No name'}
              bottomLine={collaborator.email || ''}
              showCheckbox
              isSelected={assignedCollaboratorIds.includes(collaborator.id)}
              onClick={() => flipCollaboratorAssignment(collaborator.id)}
            />
          ))}
        </div>
      )}
      {onInviteClick && (
        <>
          <Spacer size={16}/>
          <Buttons isExtraSmall isWrapper className={s.invite} onClick={onInviteClick}>
            <>
              <PlusCircle size={8} weight="fill" />
              Invite Collaborator and Assign
            </>
          </Buttons>
        </>
      )}
      <Spacer size={24}/>
      <div className={s.buttons}>
        <Button variant='primary' size='md' onClick={() => onSubmit(assignedCollaboratorIds)} loading={isSubmitting}>
          Save
        </Button>
      </div>
    </div>
  );
};

export default AssignCollaboratorsToAttachmentForm;
