import React, { FC, Fragment, ReactElement, useMemo } from 'react';
import { Icon as PhosphorIcon } from '@phosphor-icons/react';
import HouseSimple from '@components/Icons/HouseSimple';
import ChatsCircle from '@components/Icons/ChatsCircle';
import Workflow from '@components/Icons/Workflow';
import Inbox from '@components/Icons/Inbox';
import UsersThree from '@components/Icons/UsersThree';
import UserCircle from '@components/Icons/UserCircle';
import CurrencyCircleDollar from '@components/Icons/CurrencyCircleDollar';
import Package from '@components/Icons/Package';
import Coins from '@components/Icons/Coins';
import Question from '@components/Icons/Question';
import Users from '@components/Icons/Users';
import Book from '@components/Icons/Book';
import Pulse from '@components/Icons/Pulse';
import CustomLink from '@components/CustomLink';
import Tooltip from '@components/Tooltip';
import BuildingBlock from '@components/Icons/BuildingBlock';
import Bookmarks from '@components/Icons/Bookmarks';
import Buildings from '@components/Icons/Buildings';
import Calendar from '@components/Icons/CalendarCheck';
import PlusCircle from '@components/Icons/PlusCircle';
import Cookie from '@components/Icons/Cookie';
import s from './TabIcon.module.scss';

export const ICONS = [
  'account',
  'activity',
  'add',
  'bookings',
  'bookmarks',
  'building-blocks',
  'chat',
  'community',
  'get-paid',
  'home',
  'inbox',
  'library',
  'members',
  'messaging',
  'organization',
  'question',
  'services',
  'content',
  'transactions',
  'users',
  'workflows',
] as const;

type Props = {
  icon: typeof ICONS[number];

  selected: boolean;
  showLabel?: boolean;
  label?: string;
  notificationCount?: number;
} & (
  | {
      href: string;
      onClick?: never;
    }
  | {
      href?: never;
      onClick: () => unknown;
    }
);

const TabIcon: FC<Props> = ({ icon, selected, showLabel, label, href, onClick, notificationCount = 0 }) => {
  const { Icon, defaultLabel } = useMemo<{ Icon: PhosphorIcon | null; defaultLabel: string }>(() => {
    switch (icon) {
    case 'add':
      return { Icon: PlusCircle, defaultLabel: 'Add' };
    case 'inbox':
      return { Icon: Inbox, defaultLabel: 'Inbox' };
    case 'home':
      return { Icon: HouseSimple, defaultLabel: 'Home' };
    case 'messaging':
      return { Icon: ChatsCircle, defaultLabel: 'Messaging' };
    case 'chat':
      return { Icon: ChatsCircle, defaultLabel: 'Chat' };
    case 'workflows':
      return { Icon: Workflow, defaultLabel: 'Workflows' };
    case 'members':
      return { Icon: UsersThree, defaultLabel: 'Contacts' };
    case 'transactions':
      return { Icon: CurrencyCircleDollar, defaultLabel: 'Transactions' };
    case 'services':
      return { Icon: Package, defaultLabel: 'Services' };
    case 'content':
      return { Icon: Cookie, defaultLabel: 'Content' };
    case 'library':
      return { Icon: Book, defaultLabel: 'Library' };
    case 'bookmarks':
      return { Icon: Bookmarks, defaultLabel: 'Bookmarks' };
    case 'get-paid':
      return { Icon: Coins, defaultLabel: 'Get Paid' };
    case 'building-blocks':
      return { Icon: BuildingBlock, defaultLabel: 'Building Blocks' };
    case 'users':
      return { Icon: Users, defaultLabel: 'Users' };
    case 'activity':
      return { Icon: Pulse, defaultLabel: 'Activity' };
    case 'question':
      return { Icon: Question, defaultLabel: 'Question' };
    case 'account':
      return { Icon: UserCircle, defaultLabel: 'Account' };
    case 'community':
      return { Icon: UsersThree, defaultLabel: 'Community' };
    case 'organization':
      return { Icon: Buildings, defaultLabel: 'Organization' };
    case 'bookings':
      return { Icon: Calendar, defaultLabel: 'Bookings' };
    default:
      return { Icon: null, defaultLabel: '' };
    }
  }, [icon]);

  if (!Icon) {
    return null;
  }

  const Tag = href ? CustomLink : 'button';

  const definitelyLabel = label || defaultLabel || icon;

  const labelAlignment = {
    justifyContent: 'flex-start',
    padding: 12,
  };

  const iconProps = {
    'aria-label': icon,
    className: s.container,
    style: (selected && { background: 'var(--color-gray-25)', ...(showLabel && labelAlignment) }) || { ...(showLabel && labelAlignment) },
    ...(href ? { to: href } : { onClick }),
  };

  const Wrapper = showLabel
    ? Fragment
    : (props: { children: ReactElement }) => <Tooltip text={definitelyLabel} alignment="center" className={s.tooltip} {...props} />;

  return (
    <Wrapper>
      <Tag {...iconProps}>
        <div className={s.icon}>
          <Icon size={24} weight={selected ? 'fill' : 'regular'} fill="var(--color-gray-100)" />
          {notificationCount > 0 && <span>{notificationCount}</span>}
        </div>
        {showLabel && <span className={s.label}>{definitelyLabel}</span>}
      </Tag>
    </Wrapper>
  );
};

export default TabIcon;
