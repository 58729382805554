import { useCallback, useRef, useState } from 'react';
import DocumentUploader, { DocumentUploaderRef } from '@components/Document/DocumentUploader';
import DocumentAddButton from '@components/Document/DocumentAddButton';
import Buttons from '@components/Buttons';
import Upload from '@components/Icons/Upload';
import { m } from 'framer-motion';
import s from './DocumentUploadButton.module.scss';

type UploadedDocument = Parameters<NonNullable<Parameters<typeof DocumentUploader>[0]['onFinishUpload']>>[0];
type Props = {
  id: string;
  isInRow?: boolean;
  creatorId: string;
  onUploadComplete: (document: UploadedDocument) => Promise<void> | void;
  isDragging?: boolean;
};

const DocumentUploadButton: React.FC<Props> = ({ id, isInRow, creatorId, onUploadComplete, isDragging }) => {
  const [uploadingProgress, setUploadingProgress] = useState(0);
  const [uploadError, setUploadError] = useState<Error | null>(null);
  const documentUploaderRef = useRef<DocumentUploaderRef>(null);

  const handleClick = useCallback(() => {
    documentUploaderRef.current?.uploadDocument();
  }, []);

  const handleFinishUpload = async (document: UploadedDocument): Promise<void> => {
    await onUploadComplete(document);
    setUploadingProgress(0);
  };

  return (
    <m.div animate={{ opacity: isDragging ? 0 : 1 }} transition={{ duration: 0.1 }}>
      {isInRow
        ? (
          <Buttons
            className={s['circle-button']}
            onClick={handleClick}
            isFetching={uploadingProgress > 0 && uploadingProgress < 100}
            isWrapper
          >
            <Upload size={16} weight="fill" />
          </Buttons>
        )
        : (
          <DocumentAddButton
            onClick={handleClick}
            isSpinning={uploadingProgress > 0 && uploadingProgress < 100}
          />
        )}
      <DocumentUploader
        id={id}
        ref={documentUploaderRef}
        creatorId={creatorId}
        onUploadProgressChange={setUploadingProgress}
        onUploadError={setUploadError}
        onFinishUpload={handleFinishUpload}
      />
      {uploadError && (
        <p className={s.error}>
          Failed to upload document. Please try again.
        </p>
      )}

    </m.div>
  );
};

export default DocumentUploadButton;
