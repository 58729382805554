import { createContext } from 'react';

type ListAndDetailsSubLayoutContext = {
  reloadListView: () => Promise<void>;
};

const ListAndDetailsSubLayoutContext = createContext<ListAndDetailsSubLayoutContext>({
  reloadListView: async () => undefined,
});

export default ListAndDetailsSubLayoutContext;
