import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { useSearchParams } from 'next/navigation';
import classNames from 'classnames';
import { m } from 'framer-motion';
import { useIsMobile } from '@hooks';
import { SIZES } from '@styles/media';
import Header from '@components/DesignLibrary/Header';
import Search from '@components/DesignLibrary/Atoms/Search';
import Filters, { FiltersProps } from '@components/DesignLibrary/Filters';
import PaginationControls from '@components/DesignLibrary/PaginationControls';
import deserializeQueryStringItem from '@helpers/deserializeQueryStringItem';
import TableView, { TableColumn, TableRow } from './TableView';
import ListAndDetailsSubLayoutContext from './Context';
import ListView from './ListView';
import s from './ListAndDetailsSubLayout.module.scss';
import useHighlightSearchTerm from './useHighlightSearchTerm';

type Props = {
  getDetailPagePath: (args: { id: string; creatorSlug?: string }) => string | null;
  header: Omit<Parameters<typeof Header>[0], 'children'>;
  ifNoItemsText?: string;
  isOneColumn: boolean;
  isFetchingList?: boolean;
  items: Array<Parameters<typeof ListView>[0]['items'][number] & { id: string }>;
  sections?: Parameters<typeof ListView>[0]['sections'];
  itemsCount?: number;
  onSearch?: (newSearch: string) => void | Promise<void>;
  selectedId?: string | null;
  pagination?: {
    page: number;
    numPages: number;
    onChangePage: Parameters<typeof PaginationControls>[0]['onChangePage'];
  };
  filters?: FiltersProps;
  reloadListView?: () => Promise<void>;
  isTableViewOnly?: boolean;
  hideTabs?: boolean;
  hideTableToggle?: boolean;
  resourceType: string;
  tableView?: {
    columns: TableColumn[];
    rows: TableRow[];
  };
  secondaryTabs?: Parameters<typeof Header>[0]['tabs'];
  openTableView?: boolean;
};

const ListAndDetailsSubLayout: React.FC<Props> = ({
  children,
  getDetailPagePath,
  header,
  ifNoItemsText,
  isOneColumn,
  isFetchingList,
  items,
  sections,
  itemsCount,
  onSearch,
  pagination,
  selectedId,
  filters,
  reloadListView,
  resourceType,
  isTableViewOnly = false,
  hideTabs = false,
  hideTableToggle = false,
  tableView,
  openTableView = false,
  secondaryTabs,
}) => {
  const urlQueryObject = useSearchParams();
  const [isTableView, setIsTableView] = useState(isTableViewOnly || openTableView);
  const [routingTo, setRoutingTo] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const listRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  const getDetailPagePathRef = useRef(getDetailPagePath);
  getDetailPagePathRef.current = getDetailPagePath;
  const reloadListViewRef = useRef(reloadListView);
  reloadListViewRef.current = reloadListView;
  const isMobile = useIsMobile(SIZES.xl);
  const router = useRouter();
  const creatorSlug = deserializeQueryStringItem(router.query.creatorSlug);

  const isDetailView = Boolean(selectedId);
  const showTable = isTableView;
  const showList = !showTable && (!isOneColumn || !isDetailView);
  const showDetails = !showTable && (!isOneColumn || isDetailView);

  const firstItemId = items && items.length > 0 ? items[0].id : undefined;

  const reloadListViewCallback = useCallback(async () => {
    if (reloadListViewRef.current) {
      reloadListViewRef.current();
    }
  }, []);

  useEffect(() => {
    setIsTableView(isTableViewOnly || openTableView);
  }, [resourceType, openTableView]);

  useEffect(() => {
    // If this is not the detail view, and we want to show both columns, and we've loaded the list.
    // Then we want to router.push to the detail page.
    if (!isDetailView && !isOneColumn && firstItemId && !isTableView && !isTableViewOnly) {
      const path = getDetailPagePathRef.current({ creatorSlug: creatorSlug ?? undefined, id: firstItemId });
      if (path) {
        const queryString = `${urlQueryObject}`;
        const queryStringWithoutTable = queryString.replace('table=true', '').replace('table=false', '');

        if (queryStringWithoutTable !== '') {
          router.push(`${path}?${queryStringWithoutTable}`);
        } else {
          router.push(`${path}`);
        }
      }
    }
  }, [creatorSlug, firstItemId, isOneColumn, isTableView, isDetailView, isTableViewOnly]);

  useEffect(() => {
    router.events.on('routeChangeStart', url => {
      setRoutingTo(url);
    });
    router.events.on('routeChangeComplete', () => {
      setRoutingTo('');
    });
  }, [router, setRoutingTo]);

  useHighlightSearchTerm(searchTerm, listRef, contentRef);

  const contextValue = useMemo(
    () => ({
      reloadListView: reloadListViewCallback,
    }),
    [reloadListViewCallback],
  );

  const handleToggleTableView = (isSet: boolean): void => {
    setIsTableView(isSet);
  };

  const handleCloseTableView = (): void => {
    setIsTableView(false);
  };

  return (
    <ListAndDetailsSubLayoutContext.Provider value={contextValue}>
      <div
        className={s.wrapper}
        {...(creatorSlug && {
          style: {
            width: '100%',
          },
        })}
      >
        {(showList || showTable) && (
          <div
            className={classNames(s.listContent, showDetails || showTable ? s.withDetails : s.noDetails, (showTable || isTableViewOnly) && s.table)}
          >
            <Header
              {...header}
              tabs={hideTabs ? undefined : header.tabs}
              toggle={
                tableView && !isTableViewOnly && !hideTableToggle
                  ? {
                    isChecked: isTableView,
                    label: 'Table view',
                    onChange: handleToggleTableView,
                  }
                  : undefined
              }
            />
            {secondaryTabs && !hideTabs && <div className={s.secondaryTabs}>
              {secondaryTabs.map(sTab => {
                const STabIcon = sTab.icon;
                return (
                  <button key={sTab.label} onClick={sTab.onClick} disabled={sTab.selected}>
                    {sTab.selected && <div className={s.secondaryTabsSelectedTint} />}
                    {STabIcon && <STabIcon size={16} weight={sTab.selected ? 'fill' : 'regular'} />}
                    {sTab.label}
                  </button>
                );
              })}</div>}
            {filters && <Filters {...filters} />}
            {onSearch && (
              <Search
                onChange={query => {
                  setSearchTerm(query);
                  onSearch(query);
                }}
              />
            )}
            {itemsCount ? <div className={s.itemsCount}>{Intl.NumberFormat().format(itemsCount)} total</div> : null}
            <div ref={listRef} key={`${router.pathname}-list`} style={{ display: 'contents' }}>
              {showList && !isTableViewOnly && (
                <ListView
                  ifNoItemsText={ifNoItemsText}
                  isFetching={isFetchingList}
                  items={items}
                  selectedId={selectedId}
                  routingTo={routingTo}
                  sections={sections}
                />
              )}
              {(showTable || isTableViewOnly) && tableView && (
                <TableView columns={tableView.columns} rows={tableView.rows} isFetching={isFetchingList} onCloseTable={handleCloseTableView} />
              )}
            </div>
            {pagination && (
              <PaginationControls
                className={s.paginationControls}
                page={pagination.page}
                numPages={pagination.numPages}
                onChangePage={pagination.onChangePage}
              />
            )}
          </div>
        )}

        {showDetails && !isTableViewOnly && (
          <m.div
            {...(!isMobile && {
              animate: { opacity: 1 },
              initial: { opacity: 0 },
              transition: { duration: 0.5, ease: [0.25, 1, 0.5, 1] },
            })}
            className={s.mainContent}
            ref={contentRef}
            key={`${router.pathname}-content-${searchTerm.length < 3 ? '' : searchTerm}`}
          >
            {children}
          </m.div>
        )}
      </div>
    </ListAndDetailsSubLayoutContext.Provider>
  );
};

export default ListAndDetailsSubLayout;
