import React, { ReactElement, useMemo } from 'react';
import { m } from 'framer-motion';
import { setup, isSupported } from '@loomhq/record-sdk';

import LoomIcon from '@components/Icons/LoomIcon';
import VideoCamera from '@components/Icons/VideoCamera';
import Record from '@components/Icons/Record';
import XCircle from '@components/Icons/XCircle';

import Buttons from 'components/Buttons';
import { postLoomMedia } from '@tsClient';
import MenuButton from 'components/MenuButton';
import { Media } from '@typings/graphql-models';
import useNoodleApi from '@hooks/useNoodleApi';
import { Job, JobType, useJobContext } from '@providers/Jobs';
import getLoomToken from '@tsClient/getLoomToken';
import { logError } from '@providers/ErrorTracking';
import { useUser } from '@providers/Auth';

const LOOM_BUTTON_ID = 'loom-sdk-button';

type Props = {
  disabled: boolean;
  onUploadVideo: () => void;
  isLoomEnabled?: boolean;
  isVideoEnabled?: boolean;
  onAttachLoomMedia?: (media: Media) => void;
  jobCorrelationId?: string;
};

type JobRef = {
  job?: Job;
  media?: Media;
};

const AddVideo = ({ disabled, onUploadVideo, isLoomEnabled, onAttachLoomMedia, isVideoEnabled, jobCorrelationId }: Props): ReactElement => {
  const [isLoomSupported, setIsLoomSupported] = React.useState(true);
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);

  const buttonRef = React.useRef<HTMLButtonElement>(null);
  const { addJob, updateJob } = useJobContext();
  const jobRef: JobRef = useMemo(() => ({}), []);
  const [user] = useUser();

  const { getData: postLoomMediaFn } = useNoodleApi(postLoomMedia);
  const { getData: getLoomTokenFn } = useNoodleApi(getLoomToken);

  React.useEffect(() => {
    async function setupLoom(): Promise<void> {
      let isSupportedCheck = false;
      let checkError = null;
      try {
        const { supported, error } = await isSupported();
        isSupportedCheck = supported;
        checkError = error;
        if (!supported && isLoomSupported) {
          setIsLoomSupported(false);
        } else if (supported && !isLoomSupported) {
          setIsLoomSupported(true);
        }
      } catch (e) {
        setIsLoomSupported(false);
        checkError = e;
      }

      if (!isSupportedCheck) {
        // eslint-disable-next-line
        console.warn(`Error setting up Loom: ${checkError}`);
        return;
      }

      if (!user || user.isAnonymous || !user.isCreator) return;
      const { data: token } = await getLoomTokenFn();
      if (token) {
        try {
          const { configureButton } = await setup({
            jws: token,
          });

          const sdkButton = configureButton({ element: buttonRef.current || undefined });

          sdkButton.on('insert-click', async (video: { embedUrl: string }) => {
            const uploadFn = async (): Promise<Media | null> => {
              const { data: media } = await postLoomMediaFn({
                loomEmbedUrl: video.embedUrl,
                name: 'loom video',
                type: 'loomVideo',
              });
              if (media && jobRef.job) {
                updateJob({
                  id: jobRef.job.id,
                  uploadMedia: media,
                });
                onAttachLoomMedia?.(media);
              }
              return media;
            };
            jobRef.job = addJob({
              correlationId: jobCorrelationId,
              function: uploadFn,
              metadata: {
                isLoom: true,
              },
              title: 'Upload loom video',
              type: JobType.MESSAGE_MEDIA_UPLOAD,
            });
          });
        } catch (e) {
          logError(e);
          setIsLoomSupported(false);
        }
      } else {
        setIsLoomSupported(false);
      }
    }
    if (isLoomSupported) {
      setupLoom();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buttonRef, jobCorrelationId, user]);

  const ButtonCMP = isLoomEnabled ? MenuButton : Buttons;

  return (
    <>
      <ButtonCMP
        // id='handbookMenuButton'
        disabled={disabled}
        ariaLabel="Attach video"
        iconBefore={
          <m.div
            style={{
              alignItems: 'center',
              display: 'flex',
              height: '16px',
              justifyContent: 'center',
              width: '16px',
              willChange: 'transform',
            }}
            whileTap={{ scale: disabled ? 1 : 0.9 }}
          >
            {isMenuOpen
              ? (
                <XCircle size={16} weight="fill" color="var(--color-gray-50)" />
              )
              : (
                <VideoCamera size={16} weight="fill" style={{ fill: !disabled ? 'var(--color-primary)' : undefined }} />
              )}
          </m.div>
        }
        onClick={isLoomEnabled ? () => setIsMenuOpen(true) : onUploadVideo}
        onClose={isLoomEnabled ? () => setIsMenuOpen(false) : undefined}
        menuItems={[
          {
            iconBefore: <Record size={16} weight="fill" style={{ fill: !disabled ? 'var(--color-primary)' : undefined }} />,
            isEnabled: isVideoEnabled,
            onClick: () => {
              setIsMenuOpen(false);
              onUploadVideo();
            },
            style: {
              height: 32,
            },
            title: 'Upload Video',
          },
          {
            disabled: !isLoomSupported,
            iconBefore: <LoomIcon />,
            isEnabled: isLoomEnabled,
            onClick: () => {
              setIsMenuOpen(false);
              buttonRef.current?.click();
            },
            style: {
              height: 32,
            },
            title: 'Record Loom Video',
          },
        ].filter(i => i.isEnabled)}
      >Video</ButtonCMP>
      {isLoomSupported && (
        <button style={{ opacity: 0, position: 'absolute', zIndex: '-1' }} ref={buttonRef} id={LOOM_BUTTON_ID}>
          Loom Video
        </button>
      )}
    </>
  );
};

export default AddVideo;
