const canAddNewPaymentMethod = (args: {
  useDifferentPaymentMethod: boolean;
  userHasCustomerStripeId: boolean;
  creatorSlug: string | null;
  isCardPaymentEnabled: boolean;
  isDebitCardPaymentEnabled: boolean;
  isAchPaymentEnabled: boolean;
  defaultPaymentMethod: {
    card: unknown | null;
    us_bank_account: unknown | null;
  } | null;
}): boolean => Boolean(
  !args.useDifferentPaymentMethod
  && args.userHasCustomerStripeId
  && args.creatorSlug
  && (
    ((args.isCardPaymentEnabled || args.isDebitCardPaymentEnabled) && args.defaultPaymentMethod?.card)
    || (args.isAchPaymentEnabled && args.defaultPaymentMethod?.us_bank_account)
  ),
);

export default canAddNewPaymentMethod;
