import React, { FC, useContext, useEffect, useState } from 'react';
import TeamsContext from '@providers/Teams/TeamsContext';
import useNoodleApi from '@hooks/useNoodleApi';
import * as tsClient from '@tsClient';
import CustomSelect from '@components/FormFields/CustomSelect';
import ProgressIndicator from '@components/ProgressIndicator';
import s from './SelectInvoiceTemplate.module.scss';

type Props = {
  initialValue?: string;
  onSelect: (invoiceTemplate: Awaited<ReturnType<typeof tsClient.invoices.getInvoiceRequestTemplates>>['items'][number]) => void;
};

const SelectInvoiceTemplate: FC<Props> = ({ initialValue, onSelect }) => {
  const { creatorId } = useContext(TeamsContext);
  const [hasInitiallyLoaded, setHasInitiallyLoaded] = useState(false);
  const [selectedValue, setSelectedValue] = useState<string | null>(initialValue || null);

  const {
    data: invoiceTemplates,
    getData: getInvoiceTemplates,
  } = useNoodleApi(tsClient.invoices.getInvoiceRequestTemplates);

  useEffect(() => {
    if (creatorId) {
      getInvoiceTemplates({ creatorId, page: 1, perPage: 1000 });
    }
  }, [creatorId, getInvoiceTemplates]);

  useEffect(() => {
    if (!hasInitiallyLoaded && invoiceTemplates?.items && invoiceTemplates.items.length !== 0 && !initialValue) {
      onSelect(invoiceTemplates.items[0]);
      setHasInitiallyLoaded(true);
    } else if (!hasInitiallyLoaded && initialValue) {
      setHasInitiallyLoaded(true);
    }
  }, [hasInitiallyLoaded, invoiceTemplates, onSelect, initialValue]);

  if (!invoiceTemplates?.items || invoiceTemplates?.items.length === 0) {
    return <ProgressIndicator isCentered className={s.progress} />;
  }

  return (
    <CustomSelect
      id='invoiceTemplate'
      label='Select Invoice Template'
      value={selectedValue || invoiceTemplates.items[0].id}
      options={invoiceTemplates.items.map((template) => ({
        key: template.id,
        title: template.title,
        value: template.id,
      }))}
      withFilter
      onChange={(value) => {
        const template = invoiceTemplates.items.find(t => t.id === value);
        if (template) {
          setSelectedValue(value);
          onSelect(template);
        }
      }}
    />
  );
};

export default SelectInvoiceTemplate;
