import * as ApiModels from '@typings/api-models';
import s from './InvoicePaymentsWidget.module.scss';

const PaymentStatus: React.FC<Pick<ApiModels.Payment, 'paymentStatus'>> = ({ paymentStatus }) => {
  if (paymentStatus === 'succeeded') {
    return null;
  }

  if (paymentStatus === 'failed' || paymentStatus === 'requires_payment_method') {
    return (
      <p className={s.rowTitle}>
        Status: <span className={s.error}>Failed</span>
      </p>
    );
  }

  if (paymentStatus === 'pending') {
    return (
      <p className={s.rowTitle}>
        Status: <span className={s.warning}>Pending</span>
      </p>
    );
  }

  if (paymentStatus === 'voided') {
    return (
      <p className={s.rowTitle}>
        Status: <span className={s.warning}>Voided</span>
      </p>
    );
  }

  return null;
};

export default PaymentStatus;
