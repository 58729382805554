import React, { FC, useContext, useState, useEffect, useRef } from 'react';
import InputField from '@components/FormFields/InputField/InputField';
import AttachmentSettings from '@components/DesignLibrary/AttachmentSettings/AttachmentSettings';
import CheckBox from '@components/DesignLibrary/Atoms/CheckBox';
import * as ApiModels from '@typings/api-models';
import teamsContext from '@providers/Teams/TeamsContext';
import StripeAccountSelector, { StripeAccountSelectorRef } from '@components/StripeAccountSelector';
import ConfidoBankAccountSelector from '@components/ConfidoBankAccountSelector';
import Spacer from '@components/Spacer';
import LineItemEditor from '@components/LineItemEditor';
import Button from '@components/DesignLibrary/Button';
import Header from '@components/DesignLibrary/Header';
import StripeLogo from '@components/Icons/StripeLogo';
import GearSix from '@components/Icons/GearSix';
import { List } from '@phosphor-icons/react';
import { useFeatureFlag } from '@providers/FeatureFlags';
import dashboardContext from '@layouts/DashboardLayout/DashboardContext';
import CollapsibleArea from '@components/CollapsibleArea';
import { useUserProfile } from '@/providers/Auth';
import { isNoodler } from '@/providers/FeatureFlags/checkFeatureFlag';
import AddStripeAccountModal from '@modals/AddStripeAccountModal';

import s from './InvoiceSettings.module.scss';

type Props = {
  initialValue?: Pick<
    ApiModels.Invoice,
    | 'title'
    | 'priceId'
    | 'passProcessingFeesToCustomer'
    | 'isAchPaymentEnabled'
    | 'isCreditCardPaymentEnabled'
    | 'isDebitCardPaymentEnabled'
    | 'isDebitCardPaymentRequiredForCustomer'
    | 'secondaryStripeAccountId'
    | 'isPartialPaymentEnabled'
    | 'confidoBankAccountId'
    | 'isExemptFromApplicationFee'
  > & {
    lineItems: Parameters<typeof LineItemEditor>[0]['initialValue'];
    isFollowupEnabled?: boolean;
    slug?: Pick<ApiModels.InvoiceTemplate, 'slug'>;
  };
  currency: string;
  onSave: (data: {
    invoiceData: Pick<
      ApiModels.InvoiceTemplate,
      | 'title'
      | 'priceId'
      | 'isFollowupEnabled'
      | 'passProcessingFeesToCustomer'
      | 'isAchPaymentEnabled'
      | 'isCreditCardPaymentEnabled'
      | 'isDebitCardPaymentEnabled'
      | 'isDebitCardPaymentRequiredForCustomer'
      | 'isExemptFromApplicationFee'
      | 'secondaryStripeAccountId'
      | 'isPartialPaymentEnabled'
      | 'confidoBankAccountId'
    >;
    lineItems: NonNullable<Parameters<typeof LineItemEditor>[0]['initialValue']>;
  }) => Promise<void>;
  isInvoice?: boolean;
  cta?: string;
  requireLineItems: boolean;
};

const InvoiceSettings: FC<Props> = ({
  initialValue,
  currency,
  onSave,
  isInvoice = false,
  cta = 'Save',
  requireLineItems,
}) => {
  const [title, setTitle] = useState(initialValue?.title || '');
  const [isFollowupEnabled, setFollowupEnabled] = useState(initialValue?.isFollowupEnabled || false);
  const [passFeesToCustomer, setPassFeesToCustomer] = useState(initialValue?.passProcessingFeesToCustomer ?? false);
  const [isAchEnabled, setAchEnabled] = useState(initialValue?.isAchPaymentEnabled ?? false);
  const [isCardEnabled, setCardEnabled] = useState(initialValue?.isCreditCardPaymentEnabled || initialValue?.isDebitCardPaymentEnabled || false);
  const [forceDebit, setForceDebit] = useState((initialValue?.isDebitCardPaymentEnabled && !initialValue?.isDebitCardPaymentRequiredForCustomer
    && !initialValue?.isCreditCardPaymentEnabled) || false);
  const [forceCustomerToUseDebit, setForceCustomerToUseDebit] = useState(initialValue?.isDebitCardPaymentRequiredForCustomer ?? false);
  const [isPartialPaymentEnabled, setPartialPaymentEnabled] = useState(initialValue?.isPartialPaymentEnabled || false);
  const [secondaryStripeAccountId, setSecondaryStripeAccountId] = useState(initialValue?.secondaryStripeAccountId || null);
  const [isExemptFromApplicationFee, setIsExemptFromApplicationFee] = useState(initialValue?.isExemptFromApplicationFee ?? false);
  const [confidoBankAccountId, setConfidoBankAccountId] = useState(initialValue?.confidoBankAccountId || null);
  const [isAddStripeAccountModalOpen, setIsAddStripeAccountModalOpen] = useState(false);
  const [lineItems, setLineItems] = useState(initialValue?.lineItems || []);
  const [isSaving, setIsSaving] = useState(false);
  const stripeAccountRef = useRef<StripeAccountSelectorRef>(null);
  const profile = useUserProfile();

  const { creatorId } = useContext(teamsContext);

  const { creator } = useContext(dashboardContext);

  const canSetDebitOnlyForCustomer = useFeatureFlag('force-customer-debit');

  useEffect(() => {
    if (initialValue) {
      setTitle(initialValue.title || '');
      setFollowupEnabled(initialValue.isFollowupEnabled || false);
      setPassFeesToCustomer(initialValue.passProcessingFeesToCustomer);
      setAchEnabled(initialValue.isAchPaymentEnabled);
      setCardEnabled(initialValue.isCreditCardPaymentEnabled || initialValue.isDebitCardPaymentEnabled || false);
      setForceDebit(initialValue.isDebitCardPaymentEnabled && !initialValue.isCreditCardPaymentEnabled);
      setPartialPaymentEnabled(initialValue.isPartialPaymentEnabled);
      setSecondaryStripeAccountId(initialValue.secondaryStripeAccountId);
      setConfidoBankAccountId(initialValue.confidoBankAccountId);
      setLineItems(initialValue.lineItems || []);
      setIsExemptFromApplicationFee(initialValue.isExemptFromApplicationFee);
    }
  }, [initialValue]);

  const handleSave = async (): Promise<void> => {
    if (!creatorId) {
      return;
    }
    setIsSaving(true);
    await onSave({
      invoiceData: {
        confidoBankAccountId,
        isAchPaymentEnabled: isAchEnabled,
        isCreditCardPaymentEnabled: isCardEnabled && !forceDebit,
        isDebitCardPaymentEnabled: isCardEnabled || forceDebit,
        isDebitCardPaymentRequiredForCustomer: isCardEnabled && forceCustomerToUseDebit,
        isExemptFromApplicationFee,
        isFollowupEnabled,
        isPartialPaymentEnabled,
        passProcessingFeesToCustomer: passFeesToCustomer,
        priceId: null,
        secondaryStripeAccountId,
        title,
      },
      lineItems,
    });
    setIsSaving(false);
  };

  const handleChangeStripeAccount = async (stripeAccountId: string | null): Promise<void> => {
    if (stripeAccountId === 'add-new-account') {
      setIsAddStripeAccountModalOpen(true);
    } else {
      setSecondaryStripeAccountId(stripeAccountId);
    }
  };

  const handleCreatedStripeAccount = async (stripeAccountId: string): Promise<void> => {
    await stripeAccountRef.current?.reloadAccounts();
    setSecondaryStripeAccountId(stripeAccountId);
  };

  return (
    <>
      <div className={s.padding}>
        <InputField id="title" name="title" values={{ title }} onChange={setTitle} label="Title of invoice" hasFixedHeight />
        {initialValue && !isInvoice && isNoodler(profile) && <InputField id="slug" name="slug" values={{ slug: initialValue.slug }} onChange={setTitle} label="Slug" disabled hasFixedHeight />}
      </div>
      <Spacer size={0} isLine />
      <Header title="Payment processing" hierarchy="h3" Icon={StripeLogo} className={s.header} />
      <StripeAccountSelector
        ref={stripeAccountRef}
        value={secondaryStripeAccountId || undefined}
        onChange={handleChangeStripeAccount}
        className={s.stripe}
      />
      <ConfidoBankAccountSelector value={confidoBankAccountId || undefined} onChange={setConfidoBankAccountId} className={s.stripe} />
      <Header title="Settings" hierarchy="h3" Icon={GearSix} className={s.header} />
      {!isInvoice && (
        <AttachmentSettings
          showHeader={false}
          isFollowupEnabled={isFollowupEnabled}
          setIsFollowupEnabled={setFollowupEnabled}
        />
      )}
      <div className={s.row}>
        <CheckBox
          id="followup"
          label="Pass payment processing fees to contact"
          isChecked={passFeesToCustomer}
          onChange={() => setPassFeesToCustomer(prev => !prev)}
        />
        <CollapsibleArea caretPosition="left" title="Show details" openTitle='Hide details' className={s.helpfulText}>
          <p className="caption">Noodle allows law firms to implement a compliant program that helps you offset the cost of both credit and debit card fees.</p>
          <p className="caption">We allow you to display two sets of fees on your bills: one lower fee for clients paying via <b>ACH</b>{" "}
          and one higher fee for clients paying by <b>credit or debit card</b> during their checkout process.</p>
        </CollapsibleArea>
      </div>
      <CheckBox
        className={s.row}
        id="partial"
        label="Allow partial payments"
        isChecked={isPartialPaymentEnabled}
        onChange={() => setPartialPaymentEnabled(prev => !prev)}
      />
      <CheckBox className={s.row} id="followup" label="Allow ACH payments" isChecked={isAchEnabled} onChange={() => setAchEnabled(prev => !prev)} />
      <CheckBox
        className={s.row}
        id="followup"
        label="Allow card payments"
        isChecked={isCardEnabled}
        onChange={() => setCardEnabled(prev => !prev)}
      />
      {isCardEnabled && (
        <>
          <CheckBox
            className={s.row}
            id="followup"
            label="Force debit card transactions only"
            isChecked={forceDebit}
            onChange={() => {
              if (forceDebit) {
                setForceDebit(false);
              } else {
                setForceDebit(true);
                setForceCustomerToUseDebit(false);
              }
            }}
          />
          {canSetDebitOnlyForCustomer && creator?.usesConfido && (
            <CheckBox
              className={s.row}
              id="forceCustomerToUseDebit"
              label="Force debit cards, but allow credit cards from other people"
              isChecked={forceCustomerToUseDebit}
              onChange={() => {
                if (forceCustomerToUseDebit) {
                  setForceCustomerToUseDebit(false);
                } else {
                  setForceCustomerToUseDebit(true);
                  setForceDebit(false);
                }
              }}
            />
          )}
        </>
      )}
      <Header title="Line items" hierarchy="h3" Icon={List} className={s.header} />
      <LineItemEditor
        currency={currency}
        initialValue={lineItems}
        onChange={setLineItems}
      />
      <div className={s.padding}>
        <Button
          fullWidth
          disabled={(requireLineItems ? lineItems.length === 0 : false) || !title || (!isCardEnabled && !isAchEnabled)}
          variant="primary"
          size="md"
          onClick={handleSave}
          loading={isSaving}
        >
          {cta}
        </Button>
        {((requireLineItems ? lineItems.length === 0 : false) || !title || (!isCardEnabled && !isAchEnabled)) && (
          <div className={s.errorMessages}>
            {requireLineItems && lineItems.length === 0 && <p className="caption">* Line items are required for invoices</p>}
            {!title && <p className="caption">* Title is required</p>}
            {!isCardEnabled && !isAchEnabled && <p className="caption">* At least one payment method must be enabled</p>}
          </div>
        )}
      </div>
      {isAddStripeAccountModalOpen && (
        <AddStripeAccountModal onClose={() => setIsAddStripeAccountModalOpen(false)} onCreateAccount={handleCreatedStripeAccount} />
      )}
    </>
  );
};

export default InvoiceSettings;
