import React, { FC, ReactElement } from 'react';

import XCircle from 'components/Icons/XCircle';
import Buttons from 'components/Buttons';
import { MESSAGE_ATTACHMENT_REFERENCE_TYPES } from '@typings/api-models';
import getAttachmentIcon from '@helpers/getAttachmentIcon';
import s from './InputComposer.module.scss';

type Props = {
  attachments: {
    id: string;
    referenceType: MESSAGE_ATTACHMENT_REFERENCE_TYPES;
    referenceId: string;
    title: string;
  }[];
  removeAttachment: (attachmentId: string) => void;
};

const Attachments: FC<Props> = ({ attachments, removeAttachment }): ReactElement => (
  <div className={s['library-attachments']}>
    {attachments.length > 0
      && attachments.map(a => {
        const IconComponent = getAttachmentIcon(a.referenceType);
        return (
          <div key={a.referenceId} className={s['library-attachments__item']}>
            <div className={s['library-attachments__item-left']}>
              <div className={s['media-attachments__progress']}>{IconComponent && <IconComponent size="16" weight='fill' color="var(--color-primary)" />}</div>
              <span className="caption">{a.title}</span>
            </div>
            <Buttons
              className={s['library-attachments__remove']}
              onClick={() => removeAttachment(a.id)}
              icon={<XCircle weight="fill" color="var(--color-error)" />}
            />
          </div>
        );
      })}
  </div>
);

export default Attachments;
