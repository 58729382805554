import React, { FC } from 'react';
import Modal from '@components/Modal';
import CreateEditFormRequestTemplateForm from '@lib/CreateEditFormRequestTemplateForm';

type Props = {
  formRequestTemplate: Parameters<typeof CreateEditFormRequestTemplateForm>[0]['formRequestTemplate'];
  onClose: () => void;
  beforeClose?: () => Promise<void>;
  creatorId: string;
};

const FormRequestModal: FC<Props> = ({ beforeClose, formRequestTemplate, onClose, creatorId }) => {
  const isEditing = !!formRequestTemplate;

  return (
    <Modal title={`${isEditing ? 'Edit' : 'Create'} Form Request`} onClose={onClose} >
      <CreateEditFormRequestTemplateForm
        beforeClose={beforeClose}
        creatorId={creatorId}
        formRequestTemplate={formRequestTemplate}
        onSubmit={onClose}
      />
    </Modal>
  );
};

export default FormRequestModal;
