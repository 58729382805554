import { ReactElement, ReactNode, useEffect, useRef } from 'react';

type Props = {
  children: ReactNode;
  className?: string;
  isActive?: boolean;
  onOutsideClick?: (e?: MouseEvent) => void;
};

const OutsideClick = ({
  children,
  className,
  isActive = true,
  onOutsideClick = () => { /* empty */ },
}: Props): ReactElement => {
  const classes = ['outside-click', className].join(' ');
  const componentRef = useRef<HTMLDivElement>(null);

  useEffect((): () => void => {
    const clickHandler = (e: MouseEvent): void => {
      if (componentRef.current?.contains(e.target as Node)) {
        return;
      }
      setTimeout(() => onOutsideClick(e), 1);
    };
    const addListener = (): void => {
      document.addEventListener('mouseup', clickHandler);
    };
    const removeListener = (): void => {
      document.removeEventListener('mouseup', clickHandler);
    };
    if (isActive) addListener();

    return removeListener;
  }, [isActive, onOutsideClick]);

  return (
    <div className={classes} ref={componentRef}>
      {children}
    </div>
  );
};

export default OutsideClick;
