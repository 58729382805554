import { FC } from 'react';
import classNames from 'classnames';
import Button from '@components/Buttons';
import DayPicker from '../DayPicker';
import { days } from '../../utils';
import s from './EmptyRow.module.scss';
import { Day } from '../../types';

type EmptyRowProps = {
  addBlock: () => void;
  copyBlocks: (day: string) => void;
  daysOfWeek: Day[];
};

const EmptyRow: FC<EmptyRowProps> = ({ addBlock, copyBlocks, daysOfWeek }) => {
  const DayOptions = days.map((day, i) => ({
    key: i,
    title: day.charAt(0).toUpperCase() + day.slice(1),
    value: day,
  }));

  // only allow days that have blocks
  const options = DayOptions.filter((day) => daysOfWeek.find((d) => d.name === day.value)?.blocks.length !== 0);

  return (
    <div className={classNames(s['empty-row__container'])}>
      <DayPicker
        id="day"
        name="Day"
        isFullWidth
        onChange={(day: string) => copyBlocks(day)}
        options={options}
      />
      <Button
        isSecondary
        onClick={() => {
          addBlock();
        }}
        isFullWidth
      >
      Create New
      </Button>
    </div>
  );
};

export default EmptyRow;
