import { useEffect, useMemo } from 'react';
import { IDENTIFIERS, getUrl } from '@helpers/urlsHelper';
import ListAndDetailsSubLayout from '@layouts/ListAndDetailsSubLayout';
import useIsMobile, { SIZES } from '@hooks/useIsMobile';
import { useRouter } from 'next/navigation';
import { useQueryState } from 'nuqs';
import List from '@components/Icons/List';
import Table from '@components/Icons/Table';
import Checks from '@components/Icons/Checks';

import useActivity from './useActivity';
import { ResourceType } from './common';
import useMyWorkflows from './useMyWorkflows';
import useMyWorkflowsV2 from './useMyWorkflowsV2';
import useTemplates from './useTemplates';
import useAttachments from './useAttachments';

type Props = {
  resourceType: ResourceType;
  isTableViewOnly?: boolean;
  hideTabs?: boolean;
};

const TABS: Array<{
  href: string;
  label: string;
  resourceType: ResourceType;
}> = [
  {
    href: getUrl(IDENTIFIERS.DASHBOARD_USER_WORKFLOWS),
    label: 'Activity',
    resourceType: 'activity',
  },
  {
    href: getUrl(IDENTIFIERS.DASHBOARD_WORKFLOWS_MY_WORKFLOWS),
    label: 'My Workflows',
    resourceType: 'my-workflows',
  },
  {
    href: getUrl(IDENTIFIERS.DASHBOARD_WORKFLOWS_TEMPLATES),
    label: 'Templates',
    resourceType: 'templates',
  },
];

const Workflow: React.FC<Props> = ({ children, resourceType, isTableViewOnly, hideTabs }) => {

  const [isTable]= useQueryState('table');

  const router = useRouter();
  const isOneColumn = useIsMobile(SIZES.md);
  const activityData = useActivity({ resourceType });
  const myWorkflowsData = useMyWorkflows();
  const myWorkflowsV2Data = useMyWorkflowsV2();
  const templatesData = useTemplates();
  const attachmentData = useAttachments();
  const header = useMemo(() => ({
    tabs: TABS.map(t => ({
      href: t.href,
      label: t.label,
      selected: resourceType === t.resourceType || (t.resourceType === 'activity' && resourceType === 'attachments'),
    })),
    title: "Workflows",
  }), [resourceType]);

  useEffect(() => {
    if (resourceType === 'root') {
      router.push(TABS[0].href);
    }
  }, [resourceType]);

  const resourceData = (resourceType === 'activity' && activityData)
    || (resourceType === 'my-workflows' && myWorkflowsData)
    || (resourceType === 'my-workflows-v2' && myWorkflowsV2Data)
    || (resourceType === 'templates' && templatesData)
    || (resourceType === 'attachments' && attachmentData)
    || null;

  if (!resourceData) {
    return null;
  }

  return (
    <ListAndDetailsSubLayout
      header={header}
      hideTabs={hideTabs}
      isOneColumn={isOneColumn}
      isTableViewOnly={isTableViewOnly}
      openTableView={isTable === 'true'}
      resourceType={resourceType}
      hideTableToggle
      secondaryTabs={(resourceType === 'activity' || resourceType === 'attachments')
        ? [{
          icon: List,
          label: 'List view',
          onClick: () => router.push(getUrl(IDENTIFIERS.DASHBOARD_USER_WORKFLOWS, { table: null })),
          selected: resourceType === 'activity' && isTable !== 'true',
        },{
          icon: Table,
          label: 'Table view',
          onClick: () => router.push(getUrl(IDENTIFIERS.DASHBOARD_USER_WORKFLOWS, { table: 'true' })),
          selected: resourceType === 'activity' && isTable === 'true',
        },{
          icon: Checks,
          label: 'Tasks view',
          onClick: () => router.push(getUrl(IDENTIFIERS.DASHBOARD_WORKFLOWS_ATTACHMENTS, { table: 'true' })),
          selected: resourceType === 'attachments',
        }]
        : undefined}
      {...resourceData}
    >
      {children}
    </ListAndDetailsSubLayout>
  );
};

export default Workflow;
