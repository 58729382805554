import { TD } from '@/components/DesignLibrary/Table';

export type Props = {
  type: 'empty';
};

const EmptyCell: React.FC<Props> = () => (
  <TD label="—" compact />
);

export default EmptyCell;