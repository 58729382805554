import { useState, useEffect } from 'react';

const useBeforeUnload = (shouldPrevent: boolean): void => {
  const [prevent, setPrevent] = useState(shouldPrevent);

  useEffect(() => {
    setPrevent(shouldPrevent);
  }, [shouldPrevent]);

  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent): void => {
      if (prevent) {
        e.preventDefault();
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [prevent]);
};

export default useBeforeUnload;
