import { FC, FormEventHandler, Fragment, useContext, useEffect, useState } from 'react';

import { getUrl, IDENTIFIERS } from '@helpers/urlsHelper';
import useNoodleApi from '@hooks/useNoodleApi';
import * as tsClient from '@tsClient';
import * as ApiModels from '@typings/api-models';
import { useFeatureFlag } from '@providers/FeatureFlags';
import Header from '@components/DesignLibrary/Header';
import ProgressIndicator from '@components/ProgressIndicator';
import * as format from '@format';
import Button from '@components/DesignLibrary/Button';
import { useToast } from '@hooks';
import {
  isConsultationProduct,
  isInPersonProduct,
  isLiteProduct,
  isSessionProduct,
  isLiveSessionProduct,
} from '@helpers/isProductType';
import StripeAccountSelector from '@components/StripeAccountSelector';
import ConfidoBankAccountSelector from '@components/ConfidoBankAccountSelector';
import AddStripeAccountModal from '@modals/AddStripeAccountModal';
import CreatorProductAvailability from '@lib/CreatorProductAvailability/CreatorProductAvailability';
import { GoogleOauth2Provider } from '@providers';
import { Table, TableRow, TBody, TD, TH, THead } from '@components/DesignLibrary/Table';
import { Creator, Currency, Price, RichText, WORKFLOW_REFERENCE_TYPES } from '@typings/api-models';
import Switch from '@components/DesignLibrary/Atoms/Switch';
import CreateEditPriceModal from '@modals/CreateEditPriceModal';
import SecondaryActions from '@components/DesignLibrary/SecondaryActions';
import PaymentLinkModal from '@modals/PaymentLinkModal';
import { SELF_ORIGIN } from '@configuration/client';
import ShoppingCart from '@providers/ShoppingCart';
import Plus from '@components/Icons/Plus';
import Export from '@components/Icons/Export';
import CopySimple from '@components/Icons/CopySimple';
import TrashSimple from '@components/Icons/TrashSimple';
import Eye from '@components/Icons/Eye';
import Calendar from '@components/Icons/Calendar';
import Coins from '@components/Icons/Coins';
import Workflow from '@components/Icons/Workflow';
import Info from '@components/Icons/Info';
import Signature from '@components/Icons/Signature';
import StripeLogo from '@components/Icons/StripeLogo';
import { ListAndDetailsSubLayoutContext } from '@layouts/ListAndDetailsSubLayout';
import DashboardContext from '@layouts/DashboardLayout/DashboardContext';
import SlateEditor, { deserializeHtml } from '@components/SlateEditor';
import Spacer from '@components/Spacer';
import PriceInput from '@components/FormFields/PriceInput';
import { useOpenWorkflowPane } from '@panes/workflowHooks';
import CollapsibleArea from '@components/CollapsibleArea';
import removeNullish from '@helpers/removeNullish';

import s from './ServiceDetails.module.scss';

type ThisPrice = Parameters<typeof ShoppingCart.addProduct>[0]['price'] &
  Pick<Price, 'numBookings' | 'priceTitle' | 'priceSubDescription' | 'isMostPopular' | 'isActive' | 'bookingCalendarLink'> & {
    priceDescription?: Pick<RichText, 'html' | 'text'> | null;
    product: {
      slug: string;
      creator?: Pick<Creator, 'isBuyEnabled'>;
    };
  };

const ServiceDetails: FC<{ productSlug: string; creatorSlug: string }> = ({ productSlug, creatorSlug }) => {
  const { reloadListView } = useContext(ListAndDetailsSubLayoutContext);
  const { setIsShareProductModalOpen } = useContext(DashboardContext);
  const canEditNoodleApplicationFees = useFeatureFlag('can-edit-noodle-application-fees');
  const canCreateTemplate = useFeatureFlag('create-workflow-template');
  const [addStripeAccountModalOpen, setAddStripeAccountModalOpen] = useState(false);
  const [isCreateEditPriceModalOpen, setCreateEditPriceModalOpen] = useState(false);
  const [isMessageFromCreatorInitialized, setIsMessageFromCreatorInitialized] = useState(false);
  const [isProductActive, setIsProductActive] = useState(false);
  const [hideOnOneLink, setHideOnOneLink] = useState(false);
  const [createdPriceId, setCreatedPriceId] = useState<string | null>(null);
  const [isPaymentLinkModalOpen, setPaymentLinkModalOpen] = useState(false);
  const [selectedPrice, setSelectedPrice] = useState<
    (NonNullable<Parameters<typeof CreateEditPriceModal>[0]['price']> & Pick<Price, 'currency'>) | null
      >(null);
  const [isCreatingWorkflow, setIsCreatingWorkflow] = useState(false);
  const [messageFromCreatorValue, setMessageFromCreatorValue] = useState<ApiModels.SlateNode[]>(() => [
    {
      children: [{ text: '' }],
      type: 'paragraph',
    },
  ]);

  const [noodleApplicationFlatFee, setNoodleApplicationFlatFee] = useState<number>(0);
  const openWorkflowPane = useOpenWorkflowPane();

  const {
    data: productDataBySlug,
    fetchingState: { isFetched: isFetchedProductBySlug },
    getData: getProductBySlugFn,
  } = useNoodleApi(tsClient.getProductBySlug);

  const [product, setProduct] = useState(productDataBySlug);

  const { data: workflowsData, getData: getWorkflowsFn } = useNoodleApi(tsClient.products.getProductWorkflows);

  const {
    fetchingState: { isFetching: isCreatingTemplates },
    getData: createWorkflowTemplatesFn,
  } = useNoodleApi(tsClient.workflows.createWorkflowTemplateFromProduct, {
    toastOnError: true,
    toastSuccessMessage: () => [useToast.ToastTypeVariants.SUCCESS, 'Templates created successfully!'],
  });

  const { getData: cloneWorkflowFn, fetchingState: { isFetching: isCloningWorkflow } } = useNoodleApi(tsClient.workflows.cloneWorkflow, {
    toastErrorMessage: () => [useToast.ToastTypeVariants.ERROR, 'Failed to clone workflow'],
    toastSuccessMessage: () => [useToast.ToastTypeVariants.SUCCESS, 'Workflow cloned successfully!'],
  });

  const [workflows, setWorkflows] = useState(workflowsData?.items || []);

  useEffect(() => {
    if (isFetchedProductBySlug && productDataBySlug) {
      setProduct(productDataBySlug);
    }
  }, [isFetchedProductBySlug, productDataBySlug]);

  useEffect(() => {
    if (workflowsData) {
      setWorkflows(workflowsData.items);
    }
  }, [workflowsData]);

  const { getData: updateProductById, fetchingState: updatingProductState } = useNoodleApi(tsClient.products.updateProductById, {
    toastOnError: true,
    toastSuccessMessage: () => [useToast.ToastTypeVariants.SUCCESS, 'Product updated successfully!'],
  });

  const { getData: addWorkflowFn } = useNoodleApi(tsClient.workflows.createWorkflow);

  const { getData: updatePriceFn } = useNoodleApi(tsClient.updatePrice, {
    toastOnError: true,
    toastSuccessMessage: () => [useToast.ToastTypeVariants.SUCCESS, 'Price updated successfully!'],
  });
  const { getData: createPriceFn } = useNoodleApi(tsClient.createPrice, {
    toastOnError: true,
    toastSuccessMessage: () => [useToast.ToastTypeVariants.SUCCESS, 'Price created successfully!'],
  });

  const { getData: updateWorkflowFn } = useNoodleApi(tsClient.updateWorkflow, {
    toastOnError: true,
    toastSuccessMessage: () => [useToast.ToastTypeVariants.SUCCESS, 'Workflow updated successfully!'],
  });

  const usesMessageFromCreatorAsDescription = product && (
    isLiteProduct(product)
    || isConsultationProduct(product)
    || isInPersonProduct(product)
    || isLiveSessionProduct(product)
  );

  const handleSave = async (): Promise<void> => {
    const { data: pData } = await getProductBySlugFn({ creatorSlug, productSlug });
    if (pData) {
      setProduct(pData);
    }
    if (product) {
      const { data: wData } = await getWorkflowsFn({
        page: 1,
        perPage: 50,
        productId: product.id,
      });
      if (wData) {
        setWorkflows(wData.items);
      }
    }
    setAddStripeAccountModalOpen(false);
  };

  const createTemplates = async (): Promise<void> => {
    if (product) {
      await createWorkflowTemplatesFn({ productId: product.id });
    }
  };

  useEffect(() => {
    getProductBySlugFn({ creatorSlug, productSlug });
  }, [creatorSlug, productSlug, getProductBySlugFn]);

  useEffect(() => {
    if (product?.id) {
      getWorkflowsFn({
        page: 1,
        perPage: 50,
        productId: product?.id,
      });
    }
  }, [product?.id, getWorkflowsFn]);

  useEffect(() => {
    if (product) {
      const document = new DOMParser().parseFromString(product?.messageFromCreator?.html || '<p></p>', 'text/html');
      setMessageFromCreatorValue(deserializeHtml(document.body) as ApiModels.SlateNode[]);
      setIsMessageFromCreatorInitialized(true);
    }
    setIsProductActive(product?.isActive ?? false);
    setHideOnOneLink(!product?.showOnOneLink);
    setNoodleApplicationFlatFee(product?.noodleApplicationFlatFee ?? 0);
  }, [product]);

  const handleStripeAccount = async (stripeAccountId: string | null): Promise<void> => {
    if (stripeAccountId === 'add-new-account') {
      setAddStripeAccountModalOpen(true);
    } else if (product) {
      await updateProductById({
        body: {
          secondaryStripeAccountId: stripeAccountId,
        },
        productId: product.id,
      }).then(() => handleSave());
    }
  };

  const handleConfidoBankAccount = async (confidoBankId: string | null): Promise<void> => {
    if (product) {
      await updateProductById({
        body: {
          confidoBankAccountId: confidoBankId,
        },
        productId: product.id,
      }).then(() => handleSave());
    }
  };

  const handleCreatePriceSave = async (pId?: string | null): Promise<void> => {
    await handleSave();
    await reloadListView();
    setCreateEditPriceModalOpen(false);

    if (pId) {
      setCreatedPriceId(pId);
      setPaymentLinkModalOpen(true);
    }
  };

  const handleClonePrice = async (price: ThisPrice): Promise<void> => {
    const createdPrice = await createPriceFn({
      priceData: {
        bookingCalendarLink: price.bookingCalendarLink,
        currency: price.currency,
        isActive: price.isActive,
        isMostPopular: price.isMostPopular,
        numBookings: price.numBookings,
        originalPrice: price.price,
        price: price.price,
        priceDescription: price.priceDescription?.text,
        priceTitle: `${price.priceTitle} (copy)`,
        recurringInterval: price.recurringInterval,
        sessionDuration: price.sessionDuration,
        sessionInterval: price.sessionInterval,
        usageType: price.usageType,
      },
      productId: price.product.id,
    });
    const { data } = await getProductBySlugFn({ creatorSlug, productSlug });
    await reloadListView();
    if (createdPrice.data?.id && data) {
      setSelectedPrice(data.prices.find(p => p.id === createdPrice.data?.id) || null);
      setCreateEditPriceModalOpen(true);
    }
  };

  const handleArchivePrice = async (pId: string): Promise<void> => {
    const updatedPrice = await updatePriceFn({
      body: { isActive: false, isArchived: true },
      id: pId,
    });
    if (updatedPrice.data?.id) {
      await handleSave();
      await reloadListView();
    }
  };

  const handleAddWorkflow = async (): Promise<void> => {
    if (product) {
      setIsCreatingWorkflow(true);
      await addWorkflowFn({
        creatorId: product.creator.id,
        name: 'New Workflow',
        referenceId: product.id,
        referenceType: WORKFLOW_REFERENCE_TYPES.PRODUCT,
      });
      handleSave();
      setIsCreatingWorkflow(false);
    }
  };

  const handleCloneWorkflow = async (workflowId: string): Promise<void> => {
    const { data: workflow } = await cloneWorkflowFn({ id: workflowId });
    if (workflow) {
      setWorkflows(prev => [...prev, workflow]);
    }
    await getProductBySlugFn({ creatorSlug, productSlug });
  };

  const handleArchiveWorkflow = async (workflowId: string): Promise<void> => {
    await updateWorkflowFn({ updates: { isArchived: true }, workflowId });
    await handleSave();
  };

  if (!product || !isMessageFromCreatorInitialized) {
    return (
      <div className={s.padding}>
        <ProgressIndicator isPage isCentered />
      </div>
    );
  }

  if (!product || product.slug !== productSlug) {
    return (
      <>
        <Spacer size={48} />
        <ProgressIndicator isCentered />
      </>
    );
  }

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (event) => {
    event.preventDefault();
    if (product) {
      const formData = new FormData(event.currentTarget);

      const hideProductOnOneLink = formData.get('hideOnOneLink') as string | null === 'on';

      const body: Parameters<typeof updateProductById>[0]['body'] = {
        isActive: formData.get('isActive') as string | null === 'on',
        showOnOneLink: !hideProductOnOneLink,
        title: formData.get('title') as string | null,
      };

      if (canEditNoodleApplicationFees) {
        body.noodleApplicationFlatFee = noodleApplicationFlatFee;
      }

      if (usesMessageFromCreatorAsDescription) {
        body.messageFromCreator = { children: messageFromCreatorValue };
      }

      await updateProductById({
        body,
        productId: product.id,
      });

      await handleSave();
      await reloadListView();
    }
  };

  return (
    <Fragment key={product.id}>
      <Header
        description={(product.productTypes[0]?.noodleProductType && format.productType(product.productTypes[0]?.noodleProductType)) || ''}
        title={product.title || ''}
        actions={[
          {
            icon: Export,
            label: 'Share',
            onClick: () => setIsShareProductModalOpen(product.slug),
          },
          canCreateTemplate
            ? {
              isFetching: isCreatingTemplates,
              isSecondary: true,
              label: 'Create workflow templates',
              onClick: createTemplates,
            }
            : null,
          {
            href: getUrl(IDENTIFIERS.PRODUCT, {
              creatorSlug: product.creator.slug,
              customerView: true,
              productSlug: product.slug,
            }),
            label: 'Preview as customer',
            newTab: true,
          },
        ].filter(removeNullish)}
      />
      <CollapsibleArea
        title="Product details"
        Icon={{
          Component: Info,
        }}
      >
        <form
          className={s.form}
          onSubmit={handleSubmit}
        >
          <label className={s.input}>
            Product Title
            <input minLength={3} required name="title" type="text" defaultValue={product.title || ''} />
          </label>
          {usesMessageFromCreatorAsDescription
            ? (
              <div className={s.input}>
                <label htmlFor='messageFromCreator'>
                  Product Description
                </label>
                <SlateEditor
                  placeholder=''
                  id="messageFromCreator"
                  name="messageFromCreator"
                  slateValue={messageFromCreatorValue}
                  onChange={(newValue: ApiModels.SlateNode[]) => {
                    setMessageFromCreatorValue(newValue);
                  }}
                  isMediaButton={false}
                />
              </div>
            )
            : (
              <label className={s.input}>
                Product Description
                <textarea name="description" defaultValue={product.description || ''} />
              </label>
            )
          }
          {isInPersonProduct(product) && (
            <label className={s.input}>
              Address
              <input name="address" type="address" defaultValue={product.address || ''} />
            </label>
          )}
          <Switch
            label="Active"
            name="isActive"
            isChecked={isProductActive}
            onChange={setIsProductActive}
          />
          <Switch
            label="Hide from clients (you will still be able to share the service URL with them directly)"
            name="hideOnOneLink"
            isChecked={hideOnOneLink}
            onChange={setHideOnOneLink}
          />
          {canEditNoodleApplicationFees && (
            <PriceInput
              currency={ApiModels.Currency.Usd}
              value={noodleApplicationFlatFee}
              name={'noodleApplicationFlatFee'}
              onChange={setNoodleApplicationFlatFee}
              label='Noodle Application Flat Fee'
            />
          )}
          <Button onClick={() => {}} variant="primary" type="submit" loading={updatingProductState.isFetching} size="sm">
            Update
          </Button>
        </form>
      </CollapsibleArea>
      <CollapsibleArea
        title="Payments processing"
        Icon={{
          Component: StripeLogo,
        }}
      >
        <StripeAccountSelector className={s.stripe} value={product.secondaryStripeAccountId || undefined} onChange={handleStripeAccount} />
        <ConfidoBankAccountSelector className={s.stripe} value={product.confidoBankAccountId || undefined} onChange={handleConfidoBankAccount} />
      </CollapsibleArea>
      <CollapsibleArea
        title={`Prices (${product.prices.length})`}
        Icon={{
          Component: Coins,
        }}
      >
        <Button
          variant="primary"
          size="sm"
          onClick={() => {
            setSelectedPrice(null);
            setCreateEditPriceModalOpen(true);
          }}
          className={s.buttonAdd}
        >
          <Plus size={16} />
          Add price
        </Button>
        {product.prices.length > 0 && (
          <Table>
            <THead>
              <TableRow>
                <TH label="Title" />
                <TH label="Description" />
                <TH label="Price" />
                <TH label="Actions" />
              </TableRow>
            </THead>
            <TBody className={s.tbody}>
              {product.prices
                .sort((priceA, priceB) => {
                  if (priceA.isActive && !priceB.isActive) {
                    return -1;
                  }
                  if (!priceA.isActive && priceB.isActive) {
                    return 1;
                  }
                  return 0;
                })
                .map(price => (
                  <TableRow key={price.id} state={(!price.isActive && 'muted') || null}>
                    <TD compact label={price.priceTitle || 'N/A'}>
                      <Button
                        variant="secondary"
                        size="xs"
                        onClick={() => {
                          setSelectedPrice(price);
                          setCreateEditPriceModalOpen(true);
                        }}
                      >
                        Edit price
                      </Button>
                    </TD>
                    <TD compact label={price.priceDescription?.text || 'N/A'} />
                    <TD compact label={format.price.withCurrency(price.price, price.currency)} />
                    <TD compact>
                      <SecondaryActions
                        ariaLabel="Actions"
                        position="static"
                        options={[
                          {
                            icon: CopySimple,
                            value: 'Clone price',
                          },
                          {
                            icon: Eye,
                            value: 'Show payment link',
                          },
                          {
                            destructive: true,
                            icon: TrashSimple,
                            value: 'Archive price',
                          },
                        ]}
                        onSelect={value => {
                          switch (value) {
                          case 'Clone price':
                            handleClonePrice(price);
                            break;
                          case 'Show payment link':
                            setCreatedPriceId(price.id);
                            setPaymentLinkModalOpen(true);
                            break;
                          case 'Archive price':
                            handleArchivePrice(price.id);
                            break;
                          default:
                            break;
                          }
                        }}
                        compact
                        hideTooltip
                      />
                    </TD>
                  </TableRow>
                ))}
            </TBody>
          </Table>
        )}
      </CollapsibleArea>
      {(isInPersonProduct(product) || isConsultationProduct(product)
        || (isSessionProduct(product) && product.prices.find(p => p.sessionDuration))) && product.creator?.id && (
        <CollapsibleArea
          title="Availability"
          Icon={{
            Component: Calendar,
          }}
        >
          <GoogleOauth2Provider>
            <CreatorProductAvailability
              className={s.availability}
              productSlug={productSlug}
              creatorSlug={creatorSlug}
              creatorId={product.creator.id}
              isInline
            />
          </GoogleOauth2Provider>
        </CollapsibleArea>
      )}
      <CollapsibleArea
        title={`Workflows (${workflows.length})`}
        Icon={{
          Component: Workflow,
        }}
      >
        <Button
          variant="primary"
          size="sm"
          onClick={handleAddWorkflow}
          className={s.buttonAdd}
          loading={isCreatingWorkflow}
        >
          {!isCreatingWorkflow && <Plus size={16} />}
          Create new workflow
        </Button>
        {isCloningWorkflow && (
          <>
            <Spacer size={16} />
            <ProgressIndicator isCentered />
            <Spacer size={16} />
          </>
        )}
        {workflows.length > 0 && (
          <Table>
            <THead>
              <TableRow>
                <TH label="Title" />
                <TH label="Status" />
                <TH label="Actions" />
              </TableRow>
            </THead>
            <TBody>
              {workflows.map(workflow => (
                <TableRow key={workflow.id} state={workflow.isEnabled ? null : 'muted'}>
                  <TD compact>
                    <div>
                      <p className='caption'>
                        {workflow.name}
                      </p>
                      <p className='caption' style={{ color: 'var(--color-gray-75)' }}>
                        Version {workflow.version}
                      </p>
                    </div>
                    <Button
                      variant="secondary"
                      size="xs"
                      onClick={() => {
                        if (workflow.slug) {
                          openWorkflowPane({ workflowSlug: workflow.slug });
                        }
                      }}
                    >
                      Edit workflow
                    </Button>
                  </TD>
                  <TD compact>
                    <Switch
                      isChecked={workflow.isEnabled}
                      name="isEnabled"
                      label={workflow.isEnabled ? 'Enabled' : 'Disabled'}
                      onChange={async (isSet) => {
                        await updateWorkflowFn({
                          updates: { isEnabled: isSet },
                          workflowId: workflow.id,
                        });
                        await handleSave();
                      }}
                    />
                  </TD>
                  <TD compact>
                    <SecondaryActions
                      ariaLabel="Actions"
                      position="static"
                      hideTooltip
                      options={[
                        {
                          destructive: true,
                          icon: TrashSimple,
                          value: 'Archive workflow',
                        },
                        // {
                        //   destructive: false,
                        //   icon: CopySimple,
                        //   value: 'Clone workflow',
                        // },
                      ]}
                      onSelect={value => {
                        switch (value) {
                        case 'Archive workflow':
                          handleArchiveWorkflow(workflow.id);
                          break;
                        case 'Clone workflow':
                          handleCloneWorkflow(workflow.id);
                          break;
                        default:
                          break;
                        }
                      }}
                      compact
                    />
                  </TD>
                </TableRow>
              ))}
            </TBody>
          </Table>
        )}
      </CollapsibleArea>
      <CollapsibleArea
        title="Custom Terms"
        Icon={{
          Component: Signature,
        }}
      >
        <p className={s.description}>Require the customer to agree to terms before purchasing</p>
        <Button
          variant="primary"
          size="sm"
          href={getUrl(IDENTIFIERS.DASHBOARD_PRODUCT_TERMS, { productId: product.id })}
          className={s.buttonAdd}
        >
          {!product.prices.find(p => p.product.customTermsTemplate?.id) && <Plus size={16} />}
          {product.prices.find(p => p.product.customTermsTemplate?.id) !== undefined ? 'Edit' : 'Add'} custom terms
        </Button>
      </CollapsibleArea>
      {addStripeAccountModalOpen && (
        <AddStripeAccountModal onClose={() => setAddStripeAccountModalOpen(false)} onCreateAccount={handleStripeAccount} />
      )}
      {isCreateEditPriceModalOpen && product.creator?.slug && product.productTypes[0]?.noodleProductType && (
        <CreateEditPriceModal
          currency={product.creator.defaultCurrency || Currency.Usd}
          setIsVisible={e => {
            setCreateEditPriceModalOpen(e);
            setSelectedPrice(null);
          }}
          onPublish={handleCreatePriceSave}
          price={selectedPrice}
          productId={product.id}
          productTitle={product.title ?? null}
          productType={product.productTypes[0]?.noodleProductType}
          defaultCurrency={product.creator.defaultCurrency || null}
          businessCategory={product.creator.businessCategory || 'Other'}
        />
      )}
      {isPaymentLinkModalOpen && product.creator && (
        <PaymentLinkModal
          creator={product.creator}
          onClose={() => setPaymentLinkModalOpen(false)}
          creatorSlug={product.creator.slug}
          priceId={createdPriceId}
          product={product}
          paymentLink={
            createdPriceId
              ? `${SELF_ORIGIN}${getUrl(IDENTIFIERS.PRODUCT_PURCHASE, {
                creatorSlug: product.creator.slug,
                priceId: createdPriceId,
                productSlug: product.slug,
              })}`
              : undefined
          }
          creatorProducts={[]}
          recentlyUsedPrices={[]}
        />
      )}
    </Fragment>
  );
};

export default ServiceDetails;
