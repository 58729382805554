import { FC } from 'react';
import TrashSimple from '@components/Icons/TrashSimple';
import TimeSelect from '../TimeSelect';
import s from './SelectionBlock.module.scss';

type SelectionBlockProps = {
  from: string;
  setFrom: (time: string) => void;
  to: string;
  setTo: (time: string) => void;
  removeBlock: () => void;
  after?: string;
};

const SelectionBlock: FC<SelectionBlockProps> = ({
  from,
  setFrom,
  to,
  setTo,
  removeBlock,
  after,
}) => (
  <div className={s.selectionblock__wrapper}>
    <TimeSelect
      selectedTime={from}
      setSelectedTime={setFrom}
      type="from"
      otherTime={to}
      after={after}
    />
    <TimeSelect
      selectedTime={to}
      setSelectedTime={setTo}
      type="to"
      otherTime={from}
      after={after}
    />
    <button
      className={s['selectionblock__remove-button']}
      onClick={() => {
        removeBlock();
      }}
    >
      <div>
        <TrashSimple weight="fill" size={16} />
      </div>
    </button>
  </div>
);

export default SelectionBlock;
