const GoogleCalendar: React.FC = () => (
  <svg
    fill="none"
    height="20"
    viewBox="0 0 20 20"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <clipPath id="a"><path d="m0 0h20v20h-20z"/></clipPath>
    <g clipPath="url(#a)">
      <path d="m15.2631 4.73675-4.7368-.5263-5.78945.5263-.5264 5.2632.5263 5.26315 5.2632.6579 5.26315-.6579.5263-5.39465z" fill="#fff"/><path d="m6.89604 12.9026c-.3934-.2658-.6658-.6539-.8145-1.1671l.9132-.3763c.0829.3158.2276.5605.4342.7342.2053.1737.4553.2592.7474.2592.2987 0 .5553-.0908.7697-.2724s.3224-.4132.3224-.6934c0-.2868-.1132-.5211-.3395-.7026s-.5105-.2724-.85-.2724h-.5276v-.90392h.4736c.2921 0 .5382-.0789.7382-.2368s.3-.3737.3-.6487c0-.2447-.0895-.4395-.2684-.5855s-.4053-.2197-.6803-.2197c-.2684 0-.4816.0711-.6395.2145s-.2724.3197-.3447.5276l-.9039-.3763c.1197-.3395.3395-.6395.6618-.8987.3224-.2592.7342-.3895 1.2342-.3895.3697 0 .7026.0711.9974.2145.2947.1434.5263.3421.6934.5947.1671.2539.24996.5382.24996.8539 0 .3224-.07756.5947-.23286.8184s-.3461.3947-.5724.5145v.0539c.2987.12502.5421.31582.7342.57242.19076.2566.28676.5632.28676.9211s-.0908.6776-.2724.9579c-.18156.2803-.43286.5013-.75126.6618-.3197.1605-.6789.2421-1.0776.2421-.4618.0013-.8881-.1316-1.2815-.3974z" fill="#006dff"/>
      <path d="m12.5002 8.37111-.9974.725-.5013-.7605 1.7987-1.2974h.6895v6.11969h-.9895z" fill="#006dff"/>
      <path d="m15.263 20 4.7368-4.7368-2.3684-1.0526-2.3684 1.0526-1.0526 2.3684z" fill="#ea4335"/>
      <path d="m3.68408 17.6316 1.0526 2.3684h10.52632v-4.7368h-10.52632z" fill="#34a853"/>
      <path d="m1.5789 0c-.8723 0-1.5789.7066-1.5789 1.5789v13.6842l2.3684 1.0526 2.3684-1.0526v-10.5263h10.5263l1.0526-2.3684-1.0525-2.3684z" fill="#4285f4"/>
      <path d="m0 15.2632v3.1579c0 .8724.7066 1.5789 1.5789 1.5789h3.1579v-4.7368z" fill="#188038"/>
      <path d="m15.2632 4.7368v10.5263h4.7368v-10.5263l-2.3684-1.0526z" fill="#fbbc04"/>
      <path d="m20 4.7368v-3.1579c0-.8724-.7066-1.5789-1.5789-1.5789h-3.1579v4.7368z" fill="#1967d2"/>
    </g>
  </svg>
);

export default GoogleCalendar;
