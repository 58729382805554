import LoggedInUserGate from '@components/Auth/LoggedInUserGate';
import CreditReportDetails from './CreditReportDetails';

type Props = Omit<Parameters<typeof CreditReportDetails>[0], 'children'>;

const CreditReportWithAccessProvider: React.FC<Props> = (props) => (
  <LoggedInUserGate isAnonymousAllowed={false}>
    <CreditReportDetails {...props} />
  </LoggedInUserGate>
);

export default CreditReportWithAccessProvider;
