/* eslint-disable camelcase */
import React, { ReactElement } from 'react';

import classNames from 'classnames';
import { PaymentMethod as StripePaymentMethod } from '@stripe/stripe-js';
import { getUserSubscriptions } from '@tsClient';
import { CardDetailsCurrentUser } from './components/CardDetails';
import s from './PaymentMethods.module.scss';

type Props = {
  creatorSlug: string;
  isAttachCard?: boolean;
  isChoosePayment?: boolean;
  isSubscriptionActive?: boolean;
  handleRemovePaymentMethod?: (id: string) => Promise<void> | void;
  method: Pick<StripePaymentMethod, 'id'> & {
    card?: Parameters<typeof CardDetailsCurrentUser>[0]['card'];
  } | null;
  handleChangeDefaultPaymentMethod?: (id: string) => Promise<void> | void;
  subscription?: Awaited<ReturnType<typeof getUserSubscriptions>>[0];
  retryPayment: (id: string) => Promise<void>;
};

const PaymentMethods = ({
  method,
  isAttachCard = false,
  creatorSlug,
  subscription,
  retryPayment,
}: Props): ReactElement => (
  <div className={classNames(s.payments__wrapper, {[s['payments__wrapper-full']]: isAttachCard})}>
    {!isAttachCard && <h5>Payment Method</h5>}
    <div className={classNames(s.payments__list, {[s['payments__list-full']]: isAttachCard})}>
      {method && (
        <CardDetailsCurrentUser
          key={method.id}
          card={method.card || null}
          creatorSlug={creatorSlug}
          isPrimary
          subscription={subscription}
          retryPayment={retryPayment}
        />
      )}
    </div>
  </div>
);

export default PaymentMethods;
