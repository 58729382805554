import { FC, useState } from 'react';
import { Invoice } from '@typings/api-models';
import * as format from '@format';
import { m } from 'framer-motion';
import Info from '@components/Icons/Info';
import s from './InvoiceProgressTooltip.module.scss';

type Props = {
  invoice: Pick<Invoice, 'amountPaid' | 'amountTotal' | 'amountPending' | 'amountDue' | 'currency'>;
};

const InvoiceProgressTooltip: FC<Props> = ({ invoice }) => {
  const [hovered, setHovered] = useState(false);
  return (
    <div>
      <m.div className={s.progress} onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
        <div className={s.info}>
          <Info weight="fill" size={12} color="var(--color-gray-100)" />
          <p className="caption">Remaining Balance: {format.price.withCurrency(invoice.amountDue, invoice.currency)}</p>
        </div>
        <progress value={invoice.amountPaid} max={invoice.amountTotal} />
      </m.div>
      <m.ul
        className={s.tooltip}
        initial={{ opacity: 0, y: 8 }}
        animate={{ opacity: hovered ? 1 : 0, y: hovered ? 4 : 8 }}
        transition={{ duration: 0.1 }}
      >
        <li>
          Invoice Total <b>{format.price.withCurrency(invoice.amountTotal, invoice.currency)}</b>
        </li>
        <li>
          Payments Made <b>{format.price.withCurrency(invoice.amountPaid + invoice.amountPending, invoice.currency)}</b>
        </li>
        <li>
          Remaining Balance <b>{format.price.withCurrency(invoice.amountDue, invoice.currency)}</b>
        </li>
      </m.ul>
    </div>
  );
};

export default InvoiceProgressTooltip;
