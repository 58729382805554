import React, { FC, useState } from 'react';
import commonStyle from '@modals/ModalsCommon.module.scss';
import Modal from '@components/Modal';
import TextInput from '@components/FormFields/TextInput';
import PriceInput from '@components/FormFields/PriceInput';
import InputNumberWithControls from '@components/FormFields/InputNumberWithControls';
import Button from '@components/DesignLibrary/Button';
import { nanoid } from 'nanoid';
import Tabs from '@components/DesignLibrary/Tabs';
import PriceSelector from '@components/PriceSelector';
import CheckBox from '@components/DesignLibrary/Atoms/CheckBox';
import Spacer from '@components/Spacer';
import s from './LineItemModal.module.scss';

export type LineItem = {
  customTermsVariableName: string | null;
  id: string;
  item: string;
  price: number | null;
  description: string | null;
  quantity: number;
};

type Props = {
  onClose: () => void;
  currency: string;
  onSubmit: (l: LineItem) => void;
  lineItem?: LineItem;
  title?: string;
};

const LineItemModal: FC<Props> = ({ currency, onClose, onSubmit, title, lineItem: initialLineItem }) => {
  const [lineItem, setLineItem] = useState(
    initialLineItem || {
      customTermsVariableName: null,
      description: null,
      id: nanoid(),
      item: '',
      price: 0,
      quantity: 1,
    },
  );
  const [lineItemType, setLineItemType] = useState<'Custom' | 'Price'>('Custom');
  const [selectedPriceId, setSelectedPriceId] = useState('');
  const [needsPrice, setNeedsPrice] = useState(!(lineItem.price === null && lineItem.quantity === 1));

  const handleChange = (key: string, value: string | number | null): void => {
    setLineItem(prev => ({
      ...prev,
      [key]: value,
    }));
  };

  const handlePriceSelect: Parameters<typeof PriceSelector>[0]['onPriceSelect'] = price => {
    setLineItem(prev => ({
      ...prev,
      description: price.priceDescription?.text ?? null,
      id: nanoid(),
      item: price.priceTitle || price.product?.title || 'Item',
      price: price.price,
      quantity: 1,
    }));
    setSelectedPriceId(price.id);
  };

  const handleToggleEmtpyPrice: Parameters<typeof CheckBox>[0]['onChange'] = (newIsEmpty) => {
    setNeedsPrice(!newIsEmpty);
    const newValues = newIsEmpty
      ? { price: null, quantity: 1 }
      : { price: 0, quantity: 1 };
    setLineItem({
      ...lineItem,
      ...newValues,
      description: lineItem.description,
    });
  };

  return (
    <Modal title={title} onClose={onClose} >
      <div className={commonStyle.container}>
        <Tabs
          inputName="lineItemType"
          inputs={[
            {
              id: 'Custom',
              label: 'Custom line item',
            },
            {
              id: 'Price',
              label: 'Select price',
            },
          ]}
          onChange={value => {
            setLineItem(lineItem);
            setLineItemType(value);
          }}
        />
        {lineItemType === 'Custom' && (
          <div className={s.form}>
            <TextInput label="Item description" id="item" onChange={handleChange.bind(null, 'item')} value={lineItem.item} />

            <CheckBox
              label="This line item does not yet have an amount"
              isChecked={!needsPrice}
              onChange={handleToggleEmtpyPrice}
            />

            <Spacer size={4} />
            <PriceInput
              label="Price"
              name="price"
              value={lineItem.price}
              onChange={newPrice => handleChange('price', newPrice)}
              currency={currency}
              isNullable={true}
              disabled={!needsPrice}
            />
            <Spacer size={16} />
            <InputNumberWithControls
              disabled={!needsPrice}
              value={lineItem.quantity}
              onChange={handleChange.bind(null, 'quantity')}
              required
              label="Quantity"
            />
          </div>
        )}
        {lineItemType === 'Price' && (
          <div>
            <PriceSelector selectedPrice={{ id: selectedPriceId }} onPriceSelect={handlePriceSelect} />
          </div>
        )}
        <TextInput label="Custom terms variable name" id="customTermsVariableName" onChange={handleChange.bind(null, 'customTermsVariableName')} value={lineItem.customTermsVariableName || ''} />
        <div className={s.button}>
          <Button disabled={!lineItem.item || !lineItem.quantity} variant="primary" size="md" fullWidth onClick={() => onSubmit(lineItem)}>
            Submit
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default LineItemModal;
