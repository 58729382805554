import ListAndDetailsSubLayout, {
  addHeaderKeyToCell,
  type ListViewRegularItem,
  type TableRow,
  type TableColumn,
  type TableCell,
} from '@layouts/ListAndDetailsSubLayout';

export type ResourceType = 'root' | 'activity' | 'my-workflows' | 'templates' | 'my-workflows-v2' | 'attachments';

export type HookResponse = Pick<Parameters<typeof ListAndDetailsSubLayout>[0],
  | 'items'
  | 'itemsCount'
  | 'filters'
  | 'pagination'
  | 'selectedId'
  | 'isFetchingList'
  | 'ifNoItemsText'
  | 'getDetailPagePath'
  | 'onSearch'
  | 'tableView'
  | 'openTableView'
>;

type ListItem = HookResponse['items'][number];

export const PAGE_SIZE = 25;

export type {
  ListViewRegularItem,
  TableRow,
  TableCell,
  TableColumn,
  ListItem,
};
export { addHeaderKeyToCell };