import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import axios, { CancelTokenSource } from 'axios';
import { Upload } from "tus-js-client";

import { deleteFile, getMediaData } from '@tsClient';
import { logError } from '@providers/ErrorTracking';
import { MAX_FILE_SIZE, NOODLE_MEDIA_UPLOAD_HOST } from '@configuration/client';
import { Media, MediaStatus } from '@typings/graphql-models';
import Buttons from '@components/Buttons';
import DownloadSimple from '@components/Icons/DownloadSimple';
import XCircle from '@components/Icons/XCircle';
import Microphone from '@components/Icons/Microphone';
import VideoCamera from '@components/Icons/VideoCamera';
import File from '@components/Icons/File';
import MediaLoader from '@components/MediaLoader';
import Image from '@components/Image';
import getMediaUrl from '@helpers/getMediaUrl';
import { useJobContext, Job, JobType } from '@providers/Jobs';
import AsyncJob from '@providers/Jobs/JobsContainer/AsyncJob';
import { mixpanelTrack } from '@providers/Mixpanel';
import { useUser } from "@providers/Auth";
import isMediaReadyEnough from '@helpers/isMediaReadyEnough';
import promiseRetry from 'promise-retry';
import s from './InputComposer.module.scss';

const getS3OriginIdFromUrl = (url?: string | null): string | null => {
  if (!url) {
    return null;
  }

  return url?.replace(/.*\//, '').replace(/\+.*/, '');
};

type Props = {
  media?: { id: string; name?: string | null; };
  file: string | File;
  onUploadFile: (file: { mediaId: string; name: string; type: string; }) => void;
  removeFile: () => void;
  removeMedia: (mediaId: string) => void;
  jobCorrelationId?: string;
  referenceId: string;
  referenceType: string;
  isAsync?: boolean;
};

const MAX_FILE_SIZE_BYTES = Number(MAX_FILE_SIZE) * 1024 * 1024;

type JobRef = {
  isUploading?: boolean;
  job?: Job;
  media?: Media;
};

type UploadRes = { id: string } | null;

type ThisMedia = {
  id: string;
  vimeoUri?: string;
  vimeoThumbnailUrl?: string;
  name?: string | null;
  type?: string;
  mediaId: string;
  s3OriginId?: string;
  s3Id?: string;
  url?: string;
};

const FileAttachment = ({
  media: initialMedia,
  file,
  onUploadFile,
  removeFile,
  removeMedia,
  jobCorrelationId,
  referenceId,
  referenceType,
  isAsync,
}: Props): ReactElement => {
  const { jobs, addJob, updateJob } = useJobContext();
  const [cancelToken, setCancelToken] = useState<CancelTokenSource | null>(null);
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [uploadError, setUploadError] = useState<Error | null>(null);
  const [blobUrl, setBlobUrl] = useState<string | null>(null);
  const [media, setMedia] = useState<ThisMedia | null>(initialMedia ? { ...initialMedia, mediaId: initialMedia.id } : null);
  const jobRef: JobRef = useMemo(() => ({}), []);
  const fileName = (typeof file === 'string' ? file : file.name);
  const [user] = useUser();

  const renderAttachment = (): ReactElement | null => {
    if (media?.vimeoUri) {
      return media?.vimeoThumbnailUrl
        ? (
          <Image
            alt={media?.name || undefined}
            className={s['media-attachments__image']}
            image={media?.vimeoThumbnailUrl}
          />
        )
        : (
          <div
            className={s['media-attachments__progress']}
          >
            <VideoCamera size={16} weight='fill' color='var(--color-primary)' />
          </div>
        );
    } if (media?.type?.includes('audio')) {
      return (
        <div
          className={s['media-attachments__progress']}
        >
          <Microphone size={16} weight='fill' color='var(--color-primary)' />
        </div>
      );
    } if (media?.type?.includes('image')) {
      return <Image
        alt={media?.name || undefined}
        className={s['media-attachments__image']}
        image={getMediaUrl(media)}
      />;
    }
    return (
      <div
        className={s['media-attachments__progress']}
      >
        <File size={16} weight='fill' color='var(--color-primary)' />
      </div>
    );
  };

  const remove = (): void => {
    mixpanelTrack('InputComposer - file removed', { fileName });
    cancelToken?.cancel();
    removeFile();
    if (media) {
      deleteFile({ mediaId: media.mediaId });
      removeMedia(media.mediaId);
      setMedia(null);
    }
  };

  const trackDownload = (): void => {
    mixpanelTrack('InputComposer - file downloaded', { fileName });
  };

  const uploadFn = async (): Promise<{ id: string } | null> => {
    setUploadError(null);
    const cancelTokenSource = axios.CancelToken.source();
    setCancelToken(cancelTokenSource);
    if (jobRef.job?.id) {
      updateJob({
        id: jobRef.job.id,
        onCancel: (): void => {
          cancelTokenSource.cancel();
        },
        onRemove: (): void => {
          removeFile();
          if (media) {
            removeMedia(media.mediaId);
            setMedia(null);
          }
        },
      });
    }
    const upload = async (fileToUpload: File | Blob): Promise<UploadRes> => {
      let res:UploadRes = null;
      mixpanelTrack('InputComposer - file added', { fileName, fileType: fileToUpload?.type });
      setUploadPercentage(1);
      let isGettingMediaDetails = false; // hacky debounce
      try {
        const mediaRes: UploadRes = await new Promise((resolveUpload, rejectUpload) => {
          const headers: ConstructorParameters<typeof Upload>[1]['headers'] = {};
          if (user && user.token) {
            headers.authorization = user.token;
          }

          const tusUpload = new Upload(fileToUpload, {
            endpoint: `${NOODLE_MEDIA_UPLOAD_HOST}/uploads`,
            headers,
            metadata: {
              contentType: fileToUpload.type,
              filename: fileName,
              filetype: fileToUpload.type,
              // just to give context when looking at log insights
              referenceId,
              referenceType,
            },
            onError: (error) => {
              // eslint-disable-next-line no-console
              console.log(`Failed because: ${error}`);
              setUploadError(error as Error);
              logError(error, { tags: { flow: 'chat' } });
              setUploadPercentage(0);
              if (media) {
                removeMedia(media.mediaId);
              }
              rejectUpload(error);
            },
            onProgress: async (bytesUploaded, bytesTotal) => {
              const newUploadPercentage = (bytesUploaded / bytesTotal * 100).toFixed(2);
              // eslint-disable-next-line no-console
              console.log('tus upload percentage: ', newUploadPercentage);
              setUploadPercentage(+newUploadPercentage);
              if (jobRef.job) {
                jobRef.job.setProgress(+newUploadPercentage);
              }

              if (!jobRef.media && jobRef.job?.id && !isGettingMediaDetails) {
                const s3OriginId = getS3OriginIdFromUrl(tusUpload?.url);
                if (s3OriginId) {
                  isGettingMediaDetails = true;
                  try {
                    const mediaData = await getMediaData({ s3OriginId });

                    if (!mediaData.id) {
                      throw new Error(`getMediaData returned falsey: ${s3OriginId}`);
                    }
                    jobRef.media = mediaData;
                    updateJob({
                      id: jobRef.job.id,
                      uploadMedia: mediaData,
                    });
                  } catch (_error) { /* */ }
                  isGettingMediaDetails = false;
                }
              }
            },
            onSuccess: async () => {
              // eslint-disable-next-line no-console
              console.log("Download %s from %s", (tusUpload.file as File)?.name || 'unknown', tusUpload.url);
              const s3OriginId = getS3OriginIdFromUrl(tusUpload?.url);

              try {
                if (!s3OriginId) {
                  throw new Error(`Could not extract s3OriginId from ${tusUpload.url}`);
                }
                const mediaData = await promiseRetry(async (retry) => {
                  try {
                    const response = await getMediaData({ s3OriginId });
                    if (response.mediaStatus === MediaStatus.Error) {
                      throw new Error('Upload failed');
                    }
                    if (!isMediaReadyEnough(response)) {
                      throw new Error(`Media with id ${s3OriginId} not found`);
                    }
                    return response;
                  } catch(error) {
                    return retry(error);
                  }
                }, {
                  factor: 1,
                  minTimeout: 1000,
                  retries: 30,
                });

                if (!jobRef.media && jobRef.job?.id) {
                  jobRef.media = mediaData;
                  updateJob({
                    id: jobRef.job.id,
                    uploadMedia: mediaData,
                  });
                }

                setMedia({
                  ...mediaData,
                  mediaId: mediaData.id,
                  s3Id: mediaData.s3Id || '',
                  s3OriginId: mediaData.s3OriginId || '',
                  type: mediaData.type || undefined,
                  url: mediaData.url || '',
                  vimeoThumbnailUrl: mediaData.vimeoThumbnailUrl ? mediaData.vimeoThumbnailUrl : undefined,
                  vimeoUri: mediaData.vimeoUri ? mediaData.vimeoUri : undefined,
                });
                onUploadFile({ mediaId: mediaData.id, name: fileName, type: fileToUpload.type });
                resolveUpload({ id: mediaData.id });
              } catch(error) {
                setUploadError(error as unknown as Error);
                logError(error, { tags: { flow: 'chat' } });
                rejectUpload(error);
              }
            },
            retryDelays: [0, 3000, 5000, 10000, 20000],
          });
          if (jobRef.job?.id) {
            updateJob({
              id: jobRef.job.id,
              onCancel: (): void => {
                tusUpload.abort();
              },
              onRemove: (): void => {
                removeFile();
                if (media) {
                  removeMedia(media.mediaId);
                  setMedia(null);
                }
              },
            });
          }
          tusUpload.start();
        });
        res = mediaRes;

        return res;
      } catch (err) {
        logError(err, { tags: { flow: 'chat' } });
        setUploadPercentage(0);
        setUploadError(err as Error);
        return null;
      }
    };

    let blobFile;

    if (typeof file === 'string') {
      const r = await fetch(file);
      blobFile = await r.blob();
    } else {
      blobFile = file;
    }

    const { size } = blobFile;

    if (!size) {
      const error = new Error('File is not found, Try uploading again!');
      setUploadError(error);
      logError(error, { fileType: typeof file === 'string' ? 'string' : 'blob', size, tags: { flow: 'chat' } });
      if (isAsync) {
        throw error;
      }
      return null;
    }

    if (size > MAX_FILE_SIZE_BYTES) {
      const error = new Error(`Max file size is ${MAX_FILE_SIZE}mb. Try uploading a shorter video!`);
      setUploadError(error);
      logError(error, { MAX_FILE_SIZE, fileType: typeof file === 'string' ? 'string' : 'blob', level: 'info', size, tags: { flow: 'chat' } });
      if (isAsync) {
        throw error;
      }
      return null;
    }

    setBlobUrl(URL.createObjectURL(blobFile));
    return upload(blobFile);
  };

  useEffect(() => {
    if (!media && !jobRef.isUploading) {
      jobRef.isUploading = true;
      if (isAsync) {
        jobRef.job = addJob({
          correlationId: jobCorrelationId,
          function: uploadFn,
          mediaType: typeof file === 'string' ? 'file' : file.type,
          title: fileName,
          type: JobType.MESSAGE_MEDIA_UPLOAD,
        });
      } else {
        uploadFn();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [media]);

  const asyncJob = jobs.find((j:Job) => j.id === jobRef?.job?.id);

  if (asyncJob && isAsync) {
    return (<AsyncJob job={asyncJob} isSmall />);
  }

  return (
    <div className={s['media-attachments-container']}>
      <div>
        {!media && uploadPercentage > 0 && (
          <div style={{ alignItems: 'center', display: 'flex' }}>
            <div title={typeof file === 'string' ? 'Upload' : file.name} key={typeof file === 'string' ? file : file.name} className={s['media-attachments__item']}>
              <MediaLoader isSmall className={s['media-attachments__shimmer']} />
            </div>
            <span className='caption' style={{ color: 'var(--color-gray-75)', marginLeft: 16 }}>Uploading ({uploadPercentage}%)</span>
          </div>
        )}
        {uploadError && (
          <div style={{ alignItems: 'center', display: 'flex' }}>
            <div title={typeof file === 'string' ? 'Upload' : file.name} key={typeof file === 'string' ? file : file.name} className={s['media-attachments__item']}>
              <MediaLoader isSmall className={s['media-attachments__shimmer']} />
            </div>
            <span className='caption' style={{ color: 'var(--color-error)', marginLeft: 16 }}>{uploadError.message}</span>
          </div>
        )}
        {media && !uploadError && (
          <div style={{ alignItems: 'center', display: 'flex' }}>
            <div title={media.name || undefined} key={media.s3OriginId} className={s['media-attachments__item']}>
              {renderAttachment()}
            </div>
            <span className='caption' style={{ marginLeft: 16 }}>{media.name}</span>
          </div>
        )}
      </div>
      <div style={{ alignItems: 'center', display: 'flex' }}>
        {uploadError && (
          <Buttons isWrapper className={s.retry} onClick={uploadFn}>
            Try again
          </Buttons>
        )}
        {(uploadError || media) && blobUrl && (
          <a className={s.download} href={blobUrl} onClick={trackDownload} download={typeof file !== 'string' ? file.name : media?.name}>
            <DownloadSimple weight='fill' color='var(--color-gray-100)' size={20} />
          </a>
        )}
        <Buttons
          onClick={remove}
          icon={<XCircle weight='fill' size={16} color={'var(--color-error)'} />}
        />
      </div>
    </div>
  );
};

export default FileAttachment;
