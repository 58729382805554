import React, { FC, useContext, useState, useEffect } from 'react';
import { AnimatePresence, m } from 'framer-motion';
import XCircle from '@components/Icons/XCircle';
import Buttons from '@components/Buttons';
import TeamsContext from '@providers/Teams/TeamsContext';
import useNoodleApi from '@hooks/useNoodleApi';
import * as tsClient from '@tsClient';
import ProgressIndicator from '@components/ProgressIndicator';
import ChatDropDown from '@components/Chat/ChatDropDown';
import * as ApiModels from '@typings/api-models';
import OutsideClick from '@components/OutsideClick';
import HandCoins from '@components/Icons/HandCoins';
import s from './InputComposer.module.scss';
import SearchInput from '../FormFields/SearchInput';

type Props = {
  onAttach: (data: { title: string; invoiceTemplateId: string }) => void;
};

const AddInvoice: FC<Props> = ({ onAttach }) => {
  const { creatorId } = useContext(TeamsContext);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [search, setSearch] = useState('');
  const {
    data: invoiceTemplates,
    getData: getInvoiceTemplates,
    fetchingState: { isFetching: isFetchingInvoiceTemplates },
  } = useNoodleApi(tsClient.invoices.getInvoiceRequestTemplates);

  useEffect(() => {
    if (creatorId) {
      getInvoiceTemplates({ creatorId, page: 1, perPage: 1000 });
    }
  }, [creatorId, getInvoiceTemplates]);

  const toggle = (): void => {
    setPopoverOpen(prev => !prev);
  };

  const handleClick = (template: Pick<ApiModels.InvoiceTemplate, 'id' | 'title'>): void => {
    setPopoverOpen(false);
    onAttach({ invoiceTemplateId: template.id, title: template.title });
  };

  if (invoiceTemplates?.items.length === 0) {
    return null;
  }

  return (
    <>
      <Buttons
        ariaLabel={'Insert Payment Link'}
        iconBefore={
          <m.div
            style={{
              alignItems: 'center',
              display: 'flex',
              height: '16px',
              justifyContent: 'center',
              width: '16px',
              willChange: 'transform',
            }}
            whileTap={{ scale: 0.9 }}
          >
            {popoverOpen
              ? (
                <XCircle weight="fill" color="var(--color-gray-50)" size={16} />
              )
              : (
                <HandCoins size={16} weight="fill" color="var(--color-primary)" />
              )}
          </m.div>
        }
        onClick={toggle}
      >
        Invoice
      </Buttons>
      <AnimatePresence>
        {popoverOpen && (
          <OutsideClick onOutsideClick={() => setPopoverOpen(false)} className={s.outsideClick}>
            <ChatDropDown>
              {!invoiceTemplates || isFetchingInvoiceTemplates
                ? (
                  <ProgressIndicator isCentered />
                )
                : (
                  <>
                    <SearchInput label="Filter by title" placeholder="Type a title..." setSearch={setSearch} className={s.search} />
                    {invoiceTemplates.items
                      .filter(template => template.title.toLowerCase().includes(search.toLowerCase()))
                      .map(template => (
                        <Buttons className={s.documentRequestButton} key={template.id} isWrapper isFlat onClick={() => handleClick(template)}>
                          <HandCoins size={14} weight="fill" />
                          <p>
                            {template.title}
                          </p>
                        </Buttons>
                      ))}
                  </>
                )}
            </ChatDropDown>
          </OutsideClick>
        )}
      </AnimatePresence>
    </>
  );
};

export default AddInvoice;
