import { FC, useEffect, useRef, useState } from 'react';
import Modal from '@components/Modal';
import { useToast } from '@hooks';
import PriceInput from '@components/FormFields/PriceInput';
import CustomSelect from '@components/FormFields/CustomSelect';
import DatePicker from '@components/FormFields/DatePicker';
import commonStyles from '@modals/ModalsCommon.module.scss';
import * as format from '@format';
import Spacer from '@components/Spacer';
import Button from '@components/DesignLibrary/Button';
import SelectPaymentMethod, { SelectPaymentMethodRef } from '@components/SelectPaymentMethod';
import * as ApiModels from '@typings/api-models';
import * as tsClient from '@tsClient';
import useNoodleApi from '@hooks/useNoodleApi';
import StripeElementsProvider from '@providers/StripeElements';
import getProcessingFees from '@tsClient/getProcessingFees';
import SelectConfidoPaymentMethod from '@components/SelectConfidoPaymentMethod';
import ProgressIndicator from '@components/ProgressIndicator';
import s from './PaymentPlanModal.module.scss';

type Props = {
  invoice: Pick<ApiModels.Invoice, 'id' | 'currency' | 'isAchPaymentEnabled' | 'isDebitCardPaymentEnabled' | 'isCreditCardPaymentEnabled' | 'isDebitCardPaymentRequiredForCustomer' | 'creatorId' | 'amountDue' | 'passProcessingFeesToCustomer'> & {
    person: Pick<ApiModels.Person, 'id' | 'name'>;
    paymentPlan: Pick<ApiModels.PaymentPlan, 'id' | 'amount' | 'paymentMethodId' | 'nextPaymentDate' | 'frequency'> | null;
  };
  onClose: () => void;
  creatorSlug: string;
  refetchData: () => Promise<void>;
};

export const FREQUENCY_OPTIONS: { key: string; title: string; value: ApiModels.PaymentPlan['frequency'] }[] = [
  {
    key: 'monthly',
    title: 'Every month',
    value: 'monthly',
  },
  {
    key: 'biweekly',
    title: 'Biweekly',
    value: 'biweekly',
  },
  {
    key: 'weekly',
    title: 'Every week',
    value: 'weekly',
  },
];

const PaymentPlanModal: FC<Props> = ({ creatorSlug, onClose, invoice, refetchData }) => {
  const addToast = useToast();

  const [amount, setAmount] = useState(invoice.paymentPlan?.amount || 100);
  const [frequency, setFrequency] = useState<ApiModels.PaymentPlan['frequency']>(invoice.paymentPlan?.frequency || FREQUENCY_OPTIONS[0].value);
  const [date, setDate] = useState<string | null>(invoice.paymentPlan?.nextPaymentDate || null);
  const [screen, setScreen] = useState<'Settings' | 'PaymentMethod'>('Settings');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitButtonEnabled, setIsSubmitButtonEnabled] = useState(false);

  const paymentMethodRef = useRef<SelectPaymentMethodRef>();

  const { getData: updatePaymentPlanFn } = useNoodleApi(tsClient.invoices.updatePaymentPlan);
  const { getData: createPaymentPlanFn } = useNoodleApi(tsClient.invoices.createPaymentPlan);
  const { getData: getCreatorFn, data: creator } = useNoodleApi(tsClient.getCreator);
  const { data: processingFees, getData: getProcessingFeesFn } = useNoodleApi(getProcessingFees);
  const { data: paymentMethodToken, getData: getConfidoPaymentMethodTokenFn } = useNoodleApi(tsClient.creators.getConfidoPaymentMethodToken);

  const { passProcessingFeesToCustomer } = invoice;

  useEffect(() => {
    if (passProcessingFeesToCustomer && amount && creator) {
      const getProcessingFeesForPrice = async (): Promise<void> => {
        await getProcessingFeesFn({
          amount,
          isConfido: Boolean(creator?.confidoFirmId),
        });
      };
      getProcessingFeesForPrice();
    }
  }, [creator, amount, passProcessingFeesToCustomer, getProcessingFeesFn]);

  useEffect(() => {
    getCreatorFn({ creatorSlug });
  }, [creatorSlug, getCreatorFn]);

  useEffect(() => {
    if (creator && screen === 'PaymentMethod' && creator.confidoFirmId) {
      getConfidoPaymentMethodTokenFn({ creatorId: creator.id, forUserId: invoice.person.id });
    }
  }, [screen, creator?.confidoFirmId, creator?.id, getConfidoPaymentMethodTokenFn, invoice.person]);

  const handleSubmit = async (): Promise<void> => {
    if (!date) {
      return;
    }
    setIsSubmitting(true);
    const paymentMethodId = await paymentMethodRef.current?.getPaymentMethodId?.();
    if (!paymentMethodId) {
      addToast(useToast.ToastTypeVariants.ERROR, 'Failed to set payment method. Please try again');
      setIsSubmitting(false);
      return;
    }
    if (invoice.paymentPlan) {
      await updatePaymentPlanFn({
        amount,
        frequency,
        id: invoice.id,
        nextPaymentDate: date,
        paymentMethodId,
      });
    } else {
      await createPaymentPlanFn({
        amount,
        frequency,
        id: invoice.id,
        paymentMethodId,
        startDate: date,
      });
    }
    await refetchData();
    onClose();
  };

  return (
    <Modal
      hideBottomInset

      title='Set up your payment plan'
      onClose={onClose}

    >
      <div className={commonStyles.container} style={{ gap: 16, paddingTop: 40 }}>
        {screen === 'Settings' && (
          <>
            <p className='body-lg-bold' style={{ textAlign: 'center' }}>When can you make your next payment, and how much can you pay?</p>
            <Spacer size={1} />
            <PriceInput currency={invoice.currency} max={invoice.amountDue} name='amount' onChange={setAmount} value={amount} label='Amount' />
            <CustomSelect label='Frequency' value={frequency} id='frequency' onChange={newFrequency => setFrequency(newFrequency as ApiModels.PaymentPlan['frequency'])} options={FREQUENCY_OPTIONS} />
            <DatePicker min={new Date().toISOString().split('T')[0]} max={
              new Date(new Date().getTime() + 60 * 24 * 60 * 60 * 1000).toISOString().split('T')[0]
            } value={date} onChange={setDate} label='First Payment Date' />
            {amount > 0 && frequency && date && (
              <div className={s.summary}>
                <p className='body-md-bold'>Your next {format.price.withCurrency(amount, invoice.currency)} payment will be on {format.datetime.monthDay(date)}.</p>
                <Spacer size={8} />
                {passProcessingFeesToCustomer && processingFees && (
                  <>
                    <p className='caption'>A processing fee will be collected in addition to the amount above for each payment.</p>
                    <p className='caption'>Credit/Debit processing fee: {format.price.withCurrency(processingFees.USCardFee, invoice.currency)}</p>
                    <p className='caption'>Bank Account/ACH processing fee {format.price.withCurrency(processingFees.achFee, invoice.currency)}</p>
                  </>
                )}
                <Spacer size={16} />
                <p className='caption'>Then, you will be billed {frequency === 'biweekly' && 'every two weeks' || frequency === 'monthly' && 'every month' || 'every week'} automatically. Payments will cease when your invoice is paid in full.</p>
              </div>
            )}
            <Button
              variant='primary'
              size='md'
              fullWidth
              disabled={!amount || !frequency || !date}
              onClick={() => setScreen('PaymentMethod')}
            >
              Review payment method
            </Button>
          </>
        )}
        {screen === 'PaymentMethod' && (
          <>
            {creator?.confidoFirmId
              ? (
                <>
                  {paymentMethodToken
                    ? (
                      <SelectConfidoPaymentMethod
                        ref={paymentMethodRef}
                        isDebitCardPaymentEnabled={invoice.isDebitCardPaymentEnabled}
                        isDebitCardPaymentRequiredForCustomer={invoice.isDebitCardPaymentRequiredForCustomer}
                        isCreditPaymentEnabled={invoice.isCreditCardPaymentEnabled}
                        isAchPaymentEnabled={invoice.isAchPaymentEnabled}
                        onPaymentMethodAvailableChange={setIsSubmitButtonEnabled}
                        confidoPaymentMethodToken={paymentMethodToken}
                        person={invoice.person}
                        creator={creator}
                        selectedPaymentMethodId={invoice.paymentPlan?.paymentMethodId || null}
                      />
                    )
                    : <ProgressIndicator isCentered />}
                </>
              )
              : (
                <StripeElementsProvider creator={creator}>
                  <SelectPaymentMethod
                    ref={paymentMethodRef}
                    creatorSlug={creatorSlug}
                    creatorId={invoice.creatorId}
                    isDebitCardPaymentEnabled={invoice.isDebitCardPaymentEnabled}
                    isCreditPaymentEnabled={invoice.isCreditCardPaymentEnabled}
                    isAchPaymentEnabled={invoice.isAchPaymentEnabled}
                    setIsButtonEnabled={setIsSubmitButtonEnabled}
                    person={invoice.person}
                    selectedPaymentMethodId={invoice.paymentPlan?.paymentMethodId}
                  />
                </StripeElementsProvider>
              )}
            <Button
              variant='primary'
              size='md'
              fullWidth
              disabled={!isSubmitButtonEnabled}
              onClick={handleSubmit}
              loading={isSubmitting}
            >
              Save
            </Button>
          </>
        )}
      </div>
    </Modal>
  );
};

export default PaymentPlanModal;
