import useNoodleApi from '@hooks/useNoodleApi';
import * as tsClient from '@tsClient';
import * as ApiModels from '@typings/document-api-models';
import React, { useEffect, useMemo, useState } from 'react';
import ValidationStatusButton from './ValidationStatusButton';

type Props = {
  document: Pick<ApiModels.Document, 'id' | 'fileName' | 'createdAt' | 'uploadedBy' | 'mimeType' | 'uploadedAt'>;
};

const DocumentStatus: React.FC<Props> = ({document}:Props) => {
  const {
    data: validationData,
    getData: getDocumentValidation,
  } = useNoodleApi(tsClient.documents.getDocumentValidationById);
  const [lastRetrievedValidationData, setLastRetrievedValidationData] = useState(validationData);

  useEffect(() => {
    if (!validationData) return;

    setLastRetrievedValidationData(validationData);
  }, [validationData]);

  const validationStatus = useMemo(() => lastRetrievedValidationData?.validationStatus, [lastRetrievedValidationData]);
  const documentAnalysis = useMemo(() => lastRetrievedValidationData?.documentAnalysis, [lastRetrievedValidationData]);
  const documentAnalysisRaw = useMemo(() => lastRetrievedValidationData?.documentAnalysisRaw, [lastRetrievedValidationData]);

  useEffect(() => {
    getDocumentValidation({ documentId: document.id });
  }, [document.id, getDocumentValidation]);

  return (
    <ValidationStatusButton
      status={validationStatus?.status as string}
      approvedBy={validationStatus?.approvedBy as string}
      documentAnalysis={documentAnalysis}
      isRollUp={false}
      documentId={document.id}
      getDocumentValidation={getDocumentValidation}
      documentAnalysisRaw={documentAnalysisRaw}
    />
  );
};

export default DocumentStatus;
