import Visa from './Visa';
import MasterCard from './MasterCard';
import CreditCard from './CreditCard';
import Amex from './Amex';
import BankAccount from './BankAccount';

type Props = {
  brand: string;
};

const PaymentMethodLogo: React.FC<Props> = ({ brand }) => {
  if (brand === 'amex') {
    return <Amex />;
  }

  if (brand === 'mastercard') {
    return <MasterCard />;
  }

  if (brand === 'visa') {
    return <Visa />;
  }

  if (brand === 'bank_account') {
    return <BankAccount weight="fill" size={24} color="var(--color-gray-75)" />;
  }

  return <CreditCard weight="fill" size={24} color="var(--color-gray-75)" />;
};

export default PaymentMethodLogo;