import { useRef, forwardRef, ChangeEvent, ForwardedRef, ReactElement } from 'react';

type Props = {
  id?: string;
  isMultiple?: boolean;
  uploadFunction: (files: File[]) => void;
};
const FileSelector = ({
  id = 'fileInput', isMultiple = true, uploadFunction,
}: Props, ref: ForwardedRef<HTMLLabelElement>): ReactElement => {
  const inputRef = useRef<HTMLInputElement>(null);

  const onInputChange = (e: ChangeEvent<HTMLInputElement>): void => {
    if (e.target.files) {
      uploadFunction(Array.from(e.target.files));
      if (inputRef.current) {
        inputRef.current.value = '';
      }
    }
  };

  return (
    <label ref={ref} htmlFor={id}>
      <input
        id={id}
        type="file"
        ref={inputRef}
        multiple={isMultiple}
        style={{
          display: 'none',
          height: 0,
          position: 'absolute',
          width: 0,
          zIndex: '-1',
        }}
        onChange={onInputChange}
      />
    </label>
  );
};

export default forwardRef(FileSelector);
