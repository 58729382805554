import useNoodleApi from '@hooks/useNoodleApi';
import { mixpanelTrack } from '@providers/Mixpanel';
import { useGoogleLogin } from '@react-oauth/google';
import * as tsClient from '@tsClient';
import { CreatorCalendarAccountSource } from '@typings/api-models';
import { GOOGLE_OAUTH2_SCOPES } from '@configuration/client';
import ApiError from '@helpers/ApiError';

type UseGoogleCalendarConnect = (props: {
  creatorId: string;
  teamMemberPersonId: string;
  onConnect: () => void | Promise<void>;
  onError: (errorMessage: ApiError) => void | Promise<void>;
}) => ReturnType<typeof useGoogleLogin>;

const useGoogleCalendarConnect: UseGoogleCalendarConnect = ({ creatorId, teamMemberPersonId, onConnect, onError }) => {
  const { getData: createCalendarAccount } = useNoodleApi(tsClient.createCalendarAccount);

  const handleLogin = useGoogleLogin({
    flow: 'auth-code',
    onError: async () => {
      mixpanelTrack('Failed to Connect Google Calendar Account', {
        creatorId,
      });
    },
    onSuccess: async (codeResponse) => {
      const response = await createCalendarAccount({
        code: codeResponse.code,
        creatorId,
        personId: teamMemberPersonId,
        source: CreatorCalendarAccountSource.Google,
      });
      if (response.data) {
        onConnect();
        mixpanelTrack('Connected Google Calendar Account', {
          creatorId,
        });
      } else if (response.error) {
        onError(response.error);
      }
    },
    scope: GOOGLE_OAUTH2_SCOPES,
  });

  return handleLogin;
};

export default useGoogleCalendarConnect;
