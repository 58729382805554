import React, { FC, ReactElement, useEffect, useState } from 'react';
import pluralize from 'pluralize';

import { useIsMobile } from '@hooks';
import Modal from '@components/Modal';
import Copy from '@components/Icons/Copy';
import Eye from '@components/Icons/Eye';
import CaretRight from '@components/Icons/CaretRight';
import CurrencyCircleDollar from '@components/Icons/CurrencyCircleDollar';
import CaretCircleDown from '@components/Icons/CaretCircleDown';
import PlusCircle from '@components/Icons/PlusCircle';
import QRCode from '@components/QRCode';
import Spacer from '@components/Spacer';
import useNoodleApi from '@hooks/useNoodleApi';
import CopyButton from '@components/CopyButton';
import { SELF_ORIGIN, SELF_HOST } from '@configuration/client';
import { getUrl, IDENTIFIERS } from '@helpers/urlsHelper';
import { createPrice } from '@tsClient';
import { Currency, Interval, NoodleProductTypes, Price, StripePriceUsageType } from '@typings/graphql-models';
import { isInPersonProduct, isLiteProduct, isLiveSessionProduct, isChargeProduct } from '@helpers/helper';

import CustomLink from '@components/CustomLink';
import UserImage from '@components/UserImage';
import * as format from '@format';
import CalendarCheck from '@components/Icons/CalendarCheck';
import CheckCircle from '@components/Icons/CheckCircle';
import { mixpanelTrack } from '@providers/Mixpanel';
import CreateEditPriceForm from '@components/CreateEditPriceForm';
import ProductIcon from '@components/ProductIcon';
import SmallestPrice from '@components/SmallestPrice';
import Buttons from '@components/Buttons';
import * as ApiModels from '@typings/api-models';
import Tabs from '@components/DesignLibrary/Tabs';
import s from './PaymentLinkModal.module.scss';

type ThisPrice = Pick<Price, 'id' | 'currency' | 'price' | 'priceTitle' | 'numBookings' | 'usageType' | 'isActive' | 'recurringInterval'> & {
  product?: {
    slug: string;
    title?: string | null;
    productTypes?:
      | {
          noodleProductType?: NoodleProductTypes | null;
        }[]
      | null;
  } | null;
};

type Product = Pick<ApiModels.Product, 'id' | 'title' | 'slug' | 'isActive'> & {
  productTypes: {
    noodleProductType?: NoodleProductTypes | null;
  }[];
  prices: Array<ThisPrice>;
  initiateWorkflowCTAs?: Pick<ApiModels.InitiateWorkflowCTA, 'id'>[];
};

type Props = {
  onClose: () => void;
  handleCreatedPrice?: () => void;
  creatorSlug: string;
  product: Pick<ApiModels.Product, 'id' | 'title' | 'slug' | 'isActive'> & {
    prices: Array<Pick<Price, 'id' | 'currency' | 'price' | 'priceTitle' | 'isActive' | 'numBookings' | 'usageType'>>;
    productTypes: {
      noodleProductType?: NoodleProductTypes | null;
    }[];
    initiateWorkflowCTAs?: Pick<ApiModels.InitiateWorkflowCTA, 'id'>[];
  };
  paymentLink?: string;
  priceId?: string | null;
  recentlyUsedPrices: ThisPrice[];
  creatorProducts: Product[];
  creator?: {
    businessCategory?: string | null;
    defaultCurrency?: string | null;
    primaryColour?: {
      hex?: string | null;
    } | null;
    name?: string | null;
    person?: {
      name?: string | null;
      image?: {
        url: string;
      } | null;
    } | null;
  } | null;
  onCreate?: (isOpen: boolean) => void;
  onShare?: (productSlug: string) => void;
};

const PaymentLinkModal: FC<Props> = ({
  creator,
  onClose,
  handleCreatedPrice,
  creatorSlug,
  priceId,
  product,
  paymentLink: initialPaymentLink,
  recentlyUsedPrices,
  creatorProducts,
  onCreate,
  onShare,
}): ReactElement => {
  const isMobile = useIsMobile();
  const [paymentLink, setPaymentLink] = useState<string | null>(initialPaymentLink || null);
  const [requestPrice, setRequestPrice] = useState<{
    price: number | null;
    currency: string | null;
    title: string | undefined;
    numBookings: number | undefined;
    isMonthly: boolean | undefined;
  }>({ currency: null, isMonthly: undefined, numBookings: undefined, price: null, title: undefined });
  const [hasScheduling, setHasScheduling] = useState(false);
  const [tab, setTab] = useState<'slug' | 'product' | 'cart'>('slug');
  const [isCreatePriceModalOpen, setIsCreatePriceModalOpen] = useState('');
  const { fetchingState, getData: createPriceFn } = useNoodleApi(createPrice);

  const liteProducts = creatorProducts?.filter(isLiteProduct) || [];
  const onlineSessionProducts = creatorProducts?.filter(isLiveSessionProduct) || [];
  const inPersonSessionProducts = creatorProducts?.filter(isInPersonProduct) || [];
  const chargeProducts = creatorProducts?.filter(isChargeProduct) || [];

  useEffect(() => {
    if (priceId) {
      const price = product?.prices?.find(p => p.id === priceId);
      setRequestPrice({
        currency: price?.currency || null,
        isMonthly: price?.usageType === StripePriceUsageType.Recurring,
        numBookings: price?.numBookings || undefined,
        price: price?.price || null,
        title: price?.priceTitle || undefined,
      });
      if (product && price) {
        mixpanelTrack('View Payment Link', {
          priceId: price.id,
          productSlug: product.slug,
        });
      }
    }
  }, [priceId, product]);

  useEffect(() => {
    if (
      product.productTypes[0]?.noodleProductType === NoodleProductTypes.InPersonSession
      || product.productTypes[0]?.noodleProductType === NoodleProductTypes.OnlineSession
    ) {
      setHasScheduling(true);
    }
  }, [product]);

  const products = [...inPersonSessionProducts, ...liteProducts, ...onlineSessionProducts, { ...product, title: 'Other' }];

  const handleAddPrice: Parameters<typeof CreateEditPriceForm>[0]['onSubmit'] = async priceData => {
    const selectedProduct = creatorProducts?.find(p => p.title === isCreatePriceModalOpen);
    if (!selectedProduct) {
      return;
    }
    const response = await createPriceFn({
      priceData: {
        ...priceData,
        currency: (creator?.defaultCurrency as Currency) || Currency.Usd,
        price: priceData.priceAmount,
        recurringInterval: priceData.selectedPriceUsageType === StripePriceUsageType.Recurring ? Interval.Month : undefined,
        usageType: priceData.selectedPriceUsageType,
      },
      productId: selectedProduct.id,
    });
    if (response.data) {
      setPaymentLink(
        `${SELF_ORIGIN}${getUrl(IDENTIFIERS.PRODUCT_PURCHASE, { creatorSlug, priceId: response.data.id, productSlug: selectedProduct.slug })}`,
      );
      setRequestPrice({
        currency: response.data?.currency || null,
        isMonthly: response.data?.usageType === StripePriceUsageType.Recurring,
        numBookings: response.data.numBookings || undefined,
        price: response.data?.price || null,
        title: response.data?.priceTitle || undefined,
      });
      setHasScheduling(
        response.data.product.productTypes[0]?.noodleProductType === NoodleProductTypes.OnlineSession
          || response.data.product.productTypes[0]?.noodleProductType === NoodleProductTypes.InPersonSession,
      );
      if (handleCreatedPrice) {
        handleCreatedPrice();
        setIsCreatePriceModalOpen('');
      }
    }
  };

  const productTitles = Array.from(new Set([
    ...chargeProducts.map(chargeProduct => chargeProduct.title),
    ...(recentlyUsedPrices || []).map(p => p.product?.title),
  ]));

  return (
    <>
      {!isCreatePriceModalOpen && (
        <Modal
          containerStyle={isMobile ? {} : { width: '80%' }}

          title={'Share link to:'}
          onClose={onClose}

        >
          <div className={s.wrapper}>
            <Spacer size="8px" />
            {!paymentLink && (
              <>
                <Tabs
                  inputName="tabs"
                  inputs={[
                    { id: 'slug', label: 'Your Noodle URL' },
                    { id: 'product', label: 'Product' },
                    { id: 'cart', label: 'Cart' },
                  ]}
                  onChange={(val) => setTab(val)}
                />
                <Spacer />
                <p className={s.tabDescription}>
                  {(tab === 'cart' && 'This will send your customer directly to the cart with the product added to their cart.')
                    || (tab === 'product' && 'Send your customer to the product detail page where they can read the details and then add to cart.')}
                </p>
                <Spacer />
              </>
            )}
            {tab === 'slug' && !paymentLink && (
              <>
                <div className={s.code}>
                  <QRCode data={`${SELF_ORIGIN}/${creatorSlug}`} />
                  <UserImage
                    hasBorder
                    name={creator?.name || creator?.person?.name}
                    size={48}
                    color={creator?.primaryColour?.hex}
                    url={creator?.person?.image}
                  />
                </div>
                <div className={s.creator}>
                  <small>{`${SELF_HOST}/${creatorSlug}`}</small>
                  <h1 className='heading-sm'>{creator?.name || creator?.person?.name}</h1>
                </div>
                <CustomLink to={`${SELF_ORIGIN}/${creatorSlug}`} newTab className={s.previewLink}>
                  <Eye weight="fill" size={20} /> Preview Noodle URL
                </CustomLink>
                <Spacer size={16} />
                <CopyButton
                  isSecondary
                  isFullWidth
                  text={`${SELF_ORIGIN}/${creatorSlug}`}
                  iconBefore={<Copy weight="fill" size={20} />}
                  className={s.copyLink}
                >
                  Copy link
                </CopyButton>
              </>
            )}
            {tab === 'product' && !paymentLink && (
              <>
                <ul className={s.listProducts}>
                  {products
                    .filter(p => (p.prices?.find(price => price.isActive) || (p.initiateWorkflowCTAs?.length || 0) > 0) && p.isActive)
                    .filter(({ title }) => title !== 'Other')
                    .map(p => {
                      const hasActivePrice = p.prices?.find(price => price.isActive);
                      return (
                        <li key={p.id}>
                          <button
                            onClick={() => {
                              if (onShare) {
                                onShare(p.slug);
                              }
                              onClose();
                            }}
                          >
                            <ProductIcon size={24} noodleProductType={p.productTypes[0]?.noodleProductType} />
                            <div>
                              <p>{p.title}</p>
                              {hasActivePrice ? <SmallestPrice prices={p.prices} canEdit={false} /> : <small>No active prices</small>}
                            </div>
                            <CaretRight />
                          </button>
                        </li>
                      );
                    })}
                </ul>
                <Buttons
                  onClick={() => {
                    if (onCreate) {
                      onCreate(true);
                    }
                    onClose();
                  }}
                  className={s.create}
                  iconBefore={<PlusCircle weight="fill" />}
                >
                  Create product
                </Buttons>
              </>
            )}
            {(tab === 'cart' || paymentLink) && (
              <>
                {!paymentLink && (
                  <>
                    {productTitles.length > 0 && (
                      <div>
                        <div>
                          {productTitles.map((productTitle, index) => {
                            const productPrices = recentlyUsedPrices?.filter(price => price.product?.title === productTitle);
                            const thisProduct = creatorProducts.find(pr => pr.title === productTitle);
                            return (
                              <details key={productTitle} className={s.productType} open={index === 0}>
                                <summary className="caption">
                                  <div>
                                    <ProductIcon size={24} noodleProductType={thisProduct?.productTypes?.[0]?.noodleProductType} />
                                    <div>
                                      <p>{productTitle}</p>
                                      <small>
                                        {pluralize('cart link', productPrices.length, true)}
                                      </small>
                                    </div>
                                  </div>
                                  <CaretCircleDown weight="fill" size={16} />
                                </summary>
                                <div>
                                  {productPrices.map(p => (
                                    <button
                                      key={p.id}
                                      className={s.priceCard}
                                      onClick={() => {
                                        setPaymentLink(
                                          `${SELF_ORIGIN}${getUrl(IDENTIFIERS.PRODUCT_PURCHASE, {
                                            creatorSlug,
                                            priceId: p.id,
                                            productSlug: p.product?.slug || '',
                                          })}`,
                                        );
                                        setRequestPrice({
                                          currency: p.currency || null,
                                          isMonthly: p.usageType === StripePriceUsageType.Recurring,
                                          numBookings: p.numBookings || undefined,
                                          price: p.price || null,
                                          title: p.priceTitle || undefined,
                                        });
                                        setHasScheduling(
                                          p.product?.productTypes?.[0].noodleProductType === NoodleProductTypes.OnlineSession
                                            || p.product?.productTypes?.[0].noodleProductType === NoodleProductTypes.InPersonSession,
                                        );
                                      }}
                                    >
                                      <div className={s.priceCardTitle}>
                                        <p className='caption-bold'>{p.priceTitle || 'Product'}</p>
                                        {(!p.isActive || p.numBookings) && (
                                          <p className={s.body}>
                                            {!p.isActive && 'Private'}
                                            {!p.isActive && p.numBookings && ' · '}
                                            {p.numBookings && (
                                              <>
                                                {pluralize('session', p.numBookings, true)}
                                              </>
                                            )}
                                          </p>
                                        )}
                                      </div>
                                      <p className={s.priceCardPrice}>
                                        {format.price.withCurrency(p.price, p.currency)}
                                        {(p.recurringInterval === Interval.Year && '/year') || (p.recurringInterval === Interval.Month && '/mo')}
                                      </p>
                                    </button>
                                  ))}
                                </div>
                                <Buttons
                                  onClick={() => {
                                    setIsCreatePriceModalOpen(thisProduct?.title || '');
                                  }}
                                  className={s.create}
                                  iconBefore={<PlusCircle weight="fill" />}
                                >
                                  Create new cart link
                                </Buttons>
                              </details>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </>
                )}
                {paymentLink && (
                  <>
                    <div className={s.code}>
                      <QRCode data={paymentLink} />
                      <UserImage
                        hasBorder
                        name={creator?.name || creator?.person?.name}
                        size={48}
                        color={creator?.primaryColour?.hex}
                        url={creator?.person?.image}
                      />
                    </div>
                    <div className={s.creator}>
                      <small>{creator?.name || creator?.person?.name}</small>
                      <p>{requestPrice.title}</p>
                    </div>
                    <ul className={s.features}>
                      {requestPrice.price && requestPrice.currency && (
                        <li>
                          <CurrencyCircleDollar weight="fill" color="var(--color-gray-100)" size={24} />
                          <div>
                            <small>{`${(requestPrice.isMonthly && 'Monthly p') || 'P'}rice`}</small>
                            <p>{format.price.withCurrency(requestPrice.price, requestPrice.currency)}</p>
                          </div>
                        </li>
                      )}
                      {hasScheduling && (
                        <li>
                          <CalendarCheck weight="fill" color="var(--color-gray-100)" size={24} />
                          <div>
                            <small>Scheduling</small>
                            <p>Post purchase {requestPrice.isMonthly && '& after each renewal'}</p>
                          </div>
                        </li>
                      )}
                      {requestPrice.numBookings && (
                        <li>
                          <CheckCircle weight="fill" color="var(--color-gray-100)" size={24} />
                          <div>
                            <small># of sessions {requestPrice.isMonthly && 'per month'}</small>
                            <p>
                              {pluralize('session', requestPrice.numBookings, true)}
                            </p>
                          </div>
                        </li>
                      )}
                    </ul>
                    <CustomLink to={paymentLink} newTab className={s.previewLink}>
                      <Eye weight="fill" size={20} /> Preview payment link
                    </CustomLink>
                    <Spacer size={16} />
                    <CopyButton isSecondary isFullWidth text={paymentLink} iconBefore={<Copy weight="fill" size={20} />} className={s.copyLink}>
                      Copy link
                    </CopyButton>
                  </>
                )}
              </>
            )}
          </div>
        </Modal>
      )}
      {isCreatePriceModalOpen && (
        <Modal

          title={`Create new ${isCreatePriceModalOpen || ''} cart link`}
          onClose={() => {
            setIsCreatePriceModalOpen('');
            onClose();
          }}

        >
          <div className={s.wrapper}>
            <Spacer size="8px" />
            <CreateEditPriceForm
              currency={creator?.defaultCurrency || Currency.Usd}
              businessCategory={creator?.businessCategory || 'Other'}
              productType={products.find(p => p.title === isCreatePriceModalOpen)?.productTypes[0]?.noodleProductType || NoodleProductTypes.Charge}
              isSubmitting={fetchingState.isFetching}
              onSubmit={handleAddPrice}
              isActiveByDefault={false}
              requiresPriceTitle={false}
              productTitle={null}
            />
          </div>
        </Modal>
      )}
    </>
  );
};

export default PaymentLinkModal;
