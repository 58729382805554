import React, { FC, ReactElement } from 'react';

import XCircle from 'components/Icons/XCircle';
import Buttons from 'components/Buttons';
import { Expression, MESSAGE_ATTACHMENT_REFERENCE_TYPES, WorkflowContext } from '@typings/api-models';
import getAttachmentIcon from '@helpers/getAttachmentIcon';
import s from './WorkflowInvoices.module.scss';
import ConditionsWidget from '../DesignLibrary/ConditionsWidget';

type Props = {
  invoices: {
    id: string;
    referenceId: string;
    title: string;
    expression?: Expression | null;
  }[];
  removeInvoice: (attachmentId: string) => void;
  handleUpdateConditions: (expressionId: string, workflowInvoiceId: string) => void;
  workflowContexts: Pick<WorkflowContext, 'slug' | 'id' | 'label'>[];
};

const WorkflowInvoices: FC<Props> = ({ invoices, workflowContexts, removeInvoice, handleUpdateConditions }): ReactElement => (
  <div className={s.invoices}>
    {invoices.length > 0
      && invoices.map(invoice => {
        const IconComponent = getAttachmentIcon(MESSAGE_ATTACHMENT_REFERENCE_TYPES.INVOICE);
        return (
          <div key={invoice.referenceId} className={s.invoices__item}>
            <div className={s['invoices__item-left']}>
              <div className={s['invoice-attachments__progress']}>{IconComponent && <IconComponent size="16" weight='fill' color="var(--color-primary)" />}</div>
              <span className="caption">{invoice.title}</span>
            </div>
            <div className={s.conditions}>
              <ConditionsWidget
                conditionalExpression={invoice.expression || null}
                options={workflowContexts.map((context) => ({
                  key: context.slug || context.id,
                  label: context.label,
                }))}
                type={'workflow-context'}
                onOpenModal={() => {}}
                handleSubmit={(expressionId) => handleUpdateConditions(expressionId ,invoice.id)}
              />
            </div>
            <Buttons
              className={s.invoices__remove}
              onClick={() => removeInvoice(invoice.id)}
              icon={<XCircle weight="fill" color="var(--color-error)" />}
            />
          </div>
        );
      })}
  </div>
);

export default WorkflowInvoices;