import React, { ForwardRefRenderFunction, useState, useEffect, forwardRef } from 'react';
import type { Questionnaire } from '@/typings/Questionnaire';
import { QuestionnaireAssigneeTemplate } from '@/typings/questionnaires-api-models';
import { Reorder } from 'framer-motion';
import { FormProvider } from '@context/FormContext';
import ProgressIndicator from '@components/ProgressIndicator';
import s from './FormPreview.module.scss';
import FormItem from './FormItem';

type FormPreviewProps = {
  formData: Questionnaire.Questionnaire;
  assignees: QuestionnaireAssigneeTemplate[];
  currentSection: Questionnaire.Section;
  currentField: string | undefined;
  onSelectField: (args: { field: Questionnaire.Field, isInList: boolean }) => void;
  onReorderFields: (fields: Questionnaire.Field[]) => void;
};

const FormPreview: ForwardRefRenderFunction<HTMLDivElement, FormPreviewProps> = ({
  currentField,
  assignees,
  currentSection,
  onSelectField,
  onReorderFields,
  formData,
}, ref) => {
  const [selectedAssignee, setSelectedAssignee] = useState<string>('All');
  const [fields, setFields] = React.useState(currentSection.fields);

  useEffect(() => {
    setFields(currentSection.fields);
  }, [currentSection]);

  const handleAssigneeFilter = (assignee: string): void => {
    setSelectedAssignee(assignee);
  };

  const filteredFields = fields.filter(field =>
    (selectedAssignee === 'All' || field.assignedTo.includes(selectedAssignee)) && !field.listFieldTemplateId,
  ).sort((a, b) => a.rank > b.rank ? 1 : -1);

  const handleReorder = (newOrder: Questionnaire.Field[]): void => {
    setFields(newOrder);
    onReorderFields(newOrder);
  };

  const handleReorderSubFields = (parentFieldId: string, subFields: Questionnaire.Field[]): void => {
    const newFields = [
      ...fields.filter(({ listFieldTemplateId }) => listFieldTemplateId !== parentFieldId),
      ...subFields,
    ];
    setFields(newFields);
    onReorderFields(newFields);
  };

  return (
    <div className={s.formPreview}>
      <div className={s.formContent}>
        <div className={s.assigneeFilters}>
          <button
            className={selectedAssignee === 'All' ? s.activeFilter : ''}
            onClick={() => handleAssigneeFilter('All')}
          >
            All
          </button>
          {assignees.map(assignee => (
            <button
              key={assignee.id}
              className={selectedAssignee === assignee.id ? s.activeFilter : ''}
              onClick={() => handleAssigneeFilter(assignee.id)}
            >
              {assignee.label}
            </button>
          ))}
        </div>
        <div className={s.sectionTitle}>
          <h2>{currentSection.label}</h2>
          <p>{currentSection.description}</p>
        </div>
        {currentSection.isLoading && (
          <>
            <ProgressIndicator isCentered />
          </>
        )}
        <FormProvider formData={formData} defaultValues={{}} onSubmit={async () => true}>
          <Reorder.Group as="div" axis="y" values={fields} onReorder={handleReorder} className={s.form}>
            {filteredFields.map(field => (
              <FormItem
                key={field.id}
                item={field}
                assignees={
                  assignees
                    .filter(({ id }) => field.assignedTo.includes(id))
                    .map(({ label }) => label)
                }
                currentSelectedField={currentField}
                isSelected={field.id === currentField}
                subFields={fields.filter(({ listFieldTemplateId }) => listFieldTemplateId === field.id)}
                conditions={field.conditions}
                conditionExpression={field.conditionExpression || undefined}
                onClick={onSelectField}
                onReorderSubFields={handleReorderSubFields}
              />
            ))}
          </Reorder.Group>
          <div ref={ref} />
        </FormProvider>
      </div>
    </div>
  );
};

export default forwardRef(FormPreview);
