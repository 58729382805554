import { useRouter } from 'next/navigation';
import { TD } from '@/components/DesignLibrary/Table';
import Button from '@/components/DesignLibrary/Button';
import s from './OpenDetails.module.scss';

export type Props = {
  type: 'open-details';
  label: string;
  href: string;
  cta: string;
};

type InternalProps = Props & {
  onClick: () => void;
};

const OpenDetailsCell: React.FC<InternalProps> = ({ label, href, cta, onClick }) => {
  const router = useRouter();
  const handleClick = (): void => {
    router.push(href);
    onClick();
  };
  if (!href) {
    return <TD label="—" compact />;
  }

  return (
    <TD compact>
      <p className="caption">{label}</p>
      <Button variant='secondary' size="xs" onClick={handleClick} className={s.cta}>{cta}</Button>
    </TD>
  );
};

export default OpenDetailsCell;
