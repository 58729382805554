import z from 'zod';
import * as serializers from '@providers/LocalStorageState/serializers';
import { UserWorkflowCollaboratorSourceEnum } from '@typings/api-models';

export const CollaboratorsSchema = z.array(z.object({
  canAssign: z.boolean(),
  canHavePermissionsChanged: z.boolean(),
  canInviteCollaborators: z.boolean(),
  canReceiveCustomerNotifications: z.boolean(),
  color: z.nullable(z.string()),
  email: z.nullable(z.string()),
  id: z.string(),
  logo: z.nullable(z.string()),
  name: z.nullable(z.string()),
  personId: z.string(),
  source: UserWorkflowCollaboratorSourceEnum,
}));

export default {
  deserialize: serializers.json.deserializeFactory(CollaboratorsSchema),
  serialize: serializers.json.serialize,
};
