import { useContext, useMemo, useState, useRef, useCallback, useEffect } from 'react';
import { nanoid } from 'nanoid';
import { IDENTIFIERS, getUrl } from '@helpers/urlsHelper';
import useNoodleApi from '@hooks/useNoodleApi';
import TeamsContext from '@providers/Teams/TeamsContext';
import { useParams } from 'next/navigation';
import * as tsClient from '@tsClient';

import type { HookResponse } from './common';
import { PAGE_SIZE } from './common';

type ThisWorklow = Awaited<ReturnType<typeof tsClient.workflows.getCreatorWorkflows>>['items'][number];

const getDetailPagePath: HookResponse['getDetailPagePath'] = ({ id }) =>
  getUrl(IDENTIFIERS.DASHBOARD_WORKFLOWS_MY_WORKFLOWS_DETAILS, { workflowId: id });

const useMyWorkflowsV2 = (): HookResponse => {
  const pathParams = useParams<{ workflowId?: string }>();
  const { creatorId } = useContext(TeamsContext);
  const [workflows, setWorkflows] = useState<ThisWorklow[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isFetching, setIsFetching] = useState(false);
  const [numPages, setNumPages] = useState(0);
  const mostRecentRequestRef = useRef<string | null>(null);
  const { getData: getCreatorWorkflows } = useNoodleApi(tsClient.workflows.getCreatorWorkflows);

  const selectedId = pathParams?.workflowId;

  const fetchWorkflows = useCallback(async ({
    page: newPage,
  }: {
      page: number;
    }): Promise<void> => {

    if (creatorId) {
      setCurrentPage(newPage);
      const thisRequestId = nanoid();
      mostRecentRequestRef.current = thisRequestId;
      setIsFetching(true);
      setWorkflows([]);
      setCurrentPage(newPage);

      const response = await getCreatorWorkflows({
        creatorId,
        page: newPage,
        perPage: PAGE_SIZE,
      });

      if (thisRequestId === mostRecentRequestRef.current) {
        if (response.data) {
          setNumPages(response.data.numPages);
          setWorkflows(response.data.items);
        }
        setIsFetching(false);
      }
    }
  }, [creatorId, getCreatorWorkflows]);

  const handleNewPage = (newPage: number): void => {
    fetchWorkflows({
      page: newPage,
    });
  };

  useEffect(() => {
    fetchWorkflows({
      page: 1,
    });
  }, [fetchWorkflows]);

  const pagination = {
    numPages,
    onChangePage: handleNewPage,
    page: currentPage,
  };

  const items = useMemo(() => workflows.map(workflow => ({
    bottomLine: '',
    href: getUrl(IDENTIFIERS.DASHBOARD_WORKFLOWS_MY_WORKFLOWS_DETAILS, { workflowId: workflow.id }),
    id: workflow.id,
    label: workflow.name || '<Un-named>',
  })), [workflows]);

  return {
    getDetailPagePath,
    ifNoItemsText: 'No workflows',
    isFetchingList: isFetching,
    items,
    pagination,
    selectedId,
  };
};

export default useMyWorkflowsV2;
