import { FC, useState } from 'react';
import Modal from '@components/Modal';
import PriceInput from '@components/FormFields/PriceInput';
import Button from '@components/DesignLibrary/Button';
import DatePicker from '@components/FormFields/DatePicker';
import InputField from '@components/FormFields/InputField';
import Spacer from '@components/Spacer';
import useNoodleApi from '@hooks/useNoodleApi';
import * as tsClient from '@tsClient';
import * as ApiModels from '@typings/api-models';
import * as format from '@format';
import s from './PayInvoiceOutsideNoodleModal.module.scss';

type Props = {
  onClose: () => void;
  invoice: Pick<ApiModels.Invoice, 'id' | 'currency' | 'amountDue'>;
  onSubmit: () => Promise<void>;
};

const PayInvoiceOutsideNoodleModal: FC<Props> = ({ onClose, invoice, onSubmit }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [amount, setAmount] = useState(0);
  const [date, setDate] = useState(new Date().toISOString());
  const [note, setNote] = useState('');

  const { getData: addPayment } = useNoodleApi(tsClient.invoices.createInvoicePaymentFromOutsideNoodle);

  const handleClick = async (): Promise<void> => {
    setIsSubmitting(true);
    await addPayment({
      amount: Number(amount),
      date,
      id: invoice.id,
      note,
    });
    await onSubmit();
    onClose();
    setIsSubmitting(false);
  };

  return (
    <Modal title='Record a payment' onClose={onClose} >
      <div className={s.container}>
        <PriceInput
          value={amount}
          name='Total amount paid'
          label='Total amount paid *'
          onChange={setAmount}
          currency={invoice.currency}
          max={invoice.amountDue}
        />
        <Spacer size={16} />
        <DatePicker
          max={format.datetime.dateString(new Date())}
          value={date}
          onChange={setDate}
          label='Date of payment'
        />
        <Spacer size={16} />
        <InputField
          label='Note'
          values={{ note }}
          id='note'
          name='note'
          rows={3}
          onChange={setNote}
        />
        <Spacer size={16} />
        <Button
          disabled={!amount || !date}
          onClick={handleClick}
          loading={isSubmitting}
          variant='primary'
          size='md'
          fullWidth
        >
          Save
        </Button>
      </div>
    </Modal>
  );
};

export default PayInvoiceOutsideNoodleModal;
