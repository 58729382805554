import { FC, useEffect, useState } from 'react';
import classNames from 'classnames';

import Plus from '@components/Icons/Plus';
import EmptyRow from '../EmptyRow';
import SelectionBlockList from '../SelectionBlockList';
import { times, timeDiff } from '../../utils';
import type { Block, Day } from '../../types';

import s from './DayOfWeek.module.scss';

type DayProps = {
  blocks: Block[];
  setBlocks: (blocks: Block[]) => void;
  name: string;
  days: Day[];
};

const Day: FC<DayProps> = ({
  blocks, setBlocks, name, days,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(blocks.length > 0);
  }, [blocks]);

  const addBlock: () => void = () => {
    if (blocks.length === 0) {
      setBlocks([{ from: '9:00am', to: '5:00pm' }]);
      return;
    }

    const lastTime = times[times.length - 1];
    if (lastTime === blocks[blocks.length - 1].to) {
      return;
    }

    const lastBlock = blocks[blocks.length - 1];
    const from = times.find(
      (time) => timeDiff(time, lastBlock.to) > 0,
    ) as string;

    const indexFrom = times.indexOf(from);
    let to = lastTime;
    if (indexFrom + 4 < times.length) {
      to = times[indexFrom + 4];
    }

    setBlocks([...blocks, { from, to }]);
  };

  const removeBlock: (index: number) => void = (index) => {
    const newBlocks = [...blocks];
    newBlocks.splice(index, 1);
    setBlocks(newBlocks);
    if (newBlocks.length === 0) {
      setIsOpen(false);
    }
  };

  const updateBlock: (index: number, from: string, to: string) => void = (
    index,
    from,
    to,
  ) => {
    const newBlocks = [...blocks];

    newBlocks[index] = { ...newBlocks[index], from, to };
    setBlocks(newBlocks);
  };

  const copyBlocks: (day: string) => void = (day: string) => {
    const dayBlocks = days.find((d) => d.name === day)?.blocks;
    if (dayBlocks) {
      setBlocks(dayBlocks);
    }
  };

  const openMenu: () => void = () => {
    setIsOpen(true);
  };

  const closeMenu: () => void = () => {
    if (blocks.length > 0) {
      if (
        window.confirm(`Are you sure you want to remove schedule for ${name}?`)
      ) {
        setBlocks([]);
        setIsOpen(false);
      }
    } else {
      setBlocks([]);
      setIsOpen(false);
    }
  };

  return (
    <div className={s.day}>
      <button
        className={s.day__header}
        onClick={() => {
          if (isOpen) {
            closeMenu();
          } else {
            openMenu();
            if (!days.find((d) => d.blocks.length > 0)) {
              setBlocks([{ from: '9:00am', to: '5:00pm' }]);
            }
          }
        }}
      >
        <h2
          className={classNames(s.day__title, {
            [s.day__title__open]: isOpen,
          })}
        >
          {name}
        </h2>
        <div
          className={classNames(s['day__add-icon'], 'day__add-icon', {
            [s.rotate]: isOpen,
          })}
        >
          <Plus />
        </div>
      </button>
      {isOpen && (
        <div className={s.day__content}>
          {blocks.length === 0 && days.find((d) => d.blocks.length > 0)
            ? (
              <EmptyRow
                addBlock={addBlock}
                copyBlocks={copyBlocks}
                daysOfWeek={days}
              />
            )
            : (
              <SelectionBlockList
                blocks={blocks}
                removeBlock={removeBlock}
                addBlock={addBlock}
                updateBlock={updateBlock}
              />
            )}
        </div>
      )}
    </div>
  );
};

export default Day;
