import GearSix from '@components/Icons/GearSix';
import Header from '@components/DesignLibrary/Header';

type Props = {
    isFollowupEnabled: boolean;
    setIsFollowupEnabled: (newValue: boolean) => void;
    showHeader?: boolean;
  };
const AttachmentSettings: React.FC<Props> = ({
  // isFollowupEnabled,
  // setIsFollowupEnabled,
  showHeader = true,
}) => (
  <>
    {showHeader && (
      <Header
        title='Settings'
        hierarchy='h3'
        Icon={GearSix}
      />
    )}

    {/* <CheckBox
      className={s.row}
      id='followup'
      label={'Follow up automatically until completed'}
      isChecked={isFollowupEnabled}
      onChange={setIsFollowupEnabled}
    /> */}
  </>
);
export default AttachmentSettings;
