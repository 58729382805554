import { useState, useRef, useCallback, useEffect } from 'react';
import { nanoid } from 'nanoid';
import { IDENTIFIERS, getUrl } from '@helpers/urlsHelper';
import * as ApiModels from '@typings/api-models';
import * as tsClient from '@tsClient';
import useNoodleApi from '@hooks/useNoodleApi';
import { useParams } from 'next/navigation';

import type { HookResponse } from './common';

const getDetailPagePath: HookResponse['getDetailPagePath'] = ({ id }) => getUrl(IDENTIFIERS.DASHBOARD_WORKFLOWS_TEMPLATES_DETAIL, { id });

const useTemplates = (): HookResponse => {
  const pathParams = useParams<{ id?: string }>();
  const [templates, setTemplates] = useState<ApiModels.WorkflowTemplate[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isFetching, setIsFetching] = useState(false);
  const mostRecentRequestRef = useRef<string | null>(null);
  const { getData: getWorkflowTemplates } = useNoodleApi(tsClient.workflows.getWorkflowTemplates);

  const selectedId = pathParams?.id;

  const fetchTemplates = useCallback(
    async ({ page: newPage }: { page: number }): Promise<void> => {
      setCurrentPage(newPage);
      const thisRequestId = nanoid();
      mostRecentRequestRef.current = thisRequestId;
      setIsFetching(true);
      setTemplates([]);
      setCurrentPage(newPage);

      const response = await getWorkflowTemplates();

      if (thisRequestId === mostRecentRequestRef.current) {
        if (response.data) {
          setTemplates(response.data);
        }
        setIsFetching(false);
      }
    },
    [getWorkflowTemplates],
  );

  const handleNewPage = (newPage: number): void => {
    fetchTemplates({
      page: newPage,
    });
  };

  useEffect(() => {
    fetchTemplates({
      page: 1,
    });
  }, [fetchTemplates]);

  const pagination = {
    numPages: 0,
    onChangePage: handleNewPage,
    page: currentPage,
  };

  const items = templates.map(template => ({
    bottomLine: template.description || (template.workflows.length > 1 && template.workflows.map(w => w.name).join(', ')) || '',
    href: getUrl(IDENTIFIERS.DASHBOARD_WORKFLOWS_TEMPLATES_DETAIL, { id: template.slug }),
    id: template.slug,
    label: template.title || '',
  }));

  return {
    getDetailPagePath,
    ifNoItemsText: 'No templates',
    isFetchingList: isFetching,
    items,
    pagination,
    selectedId,
  };
};

export default useTemplates;
