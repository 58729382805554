import Spacer from '../Spacer';
import { PaymentType } from './typings';

type Props = {
  paymentType: PaymentType;
  isCreditPaymentEnabled: boolean;
  isDebitCardPaymentEnabled: boolean;
};

const NotTabsHeader: React.FC<Props> = ({ isCreditPaymentEnabled, isDebitCardPaymentEnabled, paymentType }) => {

  let paymentInformationText = 'Payment Information';
  if (paymentType === 'Card') {
    if (isCreditPaymentEnabled && !isDebitCardPaymentEnabled) {
      paymentInformationText = 'Payment Information (Credit Card Only)';
    }
    if (!isCreditPaymentEnabled && isDebitCardPaymentEnabled) {
      paymentInformationText = 'Payment Information (Debit Card Only)';
    }
  }

  return (<><span className='body-sm-bold'>{paymentInformationText}</span><Spacer /></>);
};

export default NotTabsHeader;
