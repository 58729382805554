import { useContext, useEffect } from 'react';
import useNoodleApi from '@hooks/useNoodleApi';
import * as tsClient from '@tsClient';
import ProgressIndicator from '@components/ProgressIndicator';
import Header from '@components/DesignLibrary/Header';
import Spacer from '@components/Spacer';
import DocumentPreviewComponent from '@components/Document/DocumentPreview';
import DocumentError from '@lib/DocumentError';
import { DocumentAccessContext, DocumentAccessGate } from '@providers/DocumentAccess';

import s from './DocumentPreview.module.scss';

type Props = {
  documentId: string;
};

const DocumentPreview: React.FC<Props> = ({ documentId }) => {
  const { accessToken } = useContext(DocumentAccessContext);

  const {
    data: document,
    getData: getDocumentById,
    fetchingState: { isFetching: isFetchingDocument },
    error: documentError,
  } = useNoodleApi(tsClient.documents.getDocumentById);

  useEffect(() => {
    if (documentId) {
      getDocumentById({
        accessToken: accessToken || undefined,
        documentId,
      });
    }
  }, [documentId, accessToken, getDocumentById]);

  if (isFetchingDocument) {
    return (
      <div className={s.container}>
        <Spacer size={80} />
        <ProgressIndicator isPage isCentered />
      </div>
    );
  }

  if (!document) {
    return (
      <div className={s.container}>
        <DocumentError message={documentError?.message || ''} />
      </div>
    );
  }

  return (
    <>
      <Header title={document.fileName} />
      <DocumentAccessGate
        documentCreatorId={document.creatorId}
        documentPersonId={document.personId}
        isCollaborator={document.isCollaborator}
        isCenteredVertically
      >
        <DocumentPreviewComponent
          document={document}
          accessToken={accessToken}
          canDownload={true}
        />
      </DocumentAccessGate>
    </>
  );
};

export default DocumentPreview;
