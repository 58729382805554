import { FC } from 'react';
import Filter from '@components/Icons/Filter';
import s from './Filters.module.scss';
import FilterMany from './FilterMany';
import FilterOne from './FilterOne';

export type FiltersProps = {
  items: Array<
    | ({
        isMultiSelect: true;
      } & Omit<Parameters<typeof FilterMany>[0], 'children'>)
    | ({
        isMultiSelect: false;
      } & Omit<Parameters<typeof FilterOne>[0], 'children'>)
  >;
};

const Filters: FC<FiltersProps> = ({ items }) => (
  <div className={s.filter}>
    <span>
      <Filter size={16} />
      Filter by:
    </span>
    <div className={s.pills}>
      {items?.map(i => (
        i.isMultiSelect && <FilterMany {...i} key={i.label} />)
        || (!i.isMultiSelect && <FilterOne {...i} key={i.label} />),
      )}
    </div>
  </div>
);

export default Filters;
