import { useState } from 'react';
import Modal from '@components/Modal';
import TextInput from '@components/FormFields/TextInput';
import Buttons from '@components/Buttons';
import Spacer from '@components/Spacer';
import s from './InitiateButtonModal.module.scss';

export type InitiateButtonModalState = {
  title: string;
  description: string | null;
  CTA: string;
};

type Props = {
  onClose: () => void;
  onSubmit: (args: InitiateButtonModalState) => Promise<void>;
  initialValues?: InitiateButtonModalState;
  isEditing: boolean;
};

const InitiateButtonModal: React.FC<Props> = ({ onClose, onSubmit, initialValues, isEditing }) => {
  const [values, setValues] = useState<InitiateButtonModalState>(
    initialValues || {
      CTA: '',
      description: '',
      title: '',
    },
  );
  const [errors, setErrors] = useState<Partial<InitiateButtonModalState>>({});
  const [isSaving, setIsSaving] = useState(false);
  const [hasAttemptedSubmit, setHasAttemptedSubmit] = useState(false);

  const { title, description, CTA } = values;
  const { title: titleError, description: descriptionError, CTA: CTAError } = errors;

  const handleSubmit = async (): Promise<void> => {
    setHasAttemptedSubmit(true);
    if (titleError || descriptionError || CTAError) {
      return;
    }
    setIsSaving(true);
    await onSubmit(values);
  };

  const setValue = (key: string) => (value: string) => {
    setValues({
      ...values,
      [key]: value,
    });
  };

  const setError = (key: string) => (value: string | null) => {
    setErrors({
      ...errors,
      [key]: value,
    });
  };

  return (
    <Modal
      hideBottomInset

      title={`${isEditing ? 'Update' : 'Create'} initiate button`}
      onClose={onClose}

    >
      <div className={s.addAssociatedUser}>
        <TextInput
          id="title"
          value={title}
          onChange={setValue('title')}
          label={'Title'}
          placeholder={`Enter title`}
          onError={setError('title')}
          required
          isTouched={hasAttemptedSubmit}
        />
        <Spacer size={12} />
        <TextInput
          id="description"
          error={descriptionError}
          value={description ?? ''}
          onChange={setValue('description')}
          onError={setError('description')}
          label="Description"
          placeholder={`Enter description`}
          isTouched={hasAttemptedSubmit}
          rows={5}
        />
        <Spacer size={12} />
        <TextInput
          id="CTA"
          error={CTAError}
          value={CTA}
          onChange={setValue('CTA')}
          onError={setError('CTA')}
          label="Button Label"
          required
          placeholder={`Enter button label`}
          isTouched={hasAttemptedSubmit}
        />
        <Spacer size={12} />
        <Buttons isSecondary isFullWidth onClick={handleSubmit} isFetching={isSaving}>
          {isEditing ? 'Update' : 'Create'}
        </Buttons>
      </div>
    </Modal>
  );
};

export default InitiateButtonModal;
