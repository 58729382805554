import React, { useState, useEffect, useMemo } from 'react';

import User from '@/components/Icons/User';
import { Input } from '@/components/DesignLibrary/Atoms';
import Button from '@/components/DesignLibrary/Button';
import { QuestionnaireAssigneeTemplate } from '@/typings/questionnaires-api-models';
import s from './AssigneeEditor.module.scss';

type AssigneeEditorProps = {
  assignee: QuestionnaireAssigneeTemplate;
  usedLabels: string[];
  usedKeys: string[];
  onUpdateAssignee: (id: string, payload: {label: string, key: string }) => void;
  onRemoveAssignee: (assignee: string) => void;
};

const AssigneeEditor: React.FC<AssigneeEditorProps> = ({ usedLabels, usedKeys, assignee, onUpdateAssignee, onRemoveAssignee }) => {
  const [label, setLabel] = useState<string>(assignee.label);
  const [key, setKey] = useState<string>(assignee.key);

  const isValidLabel = useMemo(() => !usedLabels.includes(label), [usedLabels, label]);
  const isValidKey = useMemo(() => !usedKeys.includes(key), [usedKeys, key]);

  useEffect(() => {
    setLabel(assignee.label);
    setKey(assignee.key);
  }, [assignee]);

  const handleSave = (): void => {
    if (isValidLabel && label && isValidKey && key) {
      onUpdateAssignee(assignee.id, { key, label });
    }
  };

  const labelError: string | undefined = useMemo(() => {
    if (!label) {
      return 'Required';
    }

    if (!isValidLabel) {
      return 'Label already used';
    }

    return undefined;
  }, [label, isValidLabel]);

  const keyError: string | undefined = useMemo(() => {
    if (!key) {
      return 'Required';
    }

    if (!isValidKey) {
      return 'Key already used';
    }

    return undefined;
  }, [key, isValidKey]);

  return (
    <div className={s.assigneeEditor}>
      <div className={s.assigneeHeader}>
        <User />
        <span className={s.label}>Assignee Details</span>
      </div>
      <div className={s.assigneeContent}>
        <Input
          label=""
          description="Assignee label"
          hint="Please input unique label"
          placeholder="New Assignee"
          error={labelError}
          value={label}
          onChange={setLabel}
        />
        <Input
          label=""
          description="Assignee key"
          hint="Please input unique key"
          error={keyError}
          value={key}
          onChange={setKey}
        />
        <Button variant="primary" size="sm" onClick={handleSave}>Save</Button>
        <Button variant="destructive" size="sm" onClick={() => onRemoveAssignee(assignee.id)}>Delete assignee</Button>
      </div>
    </div>
  );
};

export default AssigneeEditor;
