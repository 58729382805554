import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/loom/token']['GET'];
type Output = ThisApi['response'];

const getLoomToken = async (): Promise<Output> => {
  const data = await noodleApiClient.get('/loom/token');
  return data.data;
};

export default getLoomToken;
