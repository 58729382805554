import Buttons from '@components/Buttons';
import ApiError from '@helpers/ApiError';
import useNoodleApi from '@hooks/useNoodleApi';
import connectOutlookAccount from '@tsClient/connectOutlookAccount';
import MicrosoftOutlookLogo from '@components/Icons/MicrosoftOutlookLogo';
import s from './Connect.module.scss';

type Props = {
  creatorId: string;
  teamMemberPersonId: string;
  onError: (err: ApiError) => void;
};

const ConnectOutlookButton: React.FC<Props> = ({ creatorId, teamMemberPersonId, onError }) => {
  const { fetchingState: { isFetching }, getData: connectOutlookFn } = useNoodleApi(connectOutlookAccount);
  const handleLogin = async (): Promise<void> => {
    const { data: url, error } = await connectOutlookFn({
      creatorId,
      personId: teamMemberPersonId,
      returnUrl: window.location.href,
    });
    if (url) {
      window.location.href = url;
    } else if (error) {
      onError(error);
    }
  };
  return (
    <Buttons
      isThird
      isFullWidth
      isFetching={isFetching}
      iconBefore={<MicrosoftOutlookLogo weight='fill' fill="#2F70C0" />}
      onClick={handleLogin}
      className={s.connectButton}
    >
      Connect Outlook Calendar
    </Buttons>
  );
};

export default ConnectOutlookButton;
