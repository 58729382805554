import { nanoid } from 'nanoid';
import { Table, TBody, THead, TH, TableRow } from '@/components/DesignLibrary/Table';
import ProgressIndicator from '@/components/ProgressIndicator';
import Spacer from '@/components/Spacer';
import Cell, { TableCell } from './Cell';

export type TableColumn = {
  columnId?: string;
  label: string;
  key: string;
  sort?: Parameters<typeof TH>[0]['sort'];
};

export type TableRow = {
  id: string;
  state?: Parameters<typeof TableRow>[0]['state'];
  cells: Array<{ key: string } & TableCell>;
};

export const addHeaderKeyToCell = (columns: TableColumn[]) => (cell: TableCell, index: number): TableCell & { key: string } => ({
  ...cell,
  key: columns[index]?.key ?? nanoid(),
});

interface Props {
  columns: TableColumn[];
  rows: TableRow[];
  isFetching?: boolean;
  onCloseTable: () => void;
}

const TableView: React.FC<Props> = ({ columns, rows, isFetching, onCloseTable }) => (
  <>
    {isFetching
      ? (
        <>
          <Spacer size={50} />
          <ProgressIndicator isCentered />
          <Spacer size={50} />
        </>
      )
      : (
        <Table>
          <THead>
            <TableRow>
              {columns.map((c) => (
                <TH key={c.key} label={c.label} sort={c.sort} columnId={c.columnId} />
              ))}
            </TableRow>
          </THead>
          <TBody>
            {rows.map((row) => (
              <TableRow key={row.id} state={row.state}>
                {row.cells.map(cell => (
                  <Cell
                    key={cell.key}
                    cell={cell}
                    onCloseTable={onCloseTable}
                  />
                ))}
              </TableRow>
            ))}
          </TBody>
        </Table>
      )}
  </>
);

export default TableView;

export { type TableCell };
