import { FC, useState } from 'react';
import Modal from '@components/Modal';
import Spacer from '@components/Spacer';
import Button from '@components/DesignLibrary/Button';
import DOMPurify from 'isomorphic-dompurify';
import s from './ConfirmationModal.module.scss';

type Props = {
  onClose: () => void;
  text: string;
  onSubmit: () => unknown;
  cta: string;
  variant?: Parameters<typeof Button>[0]['variant'];
};

const ConfirmationModal: FC<Props> = ({ onClose, text, onSubmit, cta, variant = 'primary' }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleClick = (): void => {
    setIsSubmitting(true);
    onSubmit();
  };

  return (
    <Modal title='Confirm' onClose={onClose} >
      <div className={s.container}>
        <p className='body-sm' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(text.replace(/\n/g, '<br />')) }} />
        <Spacer size='16px' />
        <Button size='md' fullWidth variant={variant} loading={isSubmitting} onClick={handleClick}>
          {cta}
        </Button>
      </div>
    </Modal>
  );
};

export default ConfirmationModal;
