import { FC, useEffect, useState } from 'react';
import Sparkle from '@components/Icons/Sparkle';
import { WORKFLOW_TRIGGERS, WorkflowStep } from '@typings/api-models';

import CustomSelect from '@components/FormFields/CustomSelect';
import { usePrevious } from '@hooks';
import classes from './TriggerCard.module.scss';
import Buttons from '../Buttons';
import InitiateButtonModal, { InitiateButtonModalState } from '../InitiateButtonModal';
import Button from '../DesignLibrary/Button';

// @todo DRY up with stepName and triggerTitle.
const TRIGGER_OPTIONS = [
  {
    slug: 'an-empty-trigger-for-testing-only',
    title: 'An empty trigger for testing only',
    value: WORKFLOW_TRIGGERS.TEST_TRIGGER,
  },
  {
    slug: 'on-purchase',
    title: 'On Purchase',
    value: WORKFLOW_TRIGGERS.PRODUCT_PURCHASED,
  },
  {
    slug: 'on-purchase-initiated',
    title: 'On Purchase Initiated',
    value: WORKFLOW_TRIGGERS.PURCHASE_INITIATED,
  },
  {
    slug: 'when-shopping-cart-is-abandoned',
    title: 'When Shopping Cart is Abandoned',
    value: WORKFLOW_TRIGGERS.CART_ABANDONED,
  },
  {
    slug: 'when-a-booking-is-upcoming',
    title: 'When a Booking is Upcoming',
    value: WORKFLOW_TRIGGERS.UPCOMING_BOOKING,
  },
  {
    slug: 'on-document-upload-complete',
    title: 'On Document Upload Complete',
    value: WORKFLOW_TRIGGERS.DOCUMENTS_UPLOADED,
  },
  {
    slug: 'initiate-the-workflow-on-a-cta',
    title: 'Initiate the Workflow on a CTA',
    value: WORKFLOW_TRIGGERS.INITIATE_CTA,
  },
  {
    slug: 'on-form-complete',
    title: 'On Form Complete',
    value: WORKFLOW_TRIGGERS.FORM_REQUEST_COMPLETED,
  },
  {
    slug: 'initiate-step-on-a-cta',
    title: 'Initiate Step on a CTA',
    value: WORKFLOW_TRIGGERS.USER_WORKFLOW_CTA,
  },
  {
    slug: 'on-custom-terms-agreed',
    title: 'On Custom Terms Agreed',
    value: WORKFLOW_TRIGGERS.CUSTOM_TERMS_AGREED,
  },
  {
    slug: 'all-attachments-completed',
    title: 'All Attachments Completed',
    value: WORKFLOW_TRIGGERS.ALL_DEPENDENCIES,
  },
  {
    slug: 'payment-initiated',
    title: 'Payment Initiated',
    value: WORKFLOW_TRIGGERS.PURCHASE_INITIATED,
  },
  {
    slug: 'initiate-thread-automatically',
    title: 'Initiate Thread Automatically',
    value: WORKFLOW_TRIGGERS.INITIATE_THREAD,
  },
  {
    slug: 'booking-scheduled',
    title: 'Booking Scheduled',
    value: WORKFLOW_TRIGGERS.BOOKING_SCHEDULED,
  },
];

type TriggerCardProps = {
  availableTriggers: WORKFLOW_TRIGGERS[];
  trigger: Pick<WorkflowStep, 'eventName' | 'id' | 'data' | 'title'>;
  updateStep: (data: { stepId: string; updates: Partial<Pick<WorkflowStep, 'eventName' | 'data' | 'title'>> }) => void;
  deleteStep?: (stepId: string) => (event: React.MouseEvent<HTMLButtonElement>) => Promise<void>;
  canEdit?: boolean;
  workflowId?: string;
};

const TriggerCard: FC<TriggerCardProps> = ({ availableTriggers, trigger, updateStep, deleteStep, workflowId, canEdit = true }) => {
  const [originalEventName, setOriginalEventName] = useState(trigger.eventName);
  const previousTrigger = usePrevious(trigger);
  const [initiateModalOpen, setInitiateModalOpen] = useState<
    | boolean
    | {
        workflowId: string;
        stepId: string;
        args: InitiateButtonModalState;
      }
  >(false);
  useEffect(() => {
    if (previousTrigger && trigger.id !== previousTrigger.id) {
      setOriginalEventName(trigger.eventName);
    }
  }, [trigger, previousTrigger]);

  // availableTriggers changes on every render, so don't bother with useState/useMemo
  const triggerOptions = TRIGGER_OPTIONS.filter(option => availableTriggers.includes(option.value) || option.value === originalEventName).map(
    option => {
      if (option.value === WORKFLOW_TRIGGERS.INITIATE_CTA && trigger.eventName === WORKFLOW_TRIGGERS.INITIATE_CTA) {
        const title = trigger.data && typeof trigger.data === 'object' && 'CTA' in trigger.data && typeof trigger.data.CTA === 'string'
          ? `Initiate: ${trigger.data.CTA}`
          : option.title;

        return {
          ...option,
          key: option.slug,
          title,
        };
      }
      return { ...option, key: option.slug };
    },
  );

  const handleChange = (tValue: WORKFLOW_TRIGGERS): void => {
    updateStep({ stepId: trigger.id, updates: { eventName: tValue } });
  };

  return (
    <>
      <div className={classes.triggerContainer}>
        <div className={classes.header}>
          <div>
            <Sparkle weight="fill" size="16" />
            <p className="caption-bold">Trigger</p>
          </div>
          <div>
            {trigger.eventName === WORKFLOW_TRIGGERS.INITIATE_CTA && workflowId && trigger.data && canEdit && (
              <Button
                variant="secondary"
                size="xs"
                onClick={() =>
                  setInitiateModalOpen({
                    args: {
                      CTA: (trigger.data as { CTA: string; description: string }).CTA,
                      description: (trigger.data as { CTA: string; description: string }).description,
                      title: trigger.title || '',
                    },
                    stepId: trigger.id,
                    workflowId,
                  })
                }
                className={classes.editCta}
              >
                Edit CTA
              </Button>
            )}
            {deleteStep && canEdit && (
              <Buttons onClick={deleteStep(trigger.id)} isWrapper className={classes.delete}>
                Delete
              </Buttons>
            )}
          </div>
        </div>
        <div className={classes.triggerSelector}>
          <CustomSelect
            disabled={!canEdit}
            id={'triggers'}
            onChange={val => handleChange(val as WORKFLOW_TRIGGERS)}
            options={triggerOptions}
            value={trigger.eventName}
          />
        </div>
      </div>
      {typeof initiateModalOpen !== 'boolean' && (
        <InitiateButtonModal
          isEditing
          onClose={() => setInitiateModalOpen(false)}
          onSubmit={async args => {
            const { title, ...data } = args;
            updateStep({ stepId: initiateModalOpen.stepId, updates: { data, title } });
            setInitiateModalOpen(false);
          }}
          initialValues={initiateModalOpen.args}
        />
      )}
    </>
  );
};

export default TriggerCard;
