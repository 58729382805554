import { InvoiceData } from "@/modals/InitiateCaseModal/CreateInvoice";
import { ContextData } from "@/modals/InitiateCaseModal/types";
import { Expression } from "@/typings/api-models";
import evaluateExpression from "../evaluateExpression";

export type ThisWorkflowInvoice = { invoiceTemplate: Omit<InvoiceData, 'templateId' | 'paymentMethodId'> } & {
    expression?: Expression | null };

export const getContextValues = (contextData: ContextData[]): Record<string, unknown> => {
  const resolvedValues: Record<string, unknown> = {};
  contextData.forEach((context) => {
    if (context.context.slug) {
      resolvedValues[context.context.slug] = context.data;
    }
  });
  return resolvedValues;
};

const filterWorkflowInvoicesByContextExpressions = ({
  workflowInvoices,
  contextData,
}: {
  workflowInvoices: ThisWorkflowInvoice[];
  contextData: ContextData[];
}): ThisWorkflowInvoice[] => {
  const filteredInvoices: ThisWorkflowInvoice[] = [];
  workflowInvoices.forEach((invoice) => {
    if (!invoice.expression) {
      filteredInvoices.push(invoice);
      return;
    }

    const variables: Record<string, unknown> = {};
    invoice.expression.variables.forEach((variable) => {
      variables[variable.variableName] = getContextValues(contextData);
    });

    const result = evaluateExpression({
      expression: invoice.expression.expression,
      variables,
    }) ?? true;

    // If expression is false don't add
    if (!result) {
      return;
    }

    filteredInvoices.push(invoice);
  });
  return filteredInvoices;
};

export default filterWorkflowInvoicesByContextExpressions;