import classNames from 'classnames';
import * as ApiModels from '@typings/api-models';
import PaymentMethodLogo from '@components/Icons/PaymentMethodLogo';

import ProgressIndicator from '@components/ProgressIndicator';
import CustomLink from '@components/CustomLink';
import PendingVerification from './PendingVerification';
import s from './ACHDetails.module.scss';

type Props = {
  account: Pick<NonNullable<ApiModels.PaymentMethod['us_bank_account']>, 'bank_name' | 'last4'> | null;
  verifyWithMicrodeposits: Parameters<typeof PendingVerification>[0]['verifyWithMicrodeposits'];
  isLoading?: boolean;
  isPrimary: boolean;
};

const ACHDetails: React.FC<Props> = ({ account, isLoading, isPrimary, verifyWithMicrodeposits }) => {
  const { bank_name: bankName, last4 } = account || {};

  return (
    <CustomLink
      className={classNames(s.container, verifyWithMicrodeposits && s.clickable)}
      to={verifyWithMicrodeposits?.verificationUrl}
      newTab
    >
      <div className={s.details}>
        <PaymentMethodLogo brand="bank_account" />
        <div className={s.content}>
          <p>{`•••• •••• •••• ${last4}`}</p>
          <small>
            {bankName}
            {isPrimary && (
              <>
                {' · '}<span className={s['is-primary']}>Primary</span>
              </>
            )}
          </small>
          {isLoading && <ProgressIndicator isAbsolute />}
        </div>
      </div>
      <PendingVerification verifyWithMicrodeposits={verifyWithMicrodeposits} />
      {verifyWithMicrodeposits && <p className={s['verify-cta']}>Verify</p>}
    </CustomLink>
  );
};

export default ACHDetails;
