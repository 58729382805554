import React, { FC, useContext, useState, useEffect } from 'react';
import { AnimatePresence, m } from 'framer-motion';
import XCircle from '@components/Icons/XCircle';
import FileDashed from '@components/Icons/FileDashed';
import Buttons from '@components/Buttons';
import TeamsContext from '@providers/Teams/TeamsContext';
import useNoodleApi from '@hooks/useNoodleApi';
import * as tsClient from '@tsClient';
import ProgressIndicator from '@components/ProgressIndicator';
import ChatDropDown from '@components/Chat/ChatDropDown';
import * as ApiModels from '@typings/document-api-models';
import OutsideClick from '@components/OutsideClick';
import s from './InputComposer.module.scss';
import SearchInput from '../FormFields/SearchInput';

type Props = {
  disabled: boolean;
  onAttach: (data: { title: string; documentRequestTemplateId: string }) => void;
};

const AddDocumentRequest: FC<Props> = ({ disabled, onAttach }) => {
  const { creatorId } = useContext(TeamsContext);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [search, setSearch] = useState('');
  const {
    data: documentRequestTemplates,
    getData: getDocumentRequestTemplates,
    fetchingState: { isFetching: isFetchingDocumentRequests },
  } = useNoodleApi(tsClient.documentRequests.getCreatorDocumentRequests);

  useEffect(() => {
    if (creatorId) {
      getDocumentRequestTemplates({ creatorId, page: 1, perPage: 1000 });
    }
  }, [creatorId, getDocumentRequestTemplates]);

  const toggle = (): void => {
    setPopoverOpen(prev => !prev);
  };

  const handleClick = (template: Pick<ApiModels.DocumentRequest, 'id' | 'title'>): void => {
    setPopoverOpen(false);
    onAttach({ documentRequestTemplateId: template.id, title: template.title });
  };

  if (documentRequestTemplates?.items.length === 0) {
    return null;
  }

  return (
    <>
      <Buttons
        disabled={disabled}
        ariaLabel="Add document request"
        iconBefore={
          <m.div
            style={{
              alignItems: 'center',
              display: 'flex',
              height: '16px',
              justifyContent: 'center',
              width: '16px',
              willChange: 'transform',
            }}
            whileTap={(!disabled && { scale: 0.9 }) || {}}
          >
            {popoverOpen
              ? (
                <XCircle weight="fill" size={16} color="var(--color-gray-50)" />
              )
              : (
                <FileDashed weight="fill" size={16} color={!disabled ? 'var(--color-primary)' : 'var(--color-gray-75)'} />
              )}
          </m.div>
        }
        onClick={toggle}
      >
        Document Request
      </Buttons>
      <AnimatePresence>
        {popoverOpen && (
          <OutsideClick onOutsideClick={() => setPopoverOpen(false)} className={s.outsideClick}>
            <ChatDropDown>
              {!documentRequestTemplates || isFetchingDocumentRequests
                ? (
                  <ProgressIndicator isCentered />
                )
                : (
                  <>
                    <SearchInput label="Filter by title" placeholder="Type a title..." setSearch={setSearch} className={s.search} />
                    {documentRequestTemplates.items
                      .filter(template => template.title.toLowerCase().includes(search.toLowerCase()))
                      .map(template => (
                        <Buttons className={s.documentRequestButton} key={template.id} isWrapper isFlat onClick={() => handleClick(template)}>
                          <FileDashed size={14} weight="fill" />
                          <p>
                            {template.title} ({template.files.length} document{template.files.length > 1 ? 's' : ''})
                          </p>
                        </Buttons>
                      ))}
                  </>
                )}
            </ChatDropDown>
          </OutsideClick>
        )}
      </AnimatePresence>
    </>
  );
};

export default AddDocumentRequest;
