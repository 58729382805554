import { FC, useEffect, useState } from 'react';
import { mixpanelTrack } from '@providers/Mixpanel';
import Spacer from '@components/Spacer';
import InputField from '@components/FormFields/InputField';
import Buttons from '@components/Buttons/Buttons';
import s from './CartAdditionalTermsInfo.module.scss';

type Props = {
  initialValues?: Record<string, string>;
  additionalFields: { label: string, key: string }[];
  onSubmit: (data: Record<string, unknown>) => void;
  onFinish?: () => void;
};

const CartAdditionalTermsInfo: FC<Props> = ({
  additionalFields,
  initialValues,
  onFinish,
  onSubmit,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [fieldsData, setFieldsData] = useState<Record<string, unknown>>(initialValues || {});

  useEffect(() => {
    mixpanelTrack('Visited CartAdditionalTermsInfo');
  }, []);

  const handleSubmit = async (): Promise<void> => {
    setIsLoading(true);
    onSubmit(fieldsData);
    onFinish?.();
    setIsLoading(false);
  };

  const handleFieldChange = (key: string, val: string): void => {
    setFieldsData({
      ...fieldsData,
      [key]: val,
    });
  };

  const isDisabled = (): boolean => {
    for (let i = 0; i < additionalFields.length; i += 1) {
      if (!fieldsData[additionalFields[i].key]) {
        return true;
      }
    }
    return false;
  };

  return (
    <div className={s.container}>
      <p className='body-sm-bold'>Before agreeing to the terms, please confirm the information below.</p>
      <Spacer size={20} />
      {additionalFields.map((field) => (
        <>
          <InputField
            id={field.key}
            label={field.label}
            name={field.key}
            placeholder={`Enter ${field.label}`}
            onChange={handleFieldChange.bind(this, field.key)}
            values={{ [field.key]: fieldsData[field.key] }}
          />
          <Spacer size={16}/>
        </>
      ))}
      <Buttons
        disabled={isDisabled()}
        isSecondary
        isFetching={isLoading}
        isFullWidth
        onClick={handleSubmit}
      >
        Confirm
      </Buttons>
    </div>
  );
};

export default CartAdditionalTermsInfo;
