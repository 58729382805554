import Button from '@/components/DesignLibrary/Button';
import { TD } from '@/components/DesignLibrary/Table';
import UserCirclePlus from '@/components/Icons/UserCirclePlus';
import ProgressIndicator from '@/components/ProgressIndicator';
import UserImage from '@/components/UserImage';
import useNoodleApi from '@/hooks/useNoodleApi';
import UserWorkflowAssigneeModal from '@/modals/UserWorkflowAssigneeModal';
import TeamsContext from '@/providers/Teams/TeamsContext';
import * as tsClient from '@tsClient';
import { getCreatorTeam } from '@tsClient/creators';
import { useContext, useEffect, useState } from 'react';
import s from './Assignees.module.scss';

export type Props = {
  type: 'assignees';
  userWorkflowId: string;
};

const AssigneesCell: React.FC<Props> = ({ userWorkflowId }) => {
  const { creatorId } = useContext(TeamsContext);

  const [workflowAssigneeModal, setWorkflowAssigneeModal] = useState('');
  const [owners, setOwners] = useState<Awaited<ReturnType<typeof tsClient.workflows.getUserWorkflowOwners>> | null>(null);
  const { data: loadedOwners,
    fetchingState: { isFetching: fetchingOwners },
    getData: getOwnersFn } = useNoodleApi(tsClient.workflows.getUserWorkflowOwners, { toastOnError: true });
  const { data: teamMembers,
    fetchingState: { isFetching: fetchingMembers },
    getData: getTeamMembersFn } = useNoodleApi(getCreatorTeam);

  useEffect(() => {
    if (!creatorId) return;
    getOwnersFn({ userWorkflowId });
    getTeamMembersFn({ creatorId, page: 1, perPage: 1000 });
  }, [getTeamMembersFn, getOwnersFn, userWorkflowId, creatorId]);

  useEffect(() => {
    if (loadedOwners) {
      setOwners(loadedOwners);
    }
  }, [loadedOwners]);

  const ownersWithData = teamMembers?.items
    ?.filter(p => (owners || []).map(o => o.personId).includes(p.id));

  return (
    <TD compact>
      {(fetchingMembers || fetchingOwners)
        ? <ProgressIndicator size={16} />
        : <>
          {ownersWithData && ownersWithData.length > 0
            ? (
              <ul className={s.assignees}>
                {ownersWithData.map(owner => (
                  <li key={owner.id}>
                    <UserImage size={14} color={owner.primaryColour?.hex} name={owner.name} url={owner.image} />
                    <p className="caption">{owner.name}</p>
                  </li>
                ))}
              </ul>
            )
            : (
              <Button size="xs" onClick={() => setWorkflowAssigneeModal(userWorkflowId)} variant="secondary" className={s.assign}>
                <UserCirclePlus weight="fill" />
                Assign team member
              </Button>
            )}
        </>}
      {workflowAssigneeModal && creatorId && (
        <UserWorkflowAssigneeModal
          creatorId={creatorId}
          ownerIds={(owners || []).map(o => o.personId)}
          userWorkflowId={userWorkflowId}
          onClose={() => setWorkflowAssigneeModal("")}
          onSubmit={async () => {
            setWorkflowAssigneeModal("");
            await getOwnersFn({ userWorkflowId });
          }}
        />
      )}
    </TD>
  );
};

export default AssigneesCell;
