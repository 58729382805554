import Header from '@components/DesignLibrary/Header';
import EditWorkflowForm from '@components/EditWorkflowForm';
import ProgressIndicator from '@components/ProgressIndicator';
import useNoodleApi from '@hooks/useNoodleApi';
import TeamsContext from '@providers/Teams/TeamsContext';
import { getCreatorTeam } from '@tsClient/creators';
import { FC, useContext, useEffect, useState } from 'react';
import * as ApiModels from '@typings/api-models';
import * as tsClient from '@tsClient';
import { ChatsCircle, FileDashed, HandCoins, ListDashes, Signature } from '@phosphor-icons/react';
import { getUrl, IDENTIFIERS } from '@helpers/urlsHelper';
import { NOODLE_CREATOR_SLUG } from '@configuration/client';
import CreateEditTermsTemplateModal from '@modals/CreateEditTermsTemplateModal';
import CreateEditDocumentRequestModal from '@modals/CreateEditDocumentRequestModal';
import FormRequestModal from '@modals/FormRequestModal';
import InvoiceRequestModal from '@modals/InvoiceRequestModal';
import CollapsibleArea from '@components/CollapsibleArea';
import Spacer from '@components/Spacer';
import ProductEditModal from '@modals/ProductEditModal';
import CalendarBlank from '@components/Icons/CalendarBlank';
import { ToastTypeVariants } from '@context/ToastContext';
import Buttons from '@/components/Buttons';
import { m } from 'framer-motion';
import * as format from '@format';
import Badge from '@/components/DesignLibrary/Badge';

import s from './WorkflowDetails.module.scss';

type Props = {
  workflowSlug: string;
};

const PAGE = 1;
const PAGE_SIZE = 1000;

const getBadgeColor = (state: Awaited<ReturnType<typeof tsClient.workflows.getWorkflowVersions>>['items'][0]['state']): string => {
  switch (state) {
  case 'current':
    return 'var(--color-success)';
  case 'stale':
    return 'var(--color-error)';
  default:
    return 'var(--color-gray-75)';
  }
};

const WorkflowBadge: FC<{ state: Awaited<ReturnType<typeof tsClient.workflows.getWorkflowVersions>>['items'][0]['state'] }> = ({ state }) => {
  const color = getBadgeColor(state);
  return <Badge color={color} label={state.charAt(0).toUpperCase() + state.slice(1)} />;
};

const WorkflowDetails: FC<Props> = ({ workflowSlug }) => {
  const { creatorId } = useContext(TeamsContext);

  const [workflow, setWorkflow] = useState<ApiModels.CommonWorkflowResponse | null>(null);
  const [versions, setVersions] = useState<Awaited<ReturnType<typeof tsClient.workflows.getWorkflowVersions>> | null>(null);
  const [termsRequestId, setTermsRequestId] = useState<string | null>(null);
  const [documentRequestId, setDocumentRequestId] = useState<string | null>(null);
  const [formRequestId, setFormRequestId] = useState<string | null>(null);
  const [invoiceRequestId, setInvoiceRequestId] = useState<string | null>(null);
  const [bookingPriceId, setBookingPriceId] = useState<string | null>(null);
  const [versionHistoryVisible, setVersionHistoryVisible] = useState(false);
  const [selectedVersion, setSelectedVersion] = useState<number | null>(null);

  const { data: workflowData, getData: getWorkflowDraftFn } = useNoodleApi(tsClient.workflows.getWorkflowDraft);
  const { data: workflowVersions, getData: getWorkflowVersionsFn } = useNoodleApi(tsClient.workflows.getWorkflowVersions);
  const { data: workflowVersion, getData: getWorkflowVersionFn } = useNoodleApi(tsClient.workflows.getWorkflowVersion);
  const {
    fetchingState: { isFetching: isPublishingWorkflow },
    getData: publishWorkflowFn,
  } = useNoodleApi(tsClient.workflows.publishWorkflow, {
    toastErrorMessage: () => [ToastTypeVariants.ERROR, 'Failed to publish workflow'],
    toastOnError: true,
    toastSuccessMessage: () => [ToastTypeVariants.SUCCESS, 'Workflow published successfully!'],
  });

  const { data: creatorTeam, getData: getCreatorTeamFn } = useNoodleApi(getCreatorTeam);
  const {
    data: invoiceRequest,
    fetchingState: { isFetching: invoiceRequestFetching },
    getData: getInvoiceRequestTemplateById,
  } = useNoodleApi(tsClient.invoices.getInvoiceRequestTemplateById);
  const {
    data: documentRequest,
    fetchingState: { isFetching: documentRequestFetching },
    getData: getDocumentRequestById,
  } = useNoodleApi(tsClient.documentRequests.getDocumentRequestById);
  const {
    data: formRequest,
    fetchingState: { isFetching: formRequestFetching },
    getData: getFormRequestById,
  } = useNoodleApi(tsClient.formRequests.getFormRequestTemplateById);
  const {
    data: termsRequest,
    fetchingState: { isFetching: termsRequestFetching },
    getData: getTermsRequestById,
  } = useNoodleApi(tsClient.customTerms.getCustomTermsTemplateById);
  const {
    data: bookingPrice,
    fetchingState: { isFetching: isFetchingBookingPrice },
    getData: getBookingPrice,
  } = useNoodleApi(tsClient.getPrice);

  const refetchWorkflow = async (): Promise<void> => {
    if (creatorId) {
      await getWorkflowDraftFn({ creatorId, workflowSlug });
      await getWorkflowVersionsFn({ creatorId, workflowSlug });
    }
  };

  const publishWorkflow = async (): Promise<void> => {
    if (creatorId) {
      await publishWorkflowFn({ creatorId, workflowSlug });
      await getWorkflowDraftFn({ creatorId, workflowSlug });
      await getWorkflowVersionsFn({ creatorId, workflowSlug });
    }
  };

  useEffect(() => {
    const fetchCreatorTeam = async (): Promise<void> => {
      if (creatorId) {
        await getCreatorTeamFn({
          creatorId,
          page: PAGE,
          perPage: PAGE_SIZE,
        });
      }
    };

    if (creatorId) {
      getWorkflowDraftFn({ creatorId, workflowSlug });
      getWorkflowVersionsFn({ creatorId, workflowSlug });
      fetchCreatorTeam();
    }
  }, [creatorId, getCreatorTeamFn, getWorkflowDraftFn, getWorkflowVersionsFn, workflowSlug]);

  useEffect(() => {
    if (workflowVersions) {
      setVersions(workflowVersions);
    }
  }, [workflowVersions]);

  useEffect(() => {
    if (workflowData) {
      setWorkflow(workflowData);
      setSelectedVersion(workflowData.version);
    }
  }, [workflowData]);

  useEffect(() => {
    if (selectedVersion && creatorId) {
      getWorkflowVersionFn({ creatorId, version: selectedVersion, workflowSlug });
    }
  }, [creatorId, getWorkflowVersionFn, workflowSlug, selectedVersion]);

  useEffect(() => {
    if (workflowVersion) {
      setWorkflow(workflowVersion);
    }
  }, [workflowVersion]);

  useEffect(() => {
    if (termsRequestId) {
      getTermsRequestById({ id: termsRequestId });
    }
  }, [getTermsRequestById, termsRequestId]);

  useEffect(() => {
    if (documentRequestId) {
      getDocumentRequestById({ id: documentRequestId });
    }
  }, [documentRequestId, getDocumentRequestById]);

  useEffect(() => {
    if (formRequestId) {
      getFormRequestById({ id: formRequestId });
    }
  }, [formRequestId, getFormRequestById]);

  useEffect(() => {
    if (invoiceRequestId) {
      getInvoiceRequestTemplateById({ id: invoiceRequestId });
    }
  }, [invoiceRequestId, getInvoiceRequestTemplateById]);

  useEffect(() => {
    if (bookingPriceId) {
      getBookingPrice({ priceId: bookingPriceId });
    }
  }, [bookingPriceId, getBookingPrice]);

  if (workflow === null || versions === null) {
    return (
      <div className={s.form}>
        <Spacer size={20} />
        <ProgressIndicator isCentered />
      </div>
    );
  }

  const selectedVersionState = versions.items.find(version => version.version === workflow.version)?.state;

  return (
    <>
      <div className={s.wrapper}>
        {versionHistoryVisible && (
          <m.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} className={s.versions}>
            <Header title="Version history" hierarchy="h3" />
            <ul>
              {versions.items.map(version => (
                <li key={version.id}>
                  <Buttons
                    isWrapper
                    onClick={() => setSelectedVersion(version.version)}
                    className={selectedVersion === version.version ? s.selectedVersion : undefined}
                  >
                    <p className="body-sm">
                      Version {version.version} <WorkflowBadge state={version.state} />
                    </p>
                    <p className="caption">
                      {version.publishedAt
                        ? (<>Published on {format.datetime.withoutTimezone({ datetime: version.publishedAt })}</>)
                        : (
                          <span style={{ color: 'var(--color-gray-50)' }}>Not published yet</span>
                        )}
                    </p>
                  </Buttons>
                </li>
              ))}
            </ul>
          </m.div>
        )}
        <div className={s.main}>
          {selectedVersion === workflow.version
            ? <>
              <Header
                title={workflow.name}
                description={
                  <p className={s.editingVersionLabel}>
                    Editing Version {workflow.version} {selectedVersionState && <WorkflowBadge state={selectedVersionState} />} ·{' '}
                    <Buttons isWrapper onClick={() => setVersionHistoryVisible(!versionHistoryVisible)} className={s.versionsButton}>
                      {versionHistoryVisible ? 'Hide' : 'Show'} Version History
                    </Buttons>
                  </p>
                }
                actions={
                  versions.items.find(({ state }) => state === 'draft')?.version === selectedVersion
                    ? [
                      {
                        isFetching: isPublishingWorkflow,
                        isPrimary: true,
                        label: 'Publish',
                        onClick: publishWorkflow,
                      },
                    ]
                    : undefined
                }
              />
              <section className={s.section}>
                <div className={s.form}>
                  <EditWorkflowForm
                    canEdit={selectedVersionState === 'draft'}
                    creatorTeamMembers={creatorTeam?.items || []}
                    workflow={workflow}
                    onSave={refetchWorkflow}
                    refetchWorkflow={refetchWorkflow}
                  />
                </div>
                <div className={s.buildingBlocks}>
                  <Header
                    hierarchy="h2"
                    title="Workflow’s Building Blocks"
                    noDivider
                    actions={[
                      {
                        href: getUrl(IDENTIFIERS.DASHBOARD_CHAT_WITH_CREATOR, { creatorSlug: NOODLE_CREATOR_SLUG }),
                        icon: ChatsCircle,
                        label: 'Chat with Noodle',
                      },
                    ]}
                    className={s.buildingBlocksHeader}
                  />
                  {workflow.buildingBlocks.customTerms.length > 0 && (
                    <CollapsibleArea
                      title={`Custom Retainer Agreements`}
                      Icon={{
                        Component: Signature,
                        color: 'var(--color-error)',
                      }}
                    >
                      <ul className={s.list}>
                        {workflow.buildingBlocks.customTerms.map(tr => (
                          <li key={tr.id}>
                            <button onClick={() => setTermsRequestId(tr.id)}>
                              {(termsRequestFetching && tr.id === termsRequestId && <ProgressIndicator size={16} />) || (
                                <div className={s.spinnerPlaceholder} />
                              )}
                              <span>{tr.title}</span>
                            </button>
                          </li>
                        ))}
                      </ul>
                    </CollapsibleArea>
                  )}
                  {workflow.buildingBlocks.documentRequests.length > 0 && (
                    <CollapsibleArea
                      title={`Document Checklists`}
                      Icon={{
                        Component: FileDashed,
                        color: 'var(--color-warning)',
                      }}
                    >
                      <ul className={s.list}>
                        {workflow.buildingBlocks.documentRequests.map(dr => (
                          <li key={dr.id}>
                            <button onClick={() => setDocumentRequestId(dr.id)}>
                              {(documentRequestFetching && dr.id === documentRequestId && <ProgressIndicator size={16} />) || (
                                <div className={s.spinnerPlaceholder} />
                              )}
                              <span>{dr.title}</span>
                            </button>
                          </li>
                        ))}
                      </ul>
                    </CollapsibleArea>
                  )}
                  {workflow.buildingBlocks.formRequests.length > 0 && (
                    <CollapsibleArea
                      title={`Questionnaires`}
                      Icon={{
                        Component: ListDashes,
                        color: 'var(--color-noodle)',
                      }}
                    >
                      <ul className={s.list}>
                        {workflow.buildingBlocks.formRequests.map(fr => (
                          <li key={fr.id}>
                            <button onClick={() => setFormRequestId(fr.id)}>
                              {' '}
                              {(formRequestFetching && fr.id === formRequestId && <ProgressIndicator size={16} />) || (
                                <div className={s.spinnerPlaceholder} />
                              )}
                              <span>{fr.name}</span>
                            </button>
                          </li>
                        ))}
                      </ul>
                    </CollapsibleArea>
                  )}
                  {workflow.buildingBlocks.invoices.length > 0 && (
                    <CollapsibleArea
                      title={`Invoice collection`}
                      Icon={{
                        Component: HandCoins,
                        color: 'var(--color-success)',
                      }}
                    >
                      <ul className={s.list}>
                        {workflow.buildingBlocks.invoices.map(ir => (
                          <li key={ir.id}>
                            <button onClick={() => setInvoiceRequestId(ir.id)}>
                              {(invoiceRequestFetching && ir.id === invoiceRequestId && <ProgressIndicator size={16} />) || (
                                <div className={s.spinnerPlaceholder} />
                              )}
                              <span>{ir.title}</span>
                            </button>
                          </li>
                        ))}
                      </ul>
                    </CollapsibleArea>
                  )}
                  {workflow.buildingBlocks.bookingPrices.length > 0 && (
                    <CollapsibleArea
                      title="Bookings"
                      Icon={{
                        Component: CalendarBlank,
                        color: 'var(--color-noodle)',
                      }}
                    >
                      <ul className={s.list}>
                        {workflow.buildingBlocks.bookingPrices.map(p => (
                          <li key={p.id}>
                            <button onClick={() => setBookingPriceId(p.id)}>
                              {(isFetchingBookingPrice && p.id === bookingPriceId && <ProgressIndicator size={16} />) || (
                                <div className={s.spinnerPlaceholder} />
                              )}
                              <span>{p.priceTitle}</span>
                            </button>
                          </li>
                        ))}
                      </ul>
                    </CollapsibleArea>
                  )}
                </div>
              </section>
            </>
            : <><Spacer size={40} /><ProgressIndicator isCentered /></>}
        </div>
      </div>
      {termsRequest && termsRequestId && (
        <CreateEditTermsTemplateModal
          termsTemplate={termsRequest}
          onClose={() => setTermsRequestId(null)}
          onSubmit={async () => {
            await refetchWorkflow();
            setTermsRequestId(null);
          }}
        />
      )}
      {documentRequest && documentRequestId && (
        <CreateEditDocumentRequestModal
          documentRequest={documentRequest}
          onClose={() => setDocumentRequestId(null)}
          onSubmit={async () => {
            await refetchWorkflow();
            setDocumentRequestId(null);
          }}
        />
      )}
      {formRequest && formRequestId && creatorId && (
        <FormRequestModal
          beforeClose={async () => {
            await refetchWorkflow();
            setFormRequestId(null);
          }}
          onClose={() => setFormRequestId(null)}
          formRequestTemplate={formRequest}
          creatorId={creatorId}
        />
      )}
      {invoiceRequest && invoiceRequestId && (
        <InvoiceRequestModal
          beforeClose={async () => {
            await refetchWorkflow();
            setInvoiceRequestId(null);
          }}
          onClose={() => setInvoiceRequestId(null)}
          invoiceTemplate={invoiceRequest}
        />
      )}
      {bookingPrice?.product?.slug && bookingPrice?.product?.creator?.slug && bookingPriceId && (
        <ProductEditModal
          onClose={async () => {
            setBookingPriceId(null);
            await refetchWorkflow();
          }}
          creatorSlug={bookingPrice.product.creator.slug}
          productSlug={bookingPrice.product.slug}
        />
      )}
    </>
  );
};
export default WorkflowDetails;
