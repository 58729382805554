import classNames from 'classnames';
import s from './Badge.module.scss';

type Props = {
  color: string;
  label: string;
  className?: string;
};

const Badge: React.FC<Props> = ({ color, label, className }) => (
  <span className={classNames(s.badge, className)} style={{ color }}>
    <span style={{ backgroundColor: color, opacity: 0.1 }} className={s.badgeBg} />
    {label}
  </span>
);

export default Badge;
