import { useState } from 'react';
import { Reorder } from 'framer-motion';
import pluralize from 'pluralize';
import classNames from 'classnames';

import PlusCircle from '@/components/Icons/PlusCircle';
import ArrowLineUp from '@/components/Icons/ArrowLineUp';
import ExpandableListItem from './ExpandableListItem';
import s from './ExpandableList.module.scss';
import itemStyles from './ExpandableListItem.module.scss';

type ExpandableListProps<T> = {
  items: T[];
  headerIcon: JSX.Element;
  label: string;
  currentSelectedItem?: string | null;
  initOpen?: boolean;
  onReorderItems?: (items: T[]) => void;
  onAddItem: () => void;
  onSelectItem: (item: T) => void;
};

const ExpandableList = <T extends { id: string; label: string; },>(props: ExpandableListProps<T>): React.ReactElement => {
  const { items, headerIcon, label, currentSelectedItem, initOpen, onReorderItems, onAddItem, onSelectItem } = props;
  const [collapsed, setCollapsed] = useState(!initOpen);

  const toggleList = (): void => setCollapsed((v) => !v);

  return (
    <div className={s.listContainer}>
      <button className={classNames(s.listToggle, collapsed && s.fullRadius)} onClick={toggleList}>
        {collapsed ? headerIcon : <ArrowLineUp />}
        <span className={s.listLabel}>{pluralize(label, 2)}</span>
      </button>
      {!collapsed && (
        typeof onReorderItems === 'function'
          ? (
            <Reorder.Group
              as="div"
              axis="y"
              values={items}
              className={s.listContent}
              onReorder={onReorderItems}
            >
              {items.map(item => (
                <ExpandableListItem
                  key={item.id}
                  item={item}
                  isSelected={item.id === currentSelectedItem}
                  onClick={() => onSelectItem(item)}
                />
              ))}
            </Reorder.Group>
          )
          : (
            <div className={s.listContent}>
              {items.map(item => (
                <div
                  key={item.id}
                  className={classNames(itemStyles.listItem, item.id === currentSelectedItem && itemStyles.selected)}
                  onClick={() => onSelectItem(item)}
                >
                  <span>{item.label}</span>
                </div>
              ))}
            </div>
          ))}

      {!collapsed && (
        <button
          className={classNames(s.addListItemButton, collapsed && s.fullRadius)}
          onClick={onAddItem}
        >
          <PlusCircle weight="fill" /> {`Add ${pluralize(label, 1)}`}
        </button>
      )}
    </div>
  );
};

export default ExpandableList;
