import { useEffect, useState, forwardRef, useImperativeHandle, useRef } from 'react';
import Spacer from '@components/Spacer';
import Tabs from '@components/DesignLibrary/Tabs';
import removeNullish from '@helpers/removeNullish';
import useConfido from '@providers/Confido/useConfido';
import useNoodleApi from '@hooks/useNoodleApi';
import * as tsClient from '@tsClient';
import ConfidoHostedField from '@components/ConfidoHostedField';
import Buttons from '@components/Buttons';
import classes from '@components/StripeCartPaymentFormStep/stripeCartPaymentFormStep.module.scss';
import PlusCircle from '@components/Icons/PlusCircle';
import BackArrow from '@components/Icons/BackArrow';
import { CardDetails } from '@components/Payment/PaymentMethods';
import * as ApiModels from '@typings/api-models';
import ACHDetails from '@components/Payment/PaymentMethods/components/ACHDetails';
import classNames from 'classnames';
import { logError } from '@providers/ErrorTracking';
import InputField from '@components/FormFields/InputField';
import s from './SelectConfidoPaymentMethod.module.scss';
import { PaymentType } from './typings';
import NotTabsHeader from './NoTabsHeader';

type PaymentMethod = {
  id: string;
  lastFour: string;
  paymentMethod: string;
  cardBrand: string | null;
};

type Props = {
  isDebitCardPaymentEnabled: boolean;
  isDebitCardPaymentRequiredForCustomer: boolean;
  isCreditPaymentEnabled: boolean;
  isAchPaymentEnabled: boolean;
  onPaymentMethodAvailableChange: (isPaymentMethodAvailable: boolean) => void;
  confidoPaymentMethodToken: string;
  person: Pick<ApiModels.Person, 'id' | 'name'>;
  creator: Pick<ApiModels.Creator, 'id' | 'slug'> & Parameters<typeof CardDetails>[0]['creator'];
  selectedPaymentMethodId?: string | null;
};

// eslint-disable-next-line prefer-arrow-callback
const SelectConfidoPaymentMethod = forwardRef(function SelectPaymentMethod({
  isDebitCardPaymentEnabled,
  isDebitCardPaymentRequiredForCustomer,
  isCreditPaymentEnabled,
  isAchPaymentEnabled,
  onPaymentMethodAvailableChange,
  confidoPaymentMethodToken,
  person,
  creator,
  selectedPaymentMethodId,
}: Props, ref) {
  const [paymentType, setPaymentType] = useState<PaymentType>((isCreditPaymentEnabled || isDebitCardPaymentEnabled) ? 'Card' : 'ACH');
  const [error, setError] = useState<string | null>(null);
  const [useDifferentPaymentMethod, setUseDifferentPaymentMethod] = useState(false);
  const [isFetchingPaymentMethods, setIsFetchingPaymentMethods] = useState(true);
  const [availablePaymentMethods, setAvailablePaymentMethods] = useState<PaymentMethod[]>([]);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<string | null>(selectedPaymentMethodId || null);
  const [payerName, setPayerName] = useState('');
  const onPaymentMethodAvailableChangeRef = useRef(onPaymentMethodAvailableChange);
  onPaymentMethodAvailableChangeRef.current = onPaymentMethodAvailableChange;

  const { getData: savePaymentMethod } = useNoodleApi(tsClient.my.saveConfidoPaymentMethod);
  const { data: allPaymentMethods, getData: getPaymentMethodsForPersonFn } = useNoodleApi(tsClient.my.getConfidoPaymentMethodsForCreator);

  const showCreditCardForm = paymentType === 'Card' && (isCreditPaymentEnabled || isDebitCardPaymentEnabled) && useDifferentPaymentMethod;
  const showAchForm = paymentType === 'ACH' && isAchPaymentEnabled && useDifferentPaymentMethod;
  const needsConfido = showCreditCardForm || showAchForm;
  const showTabs = (isDebitCardPaymentEnabled || isCreditPaymentEnabled) && isAchPaymentEnabled;

  const isCustomerPaymentMethod = (methodPayerName: string): boolean => {
    const personNameParts = (person.name || '').split(' ');
    const payerNameParts = methodPayerName.split(' ');
    // Return true if there is overlap between customer and payer name
    return personNameParts.every((part) => payerNameParts.includes(part)) || payerNameParts.every((part) => personNameParts.includes(part));
  };

  const confido = useConfido({
    formType: paymentType === 'ACH' ? 'ach' : 'card',
    isAchPaymentEnabled,
    isCreditPaymentEnabled,
    isCustomerPaymentMethod: isCustomerPaymentMethod(payerName),
    isDebitCardPaymentEnabled,
    isDebitCardPaymentRequiredForCustomer,
    paymentMethodToken: confidoPaymentMethodToken,
  });

  const handleChangePaymentType = (newPaymentType: PaymentType): void => {
    setUseDifferentPaymentMethod(false);
    setSelectedPaymentMethod(null);
    setPaymentType(newPaymentType);
  };

  const handleClickAddNewPaymentMethod = (): void => {
    setUseDifferentPaymentMethod(true);
    setSelectedPaymentMethod(null);
  };

  const handleClickUseSavedPaymentMethod = (): void => {
    setUseDifferentPaymentMethod(false);
    setSelectedPaymentMethod(null);
  };

  const getAvailableCardTypes = (): string => [
    isCreditPaymentEnabled ? 'Credit' : null,
    isDebitCardPaymentEnabled ? 'Debit' : null,
  ].filter(removeNullish).join(' or ');

  const getPaymentMethodId = async (): Promise<string | null> => {
    if (selectedPaymentMethod) {
      return selectedPaymentMethod;
    }
    const { error: confidoError } = await window.gravityLegal.submitFields();

    if (confidoError) {
      logError(confidoError);
      setError(confidoError?.message);
      return null;
    }
    const { data: paymentMethod, error: saveError } = await savePaymentMethod({
      creatorId: creator.id,
      forUserId: person.id,
      payerName,
      paymentMethodType: paymentType === 'Card' ? 'CREDIT' : 'ACH',
      savePaymentMethodToken: confidoPaymentMethodToken,
    });

    if (saveError) {
      setError(saveError.message);
    }

    return paymentMethod?.id || null;
  };

  useImperativeHandle(ref, () => ({
    getPaymentMethodId,
  }), [getPaymentMethodId]);

  useEffect(() => {
    const isButtonEnabled = (): boolean => {
      if (!useDifferentPaymentMethod) {
        return !!selectedPaymentMethod;
      }

      if (!payerName) {
        return false;
      }

      if (!confido.state?.paymentMethod) {
        return false;
      }

      if (paymentType === 'Card') {
        return (
          !confido.state?.fields.cardNumber?.error
          && !confido.state?.fields.cardSecurityCode?.error
          && !confido.state?.fields.cardExpirationDate?.error
        );
      }

      if (paymentType === 'ACH') {
        return (
          !confido.state?.fields.accountHolderName?.error
          && !confido.state?.fields.accountNumber?.error
          && !confido.state?.fields.routingNumber?.error
        );
      }
      return false;
    };
    onPaymentMethodAvailableChangeRef.current(isButtonEnabled());
  }, [confido.state, paymentType, useDifferentPaymentMethod, selectedPaymentMethod, payerName]);

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      setIsFetchingPaymentMethods(true);
      await getPaymentMethodsForPersonFn({ creatorId: creator.id, forUserId: person.id });
      setIsFetchingPaymentMethods(false);
    };
    fetchData();
  }, [getPaymentMethodsForPersonFn, creator, person, paymentType]);

  useEffect(() => {
    if (!allPaymentMethods) {
      setSelectedPaymentMethod(null);
      setUseDifferentPaymentMethod(false);
      setAvailablePaymentMethods([]);
    } else {
      const newSelectablePaymentMethods = allPaymentMethods.filter((method) => {
        if (paymentType === 'ACH') {
          return method.paymentMethod === 'ACH';
        }
        if (method.paymentMethod === 'CREDIT') {
          return isCreditPaymentEnabled && (isDebitCardPaymentRequiredForCustomer
            ? method.payerName && !isCustomerPaymentMethod(method.payerName)
            : true);
        }
        if (method.paymentMethod === 'DEBIT') {
          return isDebitCardPaymentEnabled;
        }
        return false;
      });
      setSelectedPaymentMethod(newSelectablePaymentMethods.length > 0 ? newSelectablePaymentMethods[0].id : null);
      setUseDifferentPaymentMethod(newSelectablePaymentMethods.length === 0);
      setAvailablePaymentMethods(newSelectablePaymentMethods);
    }
  }, [allPaymentMethods, paymentType, isCreditPaymentEnabled, isDebitCardPaymentEnabled]);

  useEffect(() => {
    if (!isFetchingPaymentMethods && needsConfido) {
      confido.reload();
    }
  }, [confido.reload, needsConfido, isFetchingPaymentMethods]);

  return (
    <>
      {error && <p className='caption' style={{ color: 'var(--color-error)' }}>Failed to save payment method. Please try again.</p>}
      {showTabs
        ? (
          <div>
            <span className='body-sm-bold'>Select payment method</span>
            <Spacer size={12} />
            <Tabs
              inputName={'paymentType'}
              inputs={[
                {
                  id: 'Card',
                  label: `${getAvailableCardTypes()} Card`,
                },
                {
                  id: 'ACH',
                  label: 'Bank Transfer (ACH)',
                },
              ]}
              onChange={handleChangePaymentType}
            />
          </div>
        )
        : (
          <NotTabsHeader
            paymentType={paymentType}
            isCreditPaymentEnabled={isCreditPaymentEnabled}
            isDebitCardPaymentEnabled={isDebitCardPaymentEnabled}
          />
        )
      }

      {useDifferentPaymentMethod && availablePaymentMethods.length > 0 && (
        <>
          <Buttons className={classes.newPaymentButton} isWrapper onClick={() => handleClickUseSavedPaymentMethod()}>
            <BackArrow weight="fill" size={24} color="var(--color-noodle)" />
            <span>Use saved payment method</span>
          </Buttons>
          <Spacer />
        </>
      )}

      {!useDifferentPaymentMethod && (
        <>
          <Buttons className={classes.newPaymentButton} isWrapper onClick={() => handleClickAddNewPaymentMethod()}>
            <PlusCircle weight="fill" size={24} color="var(--color-noodle)" />
            <span>Use a different payment method</span>
          </Buttons>
          <Spacer />
        </>
      )}

      {!useDifferentPaymentMethod && availablePaymentMethods.map((method) => (
        <Buttons key={method.id} isWrapper isFullWidth onClick={() => setSelectedPaymentMethod(method.id)}>
          <div className={classNames(s.paymentMethod, selectedPaymentMethod === method.id ? s.selected : undefined)}>
            {method.paymentMethod === 'ACH'
              ? (
                <ACHDetails
                  account={{
                    bank_name: 'Bank account',
                    last4: method.lastFour,
                  }}
                  verifyWithMicrodeposits={null}
                  isPrimary={false}
                />
              )
              : (
                <CardDetails
                  card={{
                    brand: method.cardBrand,
                    last4: method.lastFour,
                  }}
                  creator={creator}
                  isPrimary={false}
                  personId={person.id}
                />
              )}
          </div>
        </Buttons>
      ))}
      {showCreditCardForm && (
        <div className={s.inputs}>
          <ConfidoHostedField
            id={'card-number'}
            fieldState={confido.state?.fields.cardNumber}
            label={'Card Number'}
          />
          <div className={s.inputsHorizontal}>
            <ConfidoHostedField
              id={'card-exp'}
              fieldState={confido.state?.fields.cardExpirationDate}
              label={'Card Expiration'}
            />
            <ConfidoHostedField
              id={'card-cvv'}
              fieldState={confido.state?.fields.cardSecurityCode}
              label={'Card CVV'}
            />
          </div>
          <InputField
            id="payerName"
            label="Name on card"
            name="payerName"
            values={{ payerName }}
            placeholder="Enter the name on card"
            onChange={setPayerName}
            autoComplete={'name'}
          />
        </div>
      )}
      {showAchForm && (
        <div className={s.inputs}>
          <ConfidoHostedField
            id={'account-number'}
            fieldState={confido.state?.fields.accountNumber}
            label={'Account Number'}
          />
          <ConfidoHostedField
            id={'account-holder-name'}
            fieldState={confido.state?.fields.accountHolderName}
            label={'Account Holder Name'}
          />
          <ConfidoHostedField
            id={'routing-number'}
            fieldState={confido.state?.fields.routingNumber}
            label={'Routing Number'}
          />
        </div>
      )}
    </>
  );
});

export default SelectConfidoPaymentMethod;
