import * as ApiModels from '@typings/api-models';
import s from './ACHDetails.module.scss';

type Props = {
  verifyWithMicrodeposits: Pick<NonNullable<ApiModels.PaymentMethod['verifyWithMicrodeposits']>, 'verificationUrl'> | null;
};

const PendingVerification: React.FC<Props> = ({ verifyWithMicrodeposits }) => {
  if (!verifyWithMicrodeposits) {
    return null;
  }

  return (
    <div className={s['pending-verification-container']}>
      <span className={s.title}>Pending Verification</span>
      <span className={s.instructions}>
        Stripe will add micro-deposits to this account.
        Follow the instructions via email, or enter the amount of the micro-deposits here when they appear on your account.
      </span>
    </div>
  );
};

export default PendingVerification;