import { useEffect, useState, useMemo } from 'react';
import useNoodleApi from '@hooks/useNoodleApi';
import * as tsClient from '@tsClient';
import { capitalize } from 'lodash';
import Tabs from '@components/DesignLibrary/Tabs';
import Header from '@components/DesignLibrary/Header';
import ProgressIndicator from '@/components/ProgressIndicator';
import Spacer from '@components/Spacer';
import s from './CreditReportDetails.module.scss';
import CreditReportResponse from './CreditReportResponse';

type Props = {
  creditReportId: string;
};

const CreditReportDetails: React.FC<Props> = ({ creditReportId }) => {
  const [tab, setTab] = useState('');

  const {
    data: creditReportResponses,
    getData: getCreditReportResponsesFn,
  } = useNoodleApi(tsClient.creditReportsB2C.getCreditReportResponses);

  useEffect(() => {
    getCreditReportResponsesFn({ creditReportId });
  }, [getCreditReportResponsesFn, creditReportId]);

  const responseSlugs: string[] = useMemo(() => creditReportResponses
    ?.map(({ slug }: { slug: string }) => slug)
    .sort((a, _) => (a === 'main-debtor' ? -1 : 1)) ?? [], [creditReportResponses]);

  useEffect(() => {
    if (responseSlugs) {
      setTab(responseSlugs[0]);
    }
  }, [responseSlugs]);

  return (
    <div className={s.wrapper}>
      <Header
        title="Credit Report"
      />
      {creditReportResponses
        ? (
          <>
            <Tabs
              inputName="debtorType"
              inputs={responseSlugs.map((slug) => ({
                id: slug,
                label: capitalize(slug.replace('-', ' ')),
              }))}
              onChange={(val) => setTab(val)}
            />
            <CreditReportResponse creditReportResponse={creditReportResponses?.find(({ slug }: {slug: string}) => slug === tab)} />
          </>
        )
        : (
          <>
            <Spacer />
            <ProgressIndicator isCentered />
          </>
        )}
    </div>
  );
};

export default CreditReportDetails;
