import React, { ReactElement, useEffect, useState } from 'react';
import Buttons from '@components/Buttons';
import useNoodleApi from '@hooks/useNoodleApi';
import { deserializeHtml } from '@components/SlateEditor';
import { Descendant } from 'slate';
import createRichTextAst from '@helpers/createRichTextAst';
import ProgressIndicator from '@components/ProgressIndicator';
import InputComposer from '@components/InputComposer';
import updateWorkflowStep from '@tsClient/workflows/updateWorkflowStep';
import { AssignToData, MESSAGE_ATTACHMENT_REFERENCE_TYPES, SlateNode } from '@typings/api-models';
import s from './EditSendMessageActionForm.module.scss';

export type SendMessageActionData = {
  message: {
    attachments: Array<{
      referenceId: string;
      referenceType: MESSAGE_ATTACHMENT_REFERENCE_TYPES;
      title: string;
      id: string;
    } & { assignTo?: AssignToData[] }>;
    slate: SlateNode[],
    html: string,
    text: string,
    medias: { id: string; name: string; }[],
    handbooks: { id: string; title?: string | null; }[],
  },
};

type Props = {
  actionId: string;
  messageData?: SendMessageActionData | null;
  workflowId: string;
  onSave: () => void;
};

type Values = {
  attachments: { referenceId: string; referenceType: MESSAGE_ATTACHMENT_REFERENCE_TYPES; title: string; id: string; }[];
  slate: { children: Descendant[] }[] | null;
  media: { id: string; name: string; }[];
  files: (File | string)[];
  handbooks: { id: string; title?: string | null; }[];
};

const EditSendMessageActionForm = ({
  actionId,
  messageData,
  workflowId,
  onSave,
}: Props): ReactElement => {
  const [isSaving, setIsSaving] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);

  const [values, setValues] = useState<Values>({
    attachments: messageData?.message?.attachments || [],
    files: [],
    handbooks: messageData?.message?.handbooks || [],
    media: messageData?.message?.medias || [],
    slate: messageData?.message ? null : [createRichTextAst('')],
  });

  const handleSlateChange = (slate: { children: Descendant[] }[]): void => {
    setValues(val => ({ ...val, slate }));
  };
  const handleMediaChange = (media: { id: string; name?: string | null; uploadPercentage?: number }[]): void => {
    setValues(val => ({
      ...val,
      media: media.map((m) => ({
        ...m,
        name: m.name || 'media',
      })),
    }));
  };
  const handleHandbookChange = (handbooks: { id: string; title?: string | null; }[]): void => {
    setValues(val => ({ ...val, handbooks }));
  };

  const handleAttachmentChange = (attachments: {
    referenceId: string;
    referenceType: MESSAGE_ATTACHMENT_REFERENCE_TYPES;
    title: string;
    id: string;
  }[]): void => {
    setValues(val => ({ ...val, attachments }));
  };

  const { getData: updateWorkflowStepFn } = useNoodleApi(updateWorkflowStep, { toastOnError: true });

  useEffect(() => {
    if (messageData?.message && messageData?.message.html) {
      const document = new DOMParser().parseFromString(messageData?.message.html, 'text/html');
      setValues(oldValues => ({
        ...oldValues,
        slate: deserializeHtml(document.body) as { children: Descendant[] }[],
      }));
    }
    setIsInitialized(true);
  }, [messageData?.message]);

  if (!isInitialized) {
    return <ProgressIndicator isCentered/>;
  }

  const handleSave = async (): Promise<void> => {
    setIsSaving(true);
    const sendMessageData = {
      attachments: values.attachments,
      handbooks: values.handbooks,
      medias: values.media,
      slate: values.slate,
    };
    await updateWorkflowStepFn({
      id: workflowId,
      stepId: actionId,
      updates: {
        data: { message: sendMessageData },
      },
    });
    onSave();
  };

  return (
    <>
      <div className={s.craftWelcomeMessageForm}>
        <div>
          <InputComposer
            isRichText
            rows={3}
            showAddBooking
            showAddVideo
            showAddAnyFile
            showAddDocumentRequest
            showAddCreditReport
            showAddFormRequest
            showAddPaymentLink
            showAddInvoice
            showAddCustomTerms
            showSendButton={false}
            onSend={handleSave}
            onChangeMedia={handleMediaChange}
            onChangeHandbooks={handleHandbookChange}
            onChangeText={handleSlateChange}
            onChangeAttachments={handleAttachmentChange}
            showProTips={false}
            initialText={values.slate}
            initialAttachments={values.attachments}
            initialMedia={values.media}
            initialHandbooks={values.handbooks}
            isFetching={false}
            placeholder='Type your message'
            label='Message Body'
            showAddHandbook
            referenceId={'workflowId'}
            referenceType={'action-message'}
          />
        </div>
        <Buttons
          isFetching={isSaving}
          isFullWidth
          isSecondary
          onClick={handleSave}
        >
          Update Message
        </Buttons>
      </div>
    </>
  );
};

export default EditSendMessageActionForm;
