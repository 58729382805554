import { ReactElement } from 'react';
import Modal from '@components/Modal';
import DOMPurify from 'isomorphic-dompurify';
import s from './FollowupTextModal.module.scss';

type Props = {
  isForSms?: boolean;
  followupText: string;
  onClose: () => void;
};

const FollowupTextModal = ({ isForSms, followupText, onClose }: Props): ReactElement => (
  <Modal
    hideBottomInset

    title={isForSms ? 'Message Content' : 'Followup Message'}
    onClose={onClose}

  >
    <div className={s.followupTextModal}>
      <div className={s.text} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(followupText || '') }} />
    </div>
  </Modal>
);

export default FollowupTextModal;
