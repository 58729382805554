import { TD } from '@/components/DesignLibrary/Table';
import Button from '@/components/DesignLibrary/Button';
import s from './OpenOnClick.module.scss';

export type Props = {
  type: 'open-on-click';
  label: string;
  onClick: () => void;
  cta: string;
};

const OpenOnClick: React.FC<Props> = ({ label, cta, onClick }) => {
  const handleClick = (): void => {
    onClick();
  };

  return (
    <TD compact>
      <p className="caption">{label}</p>
      <Button variant='secondary' size="xs" onClick={handleClick} className={s.cta}>{cta}</Button>
    </TD>
  );
};

export default OpenOnClick;
