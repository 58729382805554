import { isBrowser } from 'helpers/helper';
import React, { Fragment, lazy, Suspense } from 'react';

const RenderLink = lazy(() => import('./RenderLink'));

const RenderLinkTemplate: React.FC = () => <div />;

type Props = Parameters<typeof RenderLink>[0];

const RenderLinkCmp: React.FC<Props> = (props) => (
  <Fragment>
    {isBrowser()
      ? (
        <Suspense fallback={<RenderLinkTemplate />}>
          <RenderLink {...props} />
        </Suspense>
      )
      : (
        <RenderLinkTemplate />
      )}
  </Fragment>
);

export default RenderLinkCmp;
