/* eslint-disable import/prefer-default-export */
export function downloadJsonAsFile<T>(jsonData: T, filename: string): void {
  const jsonString = JSON.stringify(jsonData, null, 2);
  const blob = new Blob([jsonString], { type: 'application/json' });
  const link = document.createElement('a');

  link.href = URL.createObjectURL(blob);
  link.download = `${filename}.json`;

  document.body.appendChild(link);

  link.click();

  document.body.removeChild(link);
}
