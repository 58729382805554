import CircularProgress from '@/components/CircularProgress';
import { TD } from '@/components/DesignLibrary/Table';

export type Props = {
  type: 'progress';
  progress: number;
};

const ProgressCell: React.FC<Props> = ({ progress }) => (
  <TD>
    <CircularProgress size={16} progress={progress} />
  </TD>
);

export default ProgressCell;