import React, { ReactElement, useState } from 'react';
import CheckCircle from '@components/Icons/CheckCircle';
import Buttons, { ButtonProps } from 'components/Buttons/Buttons';

type Props = ButtonProps & {
  text: string;
  children: React.ReactNode;
};

function CopyButton({ text, children = 'Copy', ...restProps }: Props): ReactElement {
  const [buttonText, setButtonText] = useState(children);

  const copy = async (): Promise<void> => {
    try {
      await navigator.clipboard.writeText(text);
      setButtonText('Copied to clipboard!');
    } catch (e) {
      setButtonText('Error!');
    }
    setTimeout(() => {
      setButtonText(children);
    }, 2000);
  };

  return (
    <Buttons
      {...restProps}
      {...(buttonText === 'Copied!' && {
        iconBefore: <CheckCircle weight="fill" />,
      })}
      onClick={copy}
    >
      {buttonText}
    </Buttons>
  );
}

export default CopyButton;
