import LoggedInUserGate from '@components/Auth/LoggedInUserGate';
import { DocumentAccessProvider } from '@providers/DocumentAccess';
import DocumentRequestUserFileDetail from './DocumentRequestUserFileDetail';

type Props = Omit<Parameters<typeof DocumentRequestUserFileDetail>[0], 'children'>;

const DocumentRequestUserFileDetailWithAccessProvider: React.FC<Props> = (props) => (
  <LoggedInUserGate isAnonymousAllowed={false}>
    <DocumentAccessProvider>
      <DocumentRequestUserFileDetail {...props} />
    </DocumentAccessProvider>
  </LoggedInUserGate>
);

export default DocumentRequestUserFileDetailWithAccessProvider;
