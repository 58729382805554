import classNames from 'classnames';
import * as ApiModels from '@typings/api-models';
import LocalCurrencyConversion from '@components/LocalCurrencyConversion';
import getSmallestActivePrice from './getSmallestPrice';
import s from './SmallestPrice.module.scss';

type Props = {
  canEdit: boolean;
  prices: Array<Pick<ApiModels.Price, 'price' | 'currency' | 'isActive'>>;
};

const SmallestPrice: React.FC<Props> = ({ canEdit, prices }) => {
  const smallestActivePrice = getSmallestActivePrice(prices);

  return (
    <small className={classNames(!smallestActivePrice ? s.redText : undefined, s.smallText)}>
      {!smallestActivePrice && canEdit && 'Needs prices!'}
      {smallestActivePrice && (
        <>
          {(smallestActivePrice.price === 0 && prices.length === 1 && `Free`)
            || (smallestActivePrice.price === 0 && prices.length > 1 && 'Start for free') || (
            <>
                From{' '}
              <LocalCurrencyConversion
                price={smallestActivePrice.price}
                currency={smallestActivePrice.currency}
                noStyles
                roundUp
                progressConfig={{ isInline: true, size: 10 }}
              />
            </>
          )}
        </>
      )}
    </small>
  );
};

export default SmallestPrice;
