import { FC } from 'react';
import CustomLink from '@components/CustomLink';
import ProgressIndicator from '@components/ProgressIndicator';
import type { Icon } from '@phosphor-icons/react';
import Image from 'next/image';

import s from './ListCellNavigation.module.scss';

const ListCellNavigation: FC<{
  onClick?: () => void;
  label: string;
  href?: string;
  icon?: Icon;
  isMuted?: boolean;
  isRouting?: boolean;
  isSelected?: boolean;
  isAi?: boolean;
  newTab?: boolean;
  indented?: boolean;
}> = ({ label, href, icon, isRouting, isSelected, isMuted, isAi, newTab, onClick, indented }) => {
  const IconComponent = icon;

  if (onClick) {
    return (
      <li className={s.item}>
        <button onClick={onClick} style={indented ? { paddingLeft: 40 } : undefined}>
          {IconComponent && (isRouting ? <ProgressIndicator /> : <IconComponent weight={isSelected ? 'fill' : 'regular'} size={24} />)}
          <div className={s.content}>
            <p>{label}</p>
          </div>
          {(isRouting && !IconComponent && <ProgressIndicator size={16} />)}
        </button>
      </li>
    );
  }
  return (
    <li
      className={s.item}
    >
      <CustomLink
        newTab={newTab}
        to={href}
        {...(((isSelected || isMuted || indented) && {
          style: {
            ...(isSelected && { backgroundColor: 'var(--color-gray-15)' }),
            ...(isMuted && { opacity: 0.5 }),
            ...(indented && { paddingLeft: 40 }),
          },
        })
          || undefined)}
      >
        {isAi
          ? <Image src='/images/dashboard/ai-icon.webp' className={s.ai} alt='' width={20} height={20} />
          : <>
            {IconComponent && (isRouting ? <ProgressIndicator /> : <IconComponent weight={isSelected ? 'fill' : 'regular'} size={24} />)}
          </>}
        <div className={s.content}>
          <p>{label}</p>
        </div>
        {(isRouting && !IconComponent && <ProgressIndicator size={16} />)}
      </CustomLink>
    </li>
  );
};

export default ListCellNavigation;
