import React, { FC, Fragment, forwardRef } from 'react';
import * as format from '@format';
import CalendarBlank from '@components/Icons/CalendarBlank';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Buttons from '@components/Buttons';
import s from './DatePicker.module.scss';

type Props = {
  value?: string | null;
  onChange: (str: string) => void;
  max?: string;
  min?: string;
  inlineLabel?: boolean;
  label: string;
};

const DatePicker: FC<Props> = ({ onChange, value, label, inlineLabel, max, min }) => {
  const Component = inlineLabel ? Fragment : 'div';

  const CustomInput = forwardRef<HTMLButtonElement, { onClick?: () => void }>(({ onClick }, ref) => (
    <Buttons isWrapper className={s.button} onClick={onClick} ref={ref}>
      <CalendarBlank size={16} />
      {inlineLabel && <p className="caption">{label}:</p>}
      <p className="caption">{value ? format.datetime.abbreviated(value) : 'NA'}</p>
    </Buttons>
  ));

  CustomInput.displayName = 'CustomInput';

  return (
    <div className={s.datepicker}>
      <Component {...(inlineLabel ? {} : { className: s.label })}>
        {!inlineLabel && <span className="caption">{label}</span>}
        <ReactDatePicker
          showPopperArrow={false}
          customInput={<CustomInput />}
          minDate={new Date(`${min}T00:00:00`)}
          maxDate={new Date(`${max}T00:00:00`)}
          value={value || undefined}
          onChange={date => { onChange(date ? date?.toISOString() : ''); }}
        />
      </Component>
    </div>
  );
};

export default DatePicker;
