import { useCallback, useState } from 'react';
import Modal from '@components/Modal';
import Buttons from '@components/Buttons';
import Spacer from '@components/Spacer';
import requestWithCatch from '@helpers/requestWithCatch';
import * as tsClient from '@tsClient';
import PrettyJson from './PrettyJson';
import s from './DocumentPreview.module.scss';

type Props = {
  onClose: () => void;
  base64Blob: string | null | undefined;
  documentId: string;
  documentRequestFileName: string | null | undefined;
  documentRequestFileDescription: string | null | undefined;
};

const generateDocumentAnalysis = requestWithCatch(tsClient.ai.generateDocumentAnalysis);

const STARTING_PROMPT_FROM_BE = `
  I need your help reviewing a document to make sure it matches the provided criteria - title and description (if provided).
  The document may include multiple pages, all of which are attached as images here. Sometimes there may only be 1 page.
  Analyze the pages/images. Generally, the uploaded images should match the criteria.
  You may not be given all pages (due to the limitation in the number of images we can upload to you at any one time) - so, make an informed decision when providing your analysis in the format we specify below.
  For example, if you are being asked for a blank w2, and all of the uploaded pages appear to be a blank w2, you can assume it might be correct even if you do not have all pages of the blank w2.
  If you are unable to make a reasonable assumption, set the document match to false.
  In your one line summary, provide whatever context you think necessary and any assumptions you make.

  ###

  ALWAYS ADHERE TO THE FOLLOWING RESPONSE FORMAT SPECIFICATIONS:
  All keys must be exactly as specified below.
  Return valid complete json that is trimmed of necessary extra whitespace.
  All leaf values must be strings.

  Two top level keys:
    \`documentValidation\` and \`oneLineSummary\`
      For \`documentValidation\`, please add keys for:
        \`isBlurry\` - \`true\` or \`false\`
        \`matchesDocumentDescription\` - if the provided document matches the title and description (if provided) then \`true\`. If not, \`false\`,
        \`isExpired\` with values of \`true\`, \`false\`, or \`null\` if unknown.
        \`matchesDocumentDescriptionFailReason\` - if \`matchesDocumentDescription\` is \`false\`, add a short sentence explaining why it failed. If not, null.
      For \`oneLineSummary\`, Please add a short sentence to describe the image.

  For example, if the user were to upload a W-2 form that isn't blurry when asked for a permanent resident card, the output would appear as:

  {
    "summary": "The images contain various pages of IRS W-2 forms and instructions for the year 2024.",
    "documentAnalysis": {
      "isBlurry": "false",
      "isExpired": "null",
      "matchesDocumentDescription": "false",
      "matchesDocumentDescriptionFailReason": "The provided images are of IRS W-2 forms and instructions, not the front of a conditional resident's Permanent Resident Card."
    }
  }
`;

const DEFAULT_IMAGE_JSON_DATA = JSON.stringify({
  document: 'has not yet been processed',
});

const AIReaderModal: React.FC<Props> = ({
  onClose,
  base64Blob,
  documentId,
  documentRequestFileDescription,
  documentRequestFileName,
}) => {
  const [prompt, setPrompt] = useState(STARTING_PROMPT_FROM_BE);
  const [isSaving, setIsSaving] = useState(false);
  const [imageJSONData, setImageJSONData] = useState(DEFAULT_IMAGE_JSON_DATA);

  const onAiRead = useCallback(() => {
    if (
      !base64Blob
      || !documentId
      || !documentRequestFileDescription
      || !documentRequestFileName
    ) {
      return;
    }

    setIsSaving(true);
    generateDocumentAnalysis({
      base64Blob,
      documentDescription: documentRequestFileDescription,
      documentId,
      documentTitle: documentRequestFileName,
      prompt,
    }).then((response) => {
      setImageJSONData(response?.data?.generatedText || JSON.stringify({
        aiReader: "no data returned",
      }));
    }).finally(() => {
      setIsSaving(false);
    });
  }, [setIsSaving, base64Blob, prompt, documentId, documentRequestFileDescription, documentRequestFileName]);

  return (
    <Modal
      hideBottomInset

      title="Noodle AI Document Review"
      onClose={onClose}

    >
      <div
        style={{
          padding: 24,
        }}
      >
        <label className={s.prompt}>
          Prompt
          <textarea
            placeholder={`Enter prompt`}
            onChange={(event) => {
              setPrompt(event.currentTarget.value);
            }}
            disabled={isSaving}
            value={prompt}
            rows={6}
            style={{
              width: "100%",
            }}
          />
        </label >
        <Spacer size={12}/>
        <PrettyJson
          stringifiedJson={imageJSONData}
        />
        <Spacer size={12}/>
        <Buttons isSecondary isFullWidth onClick={onAiRead} isFetching={isSaving}>
          {isSaving ? "Processing": "Process with AI"}
        </Buttons>
      </div>
    </Modal>
  );
};

export default AIReaderModal;
