import axios from 'axios';
import { m, AnimatePresence } from 'framer-motion';
import React, { Fragment, ReactElement, useEffect, useMemo, useState, useContext } from 'react';
import { type Icon as IconType } from '@phosphor-icons/react';
import useForm from 'hooks/useForm';
import MagnifyingGlass from 'components/Icons/MagnifyingGlass';
import Image from 'components/Image';
import Buttons from 'components/Buttons';
import RenderLink from 'components/RenderLink';
import OutsideClick from 'components/OutsideClick';
import ChatDropDown from 'components/Chat/ChatDropDown';

import { GOOGLE_API_KEY } from '@configuration/client';
import { getCreatorContent, getProductsForSharing } from '@tsClient';
import { ContentLibraryTypes, NoodleProductTypes, Product, ProductType } from '@typings/graphql-models';
import classNames from 'classnames';
import useNoodleApi from '@hooks/useNoodleApi';
import uniqBy from 'lodash/uniqBy';
import { mixpanelTrack } from '@providers/Mixpanel';
import ArrowCircleLeft from '@components/Icons/ArrowCircleLeft';
import Cookie from '@components/Icons/Cookie';
import XCircle from '@components/Icons/XCircle';
import NoteBlank from '@components/Icons/NoteBlank';
import ArrowCircleUpRight from '@components/Icons/ArrowCircleUpRight';
import Link from '@components/Icons/Link';
import YoutubeLogo from '@components/Icons/YoutubeLogo';
import VideoCamera from '@components/Icons/VideoCamera';
import InstagramLogo from '@components/Icons/InstagramLogo';
import TiktokLogo from '@components/Icons/TiktokLogo';
import TwitterLogo from '@components/Icons/TwitterLogo';
import MonitorPlay from '@components/Icons/MonitorPlay';
import FacebookLogo from '@components/Icons/FacebookLogo';
import Files from '@components/Icons/Files';

import TeamsContext from '@providers/Teams/TeamsContext';
import s from './InputComposer.module.scss';

type Media = {
  id: string;
  imageUrl?: string;
  mimetype: string;
  title: string;
  type: ContentLibraryTypes;
  url: string;
};

type Field = {
  hasFixedHeight: boolean;
  iconBefore?: ReactElement;
  name: string;
  placeholder?: string;
  label?: string;
  type?: string;
};

type ContentMediaType = {
  id: number;
  type: ContentLibraryTypes;
};

type Props = {
  onSendMessage: (product: Pick<Product, 'id' | 'title' | 'image' | 'description'>) => void;
  onAddLink: (link: string) => void;
  onAddFiles: (el: Media) => void;
  disabled: boolean;
};

const rangeNoteFields = (placeholder: string): Field[] => [
  {
    hasFixedHeight: false,
    iconBefore: <MagnifyingGlass weight="bold" />,
    name: 'search',
    placeholder,
  },
  {
    hasFixedHeight: false,
    label: 'Send as a gift',
    name: 'gift',
    type: 'checkbox',
  },
];

const detectMediaIcon = (mediaType: ContentLibraryTypes): IconType => {
  switch (mediaType) {
  case ContentLibraryTypes.YouTube:
    return YoutubeLogo;
  case ContentLibraryTypes.Vimeo:
    return VideoCamera;
  case ContentLibraryTypes.Instagram:
    return InstagramLogo;
  case ContentLibraryTypes.TikTok:
    return TiktokLogo;
  case ContentLibraryTypes.Twitter:
    return TwitterLogo;
  case ContentLibraryTypes.Videos:
    return MonitorPlay;
  case ContentLibraryTypes.Facebook:
    return FacebookLogo;
  case ContentLibraryTypes.Files:
    return Files;
  default:
    return Files;
  }
};

const detectHandbookProductTypeIcon = (el: Pick<ProductType, 'noodleProductType'>): IconType => {
  if (el.noodleProductType === NoodleProductTypes.Handbook) {
    return NoteBlank;
  }

  if (el.noodleProductType === NoodleProductTypes.Response) {
    return ArrowCircleUpRight;
  }

  return Link;
};

const AddHandbook = ({ onSendMessage, onAddLink, onAddFiles, disabled }: Props): ReactElement => {
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [handBookContentOpen, setHandBookContentOpen] = useState(false);
  const [mediaLibFilesOpen, setMediaLibFilesOpen] = useState(false);
  const [productTypeIndex, setProductTypeIndex] = useState(0);
  const { creatorId } = useContext(TeamsContext);
  const [activeMediaType, setActiveMediaType] = useState('');
  const {
    data: items,
    fetchingState: { isFetching: isFetchingItems, isFetched: hasFetchedItems },
    getData: getItems,
    error: getItemsError,
  } = useNoodleApi(getProductsForSharing, { toastOnError: true });
  const {
    data: itemsLibrarySection,
    fetchingState: { isFetching: isFetchingLibrary, isFetched: hasFetchedLibrary },
    getData: getLibrary,
    error: getLibraryError,
  } = useNoodleApi(getCreatorContent, { toastOnError: true });

  const [values, setValues] = useState({ name: 'Some input value', search: '' });

  const mediaLibraryData = useMemo(() => itemsLibrarySection || [], [itemsLibrarySection]);
  const [media, setMedia] = useState<Media[]>([]);
  const types = useMemo(
    () => [
      ContentLibraryTypes.Videos,
      ContentLibraryTypes.Files,
      ContentLibraryTypes.YouTube,
      ContentLibraryTypes.Facebook,
      ContentLibraryTypes.Instagram,
    ],
    [],
  );
  const isSocialUrlTypes = useMemo(() => [ContentLibraryTypes.Videos, ContentLibraryTypes.Files], []);
  let availableMediaTypes = [...(mediaLibraryData as Media[]).map(mediaItem => mediaItem.type).filter(t => types.includes(t))].map((t, i) => ({
    id: i + 1,
    type: t,
  }));

  availableMediaTypes = uniqBy(availableMediaTypes, 'type');

  useEffect(() => {
    if (!hasFetchedItems && !isFetchingItems && !getItemsError && creatorId) {
      getItems({ creatorId });
    }
    if (!isFetchingLibrary && !hasFetchedLibrary && !getLibraryError) {
      getLibrary();
    }
  }, [getItems, getLibrary, hasFetchedItems, hasFetchedLibrary, isFetchingItems, isFetchingLibrary, getLibraryError, getItemsError, creatorId]);

  useEffect(() => {
    if (hasFetchedLibrary && media.length === 0) {
      const mediaLibrary = (): Promise<void | Media[]> =>
        Promise.all(
          (mediaLibraryData as Media[])
            .filter(item => types.includes(item.type))
            .map(async item => {
              if (!isSocialUrlTypes.includes(item.type)) {
                if (item?.url.indexOf('www.youtube.com') !== -1) {
                  const youTubeVideoId = (item.url.match(
                    /(?:https?:\/\/)?(?:www\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\/?\?v=|\/embed\/|\/)([^\s&]+)/,
                  ) || [])[1];

                  const res = await axios
                    .get('https://youtube.googleapis.com/youtube/v3/videos', {
                      params: {
                        id: youTubeVideoId,
                        key: GOOGLE_API_KEY,
                        part: 'snippet',
                      },
                    })
                    .then(result => result);

                  const data = res?.data?.items?.[0]?.snippet;
                  return {
                    id: item.id,
                    imageUrl: data?.thumbnails?.medium?.url,
                    mimetype: item.mimetype,
                    title: item.title || data?.title,
                    type: item.type,
                    url: item.url,
                  };
                }
                return item;
              }
              return item;
            }),
        ).then(res => setMedia(res));

      mediaLibrary();
    }
  }, [media.length, isSocialUrlTypes, itemsLibrarySection, mediaLibraryData, types, hasFetchedLibrary]);

  const { renderFormField } = useForm({
    formErrors: {},
    initialValues: {},
    rules: {},
    setFormErrors: () => {
      /* empty */
    },
    setValues,
    values,
  });

  const getFilteredItems = (
    itemsToFilter: Pick<Product, 'id' | 'title' | 'description'>[],
  ): Pick<Product, 'id' | 'title' | 'image' | 'description'>[] => {
    if (values.search && values.search !== '') {
      return itemsToFilter.filter(item => item?.title?.toLowerCase()?.includes(values.search.toLowerCase()));
    }
    return itemsToFilter;
  };

  const handleToggle = (): void => {
    setPopoverOpen(!popoverOpen);
    setHandBookContentOpen(false);
    setMediaLibFilesOpen(false);
  };

  const onHandbookProductTypeClick = (elIndex: number): void => {
    setProductTypeIndex(elIndex);
    setValues({ ...values, name: values.name });
    setHandBookContentOpen(true);
  };

  const onHandbookItemClick = (el: Pick<Product, 'id' | 'title' | 'image' | 'description'>): void => {
    mixpanelTrack('InputComposer - handbook added', { productId: el.id });
    onSendMessage(el);
    setPopoverOpen(false);
  };

  const onLibraryItemTypeClick = (el: ContentMediaType): void => {
    setMediaLibFilesOpen(true);
    setActiveMediaType(el.type);
  };

  const onLibraryItemClick = (el: Media): void => {
    setPopoverOpen(false);
    if (isSocialUrlTypes.includes(el.type)) {
      onAddFiles(el);
    } else {
      onAddLink(el.url);
    }
  };

  return (
    <>
      <Buttons
        disabled={disabled}
        iconBefore={
          <m.div
            style={{
              alignItems: 'center',
              display: 'flex',
              height: '16px',
              justifyContent: 'center',
              width: '16px',
              willChange: 'transform',
            }}
            whileTap={(!disabled && { scale: 0.9 }) || {}}
          >
            {popoverOpen
              ? (
                <XCircle weight="fill" size={16} color="var(--color-gray-50)" />
              )
              : (
                <Cookie weight="fill" size={16} color={!disabled ? 'var(--color-primary)' : 'var(--color-gray-75)'} />
              )}
          </m.div>
        }
        onClick={handleToggle}
      >
        Byte
      </Buttons>
      <AnimatePresence>
        {popoverOpen && !handBookContentOpen && !mediaLibFilesOpen && (
          <OutsideClick onOutsideClick={handleToggle} className={s.outsideClick}>
            <ChatDropDown>
              {items?.productTypes?.map((i, index) => {
                if (i.noodleProductType === NoodleProductTypes.Handbook) {
                  return (
                    <Buttons
                      isFlat
                      isFullWidth
                      isBold
                      isZeroBorderRadius
                      className={s['add-content-item-btn']}
                      key={i.id}
                      title={i.title || ''}
                      iconBefore={React.createElement(detectHandbookProductTypeIcon(i), {
                        color: 'var(--color-primary)',
                        weight: 'bold',
                      })}
                      onClick={() => onHandbookProductTypeClick(index)}
                    >
                      {i.title}
                    </Buttons>
                  );
                }
                return <Fragment key={i.id} />;
              })}
              {availableMediaTypes.map(i => (
                <Buttons
                  isFlat
                  isFullWidth
                  isBold
                  isZeroBorderRadius
                  key={i.id}
                  className={s['add-content-item-btn']}
                  title={i.type}
                  iconBefore={React.createElement(detectMediaIcon(i.type))}
                  onClick={() => onLibraryItemTypeClick(i)}
                >
                  {i.type}
                </Buttons>
              ))}
            </ChatDropDown>
          </OutsideClick>
        )}
        {popoverOpen && handBookContentOpen && (
          <OutsideClick onOutsideClick={handleToggle} className={s.outsideClick}>
            <ChatDropDown>
              <div className="search-content">
                <Buttons className={s.backButton} isWrapper onClick={() => setHandBookContentOpen(false)}>
                  <ArrowCircleLeft weight="fill" fill="var(--color-gray-100)" size={24} />
                </Buttons>
                {renderFormField(rangeNoteFields('Search for handbooks')[0])}
              </div>

              {getFilteredItems((items?.productTypes || [])[productTypeIndex].products || []).map(i => (
                <Buttons
                  isFlat
                  isFullWidth
                  isZeroBorderRadius
                  key={i.id}
                  className={classNames(s['add-content-item-btn'], {
                    [s['add-content-item-btn--with-image']]: i?.image?.url,
                  })}
                  title={i?.title || ''}
                  onClick={() => onHandbookItemClick(i)}
                >
                  {i?.image?.url && <Image isProductSquaredIcon alt={i?.title || ''} image={i.image.url} />}
                  <div
                    style={{
                      WebkitMaskImage: 'linear-gradient(90deg, rgba(0,0,0,1) 75%, rgba(0,0,0,0) 100%)',
                      display: 'flex',
                      flex: 1,
                      flexDirection: 'column',
                      overflow: 'hidden',
                    }}
                  >
                    <p style={{ margin: '0' }}>{i?.title}</p>
                    <p style={{ margin: '0' }}>{i?.description}</p>
                  </div>
                </Buttons>
              ))}
            </ChatDropDown>
          </OutsideClick>
        )}
        {popoverOpen && mediaLibFilesOpen && (
          <OutsideClick onOutsideClick={handleToggle} className={s.outsideClick}>
            <ChatDropDown>
              <div className="search-content">
                <Buttons
                  className={s.backButton}
                  icon={<ArrowCircleLeft weight="fill" color="var(--color-gray-100)" />}
                  onClick={() => setMediaLibFilesOpen(false)}
                />
                {renderFormField(rangeNoteFields('Search for media')[0])}
              </div>
              {(getFilteredItems(media) as Media[])
                .filter(i => i.type === activeMediaType)
                .map(i => (
                  <Buttons
                    isFlat
                    isFullWidth
                    isBold
                    isZeroBorderRadius
                    className={classNames(s['add-content-item-btn'], s['preview-list-item-container'])}
                    key={i.id}
                    onClick={() => onLibraryItemClick(i)}
                    title={i.title || ''}
                  >
                    {!isSocialUrlTypes.includes(i.type)
                      ? (
                        <RenderLink
                          url={i.url}
                          disableYoutube={true}
                          imageUrl={i.imageUrl}
                          title={i.title || i.url}
                          isTwitter={[ContentLibraryTypes.Twitter].includes(i.type)}
                        />
                      )
                      : (
                        <p
                          style={{
                            textAlign: 'left',
                            wordBreak: 'break-all',
                          }}
                        >
                          {i.title ? i.title : 'Unknown file'}
                        </p>
                      )}
                  </Buttons>
                ))}
            </ChatDropDown>
          </OutsideClick>
        )}
      </AnimatePresence>
    </>
  );
};

export default AddHandbook;
