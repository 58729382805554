import { FC } from 'react';
import Upload from '@components/Icons/Upload';
import Buttons from '@components/Buttons';
import * as React from 'react';

type Props = {
  onClick: () => void;
};

const UploadAudioButton: FC<Props> = ({ onClick }) => (
  <Buttons
    isSecondary
    onClick={onClick}
    isFullWidth
    iconBefore={
      <Upload weight="bold" color="var(--color-gray-0)" />
    }
  >
    Upload Audio
  </Buttons>
);

export default UploadAudioButton;
