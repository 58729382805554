import Modal from '@components/Modal';
import { ReactElement } from 'react';
import ActionEditForm from '@components/ActionEditForm';

import s from './EditWorkflowStepModal.module.scss';

type Props = {
  workflow: Parameters<typeof ActionEditForm>[0]['workflow'];
  currentStep: Parameters<typeof ActionEditForm>[0]['action'];
  onClose: () => void;
  onSave: () => void;
};

const EditWorkflowStepModal = ({ currentStep, onClose, onSave, workflow }: Props): ReactElement => (
  <Modal
    hideBottomInset

    title={'Edit Step'}
    onClose={onClose}

  >
    <div className={s.editWorkflow}>
      <ActionEditForm
        action={currentStep}
        onFinishEdit={onSave}
        workflow={workflow}
      />
    </div>
  </Modal>
);

export default EditWorkflowStepModal;
