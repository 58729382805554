import * as tsClient from '@tsClient';
import useNoodleApi from '@hooks/useNoodleApi';
import { useEffect, useState } from 'react';
import { useUser } from '@hooks';
import ApiError from '@helpers/ApiError';
import FieldError from '@components/FieldError';
import ConnectOutlookButton from '@lib/CreatorProductAvailability/components/ConnectCalendars/ConnectOutlookButton';
import { useFeatureFlag } from '@/providers/FeatureFlags';
import ConnectGoogleButton from './ConnectGoogleButton';
import ConnectedAccounts from './ConnectedAccounts';
import s from './Connect.module.scss';

type Props = {
  creatorId: string | null;
  teamMemberPersonId: string;
};

const ConnectCalendars: React.FC<Props> = ({ creatorId, teamMemberPersonId }) => {
  const [connectError, setConnectError] = useState('');
  const teamMemberAvailabilityEnabled = useFeatureFlag('team-member-availability');
  const [user] = useUser();

  const isCurrentTeamMember = teamMemberAvailabilityEnabled ? user?.id === teamMemberPersonId : true;

  const {
    data: calendarAccountsPageResponse,
    fetchingState: getCalendarAccountsState,
    getData: getCalendarAccounts,
  } = useNoodleApi(tsClient.getCalendarAccounts);

  useEffect(() => {
    if (creatorId) {
      getCalendarAccounts({ creatorId , personId: teamMemberPersonId });
    }
  }, [creatorId, teamMemberPersonId, getCalendarAccounts]);

  const handleUpdateAccounts = (): void => {
    if (creatorId) {
      getCalendarAccounts({ creatorId, personId: teamMemberPersonId });
    }
  };

  const handleError = (err: ApiError): void => {
    if (err.type === 'InvalidGoogleTokenScopeError') {
      setConnectError('In order to use the calendar feature, you must give Noodle permission to "See, edit, share, and permanently delete all calendars you can access using Google Calendar". Please try again.');
    } else {
      setConnectError('Failed to connect calendars. Please try again or contact support.');
    }
  };

  if (!creatorId || !getCalendarAccountsState.isFetched) {
    return null;
  }

  if (
    calendarAccountsPageResponse
    && calendarAccountsPageResponse.items.length > 0
  ) {
    return (
      <ConnectedAccounts
        creatorId={creatorId}
        accounts={calendarAccountsPageResponse.items}
        teamMemberPersonId={teamMemberPersonId}
        onChange={handleUpdateAccounts}
        onError={handleError}
      />
    );
  }

  return (
    <div className={s.connect}>
      {isCurrentTeamMember && (
        <>
          {connectError && <FieldError>{connectError}</FieldError>}
          <ConnectGoogleButton
            creatorId={creatorId}
            teamMemberPersonId={teamMemberPersonId}
            onConnect={handleUpdateAccounts}
            onError={handleError}
          />
          <ConnectOutlookButton
            creatorId={creatorId}
            teamMemberPersonId={teamMemberPersonId}
            onError={handleError}
          />
        </>
      )}
    </div>
  );
};

export default ConnectCalendars;
