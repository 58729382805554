import React, { useEffect } from 'react';
import useNoodleApi from '@hooks/useNoodleApi';
import * as tsClient from '@tsClient';
import { useUser } from '@hooks';
import StripeElementsProvider from '@providers/StripeElements';
import CardDetails from './CardDetails';

type Props = {
  card: Parameters<typeof CardDetails>[0]['card'];
  isPrimary: Parameters<typeof CardDetails>[0]['isPrimary'];
  isLoading?: Parameters<typeof CardDetails>[0]['isLoading'];
  creatorSlug: string;
  onSaveCard?: () => Promise<void>;
  subscription?: Parameters<typeof CardDetails>[0]['subscription'];
  retryPayment?: Parameters<typeof CardDetails>[0]['retryPayment'];
};

/*
 * For the currently logged in user, so not to be used in the dashboard where team members need access.
 */
const CardDetailsCurrentUser: React.FC<Props> = ({ retryPayment, card, isPrimary, isLoading, creatorSlug, onSaveCard, subscription }) => {
  const [user] = useUser();
  const { data: creator, getData: getCreatorFn } = useNoodleApi(tsClient.getCreator);

  useEffect(() => {
    getCreatorFn({ creatorSlug });
  }, [getCreatorFn, creatorSlug]);

  const handleSaveCard: Parameters<typeof CardDetails>[0]['onSaveCard'] = async () => {
    if (onSaveCard) {
      await onSaveCard();
    }
  };

  if (!user || !creator) {
    return null;
  }

  return (
    <StripeElementsProvider creator={creator}>
      <CardDetails
        card={card}
        creator={creator}
        isLoading={isLoading}
        isPrimary={isPrimary}
        onSaveCard={handleSaveCard}
        personId={user.id}
        retryPayment={retryPayment}
        subscription={subscription}
      />
    </StripeElementsProvider>
  );
};

export default CardDetailsCurrentUser;
