import Spacer from '@components/Spacer';
import * as format from '@format';
import * as ApiModels from '@typings/api-models';
import { DEFAULT_CURRENCY } from '@typings/common';
import BannerStatus from '@components/DesignLibrary/BannerStatus';
import PaymentStatus from './PaymentStatus';
import s from './InvoicePaymentsWidget.module.scss';

type Props = {
  payment: Pick<
    ApiModels.Payment,
    | 'amount'
    | 'amountRefunded'
    | 'createdAt'
    | 'creatorCurrency'
    | 'last4'
    | 'passedProcessingFeesToCustomer'
    | 'paymentStatus'
    | 'processingFees'
    | 'paymentMethodType'
    | 'notes'
  > & {
    person: Pick<ApiModels.Person, 'id' | 'email' | 'name'>;
  };
};

const Payment: React.FC<Props> = ({ payment }) => (
  <div>
    <div className={s.row}>
      <p className="caption-bold">
        {format.price.withCurrency(
          payment.passedProcessingFeesToCustomer ? payment.amount - payment.processingFees : payment.amount,
          payment.creatorCurrency || DEFAULT_CURRENCY,
        )}
      </p>
      <p className={s.rowTitle}>{format.datetime.withYear(payment.createdAt)}</p>
    </div>
    <Spacer size={8} />
    <PaymentStatus paymentStatus={payment.paymentStatus} />
    {payment.passedProcessingFeesToCustomer && (
      <p className={s.rowTitle}>
        Processing fees: <span>{format.price.withCurrency(payment.processingFees, payment.creatorCurrency || DEFAULT_CURRENCY)}</span>
      </p>
    )}
    <p className={s.rowTitle}>
      Total: <span>{format.price.withCurrency(payment.amount, payment.creatorCurrency || DEFAULT_CURRENCY)}</span>
    </p>
    {payment.last4 && (
      <p className={s.rowTitle}>
        Payment method: <span>*{payment.last4}</span>
      </p>
    )}
    <p className={s.rowTitle}>
      Payor: <span>{payment.person.name || payment.person.email || payment.person.id}</span>
    </p>
    {payment.notes && (
      <p className={s.rowTitle}>
        Note: <span>{payment.notes}</span>
      </p>
    )}
    {payment.paymentMethodType === 'outside-of-noodle' && (
      <>
        <Spacer size="16px" />
        <BannerStatus status="warning" label="Payment made outside of Noodle" />
        <Spacer size="8px" />
      </>
    )}
    {payment.amountRefunded > 0 && (
      <>
        <Spacer size="16px" />
        <BannerStatus status="error" label={`Amount refunded: ${format.price.withCurrency(payment.amountRefunded, payment.creatorCurrency || DEFAULT_CURRENCY)}`} />
        <Spacer size="16px" />
      </>
    )}
    <Spacer size={24} isLine />
  </div>
);

export default Payment;
