import { FC, useContext, useState } from 'react';
import Modal from '@components/Modal';
import InputField from '@components/FormFields/InputField/InputField';
import Buttons from '@components/Buttons/Buttons';
import Spacer from '@components/Spacer';
import useNoodleApi from '@hooks/useNoodleApi';
import * as tsClient from '@tsClient';
import { DocumentAccessContext } from '@providers/DocumentAccess';
import s from './RejectDocumentModal.module.scss';

type Props = {
  onClose: () => void;
  documentRequestUserId: string;
  fileId: string;
  refetchDocument: () => Promise<void>;
};

const RejectDocumentModal: FC<Props> = ({ onClose, documentRequestUserId, fileId, refetchDocument }) => {
  const [rejectionMessage, setRejectionMessage] = useState('');
  const [isRejecting, setIsRejecting] = useState(false);
  const { accessToken } = useContext(DocumentAccessContext);

  const { getData: rejectFileFn } = useNoodleApi(tsClient.documentRequests.rejectUploadedFile);

  if (!accessToken) {
    return null;
  }

  const rejectFile = async (): Promise<void> => {
    setIsRejecting(true);
    await rejectFileFn({
      accessToken,
      fileId,
      id: documentRequestUserId,
      rejectionMessage,
    });
    await refetchDocument();
    onClose();
  };

  return (
    <Modal
      hideBottomInset

      title='Reject Document'
      onClose={onClose}

    >
      <div className={s.container}>
        <InputField
          id='rejectMessage'
          name='rejectMessage'
          label='Rejection Message'
          onChange={(val) => setRejectionMessage(val)}
          values={{ rejectionMessage }}
          rows={3}
        />
        <Spacer size='24px' />
        <Buttons
          isFetching={isRejecting}
          disabled={!rejectionMessage}
          isSecondary
          isFullWidth
          onClick={rejectFile}
        >
          Reject File
        </Buttons>
      </div>
    </Modal>
  );
};

export default RejectDocumentModal;
