import { ReactElement, useState } from 'react';
import { useToast } from '@hooks';
import Modal from '@components/Modal';
import useNoodleApi from '@hooks/useNoodleApi';
import * as ApiModels from '@typings/api-models';
import TextInput from '@components/FormFields/TextInput';
import Buttons from '@components/Buttons';
import Spacer from '@components/Spacer';
import { createWorkflowStep } from '@tsClient/workflows';
import s from './AddWorkflowThreadModal.module.scss';

type Props = {
  workflow: {
    id: string;
    stepsLength: number;
  };
  onClose: () => void;
  onSubmit: () => void;
};

const AddWorkflowThreadModal = ({ workflow, onClose, onSubmit }: Props): ReactElement => {
  const [title, setTitle] = useState<string>('');
  const [isAddingThread, setIsAddingThread] = useState(false);
  const [isTouched, setIsTouched] = useState(false);
  const [titleError, setTitleError] = useState<string | null>(null);

  const { getData: createStepFn } = useNoodleApi(createWorkflowStep, {
    toastOnError: true,
    toastSuccessMessage: () => [useToast.ToastTypeVariants.INFO, 'Thread added'],
  });

  const handleAddWorkflowThread = async (): Promise<void> => {
    if (titleError) {
      setIsTouched(true);
      return;
    }
    setIsAddingThread(true);
    const { data: newTrigger } = await createStepFn({
      id: workflow.id,
      stepData: {
        data: {},
        dependentOn: [],
        eventName: workflow.stepsLength === 0 ? ApiModels.WORKFLOW_TRIGGERS.INITIATE_CTA : ApiModels.WORKFLOW_TRIGGERS.USER_WORKFLOW_CTA,
        title,
        type: ApiModels.StepType.TRIGGER,
      },
    });
    if (newTrigger) {
      await createStepFn({
        id: workflow.id,
        stepData: {
          data: {},
          dependentOn: [newTrigger],
          eventName: ApiModels.WORKFLOW_ACTIONS.SEND_MESSAGE,
          title: null,
          type: ApiModels.StepType.ACTION,
        },
      });
    }
    setIsAddingThread(false);
    onSubmit();
    onClose();
  };

  return (
    <Modal
      hideBottomInset

      title={'Add thread'}
      onClose={onClose}

    >
      <div className={s.addThread}>
        <TextInput
          id={'title'}
          value={title}
          onChange={setTitle}
          label={'Thread title'}
          placeholder={`Enter thread title`}
          onError={setTitleError}
          isTouched={isTouched}
          required
        />
        <Spacer size={12}/>
        <Buttons isSecondary isFullWidth onClick={handleAddWorkflowThread} isFetching={isAddingThread}>
          Submit
        </Buttons>
      </div>
    </Modal>
  );
};

export default AddWorkflowThreadModal;
