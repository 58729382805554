import {
  useState, useEffect, FC, ChangeEvent,
} from 'react';
import classNames from 'classnames';
import { timeDiff, times } from '../../utils';
import s from './TimeSelect.module.scss';

type TimeSelectProps = {
  selectedTime: string;
  setSelectedTime: (time: string) => void;
  type: 'from' | 'to';
  otherTime: string;
  after?: string;
  className?: string;
};

const TimeSelect: FC<TimeSelectProps> = ({
  selectedTime,
  setSelectedTime,
  type,
  otherTime,
  after,
  className,
}) => {
  const [options, setOptions] = useState(times);

  useEffect(() => {
    // filter options based on selected time
    let newOptions = times.filter((time) => {
      if (type === 'from') {
        // show all times before "to" time
        return timeDiff(time, otherTime) < 0;
      }
      if (type === 'to') {
        // show all times after "from" time
        return timeDiff(time, otherTime) > 0;
      }
      // for any other selected time, show all times
      return true;
    });

    // filter out all times before 9:00am
    if (typeof after === 'string') {
      newOptions = newOptions.filter((time) => timeDiff(time, after) >= 0);
    }

    setOptions(newOptions);
  }, [selectedTime, otherTime, type, after]);

  const handleChange: (value: ChangeEvent<HTMLSelectElement>) => void = (
    event,
  ) => {
    const { value } = event.target;
    // compare selected time with other time
    if (type === 'from') {
      if (timeDiff(value, otherTime) > 0) {
        // if selected time is after other time, set other time to 1 hour after selected time
        const index = times.indexOf(value);
        if (index + 1 < times.length) {
          setSelectedTime(times[index + 1]);
        }
      }
    }
    if (type === 'to') {
      if (timeDiff(value, otherTime) < 0) {
        // if selected time is before other time, set other time to 1 hour before selected time
        const index = times.indexOf(value);
        if (index - 1 >= 0) {
          setSelectedTime(times[index - 1]);
        }
      }
    }

    setSelectedTime(value);
  };

  return (
    <div className={classNames(s['combobox-wrapper'], className)}>
      <select value={selectedTime} onChange={(event) => handleChange(event)}>
        {options.map((time) => (
          <option key={time} value={time}>
            {time}
          </option>
        ))}
      </select>
    </div>
  );
};

export default TimeSelect;
