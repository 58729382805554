import classNames from 'classnames';
import s from '../CreatorProductAvailability.module.scss';

type Props = {
  isInline?: boolean;
  className?: string;
};

const MainWrapper: React.FC<React.PropsWithChildren<Props>> = ({ children, isInline, className }) => (
  <div className={classNames(s['creator-product-availability__container'], className)} style={isInline ? {} : { paddingTop: 0 }}>
    <div className={s['creator-product-availability__wrapper']}>{children}</div>
  </div>
);

export default MainWrapper;
