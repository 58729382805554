import { useEffect, useRef, useState } from 'react';
import * as ApiModels from '@typings/api-models';
import * as DocumentApiModels from '@typings/document-api-models';
import FileType from '@components/Icons/FileType';
import PencilSimple from '@components/Icons/PencilSimple';
import Eye from '@components/Icons/Eye';
import XCircle from '@components/Icons/XCircle';
import { usePrevious, useIsTextTruncated } from '@hooks';
import DocumentUploadButton from '@components/Document/DocumentUploadButton';
import DocumentUploader from '@components/Document/DocumentUploader';
import { useOpenPreviewDocumentPane } from '@panes/hooks';
import s from './DocumentsListModal.module.scss';
import DocumentSummary from './DocumentSummary';
import DocumentVersion from './DocumentVersion';

type UploadedDocument = Parameters<NonNullable<Parameters<typeof DocumentUploader>[0]['onFinishUpload']>>[0];
type Props = {
  creatorId?: string;
  compiledDocument:
    & Pick<ApiModels.UserWorkflowDocument, 'id' | 'referenceId' | 'referenceType' | 'templateReferenceType'| 'title'>
    & {
      document: Pick<DocumentApiModels.Document, 'id' | 'mimeType' | 'fileName'> | null;
    }
    & Parameters<typeof DocumentSummary>[0]['compiledDocument']
    & Parameters<typeof DocumentVersion>[0]['compiledDocument']
  onChangeTitle?: (documentId: string, title: string) => void;
  onUploadComplete?: (document: UploadedDocument) => Promise<void> | void;
  isSaving?: boolean;
};

const DocumentRow: React.FC<Props> = ({ creatorId, compiledDocument, onChangeTitle, onUploadComplete, isSaving }) => {
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [originalTitle, setOriginalTitle] = useState<string | null>(null);
  const previousIsSaving = usePrevious(isSaving ?? false);
  const isEmpty = !compiledDocument.document;
  const canManuallyUpload = (compiledDocument.templateReferenceType === 'manually-added'
    || compiledDocument.templateReferenceType === 'document-request-file' || compiledDocument.templateReferenceType === 'ai-bot') && creatorId && onUploadComplete;
  const openDocumentRequestDocumentPane = useOpenPreviewDocumentPane();

  const titleRef = useRef(null);
  const isTitleTruncated = useIsTextTruncated(titleRef);

  useEffect(() => {
    if (!previousIsSaving && isSaving) {
      setIsEditingTitle(false);
    }
  }, [previousIsSaving, isSaving]);

  const handleClickEdit = (): void => {
    setIsEditingTitle(true);
    setOriginalTitle(compiledDocument.title);
  };

  const handleClickCancel = (): void => {
    setIsEditingTitle(false);
    if (onChangeTitle) {
      onChangeTitle(compiledDocument.id, originalTitle || '');
    }
  };

  const handleChangeTitle = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (onChangeTitle) {
      onChangeTitle(compiledDocument.id, e.target.value);
    }
  };

  return (
    <>
      <div className={(isEmpty || (isEmpty && compiledDocument.templateReferenceType === 'manually-added')) ? s['row-container-empty'] : s['row-container']}>
        <div className={s.icon}>
          <FileType mimeType={compiledDocument.document?.mimeType} size={24} isEmpty={isEmpty} />
        </div>
        <div className={s['row-main-part']}>
          <>
            {isEditingTitle
              ? (
                <div className={s['name-edit-wrapper']}>
                  <button onClick={handleClickCancel}>
                    <XCircle weight="fill" size={16} color="var(--color-error)" />
                  </button>
                  <input type="text" id={`${compiledDocument.id}-title`} onChange={handleChangeTitle} defaultValue={compiledDocument.title || ''} />
                </div>
              )
              : (
                <div className={s['name-view-wrapper']}>
                  <p
                    ref={titleRef}
                    title={isTitleTruncated
                      ? (compiledDocument.title || compiledDocument.document?.fileName)
                      : undefined}
                    className={s.name}>
                    {compiledDocument.title || compiledDocument.document?.fileName}
                  </p>
                  <button onClick={handleClickEdit}>
                    <PencilSimple weight="fill" size={16} color="var(--color-gray-100)" />
                  </button>
                </div>
              )}
            <DocumentSummary compiledDocument={compiledDocument} />
            <DocumentVersion compiledDocument={compiledDocument} />
          </>
        </div>
        {canManuallyUpload && (
          <DocumentUploadButton creatorId={creatorId} id={`in-row-${1}`} isInRow={true} onUploadComplete={d => onUploadComplete(d)} />
        )}
        {compiledDocument.document && (
          <button className={s['circle-button']} onClick={() => {
            if (compiledDocument.document) {
              openDocumentRequestDocumentPane({ previewDocumentId: compiledDocument.document.id });
            }
          }}>
            <Eye size={16} weight="fill" />
          </button>
        )}
      </div>
    </>
  );
};

export default DocumentRow;
