import * as ApiModels from '@typings/api-models';

const getSmallestActivePrice = <P extends Pick<ApiModels.Price, 'price' | 'isActive'>>(prices: Array<P>): P | null => {
  let smallestPrice: P | null = null;
  for (let i = 0; i < prices.length; i += 1) {
    const thisPrice = prices[i];
    if (thisPrice.isActive && (!smallestPrice || (thisPrice.price < smallestPrice.price))) {
      smallestPrice = thisPrice;
    }
  }
  return smallestPrice;
};

export default getSmallestActivePrice;