import Spacer from '@components/Spacer';
import * as format from '@format';
import s from './InvoicePaymentsWidget.module.scss';

type Props = {
  event: {
    canceledAt: string;
  };
};

const CancelPaymentEvent: React.FC<Props> = ({ event }) => (
  <div>
    <div className={s.row}>
      <p className='caption-bold' style={{ color: 'var(--color-error)' }}>
        Payment plan canceled
      </p>
      <p className={s.rowTitle}>{format.datetime.withYear(event.canceledAt)}</p>
    </div>
    <Spacer size={24} isLine />
  </div>
);

export default CancelPaymentEvent;
