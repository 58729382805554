import React, { FC, useState } from 'react';
import CustomSelect from '@components/FormFields/CustomSelect';
import { CONTEXT_TYPE } from '@typings/api-models';
import Spacer from '@components/Spacer';
import useNoodleApi from '@hooks/useNoodleApi';
import { createWorkflowContext } from '@tsClient/workflows';
import { Input } from '@/components/DesignLibrary/Atoms';
import CheckBox from '@/components/DesignLibrary/Atoms/CheckBox';
import removeNullish from '@/helpers/removeNullish';
import SelectionOptions, { SelectionOption } from './SelectionOptions';
import Button from '../DesignLibrary/Button';

const typeOptions = [
  {
    key: CONTEXT_TYPE.PERSON,
    title: 'Person',
    value: CONTEXT_TYPE.PERSON,
  },
  {
    key: CONTEXT_TYPE.ADDITIONAL_INFO,
    title: 'Text',
    value: CONTEXT_TYPE.ADDITIONAL_INFO,
  },
  {
    key: CONTEXT_TYPE.SELECTION,
    title: 'Selection',
    value: CONTEXT_TYPE.SELECTION,
  },
];

type Props = {
  onSubmit: () => Promise<void>;
  workflowId: string;
};

const AddContextForm: FC<Props> = ({ workflowId, onSubmit }) => {
  const [type, setType] = useState<CONTEXT_TYPE>(CONTEXT_TYPE.PERSON);
  const [label, setLabel] = useState('');
  const [isTitleField, setIsTitleField] = useState(false);
  const [selectionOptions, setSelectionOptions] = useState<SelectionOption[]>([]);
  const [isOptional, setIsOptional] = useState(false);
  const [customTermsVariable, setCustomTermsVariable] = useState('');
  const [isFetching, setIsFetching] = useState(false);

  const { getData: createWorkflowContextFn } = useNoodleApi(createWorkflowContext, { toastOnError: true });

  const handleSubmit = async (): Promise<void> => {
    setIsFetching(true);
    await createWorkflowContextFn({
      data: {
        isOptional,
        isTitleField,
        label,
        options: selectionOptions.map(o => o.label ? { label: o.label, slug: o.slug } : null).filter(removeNullish),
        type,
        variables: [{ variableName: customTermsVariable }],
      },
      workflowId,
    });
    await onSubmit();
    setIsFetching(false);
  };

  return (
    <div>
      <CustomSelect
        id='context'
        label='Context Type'
        value={type}
        options={typeOptions}
        onChange={(newType) => setType(newType as CONTEXT_TYPE)}
      />
      <Spacer size={8} />
      <Input
        id='label'
        compact
        name='label'
        label='Label'
        placeholder='Label'
        value={label}
        onChange={setLabel}
      />
      <Spacer size={8} />
      {type === CONTEXT_TYPE.SELECTION && (
        <>
          <SelectionOptions
            onChange={setSelectionOptions}
            options={selectionOptions}
          />
          <Spacer size={8} />
        </>
      )}
      <Input
        id='variable'
        compact
        name='variable'
        label='Custom Terms Variable'
        value={customTermsVariable}
        onChange={setCustomTermsVariable}
        optional
      />
      <Spacer size={8} />
      <CheckBox
        id='titleField'
        isChecked={isTitleField}
        label='Is title field'
        onChange={setIsTitleField}
        size='sm'
      />
      <Spacer size={8}/>
      <CheckBox
        id='isOptional'
        isChecked={isOptional}
        label='Is optional'
        onChange={setIsOptional}
        size='sm'
      />
      <Spacer size={16}/>
      <Button size='md' variant='secondary' fullWidth onClick={handleSubmit} loading={isFetching} disabled={!label}>
        Add Context
      </Button>
      <Spacer size={16}/>
    </div>
  );
};

export default AddContextForm;
