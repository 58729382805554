import { FC } from 'react';
import Modal from '@components/Modal';
import DocumentRequestForm from '@components/CreateEditDocumentRequestForm/DocumentRequestForm';

type Props = {
  documentRequest: Parameters<typeof DocumentRequestForm>[0]['documentRequest']
  onClose: () => void;
  onSubmit: () => Promise<void>;
};

const CreateEditDocumentRequestModal: FC<Props> = ({ onClose, documentRequest, onSubmit }) => {
  const title = documentRequest ? 'Edit Document Request' : 'Create Document Request';
  return (
    <Modal title={title} onClose={onClose} >
      <DocumentRequestForm documentRequest={documentRequest} onSubmit={onSubmit} />
    </Modal>
  );
};

export default CreateEditDocumentRequestModal;
