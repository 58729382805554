import React, { FC, useContext, useState, useEffect } from 'react';
import { AnimatePresence, m } from 'framer-motion';
import XCircle from '@components/Icons/XCircle';
import FileText from '@components/Icons/FileText';
import Buttons from '@components/Buttons';
import TeamsContext from '@providers/Teams/TeamsContext';
import useNoodleApi from '@hooks/useNoodleApi';
import * as tsClient from '@tsClient';
import ProgressIndicator from '@components/ProgressIndicator';
import ChatDropDown from '@components/Chat/ChatDropDown';
import * as ApiModels from '@typings/api-models';
import OutsideClick from '@components/OutsideClick';
import s from './InputComposer.module.scss';
import SearchInput from '../FormFields/SearchInput';

type Props = {
  disabled: boolean;
  onAttach: (data: { title: string; formTemplateId: string }) => void;
};

const AddFormRequest: FC<Props> = ({ disabled, onAttach }) => {
  const { creatorId } = useContext(TeamsContext);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [search, setSearch] = useState('');
  const {
    data: formTemplates,
    getData: getFormTemplates,
    fetchingState: { isFetching: isFetchingFormTemplates },
  } = useNoodleApi(tsClient.formRequests.getCreatorFormTemplates);

  useEffect(() => {
    if (creatorId) {
      getFormTemplates({ creatorId, page: 1, perPage: 1000, requireValidQuestionnaire: true });
    }
  }, [creatorId, getFormTemplates]);

  const toggle = (): void => {
    setPopoverOpen(prev => !prev);
  };

  const handleClick = (template: Pick<ApiModels.FormRequestTemplate, 'id' | 'name'>): void => {
    setPopoverOpen(false);
    onAttach({ formTemplateId: template.id, title: template.name });
  };

  if (formTemplates?.items.length === 0) {
    return null;
  }

  return (
    <>
      <Buttons
        disabled={disabled}
        ariaLabel='Add form request'
        iconBefore={
          <m.div
            style={{
              alignItems: 'center',
              display: 'flex',
              height: '16px',
              justifyContent: 'center',
              width: '16px',
              willChange: 'transform',
            }}
            whileTap={(!disabled && { scale: 0.9 }) || {}}
          >
            {popoverOpen
              ? (
                <XCircle weight="fill" size={16} color="var(--color-gray-50)" />
              )
              : (
                <FileText weight="fill" size={16} color={!disabled ? 'var(--color-primary)' : 'var(--color-gray-75)'} />
              )}
          </m.div>
        }
        onClick={toggle}
      >Form Request</Buttons>
      <AnimatePresence>
        {popoverOpen && (
          <OutsideClick onOutsideClick={() => setPopoverOpen(false)} className={s.outsideClick}>
            <ChatDropDown>
              {(!formTemplates || isFetchingFormTemplates)
                ? <ProgressIndicator isCentered />
                : (
                  <>
                    <SearchInput label="Filter by title" placeholder="Type a title..." setSearch={setSearch} className={s.search} />
                    {formTemplates.items
                      .filter(template => template.name.toLowerCase().includes(search.toLowerCase()))
                      .map(template => (
                        <Buttons
                          className={s.documentRequestButton}
                          key={template.id}
                          isWrapper
                          isFlat
                          onClick={() => handleClick(template)}
                        >
                          <FileText size={14} weight='fill' />
                          <p>
                            {template.name}
                          </p>
                        </Buttons>
                      ))}
                  </>
                )
              }
            </ChatDropDown>
          </OutsideClick>
        )}
      </AnimatePresence>
    </>
  );
};

export default AddFormRequest;
