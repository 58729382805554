import * as React from 'react';
import { FC, useContext } from 'react';
import CaretCircleDown from '@components/Icons/CaretCircleDown';
import * as format from '@format';
import { Currency, Interval, NoodleProductTypes } from '@typings/graphql-models';
import TeamsContext from '@providers/Teams/TeamsContext';
import useNoodleApi from '@hooks/useNoodleApi';
import { useUser } from '@providers/Auth';
import getRecentlyUsedPrices from '@tsClient/creators/getRecentlyUsedPrices';
import * as tsClient from '@tsClient';
import classNames from 'classnames';
import PlusCircle from '@components/Icons/PlusCircle';
import Buttons from '@components/Buttons';
import CreateEditPriceModal from '@modals/CreateEditPriceModal';
import ProgressIndicator from '@components/ProgressIndicator';
import s from './PriceSelector.module.scss';
import SearchInput from '../FormFields/SearchInput';

type Price = Awaited<ReturnType<typeof getRecentlyUsedPrices>>[0];

type Props = {
  onPriceSelect: (price: Price) => void;
  selectedPrice?: Pick<Price, 'id'> | null;
  canCreateNewPrice?: boolean;
  productId?: string | null;
};

const PriceSelector: FC<Props> = ({ onPriceSelect, selectedPrice, canCreateNewPrice, productId }) => {
  const { creatorId } = useContext(TeamsContext);
  const [user] = useUser();
  const [search, setSearch] = React.useState('');
  const [prices, setPrices] = React.useState<Price[] | null>(null);
  const [selectedProduct, setProduct] = React.useState<Price['product'] | null>(null);
  const [isPriceModalOpen, setPriceModalOpen] = React.useState(false);

  const { data: fetchedPrices, getData: getRecentlyUsedPricesFn } = useNoodleApi(getRecentlyUsedPrices, {
    toastOnError: true,
  });
  const {
    data: allProducts,
    fetchingState: { isFetching: isFetchingProducts },
    getData: getCreatorProductsFn,
  } = useNoodleApi(tsClient.creators.getProductsByCreatorId);
  const fetchPrices = React.useCallback(async (): Promise<Price[]> => {
    if (user && creatorId) {
      const { data } = await getRecentlyUsedPricesFn({ creatorId });
      return data || [];
    }
    return [];
  }, [user, creatorId, getRecentlyUsedPricesFn]);

  React.useEffect(() => {
    fetchPrices();
  }, [fetchPrices]);

  React.useEffect(() => {
    if (creatorId) {
      getCreatorProductsFn({ creatorId, page: 1, perPage: 1000 });
    }
  }, [getCreatorProductsFn, creatorId]);

  React.useEffect(() => {
    if (fetchedPrices) {
      setPrices(fetchedPrices);
    } else {
      setPrices(null);
    }
  }, [fetchedPrices]);

  const products = allProducts?.items || [];

  return (
    <>
      {!prices && !isFetchingProducts && <p className="caption">No prices yet. You can create one in Dashboard</p>}
      {isFetchingProducts && <ProgressIndicator isCentered />}
      {!isFetchingProducts && prices && <SearchInput label="Filter prices" placeholder='Type a price title...' setSearch={setSearch} />}
      {products
        .filter(p => (productId ? p.id === productId : true))
        .map((product, index) => {
          const length = prices?.filter(price => price.product?.id === product.id)
            .filter(p => p.priceTitle?.toLowerCase().includes(search.toLowerCase()))
            .length || 0;
          if (length === 0) return null;
          return (
            <details key={product.id} className={s.productType} open={index === 0 || Boolean(search)}
              {...(length > 0 ? { onClick: () => setProduct(product) } : {})}
            >
              <summary className="caption" {...length === 0
                ? {
                  style: {
                    cursor: 'default',
                    opacity: 0.5,
                    pointerEvents: 'none',
                  },
                }
                : {}}>
                <div>
                  {product.title} <span>{length}</span>
                </div>
                {length > 0 && <CaretCircleDown weight="fill" />}
              </summary>
              <div>
                {prices
                  ?.filter(price => price.product?.id === product.id)
                  .filter(p => p.priceTitle?.toLowerCase().includes(search.toLowerCase()))
                  .map(p => (
                    <button
                      key={p.id}
                      className={classNames(s.priceCard, selectedPrice?.id === p.id ? s.selected : null)}
                      onClick={() => onPriceSelect(p)}
                    >
                      <div>
                        <p className={s.boldCaption}>{p.priceTitle || 'Product'}</p>
                        {(!p.isActive || p.numBookings) && (
                          <p className={s.body}>
                            {!p.isActive && 'Private'}
                            {!p.isActive && p.numBookings && ' · '}
                            {p.numBookings && (
                              <>
                                {p.numBookings} session{p.numBookings > 1 && 's'}
                              </>
                            )}
                          </p>
                        )}
                      </div>
                      <p className={s.boldCaption}>
                        {format.price.withCurrency(p.price, p.currency)}
                        {(p.recurringInterval === Interval.Year && '/year') || (p.recurringInterval === Interval.Month && '/mo')}
                      </p>
                    </button>
                  ))}
                {canCreateNewPrice && (
                  <Buttons
                    onClick={() => {
                      setProduct(product);
                      setPriceModalOpen(true);
                    }}
                    className={s.createPriceButton}
                    iconBefore={<PlusCircle weight="fill" />}
                  >
                    Create a new price
                  </Buttons>
                )}
              </div>
            </details>
          );
        })}
      {isPriceModalOpen && selectedProduct && (
        <CreateEditPriceModal
          setIsVisible={setPriceModalOpen}
          onPublish={async priceId => {
            if (priceId) {
              const newPrices = await fetchPrices();
              const foundPrice = newPrices.find(p => p.id === priceId);
              if (foundPrice) {
                onPriceSelect(foundPrice);
              }
              setPriceModalOpen(false);
            }
          }}
          businessCategory={selectedProduct.creator?.businessCategory || ''}
          productId={selectedProduct.id}
          productType={selectedProduct.productTypes?.[0].noodleProductType || NoodleProductTypes.Lite}
          currency={selectedProduct.creator?.defaultCurrency || Currency.Usd}
          productTitle={null}
        />
      )}
    </>
  );
};

export default PriceSelector;
