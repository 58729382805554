import type { Questionnaire } from '@/typings/Questionnaire';
import { Checkbox } from '@/components/DesignLibrary/Atoms';

type Props = {
  fieldType: Questionnaire.Field['type'];
  onChange: (view: string | null) => void;
  value: string | null;
};

const ViewSelector: React.FC<Props> = ({ fieldType, onChange, value }) => {
  if (fieldType === 'list') {
    return (
      <Checkbox
        label="Display as table"
        isChecked={value === 'table'}
        onChange={(isChecked) => onChange(isChecked ? 'table' : null)}
        size="sm"
      />
    );

  }

  return null;
};

export default ViewSelector;