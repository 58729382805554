import { TD } from '@/components/DesignLibrary/Table';
import UserImage from '@/components/UserImage';
import { Asset, Person } from '@/typings/api-models';
import s from './AttachmentAssignees.module.scss';

export type Props = {
  type: 'attachment-assignees';
  assignees: Array<Pick<Person, 'id' | 'name'> & {
    image: Pick<Asset, 'url'> | null;
    primaryColour: { hex: string } | null;
  }>;
};

const AttachmentAssigneesCell: React.FC<Props> = ({ assignees }) => (
  <TD compact>
    <ul className={s.assignees}>
      {assignees.map(assignee => (
        <li key={assignee.id}>
          <UserImage size={14} color={assignee.primaryColour?.hex} name={assignee.name} url={assignee.image} />
          <p className="caption">{assignee.name}</p>
        </li>
      ))}
    </ul>
  </TD>
);

export default AttachmentAssigneesCell;