const calculateTimeElapsed = (timestamp: Date): string => {
  const now = new Date();
  const past = new Date(timestamp);
  const diffInSeconds = Math.floor((now.getTime() - past.getTime()) / 1000);

  if (diffInSeconds < 60) {
    return `${diffInSeconds} seconds ago`;
  }
  if (diffInSeconds < 3600) {
    return `${Math.floor(diffInSeconds / 60)} minutes ago`;
  }
  if (diffInSeconds < 86400) {
    return `${Math.floor(diffInSeconds / 3600)} hours ago`;
  }
  if (diffInSeconds < 2592000) {
    return `${Math.floor(diffInSeconds / 86400)} days ago`;
  }
  if (diffInSeconds < 31104000) {
    return `${Math.floor(diffInSeconds / 2592000)} months ago`;
  }
  return `${Math.floor(diffInSeconds / 31104000)} years ago`;
};

export default calculateTimeElapsed;
