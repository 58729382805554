import React, { FC, useContext, useEffect } from 'react';
import { InvoiceTemplate } from '@typings/api-models';
import Spacer from '@components/Spacer';

import useNoodleApi from '@hooks/useNoodleApi';
import * as tsClient from '@tsClient';
import Header from '@components/DesignLibrary/Header';
import LineItemEditor from '@components/LineItemEditor';
import { DEFAULT_CURRENCY } from '@typings/common';
import dashboardContext from '@layouts/DashboardLayout/DashboardContext';
import InvoiceSettings from '@components/InvoiceSettings';
import { ListAndDetailsSubLayoutContext } from '@layouts/ListAndDetailsSubLayout';
import { useRouter } from 'next/router';
import PageContext from '@components/DesignLibrary/PageContext';
import { getUrl, IDENTIFIERS } from '@helpers/urlsHelper';
import TrashSimple from '@components/Icons/TrashSimple';
import ProgressIndicator from '@components/ProgressIndicator';

import s from './CreateEditInvoiceRequestForm.module.scss';

type Props = {
  invoiceTemplate: (Pick<
    InvoiceTemplate,
    'id' | 'title' | 'priceId' | 'isFollowupEnabled' | 'passProcessingFeesToCustomer' | 'isAchPaymentEnabled' | 'isCreditCardPaymentEnabled'
    | 'isDebitCardPaymentEnabled' | 'secondaryStripeAccountId' | 'isPartialPaymentEnabled' | 'confidoBankAccountId' | 'isExemptFromApplicationFee' | 'isDebitCardPaymentRequiredForCustomer'
  > & {
    lineItems: Parameters<typeof LineItemEditor>[0]['initialValue'];
  }) | null;
  onClose: () => void;
  beforeClose?: () => Promise<void>;
};

const CreateEditInvoiceRequestForm: FC<Props> = ({ beforeClose, invoiceTemplate, onClose }) => {
  const router = useRouter();
  const { creator } = useContext(dashboardContext);
  const { reloadListView } = useContext(ListAndDetailsSubLayoutContext);
  const [isArchiving, setIsArchiving] = React.useState(false);
  const [invoiceTitle, setInvoiceTitle] = React.useState<string | undefined>(invoiceTemplate?.title);
  const { getData: createInvoiceTemplate } = useNoodleApi(tsClient.invoices.createInvoiceTemplate);
  const { getData: updateTemplate } = useNoodleApi(tsClient.invoices.updateInvoiceTemplate);
  const { getData: updateLineItems } = useNoodleApi(tsClient.invoices.updateTemplateLineItems);
  const {
    data: workflows,
    getData: getWorkflowsFn,
    fetchingState: { isFetching: isFetchingWorkflows },
  } = useNoodleApi(tsClient.workflows.getWorkflowsFromBuildingBlock);
  const { getData: archiveInvoiceTemplateFn } = useNoodleApi(tsClient.invoices.archiveInvoiceTemplate);

  const handleSave = async ({ invoiceData, lineItems }: { invoiceData: Omit<Parameters<typeof tsClient.invoices.createInvoiceTemplate>[0], 'creatorId'>; lineItems: Parameters<typeof tsClient.invoices.updateTemplateLineItems>[0]['lineItems'] }): Promise<void> => {
    let templateId;
    if (!creator) {
      return;
    }

    if (invoiceTemplate) {
      await updateTemplate({
        ...invoiceData,
        id: invoiceTemplate.id,
      });
      reloadListView();
      setInvoiceTitle(invoiceData.title);
      templateId = invoiceTemplate.id;
    } else {
      const { data } = await createInvoiceTemplate({
        creatorId: creator.id,
        ...invoiceData,
      });
      if (data) {
        templateId = data.id;
      }
    }
    if (templateId) {
      await updateLineItems({
        id: templateId,
        lineItems,
      });
    }
    await beforeClose?.();
    onClose();
  };

  useEffect(() => {
    if (invoiceTemplate) {
      getWorkflowsFn({ page: 1, perPage: 1000, referenceId: invoiceTemplate.id });
    }
  }, [getWorkflowsFn, invoiceTemplate]);

  const archiveInvoiceTemplate = async (): Promise<void> => {
    if (invoiceTemplate) {
      setIsArchiving(true);
      await archiveInvoiceTemplateFn({ id: invoiceTemplate.id });
      await reloadListView();
      await router.push(getUrl(IDENTIFIERS.DASHBOARD_INVOICE_REQUESTS));
      setIsArchiving(false);
    }
  };

  return (
    <>
      {invoiceTemplate
        ? (
          <>
            <Header
              secondaryActions={[
                {
                  icon: TrashSimple,
                  isDestructive: true,
                  isFetching: isArchiving,
                  label: 'Archive',
                  onClick: archiveInvoiceTemplate,
                },
              ]}
              title={invoiceTitle || 'Invoice'}
              noDivider
            />
            <div className={s.context}>
              <PageContext
                collapsible
                label={isFetchingWorkflows ? 'workflows' : `Used in ${workflows?.items.length} workflow${workflows?.items && workflows?.items.length === 1 ? '' : 's'}`}
                items={workflows?.items?.map?.((workflow) => ({
                  href: `${router.asPath.split('?')[0]}?paneWorkflowSlug=${workflow.slug}${router.asPath.split('?')[1] ? `&${router.asPath.split('?')[1]}` : ''}`,
                  id: workflow.id,
                  value: workflow.name,
                })) || []}
                isFetching={isFetchingWorkflows}
              />
            </div>
          </>
        )
        : <Spacer size={8} />}
      {isArchiving && (
        <>
          <Spacer size={16} />
          <ProgressIndicator isCentered />
        </>
      )}
      <InvoiceSettings
        currency={creator?.defaultCurrency || DEFAULT_CURRENCY}
        initialValue={invoiceTemplate || undefined}
        onSave={handleSave}
        requireLineItems={false}
      />
    </>
  );
};

export default CreateEditInvoiceRequestForm;
