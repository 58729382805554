import CheckBox from '@components/FormFields/CheckBox';
import InputField from '@components/FormFields/InputField';
import RadioInput from '@components/FormFields/RadioInput';
import CustomSelect from '@components/FormFields/CustomSelect';

const useForm = ({ rules, values, setValues, formErrors, initialValues = {}, setFormErrors }) => {
  const handleInputChange = (key, newValue, options = {}) => {
    const { formErrors: newFormErrors } = (options || {});
    setValues(val => ({ ...val, [key]: newValue }));
    if (newFormErrors) {
      setFormErrors(newFormErrors);
    }
  };

  const renderFormField = field => {
    let CMP = InputField;
    if (field.type === 'checkbox') {
      CMP = CheckBox;
    } else if (field.type === 'radio') {
      CMP = RadioInput;
    } else if (field.type === 'native-select') {
      CMP = CustomSelect;
    }
    return (
      <CMP
        {...field}
        key={field.name}
        ref={field.ref}
        rules={rules}
        {...(field.type === 'native-select'
          ? {
            error: formErrors?.[field.name],
            value: values[field.name],
          }
          : {
            formErrors,
            values,
          })}
        onChange={handleInputChange.bind(null, field.name)}
      />
    );
  };

  const resetForm = () => setValues(initialValues);

  const isFormValid = () => !Object.keys(formErrors).some(key => !!formErrors[key]);

  const hasFormChanges = () => JSON.stringify(initialValues) !== JSON.stringify(values);

  return {
    handleInputChange,
    hasFormChanges,
    isFormValid,
    renderFormField,
    resetForm,
  };
};

export default useForm;
