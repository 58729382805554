import * as ApiModels from '@typings/api-models';
import CreateEditDocumentRequestForm from '@components/CreateEditDocumentRequestForm';
import EditSendMessageActionForm from '@components/EditSendMessageActionForm';
import { SendMessageActionData } from '@components/EditSendMessageActionForm/EditSendMessageActionForm';

type Props = {
  action: Pick<ApiModels.WorkflowStep, 'id' | 'data' | 'eventName'>;
  workflow: Pick<ApiModels.Workflow, 'id'>;
  onFinishEdit: () => void;
};

const ActionEditForm: React.FC<Props> = ({ action, onFinishEdit, workflow }) => {
  if (action.eventName === ApiModels.WORKFLOW_ACTIONS.SEND_MESSAGE) {
    return <EditSendMessageActionForm
      actionId={action.id}
      messageData={action.data as SendMessageActionData}
      workflowId={workflow.id}
      onSave={onFinishEdit}
    />;
  }
  if (action.eventName === ApiModels.WORKFLOW_ACTIONS.SEND_DOCUMENT_REQUEST) {
    return <CreateEditDocumentRequestForm />;
  }
  return null;
};

export default ActionEditForm;
