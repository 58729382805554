import React, { FC, ReactElement } from 'react';

import XCircle from 'components/Icons/XCircle';
import Cookie from 'components/Icons/Cookie';
import Image from '@components/Image';
import Buttons from 'components/Buttons';
import { Product } from '@typings/graphql-models';

import s from './InputComposer.module.scss';

type Props = {
  handbooks: Pick<Product, 'id' | 'title' | 'image' | 'description'>[];
  removeHandbook: (m: Pick<Product, 'id' | 'title'>) => void;
};

const HandbookAttachments: FC<Props> = ({ handbooks, removeHandbook }): ReactElement => (
  <div className={s['library-attachments']}>
    {handbooks.length > 0 && handbooks.map(m => (
      <div title={m.title || 'handbook'} key={m.id} className={s['library-attachments__item']}>
        <div className={s['library-attachments__item-left']}>

          {m?.image?.url && <Image
            alt={m.title || 'handbook'}
            className={s['library-attachments__image']}
            image={`${m.image.url}`}
          />}
          {!m?.image?.url && <div
            style={{ fontSize: '24px' }}
            className={s['library-attachments__progress']}
          >
            <Cookie size={24} weight='fill' color='var(--color-primary)' />
          </div>
          }
          <span className='caption'>{ m.title }</span>
        </div>

        <Buttons
          className={s['library-attachments__remove']}
          onClick={() => removeHandbook(m)}
          icon={<XCircle weight='fill' color='var(--color-error)' />}
        />

      </div>
    ))}
  </div>
);

export default HandbookAttachments;