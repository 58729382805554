import React from 'react';
import clsx from 'classnames';
import ArrowDown from '@components/Icons/ArrowDown';
import ArrowUp from '@components/Icons/ArrowUp';
import Buttons from '@components/Buttons';
import { m } from 'framer-motion';
import s from './OrderableItem.module.scss';

type Props = {
  id: string;
  rank: number;
  max?: number;
  min?: number;
  onChangeRank: (id: string, newRank: number) => void;
  onRemove?: (id: string) => void;
};

const OrderableItem: React.FC<Props> = ({ id, rank, max, min = 1, onChangeRank, onRemove, children }) => {
  const isUpEnabled = rank > min;
  const isDnEnabled = max === null || max === undefined || rank < max;

  const handleUpClick = (): void => {
    if (isUpEnabled) {
      onChangeRank(id, Math.max(rank - 1, min));
    }
  };

  const handleDnClick = (): void => {
    if (isDnEnabled) {
      onChangeRank(id, max ? Math.min(rank + 1, max) : rank + 1);
    }
  };

  const handleRemove = (): void => {
    if (onRemove) {
      onRemove(id);
    }
  };

  return (
    <m.div
      id={id}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { delay: 0.2 } }}
      exit={{ opacity: 0 }}
      layout
      className={s['order-item']}
    >
      {children}

      <div className={s.actions}>
        <div className={s.number}>
          <p>{rank}</p>
        </div>
        <div>
          <Buttons onClick={handleUpClick} disabled={!isUpEnabled} className={clsx(s.arrow, !isUpEnabled && s.disabled)}>
            <ArrowUp height={15} weight="bold" />
          </Buttons>
          <Buttons onClick={handleDnClick} disabled={!isDnEnabled} className={clsx(s.arrow, !isDnEnabled && s.disabled)}>
            <ArrowDown height={15} weight="bold" />
          </Buttons>
        </div>
        {onRemove && (
          <button onClick={handleRemove} className={clsx(s.button, s.remove)}>
            <p>Remove</p>
          </button>
        )}
      </div>
    </m.div>
  );
};

export default OrderableItem;
