import classNames from 'classnames';
import { Fragment } from 'react';
import ListCell from '@components/DesignLibrary/ListCell';
import ProgressIndicator from '@components/ProgressIndicator';
import Spacer from '@components/Spacer';
import ListCellNavigation from '@components/DesignLibrary/ListCellNavigation';
import ListCellSection from '@components/DesignLibrary/ListCellSection';
import s from './ListView.module.scss';

export type RegularItem =
  & {
    type?: 'regular' | undefined;
    id: string;
  }
  & Omit<Parameters<typeof ListCell>[0], 'children' | 'isSelected' | 'isRouting'>;

export type NavigationItem =
  & {
    type: 'navigation';
    id: string;
    section?: string;
  }
  & Omit<Parameters<typeof ListCellNavigation>[0], 'children' | 'isSelected' | 'isRouting'>;

export type Item = RegularItem | NavigationItem;

type Props = {
  items: Array<Item>;
  sections?: Array<Parameters<typeof ListCellSection>[0]>;
  ifNoItemsText?: string;
  isFetching?: boolean;
  selectedId?: string | null;
  routingTo: string;
};

const ListView: React.FC<Props> = ({
  items,
  sections,
  ifNoItemsText = 'Nothing created yet',
  isFetching,
  selectedId,
  routingTo,
}) => {

  const navigationItemsWithSection = items.filter(
    (item): item is NavigationItem => item.type === 'navigation' && Boolean(item.section),
  );
  const navigationItemsWithoutSection = items.filter(
    (item): item is NavigationItem => item.type === 'navigation' && !item.section,
  );
  const regularItems = items.filter((item): item is RegularItem => item.type !== 'navigation');

  const itemsBySection = sections
    ? sections.map((section) => ({
      filteredItems: navigationItemsWithSection.filter((item) => item.section === section.label),
      section,
    }))
    : [];

  return (
    <>
      {isFetching && (
        <div>
          <Spacer size={48} />
          <ProgressIndicator isCentered />
          <Spacer size={48} />
        </div>
      )}

      {!isFetching && items.length === 0 && (
        <div className={classNames("body-sm", s["no-items-text"])}>
          {ifNoItemsText}
        </div>
      )}

      {!isFetching && (
        <ul className={s.list}>
          {regularItems.map((item) => (
            <ListCell
              key={item.id}
              isSelected={Boolean(selectedId) && item.id === selectedId}
              isRouting={Boolean(routingTo && item.href === routingTo)}
              {...item}
            />
          ))}

          {navigationItemsWithoutSection.map((item) => (
            <ListCellNavigation
              key={item.id}
              isSelected={Boolean(selectedId) && item.id === selectedId}
              isRouting={Boolean(routingTo && item.href === routingTo)}
              {...item}
            />
          ))}

          {itemsBySection.map(({ section, filteredItems }) => (
            <Fragment key={section?.label || "no-section"}>
              {section?.label && <ListCellSection label={section.label} icon={section.icon} />}
              {filteredItems.map((item) => (
                <ListCellNavigation
                  key={item.id}
                  isSelected={Boolean(selectedId) && item.id === selectedId}
                  isRouting={Boolean(routingTo && item.href === routingTo)}
                  {...item}
                />
              ))}
            </Fragment>
          ))}
        </ul>
      )}
    </>
  );
};

export default ListView;
