import React, { Dispatch, FC, ReactElement, SetStateAction } from 'react';
import classNames from 'classnames';

import DatePicker from '@components/FormFields/DatePicker';
import Search from '@components/DesignLibrary/Atoms/Search';
import Filters from '@/components/DesignLibrary/Filters';
import s from './Filter.module.scss';

type ValuesStateType = {
  searchBy: string;
  startDate: string | null;
  endDate: string | null;
};

type filterProps = {
  values: ValuesStateType;
  setValues: Dispatch<SetStateAction<ValuesStateType>>;
  filters: {
    title: string;
    value: string;
  }[];
  selectedFilterIndex: number;
  setSelectedFilterIndex: (index: number) => void;
  wrapperClass?: string;
  resetData: () => void;
  displayDatePickers?: boolean,
  displaySearch?: boolean,
};

const Filter: FC<filterProps> = ({
  values,
  setValues,
  filters,
  selectedFilterIndex,
  setSelectedFilterIndex,
  resetData,
  wrapperClass,
  displayDatePickers = true,
  displaySearch = true,
}): ReactElement => {
  const handleSearchChange = async (searchFor: string): Promise<void> => {
    setValues(prev => ({ ...prev, searchBy: searchFor }));
    resetData();
  };

  return (
    <div className={classNames(s.filter, wrapperClass)}>
      <div className={s.dateFilter}>
        {displayDatePickers && <DatePicker
          onChange={startDate => setValues(prev => ({ ...prev, startDate }))}
          value={values.startDate}
          max={values.endDate?.split('T')[0] || new Date().toISOString().split('T')[0]}
          label="Start date"
          inlineLabel
        />}
        {displayDatePickers && <DatePicker
          onChange={endDate => setValues(prev => ({ ...prev, endDate }))}
          value={values.endDate}
          min={values.startDate?.split('T')[0] || undefined}
          max={new Date().toISOString().split('T')[0]}
          label="End date"
          inlineLabel
        />}
      </div>
      {filters.length > 0 && (
        <Filters
          items={[
            {
              isMultiSelect: false,
              items: filters.map(f => ({
                key: f.value,
                label: f.title,
              })),
              label: 'Status',
              onChange: value => setSelectedFilterIndex(filters.map(f => f.value).indexOf(value)),
              selected: filters[selectedFilterIndex].value,
            },
          ]}
        />
      )}
      {displaySearch && <Search onChange={handleSearchChange} />}
    </div>
  );
};

export default Filter;
