import React, { FC, ReactElement } from 'react';
import { useQRCode } from 'next-qrcode';

type Props = {
  data?: string;
};

const QRCode: FC<Props> = ({ data }): ReactElement => {

  const { Canvas } = useQRCode();

  if (!data) {
    return <></>;
  }

  return (
    <Canvas
      text={data}
      options={{
        color: {
          dark: '#212121',
          light: '#fff',
        },
        level: 'M',
        margin: 3,
        quality: 0.5,
        scale: 4,
        type: 'image/jpeg',
        width: 200,
      }}
    />
  );
};

export default QRCode;