import { useContext, useState } from 'react';
import dynamic from 'next/dynamic';
import Image from '@components/Image';
import DashboardContext from '@layouts/DashboardLayout/DashboardContext';

import s from './DocumentPreview.module.scss';

const PDFRenderer = dynamic(() => import('@/components/PDFRenderer'), { ssr: false });

type Props = {
  mimeType: string;
  url: string;
};

interface AdobeDCViewConfig {
  clientId: string;
  divId: string;
}

interface PreviewFileConfig {
  showDownloadPDF: boolean;
}

interface PreviewFileArgs {
  content: { location: { url: string } };
  metaData: { fileName: string };
}

interface AdobeDC {
  View: new (args: AdobeDCViewConfig) => {
    previewFile: (args: PreviewFileArgs, config: PreviewFileConfig) => void;
  };
}

declare global {
  interface Window {
    AdobeDC?: AdobeDC;
  }
}

const Content: React.FC<Props> = ({ mimeType, url }) => {
  const [pdfRenderError, setPdfRenderError] = useState(false);
  const { isInDashboard } = useContext(DashboardContext);

  if (mimeType.includes('image')) {
    return <Image image={url} alt="" />;
  }

  if (mimeType.includes('pdf') && !pdfRenderError && !isInDashboard) {
    return <PDFRenderer url={url} setError={setPdfRenderError} />;
  }

  return <iframe src={`${url}#toolbar=0&navpanes=0`} width="100%" allowFullScreen={false} className={s.iframe} />;
};

export default Content;
