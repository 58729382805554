import Buttons from '@components/Buttons';
import Button from '@components/DesignLibrary/Button';
import { TBody, TD, TH, THead, Table, TableRow } from '@components/DesignLibrary/Table';
import PlusCircle from '@components/Icons/PlusCircle';
import TrashSimple from '@components/Icons/TrashSimple';
import Spacer from '@components/Spacer';
import * as format from '@format';
import LineItemModal, { LineItem } from '@modals/LineItemModal';
import { FC, useEffect, useState } from 'react';
import s from './LineItemEditor.module.scss';

type Props = {
  currency: string;
  initialValue?: LineItem[];
  onChange: (val: LineItem[]) => void;
};

enum Actions {
  Edit = 'Edit',
  Remove = 'Remove',
}

const LineItemEditor: FC<Props> = ({ currency, initialValue, onChange }) => {
  const [lineItems, setLineItems] = useState(initialValue || []);
  const [isAddModalOpen, setAddModalOpen] = useState(false);
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [lineItemToEditIdx, setLineItemToEditIdx] = useState(0);
  useEffect(() => {
    onChange(lineItems);
  }, [onChange, lineItems]);

  useEffect(() => {
    if (initialValue) {
      setLineItems(initialValue);
    }
  }, [initialValue]);

  const handleActionClick = (rowNumber: number, action: string): void => {
    if (action === Actions.Remove) {
      setLineItems(prev => prev.filter((_, idx) => idx !== rowNumber));
    } else if (action === Actions.Edit) {
      setLineItemToEditIdx(rowNumber);
      setEditModalOpen(true);
    }
  };

  const handleEditLineItems = (lineItem: LineItem): void => {
    lineItems[lineItemToEditIdx] = lineItem;
    setLineItems(lineItems);
  };

  return (
    <div>
      {isAddModalOpen && (
        <LineItemModal
          onClose={() => setAddModalOpen(false)}
          currency={currency}
          title="Add line item"
          onSubmit={lineItem => {
            setLineItems(prev => [...prev, lineItem]);
            setAddModalOpen(false);
          }}
        />
      )}
      {isEditModalOpen && (
        <LineItemModal
          onClose={() => setEditModalOpen(false)}
          currency={currency}
          lineItem={lineItems[lineItemToEditIdx]}
          title="Edit line item"
          onSubmit={lineItem => {
            handleEditLineItems(lineItem);
            setEditModalOpen(false);
          }}
        />
      )}
      <Table className={s.table}>
        <THead>
          <TableRow>
            <TH label="Item Description" />
            <TH label="Qty" />
            <TH label="Price" />
            <TH label="Total Amount" />
            <TH label="" />
          </TableRow>
        </THead>
        <TBody>
          {lineItems.map((item, idx) => (
            <TableRow key={item.id} rowNumber={idx} state={item.price === null ? 'muted' : undefined}>
              <TD compact>
                <p className="caption">{item.item}</p>
                <Button
                  variant="secondary"
                  size="xs"
                  onClick={() => {
                    handleActionClick(idx, Actions.Edit);
                  }}
                >
                  Edit
                </Button>
              </TD>
              <TD label={item.price === null ? '' : item.quantity.toString()} compact />
              <TD label={item.price === null ? '' : format.price.withCurrency(item.price, currency)} compact />
              <TD label={item.price === null ? '' : format.price.withCurrency(item.price * item.quantity, currency)} compact />
              <TD compact>
                <Buttons isWrapper aria-label="Remove" onClick={() => handleActionClick(idx, Actions.Remove)}>
                  <TrashSimple size={16} weight="fill" color="var(--color-error)" />
                </Buttons>
              </TD>
            </TableRow>
          ))}
        </TBody>
      </Table>
      <Spacer size={16} />
      <Buttons isWrapper onClick={() => setAddModalOpen(true)} className={s.button}>
        <PlusCircle size={16} weight="fill" />
        <span className="caption">Add line item</span>
      </Buttons>
    </div>
  );
};

export default LineItemEditor;
