import { useRef, useState, useEffect } from 'react';
import RadioButton from '@components/DesignLibrary/Atoms/RadioButton';
import { AnimatePresence, m } from 'framer-motion';
import s from '../Filters.module.scss';

type Props<K> = {
  items: Array<{
    key: K;
    label: string;
  }>;
  selected: K;
  label: string;
  onChange: (selected: K) => void;
};

const FilterOne = <K extends string>({ onChange, items, selected, label }: Props<K>): React.ReactElement => {
  const [open, setOpen] = useState(false);
  const onChangeRef = useRef(onChange);
  onChangeRef.current = onChange;

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent): void => {
      if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
        setOpen(false);
      }
    };

    if (open) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [open]);

  const handleChange = (key: K, newValue: boolean): void => {
    if (newValue && key !== selected) {
      onChangeRef.current(key);
    }
  };

  const selectedItem = items.find(item => item.key === selected);

  const animationHidden = {
    opacity: 0,
    y: 8,
  };

  return (
    <div className={s.container} ref={containerRef}>
      <button type="button" onClick={() => setOpen(!open)} style={open ? { backgroundColor: 'var(--color-gray-25)' } : undefined}>
        <span>
          {label}
          {selectedItem && `: ${selectedItem.label}`}
        </span>
      </button>
      <AnimatePresence>
        {open && (
          <m.fieldset
            className={s.inputs}
            initial={animationHidden}
            animate={{
              opacity: 1,
              y: 0,
            }}
            exit={animationHidden}
            transition={{
              duration: 0.2,
            }}
          >
            {items.map(i => (
              <RadioButton
                key={i.key}
                size="sm"
                isChecked={Boolean(selectedItem && selectedItem.key === i.key)}
                onChange={value => handleChange(i.key, value)}
                label={i.label}
              />
            ))}
          </m.fieldset>
        )}
      </AnimatePresence>
    </div>
  );
};

export default FilterOne;
