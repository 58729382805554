import { useContext, useState, useRef, useCallback, useEffect } from 'react';
import { nanoid } from 'nanoid';
import { IDENTIFIERS, getUrl } from '@helpers/urlsHelper';
import * as ApiModels from '@typings/api-models';
import useNoodleApi from '@hooks/useNoodleApi';
import TeamsContext from '@providers/Teams/TeamsContext';
import { useParams } from 'next/navigation';
import { getProductsByCreatorId } from '@tsClient/creators';

import hasNoodleProductType from '@helpers/hasNoodleProductType';
import type { HookResponse } from './common';
import { PAGE_SIZE } from './common';

const getDetailPagePath: HookResponse['getDetailPagePath'] = ({ id }) =>
  getUrl(IDENTIFIERS.DASHBOARD_WORKFLOWS_MY_WORKFLOWS_PRODUCT, { productId: id });

const useMyWorkflows = (): HookResponse => {
  const pathParams = useParams<{ productId?: string }>();
  const { creatorId } = useContext(TeamsContext);
  const [products, setProducts] = useState<
    Pick<ApiModels.Product, 'id' | 'title'>[]
    >([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isFetching, setIsFetching] = useState(false);
  const [numPages, setNumPages] = useState(0);
  const mostRecentRequestRef = useRef<string | null>(null);
  const { getData: getCreatorProductsFn } = useNoodleApi(getProductsByCreatorId);

  const selectedId = pathParams?.productId;

  const fetchProducts = useCallback(
    async ({ page: newPage }: { page: number }): Promise<void> => {
      if (creatorId) {
        setCurrentPage(newPage);
        const thisRequestId = nanoid();
        mostRecentRequestRef.current = thisRequestId;
        setIsFetching(true);
        setProducts([]);
        setCurrentPage(newPage);

        const response = await getCreatorProductsFn({
          creatorId,
          page: newPage,
          perPage: PAGE_SIZE,
        });

        if (thisRequestId === mostRecentRequestRef.current) {
          if (response.data) {
            setNumPages(response.data.numPages);
            setProducts(response.data.items
              .filter(product => !hasNoodleProductType(product, ApiModels.NoodleProductTypes.Charge)),
            );
          }
          setIsFetching(false);
        }
      }
    },
    [creatorId, getCreatorProductsFn],
  );

  const handleNewPage = (newPage: number): void => {
    fetchProducts({
      page: newPage,
    });
  };

  useEffect(() => {
    fetchProducts({
      page: 1,
    });
  }, [fetchProducts]);

  const pagination = {
    numPages,
    onChangePage: handleNewPage,
    page: currentPage,
  };

  const items = products.map(product => ({
    href: getUrl(IDENTIFIERS.DASHBOARD_WORKFLOWS_MY_WORKFLOWS_PRODUCT, { productId: product.id }),
    id: product.id,
    label: product.title || '',
  }));

  return {
    getDetailPagePath,
    ifNoItemsText: 'No products',
    isFetchingList: isFetching,
    items,
    pagination,
    selectedId,
  };
};

export default useMyWorkflows;
