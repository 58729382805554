import { useContext, useEffect, useMemo, useCallback, useState } from 'react';
import TeamsContext from '@providers/Teams/TeamsContext';
import useNoodleApi from '@hooks/useNoodleApi';
import * as tsClient from '@tsClient';
import { v4 as uuidv4 } from 'uuid';
import { FieldAssigneeTemplate, QuestionnaireTemplate, SectionTemplate } from '@/typings/questionnaires-api-models';
import FormEditor from '@/components/Questionnaire/questionnaireEditor/FormEditor';
import { Questionnaire } from '@/typings/Questionnaire';
import { useUserProfile } from '@/providers/Auth';
import { useToast } from '@/hooks';
import ProgressIndicator from '@components/ProgressIndicator';
import Bluebird from 'bluebird';

type Props = { slug: string };

const QuestionnaireEditor: React.FC<Props> = ({ slug }) => {
  const { currentTeamOwner } = useContext(TeamsContext);
  const {
    data: questionnaireTemplate,
    fetchingState: { isFetching },
    getData: getQuestionnaireTemplate,
  } = useNoodleApi(tsClient.questionnaires.getQuestionnaireTemplateBySlug);
  const { getData: getFieldTemplates } = useNoodleApi(tsClient.questionnaires.getFieldTemplatesForSectionTemplate);
  const [isSaving, setIsSaving] = useState(false);
  const [sectionFields, setSectionFields] = useState<Record<string, unknown>>({});
  const { getData: updateQuestionnaireTemplate } = useNoodleApi(tsClient.questionnaires.updateQuestionnaireTemplate, {
    toastErrorMessage: error => [
      useToast.ToastTypeVariants.ERROR,
      error?.errors?.[0] || 'There was an error updating questionnaire. Please try again.',
    ],
    toastOnError: true,
  });
  const profile = useUserProfile();
  const toast = useToast();

  const creatorId = currentTeamOwner?.id;

  const fetchQuestionnaireTemplate = useCallback(() => {
    if (creatorId) {
      getQuestionnaireTemplate({ creatorId, questionnaireTemplateSlug: slug, statusOrVersion: 'draft' });
    }
  }, [getQuestionnaireTemplate, creatorId, slug]);

  useEffect(() => {
    fetchQuestionnaireTemplate();
  }, [fetchQuestionnaireTemplate]);

  const getFieldTemplatesForSectionTemplate = useCallback(async (section: Pick<SectionTemplate, 'id' | 'slug' | 'questionnaireTemplateId'>): Promise<void> => {
    if (creatorId && section.id) {
      const { data: fields } = await getFieldTemplates({
        creatorId,
        questionnaireTemplateId: section.questionnaireTemplateId,
        sectionTemplateId: section.id,
      });
      if (fields) {
        setSectionFields(prev => ({
          ...prev,
          [section.id!]: fields.map((field) => ({
            ...field,
            assignedTo: field.fieldAssigneeTemplates?.map((assignee) => assignee.questionnaireAssigneeTemplateId) ?? [],
            description: field.descriptionText ?? '',
            id: field.id!,
            validation: field.validation || undefined,
          })),
        }));
      }
    }
  }, [creatorId, getFieldTemplates]);

  const fieldAssigneeTempates = useMemo(() => questionnaireTemplate
    ? (questionnaireTemplate as QuestionnaireTemplate).sectionTemplates?.reduce((p: FieldAssigneeTemplate[], section) => p.concat(
      section.fieldTemplates?.reduce((pp: FieldAssigneeTemplate[], field) => pp.concat(field.fieldAssigneeTemplates ?? []), []) ?? [],
    ), []) ?? []
    : [], [questionnaireTemplate]);

  const handleSave = useCallback(async (data: Questionnaire.Questionnaire): Promise<void> => {
    if (!creatorId || !questionnaireTemplate) return;

    setIsSaving(true);

    const payload = {
      ...questionnaireTemplate,
      creatorId,
      descriptionHtml: data.description,
      descriptionText: data.description,
      id: data.id,
      label: data.label,
      questionnaireAssigneeTemplates: data.assigneeTemplates,
      sectionTemplates: data.sections.map((section: Questionnaire.Section) => ({
        ...section,

        // @todo: support html text later
        descriptionAST: null,

        // @todo: support html text later
        descriptionHtml: section.description,
        descriptionText: section.description,
        fieldTemplates: section.fields.map((field) => ({
          ...field,
          conditionExpression: field.conditionExpression ?? null,
          conditions: field.conditions ?? [],
          descriptionHtml: field.description,
          descriptionText: field.description,
          fieldAssigneeTemplates:
            data.assigneeTemplates
              .filter(({ id }) => field.assignedTo.includes(id))
              .map((qat) => fieldAssigneeTempates.find(
                (fat) => fat.fieldTemplateId === field.id && fat.questionnaireAssigneeTemplateId === qat.id,
              )
              ?? ({
                fieldTemplateId: field.id,
                id: uuidv4(),
                questionnaireAssigneeTemplateId: qat.id,
              })),
          hint: field.hint ?? null,
          options: field.options ?? [],
          questionnaireTemplateId: questionnaireTemplate!.id,
          sectionTemplateId: section.id,
          validation: field.validation ?? null,
          view: field.view ?? null,
        })),
        questionnaireTemplateId: questionnaireTemplate!.id,
      })),
      slug: data.slug,
      status: data.status,
      updatedBy: profile?.id ?? '',
      version: data.version,
      versionNote: data.versionNote ?? '',
    };

    const res = await updateQuestionnaireTemplate({ creatorId, questionnaireTemplateId: questionnaireTemplate.id }, payload);

    if (data.status === 'current' && !res.error) {
      await fetchQuestionnaireTemplate();

      toast(useToast.ToastTypeVariants.SUCCESS, 'Questionnaire published successfully!');
    }

    setIsSaving(false);
  },
  [
    creatorId,
    questionnaireTemplate,
    fieldAssigneeTempates,
    profile?.id,
    toast,
    updateQuestionnaireTemplate,
    fetchQuestionnaireTemplate,
  ]);

  useEffect(() => {
    if (questionnaireTemplate?.sectionTemplates) {
      Bluebird.mapSeries(questionnaireTemplate.sectionTemplates.sort((a, b) => a.rank - b.rank), getFieldTemplatesForSectionTemplate);
    }
  }, [questionnaireTemplate, getFieldTemplatesForSectionTemplate]);

  // @ts-expect-error @todo: fix validation typing issue later
  const formData: Questionnaire.Questionnaire | undefined = useMemo(() =>
    questionnaireTemplate
      ? ({
        ...questionnaireTemplate,
        assigneeTemplates: questionnaireTemplate.questionnaireAssigneeTemplates,
        description: questionnaireTemplate.descriptionText ?? '',
        id: questionnaireTemplate.id!,
        sections: questionnaireTemplate.sectionTemplates?.map((section: SectionTemplate) => ({
          ...section,
          description: section.descriptionText ?? '',
          // @todo: support html text later
          fields: sectionFields[section.id!] ?? [],
          id: section.id!,
          isLoading: !sectionFields[section.id!],
        })).sort(
          (a: SectionTemplate, b: SectionTemplate) => {
            const rankA = a.rank ?? Number.MAX_SAFE_INTEGER;
            const rankB = b.rank ?? Number.MAX_SAFE_INTEGER;
            return rankA - rankB;
          },
        ) ?? [],
        updatedByName: '',
      })
      : undefined,
  [questionnaireTemplate, sectionFields],
  );

  if (isFetching) {
    return <ProgressIndicator isCentered isPage />;
  }

  if (!creatorId || !questionnaireTemplate) {
    return null;
  }

  return formData
    ? (
      <FormEditor
        formData={formData}
        isSaving={isSaving}
        onSave={handleSave}
      />
    )
    : null;
};

export default QuestionnaireEditor;
