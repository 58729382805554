import { TD } from '@/components/DesignLibrary/Table';

export type Props = {
  type: 'text';
  content: string;
};

const TextCell: React.FC<Props> = ({ content }) => (
  <TD label={content} compact />
);

export default TextCell;