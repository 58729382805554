import React, { ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import { useQueryState } from 'nuqs';
import { nanoid } from 'nanoid';

import DashboardContext from '@layouts/DashboardLayout/DashboardContext';
import WorkflowSubLayout from '@layouts/DashboardLayout/SubLayouts/Workflow';
import UserProfileContext from '@layouts/UserProfileLayout/UserProfileContext';
import FormRequestDetails from '@lib/FormRequestDetails';
import CreditReportDetails from '@lib/CreditReportDetails';
import CustomTermsDetails from '@lib/CustomTermsDetails';
import WorkflowDetails from '@lib/WorkflowDetails';
import DocumentRequestUserDetail from '@lib/DocumentRequestUserDetailV3';
import InvoicePreview from '@lib/InvoicePreview/InvoicePreview';
import { DocumentAccessProvider } from '@providers/DocumentAccess';
import DocumentRequestUserFileDetail from '@lib/DocumentRequestUserFileDetail';
import UserWorkflowFollowupHistoryPage from '@lib/UserWorkflowFollowupHistoryPage';
import AIDocumentGenerationForm from '@components/AIDocumentGenerationForm';
import UserWorkflowDocuments from '@lib/UserWorkflowDocuments';
import AiFollowupHistoryPage from '@lib/AiFollowupHistoryPage';
import DocumentPreview from '@lib/DocumentPreview/DocumentPreview';
import UserWorkflowActivityThread from '@/lib/UserWorkflowActivityThread';
import QuestionnaireEditor from '@/components/Questionnaire/questionnaireEditor/QuestionnaireEditor';
import PaneWrapper from './PaneWrapper';
import { QS_KEY as CUSTOM_TERMS_ID_KEY } from './customTermsHooks';
import { QS_KEY as DOCUMENT_REQUEST_USER_ID_KEY } from './documentRequestUserHooks';
import { QS_KEY as FORM_REQUEST_ID_KEY } from './formRequestHooks';
import { QS_KEY as CREDIT_REPORT_ID_KEY } from './creditReportHooks';
import { QS_KEY as FORM_REQUEST_TEMPLATE_SLUG_KEY } from './formRequestTemplateHooks';
import { QS_KEY as INVOICE_REQUEST_ID_KEY } from './invoiceHooks';
import { QS_KEY as DOCUMENT_REQUEST_USER_FILE_ID_KEY } from './documentRequestUserFileHooks';
import { QS_KEY as USER_WORKFLOW_DOCUMENT_ID_KEY } from './userWorkflowDocumentHooks';
import { QS_KEY as FOLLOWUP_USER_WORKFLOW_KEY } from './followupHooks';
import { QS_KEY as FOLLOWUPS_KEY } from './followupsHooks';
import { QS_KEY as ALL_CASES_KEY } from './allCasesHooks';
import { QS_KEY as WORKFLOW_DOCUMENTS_ID_KEY } from './documentsHooks';
import { QS_KEY as PREVIEW_DOCUMENT_ID_KEY } from './previewDocumentHooks';
import { QS_KEY as WORKFLOW_SLUG_KEY } from './workflowHooks';
import { QS_KEY as ACTIVITY_THREAD_USER_WORKFLOW_ID_KEY } from './activityThreadHooks';

type Props = {
  hasLeftNav: boolean;
};

const Panes: React.FC<Props> = ({ hasLeftNav }) => {
  const { creator: creatorFromDashboard, isInDashboard } = useContext(DashboardContext);
  const { creatorSlug: creatorSlugFromUserProfile, isInUserProfile } = useContext(UserProfileContext);

  const [paneContent, setPaneContent] = useState<ReactNode>(null);
  const [onPaneClose, setOnPaneClose] = useState<(() => void) | null>(null);
  const [paneKey, setPaneKey] = useState(nanoid());

  const [paneDocumentRequestUserId, setDocumentRequestUserId] = useQueryState(DOCUMENT_REQUEST_USER_ID_KEY);
  const [paneCustomTermsId, setCustomTermsId] = useQueryState(CUSTOM_TERMS_ID_KEY);
  const [paneFormRequestId, setFormRequestId] = useQueryState(FORM_REQUEST_ID_KEY);
  const [paneCreditReportId, setCreditReportId] = useQueryState(CREDIT_REPORT_ID_KEY);
  const [paneFormRequestTemplateSlug, setFormRequestTemplateSlug] = useQueryState(FORM_REQUEST_TEMPLATE_SLUG_KEY);
  const [paneInvoiceId, setInvoiceId] = useQueryState(INVOICE_REQUEST_ID_KEY);
  const [paneDocumentRequestUserFileId, setDocumentRequestUserFileId] = useQueryState(DOCUMENT_REQUEST_USER_FILE_ID_KEY);
  const [paneFollowupUserWorkflowId, setFollowupUserWorkflowId] = useQueryState(FOLLOWUP_USER_WORKFLOW_KEY);
  const [paneAiFollowups, setAiFollowups] = useQueryState(FOLLOWUPS_KEY);
  const [paneAllCases, setAllCases] = useQueryState(ALL_CASES_KEY);
  const [paneUserWorkflowDocumentId, setUserWorkflowDocumentId] = useQueryState(USER_WORKFLOW_DOCUMENT_ID_KEY);
  const [paneWorkflowDocumentsId, setWorkflowDocumentsId] = useQueryState(WORKFLOW_DOCUMENTS_ID_KEY);
  const [panePreviewDocumentId, setPreviewDocumentId] = useQueryState(PREVIEW_DOCUMENT_ID_KEY);
  const [paneWorkflowSlug, setWorkflowSlug] = useQueryState(WORKFLOW_SLUG_KEY);
  const [paneActivityThreadUserWorkflowId, setActivityThreadUserWorkflowId] = useQueryState(ACTIVITY_THREAD_USER_WORKFLOW_ID_KEY);

  let creatorSlug: string | null = null;
  let creatorId: string | null = null;
  let creatorNoodlePhoneNumber: string | null = null;

  if (isInDashboard) {
    creatorSlug = creatorFromDashboard?.slug ?? null;
    creatorId = creatorFromDashboard?.id ?? null;
    creatorNoodlePhoneNumber = creatorFromDashboard?.noodlePhoneNumber ?? null;
  } else if (isInUserProfile) {
    creatorSlug = creatorSlugFromUserProfile;
    creatorId = null;
  }

  const setPaneState = useCallback((args: { content: ReactNode, onClose: (() => void) | null } | null): void => {
    if (args) {
      // needs to be a function that returns the function so that setState doesn't execute it right away.
      // Thinking it's: setState(prev => newState);
      const { onClose } = args;
      setOnPaneClose(() => () => {
        if (onClose) {
          onClose();
        }
      });
      setPaneContent(args.content);
    } else {
      setPaneContent(null);
      setOnPaneClose(null);
    }
  }, []);

  useEffect(() => {
    setPaneKey(nanoid());
  }, [paneContent]);

  useEffect(() => {
    if (creatorSlug && paneFormRequestId) {
      setPaneState({
        content: <FormRequestDetails creatorSlug={creatorSlug} formRequestId={paneFormRequestId} />,
        onClose: () => setFormRequestId(null),
      });
    } else if (paneCreditReportId) {
      setPaneState({
        content: <CreditReportDetails creditReportId={paneCreditReportId} />,
        onClose: () => setCreditReportId(null),
      });
    } else if (creatorSlug && paneFormRequestTemplateSlug) {
      setPaneState({
        content: <QuestionnaireEditor slug={paneFormRequestTemplateSlug} />,
        onClose: () => setFormRequestTemplateSlug(null),
      });
    } else if (paneCustomTermsId) {
      setPaneState({
        content: <CustomTermsDetails customTermsId={paneCustomTermsId} />,
        onClose: () => setCustomTermsId(null),
      });
    } else if (paneDocumentRequestUserFileId) { // must be before paneDocumentRequestUserId
      setPaneState({
        content: <DocumentRequestUserFileDetail
          documentRequestUserFileId={paneDocumentRequestUserFileId}
        />,
        onClose: () => setDocumentRequestUserFileId(null),
      });
    } else if (panePreviewDocumentId) { // must be before paneWorkflowDocumentsId
      setPaneState({
        content: (
          <DocumentAccessProvider>
            <DocumentPreview documentId={panePreviewDocumentId} />
          </DocumentAccessProvider>
        ),
        onClose: () => setPreviewDocumentId(null),
      });
    } else if (creatorSlug && paneDocumentRequestUserId) {
      setPaneState({
        content: (
          <DocumentRequestUserDetail
            creatorSlug={creatorSlug}
            documentRequestUserId={paneDocumentRequestUserId}
          />
        ),
        onClose: () => setDocumentRequestUserId(null),
      });
    } else if (creatorSlug && paneInvoiceId) {
      setPaneState({
        content: (
          <DocumentAccessProvider>
            <InvoicePreview creatorSlug={creatorSlug} invoiceId={paneInvoiceId} />
          </DocumentAccessProvider>
        ),
        onClose: () => setInvoiceId(null),
      });
    } else if (creatorId && paneFollowupUserWorkflowId) {
      setPaneState({
        content: (
          <UserWorkflowFollowupHistoryPage
            creatorId={creatorId}
            creatorNoodlePhoneNumber={creatorNoodlePhoneNumber}
            userWorkflowId={paneFollowupUserWorkflowId}
          />
        ),
        onClose: () => setFollowupUserWorkflowId(null),
      });
    } else if (paneAiFollowups) {
      setPaneState({
        content: <AiFollowupHistoryPage />,
        onClose: () => setAiFollowups(null),
      });
    } else if (paneAllCases) {
      setPaneState({
        content: <WorkflowSubLayout resourceType='activity' isTableViewOnly hideTabs/>,
        onClose: () => setAllCases(null),
      });
    } else if (paneUserWorkflowDocumentId && creatorSlug) {
      setPaneState({
        content: (
          <AIDocumentGenerationForm
            creatorSlug={creatorSlug}
            userWorkflowDocumentId={paneUserWorkflowDocumentId}
            onClose={() => setUserWorkflowDocumentId(null)}
          />
        ),
        onClose: () => setUserWorkflowDocumentId(null),
      });
    } else if (paneWorkflowDocumentsId && creatorId) {
      setPaneState({
        content: (
          <DocumentAccessProvider>
            <UserWorkflowDocuments creatorId={creatorId} isDownloadEnabled userWorkflowId={paneWorkflowDocumentsId} />
          </DocumentAccessProvider>
        ),
        onClose: () => setWorkflowDocumentsId(null),
      });
    } else if (paneWorkflowSlug) {
      setPaneState({
        content: (
          <WorkflowDetails workflowSlug={paneWorkflowSlug} />
        ),
        onClose: () => setWorkflowSlug(null),
      });
    } else if (paneActivityThreadUserWorkflowId) {
      setPaneState({
        content: (
          <UserWorkflowActivityThread userWorkflowId={paneActivityThreadUserWorkflowId} />
        ),
        onClose: () => setActivityThreadUserWorkflowId(null),
      });
    }
    else {
      setPaneState(null);
    }
  }, [
    creatorId,
    creatorNoodlePhoneNumber,
    creatorSlug,
    setPaneState,
    paneFormRequestId,
    setFormRequestId,
    paneCreditReportId,
    setCreditReportId,
    paneCustomTermsId,
    setCustomTermsId,
    paneDocumentRequestUserId,
    setDocumentRequestUserId,
    paneInvoiceId,
    setInvoiceId,
    paneDocumentRequestUserFileId,
    setDocumentRequestUserFileId,
    paneFollowupUserWorkflowId,
    setFollowupUserWorkflowId,
    paneUserWorkflowDocumentId,
    setUserWorkflowDocumentId,
    paneWorkflowDocumentsId,
    setWorkflowDocumentsId,
    paneAiFollowups,
    setAiFollowups,
    paneAllCases,
    setAllCases,
    panePreviewDocumentId,
    setPreviewDocumentId,
    paneWorkflowSlug,
    setWorkflowSlug,
    paneActivityThreadUserWorkflowId,
    setActivityThreadUserWorkflowId,
    paneFormRequestTemplateSlug,
    setFormRequestTemplateSlug,
  ]);

  return (
    <>
      {paneContent && onPaneClose && (
        <PaneWrapper
          key={paneKey}
          hasSideNav={hasLeftNav}
          onClose={onPaneClose}
        >
          {paneContent}
        </PaneWrapper>
      )}
    </>
  );
};

export default Panes;
