import * as ApiModels from '@typings/api-models';
import Payment from './Payment';
import CancelPaymentEvent from './CancelPaymentEvent';

type Props = {
  payments: Array<
    (& Pick<ApiModels.Payment, 'id'>
    & Parameters<typeof Payment>[0]['payment']
    & { type: 'payment' })
    | {
      id: string;
      type: 'cancel-event';
      canceledAt: string;
    }
  >;
};

const PaymentsList: React.FC<Props> = ({ payments }) => (
  <>
    {payments.map((payment) => (
      <>
        {payment.type === 'payment' && (
          <Payment key={payment.id} payment={payment} />
        )}
        {payment.type === 'cancel-event' && (
          <CancelPaymentEvent key={payment.id} event={payment} />
        )}
      </>
    ))}
    {payments.length === 0 && (
      <p className='body-sm'>No payments have been made yet.</p>
    )}
  </>
);

export default PaymentsList;
