import { useEffect, useMemo, useState, forwardRef, useImperativeHandle } from 'react';
import classes from '@components/StripeCartPaymentFormStep/stripeCartPaymentFormStep.module.scss';
import Spacer from '@components/Spacer';
import Tabs from '@components/DesignLibrary/Tabs';
import DefaultPaymentMethod from '@components/StripeCartPaymentFormStep/DefaultPaymentMethod';
import Buttons from '@components/Buttons';
import PlusCircle from '@components/Icons/PlusCircle';
import ArrowCircleLeft from '@components/Icons/ArrowCircleLeft';
import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { CARD_ELEMENT_OPTIONS } from '@helpers/checkout';
import InputField from '@components/FormFields/InputField';
import removeNullish from '@helpers/removeNullish';
import useNoodleApi from '@hooks/useNoodleApi';
import * as tsClient from '@tsClient';
import { useUser } from '@providers/Auth';
import canAddNewPaymentMethod from '@components/StripeCartPaymentFormStep/canAddNewPaymentMethod';
import {
  StripeCardCvcElementChangeEvent,
  StripeCardExpiryElementChangeEvent,
  StripeCardNumberElementChangeEvent,
} from '@stripe/stripe-js';
import { mixpanelTrack } from '@providers/Mixpanel';
import { logError } from '@providers/ErrorTracking';
import * as stripeHelpers from '@helpers/stripe';
import { getUrl, IDENTIFIERS } from '@helpers/urlsHelper';
import { attachNewCard } from '@tsClient';
import ApiError from '@helpers/ApiError';
import { nanoid } from 'nanoid';
import { useRouter } from 'next/router';
import ProgressIndicator from '@components/ProgressIndicator';
import * as ApiModels from '@typings/api-models';

type Props = {
  creatorId: string;
  creatorSlug: string;
  isDebitCardPaymentEnabled: boolean;
  isCreditPaymentEnabled: boolean;
  isAchPaymentEnabled: boolean;
  initialName?: string;
  onBehalfOfPersonId?: string;
  setIsButtonEnabled: (e: boolean) => void;
  person: Pick<ApiModels.Person, 'id' | 'name'>;
  selectedPaymentMethodId?: string;
};

type PaymentType = 'Card' | 'ACH';

const isCreditCardRequired = ({
  onlyFreeProducts,
  defaultPaymentMethod,
  useDifferentPaymentMethod,
}: {
  onlyFreeProducts: boolean;
  defaultPaymentMethod: {
    card: unknown | null;
  } | null;
  useDifferentPaymentMethod: boolean;
}): boolean => {
  if (onlyFreeProducts) {
    return false;
  }

  if (defaultPaymentMethod?.card && !useDifferentPaymentMethod) {
    return false;
  }

  return true;
};

export type SelectPaymentMethodRef = {
  getPaymentMethodId: () => Promise<string | null>;
};

// eslint-disable-next-line prefer-arrow-callback
const SelectPaymentMethod = forwardRef(function SelectPaymentMethod({
  creatorId,
  creatorSlug,
  isDebitCardPaymentEnabled,
  isCreditPaymentEnabled,
  isAchPaymentEnabled,
  initialName,
  onBehalfOfPersonId,
  setIsButtonEnabled,
  person,
  selectedPaymentMethodId,
}: Props, ref) {
  const [paymentType, setPaymentType] = useState<PaymentType>((isCreditPaymentEnabled || isDebitCardPaymentEnabled) ? 'Card' : 'ACH');
  const [useDifferentPaymentMethod, setUseDifferentPaymentMethod] = useState(false);
  const [userHasCustomerStripeId, setUserHasCustomerStripeId] = useState(false);
  const [isValidCCNumber, setIsValidCCNumber] = useState(false);
  const [isValidExpiry, setIsValidExpiry] = useState(false);
  const [isValidCvc, setIsValidCvc] = useState(false);
  const [payerName, setPayerName] = useState<string>(initialName || '');
  const [isFetchingPaymentMethod, setIsFetchingPaymentMethod] = useState(false);
  const errorCorrelationId = useMemo(() => nanoid(), []);

  const router = useRouter();

  const stripe = useStripe();
  const elements = useElements();
  const [user] = useUser();

  const { getData: createSetupIntentFn } = useNoodleApi(tsClient.createSetupIntent);
  const { data: thisCustomer, getData: getCustomerStripeFn } = useNoodleApi(tsClient.getCustomerStripe);
  const { data: myPaymentMethodsResponse, getData: getPaymentMethodsForPersonFn } = useNoodleApi(tsClient.paymentMethods.getPaymentMethodsForPerson);

  useEffect(() => {
    const getPaymentMethods = async (): Promise<void> => {
      try {
        if (creatorId && user?.id && !user.isAnonymous) {
          setIsFetchingPaymentMethod(true);
          const getCustomerResponse = await getCustomerStripeFn({ creatorId, personId: person.id });
          await getPaymentMethodsForPersonFn({ creatorId, personId: person.id });
          setUserHasCustomerStripeId(Boolean(getCustomerResponse.data?.id));
          setIsFetchingPaymentMethod(false);
        }
      } catch (err) {
        setUserHasCustomerStripeId(false);
        setIsFetchingPaymentMethod(false);
      }
    };
    getPaymentMethods();
  }, [user, user, creatorId, getPaymentMethodsForPersonFn, getCustomerStripeFn]);

  const myPaymentMethods = myPaymentMethodsResponse?.items || [];
  const customerDefaultPaymentMethodId = selectedPaymentMethodId || thisCustomer?.invoice_settings?.default_payment_method || null;
  const unverifiedPaymentMethods = myPaymentMethods.filter(pm => Boolean(pm.verifyWithMicrodeposits));
  const hasUnverifiedPaymentMethods = unverifiedPaymentMethods.length > 0;
  const defaultPaymentMethod = myPaymentMethods.find(p => p.id === customerDefaultPaymentMethodId && (paymentType === 'Card' ? (isDebitCardPaymentEnabled && !isCreditPaymentEnabled ? p.card?.funding === 'debit' : p.card) : p.us_bank_account)) || null;
  const useDefaultPaymentMethod = defaultPaymentMethod && !useDifferentPaymentMethod;

  const getAvailableCardTypes = (): string => [
    isCreditPaymentEnabled ? 'Credit' : null,
    isDebitCardPaymentEnabled ? 'Debit' : null,
  ].filter(removeNullish).join(' or ');

  const handleCardElementChange = async (event: StripeCardNumberElementChangeEvent): Promise<void> => {
    setIsValidCCNumber(event.complete);
  };

  const handleExpiryChange = async (event: StripeCardExpiryElementChangeEvent): Promise<void> => {
    setIsValidExpiry(event.complete);
  };

  const handleCvcChange = async (event: StripeCardCvcElementChangeEvent): Promise<void> => {
    setIsValidCvc(event.complete);
  };

  const getPaymentMethodId = async (): Promise<string | null> => {
    if (!stripe || !elements) {
      throw new Error(`Stripe not initialized: stripe=${Boolean(stripe)} elements=${Boolean(elements)}`);
    }

    let paymentMethodId: string | undefined | null;

    if (paymentType === 'Card') {
      if (!paymentMethodId && useDefaultPaymentMethod && defaultPaymentMethod?.type === 'card') {
        paymentMethodId = defaultPaymentMethod.id;
      }

      if (!paymentMethodId) {
        const card = elements?.getElement(CardNumberElement);
        if (!card) {
          throw new Error('Failed to get card from stripe');
        }

        const paymentMethodData = await stripe.createPaymentMethod({
          billing_details: {
            name: payerName || '',
          },
          card,
          type: 'card',
        });

        const { error: stripeError, paymentMethod } = paymentMethodData;

        if (stripeError) {
          console.warn('errorCorrelationId', errorCorrelationId);
          mixpanelTrack('Error in payment method flow (stripe error)', {
            errorCorrelationId,
            errorReason: stripeError.message,
          });
          return null;
        }

        if (!paymentMethod) {
          console.warn('errorCorrelationId', errorCorrelationId);
          mixpanelTrack('Error in payment method flow (no payment method)', { errorCorrelationId });
          const noPaymentMethodError = new Error('No payment method available');
          logError(noPaymentMethodError, { errorCorrelationId });
          return null;
        }

        paymentMethodId = paymentMethod.id;
      }
    } else if (paymentType === 'ACH') {
      if (!paymentMethodId && useDefaultPaymentMethod && defaultPaymentMethod?.type === 'us_bank_account') {
        paymentMethodId = defaultPaymentMethod.id;
      }
      if (!paymentMethodId && creatorId) {
        const { data: setupIntent } = await createSetupIntentFn({ creatorId, personId: person.id });
        const clientSecret = setupIntent?.client_secret;
        if (!clientSecret) {
          throw new Error('Failed to create setup intent');
        }
        const bankSetupResponse = await stripe.collectBankAccountForSetup({
          clientSecret,
          expand: ['payment_method'],
          params: {
            payment_method_data: {
              billing_details: {
                name: payerName,
              },
            },
            payment_method_type: 'us_bank_account',
          },
        });

        const confirmBankSetupResponse = await stripe.confirmUsBankAccountSetup(clientSecret);
        const debugInfo = {
          nextAction: confirmBankSetupResponse.setupIntent?.next_action,
          setupIntentId: confirmBankSetupResponse.setupIntent?.id,
          setupIntentStatus: confirmBankSetupResponse.setupIntent?.status,
        };

        if (confirmBankSetupResponse.setupIntent?.status === 'succeeded') {
          paymentMethodId = stripeHelpers.getPaymentMethodId(bankSetupResponse.setupIntent?.payment_method);
        } else if (confirmBankSetupResponse.setupIntent?.next_action?.type === 'verify_with_microdeposits') {
          router.push(getUrl(IDENTIFIERS.USER_PROFILE_EDIT, { creatorSlug }));
        } else if (confirmBankSetupResponse.error) {
          console.warn('Failed bank account setup in an unexpected way', debugInfo);
          console.warn(confirmBankSetupResponse.error);
          logError(confirmBankSetupResponse.error, debugInfo);
        } else {
          console.warn('Failed bank account setup in an unexpected way', debugInfo);
          logError(new Error('Failed bank account setup in an unexpected way'), debugInfo);
        }
      }
    }
    if (creatorId && paymentMethodId) {
      try {
        await attachNewCard({
          creatorId,
          isDefaultPaymentMethod: true,
          paymentMethodId,
          ...(onBehalfOfPersonId && { personId: onBehalfOfPersonId }),
        });
      } catch (attachNewCardError) {
        const asApiError = ApiError.create(attachNewCardError);
        const reason = asApiError?.errors && asApiError?.errors.length > 0 ? asApiError.errors[0] : asApiError.message;
        mixpanelTrack('Error attaching card', { errorCorrelationId, paymentMethodId, reason });
      }
    }
    return paymentMethodId || null;
  };

  useImperativeHandle(ref, () => ({
    getPaymentMethodId,
  }), [getPaymentMethodId]);

  const isAddNewPaymentMethodButtonShown = canAddNewPaymentMethod({
    creatorSlug,
    defaultPaymentMethod,
    isAchPaymentEnabled,
    isCardPaymentEnabled: isCreditPaymentEnabled,
    isDebitCardPaymentEnabled,
    useDifferentPaymentMethod,
    userHasCustomerStripeId,
  });

  const showCreditCardForm = paymentType === 'Card' && (isCreditPaymentEnabled || isDebitCardPaymentEnabled)
    && isCreditCardRequired({
      defaultPaymentMethod,
      onlyFreeProducts: false,
      useDifferentPaymentMethod,
    });

  const isButtonEnabled = (): boolean => {
    if (paymentType === 'ACH') {
      if (hasUnverifiedPaymentMethods) {
        return false;
      }

      if (defaultPaymentMethod?.us_bank_account && !useDifferentPaymentMethod) {
        return true;
      }

      return Boolean(payerName);
    }

    const isCardComplete = (
      isValidCCNumber
      && isValidExpiry
      && isValidCvc
      && Boolean(payerName)
    );

    if (paymentType === 'Card' && !isAchPaymentEnabled && !isCreditPaymentEnabled) {
      if (hasUnverifiedPaymentMethods) {
        return false;
      }
      if (defaultPaymentMethod?.card?.funding === 'debit' && !useDifferentPaymentMethod) {
        return true;
      }
    }

    if (defaultPaymentMethod && !useDifferentPaymentMethod) {
      return true;
    }

    return isCardComplete;
  };

  useEffect(() => {
    setIsButtonEnabled(isButtonEnabled());
  }, [setIsButtonEnabled, isButtonEnabled]);

  return (
    <div>
      {(isDebitCardPaymentEnabled || isCreditPaymentEnabled) && isAchPaymentEnabled
        ? (
          <>
            <span className='body-sm-bold'>Select payment method</span>
            <Spacer size={12} />
            <Tabs
              inputName={'paymentType'}
              inputs={[
                {
                  id: 'Card',
                  label: `${getAvailableCardTypes()} Card`,
                },
                {
                  id: 'ACH',
                  label: 'Bank Transfer (ACH)',
                },
              ]}
              onChange={(value) => setPaymentType(value)}
            />
            <Spacer size={24} />
          </>
        )
        : <span className='body-sm-bold'>Payment Information</span>}
      {isFetchingPaymentMethod
        ? <ProgressIndicator isCentered />
        : (
          <div>
            <Spacer size={16} />
            <DefaultPaymentMethod
              creatorSlug={creatorSlug}
              paymentType={paymentType}
              defaultPaymentMethod={defaultPaymentMethod}
              isCardPaymentEnabled={isCreditPaymentEnabled}
              isDebitCardPaymentEnabled={isDebitCardPaymentEnabled}
              unverifiedPaymentMethods={unverifiedPaymentMethods}
              useDifferentPaymentMethod={useDifferentPaymentMethod}
              userHasCustomerStripeId={userHasCustomerStripeId}
              addSpacing={false}
            />
            {isAddNewPaymentMethodButtonShown && (
              <>
                <Spacer size={16} />
                <Buttons className={classes.newPaymentButton} isWrapper onClick={() => setUseDifferentPaymentMethod(true)}>
                  <PlusCircle weight="fill" size={24} color="var(--color-noodle)" />
                  <span>Use a different payment method</span>
                </Buttons>
              </>
            )}
            {showCreditCardForm && (isCreditPaymentEnabled || isDebitCardPaymentEnabled) && (
              <>
                {defaultPaymentMethod?.card && (
                  <>
                    <Buttons className={classes.newPaymentButton} isWrapper onClick={() => setUseDifferentPaymentMethod(false)}>
                      <ArrowCircleLeft weight="fill" size={24} color="var(--color-noodle)" />
                      <span>Use existing payment method</span>
                    </Buttons>
                    <Spacer size={16} />
                  </>
                )}
                <span className={classes.cardNumberLabel}>Card Number</span>
                <div className={classes.cardContainersWrapper}>
                  <div className={classes.cardNumberContainer}>
                    <CardNumberElement options={CARD_ELEMENT_OPTIONS} onChange={handleCardElementChange} />
                  </div>
                  <div className={classes.cardExtraInfoContainer}>
                    <div className={classes.cardExpirationContainer}>
                      <CardExpiryElement options={CARD_ELEMENT_OPTIONS} onChange={handleExpiryChange} />
                    </div>
                    <div className={classes.cardCvcContainer}>
                      <CardCvcElement options={CARD_ELEMENT_OPTIONS} onChange={handleCvcChange} />
                    </div>
                  </div>
                </div>
                <InputField
                  id="payerName"
                  label="Name on card"
                  name="payerName"
                  values={{ payerName }}
                  placeholder="Enter the name on card"
                  onChange={setPayerName}
                  autoComplete={'name'}
                />
              </>
            )}
            {paymentType === 'ACH' && defaultPaymentMethod?.us_bank_account && useDifferentPaymentMethod && (
              <>
                <div className={classes.newPaymentButton}>
                  <Buttons isWrapper onClick={() => setUseDifferentPaymentMethod(false)}>
                    <ArrowCircleLeft weight="fill" size={24} color="var(--color-noodle)" />
                  </Buttons>
                  <span>Use existing payment method</span>
                </div>
              </>
            )}
            {paymentType === 'ACH' && (defaultPaymentMethod?.us_bank_account ? useDifferentPaymentMethod : true) && (
              <InputField
                id="bankName"
                label="Name on bank account"
                name="payerName"
                values={{ payerName }}
                placeholder="Enter the name on the bank account"
                onChange={setPayerName}
                autoComplete={'name'}
              />
            )}
          </div>
        )}
    </div>
  );
});

export default SelectPaymentMethod;
