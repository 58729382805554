import { CardDetailsCurrentUser } from './components/CardDetails';
import ACHDetails from './components/ACHDetails';

type Props = {
  creator: {
    slug: string;
  }
  isLoading: boolean;
  isPrimary: boolean;
  onSaveCard?: Parameters<typeof CardDetailsCurrentUser>[0]['onSaveCard']
  paymentMethod: {
    id: string;
    card: Parameters<typeof CardDetailsCurrentUser>[0]['card'] | null;
    us_bank_account: Parameters<typeof ACHDetails>[0]['account'] | null;
    verifyWithMicrodeposits: Parameters<typeof ACHDetails>[0]['verifyWithMicrodeposits'] | null;
  }
};

const PaymentMethod: React.FC<Props> = ({
  creator,
  isLoading,
  isPrimary,
  onSaveCard,
  paymentMethod,
}) => {
  if (paymentMethod.card) {
    return (
      <CardDetailsCurrentUser
        card={paymentMethod.card}
        isPrimary={isPrimary}
        isLoading={isLoading}
        creatorSlug={creator.slug}
        onSaveCard={onSaveCard}
      />
    );
  }

  if (paymentMethod.us_bank_account) {
    return (
      <ACHDetails
        account={paymentMethod.us_bank_account}
        verifyWithMicrodeposits={paymentMethod.verifyWithMicrodeposits}
        isLoading={isLoading}
        isPrimary={isPrimary}
      />
    );
  }

  return <div>Nope</div>;
  return null;
};

export default PaymentMethod;
