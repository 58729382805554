import { ReactElement, useState } from 'react';
import Modal from '@components/Modal';
import TextInput from '@components/FormFields/TextInput';
import Buttons from '@components/Buttons';
import Spacer from '@components/Spacer';
import s from './RenameDocumentModal.module.scss';

type Props = {
  fileName: string;
  onSave: (newName: string) => void;
  onClose : () => void;
};

const RenameDocumentModal = ({fileName, onSave, onClose}: Props): ReactElement => {

  const fileExtension = fileName.split('.').pop();

  const [loading,setLoading] = useState(false);

  const [newFileName,setNewFileName] = useState<string>(fileName.replace(/\.[^/.]+$/, ''));

  return (
    <Modal
      hideBottomInset
      title={'Rename File'}

      onClose={onClose}
    >
      <div>

      </div>
      <div className={s.renameFile}>
        <TextInput
          id={'name'}
          onChange={(val) => setNewFileName(val)}
          value={newFileName}

          label={'File name'}
          placeholder={`Enter new file name`}
          autoComplete={'name'}
        />

        <Spacer size={12}/>
        <Buttons isFetching={loading} isSecondary isFullWidth onClick={() => {
          onSave(newFileName + (fileExtension !== "" ? `.${fileExtension}` : "" ));
          setLoading(true);
        } }>
          Save
        </Buttons>
      </div>
    </Modal>
  );
};

export default RenameDocumentModal;
