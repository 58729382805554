import { HostedFieldsState } from './ConfidoTypes';

const validateState = ({
  isAchPaymentEnabled,
  isCreditPaymentEnabled,
  isDebitCardPaymentEnabled,
  isDebitCardPaymentRequiredForCustomer,
  isCustomerPaymentMethod,
  state: originalState,
}: {
  isAchPaymentEnabled: boolean;
  isCreditPaymentEnabled: boolean;
  isDebitCardPaymentEnabled: boolean;
  isDebitCardPaymentRequiredForCustomer: boolean;
  isCustomerPaymentMethod: boolean;
  state: HostedFieldsState;
}): HostedFieldsState => {
  let state = originalState;

  if (state.paymentMethod === 'DEBIT') {
    if (!isDebitCardPaymentEnabled) {
      state = {
        ...state,
        fields: {
          ...state.fields,
          cardNumber: state.fields.cardNumber
            ? {
              ...state.fields.cardNumber,
              error: state.fields.cardNumber.error || { message: 'Debit cards are not allowed' },
            }
            : state.fields.cardNumber,
        },
      };
    }
  } else if (state.paymentMethod === 'CREDIT') {
    if (!isCreditPaymentEnabled || (isDebitCardPaymentRequiredForCustomer && isCustomerPaymentMethod)) {
      state = {
        ...state,
        fields: {
          ...state.fields,
          cardNumber: state.fields.cardNumber
            ? {
              ...state.fields.cardNumber,
              error: state.fields.cardNumber.error || { message: 'Credit cards are not allowed' },
            }
            : state.fields.cardNumber,
        },
      };
    }
  } else if (state.paymentMethod === 'ACH') {
    if (!isAchPaymentEnabled) {
      state = {
        ...state,
        fields: {
          ...state.fields,
          accountNumber: state.fields.accountNumber
            ? {
              ...state.fields.accountNumber,
              error: state.fields.accountNumber.error || { message: 'ACH payments are not allowed' },
            }
            : state.fields.accountNumber,
        },
      };
    }
  }

  return state;
};

export default validateState;
