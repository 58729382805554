import { FC, useState } from 'react';
import EditButton from '@components/EditButton';
import FlagBanner from '@components/Icons/FlagBanner';
import DOMPurify from 'isomorphic-dompurify';
import * as format from '@format';
import * as ApiModels from '@typings/api-models';

import Button from '@components/Buttons/Buttons';
import UserCirclePlus from '@components/Icons/UserCirclePlus';
import getAttachmentIcon from '@helpers/getAttachmentIcon';
import { AssignToData, AssignToPersonType, Expression } from '@typings/api-models';
import updateWorkflowStep from '@tsClient/workflows/updateWorkflowStep';
import useNoodleApi from '@hooks/useNoodleApi';
import CustomLink from '@components/CustomLink';
import { IDENTIFIERS, getUrl } from '@helpers/urlsHelper';
import File from '@components/Icons/File';
import AssignSelector from '@components/AssignSelector';
import classes from './ActionCard.module.scss';
import ConditionsWidget from '../DesignLibrary/ConditionsWidget';

type ThisWorkflowStep = Pick<ApiModels.WorkflowStep, 'id'> & Parameters<typeof format.actionText>[0];

type ThisMessageAttachment = Pick<ApiModels.MessageAttachment, 'id' | 'title' | 'referenceType' | 'referenceId'>
  & { assignTo?: AssignToData[] }
  & { expression?: Expression | null };

type ThisMessageMedia = Pick<ApiModels.Media, 'id' | 'name'>;

type SendMessageActionData = {
  message: {
    html: string;
    attachments: ThisMessageAttachment[];
    medias: ThisMessageMedia[];
  } | null;
};

type WorkflowCardProps = {
  action: ThisWorkflowStep;
  actionData: SendMessageActionData;
  availableAssignees: { key: string; label: string; type: AssignToPersonType }[];
  workflowContexts: Pick<ApiModels.WorkflowContext, 'id' | 'label' | 'slug'>[];
  workflowId: string;
  onEditClick: () => void;
  onUpdate: () => void;
  canEdit?: boolean;
};

const ActionCard: FC<WorkflowCardProps> = ({
  action,
  actionData,
  availableAssignees,
  onEditClick,
  onUpdate,
  workflowContexts,
  workflowId,
  canEdit = true,
}) => {
  const [currentAttachment, setCurrentAttachment] = useState<ThisMessageAttachment | null>(null);
  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const [hasBeenInteractedWith, setHasBeenInteractedWith] = useState(false);
  const [currentSelectedPeople, setCurrentlySelectedPeople] = useState<{ key: string; label: string }[]>([]);

  const { getData: updateWorkflowStepFn } = useNoodleApi(updateWorkflowStep, { toastOnError: true });

  const handleChangePeople = (newPeople: { key: string; label: string }[]): void => {
    setHasBeenInteractedWith(true);
    if (newPeople.length === 0) {
      setCurrentlySelectedPeople([]);
    } else {
      setCurrentlySelectedPeople(newPeople);
    }
  };

  const formatAssignedPeople = (assignedPersons: { key: string; label: string }[]): string => {
    let returnString = 'Assigned to: ';
    assignedPersons.forEach((person, index) => {
      returnString = returnString.concat(`${person.label}${index === assignedPersons.length - 1 ? '' : ', '}`);
    });
    return returnString;
  };

  const formatAssignToData = (selectedKeys: string[]): AssignToData[] => {
    const assignToData: AssignToData[] = [];
    selectedKeys.forEach(key => {
      const availableAssignee = availableAssignees.find(aa => key === aa.key);
      if (availableAssignee) {
        assignToData.push({
          personType: availableAssignee.type,
          referenceId: availableAssignee.key,
        });
      }
    });
    return assignToData;
  };

  const handleUpdateAutoAssignees = async (selectedKeys: string[]): Promise<void> => {
    const assignTo = formatAssignToData(selectedKeys);
    const updatedAttachments = actionData.message?.attachments.map(attachment => {
      if (attachment === currentAttachment) {
        return { ...attachment, assignTo };
      }
      return attachment;
    });
    const sendMessageData = {
      ...actionData.message,
      attachments: updatedAttachments,
    };
    await updateWorkflowStepFn({
      id: workflowId,
      stepId: action.id,
      updates: {
        data: { message: sendMessageData },
      },
    });
    setIsSelectOpen(false);
    setCurrentlySelectedPeople([]);
    onUpdate();
  };

  const handleUpdateConditions = async (expressionId: string): Promise<void> => {
    const updatedAttachments = actionData.message?.attachments.map(attachment => {
      if (attachment === currentAttachment) {
        return { ...attachment, expressionId };
      }
      return attachment;
    });
    const updatedAttachmentsWithoutFullExpression = updatedAttachments
      ? updatedAttachments.map(({ expression, ...rest }) => rest)
      : undefined;
    const sendMessageData = {
      ...actionData.message,
      attachments: updatedAttachmentsWithoutFullExpression,
    };
    await updateWorkflowStepFn({
      id: workflowId,
      stepId: action.id,
      updates: {
        data: { message: sendMessageData },
      },
    });
    setIsSelectOpen(false);
    onUpdate();
  };

  const handleSwitchAttachmentAssignSelector = (selectedAttachment: ThisMessageAttachment): void => {
    setCurrentAttachment(selectedAttachment);
    setHasBeenInteractedWith(false);
    if (currentAttachment !== selectedAttachment) {
      setIsSelectOpen(true);
      setCurrentlySelectedPeople([]);
    } else {
      setIsSelectOpen(!isSelectOpen);
      setCurrentlySelectedPeople([]);
    }
  };

  return (
    <div className={classes.actionContainer}>
      <div className={classes.header}>
        <FlagBanner weight="fill" size="16" />
        <p className="caption-bold">Action</p>
      </div>
      <div className={classes.action} style={canEdit ? undefined : { paddingBottom: 4 }}>
        {!actionData.message?.html && <div className={classes.currMessage}><p style={{ color: 'var(--color-gray-75)' }}>No message</p></div>}
        {actionData.message && actionData.message.html && (
          <div
            className={classes.currMessage}
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(actionData.message.html) }}
          />
        )}
        {canEdit && <EditButton label="Edit message" onClick={onEditClick} />}
      </div>
      {actionData.message && actionData.message.attachments?.length > 0 && (
        <ul className={classes.attachments}>
          {actionData.message.medias.map(media => (
            <li key={media.id} className={classes.attachment}>
              <File weight="fill" size={16} color="var(--color-primary)" />
              <div className={classes.content}>
                <div>{media.name}</div>
              </div>
            </li>
          ))}
          {actionData.message.attachments.map(attachment => {
            const currentlyAssignedIds = attachment.assignTo ? attachment.assignTo.map(a => a.referenceId) : [];
            const isCurrentAttachment = attachment === currentAttachment;
            const IconComponent = getAttachmentIcon(attachment.referenceType);
            const hasLink = attachment.referenceType === ApiModels.MESSAGE_ATTACHMENT_REFERENCE_TYPES.DOCUMENT_REQUEST_TEMPLATE
              || attachment.referenceType === ApiModels.MESSAGE_ATTACHMENT_REFERENCE_TYPES.FORM_REQUEST_TEMPLATE
              || attachment.referenceType === ApiModels.MESSAGE_ATTACHMENT_REFERENCE_TYPES.INVOICE_REQUEST
              || attachment.referenceType === ApiModels.MESSAGE_ATTACHMENT_REFERENCE_TYPES.CUSTOM_TERMS_TEMPLATE
              || attachment.referenceType === ApiModels.MESSAGE_ATTACHMENT_REFERENCE_TYPES.CUSTOM_TERMS;
            return (
              <li key={attachment.id} className={classes.attachment}>
                {IconComponent && <IconComponent weight="fill" size={16} color="var(--color-primary)" />}
                <div className={classes.content}>
                  {hasLink
                    ? (
                      <CustomLink
                        to={getUrl(
                          (attachment.referenceType === ApiModels.MESSAGE_ATTACHMENT_REFERENCE_TYPES.DOCUMENT_REQUEST_TEMPLATE
                          && IDENTIFIERS.DASHBOARD_DOCUMENT_REQUEST_TEMPLATES_EDIT)
                          || (attachment.referenceType === ApiModels.MESSAGE_ATTACHMENT_REFERENCE_TYPES.FORM_REQUEST_TEMPLATE
                            && IDENTIFIERS.DASHBOARD_FORM_REQUESTS_EDIT)
                          || (attachment.referenceType === ApiModels.MESSAGE_ATTACHMENT_REFERENCE_TYPES.INVOICE_REQUEST
                            && IDENTIFIERS.DASHBOARD_INVOICE_REQUESTS_EDIT)
                          || IDENTIFIERS.DASHBOARD_TERMS_TEMPLATES_EDIT,
                          { id: attachment.referenceId },
                        )}
                        newTab
                      >
                        {attachment.title}
                      </CustomLink>
                    )
                    : (
                      <div>{attachment.title}</div>
                    )}
                  <div className={classes.assignedPeople}>
                    {attachment.assignTo
                      && attachment.assignTo.length > 0
                      && formatAssignedPeople(
                        attachment.assignTo.map(assignedPerson => ({
                          key: assignedPerson.referenceId,
                          label: availableAssignees.find(aa => aa.key === assignedPerson.referenceId)?.label || '',
                        })),
                      )}
                  </div>
                </div>
                {availableAssignees.length > 0 && canEdit && (
                  <Button isWrapper className={classes.invite} onClick={() => handleSwitchAttachmentAssignSelector(attachment)}>
                    <UserCirclePlus size={20} weight="fill" />
                  </Button>
                )}
                <div className={classes.conditions}>
                  <ConditionsWidget
                    conditionalExpression={attachment.expression || null}
                    options={workflowContexts.map((context) => ({
                      key: context.slug || context.id,
                      label: context.label,
                    }))}
                    type={'workflow-context'}
                    onOpenModal={() => setCurrentAttachment(attachment)}
                    handleSubmit={handleUpdateConditions}
                  />
                </div>
                {isSelectOpen && isCurrentAttachment && (
                  <div className={classes.selector}>
                    <AssignSelector
                      onChange={handleChangePeople}
                      handleSave={handleUpdateAutoAssignees}
                      items={availableAssignees.map(person => ({
                        ...person,
                        isChecked: hasBeenInteractedWith
                          ? currentSelectedPeople.some(item => item.key === person.key)
                          : currentlyAssignedIds.includes(person.key),
                      }))}
                    />
                  </div>
                )}
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

export default ActionCard;
