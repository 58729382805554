import React, { FC } from 'react';
import MediaLoader from '@components/MediaLoader';
import VideoCamera from '@components/Icons/VideoCamera';
import { Job } from '@providers/Jobs';
import { Media } from '@typings/graphql-models';
import s from './InputComposer.module.scss';

type Props = {
  uploadJobs: Job[];
  medias: Pick<Media, 'id'>[];
};

const LoomAttachments: FC<Props> = ({ uploadJobs, medias }) => (
  <>
    {uploadJobs.map((j) => (
      <div
        key={j.id}
        className={s.loomAttachment}
      >
        <div className={s['media-attachments__item']}>
          <MediaLoader isSmall className={s['media-attachments__shimmer']} />
        </div>
        <span className='caption' style={{ marginLeft: 16 }}>Loom Video</span>
      </div>
    ))}
    {medias.map((media) => (
      <div
        key={media.id}
        className={s.loomAttachment}
      >
        <VideoCamera size={16} weight='fill' color='var(--color-primary)' />
        <span className='caption' style={{ marginLeft: 16 }}>Loom Video</span>
      </div>
    ))}
  </>
);

export default LoomAttachments;
