import Buttons from '@components/Buttons';
import GoogleCalendar from '@components/Icons/GoogleCalendar';
import ApiError from '@helpers/ApiError';
import useGoogleCalendarConnect from './useGoogleCalendarConnect';
import s from './Connect.module.scss';

type Props = {
  creatorId: string;
  teamMemberPersonId: string;
  onConnect: () => void;
  onError: (err: ApiError) => void;
};

const ConnectGoogleButton: React.FC<Props> = ({ creatorId, teamMemberPersonId, onConnect, onError }) => {
  const handleLogin = useGoogleCalendarConnect({ creatorId, onConnect, onError, teamMemberPersonId });
  return (
    <Buttons
      isThird
      isFullWidth
      iconBefore={<GoogleCalendar />}
      onClick={handleLogin}
      className={s.connectButton}
    >
      Connect Google Calendar
      <small>Gmail, G Suite</small>
    </Buttons>
  );
};

export default ConnectGoogleButton;
