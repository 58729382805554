import useNoodleApi from '@hooks/useNoodleApi';
import Spacer from '@components/Spacer';
import ProgressIndicator from '@components/ProgressIndicator';
import { getCreatorAiFollowupHistory } from '@tsClient/creators';
import { useCallback, useEffect, useRef, useState } from 'react';
import NoodleAI from '@components/Icons/NoodleAI';
import PaginationControls from '@components/DesignLibrary/PaginationControls';
import Header from '@components/DesignLibrary/Header';
import { Icon } from '@phosphor-icons/react';
import { AiFollowUpItem, FollowupHistoryType } from '@typings/api-models';
import { nanoid } from 'nanoid';
import * as tsClient from '@tsClient';
import PageContext from '@components/DesignLibrary/PageContext';
import userWorkflowToContext from '@helpers/userWorkflowToContext';
import * as format from '@format';
import { m } from 'framer-motion';
import Filters from '@/components/DesignLibrary/Filters';
import { TBody, THead, Table, TableRow, TH, TD } from '@/components/DesignLibrary/Table';
import DOMPurify from 'isomorphic-dompurify';
import CustomLink from '@/components/CustomLink';
import { IDENTIFIERS, getUrl } from '@/helpers/urlsHelper';
import UserImage from '@/components/UserImage';
import s from './UserWorkflowFollowupHistoryPage.module.scss';

const PAGE_SIZE = 16;

type Props = {
  userWorkflowId: string;
  creatorId: string;
  creatorNoodlePhoneNumber?: string | null;
};

const UserWorkflowFollowupHistoryPage: React.FC<Props> = ({ creatorId, userWorkflowId, creatorNoodlePhoneNumber }) => {
  const [followups, setFollowups] = useState<Array<AiFollowUpItem>>([]);
  const [isFetchingPage, setIsFetchingPage] = useState(false);
  const mostRecentRequestRef = useRef<string | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [numPages, setNumPages] = useState(0);
  const [numItems, setNumItems] = useState(0);
  const [searchMethods, setSearchMethods] = useState<string[]>([]);
  const { getData: getFollowupHistoryFn } = useNoodleApi(getCreatorAiFollowupHistory);

  const loadFollowups = useCallback(
    async ({ page, filter }: { page: number; filter?: string | null }) => {
      setIsFetchingPage(true);
      const thisRequestId = nanoid();
      mostRecentRequestRef.current = thisRequestId;
      setCurrentPage(page);
      const response = await getFollowupHistoryFn({
        creatorId,
        filter: filter ?? undefined,
        page,
        perPage: PAGE_SIZE,
        userWorkflowId,
      });
      if (response && response.data && thisRequestId === mostRecentRequestRef.current) {
        setIsFetchingPage(false);
        setFollowups(response.data.items);
        setNumPages(response.data.numPages);
        setNumItems(response.data.numItems);
      }
    },
    [creatorId, getFollowupHistoryFn, userWorkflowId],
  );

  const handlePageChange = (newPage: number): void => {
    loadFollowups({ page: newPage });
  };

  useEffect(() => {
    loadFollowups({
      page: 1,
    });
  }, [loadFollowups]);

  useEffect(() => {
    const [filter] = searchMethods.length === 1 ? searchMethods : [];
    loadFollowups({
      filter,
      page: 1,
    });
  }, [loadFollowups, searchMethods]);

  const { data: userWorkflow, getData: getUserWorkflowFn } = useNoodleApi(tsClient.workflows.getUserWorkflowById, { toastOnError: true });

  useEffect(() => {
    getUserWorkflowFn({ userWorkflowId });
  }, [userWorkflowId, getUserWorkflowFn]);

  const hasItems = followups.length > 0;

  const getTypeText = (t: FollowupHistoryType): string => {
    if (t === FollowupHistoryType.EMAIL) {
      return 'Email';
    }
    return 'SMS';
  };

  const handleFilterChange = (selected: string[]): void => {
    setSearchMethods(selected);
  };

  return (
    <div className={s.followupHistory__wrapper}>
      <Header
        className={s.header}
        Icon={NoodleAI as Icon}
        title={'Your AI assistant is following up.'}
        description={isFetchingPage ? 'Loading...' : `${numItems} follow-up${numItems > 1 ? 's' : ''} total for this case`}
        aiBg
      />
      <PageContext isFetching={!userWorkflow} items={userWorkflowToContext(userWorkflow)} />
      {creatorNoodlePhoneNumber && (
        <Filters
          items={[
            {
              isMultiSelect: true,
              items: [
                {
                  isChecked: searchMethods.includes('email'),
                  key: 'email',
                  label: 'Email',
                },
                {
                  isChecked: searchMethods.includes('sms'),
                  key: 'sms',
                  label: `SMS (${creatorNoodlePhoneNumber})`,
                },
              ],
              label: 'Delivery methods',
              onChange: handleFilterChange,
            },
          ]}
        />
      )}
      {isFetchingPage
        ? (
          <>
            <Spacer size={48} />
            <ProgressIndicator isCentered />
          </>
        )
        : (
          <>
            {hasItems && (
              <Table className={s.table}>
                <THead>
                  <TableRow>
                    <TH label="AI followed up with" />
                    <TH label="Message" />
                    <TH label="Date" />
                    <TH label="Type" />
                    <TH label="Workflows" />
                  </TableRow>
                </THead>
                <TBody>
                  {followups.map(followup => (
                    <TableRow key={followup.id}>
                      <TD>
                        <CustomLink
                          to={getUrl(IDENTIFIERS.DASHBOARD_MEMBER, {
                            id: followup.person.id,
                          })}
                          className={s.person}
                        >
                          <UserImage size={16} {...followup.person} />
                          {followup.person.name}
                        </CustomLink>
                      </TD>
                      <TD>
                        <div className={s.message} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(followup.text || '') }} />
                      </TD>
                      <TD label={`${format.datetime.timeSince({ date: followup.date })} ago`} compact />
                      <TD label={getTypeText(followup.type)} compact />
                      <TD>
                        <CustomLink
                          className="caption"
                          to={getUrl(IDENTIFIERS.DASHBOARD_MEMBER, {
                            id: followup.person.id,
                            tab: 'workflows',
                          })}
                        >
                          {followup.person.numActiveWorkflows === 0
                            ? `View workflows`
                            : `${followup.person.numActiveWorkflows} workflow${followup.person.numActiveWorkflows > 1 ? 's' : ''}`}
                        </CustomLink>
                      </TD>
                    </TableRow>
                  ))}
                </TBody>
              </Table>
            )}
            {followups.length === 0 && (
              <div className={s.noHistory}>
                <p>No AI followup history yet.</p>
                {userWorkflow?.nextFollowupTime && (
                  <m.strong initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
                    Upcoming followup in {format.datetime.timeSince({ date: userWorkflow.nextFollowupTime })}.
                  </m.strong>
                )}
              </div>
            )}
            {hasItems && <PaginationControls onChangePage={handlePageChange} page={currentPage} numPages={numPages} className={s.pagination} />}
          </>
        )}
    </div>
  );
};

export default UserWorkflowFollowupHistoryPage;
