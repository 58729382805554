import { FC, useCallback, useContext, useEffect, useState } from 'react';
import useNoodleApi from '@hooks/useNoodleApi';
import * as tsClient from '@tsClient';
import * as format from '@format';
import ProgressIndicator from '@components/ProgressIndicator';
import AdditionalTermsInfo from '@components/AdditionalTermsInfo';
import getLabelFromFieldName from '@helpers/getLabelFromFieldName';
import DOMPurify from 'isomorphic-dompurify';
import Spacer from '@components/Spacer';
import Header from '@components/DesignLibrary/Header';
import { useRouter } from 'next/router';
import { getUrl, IDENTIFIERS } from '@helpers/urlsHelper';
import teamsContext from '@providers/Teams/TeamsContext';
import removeNullish from '@helpers/removeNullish';
import PageContext from '@components/DesignLibrary/PageContext';
import userWorkflowToContext from '@helpers/userWorkflowToContext';
import DashboardContext from '@layouts/DashboardLayout/DashboardContext';
import BannerStatus from '@components/DesignLibrary/BannerStatus';
import Signature from '@components/Icons/Signature';
import UserProfileContext from '@layouts/UserProfileLayout/UserProfileContext';
import { Meow_Script as meowScript } from 'next/font/google';
import getCreator from '@tsClient/getCreator';
import { CustomTermsDynamicVariableType } from '@typings/api-models';
import AgreeToTermsModal from '@modals/AgreeToTermsModal';
import s from './CustomTermsDetails.module.scss';

type Props = {
  customTermsId: string;
};

const handwriting = meowScript({
  subsets: ['latin'],
  weight: '400',
});

const CustomTermsDetails: FC<Props> = ({ customTermsId }) => {
  const { data: customTermsData, getData: getCustomTerms } = useNoodleApi(tsClient.customTerms.getCustomTerms, {
    toastOnError: true,
  });
  const { data: userWorkflow, getData: getUserWorkflowById } = useNoodleApi(tsClient.workflows.getUserWorkflowById, {
    toastOnError: true,
  });
  const { getData: generateCustomTerms } = useNoodleApi(tsClient.customTerms.createCustomTermsFromInstance);
  const { getData: resetCustomTerms } = useNoodleApi(tsClient.customTerms.resetCustomTerms);
  const { currentTeamOwner } = useContext(teamsContext);
  const { isInDashboard } = useContext(DashboardContext);
  const { isInUserProfile } = useContext(UserProfileContext);
  const router = useRouter();

  const [isRegenerating, setIsRegenerating] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [isAgreeingToCustomTerms, setIsAgreeingToCustomTerms] = useState(false);
  const [additionalFields, setAdditionalFields] = useState<Record<string, unknown> | null>(null);
  const [creatorName, setCreatorName] = useState('');
  const [isAgreeToTermsModalOpen, setIsAgreeToTermsModalOpen] = useState(false);

  const canEditTerms = (
    currentTeamOwner
    && customTermsData?.template?.creator?.id
    && customTermsData.template.creator.id === currentTeamOwner.id
  );

  const getData = useCallback(async (): Promise<void> => {
    setIsFetching(true);
    const { data } = await getCustomTerms({ id: customTermsId });
    if (!data?.customTerms?.termsText && data?.template?.customTermsDynamicVariables?.length === 0) {
      await generateCustomTerms({ data: {}, id: customTermsId });
      await getCustomTerms({ id: customTermsId });
    }
    setIsFetching(false);
  }, [getCustomTerms, generateCustomTerms, customTermsId]);

  const handleSubmitAdditionalFields = async (data: Record<string, unknown>): Promise<void> => {
    setAdditionalFields(data);
    setIsFetching(true);
    await generateCustomTerms({
      data,
      id: customTermsId,
    });
    await getCustomTerms({ id: customTermsId });
    setIsFetching(false);
  };

  const regenerate = async (): Promise<void> => {
    setIsRegenerating(true);
    setAdditionalFields(null);
    await resetCustomTerms({ id: customTermsId });
    await getCustomTerms({ id: customTermsId });
    setIsRegenerating(false);
  };

  const agreeToCustomTermsFn = async (): Promise<void> => {
    if (customTermsData?.customTerms?.userWorkflowId) {
      setIsAgreeToTermsModalOpen(true);
    } else {
      await getCustomTerms({ id: customTermsId });
    }
    setIsAgreeingToCustomTerms(false);
  };

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    if (userWorkflow?.workflow.creator.slug) {
      const fetchCreatorData = async (): Promise<void> => {
        const data = await getCreator({ creatorSlug: userWorkflow?.workflow.creator.slug });
        setCreatorName(data.name || '');
      };
      fetchCreatorData();
    }
  }, [userWorkflow?.workflow.creator.slug, setCreatorName]);

  useEffect(() => {
    if (customTermsData?.customTerms?.userWorkflowId) {
      getUserWorkflowById({
        userWorkflowId: customTermsData.customTerms.userWorkflowId,
      });
    }
  }, [getUserWorkflowById, customTermsData?.customTerms?.userWorkflowId]);

  const readyToAgree = customTermsData?.customTerms?.termsText && !customTermsData.customTerms.agreedAt && !customTermsData.customTerms.skippedAt;

  const bannerStatus = (customTermsData?.customTerms?.agreedAt
    && { label: 'These custom terms have been signed.', status: 'success' as const })
    || (customTermsData?.customTerms?.skippedAt
      ? { label: 'These custom terms have been skipped.', status: 'warning' as const }
      : null);

  const customTermsDynamicVariables = customTermsData?.template.customTermsDynamicVariables
    .filter((cdv) => cdv.type === CustomTermsDynamicVariableType.Info)
    .map(variable => ({
      key: variable.name,
      label: getLabelFromFieldName(variable.name),
      type: variable.type,
    })) || [];

  const actions = [
    readyToAgree
      ? {
        disabled: isInDashboard,
        icon: Signature,
        isFetching: isAgreeingToCustomTerms,
        label: 'Agree and Sign',
        onClick: agreeToCustomTermsFn,
        tooltip: isInDashboard ? 'Only the assigned client is permitted to sign these terms.' : undefined,
      }
      : null,
    (readyToAgree && canEditTerms && customTermsDynamicVariables.length > 0)
      ? {
        isFetching: isRegenerating,
        label: 'Edit Details',
        onClick: regenerate,
      }
      : null,
    !isInDashboard && !isInUserProfile
      ? {
        href: getUrl(IDENTIFIERS.USER_PROFILE, {
          creatorSlug: customTermsData?.template?.creator?.slug || '',
          tab: customTermsData?.customTerms?.userWorkflowId ? 'activity' : 'chat',
          userWorkflowId: customTermsData?.customTerms?.userWorkflowId || undefined,
        }),
        label: 'Back to profile',
      }
      : null,
  ].filter(removeNullish);

  return (
    <>
      {isAgreeToTermsModalOpen && (
        <AgreeToTermsModal
          usesHandwrittenSignature={customTermsData?.template?.usesHandwrittenSignature ?? false}
          customTermsId={customTermsId}
          customerName={customTermsData?.customTerms.person.name ?? null}
          onClose={() => setIsAgreeToTermsModalOpen(false)}
          onSubmit={async () => {
            setIsAgreeToTermsModalOpen(false);
            setIsAgreeingToCustomTerms(true);
            if (customTermsData?.customTerms?.userWorkflowId) {
              await router.push(
                getUrl(IDENTIFIERS.USER_PROFILE, {
                  creatorSlug: customTermsData.template?.creator?.slug || '',
                  tab: 'activity',
                  userWorkflowId: customTermsData.customTerms.userWorkflowId,
                }),
              );
            }
          }}
        />
      )}
      {customTermsData && <Header title={customTermsData.template.title || 'Terms of Service'} actions={actions} />}
      <PageContext items={userWorkflowToContext(userWorkflow)} isFetching={!userWorkflow} />
      <div className={s.content}>
        {isFetching && <ProgressIndicator isCentered isPage />}
        {!isFetching && !customTermsData && <p className="body-sm">Forbidden</p>}

        {!customTermsData?.customTerms?.agreedAt && <Spacer size={16} />}
        {bannerStatus ? <BannerStatus label={bannerStatus.label} status={bannerStatus.status}></BannerStatus> : undefined}
        {!isFetching
          && customTermsData
          && !customTermsData.customTerms.termsText
          && customTermsDynamicVariables.length > 0
          && !additionalFields
          && !customTermsData.customTerms.skippedAt && (
          <div className={s.additionalTermsWrapper}>
            <AdditionalTermsInfo
              initialValues={customTermsData.contextVariables}
              additionalFields={customTermsDynamicVariables}
              onSubmit={handleSubmitAdditionalFields}
            />
          </div>
        )}
        {customTermsData?.customTerms?.termsText && !customTermsData.customTerms.skippedAt && (
          <>
            <div
              className={s.terms}
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(customTermsData.customTerms.termsText.replace(/<p\s*>\s*<\/p>/g, '<br>')) }}
            />
            <Spacer size="24px" />
          </>
        )}
        {userWorkflow && customTermsData?.customTerms.agreedAt && creatorName && (
          <div className={s.signatures}>
            <div>
              <p className={handwriting.className}>{userWorkflow.person.name}</p>
              <time className="body-sm">Signed on {format.datetime.withYear(customTermsData.customTerms.agreedAt)}</time>
            </div>
            <div>
              <p className={handwriting.className}>{creatorName}</p>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default CustomTermsDetails;
