import { createContext, PropsWithChildren, useState, useEffect, useMemo, useContext, SetStateAction, Dispatch } from 'react';
import { Questionnaire } from '@/typings/Questionnaire';

type FormEditorContextValues = {
  formData: Questionnaire.Questionnaire;
  setFormData: Dispatch<SetStateAction<Questionnaire.Questionnaire>>;
};

const FormEditorContext = createContext<FormEditorContextValues>(undefined as never);

type FormEditorProviderProps = {
  formData: Questionnaire.Questionnaire;
};

const FormEditorProvider: React.FC<PropsWithChildren<FormEditorProviderProps>> = ({
  formData: initFormData,
  children,
}) => {
  const [formData, setFormData] = useState<Questionnaire.Questionnaire>(initFormData);

  useEffect(() => setFormData(initFormData), [initFormData]);

  const values = useMemo(() => ({
    formData,
    setFormData,
  }), [formData]);

  return (
    <FormEditorContext.Provider value={values}>
      {children}
    </FormEditorContext.Provider>
  );
};

export const useFormEditorContext = (): FormEditorContextValues => useContext(FormEditorContext);
export default FormEditorProvider;
