import React from "react";
import { Reorder, useDragControls } from "framer-motion";
import classNames from "classnames";
import DotsSixVertical from '@/components/Icons/DotsSixVertical';
import s from './ExpandableListItem.module.scss';

type Props<T> = {
  item: T;
  isSelected: boolean;
  onClick: () => void;
};

const ExpandableListItem = <T extends { id: string; label: string; },>({ item, isSelected, onClick }: Props<T>): React.ReactElement => {
  const dragControls = useDragControls();

  return (
    <Reorder.Item
      as="div"
      value={item}
      id={item.id}
      className={classNames(s.listItem, isSelected && s.selected)}
      dragListener={false}
      dragControls={dragControls}
      onClick={onClick}
      whileDrag={{ boxShadow: 'var(--shadow-lg)' }}
    >
      <span style={!item.label ? { opacity: 0.5 } : undefined}>{item.label || 'No title'}</span>
      <DotsSixVertical className={s.reorderHandle} onPointerDown={(e) => dragControls.start(e)} />
    </Reorder.Item>
  );
};

export default ExpandableListItem;
