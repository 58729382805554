import AddDynamicVariableForm, { validVariableTextRegex } from '@components/AddDynamicVariableForm';
import Button from '@components/DesignLibrary/Button';
import Header from '@components/DesignLibrary/Header';
import PageContext from '@components/DesignLibrary/PageContext';
import Divider from '@components/Divider';
import InputField from '@components/FormFields/InputField';
import TextInput from '@components/FormFields/TextInput';
import PlusCircle from '@components/Icons/PlusCircle';
import Signature from '@components/Icons/Signature';
import TrashSimple from '@components/Icons/TrashSimple';
import { Textbox, TextColumns } from '@phosphor-icons/react';
import ProgressIndicator from '@components/ProgressIndicator';
import SlateEditor, { htmlTextToSlate, slateSerialize } from '@components/SlateEditor';
import Spacer from '@components/Spacer';
import Tooltip from '@components/Tooltip';
import createRichTextAst from '@helpers/createRichTextAst';
import serializeToPlainText from '@helpers/slate/serializeToPlainText';
import { IDENTIFIERS, getUrl } from '@helpers/urlsHelper';
import { useToast } from '@hooks';
import useNoodleApi from '@hooks/useNoodleApi';
import { ListAndDetailsSubLayoutContext } from '@layouts/ListAndDetailsSubLayout';
import TeamsContext from '@providers/Teams/TeamsContext';
import * as tsClient from '@tsClient';
import { deleteTypedDynamicVariable, findOrCreateTypedCustomTermsDynamicVariable, getCreatorDynamicVariables } from '@tsClient/creators';
import { CustomTermsDynamicVariableType, CustomTermsTemplate, TypedCustomTermsDynamicVariable } from '@typings/api-models';
import { Meow_Script as meowScript } from 'next/font/google';
import { useRouter } from 'next/router';
import { useContext, useEffect, useState } from 'react';
import { Descendant } from 'slate';
import Modal from '@/components/Modal';
import CheckBox from '@components/FormFields/CheckBox';
import SignatureInput from '@components/FormFields/SignatureInput';
import s from './CreateEditTermsTemplateForm.module.scss';

type Props = {
  termsTemplate?: Pick<
    CustomTermsTemplate,
    'id' | 'templateContent' | 'title' | 'companySignatory' | 'customTermsDynamicVariables' | 'products' | 'usesHandwrittenSignature' | 'signatorySignature'
  > | null;
  onSubmit: () => void;
};

const handwriting = meowScript({
  subsets: ['latin'],
  weight: '400',
});

const fullVariableRegex = /\{\{([a-zA-Z0-9_]+)\}\}/g;
const potentialVariableRegex = /\{\{(.+?)\}\}/g;

const CreateEditProductTermsForm: React.FC<Props> = ({ termsTemplate, onSubmit }) => {
  const addToast = useToast();
  const router = useRouter();
  const { reloadListView } = useContext(ListAndDetailsSubLayoutContext);
  const {
    data: workflows,
    getData: getWorkflowsFn,
    fetchingState: { isFetching: isFetchingWorkflows },
  } = useNoodleApi(tsClient.workflows.getWorkflowsFromBuildingBlock);
  const { data: invoiceVariables, getData: getInvoiceVariablesFn } = useNoodleApi(tsClient.creators.getInvoiceVariablesForCustomTerms);
  const { getData: getCreatorDynamicVariablesFn } = useNoodleApi(getCreatorDynamicVariables);
  const { getData: archiveCustomTermsTemplateFn } = useNoodleApi(tsClient.customTermsTemplates.archiveCustomTermsTemplate);
  const { getData: updateOrCreateTermsTemplateFn } = useNoodleApi(tsClient.customTermsTemplates.updateOrCreateCustomTermsTemplate, {
    toastErrorMessage: error => [
      useToast.ToastTypeVariants.ERROR,
      error?.errors?.[0] || 'There was an error updating your terms template. Please try again.',
    ],
    toastOnError: true,
    toastSuccessMessage: () => [useToast.ToastTypeVariants.SUCCESS, termsTemplate ? 'Template updated successfully!' : 'Terms created successfully!'],
  });
  const { getData: deleteTypedDynamicVariableFn } = useNoodleApi(deleteTypedDynamicVariable, {
    toastErrorMessage: error => [
      useToast.ToastTypeVariants.ERROR,
      error?.errors?.[0] || 'There was an error deleting your custom input. Please try again.',
    ],
    toastOnError: true,
    toastSuccessMessage: () => [useToast.ToastTypeVariants.SUCCESS, 'Custom input deleted successfully!'],
  });

  const defaultDynamicVariables = [
    'customerName',
    'contactEmail',
    'creatorName',
    'initialPaymentAmount',
    'todaysDate',
    'dateSigned',
    ...(termsTemplate?.products && termsTemplate.products.length > 0 ? ['price', 'priceTitle', 'sessionDuration', 'sessionTime'] : []),
    ...(termsTemplate?.companySignatory ? ['signatorySignature', 'signatoryName'] : []),
  ];
  const defaultInputs = defaultDynamicVariables.map(ddv => ({ label: `{{${ddv}}}` }));

  const [isArchiving, setIsArchiving] = useState(false);
  const [templateContent, setTemplateContent] = useState<{ children: Descendant[] }[]>([createRichTextAst('')]);
  const [addingVariable, setAddingVariable] = useState(false);
  const [creatorVariables, setCreatorVariables] = useState<TypedCustomTermsDynamicVariable[]>([]);
  const [deletingVariable, setDeletingVariable] = useState<string | null>(null);
  const [templateVariables, setTemplateVariables] = useState<string[]>([]);
  const [settingTemplateVariables, setIsSettingTemplateVariables] = useState(false);
  const [settingCreatorVariables, setIsSettingCreatorVariables] = useState(false);
  const [hasUpdatedCreatorVariables, setHasUpdatedCreatorVariables] = useState(false);
  const [isAddInfoVariableOpen, setIsAddInfoVariableOpen] = useState(false);
  const [isAddSignatureVariableOpen, setIsAddSignatureVariableOpen] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [hasVariableError, setHasVariableError] = useState(false);
  const [requiresSignature, setRequiresSignature] = useState(termsTemplate?.usesHandwrittenSignature ?? false);
  const [signature, setSignature] = useState(termsTemplate?.signatorySignature ?? '');

  const [title, setTitle] = useState(termsTemplate?.title || '');
  const [companySignatory, setCompanySignatory] = useState(termsTemplate?.companySignatory || '');
  const { creatorId } = useContext(TeamsContext);

  const fetchAndSetCreatorDynamicVariables = async (): Promise<void> => {
    if (creatorId) {
      const creatorDynamicVariableData = await getCreatorDynamicVariablesFn({ creatorId });
      if (creatorDynamicVariableData?.data) {
        setIsSettingCreatorVariables(true);
        setCreatorVariables(creatorDynamicVariableData.data);
        setIsSettingCreatorVariables(false);
      }
    }
  };

  const toggleRequiresSignature = (): void => setRequiresSignature(prev => !prev);

  const handleAddVariableToCreator = async (): Promise<void> => {
    setIsAddInfoVariableOpen(false);
    setIsAddSignatureVariableOpen(false);
    await fetchAndSetCreatorDynamicVariables();
  };

  const handleDisconnectVariableFromCreator = async (variableId: string): Promise<void> => {
    setDeletingVariable(variableId);
    if (creatorId) {
      await deleteTypedDynamicVariableFn({ creatorId, id: variableId });
    }
    await fetchAndSetCreatorDynamicVariables();
    setDeletingVariable(null);
  };

  const { getData: findOrCreateDynamicVariableFn } = useNoodleApi(findOrCreateTypedCustomTermsDynamicVariable, {
    toastErrorMessage: error => [
      useToast.ToastTypeVariants.ERROR,
      error?.errors?.[0] || 'There was an error creating your custom input. Please try again.',
    ],
    toastOnError: true,
    toastSuccessMessage: () => [useToast.ToastTypeVariants.SUCCESS, 'Custom input created successfully!'],
  });

  const handleAddInput = async (variableName: string, connectTemplateId?: string): Promise<void> => {
    if (creatorId) {
      await findOrCreateDynamicVariableFn({
        connectTemplateId,
        creatorId,
        type: CustomTermsDynamicVariableType.Info,
        variableName,
      });
      handleAddVariableToCreator();
    }
  };

  async function copyToClipboard(text: string): Promise<void> {
    try {
      await navigator.clipboard.writeText(text);
      addToast(useToast.ToastTypeVariants.SUCCESS, `${text} copied to clipboard!`);
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  }

  const setTemplate = (): void => {
    setIsSettingTemplateVariables(true);
    const asSlate = htmlTextToSlate(termsTemplate?.templateContent);
    setTemplateContent(asSlate);
    setTemplateVariables(termsTemplate?.customTermsDynamicVariables.map(variable => variable.name) || []);
    setIsSettingTemplateVariables(false);
    setIsInitialized(true);
  };

  const getDynamicVariableNamesFromSlate = (slateNodes: { children: Descendant[] }[]): string[] => {
    const slateAsPlainText = serializeToPlainText(slateNodes);

    const matches = slateAsPlainText.match(fullVariableRegex) || [];
    const dynamicVariables = matches.map(match => match.slice(2, -2));

    const nonDefaultDynamicVariables = dynamicVariables.filter(dv => !defaultDynamicVariables.includes(dv));
    return nonDefaultDynamicVariables;
  };

  const checkDynamicVariableValidity = (slateNodes: { children: Descendant[] }[]): boolean => {
    let areVariablesValid = true;
    const slateAsPlainText = serializeToPlainText(slateNodes);

    const matches = slateAsPlainText.match(potentialVariableRegex) || [];
    const potentialVariables = matches.map(match => match.slice(2, -2));

    potentialVariables.forEach(variable => {
      const isValid = validVariableTextRegex.test(variable);
      if (!isValid) {
        areVariablesValid = false;
      }
    });

    return areVariablesValid;
  };

  useEffect(() => {
    if (termsTemplate) {
      getWorkflowsFn({ page: 1, perPage: 1000, referenceId: termsTemplate.id });
    }
  }, [getWorkflowsFn, termsTemplate]);

  useEffect(() => {
    fetchAndSetCreatorDynamicVariables();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creatorId]);

  useEffect(() => {
    if (creatorId) {
      getInvoiceVariablesFn({ creatorId });
    }
  }, [creatorId, getInvoiceVariablesFn]);

  useEffect(() => {
    if (termsTemplate) {
      setTemplate();
    } else {
      setIsInitialized(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (creatorVariables.length > 0 && !hasUpdatedCreatorVariables) {
      const oldCreatorVariablesSet = new Set(creatorVariables.map(cV => cV.name));
      const dynamicVariables = getDynamicVariableNamesFromSlate(templateContent);
      const newTemplateVariables = dynamicVariables.filter(dv => !oldCreatorVariablesSet.has(dv));

      newTemplateVariables.forEach(async variable => {
        setAddingVariable(true);
        await handleAddInput(variable, termsTemplate?.id);
        setAddingVariable(false);
      });
      setTemplateVariables(dynamicVariables);
      setHasUpdatedCreatorVariables(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creatorVariables, hasUpdatedCreatorVariables]);

  const handleSave = async (): Promise<void> => {
    setIsSaving(true);

    const isTemplateValid = checkDynamicVariableValidity(templateContent);
    if (!isTemplateValid) {
      setHasVariableError(true);
      setIsSaving(false);
      return;
    }
    setHasVariableError(false);

    const dynamicVariables = getDynamicVariableNamesFromSlate(templateContent);
    if (creatorId) {
      await updateOrCreateTermsTemplateFn({
        creatorId,
        dynamicVariables,
        productId: null,
        templateData: {
          companySignatory,
          signatorySignature: signature,
          templateContent: templateContent.map(e => slateSerialize(e)).join(''),
          title,
          usesHandwrittenSignature: requiresSignature,
        },
        templateId: termsTemplate?.id || null,
      });
    }
    reloadListView();
    onSubmit();
    setHasUpdatedCreatorVariables(false);
    fetchAndSetCreatorDynamicVariables();
    setIsSaving(false);
  };

  const archiveCustomTermsTemplate = async (): Promise<void> => {
    if (termsTemplate) {
      setIsArchiving(true);
      await archiveCustomTermsTemplateFn({ id: termsTemplate.id });
      await reloadListView();
      onSubmit();
      await router.push(getUrl(IDENTIFIERS.DASHBOARD_TERMS_TEMPLATES));
      setIsArchiving(false);
    }
  };

  if (!isInitialized || settingCreatorVariables || settingTemplateVariables) {
    return <ProgressIndicator isCentered />;
  }

  return (
    <div className={s.terms__wrapper}>
      {termsTemplate
        ? (
          <>
            <Header
              secondaryActions={[
                {
                  icon: TrashSimple,
                  isDestructive: true,
                  isFetching: isArchiving,
                  label: 'Archive',
                  onClick: archiveCustomTermsTemplate,
                },
              ]}
              title={title || 'Untitled'}
              noDivider
            />
            <div className={s.context}>
              <PageContext
                collapsible
                label={
                  isFetchingWorkflows
                    ? 'workflows'
                    : `Used in ${workflows?.items.length} workflow${workflows?.items && workflows?.items.length === 1 ? '' : 's'}`
                }
                items={
                  workflows?.items?.map?.(workflow => ({
                    href: `${router.asPath.split('?')[0]}?paneWorkflowSlug=${workflow.slug}${
                      router.asPath.split('?')[1] ? `&${router.asPath.split('?')[1]}` : ''
                    }`,
                    id: workflow.id,
                    value: workflow.name,
                  })) || []
                }
                isFetching={isFetchingWorkflows}
              />
            </div>
          </>
        )
        : (
          <Spacer size={8} />
        )}
      {isArchiving && (
        <>
          <Spacer size={16} />
          <ProgressIndicator isCentered />
        </>
      )}
      <div className={s.terms__padding}>
        <InputField id="title" name="title" values={{ title }} onChange={setTitle} label="Title of custom terms" hasFixedHeight />
      </div>
      <Header title="Company Signatory" hierarchy="h3" Icon={Signature} />
      <div className={s.terms__padding}>
        <CheckBox hasFixedHeight={false} isSwitch id='requiresSignature' label='Require handwritten signature' name='requiresSignature' values={{ requiresSignature }} onChange={toggleRequiresSignature} />
        <Spacer size={16} />
        <TextInput id="signatoryName" value={companySignatory} onChange={setCompanySignatory} label="Signatory Name" hasFixedHeight />
        {requiresSignature
          ? (
            <SignatureInput
              value={signature}
              label='Handwritten signature'
              onChange={newSignature => setSignature(newSignature)}
            />
          )
          : (
            <TextInput
              id="signatorySignature"
              value={companySignatory}
              onChange={setCompanySignatory}
              label="Signatory Signature"
              hasFixedHeight
              disabled
              style={{
                fontFamily: handwriting.style.fontFamily,
                fontSize: '24px',
                fontWeight: 800,
              }}
            />
          )}
      </div>
      <Header title="Inputs" hierarchy="h3" Icon={Textbox} />
      <div className={s.terms__padding}>
        <div className={s.terms__variables}>
          <p className="body-sm-bold">Available Inputs</p>
          <Spacer size={6} />
          <p className="caption">
            Use these in your custom terms and conditions to call dynamic variables that generate unique versions for each individual customer based
            on their information. Any custom inputs you create and add to these terms will be collected from your customer during checkout.
          </p>
          <Spacer size={12} />
          {defaultInputs.length > 0 && (
            <ul>
              {defaultInputs.map(input => (
                <li key={input.label}>
                  <button onClick={() => copyToClipboard(input.label)}>{input.label}</button>
                </li>
              ))}
            </ul>
          )}
          {(invoiceVariables?.length || 0) > 0 && (
            <>
              <Spacer size={16} />
              <Divider />
              <Spacer size={16} />
              <p className="body-sm-bold">Invoice Inputs</p>
              <Spacer size={6} />
              <p className="caption">
                These variables are filled in from invoice line items.
              </p>
              <Spacer size={12} />
              <ul>
                {invoiceVariables
                  ?.map(input => (
                    <li key={input}>
                      <button onClick={() => copyToClipboard(`{{invoice:${input}}}`)}>{`{{invoice:${input}}}`}</button>
                    </li>
                  ))}
              </ul>
            </>
          )}
          {creatorVariables.filter(cv => cv.type === CustomTermsDynamicVariableType.Info).length > 0 && (
            <>
              <Spacer size={16} />
              <Divider />
              <Spacer size={16} />
              <p className="body-sm-bold">Custom Inputs</p>
              <Spacer size={6} />
              {defaultInputs.length > 0 && (
                <>
                  <p className="caption">
                    During checkout, your customer will be required to fill in the following information based on your template.
                  </p>
                  <Spacer size={12} />
                  <ul>
                    {creatorVariables
                      .filter(cv => cv.type === CustomTermsDynamicVariableType.Info)
                      .map(input => (
                        <li key={input.id}>
                          <button onClick={() => copyToClipboard(`{{${input.name}}}`)}>{`{{${input.name}}}`}</button>
                          {templateVariables.includes(input.name)
                            ? (
                              <Tooltip text="Please remove it from the template first" className={s.tooltip}>
                                <TrashSimple weight="fill" size={16} style={{ opacity: 0.5 }} />
                              </Tooltip>
                            )
                            : (
                              <button onClick={() => {
                                if (window.confirm('Are you sure you want to delete this input?')) {
                                  handleDisconnectVariableFromCreator(input.id);
                                }
                              }}>
                                {deletingVariable === input.id
                                  ? (
                                    <ProgressIndicator size={16} />
                                  )
                                  : (
                                    <TrashSimple weight="fill" size={16} color="var(--color-error)" />
                                  )}
                              </button>
                            )}
                        </li>
                      ))}
                  </ul>
                </>
              )}
            </>
          )}
          <Spacer size={12} />
          {addingVariable
            ? (
              <ProgressIndicator />
            )
            : (
              <Button size="sm" variant="secondary" onClick={() => setIsAddInfoVariableOpen(true)} className={s.terms__variables__add}>
                <PlusCircle size={16} weight="fill" />
              Add new input
              </Button>
            )}
          {isAddInfoVariableOpen && creatorId && (
            <Modal onClose={() => setIsAddInfoVariableOpen(false)} hasPadding title="Add new input">
              <AddDynamicVariableForm creatorId={creatorId} onSubmit={handleAddVariableToCreator} type={CustomTermsDynamicVariableType.Info} />
            </Modal>
          )}
          {creatorVariables.filter(cv => cv.type === CustomTermsDynamicVariableType.Signature).length > 0 && (
            <>
              <Spacer size={16} />
              <Divider />
              <Spacer size={16} />
              <p className="body-sm-bold">Custom Signatures</p>
              <Spacer size={6} />
              {defaultInputs.length > 0 && (
                <>
                  <p className="caption">
                    These variables are used to specify where your customers will be required to leave their electronic signature when agreeing to
                    terms
                  </p>
                  <Spacer size={12} />
                  <ul>
                    {creatorVariables
                      .filter(cv => cv.type === CustomTermsDynamicVariableType.Signature)
                      .map(input => (
                        <li key={input.id}>
                          <button onClick={() => copyToClipboard(`{{${input.name}}}`)}>{`{{${input.name}}}`}</button>
                          {templateVariables.includes(input.name)
                            ? (
                              <Tooltip text="Please remove it from the template first">
                                <TrashSimple weight="fill" size={16} style={{ opacity: 0.5 }} />
                              </Tooltip>
                            )
                            : (
                              <button onClick={() => {
                                if (window.confirm('Are you sure you want to delete this input?')) {
                                  handleDisconnectVariableFromCreator(input.id);
                                }
                              }}>
                                {deletingVariable === input.id
                                  ? (
                                    <ProgressIndicator size={16} />
                                  )
                                  : (
                                    <TrashSimple weight="fill" size={16} color="var(--color-error)" />
                                  )}
                              </button>
                            )}
                        </li>
                      ))}
                  </ul>
                </>
              )}
            </>
          )}
          <Spacer size={12} />
          {addingVariable
            ? (
              <ProgressIndicator />
            )
            : (
              <Button
                size="sm"
                variant="secondary"
                onClick={() => setIsAddSignatureVariableOpen(true)}
                className={s.terms__variables__add}
              >
                <PlusCircle size={16} weight="fill" />
              Add new signature input
              </Button>
            )}
          {isAddSignatureVariableOpen && creatorId && (
            <Modal onClose={() => setIsAddSignatureVariableOpen(false)} hasPadding title="Add new signature input">
              <AddDynamicVariableForm creatorId={creatorId} onSubmit={handleAddVariableToCreator} type={CustomTermsDynamicVariableType.Signature} />
            </Modal>
          )}
        </div>
      </div>
      <Header title="Terms" hierarchy="h3" Icon={TextColumns} />
      <div className={s.terms__padding}>
        <SlateEditor
          placeholder={'Create custom terms template'}
          id="templateContent"
          name="templateContent"
          slateValue={templateContent}
          onChange={(newValue: { children: Descendant[] }[]) => {
            setTemplateContent(newValue);
          }}
          containerClassName={s.slateEditor}
          isMediaButton={false}
        />
        <Spacer size={16} />
        <Button variant="primary" size="md" onClick={handleSave} loading={isSaving}>
          Save
        </Button>
        {hasVariableError && (
          <>
            <Spacer size={12} />
            <div className={s.errorMessage}>
              Unable to save template. Please make sure all custom inputs contain only alphanumeric characters and underscores.
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default CreateEditProductTermsForm;
