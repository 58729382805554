type Input = {
  name: string;
  prefix?: string;
};

const slugify = ({ name, prefix }: Input): string => {
  const baseSlug = name
    .trim()
    .replace(/\s+/g, '-')
    .toLowerCase()
    .replace(/[^a-z0-9-]/g, '')
    .replace(/--+/g, '-');
  const finalText = prefix ? `${prefix}-${baseSlug}` : baseSlug;

  // Remove leading '-' and trailing '-' from the finalText
  const cleanedText = finalText.replace(/^-+|-+$/g, '');

  return cleanedText;
};

const generateUniqSlug = ({ existingSlugs, ...input }: Input & { existingSlugs: string[] }): string => {
  let slug = slugify(input);
  const originSlug = slug;
  let increment = 2;

  while(existingSlugs.includes(slug)) {
    slug = `${originSlug}-${increment}`;
    increment += 1;
  }

  return slug;
};

slugify.generateUniqSlug = generateUniqSlug;

export default slugify;
