import React, { useState, useEffect } from 'react';

import { Select, Input, Switch } from '@/components/DesignLibrary/Atoms';
import { Option } from '@/components/DesignLibrary/Atoms/Select';
import { Questionnaire } from '@/typings/Questionnaire';
import type { ValueOf } from '@/typings/common';
import { ThisExpression } from '@/helpers/expressions/isSimpleExpression';
import * as expressionHelpers from '@helpers/expressions';
import Comparison = Questionnaire.Comparison;
import s from './ConditionsForm.module.scss';
import Spacer from '../Spacer';

const ConditionsLabel = {
  [Questionnaire.Comparison.Equal]: 'Equal',
  [Questionnaire.Comparison.NotEqual]: 'Not equal',
  [Questionnaire.Comparison.Contain]: 'Contain',
  [Questionnaire.Comparison.NotContain]: 'Not contain',
  [Questionnaire.Comparison.GreaterThan]: 'Greater than',
  [Questionnaire.Comparison.LessThan]: 'Less than',
  [Questionnaire.Comparison.Filled]: 'Filled',
  [Questionnaire.Comparison.NotFilled]: 'Not filled',
};

const ConditionOptions = Object.keys(ConditionsLabel).reduce(
  (p: Record<string, Option>, c: string) => ({
    ...p,
    [c]: {
      key: c as string,
      label: ConditionsLabel[c as ValueOf<typeof Questionnaire.Comparison>],
    },
  }), {});

type ConditionsProps = {
  conditionExpression?: ThisExpression;
  options: { key: string; label: string; }[];
  setConditionExpression: React.Dispatch<React.SetStateAction<ThisExpression>>;
  type: 'workflow-context';
};

const ConditionsForm: React.FC<ConditionsProps> = ({ conditionExpression, options, type, setConditionExpression }) => {
  const [inputComplexExpression, setInputComplexExpression] = useState(
    conditionExpression ? !expressionHelpers.isSimpleExpression(conditionExpression) : false,
  );

  useEffect(() => {
    if (conditionExpression && !expressionHelpers.isSimpleExpression(conditionExpression) && !inputComplexExpression) {
      setConditionExpression({
        expression: '',
        variables: [],
      });
    }
  }, [conditionExpression, inputComplexExpression, setConditionExpression]);

  const setConditionExpressionInput = (fieldKey: string): void => {
    const [_, operator, value] = (conditionExpression?.expression || '').split(' ');
    let newExpression = '';
    if (conditionExpression?.expression?.includes('!!')) {
      newExpression = type === 'workflow-context' ? `!!context["${fieldKey}"]` : `!!${fieldKey}`;
    } else {
      newExpression = type === 'workflow-context'
        ? `context["${fieldKey || ''}"] ${operator || ''} ${value || ''}`
        : `${fieldKey || ''} ${operator || ''} ${value || ''}`;
    }
    setConditionExpression({
      expression: newExpression,
      variables: [{
        referenceId: type === 'workflow-context' ? 'self' : fieldKey,
        selector: type === 'workflow-context' ? '' : fieldKey,
        variableName: type === 'workflow-context' ? 'context' : fieldKey,
      }],
    });
  };

  const getOperatorString = (operator: Comparison): string => {
    if (operator === Comparison.Equal) return '===';
    if (operator === Comparison.NotEqual) return '!==';
    if (operator === Comparison.LessThan) return '<';
    if (operator === Comparison.GreaterThan) return '>';
    // Default to equal
    return '===';
  };

  const setConditionExpressionOperator = (operator: Comparison): void => {
    const [fieldFromExpression, _, value] = (conditionExpression?.expression || '').split(' ');
    let newExpression = '';
    let field = fieldFromExpression;
    if (field.includes('!!')) {
      field = field.substring(2);
    }
    if (field.includes('!')) {
      field = field.substring(1);
    }
    if (operator === Comparison.Filled) {
      newExpression = `!!${field}`;
    } else if (operator === Comparison.NotFilled) {
      newExpression = `!${field}`;
    } else {
      newExpression = `${field} ${getOperatorString(operator)} ${value || ''}`;
    }
    setConditionExpression({
      expression: newExpression,
      variables: [{
        referenceId: field,
        selector: field,
        variableName: field,
      }],
    });
  };

  const setConditionExpressionValue = (value: string): void => {
    const [field, operator] = (conditionExpression?.expression || '').split(' ');
    const newExpression = `${field || ''} ${operator || ''} "${value || ''}"`;
    setConditionExpression({
      expression: newExpression,
      variables: [{
        referenceId: type === 'workflow-context' ? 'self' : field,
        selector: type === 'workflow-context' ? '' : field,
        variableName: type === 'workflow-context' ? 'context' : field,
      }],
    });
  };

  const getAvailableConditions = (): Option[] => [
    ConditionOptions[Comparison.Equal],
    ConditionOptions[Comparison.NotEqual],
    ConditionOptions[Comparison.Contain],
  ];

  // todo - make more robust
  const getConditionExpressionCondition = (): Comparison | undefined => {
    if (conditionExpression) {
      if (conditionExpression.expression.includes('===')) {
        return Comparison.Equal;
      }
      if (conditionExpression.expression.includes('!==')) {
        return Comparison.NotEqual;
      }
      if (conditionExpression.expression.includes(' > ')) {
        return Comparison.GreaterThan;
      }
      if (conditionExpression.expression.includes(' < ')) {
        return Comparison.LessThan;
      }
    }
    return undefined;
  };

  const getConditionExpressionInput = (): string | undefined => {
    if (type === 'workflow-context') {
      const [field] = (conditionExpression?.expression || '').split(' ');
      const inputSlug = field.match(/["']([^"']+)["']/)?.[1];
      return inputSlug;
    }
    return conditionExpression?.variables?.[0]?.referenceId;
  };

  const getConditionExpressionValue = (): string | undefined => {
    if (conditionExpression) {
      if (conditionExpression.expression.includes('===')) {
        return conditionExpression.expression.substring(conditionExpression.expression.indexOf('===') + 4);
      }
      if (conditionExpression.expression.includes('!==')) {
        return conditionExpression.expression.substring(conditionExpression.expression.indexOf('!==') + 4);
      }
      if (conditionExpression.expression.includes(' > ')) {
        return conditionExpression.expression.substring(conditionExpression.expression.indexOf('>') + 2);
      }
      if (conditionExpression.expression.includes(' < ')) {
        return conditionExpression.expression.substring(conditionExpression.expression.indexOf('<') + 2);
      }
    }
    return undefined;
  };

  const createVariables = (expression: string): ThisExpression['variables'] => {
    const variablesInExpression: string[] = [];
    Object.values(options).forEach((option) => {
      if (expression.includes(option.key)) {
        variablesInExpression.push(option.key);
      }
    });
    return variablesInExpression.map(variable => ({
      referenceId: type === 'workflow-context' ? 'self' : variable,
      selector: type === 'workflow-context' ? '' : variable,
      variableName: type === 'workflow-context' ? 'context' : variable,
    }));
  };

  return (
    <>
      <Switch
        label="Input complex expression"
        name="input-complex-expression"
        isChecked={inputComplexExpression}
        onChange={setInputComplexExpression}
      />
      <Spacer size={12}/>
      {inputComplexExpression
        ? (
          <>
            <span className={'body-sm-bold'}>{'Availiable Values (Use IDs to build expression)'}</span>
            {options.map(option => (
              <div className={'caption'} key={option.key}>
                <strong>{option.label}:</strong> {option.key}
              </div>
            ))}
            <Spacer size={12}/>
            <Input label='' description='Expression' value={conditionExpression?.expression || ''} onChange={value => setConditionExpression({
              expression: value,
              variables: createVariables(value),
            })} />
            {(conditionExpression?.variables.length || 0) > 0 && (
              <>
                <Spacer size={12}/>
                <div className={s.variables}>
                  <div className={s.variables__header}>
                    <p className="body-md-bold">Expression Variables</p>
                  </div>
                  <div>
                    <Spacer size="8px" />
                    <table>
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Selector</th>
                          <th>Reference ID</th>
                        </tr>
                      </thead>
                      <tbody>
                        {conditionExpression?.variables.map(variable => (
                          <tr key={variable.referenceId}>
                            <td>{variable.variableName}</td>
                            <td>{variable.selector || 'N/A'}</td>
                            <td>{variable.referenceId}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </>
            )}
          </>
        )
        : (
          <>
            <Select
              label=""
              description="Input"
              options={options}
              value={getConditionExpressionInput()}
              onChange={setConditionExpressionInput}
            />
            <Spacer size={8}/>
            <Select
              label=""
              description="Condition"
              options={getAvailableConditions()}
              value={getConditionExpressionCondition()}
              onChange={(val) => setConditionExpressionOperator(val as Comparison)}
            />
            <Spacer size={8}/>
            <Input
              label=""
              description="Value"
              value={getConditionExpressionValue()?.replace(/"/g, '')}
              onChange={setConditionExpressionValue}
            />
          </>
        )}
    </>
  );
};

export default ConditionsForm;