import { FC } from 'react';
import Modal from '@components/Modal';
import CreateEditTermsTemplateForm from '@lib/CreateEditTermsTemplateForm';

type Props = {
  termsTemplate: Parameters<typeof CreateEditTermsTemplateForm>[0]['termsTemplate'];
  onClose: () => void;
  onSubmit: () => void
};

const CreateEditTermsTemplateModal: FC<Props> = ({ onClose, termsTemplate, onSubmit }) => {
  const title = termsTemplate ? 'Edit Terms Template' : 'Create Terms Template';
  return (
    <Modal title={title} onClose={onClose} >
      <CreateEditTermsTemplateForm termsTemplate={termsTemplate} onSubmit={onSubmit}/>
    </Modal>
  );
};

export default CreateEditTermsTemplateModal;
