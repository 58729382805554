const reorderItems = <P extends { id: string; rank: number } >({
  items,
  movedItemId,
  newRank: newRankOriginal,
  min = 1,
}: {
  items: P[];
  movedItemId: string;
  newRank: number;
  min?: number;
}): P[] => {
  const movedItem = items.find(d => d.id === movedItemId);
  if (movedItem) {
    const reorderedItems: P[] = [];
    let newRank = newRankOriginal;
    if (newRank < min) {
      newRank = min;
    }
    if (newRank > items.length) {
      newRank = items.length;
    }
    let nextRank = min;
    for (let i = 0; i < items.length; i += 1) {
      const thisItem = items[i];
      if (movedItem.id !== thisItem.id) {
        if (newRank === nextRank) {
          reorderedItems.push({
            ...movedItem,
            rank: nextRank,
          });
          nextRank += 1;
        }

        reorderedItems.push({
          ...thisItem,
          rank: nextRank,
        });
        nextRank += 1;
      }
    }

    if (newRank === nextRank) {
      reorderedItems.push({
        ...movedItem,
        rank: nextRank,
      });
      nextRank += 1;
    }

    return reorderedItems;
  }
  return items;
};

export default reorderItems;