import { useEffect, useRef } from 'react';
import classNames from 'classnames';
import { m } from 'framer-motion';
import XCircle from '@components/Icons/XCircle';
import { useIsMobile } from '@/hooks';
import Spacer from '@/components/Spacer';
import s from './PaneWrapper.module.scss';

type Props = {
  hasSideNav: boolean;
  onClose: () => void;
};

const PaneWrapper: React.FC<Props> = ({ children, hasSideNav, onClose }) => {
  const isMobile = useIsMobile();
  const onCloseRef = useRef(onClose);
  onCloseRef.current = onClose;

  useEffect(() => {
    document.body.style.overflowY = 'hidden';
    return () => {
      document.body.style.overflowY = 'auto';
    };
  }, []);

  return (
    <m.div
      key="modal"
      initial={{ boxShadow: '0 0 0 4000px rgba(0, 0, 0, 0)', y: 'min(100vh, 100dvh)' }}
      animate={{ boxShadow: '0 0 0 4000px rgba(0, 0, 0, 0.3)', transition: { duration: 0.5, ease: [0.25, 1, 0.5, 1] }, y: 0 }}
      className={classNames(s.container, hasSideNav && s.containerWithSidNav)}
    >
      <div className={s.content}>
        {children}
        {isMobile && <Spacer size={96} />}
      </div>
      <button className={s.closeButton} onClick={onClose}>
        <XCircle weight="fill" size={24} color="var(--color-gray-100)" />
      </button>
    </m.div>
  );
};

export default PaneWrapper;
