export const BlackText: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <span
    style={{
      color: 'var(--color-gray-100)',
    }}
  >
    {children}
  </span>
);

export const GrayText: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <span
    style={{
      color: 'var(--color-gray-75)',
    }}
  >
    {children}
  </span>
);

export const BlueText: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <span
    style={{
      color: 'var(--color-primary)',
    }}
  >
    {children}
  </span>
);

export const GreenText: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <span
    style={{
      color: 'var(--color-success',
    }}
  >
    {children}
  </span>
);

export const RedText: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <span
    style={{
      color: 'var(--color-error)',
    }}
  >
    {children}
  </span>
);
