import useNoodleApi from '@hooks/useNoodleApi';
import Spacer from '@components/Spacer';
import ProgressIndicator from '@components/ProgressIndicator';
import { getCreatorAiFollowupHistory } from '@tsClient/creators';
import DashboardContext from '@layouts/DashboardLayout/DashboardContext';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import FollowupCard from '@components/DesignLibrary/FollowupCard';
import NoodleAI from '@components/Icons/NoodleAI';
import PaginationControls from '@components/DesignLibrary/PaginationControls';
import Header from '@components/DesignLibrary/Header';
import { Icon } from '@phosphor-icons/react';
import { AiFollowUpItem } from '@typings/api-models';
import { nanoid } from 'nanoid';
import s from './AiFollowupHistoryPage.module.scss';

const PAGE_SIZE = 16;

const AiFollowupHistoryPage: React.FC = () => {
  const { creator } = useContext(DashboardContext);
  const [followups, setFollowups] = useState<Array<AiFollowUpItem>>([]);
  const [isFetchingPage, setIsFetchingPage] = useState(false);
  const mostRecentRequestRef = useRef<string | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [numPages, setNumPages] = useState(0);
  const { getData: getFollowupHistoryFn } = useNoodleApi(getCreatorAiFollowupHistory);

  const loadFollowups = useCallback(async ({ page }: { page: number; }) => {
    setIsFetchingPage(true);
    const thisRequestId = nanoid();
    mostRecentRequestRef.current = thisRequestId;
    setCurrentPage(page);
    let responseData: Awaited<ReturnType<typeof getCreatorAiFollowupHistory>> | null = null;
    if (creator?.id) {
      const response = await getFollowupHistoryFn({
        creatorId: creator.id,
        page,
        perPage: PAGE_SIZE,
      });
      responseData = response.data;
    }
    if (responseData && thisRequestId === mostRecentRequestRef.current) {
      setIsFetchingPage(false);
      setFollowups(responseData.items);
      setNumPages(responseData.numPages);
    }
  }, [creator, getFollowupHistoryFn]);

  const handlePageChange = (newPage: number): void => {
    loadFollowups({ page: newPage });
  };

  useEffect(() => {
    loadFollowups({
      page: 1,
    });
  }, [loadFollowups]);

  if (isFetchingPage) {
    return (
      <>
        <Spacer size={48} />
        <ProgressIndicator isCentered />
      </>
    );
  }

  const hasItems = followups.length > 0;

  return (
    <div className={s.followupHistory__wrapper}>
      <Header
        className={s.header}
        Icon={NoodleAI as Icon}
        title={'Ai Followup History'}
        aiBg
      />
      {hasItems && <div className={s.cards} {...numPages > 1 && {
        style: {
          border: 'none',
        },
      }}>
        {followups.length > 0 && followups.map((followup) => (
          <FollowupCard
            key={followup.id}
            date={followup.date}
            member={followup.person}
            numActiveWorkflows={followup.person.numActiveWorkflows}
            text={followup.text}
            title={followup.title}
            type={followup.type}
          />
        ))}
      </div>}
      {followups.length === 0 && (
        <div className={s.noHistory}>No AI followup history yet.</div>
      )}
      {hasItems && (
        <PaginationControls
          onChangePage={handlePageChange}
          page={currentPage}
          numPages={numPages}
          className={s.pagination}
        />
      )}
    </div>
  );
};

export default AiFollowupHistoryPage;
