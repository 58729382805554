import * as ApiModels from '@typings/api-models';

const getAvailableTriggers = ({
  step,
  workflow,
}: {
  step: Pick<ApiModels.WorkflowStep, 'id'>;
  workflow: {
    /** @deprecated - use threads + stepMap instead */
    steps: Array<Pick<ApiModels.WorkflowStep, 'id'>>
  };
 }): ApiModels.WORKFLOW_TRIGGERS[] => {
  const stepIndex = workflow.steps.indexOf(step);
  const previousStep = workflow.steps[stepIndex - 1] || null;
  if (previousStep) {
    return [ApiModels.WORKFLOW_TRIGGERS.ALL_DEPENDENCIES];
  }
  return [
    ApiModels.WORKFLOW_TRIGGERS.INITIATE_CTA,
    ApiModels.WORKFLOW_TRIGGERS.PRODUCT_PURCHASED,
    ApiModels.WORKFLOW_TRIGGERS.PURCHASE_INITIATED,
    ApiModels.WORKFLOW_TRIGGERS.BOOKING_SCHEDULED,
  ];
};

export default getAvailableTriggers;
