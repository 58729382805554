import Buttons from '@components/Buttons';
import CheckBoxBlank from '@components/Icons/CheckBoxBlank';
import CheckBoxChecked from '@components/Icons/CheckBoxChecked';
import GoogleCalendar from '@components/Icons/GoogleCalendar';
import { CreatorCalendar, CreatorCalendarAccount, CreatorCalendarAccountSource } from '@typings/api-models';
import MicrosoftOutlookLogo from '@/components/Icons/MicrosoftOutlookLogo';

type Props = {
  account: Pick<CreatorCalendarAccount, 'id' | 'title' | 'isConnected' | 'source'> & {
    calendars: Array<Pick<CreatorCalendar, 'id' | 'isEnabled' | 'title'>>;
  };
  onDisableCalendar: (args: {
    accountId: string;
    calendarId: string;
  }) => void | Promise<void>;
  onEnableCalendar: (args: {
    accountId: string;
    calendarId: string;
  }) => void | Promise<void>;
  onDisconnectAccount: (args: { accountId: string }) => void | Promise<void>;
  onReconnectAccount: (args: { accountId: string }) => void | Promise<void>;
  setNumberOfCalendars: (numberOfCalendars: number) => void;
  numberOfCalendars: number;
  isLoading: boolean;
};

const CalendarAccount: React.FC<Props> = ({
  account,
  onDisconnectAccount,
  onReconnectAccount,
  onEnableCalendar,
  onDisableCalendar,
  setNumberOfCalendars,
  numberOfCalendars,
  isLoading,
}) => {
  const handleAccountClick = (): void => {
    if (account.isConnected) {
      onDisconnectAccount({ accountId: account.id });
    } else {
      onReconnectAccount({ accountId: account.id });
    }
  };

  const handleCalendarClick = (
    e: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    const calendarId = e.target.id;
    if (e.target.checked) {
      onEnableCalendar({ accountId: account.id, calendarId });
      setNumberOfCalendars(numberOfCalendars + 1);
    } else {
      onDisableCalendar({ accountId: account.id, calendarId });
      setNumberOfCalendars(numberOfCalendars - 1);
    }
  };

  return (
    <li>
      <div>
        {account.source === CreatorCalendarAccountSource.Google && <GoogleCalendar />}
        {account.source === CreatorCalendarAccountSource.Outlook && <MicrosoftOutlookLogo weight='fill' fill="#2F70C0" />}
        <strong>{account.title}</strong>
        <Buttons
          isFetching={isLoading}
          isFlat
          isWrapper
          style={{
            color: account.isConnected
              ? 'var(--color-error)'
              : 'var(--color-noodle)',
          }}
          onClick={handleAccountClick}
        >
          {account.isConnected ? 'Disconnect' : 'Reconnect'}
        </Buttons>
      </div>
      <fieldset>
        {account.calendars.map((calendar) => (
          <label key={calendar.id}>
            <input
              type="checkbox"
              defaultChecked={calendar.isEnabled}
              id={calendar.id}
              onChange={handleCalendarClick}
            />
            <CheckBoxChecked
              weight="fill"
              size={20}
              color="var(--color-noodle)"
            />
            <CheckBoxBlank size={20} color="var(--color-gray-50)" />
            {calendar.title}
          </label>
        ))}
      </fieldset>
    </li>
  );
};

export default CalendarAccount;
