import * as tsClient from '@tsClient';
import { CreatorCalendar, CreatorCalendarAccount } from '@typings/api-models';
import useNoodleApi from '@hooks/useNoodleApi';
import CalendarBlank from '@components/Icons/CalendarBlank';
import { mixpanelTrack } from '@providers/Mixpanel';
import { useEffect, useState } from 'react';
import ApiError from '@helpers/ApiError';
import Spacer from '@components/Spacer';
import ConnectOutlookButton from '@lib/CreatorProductAvailability/components/ConnectCalendars/ConnectOutlookButton';
import CollapsibleArea from '@components/CollapsibleArea';
import { useUser } from '@/hooks';
import { useFeatureFlag } from '@/providers/FeatureFlags';
import CalendarAccount from './CalendarAccount';
import ConnectGoogleButton from './ConnectGoogleButton';
import useGoogleCalendarConnect from './useGoogleCalendarConnect';
import s from './Connect.module.scss';

type Props = {
  creatorId: string;
  accounts: Array<
    Pick<CreatorCalendarAccount, 'id' | 'title' | 'isConnected' | 'source'> & {
      calendars: Array<Pick<CreatorCalendar, 'id' | 'isEnabled' | 'title'>>;
    }
  >;
  onChange: () => void | Promise<void>;
  onError: (err: ApiError) => void;
  teamMemberPersonId: string;
};

const ConnectedAccounts: React.FC<Props> = ({ accounts, creatorId, onChange, onError, teamMemberPersonId }) => {
  const handleLogin = useGoogleCalendarConnect({ creatorId, onConnect: onChange, onError, teamMemberPersonId });
  const teamMemberAvailabilityEnabled = useFeatureFlag('team-member-availability');
  const [user] = useUser();

  const isCurrentTeamMember = teamMemberAvailabilityEnabled ? user?.id === teamMemberPersonId : true;

  const { getData: disconnectCalendarAccount, fetchingState: { isFetching: isDisconnecting } } = useNoodleApi(
    tsClient.disconnectCalendarAccount,
  );
  const { getData: enableCalendar } = useNoodleApi(tsClient.enableCalendar);
  const { getData: disableCalendar } = useNoodleApi(tsClient.disableCalendar);

  const handleDisconnectAccount = async ({
    accountId,
  }: {
    accountId: string;
  }): Promise<void> => {
    const response = await disconnectCalendarAccount({
      calendarAccountId: accountId,
    });
    mixpanelTrack('Disconnected Google Calendar Account', {
      accountId,
      creatorId,
    });
    if (!response.error) {
      onChange();
    }
  };

  const handleReconnectAccount = ({ accountId }: { accountId: string; }): void => {
    disconnectCalendarAccount({
      calendarAccountId: accountId,
    });
    mixpanelTrack('Reconnect Google Calendar Account', {
      accountId,
      creatorId,
    });
    handleLogin();
  };

  const handleEnableCalendar = async ({
    accountId,
    calendarId,
  }: {
    accountId: string;
    calendarId: string;
  }): Promise<void> => {
    await enableCalendar({
      calendarAccountId: accountId,
      calendarId,
    });
    mixpanelTrack('Enabled A Google Calendar', {
      accountId,
      calendarId,
    });
  };

  const handleDisableCalendar = async ({
    accountId,
    calendarId,
  }: {
    accountId: string;
    calendarId: string;
  }): Promise<void> => {
    await disableCalendar({
      calendarAccountId: accountId,
      calendarId,
    });
    mixpanelTrack('Disabled A Google Calendar', {
      accountId,
      calendarId,
    });
  };

  const numberOfConnectedCalendars = accounts.reduce(
    (acc, account) => acc + account.calendars.filter((c) => c.isEnabled).length,
    0,
  );

  const [numberOfCalendars, setNumberOfCalendars] = useState(0);

  useEffect(() => {
    setNumberOfCalendars(numberOfConnectedCalendars);
  }, [numberOfConnectedCalendars]);

  return (
    <CollapsibleArea title={`${numberOfCalendars} Connected Calendar${numberOfCalendars !== 1 && 's' || ''}`} Icon={{ Component: CalendarBlank, color: 'var(--color-success)' }} className={s.accounts}>
      <ul>
        {accounts.map((account) => (
          <CalendarAccount
            key={account.id}
            account={account}
            onEnableCalendar={handleEnableCalendar}
            onDisableCalendar={handleDisableCalendar}
            onDisconnectAccount={handleDisconnectAccount}
            onReconnectAccount={handleReconnectAccount}
            setNumberOfCalendars={setNumberOfCalendars}
            numberOfCalendars={numberOfCalendars}
            isLoading={isDisconnecting}
          />
        ))}
      </ul>
      <Spacer size='16px' />
      {isCurrentTeamMember && (
        <>
          <span className='body-sm-bold'>Add another calendar:</span>
          <Spacer size='16px' />
          <ConnectGoogleButton
            creatorId={creatorId}
            teamMemberPersonId={teamMemberPersonId}
            onConnect={onChange}
            onError={onError}
          />
          <Spacer size='8px' />
          <ConnectOutlookButton
            creatorId={creatorId}
            teamMemberPersonId={teamMemberPersonId}
            onError={onError}
          />
          <Spacer size='24px' />
        </>
      )}
    </CollapsibleArea>
  );
};

export default ConnectedAccounts;
