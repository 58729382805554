import React, { FC } from 'react';
import Modal from '@components/Modal';
import CreateEditInvoiceRequestForm from '@lib/CreateEditInvoiceRequestForm';

type Props = {
  invoiceTemplate: Parameters<typeof CreateEditInvoiceRequestForm>[0]['invoiceTemplate'];
  onClose: () => void;
  beforeClose?: () => Promise<void>;
};

const InvoiceRequestModal: FC<Props> = ({ beforeClose, invoiceTemplate, onClose }) => (
  <Modal title={invoiceTemplate ? 'Edit Invoice Template' : 'Create Invoice Template'} onClose={onClose} >
    <CreateEditInvoiceRequestForm beforeClose={beforeClose} invoiceTemplate={invoiceTemplate} onClose={onClose} />
  </Modal>
);

export default InvoiceRequestModal;
