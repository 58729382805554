import { useEffect, useState } from 'react';
import classNames from 'classnames';
import File from '@components/DesignLibrary/File';
import Shimmer from '@components/Shimmer';
import preloadDocument from '@helpers/preloadDocument';
import { useUser } from '@hooks';
import { logError } from '@providers/ErrorTracking';
import { useFeatureFlag } from '@providers/FeatureFlags';
import * as ApiModels from '@typings/document-api-models';
import RenameDocumentModal from '@modals/RenameDocumentModal';
import useNoodleApi from '@hooks/useNoodleApi';
import updateDocumentById from '@/tsClient/documents/updateDocumentById';
import AIReaderModal from './AIReaderModal';
import Content from './Content';
import s from './DocumentPreview.module.scss';

type Props = {
  document: Pick<ApiModels.Document, 'id' | 'fileName' | 'createdAt' | 'uploadedBy' | 'mimeType' | 'uploadedAt'>;
  accessToken: string | null;
  canDownload: boolean;
  onReject?: () => void;
  isFaded?: boolean;
  documentRequestFileDescription?: string | null;
  documentRequestFileName?: string | null;
  canEvaluateWithAi?: boolean;
  showRenameButton?: boolean;
};

async function blobToBase64(blob: Blob): Promise<string> {
  const retVal = await new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
  return retVal as string;
}

const DocumentPreview: React.FC<Props> = ({
  document,
  accessToken,
  canDownload,
  onReject,
  isFaded,
  documentRequestFileDescription,
  documentRequestFileName,
  canEvaluateWithAi = false,
  showRenameButton = false,
}) => {
  const [user] = useUser();
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const [base64Blob, setBase64Blob] = useState<string | null>(null);
  const [aiReaderModalIsOpen, setAiReaderModalIsOpen] = useState(false);
  const [renamingModalIsOpen, setRenamingModalIsOpen] = useState(false);
  const canUseAiReaderFeatureFlag = useFeatureFlag('can-use-ai-reader');
  const { getData: sendReq } = useNoodleApi(updateDocumentById,{
    toastOnError: true,
  });
  const canUseAiReader = canUseAiReaderFeatureFlag && canEvaluateWithAi;

  useEffect(() => {
    if ((accessToken || user?.id) && document.id) {
      preloadDocument({
        accessToken,
        id: document.id,
      })
        .then(blob => {
          const typeBlob = new Blob([blob], { type: document.mimeType });
          const blobPreviewUrl = window.URL.createObjectURL(typeBlob);
          blobToBase64(blob).then(res => {
            // The API doesn't take `application/octet-stream`
            const remimedBase64Blob = res.replace('application/octet-stream', document.mimeType);

            setBase64Blob(remimedBase64Blob);
          });

          setPreviewUrl(blobPreviewUrl);
        })
        .catch(error => {
          logError(error);
        });
    }
  }, [document.id, accessToken, user?.id, document.mimeType]);

  const downloadFile = (): void => {
    if (previewUrl) {
      const a = window.document.createElement('a');
      a.href = previewUrl;
      a.style.display = 'none';

      if (document.fileName) {
        a.download = document.fileName;
      }
      window.document.body.appendChild(a);
      a.click();
    }
  };

  const showRenameModal = (): void => {
    setRenamingModalIsOpen(true);
  };

  const saveRenameModal = async (newName: string): Promise<void> => {
    if(newName === "") return;

    setRenamingModalIsOpen(false);
    const res = await sendReq({
      accessToken: accessToken || '',
      body : {fileName: newName},
      documentId: document.id,
    });

    if(res.error == null) {
      window.location.reload();
    }
  };

  const download = canDownload ? downloadFile : undefined;

  const onOpenAiReader = (): void => setAiReaderModalIsOpen(true);

  if (!previewUrl) {
    return <Shimmer className={s.iframe} />;
  }

  return (
    <>
      <File
        document={document}
        onDownload={download}
        onRename={showRenameButton ? showRenameModal : undefined}
        onReject={onReject}
        onOpenAiReader={canUseAiReader ? onOpenAiReader : undefined}
      />
      <div className={s.container}>
        <div className={classNames(s.wrapper, isFaded && s.faded)}>
          <Content
            url={previewUrl}
            mimeType={document.mimeType}
          />
        </div>
      </div>
      {aiReaderModalIsOpen && (
        <AIReaderModal
          onClose={() => setAiReaderModalIsOpen(false)}
          base64Blob={base64Blob}
          documentId={document.id}
          documentRequestFileDescription={documentRequestFileDescription}
          documentRequestFileName={documentRequestFileName}
        />
      )}

      {renamingModalIsOpen && (
        <RenameDocumentModal
          fileName={document.fileName}
          onSave={saveRenameModal}
          onClose={() => { setRenamingModalIsOpen(false); }}
        />
      )}
    </>
  );
};

export default DocumentPreview;
