import React, { useState } from 'react';

import X from '@components/Icons/X';
import Buttons from '@components/Buttons/Buttons';
import ProgressIndicator from '@components/ProgressIndicator';
import useDismissedMessages, { DEFAULT_HIDE_FOR_DAYS } from '@providers/Messages/useDismissedMessages';

import s from './MessageTip.module.scss';

type PropsTypes = {
  children: React.ReactNode;
  buttonClassName?: string;
  containerClassName?: string;
  messageTipKey?: string;
  dontShowDays?: number;
};

const MessageTip: React.FC<PropsTypes> = ({ children, containerClassName, messageTipKey, buttonClassName, dontShowDays }) => {
  const { dismissMessage, isMessageDismissed } = useDismissedMessages();
  const [isFetching, setIsFetching] = useState(false);

  const messageDismissed = isMessageDismissed(messageTipKey, { days: dontShowDays || DEFAULT_HIDE_FOR_DAYS });
  if (messageDismissed) {
    return null;
  }

  const dismissMessageTip = async (): Promise<void> => {
    if (messageTipKey) {
      setIsFetching(true);
      await dismissMessage(messageTipKey);
      setIsFetching(false);
    }
  };

  return (
    <div className={containerClassName || s['message-tip']}>
      <div className={s['message-tip__wrap']}>
        <div className={s['message-tip__content']}>{children}</div>
        {messageTipKey && (
          <Buttons
            className={buttonClassName || s['message-tip__button']}
            icon={
              isFetching
                ? (
                  <ProgressIndicator />
                )
                : (
                  <X weight="bold" size={10} />
                )
            }
            onClick={dismissMessageTip}
            aria-label="Dismiss message tip"
          />
        )}
      </div>
    </div>
  );
};

export default MessageTip;
